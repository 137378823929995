/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateIncident: OnCreateIncidentSubscription;
  onUpdateIncident: OnUpdateIncidentSubscription;
  onDeleteIncident: OnDeleteIncidentSubscription;
  onCreateIncidentNote: OnCreateIncidentNoteSubscription;
  onUpdateIncidentNote: OnUpdateIncidentNoteSubscription;
  onDeleteIncidentNote: OnDeleteIncidentNoteSubscription;
  onCreateIncidentActionList: OnCreateIncidentActionListSubscription;
  onUpdateIncidentActionList: OnUpdateIncidentActionListSubscription;
  onDeleteIncidentActionList: OnDeleteIncidentActionListSubscription;
  onCreateProcedureTask: OnCreateProcedureTaskSubscription;
  onUpdateProcedureTask: OnUpdateProcedureTaskSubscription;
  onDeleteProcedureTask: OnDeleteProcedureTaskSubscription;
  onCreateIncidentBundleTime: OnCreateIncidentBundleTimeSubscription;
  onUpdateIncidentBundleTime: OnUpdateIncidentBundleTimeSubscription;
  onDeleteIncidentBundleTime: OnDeleteIncidentBundleTimeSubscription;
  onCreateCameraEvent: OnCreateCameraEventSubscription;
  onUpdateCameraEvent: OnUpdateCameraEventSubscription;
  onDeleteCameraEvent: OnDeleteCameraEventSubscription;
  onCreateSecondaryCameraEvent: OnCreateSecondaryCameraEventSubscription;
  onUpdateSecondaryCameraEvent: OnUpdateSecondaryCameraEventSubscription;
  onDeleteSecondaryCameraEvent: OnDeleteSecondaryCameraEventSubscription;
  onCreateCustomer: OnCreateCustomerSubscription;
  onUpdateCustomer: OnUpdateCustomerSubscription;
  onDeleteCustomer: OnDeleteCustomerSubscription;
  onCreateVMSDetails: OnCreateVMSDetailsSubscription;
  onUpdateVMSDetails: OnUpdateVMSDetailsSubscription;
  onDeleteVMSDetails: OnDeleteVMSDetailsSubscription;
  onCreateDashboardDetail: OnCreateDashboardDetailSubscription;
  onUpdateDashboardDetail: OnUpdateDashboardDetailSubscription;
  onDeleteDashboardDetail: OnDeleteDashboardDetailSubscription;
  onCreateBoldBIDetails: OnCreateBoldBIDetailsSubscription;
  onUpdateBoldBIDetails: OnUpdateBoldBIDetailsSubscription;
  onDeleteBoldBIDetails: OnDeleteBoldBIDetailsSubscription;
  onCreateRapidSOSDetails: OnCreateRapidSOSDetailsSubscription;
  onUpdateRapidSOSDetails: OnUpdateRapidSOSDetailsSubscription;
  onDeleteRapidSOSDetails: OnDeleteRapidSOSDetailsSubscription;
  onCreateAddress: OnCreateAddressSubscription;
  onUpdateAddress: OnUpdateAddressSubscription;
  onDeleteAddress: OnDeleteAddressSubscription;
  onCreateContact: OnCreateContactSubscription;
  onUpdateContact: OnUpdateContactSubscription;
  onDeleteContact: OnDeleteContactSubscription;
  onCreatePendingSite: OnCreatePendingSiteSubscription;
  onUpdatePendingSite: OnUpdatePendingSiteSubscription;
  onDeletePendingSite: OnDeletePendingSiteSubscription;
  onCreateSite: OnCreateSiteSubscription;
  onUpdateSite: OnUpdateSiteSubscription;
  onDeleteSite: OnDeleteSiteSubscription;
  onCreateSiteSupvisdSetting: OnCreateSiteSupvisdSettingSubscription;
  onUpdateSiteSupvisdSetting: OnUpdateSiteSupvisdSettingSubscription;
  onDeleteSiteSupvisdSetting: OnDeleteSiteSupvisdSettingSubscription;
  onCreateSupvisdEventRule: OnCreateSupvisdEventRuleSubscription;
  onUpdateSupvisdEventRule: OnUpdateSupvisdEventRuleSubscription;
  onDeleteSupvisdEventRule: OnDeleteSupvisdEventRuleSubscription;
  onCreateSiteDeviceStatusConfig: OnCreateSiteDeviceStatusConfigSubscription;
  onUpdateSiteDeviceStatusConfig: OnUpdateSiteDeviceStatusConfigSubscription;
  onDeleteSiteDeviceStatusConfig: OnDeleteSiteDeviceStatusConfigSubscription;
  onCreateTrigger: OnCreateTriggerSubscription;
  onUpdateTrigger: OnUpdateTriggerSubscription;
  onDeleteTrigger: OnDeleteTriggerSubscription;
  onCreateSiteIntegration: OnCreateSiteIntegrationSubscription;
  onUpdateSiteIntegration: OnUpdateSiteIntegrationSubscription;
  onDeleteSiteIntegration: OnDeleteSiteIntegrationSubscription;
  onCreateSiteVMSDetails: OnCreateSiteVMSDetailsSubscription;
  onUpdateSiteVMSDetails: OnUpdateSiteVMSDetailsSubscription;
  onDeleteSiteVMSDetails: OnDeleteSiteVMSDetailsSubscription;
  onCreateTrackTikDetails: OnCreateTrackTikDetailsSubscription;
  onUpdateTrackTikDetails: OnUpdateTrackTikDetailsSubscription;
  onDeleteTrackTikDetails: OnDeleteTrackTikDetailsSubscription;
  onCreateTrackTikTask: OnCreateTrackTikTaskSubscription;
  onUpdateTrackTikTask: OnUpdateTrackTikTaskSubscription;
  onDeleteTrackTikTask: OnDeleteTrackTikTaskSubscription;
  onCreateSiteEmergencyContact: OnCreateSiteEmergencyContactSubscription;
  onUpdateSiteEmergencyContact: OnUpdateSiteEmergencyContactSubscription;
  onDeleteSiteEmergencyContact: OnDeleteSiteEmergencyContactSubscription;
  onCreatePhone: OnCreatePhoneSubscription;
  onUpdatePhone: OnUpdatePhoneSubscription;
  onDeletePhone: OnDeletePhoneSubscription;
  onCreateCamera: OnCreateCameraSubscription;
  onUpdateCamera: OnUpdateCameraSubscription;
  onDeleteCamera: OnDeleteCameraSubscription;
  onCreateObjectGroup: OnCreateObjectGroupSubscription;
  onUpdateObjectGroup: OnUpdateObjectGroupSubscription;
  onDeleteObjectGroup: OnDeleteObjectGroupSubscription;
  onCreateAudioCustomMessageType: OnCreateAudioCustomMessageTypeSubscription;
  onUpdateAudioCustomMessageType: OnUpdateAudioCustomMessageTypeSubscription;
  onDeleteAudioCustomMessageType: OnDeleteAudioCustomMessageTypeSubscription;
  onCreateCameraQualitySettings: OnCreateCameraQualitySettingsSubscription;
  onUpdateCameraQualitySettings: OnUpdateCameraQualitySettingsSubscription;
  onDeleteCameraQualitySettings: OnDeleteCameraQualitySettingsSubscription;
  onCreateCameraSceneDetectionSettings: OnCreateCameraSceneDetectionSettingsSubscription;
  onUpdateCameraSceneDetectionSettings: OnUpdateCameraSceneDetectionSettingsSubscription;
  onDeleteCameraSceneDetectionSettings: OnDeleteCameraSceneDetectionSettingsSubscription;
  onCreateCameraSceneDetectionThreshold: OnCreateCameraSceneDetectionThresholdSubscription;
  onUpdateCameraSceneDetectionThreshold: OnUpdateCameraSceneDetectionThresholdSubscription;
  onDeleteCameraSceneDetectionThreshold: OnDeleteCameraSceneDetectionThresholdSubscription;
  onCreateCameraSceneDetectionResult: OnCreateCameraSceneDetectionResultSubscription;
  onUpdateCameraSceneDetectionResult: OnUpdateCameraSceneDetectionResultSubscription;
  onDeleteCameraSceneDetectionResult: OnDeleteCameraSceneDetectionResultSubscription;
  onCreateCameraSceneDetectionResultItem: OnCreateCameraSceneDetectionResultItemSubscription;
  onUpdateCameraSceneDetectionResultItem: OnUpdateCameraSceneDetectionResultItemSubscription;
  onDeleteCameraSceneDetectionResultItem: OnDeleteCameraSceneDetectionResultItemSubscription;
  onCreateCameraCredentials: OnCreateCameraCredentialsSubscription;
  onUpdateCameraCredentials: OnUpdateCameraCredentialsSubscription;
  onDeleteCameraCredentials: OnDeleteCameraCredentialsSubscription;
  onCreateCameraAccessUrls: OnCreateCameraAccessUrlsSubscription;
  onUpdateCameraAccessUrls: OnUpdateCameraAccessUrlsSubscription;
  onDeleteCameraAccessUrls: OnDeleteCameraAccessUrlsSubscription;
  onCreateCameraImmixConfig: OnCreateCameraImmixConfigSubscription;
  onUpdateCameraImmixConfig: OnUpdateCameraImmixConfigSubscription;
  onDeleteCameraImmixConfig: OnDeleteCameraImmixConfigSubscription;
  onCreateActivityLog: OnCreateActivityLogSubscription;
  onUpdateActivityLog: OnUpdateActivityLogSubscription;
  onDeleteActivityLog: OnDeleteActivityLogSubscription;
  onCreateSignalTestLog: OnCreateSignalTestLogSubscription;
  onUpdateSignalTestLog: OnUpdateSignalTestLogSubscription;
  onDeleteSignalTestLog: OnDeleteSignalTestLogSubscription;
  onCreateAuditLog: OnCreateAuditLogSubscription;
  onUpdateAuditLog: OnUpdateAuditLogSubscription;
  onDeleteAuditLog: OnDeleteAuditLogSubscription;
  onCreateIncidentActionDetail: OnCreateIncidentActionDetailSubscription;
  onUpdateIncidentActionDetail: OnUpdateIncidentActionDetailSubscription;
  onDeleteIncidentActionDetail: OnDeleteIncidentActionDetailSubscription;
  onCreateSOP: OnCreateSOPSubscription;
  onUpdateSOP: OnUpdateSOPSubscription;
  onDeleteSOP: OnDeleteSOPSubscription;
  onCreateRspndrEvents: OnCreateRspndrEventsSubscription;
  onUpdateRspndrEvents: OnUpdateRspndrEventsSubscription;
  onDeleteRspndrEvents: OnDeleteRspndrEventsSubscription;
  onCreateRspndrIncident: OnCreateRspndrIncidentSubscription;
  onUpdateRspndrIncident: OnUpdateRspndrIncidentSubscription;
  onDeleteRspndrIncident: OnDeleteRspndrIncidentSubscription;
  onCreateRspndrIncidentCheckItem: OnCreateRspndrIncidentCheckItemSubscription;
  onUpdateRspndrIncidentCheckItem: OnUpdateRspndrIncidentCheckItemSubscription;
  onDeleteRspndrIncidentCheckItem: OnDeleteRspndrIncidentCheckItemSubscription;
  onCreateRspndrIncidentGroundingRule: OnCreateRspndrIncidentGroundingRuleSubscription;
  onUpdateRspndrIncidentGroundingRule: OnUpdateRspndrIncidentGroundingRuleSubscription;
  onDeleteRspndrIncidentGroundingRule: OnDeleteRspndrIncidentGroundingRuleSubscription;
  onCreateRspndrActivity: OnCreateRspndrActivitySubscription;
  onUpdateRspndrActivity: OnUpdateRspndrActivitySubscription;
  onDeleteRspndrActivity: OnDeleteRspndrActivitySubscription;
  onCreateTrackTikIncident: OnCreateTrackTikIncidentSubscription;
  onUpdateTrackTikIncident: OnUpdateTrackTikIncidentSubscription;
  onDeleteTrackTikIncident: OnDeleteTrackTikIncidentSubscription;
  onCreateTrackTikActivity: OnCreateTrackTikActivitySubscription;
  onUpdateTrackTikActivity: OnUpdateTrackTikActivitySubscription;
  onDeleteTrackTikActivity: OnDeleteTrackTikActivitySubscription;
  onCreateRapidSOSIncident: OnCreateRapidSOSIncidentSubscription;
  onUpdateRapidSOSIncident: OnUpdateRapidSOSIncidentSubscription;
  onDeleteRapidSOSIncident: OnDeleteRapidSOSIncidentSubscription;
  onCreateRapidSOSEventDetails: OnCreateRapidSOSEventDetailsSubscription;
  onUpdateRapidSOSEventDetails: OnUpdateRapidSOSEventDetailsSubscription;
  onDeleteRapidSOSEventDetails: OnDeleteRapidSOSEventDetailsSubscription;
  onCreateRapidSOSLocation: OnCreateRapidSOSLocationSubscription;
  onUpdateRapidSOSLocation: OnUpdateRapidSOSLocationSubscription;
  onDeleteRapidSOSLocation: OnDeleteRapidSOSLocationSubscription;
  onCreateRapidSOSCivicAddress: OnCreateRapidSOSCivicAddressSubscription;
  onUpdateRapidSOSCivicAddress: OnUpdateRapidSOSCivicAddressSubscription;
  onDeleteRapidSOSCivicAddress: OnDeleteRapidSOSCivicAddressSubscription;
  onCreateRapidSOSEmergencyContact: OnCreateRapidSOSEmergencyContactSubscription;
  onUpdateRapidSOSEmergencyContact: OnUpdateRapidSOSEmergencyContactSubscription;
  onDeleteRapidSOSEmergencyContact: OnDeleteRapidSOSEmergencyContactSubscription;
  onCreateRapidSOSActivity: OnCreateRapidSOSActivitySubscription;
  onUpdateRapidSOSActivity: OnUpdateRapidSOSActivitySubscription;
  onDeleteRapidSOSActivity: OnDeleteRapidSOSActivitySubscription;
  onCreateRapidSOSLogMessage: OnCreateRapidSOSLogMessageSubscription;
  onUpdateRapidSOSLogMessage: OnUpdateRapidSOSLogMessageSubscription;
  onDeleteRapidSOSLogMessage: OnDeleteRapidSOSLogMessageSubscription;
  onCreateRapidSOSAttachment: OnCreateRapidSOSAttachmentSubscription;
  onUpdateRapidSOSAttachment: OnUpdateRapidSOSAttachmentSubscription;
  onDeleteRapidSOSAttachment: OnDeleteRapidSOSAttachmentSubscription;
  onCreateIncidentCodeDetail: OnCreateIncidentCodeDetailSubscription;
  onUpdateIncidentCodeDetail: OnUpdateIncidentCodeDetailSubscription;
  onDeleteIncidentCodeDetail: OnDeleteIncidentCodeDetailSubscription;
  onCreateIncidentShareSettings: OnCreateIncidentShareSettingsSubscription;
  onUpdateIncidentShareSettings: OnUpdateIncidentShareSettingsSubscription;
  onDeleteIncidentShareSettings: OnDeleteIncidentShareSettingsSubscription;
  onCreateIncidentDataToShare: OnCreateIncidentDataToShareSubscription;
  onUpdateIncidentDataToShare: OnUpdateIncidentDataToShareSubscription;
  onDeleteIncidentDataToShare: OnDeleteIncidentDataToShareSubscription;
  onCreateIncidentShareEntry: OnCreateIncidentShareEntrySubscription;
  onUpdateIncidentShareEntry: OnUpdateIncidentShareEntrySubscription;
  onDeleteIncidentShareEntry: OnDeleteIncidentShareEntrySubscription;
  onCreateIncidentShareSite: OnCreateIncidentShareSiteSubscription;
  onUpdateIncidentShareSite: OnUpdateIncidentShareSiteSubscription;
  onDeleteIncidentShareSite: OnDeleteIncidentShareSiteSubscription;
  onCreateIncidentShareIntegrator: OnCreateIncidentShareIntegratorSubscription;
  onUpdateIncidentShareIntegrator: OnUpdateIncidentShareIntegratorSubscription;
  onDeleteIncidentShareIntegrator: OnDeleteIncidentShareIntegratorSubscription;
  onCreateIncidentShareEntryAccess: OnCreateIncidentShareEntryAccessSubscription;
  onUpdateIncidentShareEntryAccess: OnUpdateIncidentShareEntryAccessSubscription;
  onDeleteIncidentShareEntryAccess: OnDeleteIncidentShareEntryAccessSubscription;
  onCreateIncidentShareEntyNote: OnCreateIncidentShareEntyNoteSubscription;
  onUpdateIncidentShareEntyNote: OnUpdateIncidentShareEntyNoteSubscription;
  onDeleteIncidentShareEntyNote: OnDeleteIncidentShareEntyNoteSubscription;
  onCreateIncidentShareEvent: OnCreateIncidentShareEventSubscription;
  onUpdateIncidentShareEvent: OnUpdateIncidentShareEventSubscription;
  onDeleteIncidentShareEvent: OnDeleteIncidentShareEventSubscription;
  onCreateIncidentShareActionItems: OnCreateIncidentShareActionItemsSubscription;
  onUpdateIncidentShareActionItems: OnUpdateIncidentShareActionItemsSubscription;
  onDeleteIncidentShareActionItems: OnDeleteIncidentShareActionItemsSubscription;
  onCreateIncidentShareContactInformation: OnCreateIncidentShareContactInformationSubscription;
  onUpdateIncidentShareContactInformation: OnUpdateIncidentShareContactInformationSubscription;
  onDeleteIncidentShareContactInformation: OnDeleteIncidentShareContactInformationSubscription;
  onCreateCallNotice: OnCreateCallNoticeSubscription;
  onUpdateCallNotice: OnUpdateCallNoticeSubscription;
  onDeleteCallNotice: OnDeleteCallNoticeSubscription;
  onCreateCallLog: OnCreateCallLogSubscription;
  onUpdateCallLog: OnUpdateCallLogSubscription;
  onDeleteCallLog: OnDeleteCallLogSubscription;
  onCreateSMSLog: OnCreateSMSLogSubscription;
  onUpdateSMSLog: OnUpdateSMSLogSubscription;
  onDeleteSMSLog: OnDeleteSMSLogSubscription;
  onCreateEmailLog: OnCreateEmailLogSubscription;
  onUpdateEmailLog: OnUpdateEmailLogSubscription;
  onDeleteEmailLog: OnDeleteEmailLogSubscription;
  onCreateNotificationSetting: OnCreateNotificationSettingSubscription;
  onUpdateNotificationSetting: OnUpdateNotificationSettingSubscription;
  onDeleteNotificationSetting: OnDeleteNotificationSettingSubscription;
  onCreateNotification: OnCreateNotificationSubscription;
  onUpdateNotification: OnUpdateNotificationSubscription;
  onDeleteNotification: OnDeleteNotificationSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateUserVMSDetails: OnCreateUserVMSDetailsSubscription;
  onUpdateUserVMSDetails: OnUpdateUserVMSDetailsSubscription;
  onDeleteUserVMSDetails: OnDeleteUserVMSDetailsSubscription;
  onCreateCustomerUserPermissionSet: OnCreateCustomerUserPermissionSetSubscription;
  onUpdateCustomerUserPermissionSet: OnUpdateCustomerUserPermissionSetSubscription;
  onDeleteCustomerUserPermissionSet: OnDeleteCustomerUserPermissionSetSubscription;
  onCreateCustomerUserPermission: OnCreateCustomerUserPermissionSubscription;
  onUpdateCustomerUserPermission: OnUpdateCustomerUserPermissionSubscription;
  onDeleteCustomerUserPermission: OnDeleteCustomerUserPermissionSubscription;
  onCreateTax: OnCreateTaxSubscription;
  onUpdateTax: OnUpdateTaxSubscription;
  onDeleteTax: OnDeleteTaxSubscription;
  onCreateTaxesTaxType: OnCreateTaxesTaxTypeSubscription;
  onUpdateTaxesTaxType: OnUpdateTaxesTaxTypeSubscription;
  onDeleteTaxesTaxType: OnDeleteTaxesTaxTypeSubscription;
  onCreateTaxType: OnCreateTaxTypeSubscription;
  onUpdateTaxType: OnUpdateTaxTypeSubscription;
  onDeleteTaxType: OnDeleteTaxTypeSubscription;
  onCreateSetupFee: OnCreateSetupFeeSubscription;
  onUpdateSetupFee: OnUpdateSetupFeeSubscription;
  onDeleteSetupFee: OnDeleteSetupFeeSubscription;
  onCreateProduct: OnCreateProductSubscription;
  onUpdateProduct: OnUpdateProductSubscription;
  onDeleteProduct: OnDeleteProductSubscription;
  onCreateCameraPlan: OnCreateCameraPlanSubscription;
  onUpdateCameraPlan: OnUpdateCameraPlanSubscription;
  onDeleteCameraPlan: OnDeleteCameraPlanSubscription;
  onCreateLivePatrol: OnCreateLivePatrolSubscription;
  onUpdateLivePatrol: OnUpdateLivePatrolSubscription;
  onDeleteLivePatrol: OnDeleteLivePatrolSubscription;
  onCreateOperatorMinutes: OnCreateOperatorMinutesSubscription;
  onUpdateOperatorMinutes: OnUpdateOperatorMinutesSubscription;
  onDeleteOperatorMinutes: OnDeleteOperatorMinutesSubscription;
  onCreateSiteSubscription: OnCreateSiteSubscriptionSubscription;
  onUpdateSiteSubscription: OnUpdateSiteSubscriptionSubscription;
  onDeleteSiteSubscription: OnDeleteSiteSubscriptionSubscription;
  onCreateSubscriptionStripeDetail: OnCreateSubscriptionStripeDetailSubscription;
  onUpdateSubscriptionStripeDetail: OnUpdateSubscriptionStripeDetailSubscription;
  onDeleteSubscriptionStripeDetail: OnDeleteSubscriptionStripeDetailSubscription;
  onCreateStripePaymentMethod: OnCreateStripePaymentMethodSubscription;
  onUpdateStripePaymentMethod: OnUpdateStripePaymentMethodSubscription;
  onDeleteStripePaymentMethod: OnDeleteStripePaymentMethodSubscription;
  onCreateAppliedProduct: OnCreateAppliedProductSubscription;
  onUpdateAppliedProduct: OnUpdateAppliedProductSubscription;
  onDeleteAppliedProduct: OnDeleteAppliedProductSubscription;
  onCreateAppliedCameraPlan: OnCreateAppliedCameraPlanSubscription;
  onUpdateAppliedCameraPlan: OnUpdateAppliedCameraPlanSubscription;
  onDeleteAppliedCameraPlan: OnDeleteAppliedCameraPlanSubscription;
  onCreateAppliedLivePatrol: OnCreateAppliedLivePatrolSubscription;
  onUpdateAppliedLivePatrol: OnUpdateAppliedLivePatrolSubscription;
  onDeleteAppliedLivePatrol: OnDeleteAppliedLivePatrolSubscription;
  onCreateAppliedOperatorMinutes: OnCreateAppliedOperatorMinutesSubscription;
  onUpdateAppliedOperatorMinutes: OnUpdateAppliedOperatorMinutesSubscription;
  onDeleteAppliedOperatorMinutes: OnDeleteAppliedOperatorMinutesSubscription;
  onCreateAppliedSetupFee: OnCreateAppliedSetupFeeSubscription;
  onUpdateAppliedSetupFee: OnUpdateAppliedSetupFeeSubscription;
  onDeleteAppliedSetupFee: OnDeleteAppliedSetupFeeSubscription;
  onCreateTransactionLineItem: OnCreateTransactionLineItemSubscription;
  onUpdateTransactionLineItem: OnUpdateTransactionLineItemSubscription;
  onDeleteTransactionLineItem: OnDeleteTransactionLineItemSubscription;
  onCreateTransactionLineItemTax: OnCreateTransactionLineItemTaxSubscription;
  onUpdateTransactionLineItemTax: OnUpdateTransactionLineItemTaxSubscription;
  onDeleteTransactionLineItemTax: OnDeleteTransactionLineItemTaxSubscription;
  onCreateInvoice: OnCreateInvoiceSubscription;
  onUpdateInvoice: OnUpdateInvoiceSubscription;
  onDeleteInvoice: OnDeleteInvoiceSubscription;
  onCreateInvoicePayment: OnCreateInvoicePaymentSubscription;
  onUpdateInvoicePayment: OnUpdateInvoicePaymentSubscription;
  onDeleteInvoicePayment: OnDeleteInvoicePaymentSubscription;
  onCreateInvoiceTransferPayment: OnCreateInvoiceTransferPaymentSubscription;
  onUpdateInvoiceTransferPayment: OnUpdateInvoiceTransferPaymentSubscription;
  onDeleteInvoiceTransferPayment: OnDeleteInvoiceTransferPaymentSubscription;
  onCreateWalletTransfer: OnCreateWalletTransferSubscription;
  onUpdateWalletTransfer: OnUpdateWalletTransferSubscription;
  onDeleteWalletTransfer: OnDeleteWalletTransferSubscription;
  onCreateStripePayoutDetails: OnCreateStripePayoutDetailsSubscription;
  onUpdateStripePayoutDetails: OnUpdateStripePayoutDetailsSubscription;
  onDeleteStripePayoutDetails: OnDeleteStripePayoutDetailsSubscription;
  onCreateExchangeRateSetting: OnCreateExchangeRateSettingSubscription;
  onUpdateExchangeRateSetting: OnUpdateExchangeRateSettingSubscription;
  onDeleteExchangeRateSetting: OnDeleteExchangeRateSettingSubscription;
  onCreatePaymentTransferSettings: OnCreatePaymentTransferSettingsSubscription;
  onUpdatePaymentTransferSettings: OnUpdatePaymentTransferSettingsSubscription;
  onDeletePaymentTransferSettings: OnDeletePaymentTransferSettingsSubscription;
  onCreateWebhookHealth: OnCreateWebhookHealthSubscription;
  onUpdateWebhookHealth: OnUpdateWebhookHealthSubscription;
  onDeleteWebhookHealth: OnDeleteWebhookHealthSubscription;
  onCreateMaster: OnCreateMasterSubscription;
  onUpdateMaster: OnUpdateMasterSubscription;
  onDeleteMaster: OnDeleteMasterSubscription;
};

export type LambdaResponse = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export enum VMSPortalType {
  Alarm = "Alarm",
  Admin = "Admin",
  Client = "Client"
}

export type DispatchRspndrIncidentInput = {
  rspndr: RspndrApiIncident;
  monitoringCenterId: string;
  customerId: string;
  siteId: string;
  integratorId: string;
};

export type RspndrApiIncident = {
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  call_list?: Array<RspndrApiIncidentCallItemInput | null> | null;
  check_list?: Array<RspndrApiIncidentCheckItemInput | null> | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  dispatcher?: RspndrApiUser | null;
  eta_at?: string | null;
  events?: Array<RspndrApiEvent | null> | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  id?: string | null;
  incident_id: string;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  postal_code: string;
  report?: RspndrApiIncidentReport | null;
  rules: Array<RspndrApiIncidentGroundingRuleInput>;
  state: string;
  status?: string | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  user?: RspndrApiUser | null;
  zone?: string | null;
};

export enum RspndrBilledTo {
  MC = "MC",
  DEALER = "DEALER"
}

export type RspndrApiIncidentCallItemInput = {
  name: string;
  number: string;
  order?: number | null;
  type?: string | null;
};

export type RspndrApiIncidentCheckItemInput = {
  description?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
};

export type RspndrApiUser = {
  image_url?: string | null;
  name?: string | null;
  organisation?: RspndrApiOrg | null;
  username?: string | null;
};

export type RspndrApiOrg = {
  image_url?: string | null;
  name?: string | null;
  tenant_id?: string | null;
};

export type RspndrApiEvent = {
  created_at?: string | null;
  customer_number?: string | null;
  eta_at?: string | null;
  event?: string | null;
  id?: string | null;
  incident_id?: string | null;
  master_account_number?: string | null;
  notes?: string | null;
  report?: RspndrApiIncidentReport | null;
  user?: RspndrApiUser | null;
};

export type RspndrApiIncidentReport = {
  created_at?: string | null;
  id?: string | null;
  image_urls: Array<string>;
  pdf_url?: string | null;
  summary: string;
};

export enum RspndrServiceKind {
  ALARM_RESPONSE = "ALARM_RESPONSE"
}

export type RspndrApiIncidentGroundingRuleInput = {
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
};

export enum RspndrLeaveWhen {
  WHEN_ADVISED = "WHEN_ADVISED",
  WHEN_POLICE_ARRIVES = "WHEN_POLICE_ARRIVES",
  WHEN_MAX_DURATION_EXPIRED = "WHEN_MAX_DURATION_EXPIRED",
  LEAVE_IMMEDIATELY = "LEAVE_IMMEDIATELY",
  UNKNOWN = "UNKNOWN"
}

export type RspndrApiCancelIncident = {
  incident_id: string;
  notes: string;
};

export type CreateIncidentCustomerInput = {
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
};

export type CreateIncidentSiteInput = {
  id: string;
  timezone?: string | null;
};

export enum IncidentStatus {
  Open = "Open",
  Assigned = "Assigned",
  Closed = "Closed"
}

export enum EventType {
  CameraDisconnect = "CameraDisconnect",
  CameraReconnect = "CameraReconnect",
  Disarmed = "Disarmed",
  ErroneousAlert = "ErroneousAlert",
  Misidentification = "Misidentification",
  Other = "Other",
  Uncategorized = "Uncategorized",
  Normal = "Normal",
  CheckIn = "CheckIn",
  CheckOut = "CheckOut",
  ClockIn = "ClockIn",
  ClockOut = "ClockOut",
  ParkingStart = "ParkingStart",
  ParkingEnd = "ParkingEnd",
  GateAccess = "GateAccess",
  DoorAccess = "DoorAccess",
  TemperatureCheck = "TemperatureCheck",
  IDCheck = "IDCheck",
  PPECheck = "PPECheck",
  WorkingAloneCheck = "WorkingAloneCheck",
  WelfareCheck = "WelfareCheck",
  ParkingViolation = "ParkingViolation",
  Suspicious = "Suspicious",
  Loitering = "Loitering",
  Vandalism = "Vandalism",
  Trespass = "Trespass",
  Emergency = "Emergency",
  LifeInDanger = "LifeInDanger",
  Fire = "Fire",
  MedicalDuress = "MedicalDuress",
  HoldUp = "HoldUp",
  VehicleBreakIn = "VehicleBreakIn",
  DrugTrafficking = "DrugTrafficking",
  Assault = "Assault",
  PackageDelivery = "PackageDelivery",
  Muted = "Muted",
  Snoozed = "Snoozed",
  DeviceNotice = "DeviceNotice"
}

export type CreateIncidentCameraInput = {
  id: string;
  vmsCameraId?: string | null;
};

export type ExternalPartyResponseInput = {
  police: boolean;
  fire: boolean;
  ambulance: boolean;
  bylaw: boolean;
  security: boolean;
  outreach: boolean;
  siteContact: boolean;
  agenciesArrived: boolean;
  notArrivedReason?: string | null;
  notArrivedReasonTranslated?: string | null;
  notArrivedReasonSanitized?: string | null;
};

export type IncidentInput = {
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status?: IncidentStatus | null;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  assignmentHistory?: Array<AssignmentHistoryItemInput> | null;
  assignedUsername?: string | null;
  modifiedBy: string;
  resolution?: string | null;
  createdAt?: string | null;
};

export type AssignmentHistoryItemInput = {
  username: string;
  assignedAt: string;
};

export type LambdaDispositionBreakdownResponse = {
  __typename: "LambdaDispositionBreakdownResponse";
  statusCode?: number | null;
  l0?: number | null;
  l1?: number | null;
  l2?: number | null;
  l3?: number | null;
  l4?: number | null;
};

export type UpdateEventDispositionInput = {
  id: string;
  oldEventType: EventType;
  monitoringCenterId: string;
  vmsEventId?: string | null;
};

export enum RapidSOSEmergencyType {
  BURGLARY = "BURGLARY",
  HOLDUP = "HOLDUP",
  SILENT_ALARM = "SILENT_ALARM",
  CRASH = "CRASH",
  MEDICAL = "MEDICAL",
  FIRE = "FIRE",
  CO = "CO",
  OTHER = "OTHER"
}

export type LambdaRapidSOSResponse = {
  __typename: "LambdaRapidSOSResponse";
  statusCode?: number | null;
  rapidSOSIncident?: string | null;
  body?: string | null;
};

export enum RapidSOSLogMessageType {
  SYSTEM_UPDATE = "SYSTEM_UPDATE",
  STATUS_UPDATE = "STATUS_UPDATE",
  CHAT_MESSAGE = "CHAT_MESSAGE",
  MILESTONE = "MILESTONE",
  MULTI_TRIP_SIGNAL = "MULTI_TRIP_SIGNAL"
}

export enum TrackTikPriority {
  LOW = "LOW",
  IMPORTANT = "IMPORTANT",
  URGENT = "URGENT"
}

export type LambdaSearchResponse = {
  __typename: "LambdaSearchResponse";
  statusCode: number;
  items?: Array<LambdaSearchResponseItem> | null;
  errorMessage?: string | null;
  nextToken?: string | null;
};

export type LambdaSearchResponseItem = {
  __typename: "LambdaSearchResponseItem";
  type: string;
  id: string;
  url: string;
  overviewText: string;
};

export type IncidentSearchFilters = {
  startDate?: string | null;
  endDate?: string | null;
  assignedUsername?: string | null;
  cameraName?: string | null;
  vmsCameraName?: string | null;
  vmsCameraId?: number | null;
  siteIdList?: Array<string> | null;
  clientIdList?: Array<string> | null;
  primaryDispositionList?: Array<string> | null;
  statusList?: Array<string> | null;
};

export type IncidentReportResponse = {
  __typename: "IncidentReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<IncidentReportItem> | null;
};

export type IncidentReportItem = {
  __typename: "IncidentReportItem";
  id: string;
  createdAt: string;
  resolvedAt?: string | null;
  primaryDisposition: string;
  siteName: string;
  clientName: string;
  assignedTo?: string | null;
  status: string;
};

export type CameraEventSearchFilters = {
  startDate?: string | null;
  endDate?: string | null;
  siteIdList?: Array<string> | null;
  cameraIdList?: Array<string> | null;
  clientIdList?: Array<string> | null;
  integratorIdList?: Array<string> | null;
  dispositionList?: Array<string> | null;
  eventTypeList?: Array<EventType> | null;
};

export type CameraEventReportResponse = {
  __typename: "CameraEventReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<CameraEventReportItem> | null;
};

export type CameraEventReportItem = {
  __typename: "CameraEventReportItem";
  id: string;
  incidentId: string;
  startTime: string;
  site: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  camera: string;
  disposition: string;
  eventType: EventType;
  client: string;
  integrator: string;
};

export type InvoiceSearchFilters = {
  startDate?: string | null;
  endDate?: string | null;
  assignedUsername?: string | null;
  invoiceNumber?: string | null;
  siteIdList?: Array<string> | null;
  clientIdList?: Array<string> | null;
  invoiceTypeList?: Array<string> | null;
  toList?: Array<InvoiceToFromType> | null;
  fromList?: Array<InvoiceToFromType> | null;
  statusList?: Array<string> | null;
};

export type InvoiceToFromType = {
  id: string;
  isSite?: boolean | null;
  isIntegrator?: boolean | null;
  isPlatform?: boolean | null;
};

export type InvoiceReportResponse = {
  __typename: "InvoiceReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<InvoiceReportItem> | null;
};

export type InvoiceReportItem = {
  __typename: "InvoiceReportItem";
  id: string;
  invoiceNumber: string;
  createdAt: string;
  siteName: string;
  clientName: string;
  invoiceType: string;
  fromName: string;
  toName: string;
  status: string;
  total: number;
  currency: string;
};

export type CustomerPaymentsSearchFilters = {
  startDate?: string | null;
  endDate?: string | null;
  transactionId?: string | null;
  invoiceNumber?: string | null;
  billingEntityName?: string | null;
};

export type CustomerPaymentsReportResponse = {
  __typename: "CustomerPaymentsReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<CustomerPaymentsReportItem> | null;
};

export type CustomerPaymentsReportItem = {
  __typename: "CustomerPaymentsReportItem";
  invoiceNumber: string;
  paymentDate: string;
  billingEntityName: string;
  transactionId: string;
  amount: number;
  currency: string;
};

export type PayoutsSearchFilters = {
  startDate?: string | null;
  endDate?: string | null;
  referenceId?: string | null;
  invoiceNumber?: string | null;
  type?: PayoutItemType | null;
};

export enum PayoutItemType {
  Bill = "Bill",
  Invoice = "Invoice"
}

export type PayoutsReportResponse = {
  __typename: "PayoutsReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<PayoutsReportItem> | null;
};

export type PayoutsReportItem = {
  __typename: "PayoutsReportItem";
  invoiceNumber: string;
  paymentDate: string;
  amount: number;
  transferAmount: number;
  referenceId: string;
  type: PayoutItemType;
  currency: string;
  transactionCurr: string;
};

export type IncidentDataToShareInput = {
  id?: string | null;
  incidentId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes: boolean;
  externalSiteCriticalNotes: boolean;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  attachmentsList: Array<string>;
  videoFeedUrls?: boolean | null;
};

export enum IncidentShareType {
  ENDCUSTOMER = "ENDCUSTOMER",
  OTHER = "OTHER"
}

export type CreateUpdateSiteSubscription = {
  id?: string | null;
  siteId: string;
  customerId: string;
  currency: Currency;
  startDate: string;
  term: number;
  renewalTerm: number;
  merchant: SiteSubscriptionMerchant;
  billingDay: number;
  billingFrequency: BillingFrequency;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  integratorId: string;
  setupFeeIdsToRemove?: Array<string> | null;
  setupFeeParentIdsToAdd?: Array<string> | null;
  livePatrolBillingDisabled?: boolean | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  secondsPerInteraction?: number | null;
  billingEmails?: Array<string> | null;
  billingEntityName: string;
  maxNumberRecharges?: number | null;
  commissionBilling?: boolean | null;
  monitoringCenterId?: string | null;
};

export enum Currency {
  CAD = "CAD",
  USD = "USD",
  EUR = "EUR",
  MXN = "MXN",
  GBP = "GBP",
  JMD = "JMD"
}

export enum SiteSubscriptionMerchant {
  Integrator = "Integrator",
  MonitoringCenter = "MonitoringCenter"
}

export enum BillingFrequency {
  Monthly = "Monthly"
}

export type StripeSiteItem = {
  id: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  name: string;
};

export type StripeSetupIntent = {
  stripeSubscriptionId: string;
  paymentMethodId: string;
  status: StripePaymentStatus;
  customerId: string;
  integratorId: string;
  subscriptionId: string;
  monitoringCenterId: string;
};

export enum StripePaymentStatus {
  succeeded = "succeeded",
  processing = "processing",
  requires_payment_method = "requires_payment_method",
  requires_confirmation = "requires_confirmation",
  requires_action = "requires_action",
  canceled = "canceled"
}

export enum MonitoringStatus {
  Armed = "Armed",
  Disarmed = "Disarmed",
  Alarm = "Alarm"
}

export enum ArmedStates {
  Armed = "Armed",
  Disarmed = "Disarmed"
}

export enum MonitoringStates {
  Available = "Available",
  Unavailable = "Unavailable"
}

export type SupvisdEventSettingsInput = {
  siteSupvisdSettingId?: string | null;
  siteId: string;
  rulesToAdd?: Array<SupvisdRulesToAddUpdateInput> | null;
  rulesToUpdate?: Array<SupvisdRulesToAddUpdateInput> | null;
  rulesIdsToRemove?: Array<string> | null;
};

export type SupvisdRulesToAddUpdateInput = {
  id?: string | null;
  ruleType: RuleType;
  eventType: CameraEventSupervisionType;
  confidence?: number | null;
  disposition: EventType;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
};

export enum RuleType {
  AlarmOnly = "AlarmOnly",
  AlwaysOn = "AlwaysOn"
}

export enum CameraEventSupervisionType {
  Animal = "Animal",
  Bicycle = "Bicycle",
  Boat = "Boat",
  DeviceNotice = "DeviceNotice",
  Car = "Car",
  Fire = "Fire",
  HardHat = "HardHat",
  Luggage = "Luggage",
  NoHardHat = "NoHardHat",
  Person = "Person",
  Smoke = "Smoke",
  Unknown = "Unknown",
  Intrusion = "Intrusion",
  Loitering = "Loitering"
}

export type SiteVMSToken = {
  __typename: "SiteVMSToken";
  statusCode: number;
  accessToken?: string | null;
  refreshToken?: string | null;
};

export enum AuthCXUserAction {
  DeleteUser = "DeleteUser",
  CreateUser = "CreateUser",
  UpdateMe = "UpdateMe",
  UpdateUser = "UpdateUser",
  DisableUser = "DisableUser",
  ListUsers = "ListUsers",
  GetMe = "GetMe",
  GetUser = "GetUser"
}

export type CXUserDetailsInput = {
  phone?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  sitePermissions?: string | null;
  disabled?: boolean | null;
};

export enum AudioManagementAction {
  AddGroups = "AddGroups",
  UpdateGroups = "UpdateGroups",
  RemoveGroups = "RemoveGroups",
  AddMessageTypes = "AddMessageTypes",
  UpdateMessageTypes = "UpdateMessageTypes",
  RemoveMessageTypes = "RemoveMessageTypes"
}

export type AudioGroupDetailInput = {
  id?: string | null;
  name?: string | null;
};

export type AudioCustomMessageTypeInput = {
  id?: string | null;
  name?: string | null;
  transcript?: string | null;
  note?: string | null;
};

export enum AudioMessageType {
  Default = "Default",
  Custom = "Custom"
}

export type CreateVMSCustomerInput = {
  id?: string | null;
  name: string;
  type?: CustomerType | null;
  integratorId: string;
  status?: DataEntryStatus | null;
  monitoringCenterId: string;
  vmsCustomerId?: string | null;
  slaL2: string;
  slaL3: string;
  slaL4: string;
  address: CreateVMSAddressInput;
  contact: CreateVMSContactInput;
};

export enum CustomerType {
  MonitoringCenter = "MonitoringCenter",
  Integrator = "Integrator",
  Client = "Client"
}

export enum DataEntryStatus {
  Pending = "Pending",
  Created = "Created",
  Submitted = "Submitted",
  Approved = "Approved",
  Published = "Published",
  Suspended = "Suspended"
}

export type CreateVMSAddressInput = {
  id?: string | null;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
};

export type CreateVMSContactInput = {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone: string;
  ext?: string | null;
  email: string;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
};

export type AddEditSiteInput = {
  id?: string | null;
  name?: string | null;
  address?: CreateVMSAddressInput | null;
  contacts: Array<AddEditEmergencyContactInput>;
  condensedAddress?: string | null;
  status?: DataEntryStatus | null;
  timezone?: string | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  customerId: string;
  modifiedBy?: string | null;
  siteAddressId: string;
  hasSiteMap?: boolean | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  criticalNotes?: string | null;
};

export type AddEditEmergencyContactInput = {
  id?: string | null;
  name?: string | null;
  type: Array<SiteContactType>;
  phones: Array<AddEditPhoneInput>;
  verbalPasscode?: string | null;
  email?: string | null;
  priority?: number | null;
  notes?: string | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export enum SiteContactType {
  EmergencyContact = "EmergencyContact",
  EmailNotificationContact = "EmailNotificationContact",
  SiteContact = "SiteContact",
  AdminContact = "AdminContact",
  IncidentCODEContact = "IncidentCODEContact"
}

export type AddEditPhoneInput = {
  id?: string | null;
  contactId?: string | null;
  type: PhoneType;
  number: string;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export enum PhoneType {
  Landline = "Landline",
  Mobile = "Mobile"
}

export enum SiteType {
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL = "COMMERCIAL",
  VEHICLE = "VEHICLE",
  PERSONAL = "PERSONAL"
}

export type UniversalQueueResponse = {
  __typename: "UniversalQueueResponse";
  statusCode: number;
  rows?: Array<UniversalQueueRow> | null;
};

export type UniversalQueueRow = {
  __typename: "UniversalQueueRow";
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  primaryDisposition: string;
  siteName: string;
  overSLASeconds?: number | null;
  monitoringCenterName: string;
  assignedTo?: string | null;
  assignBy: string;
  username: string;
  url: string;
  verifiedAlarm: boolean;
};

export type CreateIncidentInput = {
  id?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<AssignmentHistoryItemInput> | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: ExternalPartyResponseInput | null;
  invoiceId?: string | null;
};

export type ModelIncidentConditionInput = {
  integratorId?: ModelIDInput | null;
  status?: ModelIncidentStatusInput | null;
  incidentSiteId?: ModelIDInput | null;
  incidentCustomerId?: ModelIDInput | null;
  dispositionLevel?: ModelStringInput | null;
  primaryDisposition?: ModelStringInput | null;
  dispositionList?: ModelStringInput | null;
  closedTime?: ModelStringInput | null;
  resolvedTime?: ModelStringInput | null;
  expectedResolvedTime?: ModelStringInput | null;
  billableTime?: ModelFloatInput | null;
  assignedUsername?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  hasShareExternal?: ModelBooleanInput | null;
  resolution?: ModelStringInput | null;
  resolutionTranslated?: ModelStringInput | null;
  resolutionSanitized?: ModelStringInput | null;
  invoiceId?: ModelIDInput | null;
  and?: Array<ModelIncidentConditionInput | null> | null;
  or?: Array<ModelIncidentConditionInput | null> | null;
  not?: ModelIncidentConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIncidentStatusInput = {
  eq?: IncidentStatus | null;
  ne?: IncidentStatus | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Incident = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<AssignmentHistoryItem> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: ExternalPartyResponse | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: ModelCameraEventConnection | null;
  site: Site;
};

export type AssignmentHistoryItem = {
  __typename: "AssignmentHistoryItem";
  username: string;
  assignedAt: string;
};

export type ExternalPartyResponse = {
  __typename: "ExternalPartyResponse";
  police: boolean;
  fire: boolean;
  ambulance: boolean;
  bylaw: boolean;
  security: boolean;
  outreach: boolean;
  siteContact: boolean;
  agenciesArrived: boolean;
  notArrivedReason?: string | null;
  notArrivedReasonTranslated?: string | null;
  notArrivedReasonSanitized?: string | null;
};

export type ModelCameraEventConnection = {
  __typename: "ModelCameraEventConnection";
  items: Array<CameraEvent | null>;
  nextToken?: string | null;
};

export type CameraEvent = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: ModelSecondaryCameraEventConnection | null;
  camera: Camera;
};

export enum Disposition {
  L0 = "L0",
  L1 = "L1",
  L2 = "L2",
  L3 = "L3",
  L4 = "L4"
}

export enum CameraEventTriggerType {
  Motion = "Motion",
  Intrusion = "Intrusion",
  LineCrossing = "LineCrossing",
  Analytic = "Analytic",
  LicensePlate = "LicensePlate",
  FaceRecognition = "FaceRecognition",
  Tampering = "Tampering",
  Loitering = "Loitering",
  Notice = "Notice"
}

export enum CameraEventObjectType {
  Animal = "Animal",
  Bicycle = "Bicycle",
  Boat = "Boat",
  Car = "Car",
  DeviceNotice = "DeviceNotice",
  Fire = "Fire",
  HardHat = "HardHat",
  Luggage = "Luggage",
  NoHardHat = "NoHardHat",
  Person = "Person",
  Smoke = "Smoke",
  Unknown = "Unknown"
}

export enum VMSSourceType {
  Acknowledgement = "Acknowledgement",
  SupervisedEvent = "SupervisedEvent"
}

export type ModelSecondaryCameraEventConnection = {
  __typename: "ModelSecondaryCameraEventConnection";
  items: Array<SecondaryCameraEvent | null>;
  nextToken?: string | null;
};

export type SecondaryCameraEvent = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type Camera = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: TalkdownInstructions | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: CameraQualitySettings | null;
};

export enum DeviceType {
  Camera = "Camera",
  Gateway = "Gateway",
  Audio = "Audio"
}

export type TalkdownInstructions = {
  __typename: "TalkdownInstructions";
  talkdownType?: TalkdownType | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  phone?: string | null;
  url?: string | null;
  messageDetails?: Array<TalkdownMessageDetail | null> | null;
  hostConfiguration?: TalkdownHostConfiguration | null;
};

export enum TalkdownType {
  Manual = "Manual",
  PlayMessage = "PlayMessage"
}

export type TalkdownMessageDetail = {
  __typename: "TalkdownMessageDetail";
  messageType: AudioMessageType;
  messageTypeId?: string | null;
  urlSuffix: string;
};

export type TalkdownHostConfiguration = {
  __typename: "TalkdownHostConfiguration";
  baseUrl?: string | null;
  username?: string | null;
  password?: string | null;
  method: HttpRequestType;
};

export enum HttpRequestType {
  POST = "POST",
  GET = "GET",
  PUT = "PUT"
}

export enum DeviceHealthCheckSLA {
  HOUR_1 = "HOUR_1",
  HOUR_4 = "HOUR_4",
  HOUR_24 = "HOUR_24"
}

export enum HealthCheckStatus {
  PASS = "PASS",
  FAIL = "FAIL"
}

export enum DeviceStatusProperties {
  vmsConnect = "vmsConnect",
  isOnvif = "isOnvif",
  videoStreamState = "videoStreamState",
  isAnalyticsEnabled = "isAnalyticsEnabled",
  isMotionEnabled = "isMotionEnabled"
}

export type CameraQualitySettings = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type Site = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: Address;
  integrations?: ModelSiteIntegrationConnection | null;
  contacts?: ModelSiteEmergencyContactConnection | null;
  cameras?: ModelCameraConnection | null;
  sops?: ModelSOPConnection | null;
};

export enum AlarmStates {
  Alarm = "Alarm",
  NoAlarm = "NoAlarm"
}

export type Address = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelSiteIntegrationConnection = {
  __typename: "ModelSiteIntegrationConnection";
  items: Array<SiteIntegration | null>;
  nextToken?: string | null;
};

export type SiteIntegration = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: TrackTikDetails | null;
};

export enum IntegrationType {
  EmergencyOperator = "EmergencyOperator",
  GuardService = "GuardService",
  Bylaw = "Bylaw",
  OutreachAgencies = "OutreachAgencies",
  IncidentCODE = "IncidentCODE"
}

export enum IntegrationSubType {
  Direct = "Direct",
  Shared = "Shared",
  TrackTik = "TrackTik",
  Fire = "Fire",
  Police = "Police",
  Medical = "Medical"
}

export type TrackTikDetails = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: ModelTrackTikTaskConnection | null;
};

export type ModelTrackTikTaskConnection = {
  __typename: "ModelTrackTikTaskConnection";
  items: Array<TrackTikTask | null>;
  nextToken?: string | null;
};

export type TrackTikTask = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelSiteEmergencyContactConnection = {
  __typename: "ModelSiteEmergencyContactConnection";
  items: Array<SiteEmergencyContact | null>;
  nextToken?: string | null;
};

export type SiteEmergencyContact = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: ModelPhoneConnection | null;
};

export type ModelPhoneConnection = {
  __typename: "ModelPhoneConnection";
  items: Array<Phone | null>;
  nextToken?: string | null;
};

export type Phone = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelCameraConnection = {
  __typename: "ModelCameraConnection";
  items: Array<Camera | null>;
  nextToken?: string | null;
};

export type ModelSOPConnection = {
  __typename: "ModelSOPConnection";
  items: Array<SOP | null>;
  nextToken?: string | null;
};

export type SOP = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export enum EventOp {
  DispatchPolice = "DispatchPolice",
  ContactSite = "ContactSite",
  ContactList = "ContactList",
  LogEvent = "LogEvent",
  DispatchGuard = "DispatchGuard",
  TwowayAudio = "TwowayAudio",
  DispatchFire = "DispatchFire",
  DispatchMedical = "DispatchMedical",
  DispatchOutreach = "DispatchOutreach",
  DispatchBylaw = "DispatchBylaw",
  SystemDisarm = "SystemDisarm",
  CreateClip = "CreateClip"
}

export type UpdateIncidentInput = {
  id: string;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  status?: IncidentStatus | null;
  incidentSiteId?: string | null;
  incidentCustomerId?: string | null;
  dispositionLevel?: string | null;
  primaryDisposition?: string | null;
  dispositionList?: Array<string> | null;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime?: string | null;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<AssignmentHistoryItemInput> | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: ExternalPartyResponseInput | null;
  invoiceId?: string | null;
};

export type DeleteIncidentInput = {
  id: string;
};

export type CreateIncidentNoteInput = {
  id?: string | null;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt?: string | null;
  shareExternal?: boolean | null;
};

export type ModelIncidentNoteConditionInput = {
  incidentId?: ModelIDInput | null;
  note?: ModelStringInput | null;
  noteTranslated?: ModelStringInput | null;
  noteSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  writtenBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  shareExternal?: ModelBooleanInput | null;
  and?: Array<ModelIncidentNoteConditionInput | null> | null;
  or?: Array<ModelIncidentNoteConditionInput | null> | null;
  not?: ModelIncidentNoteConditionInput | null;
};

export type IncidentNote = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type UpdateIncidentNoteInput = {
  id: string;
  incidentId?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  writtenBy?: string | null;
  createdAt?: string | null;
  shareExternal?: boolean | null;
};

export type DeleteIncidentNoteInput = {
  id: string;
};

export type CreateIncidentActionListInput = {
  id?: string | null;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type ModelIncidentActionListConditionInput = {
  incidentId?: ModelIDInput | null;
  priority?: ModelIntInput | null;
  eventType?: ModelEventTypeInput | null;
  disposition?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelIncidentActionListConditionInput | null> | null;
  or?: Array<ModelIncidentActionListConditionInput | null> | null;
  not?: ModelIncidentActionListConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelEventTypeInput = {
  eq?: EventType | null;
  ne?: EventType | null;
};

export type IncidentActionList = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: ModelProcedureTaskConnection | null;
};

export type ModelProcedureTaskConnection = {
  __typename: "ModelProcedureTaskConnection";
  items: Array<ProcedureTask | null>;
  nextToken?: string | null;
};

export type ProcedureTask = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentActionListInput = {
  id: string;
  incidentId?: string | null;
  priority?: number | null;
  eventType?: EventType | null;
  disposition?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteIncidentActionListInput = {
  id: string;
};

export type CreateProcedureTaskInput = {
  id?: string | null;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
};

export type ModelProcedureTaskConditionInput = {
  incidentId?: ModelIDInput | null;
  incidentActionItemId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  completed?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelProcedureTaskConditionInput | null> | null;
  or?: Array<ModelProcedureTaskConditionInput | null> | null;
  not?: ModelProcedureTaskConditionInput | null;
};

export type UpdateProcedureTaskInput = {
  id: string;
  incidentId?: string | null;
  incidentActionItemId?: string | null;
  order?: number | null;
  completed?: boolean | null;
  description?: string | null;
  modifiedBy?: string | null;
  monitoringCenterId?: string | null;
  archived?: boolean | null;
};

export type DeleteProcedureTaskInput = {
  id: string;
};

export type CreateIncidentBundleTimeInput = {
  id?: string | null;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
};

export type ModelIncidentBundleTimeConditionInput = {
  applyToAssigned?: ModelBooleanInput | null;
  bundleTime?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelIncidentBundleTimeConditionInput | null> | null;
  or?: Array<ModelIncidentBundleTimeConditionInput | null> | null;
  not?: ModelIncidentBundleTimeConditionInput | null;
};

export type IncidentBundleTime = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentBundleTimeInput = {
  id: string;
  monitoringCenterId?: string | null;
  applyToAssigned?: boolean | null;
  bundleTime?: number | null;
  modifiedBy?: string | null;
};

export type DeleteIncidentBundleTimeInput = {
  id: string;
};

export type CreateCameraEventInput = {
  id?: string | null;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt?: string | null;
  vmsSourceType?: VMSSourceType | null;
};

export type ModelCameraEventConditionInput = {
  vmsEventId?: ModelStringInput | null;
  vmsNotes?: ModelStringInput | null;
  vmsNotesTranslated?: ModelStringInput | null;
  vmsNotesSanitized?: ModelStringInput | null;
  disposition?: ModelDispositionInput | null;
  eventType?: ModelEventTypeInput | null;
  thumbnailFilename?: ModelStringInput | null;
  triggerType?: ModelCameraEventTriggerTypeInput | null;
  objectsFound?: ModelCameraEventObjectTypeListInput | null;
  integratorId?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  incidentPriority?: ModelIntInput | null;
  cameraEventCameraId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  startTime?: ModelStringInput | null;
  endTime?: ModelStringInput | null;
  resolvedTime?: ModelStringInput | null;
  instantaneous?: ModelBooleanInput | null;
  cameraEventUrl?: ModelStringInput | null;
  cameraEventUrlPublic?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  vmsUserId?: ModelStringInput | null;
  ackTimestamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  vmsSourceType?: ModelVMSSourceTypeInput | null;
  and?: Array<ModelCameraEventConditionInput | null> | null;
  or?: Array<ModelCameraEventConditionInput | null> | null;
  not?: ModelCameraEventConditionInput | null;
};

export type ModelDispositionInput = {
  eq?: Disposition | null;
  ne?: Disposition | null;
};

export type ModelCameraEventTriggerTypeInput = {
  eq?: CameraEventTriggerType | null;
  ne?: CameraEventTriggerType | null;
};

export type ModelCameraEventObjectTypeListInput = {
  eq?: Array<CameraEventObjectType | null> | null;
  ne?: Array<CameraEventObjectType | null> | null;
  contains?: CameraEventObjectType | null;
  notContains?: CameraEventObjectType | null;
};

export type ModelVMSSourceTypeInput = {
  eq?: VMSSourceType | null;
  ne?: VMSSourceType | null;
};

export type UpdateCameraEventInput = {
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition?: Disposition | null;
  eventType?: EventType | null;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  incidentId?: string | null;
  incidentPriority?: number | null;
  cameraEventCameraId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl?: string | null;
  cameraEventUrlPublic?: string | null;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt?: string | null;
  vmsSourceType?: VMSSourceType | null;
};

export type DeleteCameraEventInput = {
  id: string;
};

export type CreateSecondaryCameraEventInput = {
  id?: string | null;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
};

export type ModelSecondaryCameraEventConditionInput = {
  cameraEventId?: ModelIDInput | null;
  vmsNotes?: ModelStringInput | null;
  vmsNotesTranslated?: ModelStringInput | null;
  vmsNotesSanitized?: ModelStringInput | null;
  disposition?: ModelDispositionInput | null;
  eventType?: ModelEventTypeInput | null;
  vmsSourceType?: ModelVMSSourceTypeInput | null;
  ackTimestamp?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  vmsUserId?: ModelStringInput | null;
  and?: Array<ModelSecondaryCameraEventConditionInput | null> | null;
  or?: Array<ModelSecondaryCameraEventConditionInput | null> | null;
  not?: ModelSecondaryCameraEventConditionInput | null;
};

export type UpdateSecondaryCameraEventInput = {
  id: string;
  cameraEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition?: Disposition | null;
  eventType?: EventType | null;
  vmsSourceType?: VMSSourceType | null;
  ackTimestamp?: string | null;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId?: string | null;
};

export type DeleteSecondaryCameraEventInput = {
  id: string;
};

export type CreateCustomerInput = {
  id?: string | null;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  customerAddressId: string;
  customerContactId: string;
  customerRapidSOSIntegrationId?: string | null;
  customerBoldBIIntegrationId?: string | null;
};

export type ModelCustomerConditionInput = {
  name?: ModelStringInput | null;
  type?: ModelCustomerTypeInput | null;
  status?: ModelDataEntryStatusInput | null;
  criticalNotes?: ModelStringInput | null;
  criticalNotesTranslated?: ModelStringInput | null;
  criticalNotesSanitized?: ModelStringInput | null;
  externalCriticalNotes?: ModelStringInput | null;
  externalCriticalNotesTranslated?: ModelStringInput | null;
  externalCriticalNotesSanitized?: ModelStringInput | null;
  vmsCustomerId?: ModelIntInput | null;
  vmsMCWebhookId?: ModelStringInput | null;
  vmsMonitoringCenterRoleId?: ModelIntInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  slaL1?: ModelStringInput | null;
  slaL2?: ModelStringInput | null;
  slaL3?: ModelStringInput | null;
  slaL4?: ModelStringInput | null;
  sortName?: ModelStringInput | null;
  softphoneEnabled?: ModelBooleanInput | null;
  softphoneNumber?: ModelStringInput | null;
  callbackNumber?: ModelStringInput | null;
  callbackNumberExt?: ModelStringInput | null;
  zendeskWidgetScript?: ModelStringInput | null;
  hubspotId?: ModelStringInput | null;
  hasLogo?: ModelBooleanInput | null;
  mobileLogo?: ModelStringInput | null;
  invoicePrefix?: ModelStringInput | null;
  invoiceNumber?: ModelIntInput | null;
  invoiceFooter?: ModelStringInput | null;
  errorEmail?: ModelStringInput | null;
  vmsUrl?: ModelStringInput | null;
  vmsAlarmUrl?: ModelStringInput | null;
  vmsAdminUrl?: ModelStringInput | null;
  orgDomainUrl?: ModelStringInput | null;
  fromEmail?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  allowCustomerViewPendingUntil?: ModelStringInput | null;
  and?: Array<ModelCustomerConditionInput | null> | null;
  or?: Array<ModelCustomerConditionInput | null> | null;
  not?: ModelCustomerConditionInput | null;
};

export type ModelCustomerTypeInput = {
  eq?: CustomerType | null;
  ne?: CustomerType | null;
};

export type ModelDataEntryStatusInput = {
  eq?: DataEntryStatus | null;
  ne?: DataEntryStatus | null;
};

export type Customer = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: BoldBIDetails | null;
  rapidSOSIntegration?: RapidSOSDetails | null;
  address: Address;
  contact: Contact;
  sites?: ModelSiteConnection | null;
  cameras?: ModelCameraConnection | null;
};

export type BoldBIDetails = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type RapidSOSDetails = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type Contact = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelSiteConnection = {
  __typename: "ModelSiteConnection";
  items: Array<Site | null>;
  nextToken?: string | null;
};

export type UpdateCustomerInput = {
  id: string;
  name?: string | null;
  type?: CustomerType | null;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  customerAddressId?: string | null;
  customerContactId?: string | null;
  customerRapidSOSIntegrationId?: string | null;
  customerBoldBIIntegrationId?: string | null;
};

export type DeleteCustomerInput = {
  id: string;
};

export type CreateVMSDetailsInput = {
  id?: string | null;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
};

export type ModelVMSDetailsConditionInput = {
  apiKey?: ModelStringInput | null;
  username?: ModelStringInput | null;
  password?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  accessTokenExpires?: ModelStringInput | null;
  refreshTokenExpires?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelVMSDetailsConditionInput | null> | null;
  or?: Array<ModelVMSDetailsConditionInput | null> | null;
  not?: ModelVMSDetailsConditionInput | null;
};

export type VMSDetails = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVMSDetailsInput = {
  id: string;
  apiKey?: string | null;
  username?: string | null;
  password?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteVMSDetailsInput = {
  id: string;
};

export type CreateDashboardDetailInput = {
  id?: string | null;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
};

export enum UserGroup {
  Operators = "Operators",
  SuperOperators = "SuperOperators",
  IntegratorAdmin = "IntegratorAdmin",
  Admin = "Admin",
  BillingAdmin = "BillingAdmin",
  Controller = "Controller",
  Owner = "Owner",
  Customer = "Customer"
}

export type ModelDashboardDetailConditionInput = {
  name?: ModelStringInput | null;
  awsId?: ModelStringInput | null;
  allowedGroups?: ModelUserGroupListInput | null;
  username?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelDashboardDetailConditionInput | null> | null;
  or?: Array<ModelDashboardDetailConditionInput | null> | null;
  not?: ModelDashboardDetailConditionInput | null;
};

export type ModelUserGroupListInput = {
  eq?: Array<UserGroup | null> | null;
  ne?: Array<UserGroup | null> | null;
  contains?: UserGroup | null;
  notContains?: UserGroup | null;
};

export type DashboardDetail = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDashboardDetailInput = {
  id: string;
  name?: string | null;
  awsId?: string | null;
  monitoringCenterId?: string | null;
  allowedGroups?: Array<UserGroup | null> | null;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
};

export type DeleteDashboardDetailInput = {
  id: string;
};

export type CreateBoldBIDetailsInput = {
  id?: string | null;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type ModelBoldBIDetailsConditionInput = {
  siteUsername?: ModelStringInput | null;
  sitePassword?: ModelStringInput | null;
  siteUrl?: ModelStringInput | null;
  siteDefaultDashboardId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelBoldBIDetailsConditionInput | null> | null;
  or?: Array<ModelBoldBIDetailsConditionInput | null> | null;
  not?: ModelBoldBIDetailsConditionInput | null;
};

export type UpdateBoldBIDetailsInput = {
  id: string;
  siteUsername?: string | null;
  sitePassword?: string | null;
  siteUrl?: string | null;
  siteDefaultDashboardId?: string | null;
  customerId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteBoldBIDetailsInput = {
  id: string;
};

export type CreateRapidSOSDetailsInput = {
  id?: string | null;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type ModelRapidSOSDetailsConditionInput = {
  clientId?: ModelStringInput | null;
  clientSecret?: ModelStringInput | null;
  webhookEnabled?: ModelBooleanInput | null;
  agencyId?: ModelStringInput | null;
  webhookId?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelRapidSOSDetailsConditionInput | null> | null;
  or?: Array<ModelRapidSOSDetailsConditionInput | null> | null;
  not?: ModelRapidSOSDetailsConditionInput | null;
};

export type UpdateRapidSOSDetailsInput = {
  id: string;
  clientId?: string | null;
  clientSecret?: string | null;
  webhookEnabled?: boolean | null;
  agencyId?: string | null;
  webhookId?: number | null;
  customerId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteRapidSOSDetailsInput = {
  id: string;
};

export type CreateAddressInput = {
  id?: string | null;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
};

export type ModelAddressConditionInput = {
  lineOne?: ModelStringInput | null;
  lineTwo?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  country?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelAddressConditionInput | null> | null;
  or?: Array<ModelAddressConditionInput | null> | null;
  not?: ModelAddressConditionInput | null;
};

export type UpdateAddressInput = {
  id: string;
  lineOne?: string | null;
  lineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteAddressInput = {
  id: string;
};

export type CreateContactInput = {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
};

export type ModelContactConditionInput = {
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  ext?: ModelStringInput | null;
  email?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  customerCanShareSecurables?: ModelBooleanInput | null;
  customerCanLoginAdminPortal?: ModelBooleanInput | null;
  and?: Array<ModelContactConditionInput | null> | null;
  or?: Array<ModelContactConditionInput | null> | null;
  not?: ModelContactConditionInput | null;
};

export type UpdateContactInput = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
};

export type DeleteContactInput = {
  id: string;
};

export type CreatePendingSiteInput = {
  id?: string | null;
  name: string;
  address?: PendingEntityAddressInput | null;
  contacts?: Array<PendingEntityContactInput> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
};

export type PendingEntityAddressInput = {
  lineOne?: string | null;
  lineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
};

export type PendingEntityContactInput = {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  types?: Array<SiteContactType> | null;
  verbalPasscode?: string | null;
};

export enum PendingEntityStatus {
  Created = "Created",
  Taken = "Taken"
}

export type ModelPendingSiteConditionInput = {
  name?: ModelStringInput | null;
  status?: ModelPendingEntityStatusInput | null;
  notes?: ModelStringInput | null;
  primaryEmail?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelPendingSiteConditionInput | null> | null;
  or?: Array<ModelPendingSiteConditionInput | null> | null;
  not?: ModelPendingSiteConditionInput | null;
};

export type ModelPendingEntityStatusInput = {
  eq?: PendingEntityStatus | null;
  ne?: PendingEntityStatus | null;
};

export type PendingSite = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: PendingEntityAddress | null;
  contacts?: Array<PendingEntityContact> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type PendingEntityAddress = {
  __typename: "PendingEntityAddress";
  lineOne?: string | null;
  lineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
};

export type PendingEntityContact = {
  __typename: "PendingEntityContact";
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  types?: Array<SiteContactType> | null;
  verbalPasscode?: string | null;
};

export type UpdatePendingSiteInput = {
  id: string;
  name?: string | null;
  address?: PendingEntityAddressInput | null;
  contacts?: Array<PendingEntityContactInput> | null;
  status?: PendingEntityStatus | null;
  notes?: string | null;
  primaryEmail?: string | null;
  customerId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeletePendingSiteInput = {
  id: string;
};

export type CreateSiteInput = {
  id?: string | null;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  siteAddressId: string;
};

export type ModelSiteConditionInput = {
  name?: ModelStringInput | null;
  status?: ModelDataEntryStatusInput | null;
  customerId?: ModelIDInput | null;
  criticalNotes?: ModelStringInput | null;
  criticalNotesTranslated?: ModelStringInput | null;
  criticalNotesSanitized?: ModelStringInput | null;
  externalCriticalNotes?: ModelStringInput | null;
  externalCriticalNotesTranslated?: ModelStringInput | null;
  externalCriticalNotesSanitized?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  timezone?: ModelStringInput | null;
  vmsSharedMonitoringRoleId?: ModelIntInput | null;
  monitoringStatus?: ModelMonitoringStatusInput | null;
  maintainCamerasOnMonitoringRole?: ModelBooleanInput | null;
  armingRulesConfig?: ModelStringInput | null;
  monitoringState?: ModelMonitoringStatesInput | null;
  armedState?: ModelArmedStatesInput | null;
  alarmState?: ModelAlarmStatesInput | null;
  supervisionEnabled?: ModelBooleanInput | null;
  triggersEnabled?: ModelBooleanInput | null;
  muteAfterInteractionCount?: ModelIntInput | null;
  type?: ModelSiteTypeInput | null;
  alarmsCentralStation?: ModelStringInput | null;
  alarmsCentralStationPhone?: ModelStringInput | null;
  alarmsCentralStationPhoneExt?: ModelStringInput | null;
  alarmsVerbalPasscode?: ModelStringInput | null;
  alarmsCSID?: ModelStringInput | null;
  alarmsAR?: ModelStringInput | null;
  alarmsNotes?: ModelStringInput | null;
  alarmsNotesTranslated?: ModelStringInput | null;
  alarmsNotesSanitized?: ModelStringInput | null;
  alarmsPermitNum?: ModelStringInput | null;
  alarmsPermitExpires?: ModelStringInput | null;
  autoSendToTrackTik?: ModelBooleanInput | null;
  hasSiteMap?: ModelBooleanInput | null;
  condensedAddress?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  allowCustomerViewPendingUntil?: ModelStringInput | null;
  and?: Array<ModelSiteConditionInput | null> | null;
  or?: Array<ModelSiteConditionInput | null> | null;
  not?: ModelSiteConditionInput | null;
};

export type ModelMonitoringStatusInput = {
  eq?: MonitoringStatus | null;
  ne?: MonitoringStatus | null;
};

export type ModelMonitoringStatesInput = {
  eq?: MonitoringStates | null;
  ne?: MonitoringStates | null;
};

export type ModelArmedStatesInput = {
  eq?: ArmedStates | null;
  ne?: ArmedStates | null;
};

export type ModelAlarmStatesInput = {
  eq?: AlarmStates | null;
  ne?: AlarmStates | null;
};

export type ModelSiteTypeInput = {
  eq?: SiteType | null;
  ne?: SiteType | null;
};

export type UpdateSiteInput = {
  id: string;
  name?: string | null;
  status?: DataEntryStatus | null;
  customerId?: string | null;
  integratorId?: string | null;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId?: string | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  siteAddressId?: string | null;
};

export type DeleteSiteInput = {
  id: string;
};

export type CreateSiteSupvisdSettingInput = {
  id?: string | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelSiteSupvisdSettingConditionInput = {
  siteId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSiteSupvisdSettingConditionInput | null> | null;
  or?: Array<ModelSiteSupvisdSettingConditionInput | null> | null;
  not?: ModelSiteSupvisdSettingConditionInput | null;
};

export type SiteSupvisdSetting = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: ModelSupvisdEventRuleConnection | null;
};

export type ModelSupvisdEventRuleConnection = {
  __typename: "ModelSupvisdEventRuleConnection";
  items: Array<SupvisdEventRule | null>;
  nextToken?: string | null;
};

export type SupvisdEventRule = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSiteSupvisdSettingInput = {
  id: string;
  siteId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteSiteSupvisdSettingInput = {
  id: string;
};

export type CreateSupvisdEventRuleInput = {
  id?: string | null;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
};

export type ModelSupvisdEventRuleConditionInput = {
  cameraId?: ModelIDInput | null;
  muteSitePersonRule?: ModelBooleanInput | null;
  siteId?: ModelIDInput | null;
  siteSupvisdSettingId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  ruleType?: ModelRuleTypeInput | null;
  cameraEventType?: ModelCameraEventSupervisionTypeInput | null;
  disposition?: ModelEventTypeInput | null;
  confidence?: ModelIntInput | null;
  and?: Array<ModelSupvisdEventRuleConditionInput | null> | null;
  or?: Array<ModelSupvisdEventRuleConditionInput | null> | null;
  not?: ModelSupvisdEventRuleConditionInput | null;
};

export type ModelRuleTypeInput = {
  eq?: RuleType | null;
  ne?: RuleType | null;
};

export type ModelCameraEventSupervisionTypeInput = {
  eq?: CameraEventSupervisionType | null;
  ne?: CameraEventSupervisionType | null;
};

export type UpdateSupvisdEventRuleInput = {
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  siteSupvisdSettingId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  ruleType?: RuleType | null;
  cameraEventType?: CameraEventSupervisionType | null;
  disposition?: EventType | null;
  confidence?: number | null;
};

export type DeleteSupvisdEventRuleInput = {
  id: string;
};

export type CreateSiteDeviceStatusConfigInput = {
  id?: string | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
};

export enum DeviceStatusCheckSLA {
  MINUTE_5 = "MINUTE_5",
  MINUTE_10 = "MINUTE_10",
  MINUTE_15 = "MINUTE_15",
  MINUTE_30 = "MINUTE_30",
  HOUR_1 = "HOUR_1",
  HOUR_4 = "HOUR_4",
  HOUR_24 = "HOUR_24"
}

export type ModelSiteDeviceStatusConfigConditionInput = {
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  deviceStatusEnabled?: ModelBooleanInput | null;
  statusFrequency?: ModelDeviceStatusCheckSLAInput | null;
  statusVerifiedTolerance?: ModelDeviceStatusCheckSLAInput | null;
  statusProperties?: ModelDeviceStatusPropertiesListInput | null;
  and?: Array<ModelSiteDeviceStatusConfigConditionInput | null> | null;
  or?: Array<ModelSiteDeviceStatusConfigConditionInput | null> | null;
  not?: ModelSiteDeviceStatusConfigConditionInput | null;
};

export type ModelDeviceStatusCheckSLAInput = {
  eq?: DeviceStatusCheckSLA | null;
  ne?: DeviceStatusCheckSLA | null;
};

export type ModelDeviceStatusPropertiesListInput = {
  eq?: Array<DeviceStatusProperties | null> | null;
  ne?: Array<DeviceStatusProperties | null> | null;
  contains?: DeviceStatusProperties | null;
  notContains?: DeviceStatusProperties | null;
};

export type SiteDeviceStatusConfig = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSiteDeviceStatusConfigInput = {
  id: string;
  siteId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  deviceStatusEnabled?: boolean | null;
  statusFrequency?: DeviceStatusCheckSLA | null;
  statusVerifiedTolerance?: DeviceStatusCheckSLA | null;
  statusProperties?: Array<DeviceStatusProperties> | null;
};

export type DeleteSiteDeviceStatusConfigInput = {
  id: string;
};

export type CreateTriggerInput = {
  id?: string | null;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<TriggerFilterInput> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
};

export enum TriggerType {
  Status = "Status",
  Analytic = "Analytic",
  Disposition = "Disposition"
}

export type TriggerFilterInput = {
  filter: string;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  actions?: Array<TriggerActionInput> | null;
};

export type TriggerActionInput = {
  action: TriggerActionType;
  actionSettings: string;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
};

export enum TriggerActionType {
  Webhook = "Webhook",
  Audio = "Audio",
  SMS = "SMS",
  Email = "Email"
}

export type ModelTriggerConditionInput = {
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  type?: ModelTriggerTypeInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  frequencyLimit?: ModelIntInput | null;
  lastTriggered?: ModelStringInput | null;
  and?: Array<ModelTriggerConditionInput | null> | null;
  or?: Array<ModelTriggerConditionInput | null> | null;
  not?: ModelTriggerConditionInput | null;
};

export type ModelTriggerTypeInput = {
  eq?: TriggerType | null;
  ne?: TriggerType | null;
};

export type Trigger = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<TriggerFilter> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type TriggerFilter = {
  __typename: "TriggerFilter";
  filter: string;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  actions?: Array<TriggerAction> | null;
};

export type TriggerAction = {
  __typename: "TriggerAction";
  action: TriggerActionType;
  actionSettings: string;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
};

export type UpdateTriggerInput = {
  id: string;
  siteId?: string | null;
  integratorId?: string | null;
  customerId?: string | null;
  monitoringCenterId?: string | null;
  type?: TriggerType | null;
  filters?: Array<TriggerFilterInput> | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
};

export type DeleteTriggerInput = {
  id: string;
};

export type CreateSiteIntegrationInput = {
  id?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  siteIntegrationTrackTickDetailsId?: string | null;
};

export type ModelSiteIntegrationConditionInput = {
  siteId?: ModelIDInput | null;
  type?: ModelIntegrationTypeInput | null;
  subType?: ModelIntegrationSubTypeInput | null;
  subTypeList?: ModelIntegrationSubTypeListInput | null;
  agency?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  phoneExt?: ModelStringInput | null;
  email?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSiteIntegrationConditionInput | null> | null;
  or?: Array<ModelSiteIntegrationConditionInput | null> | null;
  not?: ModelSiteIntegrationConditionInput | null;
};

export type ModelIntegrationTypeInput = {
  eq?: IntegrationType | null;
  ne?: IntegrationType | null;
};

export type ModelIntegrationSubTypeInput = {
  eq?: IntegrationSubType | null;
  ne?: IntegrationSubType | null;
};

export type ModelIntegrationSubTypeListInput = {
  eq?: Array<IntegrationSubType | null> | null;
  ne?: Array<IntegrationSubType | null> | null;
  contains?: IntegrationSubType | null;
  notContains?: IntegrationSubType | null;
};

export type UpdateSiteIntegrationInput = {
  id: string;
  siteId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  siteIntegrationTrackTickDetailsId?: string | null;
};

export type DeleteSiteIntegrationInput = {
  id: string;
};

export type CreateSiteVMSDetailsInput = {
  id?: string | null;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelSiteVMSDetailsConditionInput = {
  username?: ModelStringInput | null;
  password?: ModelStringInput | null;
  apiKey?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  accessTokenExpires?: ModelStringInput | null;
  refreshTokenExpires?: ModelStringInput | null;
  siteId?: ModelIDInput | null;
  siteUserId?: ModelIDInput | null;
  siteUserRoleId?: ModelIntInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSiteVMSDetailsConditionInput | null> | null;
  or?: Array<ModelSiteVMSDetailsConditionInput | null> | null;
  not?: ModelSiteVMSDetailsConditionInput | null;
};

export type SiteVMSDetails = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSiteVMSDetailsInput = {
  id: string;
  username?: string | null;
  password?: string | null;
  apiKey?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId?: string | null;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteSiteVMSDetailsInput = {
  id: string;
};

export type CreateTrackTikDetailsInput = {
  id?: string | null;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
};

export type ModelTrackTikDetailsConditionInput = {
  siteIntegrationId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  apiUrl?: ModelStringInput | null;
  apiUsername?: ModelStringInput | null;
  apiPassword?: ModelStringInput | null;
  accountId?: ModelStringInput | null;
  token?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  tokenExpiry?: ModelStringInput | null;
  refreshTokenExpiry?: ModelStringInput | null;
  autoSend?: ModelBooleanInput | null;
  autoSendErrorEmail?: ModelStringInput | null;
  configured?: ModelBooleanInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTrackTikDetailsConditionInput | null> | null;
  or?: Array<ModelTrackTikDetailsConditionInput | null> | null;
  not?: ModelTrackTikDetailsConditionInput | null;
};

export type UpdateTrackTikDetailsInput = {
  id: string;
  siteIntegrationId?: string | null;
  siteId?: string | null;
  monitoringCenterId?: string | null;
  apiUrl?: string | null;
  apiUsername?: string | null;
  apiPassword?: string | null;
  accountId?: string | null;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured?: boolean | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
};

export type DeleteTrackTikDetailsInput = {
  id: string;
};

export type CreateTrackTikTaskInput = {
  id?: string | null;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
};

export type ModelTrackTikTaskConditionInput = {
  trackTikDetailsId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  eventType?: ModelEventTypeInput | null;
  trackTikName?: ModelStringInput | null;
  trackTikId?: ModelIntInput | null;
  trackTikReportTemplateId?: ModelIntInput | null;
  archived?: ModelBooleanInput | null;
  modifedBy?: ModelStringInput | null;
  and?: Array<ModelTrackTikTaskConditionInput | null> | null;
  or?: Array<ModelTrackTikTaskConditionInput | null> | null;
  not?: ModelTrackTikTaskConditionInput | null;
};

export type UpdateTrackTikTaskInput = {
  id: string;
  trackTikDetailsId?: string | null;
  siteId?: string | null;
  monitoringCenterId?: string | null;
  eventType?: EventType | null;
  trackTikName?: string | null;
  trackTikId?: number | null;
  trackTikReportTemplateId?: number | null;
  archived?: boolean | null;
  modifedBy?: string | null;
};

export type DeleteTrackTikTaskInput = {
  id: string;
};

export type CreateSiteEmergencyContactInput = {
  id?: string | null;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
};

export type ModelSiteEmergencyContactConditionInput = {
  siteId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelSiteContactTypeListInput | null;
  verbalPasscode?: ModelStringInput | null;
  email?: ModelStringInput | null;
  priority?: ModelIntInput | null;
  archived?: ModelBooleanInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelSiteEmergencyContactConditionInput | null> | null;
  or?: Array<ModelSiteEmergencyContactConditionInput | null> | null;
  not?: ModelSiteEmergencyContactConditionInput | null;
};

export type ModelSiteContactTypeListInput = {
  eq?: Array<SiteContactType | null> | null;
  ne?: Array<SiteContactType | null> | null;
  contains?: SiteContactType | null;
  notContains?: SiteContactType | null;
};

export type UpdateSiteEmergencyContactInput = {
  id: string;
  siteId?: string | null;
  name?: string | null;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority?: number | null;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
};

export type DeleteSiteEmergencyContactInput = {
  id: string;
};

export type CreatePhoneInput = {
  id?: string | null;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type ModelPhoneConditionInput = {
  contactId?: ModelIDInput | null;
  type?: ModelPhoneTypeInput | null;
  number?: ModelStringInput | null;
  ext?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelPhoneConditionInput | null> | null;
  or?: Array<ModelPhoneConditionInput | null> | null;
  not?: ModelPhoneConditionInput | null;
};

export type ModelPhoneTypeInput = {
  eq?: PhoneType | null;
  ne?: PhoneType | null;
};

export type UpdatePhoneInput = {
  id: string;
  contactId?: string | null;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeletePhoneInput = {
  id: string;
};

export type CreateCameraInput = {
  id?: string | null;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: TalkdownInstructionsInput | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  cameraQualitySettingsId?: string | null;
};

export type TalkdownInstructionsInput = {
  talkdownType?: TalkdownType | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  phone?: string | null;
  url?: string | null;
  messageDetails?: Array<TalkdownMessageDetailInput | null> | null;
  hostConfiguration?: TalkdownHostConfigurationInput | null;
};

export type TalkdownMessageDetailInput = {
  messageType: AudioMessageType;
  messageTypeId?: string | null;
  urlSuffix: string;
};

export type TalkdownHostConfigurationInput = {
  baseUrl?: string | null;
  username?: string | null;
  password?: string | null;
  method: HttpRequestType;
};

export type ModelCameraConditionInput = {
  name?: ModelStringInput | null;
  deviceType?: ModelDeviceTypeInput | null;
  status?: ModelDataEntryStatusInput | null;
  vmsCameraName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  descriptionTranslated?: ModelStringInput | null;
  descriptionSanitized?: ModelStringInput | null;
  vmsDescription?: ModelStringInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  criticalNotes?: ModelStringInput | null;
  criticalNotesTranslated?: ModelStringInput | null;
  criticalNotesSanitized?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  vmsCameraId?: ModelStringInput | null;
  vmsCustomerId?: ModelStringInput | null;
  testingExpiry?: ModelStringInput | null;
  armedState?: ModelArmedStatesInput | null;
  snoozeReason?: ModelStringInput | null;
  snoozeReasonTranslated?: ModelStringInput | null;
  snoozeReasonSanitized?: ModelStringInput | null;
  snoozeExpiry?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  zoneNumber?: ModelStringInput | null;
  zoneDescription?: ModelStringInput | null;
  zoneDescriptionTranslated?: ModelStringInput | null;
  zoneDescriptionSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  isOnvif?: ModelBooleanInput | null;
  videoStreamState?: ModelStringInput | null;
  deviceUrlOnline?: ModelBooleanInput | null;
  vmsConnect?: ModelBooleanInput | null;
  isP2P?: ModelBooleanInput | null;
  isAnalyticsEnabled?: ModelBooleanInput | null;
  isMotionEnabled?: ModelBooleanInput | null;
  testEventReceived?: ModelBooleanInput | null;
  healthCheckEnabled?: ModelBooleanInput | null;
  healthCheckSLA?: ModelDeviceHealthCheckSLAInput | null;
  healthCheckStatus?: ModelHealthCheckStatusInput | null;
  ignoreStatusChecks?: ModelBooleanInput | null;
  statusChecksProperties?: ModelDeviceStatusPropertiesListInput | null;
  statusLastVerified?: ModelStringInput | null;
  archiveDurationHours?: ModelIntInput | null;
  accessUrlsId?: ModelIDInput | null;
  credentialsId?: ModelIDInput | null;
  deviceBrand?: ModelStringInput | null;
  deviceModel?: ModelStringInput | null;
  vmsPlanId?: ModelIntInput | null;
  phpRegistrationCode?: ModelStringInput | null;
  serialNumber?: ModelStringInput | null;
  p2pState?: ModelStringInput | null;
  lastPowerCycle?: ModelStringInput | null;
  baselineThumbnailFilename?: ModelStringInput | null;
  currentThumbnailFilename?: ModelStringInput | null;
  sceneChangeDetectionEnabled?: ModelBooleanInput | null;
  passingSceneDetection?: ModelBooleanInput | null;
  hubspotId?: ModelStringInput | null;
  immixMonitoring?: ModelBooleanInput | null;
  hasMic?: ModelBooleanInput | null;
  hasSpeaker?: ModelBooleanInput | null;
  audioGroups?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCameraConditionInput | null> | null;
  or?: Array<ModelCameraConditionInput | null> | null;
  not?: ModelCameraConditionInput | null;
};

export type ModelDeviceTypeInput = {
  eq?: DeviceType | null;
  ne?: DeviceType | null;
};

export type ModelDeviceHealthCheckSLAInput = {
  eq?: DeviceHealthCheckSLA | null;
  ne?: DeviceHealthCheckSLA | null;
};

export type ModelHealthCheckStatusInput = {
  eq?: HealthCheckStatus | null;
  ne?: HealthCheckStatus | null;
};

export type UpdateCameraInput = {
  id: string;
  name?: string | null;
  deviceType?: DeviceType | null;
  status?: DataEntryStatus | null;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  customerId?: string | null;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: TalkdownInstructionsInput | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  cameraQualitySettingsId?: string | null;
};

export type DeleteCameraInput = {
  id: string;
};

export type CreateObjectGroupInput = {
  id?: string | null;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
};

export enum ObjectGroupType {
  Audio = "Audio"
}

export type ModelObjectGroupConditionInput = {
  customerId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameTranslated?: ModelStringInput | null;
  nameSanitized?: ModelStringInput | null;
  groupType?: ModelObjectGroupTypeInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelObjectGroupConditionInput | null> | null;
  or?: Array<ModelObjectGroupConditionInput | null> | null;
  not?: ModelObjectGroupConditionInput | null;
};

export type ModelObjectGroupTypeInput = {
  eq?: ObjectGroupType | null;
  ne?: ObjectGroupType | null;
};

export type ObjectGroup = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateObjectGroupInput = {
  id: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  customerId?: string | null;
  siteId?: string | null;
  name?: string | null;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType?: ObjectGroupType | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
};

export type DeleteObjectGroupInput = {
  id: string;
};

export type CreateAudioCustomMessageTypeInput = {
  id?: string | null;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
};

export type ModelAudioCustomMessageTypeConditionInput = {
  customerId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameTranslated?: ModelStringInput | null;
  nameSanitized?: ModelStringInput | null;
  transcript?: ModelStringInput | null;
  transcriptTranslated?: ModelStringInput | null;
  transcriptSanitized?: ModelStringInput | null;
  note?: ModelStringInput | null;
  noteTranslated?: ModelStringInput | null;
  noteSanitized?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelAudioCustomMessageTypeConditionInput | null> | null;
  or?: Array<ModelAudioCustomMessageTypeConditionInput | null> | null;
  not?: ModelAudioCustomMessageTypeConditionInput | null;
};

export type AudioCustomMessageType = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAudioCustomMessageTypeInput = {
  id: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  customerId?: string | null;
  siteId?: string | null;
  name?: string | null;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript?: string | null;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
};

export type DeleteAudioCustomMessageTypeInput = {
  id: string;
};

export type CreateCameraQualitySettingsInput = {
  id?: string | null;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
};

export type ModelCameraQualitySettingsConditionInput = {
  width?: ModelIntInput | null;
  height?: ModelIntInput | null;
  videoBitRate?: ModelIntInput | null;
  frameRate?: ModelIntInput | null;
  cameraId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCameraQualitySettingsConditionInput | null> | null;
  or?: Array<ModelCameraQualitySettingsConditionInput | null> | null;
  not?: ModelCameraQualitySettingsConditionInput | null;
};

export type UpdateCameraQualitySettingsInput = {
  id: string;
  width?: number | null;
  height?: number | null;
  videoBitRate?: number | null;
  frameRate?: number | null;
  monitoringCenterId?: string | null;
  cameraId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteCameraQualitySettingsInput = {
  id: string;
};

export type CreateCameraSceneDetectionSettingsInput = {
  id?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
};

export type ModelCameraSceneDetectionSettingsConditionInput = {
  cameraId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCameraSceneDetectionSettingsConditionInput | null> | null;
  or?: Array<ModelCameraSceneDetectionSettingsConditionInput | null> | null;
  not?: ModelCameraSceneDetectionSettingsConditionInput | null;
};

export type CameraSceneDetectionSettings = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: ModelCameraSceneDetectionThresholdConnection | null;
};

export type ModelCameraSceneDetectionThresholdConnection = {
  __typename: "ModelCameraSceneDetectionThresholdConnection";
  items: Array<CameraSceneDetectionThreshold | null>;
  nextToken?: string | null;
};

export type CameraSceneDetectionThreshold = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraSceneDetectionSettingsInput = {
  id: string;
  cameraId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt?: string | null;
};

export type DeleteCameraSceneDetectionSettingsInput = {
  id: string;
};

export type CreateCameraSceneDetectionThresholdInput = {
  id?: string | null;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
};

export type ModelCameraSceneDetectionThresholdConditionInput = {
  cameraId?: ModelIDInput | null;
  cameraSceneDetectionSettingsId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  objectName?: ModelStringInput | null;
  baselineValue?: ModelFloatInput | null;
  thresholdValue?: ModelFloatInput | null;
  and?: Array<ModelCameraSceneDetectionThresholdConditionInput | null> | null;
  or?: Array<ModelCameraSceneDetectionThresholdConditionInput | null> | null;
  not?: ModelCameraSceneDetectionThresholdConditionInput | null;
};

export type UpdateCameraSceneDetectionThresholdInput = {
  id: string;
  cameraId?: string | null;
  cameraSceneDetectionSettingsId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  objectName?: string | null;
  baselineValue?: number | null;
  thresholdValue?: number | null;
};

export type DeleteCameraSceneDetectionThresholdInput = {
  id: string;
};

export type CreateCameraSceneDetectionResultInput = {
  id?: string | null;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
};

export type ModelCameraSceneDetectionResultConditionInput = {
  cameraId?: ModelIDInput | null;
  passing?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCameraSceneDetectionResultConditionInput | null> | null;
  or?: Array<ModelCameraSceneDetectionResultConditionInput | null> | null;
  not?: ModelCameraSceneDetectionResultConditionInput | null;
};

export type CameraSceneDetectionResult = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: ModelCameraSceneDetectionResultItemConnection | null;
};

export type ModelCameraSceneDetectionResultItemConnection = {
  __typename: "ModelCameraSceneDetectionResultItemConnection";
  items: Array<CameraSceneDetectionResultItem | null>;
  nextToken?: string | null;
};

export type CameraSceneDetectionResultItem = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraSceneDetectionResultInput = {
  id: string;
  cameraId?: string | null;
  passing?: boolean | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type DeleteCameraSceneDetectionResultInput = {
  id: string;
};

export type CreateCameraSceneDetectionResultItemInput = {
  id?: string | null;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
};

export type ModelCameraSceneDetectionResultItemConditionInput = {
  cameraSceneDetectionResultId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  objectName?: ModelStringInput | null;
  thresholdValue?: ModelFloatInput | null;
  actualValue?: ModelFloatInput | null;
  and?: Array<ModelCameraSceneDetectionResultItemConditionInput | null> | null;
  or?: Array<ModelCameraSceneDetectionResultItemConditionInput | null> | null;
  not?: ModelCameraSceneDetectionResultItemConditionInput | null;
};

export type UpdateCameraSceneDetectionResultItemInput = {
  id: string;
  cameraSceneDetectionResultId?: string | null;
  cameraId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  objectName?: string | null;
  thresholdValue?: number | null;
  actualValue?: number | null;
};

export type DeleteCameraSceneDetectionResultItemInput = {
  id: string;
};

export type CreateCameraCredentialsInput = {
  id?: string | null;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
};

export type ModelCameraCredentialsConditionInput = {
  name?: ModelStringInput | null;
  password?: ModelStringInput | null;
  cameraId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCameraCredentialsConditionInput | null> | null;
  or?: Array<ModelCameraCredentialsConditionInput | null> | null;
  not?: ModelCameraCredentialsConditionInput | null;
};

export type CameraCredentials = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraCredentialsInput = {
  id: string;
  name?: string | null;
  password?: string | null;
  cameraId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteCameraCredentialsInput = {
  id: string;
};

export type CreateCameraAccessUrlsInput = {
  id?: string | null;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
};

export type ModelCameraAccessUrlsConditionInput = {
  dashStream?: ModelStringInput | null;
  hlsStream?: ModelStringInput | null;
  http?: ModelStringInput | null;
  rtmpStream?: ModelStringInput | null;
  rtsp?: ModelStringInput | null;
  snapShot?: ModelStringInput | null;
  webRtc?: ModelStringInput | null;
  cameraId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCameraAccessUrlsConditionInput | null> | null;
  or?: Array<ModelCameraAccessUrlsConditionInput | null> | null;
  not?: ModelCameraAccessUrlsConditionInput | null;
};

export type CameraAccessUrls = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraAccessUrlsInput = {
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteCameraAccessUrlsInput = {
  id: string;
};

export type CreateCameraImmixConfigInput = {
  id?: string | null;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelCameraImmixConfigConditionInput = {
  cameraId?: ModelIDInput | null;
  vmsCameraId?: ModelStringInput | null;
  vmsWebhookId?: ModelStringInput | null;
  immixEmail?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelCameraImmixConfigConditionInput | null> | null;
  or?: Array<ModelCameraImmixConfigConditionInput | null> | null;
  not?: ModelCameraImmixConfigConditionInput | null;
};

export type CameraImmixConfig = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraImmixConfigInput = {
  id: string;
  cameraId?: string | null;
  vmsCameraId?: string | null;
  vmsWebhookId?: string | null;
  immixEmail?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteCameraImmixConfigInput = {
  id: string;
};

export type CreateActivityLogInput = {
  id?: string | null;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
};

export enum ActivityLogType {
  Info = "Info",
  Failure = "Failure"
}

export enum ActivityLogSubSystem {
  Arming = "Arming",
  Device = "Device",
  Automation = "Automation"
}

export enum ActivityLogEvent {
  Armed = "Armed",
  Disarmed = "Disarmed",
  Exception = "Exception",
  TroubleshootingURLRequest = "TroubleshootingURLRequest",
  Webhook = "Webhook",
  Audio = "Audio",
  SMS = "SMS",
  Email = "Email",
  Snooze = "Snooze",
  UnSnooze = "UnSnooze"
}

export type ModelActivityLogConditionInput = {
  integratorId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  modifiedOn?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  type?: ModelActivityLogTypeInput | null;
  subSystem?: ModelActivityLogSubSystemInput | null;
  event?: ModelActivityLogEventInput | null;
  reference?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelActivityLogConditionInput | null> | null;
  or?: Array<ModelActivityLogConditionInput | null> | null;
  not?: ModelActivityLogConditionInput | null;
};

export type ModelActivityLogTypeInput = {
  eq?: ActivityLogType | null;
  ne?: ActivityLogType | null;
};

export type ModelActivityLogSubSystemInput = {
  eq?: ActivityLogSubSystem | null;
  ne?: ActivityLogSubSystem | null;
};

export type ModelActivityLogEventInput = {
  eq?: ActivityLogEvent | null;
  ne?: ActivityLogEvent | null;
};

export type ActivityLog = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type UpdateActivityLogInput = {
  id: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  customerId?: string | null;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  type?: ActivityLogType | null;
  subSystem?: ActivityLogSubSystem | null;
  event?: ActivityLogEvent | null;
  reference?: string | null;
  description?: string | null;
};

export type DeleteActivityLogInput = {
  id: string;
};

export type CreateSignalTestLogInput = {
  id?: string | null;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt?: string | null;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<CameraEventObjectInput | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
};

export type CameraEventObjectInput = {
  object: CameraEventObjectType;
  confidence: number;
};

export enum ActiveInactive {
  Active = "Active",
  Inactive = "Inactive"
}

export type ModelSignalTestLogConditionInput = {
  cameraId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  vmsEventId?: ModelStringInput | null;
  starred?: ModelActiveInactiveInput | null;
  thumbnailFilename?: ModelStringInput | null;
  and?: Array<ModelSignalTestLogConditionInput | null> | null;
  or?: Array<ModelSignalTestLogConditionInput | null> | null;
  not?: ModelSignalTestLogConditionInput | null;
};

export type ModelActiveInactiveInput = {
  eq?: ActiveInactive | null;
  ne?: ActiveInactive | null;
};

export type SignalTestLog = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<CameraEventObject | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type CameraEventObject = {
  __typename: "CameraEventObject";
  object: CameraEventObjectType;
  confidence: number;
};

export type UpdateSignalTestLogInput = {
  id: string;
  cameraId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  createdAt?: string | null;
  modifiedBy?: string | null;
  vmsEventId?: string | null;
  objectsFound?: Array<CameraEventObjectInput | null> | null;
  starred?: ActiveInactive | null;
  thumbnailFilename?: string | null;
};

export type DeleteSignalTestLogInput = {
  id: string;
};

export type CreateAuditLogInput = {
  id?: string | null;
  partition: string;
  monitoringCenterId: string;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
};

export enum AuditLogArea {
  Backend = "Backend",
  Frontend = "Frontend"
}

export enum AuditLogAction {
  Add = "Add",
  Update = "Update",
  Delete = "Delete",
  Navigate = "Navigate",
  Click = "Click"
}

export type ModelAuditLogConditionInput = {
  partition?: ModelStringInput | null;
  modifiedOn?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  area?: ModelAuditLogAreaInput | null;
  action?: ModelAuditLogActionInput | null;
  entityModel?: ModelStringInput | null;
  entityKey?: ModelStringInput | null;
  description?: ModelStringInput | null;
  newImage?: ModelStringInput | null;
  oldImage?: ModelStringInput | null;
  and?: Array<ModelAuditLogConditionInput | null> | null;
  or?: Array<ModelAuditLogConditionInput | null> | null;
  not?: ModelAuditLogConditionInput | null;
};

export type ModelAuditLogAreaInput = {
  eq?: AuditLogArea | null;
  ne?: AuditLogArea | null;
};

export type ModelAuditLogActionInput = {
  eq?: AuditLogAction | null;
  ne?: AuditLogAction | null;
};

export type AuditLog = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type UpdateAuditLogInput = {
  id: string;
  partition?: string | null;
  monitoringCenterId?: string | null;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  area?: AuditLogArea | null;
  action?: AuditLogAction | null;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
};

export type DeleteAuditLogInput = {
  id: string;
};

export type CreateIncidentActionDetailInput = {
  id?: string | null;
  incidentId: string;
  monitoringCenterId: string;
  createdAt?: string | null;
  modifiedBy: string;
  description?: string | null;
};

export type ModelIncidentActionDetailConditionInput = {
  incidentId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelIncidentActionDetailConditionInput | null> | null;
  or?: Array<ModelIncidentActionDetailConditionInput | null> | null;
  not?: ModelIncidentActionDetailConditionInput | null;
};

export type IncidentActionDetail = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type UpdateIncidentActionDetailInput = {
  id: string;
  incidentId?: string | null;
  monitoringCenterId?: string | null;
  createdAt?: string | null;
  modifiedBy?: string | null;
  description?: string | null;
};

export type DeleteIncidentActionDetailInput = {
  id: string;
};

export type CreateSOPInput = {
  id?: string | null;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type ModelSOPConditionInput = {
  siteId?: ModelIDInput | null;
  eventType?: ModelEventTypeInput | null;
  eventSOPs?: ModelEventOpListInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSOPConditionInput | null> | null;
  or?: Array<ModelSOPConditionInput | null> | null;
  not?: ModelSOPConditionInput | null;
};

export type ModelEventOpListInput = {
  eq?: Array<EventOp | null> | null;
  ne?: Array<EventOp | null> | null;
  contains?: EventOp | null;
  notContains?: EventOp | null;
};

export type UpdateSOPInput = {
  id: string;
  siteId?: string | null;
  eventType?: EventType | null;
  eventSOPs?: Array<EventOp> | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteSOPInput = {
  id: string;
};

export type CreateRspndrEventsInput = {
  id?: string | null;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type ModelRspndrEventsConditionInput = {
  request_id?: ModelIDInput | null;
  tenant_id?: ModelStringInput | null;
  error?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelRspndrEventsConditionInput | null> | null;
  or?: Array<ModelRspndrEventsConditionInput | null> | null;
  not?: ModelRspndrEventsConditionInput | null;
};

export type RspndrEvents = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRspndrEventsInput = {
  id: string;
  request_id?: string | null;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type DeleteRspndrEventsInput = {
  id: string;
};

export type CreateRspndrIncidentInput = {
  id?: string | null;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt?: string | null;
};

export enum RspndrIncidentStatus {
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  TAKEN = "TAKEN",
  ARRIVED = "ARRIVED",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
  GROUNDING_REQUESTED = "GROUNDING_REQUESTED",
  GROUNDING_REQUEST_CONFIRMED = "GROUNDING_REQUEST_CONFIRMED",
  GROUNDING_REQUEST_DECLINED = "GROUNDING_REQUEST_DECLINED",
  GROUNDING_REQUEST_CONFIRM_ACKED = "GROUNDING_REQUEST_CONFIRM_ACKED",
  GROUNDING_REQUEST_DECLINE_ACKED = "GROUNDING_REQUEST_DECLINE_ACKED",
  GROUNDED = "GROUNDED",
  GROUNDING_ENDING = "GROUNDING_ENDING",
  GROUNDING_END_CONFIRMED = "GROUNDING_END_CONFIRMED",
  GROUNDING_END_DECLINED = "GROUNDING_END_DECLINED",
  GROUNDING_END_CONFIRM_ACKED = "GROUNDING_END_CONFIRM_ACKED",
  GROUNDING_END_DECLINE_ACKED = "GROUNDING_END_DECLINE_ACKED",
  UNKNOWN = "UNKNOWN",
  ERROR = "ERROR",
  NOTED = "NOTED"
}

export type ModelRspndrIncidentConditionInput = {
  incident_id?: ModelStringInput | null;
  address1?: ModelStringInput | null;
  address2?: ModelStringInput | null;
  arrived_at?: ModelStringInput | null;
  billed_to?: ModelRspndrBilledToInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  created_at?: ModelStringInput | null;
  customer_number?: ModelStringInput | null;
  dealer?: ModelStringInput | null;
  eta_at?: ModelStringInput | null;
  ext_username?: ModelStringInput | null;
  finished_at?: ModelStringInput | null;
  grounded_at?: ModelStringInput | null;
  grounding_end_confirm_acked_at?: ModelStringInput | null;
  grounding_end_confirmed_at?: ModelStringInput | null;
  grounding_end_decline_acked_at?: ModelStringInput | null;
  grounding_end_declined_at?: ModelStringInput | null;
  grounding_ended_at?: ModelStringInput | null;
  grounding_ending_at?: ModelStringInput | null;
  grounding_request_confirm_acked_at?: ModelStringInput | null;
  grounding_request_confirmed_at?: ModelStringInput | null;
  grounding_request_decline_acked_at?: ModelStringInput | null;
  grounding_request_declined_at?: ModelStringInput | null;
  grounding_requested_at?: ModelStringInput | null;
  grounding_terminate_acked_at?: ModelStringInput | null;
  grounding_terminating_at?: ModelStringInput | null;
  kind?: ModelRspndrServiceKindInput | null;
  lat?: ModelFloatInput | null;
  lng?: ModelFloatInput | null;
  master_account_number?: ModelStringInput | null;
  modified_at?: ModelStringInput | null;
  name?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  postal_code?: ModelStringInput | null;
  state?: ModelStringInput | null;
  status?: ModelRspndrIncidentStatusInput | null;
  taken_at?: ModelStringInput | null;
  tenant_id?: ModelStringInput | null;
  timezone?: ModelStringInput | null;
  triggered_at?: ModelStringInput | null;
  type?: ModelStringInput | null;
  zone?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  integratorId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelRspndrIncidentConditionInput | null> | null;
  or?: Array<ModelRspndrIncidentConditionInput | null> | null;
  not?: ModelRspndrIncidentConditionInput | null;
};

export type ModelRspndrBilledToInput = {
  eq?: RspndrBilledTo | null;
  ne?: RspndrBilledTo | null;
};

export type ModelRspndrServiceKindInput = {
  eq?: RspndrServiceKind | null;
  ne?: RspndrServiceKind | null;
};

export type ModelRspndrIncidentStatusInput = {
  eq?: RspndrIncidentStatus | null;
  ne?: RspndrIncidentStatus | null;
};

export type RspndrIncident = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: ModelRspndrIncidentCheckItemConnection | null;
  rules?: ModelRspndrIncidentGroundingRuleConnection | null;
  activities?: ModelRspndrActivityConnection | null;
};

export type ModelRspndrIncidentCheckItemConnection = {
  __typename: "ModelRspndrIncidentCheckItemConnection";
  items: Array<RspndrIncidentCheckItem | null>;
  nextToken?: string | null;
};

export type RspndrIncidentCheckItem = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelRspndrIncidentGroundingRuleConnection = {
  __typename: "ModelRspndrIncidentGroundingRuleConnection";
  items: Array<RspndrIncidentGroundingRule | null>;
  nextToken?: string | null;
};

export type RspndrIncidentGroundingRule = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelRspndrActivityConnection = {
  __typename: "ModelRspndrActivityConnection";
  items: Array<RspndrActivity | null>;
  nextToken?: string | null;
};

export type RspndrActivity = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type UpdateRspndrIncidentInput = {
  id: string;
  incident_id?: string | null;
  address1?: string | null;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city?: string | null;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code?: string | null;
  state?: string | null;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id?: string | null;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  siteId?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type DeleteRspndrIncidentInput = {
  id: string;
};

export type CreateRspndrIncidentCheckItemInput = {
  id?: string | null;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
};

export type ModelRspndrIncidentCheckItemConditionInput = {
  incidentId?: ModelIDInput | null;
  rspndrIncidentId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  descriptionTranslated?: ModelStringInput | null;
  descriptionSanitized?: ModelStringInput | null;
  image_required?: ModelBooleanInput | null;
  image_url?: ModelStringInput | null;
  order?: ModelIntInput | null;
  required?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRspndrIncidentCheckItemConditionInput | null> | null;
  or?: Array<ModelRspndrIncidentCheckItemConditionInput | null> | null;
  not?: ModelRspndrIncidentCheckItemConditionInput | null;
};

export type UpdateRspndrIncidentCheckItemInput = {
  id: string;
  incidentId?: string | null;
  rspndrIncidentId?: string | null;
  monitoringCenterId?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy?: string | null;
};

export type DeleteRspndrIncidentCheckItemInput = {
  id: string;
};

export type CreateRspndrIncidentGroundingRuleInput = {
  id?: string | null;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
};

export type ModelRspndrIncidentGroundingRuleConditionInput = {
  incidentId?: ModelIDInput | null;
  rspndrIncidentId?: ModelIDInput | null;
  call_police?: ModelBooleanInput | null;
  expires_at?: ModelStringInput | null;
  leave_when?: ModelRspndrLeaveWhenInput | null;
  max_duration?: ModelStringInput | null;
  order?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRspndrIncidentGroundingRuleConditionInput | null> | null;
  or?: Array<ModelRspndrIncidentGroundingRuleConditionInput | null> | null;
  not?: ModelRspndrIncidentGroundingRuleConditionInput | null;
};

export type ModelRspndrLeaveWhenInput = {
  eq?: RspndrLeaveWhen | null;
  ne?: RspndrLeaveWhen | null;
};

export type UpdateRspndrIncidentGroundingRuleInput = {
  id: string;
  incidentId?: string | null;
  rspndrIncidentId?: string | null;
  monitoringCenterId?: string | null;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy?: string | null;
};

export type DeleteRspndrIncidentGroundingRuleInput = {
  id: string;
};

export type CreateRspndrActivityInput = {
  id?: string | null;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
};

export type ModelRspndrActivityConditionInput = {
  tenant_id?: ModelStringInput | null;
  request_id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  rspndrIncidentId?: ModelIDInput | null;
  error?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  type?: ModelRspndrIncidentStatusInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  and?: Array<ModelRspndrActivityConditionInput | null> | null;
  or?: Array<ModelRspndrActivityConditionInput | null> | null;
  not?: ModelRspndrActivityConditionInput | null;
};

export type UpdateRspndrActivityInput = {
  id: string;
  tenant_id?: string | null;
  request_id?: string | null;
  incidentId?: string | null;
  rspndrIncidentId?: string | null;
  monitoringCenterId?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  type?: RspndrIncidentStatus | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
};

export type DeleteRspndrActivityInput = {
  id: string;
};

export type CreateTrackTikIncidentInput = {
  id?: string | null;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt?: string | null;
  trackTikIncidentAdditionalDataToSendId?: string | null;
};

export enum TrackTikIncidentStatus {
  DISPATCH_REQUESTED = "DISPATCH_REQUESTED",
  CLOSED = "CLOSED"
}

export type ModelTrackTikIncidentConditionInput = {
  incidentId?: ModelIDInput | null;
  status?: ModelTrackTikIncidentStatusInput | null;
  reportTemplateId?: ModelIntInput | null;
  taskTypeId?: ModelIntInput | null;
  taskTypeName?: ModelStringInput | null;
  priority?: ModelTrackTikPriorityInput | null;
  trackTikId?: ModelIntInput | null;
  primaryCameraEventId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTrackTikIncidentConditionInput | null> | null;
  or?: Array<ModelTrackTikIncidentConditionInput | null> | null;
  not?: ModelTrackTikIncidentConditionInput | null;
};

export type ModelTrackTikIncidentStatusInput = {
  eq?: TrackTikIncidentStatus | null;
  ne?: TrackTikIncidentStatus | null;
};

export type ModelTrackTikPriorityInput = {
  eq?: TrackTikPriority | null;
  ne?: TrackTikPriority | null;
};

export type TrackTikIncident = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: ModelTrackTikActivityConnection | null;
  additionalDataToSend?: IncidentDataToShare | null;
};

export type ModelTrackTikActivityConnection = {
  __typename: "ModelTrackTikActivityConnection";
  items: Array<TrackTikActivity | null>;
  nextToken?: string | null;
};

export type TrackTikActivity = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type IncidentDataToShare = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTrackTikIncidentInput = {
  id: string;
  incidentId?: string | null;
  monitoringCenterId?: string | null;
  status?: TrackTikIncidentStatus | null;
  reportTemplateId?: number | null;
  taskTypeId?: number | null;
  taskTypeName?: string | null;
  priority?: TrackTikPriority | null;
  trackTikId?: number | null;
  primaryCameraEventId?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  trackTikIncidentAdditionalDataToSendId?: string | null;
};

export type DeleteTrackTikIncidentInput = {
  id: string;
};

export type CreateTrackTikActivityInput = {
  id?: string | null;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt?: string | null;
};

export type ModelTrackTikActivityConditionInput = {
  incidentId?: ModelIDInput | null;
  trackTikIncidentId?: ModelIDInput | null;
  notes?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTrackTikActivityConditionInput | null> | null;
  or?: Array<ModelTrackTikActivityConditionInput | null> | null;
  not?: ModelTrackTikActivityConditionInput | null;
};

export type UpdateTrackTikActivityInput = {
  id: string;
  incidentId?: string | null;
  trackTikIncidentId?: string | null;
  monitoringCenterId?: string | null;
  notes?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type DeleteTrackTikActivityInput = {
  id: string;
};

export type CreateRapidSOSIncidentInput = {
  id?: string | null;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  rapidSOSIncidentEventId: string;
  rapidSOSIncidentAdditionalDataToSendId?: string | null;
};

export enum RapidSOSCoverageType {
  Error = "Error",
  None = "None",
  Supplemental = "Supplemental",
  Full = "Full"
}

export enum RapidSOSIncidentStatus {
  NEW = "NEW",
  TIMEOUT = "TIMEOUT",
  IGNORED = "IGNORED",
  DISPATCH_REQUESTED = "DISPATCH_REQUESTED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  CANCELED = "CANCELED",
  ERROR = "ERROR",
  REJECTED = "REJECTED"
}

export type ModelRapidSOSIncidentConditionInput = {
  coverageType?: ModelRapidSOSCoverageTypeInput | null;
  alert_id?: ModelStringInput | null;
  covering_psap?: ModelStringInput | null;
  callflow?: ModelStringInput | null;
  zone_description?: ModelStringInput | null;
  alarm_permit_number?: ModelStringInput | null;
  visually_verified?: ModelBooleanInput | null;
  site_phone?: ModelStringInput | null;
  status?: ModelRapidSOSIncidentStatusInput | null;
  instructions?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  primaryCameraEventId?: ModelIDInput | null;
  siteIntegrationId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  and?: Array<ModelRapidSOSIncidentConditionInput | null> | null;
  or?: Array<ModelRapidSOSIncidentConditionInput | null> | null;
  not?: ModelRapidSOSIncidentConditionInput | null;
};

export type ModelRapidSOSCoverageTypeInput = {
  eq?: RapidSOSCoverageType | null;
  ne?: RapidSOSCoverageType | null;
};

export type ModelRapidSOSIncidentStatusInput = {
  eq?: RapidSOSIncidentStatus | null;
  ne?: RapidSOSIncidentStatus | null;
};

export type RapidSOSIncident = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: RapidSOSEventDetails;
  emergency_contacts?: ModelRapidSOSEmergencyContactConnection | null;
  activities?: ModelRapidSOSActivityConnection | null;
  attachments?: ModelRapidSOSAttachmentConnection | null;
  additionalDataToSend?: IncidentDataToShare | null;
};

export type RapidSOSEventDetails = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: RapidSOSLocation;
};

export type RapidSOSLocation = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: RapidSOSCivicAddress | null;
};

export type RapidSOSCivicAddress = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelRapidSOSEmergencyContactConnection = {
  __typename: "ModelRapidSOSEmergencyContactConnection";
  items: Array<RapidSOSEmergencyContact | null>;
  nextToken?: string | null;
};

export type RapidSOSEmergencyContact = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelRapidSOSActivityConnection = {
  __typename: "ModelRapidSOSActivityConnection";
  items: Array<RapidSOSActivity | null>;
  nextToken?: string | null;
};

export type RapidSOSActivity = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: RapidSOSLogMessage | null;
};

export type RapidSOSLogMessage = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelRapidSOSAttachmentConnection = {
  __typename: "ModelRapidSOSAttachmentConnection";
  items: Array<RapidSOSAttachment | null>;
  nextToken?: string | null;
};

export type RapidSOSAttachment = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRapidSOSIncidentInput = {
  id: string;
  coverageType?: RapidSOSCoverageType | null;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow?: string | null;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified?: boolean | null;
  site_phone?: string | null;
  status?: RapidSOSIncidentStatus | null;
  instructions?: string | null;
  createdAt?: string | null;
  modifiedBy?: string | null;
  primaryCameraEventId?: string | null;
  siteIntegrationId?: string | null;
  incidentId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  monitoringCenterId?: string | null;
  rapidSOSIncidentEventId?: string | null;
  rapidSOSIncidentAdditionalDataToSendId?: string | null;
};

export type DeleteRapidSOSIncidentInput = {
  id: string;
};

export type CreateRapidSOSEventDetailsInput = {
  id?: string | null;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  rapidSOSEventDetailsLocationId: string;
};

export type ModelRapidSOSEventDetailsConditionInput = {
  rapidSOSIncidentId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  descriptionTranslated?: ModelStringInput | null;
  descriptionSanitized?: ModelStringInput | null;
  emergency_type?: ModelRapidSOSEmergencyTypeInput | null;
  incident_time?: ModelStringInput | null;
  service_provider_name?: ModelStringInput | null;
  site_type?: ModelSiteTypeInput | null;
  source_id?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRapidSOSEventDetailsConditionInput | null> | null;
  or?: Array<ModelRapidSOSEventDetailsConditionInput | null> | null;
  not?: ModelRapidSOSEventDetailsConditionInput | null;
};

export type ModelRapidSOSEmergencyTypeInput = {
  eq?: RapidSOSEmergencyType | null;
  ne?: RapidSOSEmergencyType | null;
};

export type UpdateRapidSOSEventDetailsInput = {
  id: string;
  rapidSOSIncidentId?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type?: RapidSOSEmergencyType | null;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id?: string | null;
  modifiedBy?: string | null;
  monitoringCenterId?: string | null;
  rapidSOSEventDetailsLocationId?: string | null;
};

export type DeleteRapidSOSEventDetailsInput = {
  id: string;
};

export type CreateRapidSOSLocationInput = {
  id?: string | null;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  rapidSOSLocationCivicId?: string | null;
};

export type ModelRapidSOSLocationConditionInput = {
  rapidSOSIncidentId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRapidSOSLocationConditionInput | null> | null;
  or?: Array<ModelRapidSOSLocationConditionInput | null> | null;
  not?: ModelRapidSOSLocationConditionInput | null;
};

export type UpdateRapidSOSLocationInput = {
  id: string;
  rapidSOSIncidentId?: string | null;
  modifiedBy?: string | null;
  monitoringCenterId?: string | null;
  rapidSOSLocationCivicId?: string | null;
};

export type DeleteRapidSOSLocationInput = {
  id: string;
};

export type CreateRapidSOSCivicAddressInput = {
  id?: string | null;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
};

export type ModelRapidSOSCivicAddressConditionInput = {
  rapidSOSIncidentId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  street_1?: ModelStringInput | null;
  street_2?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  country?: ModelStringInput | null;
  zip_code?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRapidSOSCivicAddressConditionInput | null> | null;
  or?: Array<ModelRapidSOSCivicAddressConditionInput | null> | null;
  not?: ModelRapidSOSCivicAddressConditionInput | null;
};

export type UpdateRapidSOSCivicAddressInput = {
  id: string;
  rapidSOSIncidentId?: string | null;
  name?: string | null;
  street_1?: string | null;
  street_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip_code?: string | null;
  modifiedBy?: string | null;
  monitoringCenterId?: string | null;
};

export type DeleteRapidSOSCivicAddressInput = {
  id: string;
};

export type CreateRapidSOSEmergencyContactInput = {
  id?: string | null;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
};

export type ModelRapidSOSEmergencyContactConditionInput = {
  rapidSOSIncidentId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRapidSOSEmergencyContactConditionInput | null> | null;
  or?: Array<ModelRapidSOSEmergencyContactConditionInput | null> | null;
  not?: ModelRapidSOSEmergencyContactConditionInput | null;
};

export type UpdateRapidSOSEmergencyContactInput = {
  id: string;
  rapidSOSIncidentId?: string | null;
  name?: string | null;
  phone?: string | null;
  modifiedBy?: string | null;
  monitoringCenterId?: string | null;
};

export type DeleteRapidSOSEmergencyContactInput = {
  id: string;
};

export type CreateRapidSOSActivityInput = {
  id?: string | null;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  rapidSOSActivityLogMessageId?: string | null;
};

export type ModelRapidSOSActivityConditionInput = {
  incidentId?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  error?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  and?: Array<ModelRapidSOSActivityConditionInput | null> | null;
  or?: Array<ModelRapidSOSActivityConditionInput | null> | null;
  not?: ModelRapidSOSActivityConditionInput | null;
};

export type UpdateRapidSOSActivityInput = {
  id: string;
  incidentId?: string | null;
  siteIntegrationId?: string | null;
  rapidSOSIncidentId?: string | null;
  monitoringCenterId?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  rapidSOSActivityLogMessageId?: string | null;
};

export type DeleteRapidSOSActivityInput = {
  id: string;
};

export type CreateRapidSOSLogMessageInput = {
  id?: string | null;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
};

export type ModelRapidSOSLogMessageConditionInput = {
  incidentId?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  type?: ModelRapidSOSLogMessageTypeInput | null;
  modifiedBy?: ModelStringInput | null;
  writtenBy?: ModelStringInput | null;
  message?: ModelStringInput | null;
  messageTranslated?: ModelStringInput | null;
  messageSanitized?: ModelStringInput | null;
  and?: Array<ModelRapidSOSLogMessageConditionInput | null> | null;
  or?: Array<ModelRapidSOSLogMessageConditionInput | null> | null;
  not?: ModelRapidSOSLogMessageConditionInput | null;
};

export type ModelRapidSOSLogMessageTypeInput = {
  eq?: RapidSOSLogMessageType | null;
  ne?: RapidSOSLogMessageType | null;
};

export type UpdateRapidSOSLogMessageInput = {
  id: string;
  incidentId?: string | null;
  siteIntegrationId?: string | null;
  rapidSOSIncidentId?: string | null;
  monitoringCenterId?: string | null;
  type?: RapidSOSLogMessageType | null;
  modifiedBy?: string | null;
  writtenBy?: string | null;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
};

export type DeleteRapidSOSLogMessageInput = {
  id: string;
};

export type CreateRapidSOSAttachmentInput = {
  id?: string | null;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
};

export type ModelRapidSOSAttachmentConditionInput = {
  incidentId?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  and?: Array<ModelRapidSOSAttachmentConditionInput | null> | null;
  or?: Array<ModelRapidSOSAttachmentConditionInput | null> | null;
  not?: ModelRapidSOSAttachmentConditionInput | null;
};

export type UpdateRapidSOSAttachmentInput = {
  id: string;
  incidentId?: string | null;
  siteIntegrationId?: string | null;
  rapidSOSIncidentId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  fileName?: string | null;
};

export type DeleteRapidSOSAttachmentInput = {
  id: string;
};

export type CreateIncidentCodeDetailInput = {
  id?: string | null;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  incidentCodeDetailIncidentDataToShareId?: string | null;
};

export type ModelIncidentCodeDetailConditionInput = {
  incidentId?: ModelIDInput | null;
  publicCode?: ModelStringInput | null;
  shareUrl?: ModelStringInput | null;
  locationSid?: ModelIDInput | null;
  deviceSid?: ModelIDInput | null;
  eventDate?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelIncidentCodeDetailConditionInput | null> | null;
  or?: Array<ModelIncidentCodeDetailConditionInput | null> | null;
  not?: ModelIncidentCodeDetailConditionInput | null;
};

export type IncidentCodeDetail = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: IncidentDataToShare | null;
};

export type UpdateIncidentCodeDetailInput = {
  id: string;
  incidentId?: string | null;
  monitoringCenterId?: string | null;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid?: string | null;
  deviceSid?: string | null;
  eventDate?: string | null;
  modifiedBy?: string | null;
  incidentCodeDetailIncidentDataToShareId?: string | null;
};

export type DeleteIncidentCodeDetailInput = {
  id: string;
};

export type CreateIncidentShareSettingsInput = {
  id?: string | null;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  incidentShareSettingsIncidentDataToShareId?: string | null;
};

export type ModelIncidentShareSettingsConditionInput = {
  incidentId?: ModelIDInput | null;
  code?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  expiration?: ModelStringInput | null;
  and?: Array<ModelIncidentShareSettingsConditionInput | null> | null;
  or?: Array<ModelIncidentShareSettingsConditionInput | null> | null;
  not?: ModelIncidentShareSettingsConditionInput | null;
};

export type IncidentShareSettings = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: IncidentDataToShare | null;
};

export type UpdateIncidentShareSettingsInput = {
  id: string;
  incidentId?: string | null;
  monitoringCenterId?: string | null;
  code?: string | null;
  modifiedBy?: string | null;
  expiration?: string | null;
  incidentShareSettingsIncidentDataToShareId?: string | null;
};

export type DeleteIncidentShareSettingsInput = {
  id: string;
};

export type CreateIncidentDataToShareInput = {
  id?: string | null;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
};

export type ModelIncidentDataToShareConditionInput = {
  incidentId?: ModelIDInput | null;
  shareType?: ModelIncidentShareTypeInput | null;
  externalIncidentNotes?: ModelBooleanInput | null;
  externalSiteCriticalNotes?: ModelBooleanInput | null;
  externalClientCriticalNotes?: ModelBooleanInput | null;
  completedActionItems?: ModelBooleanInput | null;
  objectsFound?: ModelBooleanInput | null;
  alarmStationInfo?: ModelBooleanInput | null;
  customerContactInfo?: ModelBooleanInput | null;
  videoFeedUrls?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  attachmentsList?: ModelStringInput | null;
  and?: Array<ModelIncidentDataToShareConditionInput | null> | null;
  or?: Array<ModelIncidentDataToShareConditionInput | null> | null;
  not?: ModelIncidentDataToShareConditionInput | null;
};

export type ModelIncidentShareTypeInput = {
  eq?: IncidentShareType | null;
  ne?: IncidentShareType | null;
};

export type UpdateIncidentDataToShareInput = {
  id: string;
  incidentId?: string | null;
  monitoringCenterId?: string | null;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy?: string | null;
  attachmentsList?: Array<string> | null;
};

export type DeleteIncidentDataToShareInput = {
  id: string;
};

export type CreateIncidentShareEntryInput = {
  id?: string | null;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  incidentShareEntrySiteId?: string | null;
  incidentShareEntryIntegratorId?: string | null;
};

export type ModelIncidentShareEntryConditionInput = {
  incidentDataToShareId?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  code?: ModelStringInput | null;
  shareType?: ModelIncidentShareTypeInput | null;
  expiration?: ModelStringInput | null;
  alarmsCentralStation?: ModelStringInput | null;
  alarmsPermitNum?: ModelStringInput | null;
  alarmsCSID?: ModelStringInput | null;
  attachmentsList?: ModelStringInput | null;
  incidentOpenTime?: ModelStringInput | null;
  incidentClosedTime?: ModelStringInput | null;
  externalSiteNotes?: ModelStringInput | null;
  externalClientNotes?: ModelStringInput | null;
  and?: Array<ModelIncidentShareEntryConditionInput | null> | null;
  or?: Array<ModelIncidentShareEntryConditionInput | null> | null;
  not?: ModelIncidentShareEntryConditionInput | null;
};

export type IncidentShareEntry = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: IncidentShareSite | null;
  integrator?: IncidentShareIntegrator | null;
  notes?: ModelIncidentShareEntyNoteConnection | null;
  events?: ModelIncidentShareEventConnection | null;
  contactInformation?: ModelIncidentShareContactInformationConnection | null;
};

export type IncidentShareSite = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type IncidentShareIntegrator = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelIncidentShareEntyNoteConnection = {
  __typename: "ModelIncidentShareEntyNoteConnection";
  items: Array<IncidentShareEntyNote | null>;
  nextToken?: string | null;
};

export type IncidentShareEntyNote = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelIncidentShareEventConnection = {
  __typename: "ModelIncidentShareEventConnection";
  items: Array<IncidentShareEvent | null>;
  nextToken?: string | null;
};

export type IncidentShareEvent = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: ModelIncidentShareActionItemsConnection | null;
};

export type ModelIncidentShareActionItemsConnection = {
  __typename: "ModelIncidentShareActionItemsConnection";
  items: Array<IncidentShareActionItems | null>;
  nextToken?: string | null;
};

export type IncidentShareActionItems = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelIncidentShareContactInformationConnection = {
  __typename: "ModelIncidentShareContactInformationConnection";
  items: Array<IncidentShareContactInformation | null>;
  nextToken?: string | null;
};

export type IncidentShareContactInformation = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export enum IncidentShareContactType {
  Site = "Site",
  Client = "Client",
  Emergency = "Emergency"
}

export type UpdateIncidentShareEntryInput = {
  id: string;
  incidentDataToShareId?: string | null;
  incidentId?: string | null;
  code?: string | null;
  shareType?: IncidentShareType | null;
  expiration?: string | null;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  incidentShareEntrySiteId?: string | null;
  incidentShareEntryIntegratorId?: string | null;
};

export type DeleteIncidentShareEntryInput = {
  id: string;
};

export type CreateIncidentShareSiteInput = {
  id?: string | null;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
};

export type ModelIncidentShareSiteConditionInput = {
  name?: ModelStringInput | null;
  addressOne?: ModelStringInput | null;
  addressTwo?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  country?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  and?: Array<ModelIncidentShareSiteConditionInput | null> | null;
  or?: Array<ModelIncidentShareSiteConditionInput | null> | null;
  not?: ModelIncidentShareSiteConditionInput | null;
};

export type UpdateIncidentShareSiteInput = {
  id: string;
  name?: string | null;
  addressOne?: string | null;
  addressTwo?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
};

export type DeleteIncidentShareSiteInput = {
  id: string;
};

export type CreateIncidentShareIntegratorInput = {
  id?: string | null;
  name: string;
  logoPath?: string | null;
};

export type ModelIncidentShareIntegratorConditionInput = {
  name?: ModelStringInput | null;
  logoPath?: ModelStringInput | null;
  and?: Array<ModelIncidentShareIntegratorConditionInput | null> | null;
  or?: Array<ModelIncidentShareIntegratorConditionInput | null> | null;
  not?: ModelIncidentShareIntegratorConditionInput | null;
};

export type UpdateIncidentShareIntegratorInput = {
  id: string;
  name?: string | null;
  logoPath?: string | null;
};

export type DeleteIncidentShareIntegratorInput = {
  id: string;
};

export type CreateIncidentShareEntryAccessInput = {
  id?: string | null;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
};

export type ModelIncidentShareEntryAccessConditionInput = {
  incidentShareEntryId?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  code?: ModelStringInput | null;
  success?: ModelBooleanInput | null;
  accessDate?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  and?: Array<ModelIncidentShareEntryAccessConditionInput | null> | null;
  or?: Array<ModelIncidentShareEntryAccessConditionInput | null> | null;
  not?: ModelIncidentShareEntryAccessConditionInput | null;
};

export type IncidentShareEntryAccess = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareEntryAccessInput = {
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code?: string | null;
  success?: boolean | null;
  accessDate?: string | null;
  ipAddress?: string | null;
};

export type DeleteIncidentShareEntryAccessInput = {
  id: string;
};

export type CreateIncidentShareEntyNoteInput = {
  id?: string | null;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
};

export type ModelIncidentShareEntyNoteConditionInput = {
  incidentId?: ModelIDInput | null;
  incidentNoteId?: ModelIDInput | null;
  incidentShareEntryId?: ModelIDInput | null;
  note?: ModelStringInput | null;
  noteCreatedAt?: ModelStringInput | null;
  and?: Array<ModelIncidentShareEntyNoteConditionInput | null> | null;
  or?: Array<ModelIncidentShareEntyNoteConditionInput | null> | null;
  not?: ModelIncidentShareEntyNoteConditionInput | null;
};

export type UpdateIncidentShareEntyNoteInput = {
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId?: string | null;
  note?: string | null;
  noteCreatedAt?: string | null;
};

export type DeleteIncidentShareEntyNoteInput = {
  id: string;
};

export type CreateIncidentShareEventInput = {
  id?: string | null;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
};

export type ModelIncidentShareEventConditionInput = {
  cameraEventId?: ModelIDInput | null;
  startTime?: ModelStringInput | null;
  vmsCameraName?: ModelStringInput | null;
  videoFeedUrl?: ModelStringInput | null;
  eventType?: ModelEventTypeInput | null;
  disposition?: ModelDispositionInput | null;
  triggerType?: ModelCameraEventTriggerTypeInput | null;
  objectsFound?: ModelCameraEventObjectTypeListInput | null;
  incidentShareEntryId?: ModelIDInput | null;
  cameraLatitude?: ModelFloatInput | null;
  cameraLongitude?: ModelFloatInput | null;
  and?: Array<ModelIncidentShareEventConditionInput | null> | null;
  or?: Array<ModelIncidentShareEventConditionInput | null> | null;
  not?: ModelIncidentShareEventConditionInput | null;
};

export type UpdateIncidentShareEventInput = {
  id: string;
  cameraEventId?: string | null;
  startTime?: string | null;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType?: EventType | null;
  disposition?: Disposition | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId?: string | null;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
};

export type DeleteIncidentShareEventInput = {
  id: string;
};

export type CreateIncidentShareActionItemsInput = {
  id?: string | null;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
};

export type ModelIncidentShareActionItemsConditionInput = {
  incidentProcedureTaskId?: ModelIDInput | null;
  incidentShareEventId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  completedAt?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelIncidentShareActionItemsConditionInput | null> | null;
  or?: Array<ModelIncidentShareActionItemsConditionInput | null> | null;
  not?: ModelIncidentShareActionItemsConditionInput | null;
};

export type UpdateIncidentShareActionItemsInput = {
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId?: string | null;
  order?: number | null;
  completedAt?: string | null;
  description?: string | null;
};

export type DeleteIncidentShareActionItemsInput = {
  id: string;
};

export type CreateIncidentShareContactInformationInput = {
  id?: string | null;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
};

export type ModelIncidentShareContactInformationConditionInput = {
  incidentShareEntryId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  ext?: ModelStringInput | null;
  email?: ModelStringInput | null;
  type?: ModelIncidentShareContactTypeInput | null;
  and?: Array<ModelIncidentShareContactInformationConditionInput | null> | null;
  or?: Array<ModelIncidentShareContactInformationConditionInput | null> | null;
  not?: ModelIncidentShareContactInformationConditionInput | null;
};

export type ModelIncidentShareContactTypeInput = {
  eq?: IncidentShareContactType | null;
  ne?: IncidentShareContactType | null;
};

export type UpdateIncidentShareContactInformationInput = {
  id: string;
  incidentShareEntryId?: string | null;
  name?: string | null;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  type?: IncidentShareContactType | null;
};

export type DeleteIncidentShareContactInformationInput = {
  id: string;
};

export type CreateCallNoticeInput = {
  id?: string | null;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt?: string | null;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
};

export type ModelCallNoticeConditionInput = {
  fromNumber?: ModelStringInput | null;
  toNumber?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  answeredTime?: ModelStringInput | null;
  answeredBy?: ModelStringInput | null;
  twilioCallSid?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCallNoticeConditionInput | null> | null;
  or?: Array<ModelCallNoticeConditionInput | null> | null;
  not?: ModelCallNoticeConditionInput | null;
};

export type CallNotice = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type UpdateCallNoticeInput = {
  id: string;
  fromNumber?: string | null;
  toNumber?: string | null;
  monitoringCenterId?: string | null;
  createdAt?: string | null;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid?: string | null;
  modifiedBy?: string | null;
};

export type DeleteCallNoticeInput = {
  id: string;
};

export type CreateCallLogInput = {
  id?: string | null;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt?: string | null;
  callingUser?: string | null;
  modifiedBy?: string | null;
};

export type ModelCallLogConditionInput = {
  phoneNumber?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  callEnd?: ModelStringInput | null;
  storageAudioUrl?: ModelStringInput | null;
  storageTranscriptUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  callingUser?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCallLogConditionInput | null> | null;
  or?: Array<ModelCallLogConditionInput | null> | null;
  not?: ModelCallLogConditionInput | null;
};

export type CallLog = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type UpdateCallLogInput = {
  id: string;
  phoneNumber?: string | null;
  monitoringCenterId?: string | null;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt?: string | null;
  callingUser?: string | null;
  modifiedBy?: string | null;
};

export type DeleteCallLogInput = {
  id: string;
};

export type CreateSMSLogInput = {
  id?: string | null;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt?: string | null;
  textingUser?: string | null;
  modifiedBy: string;
};

export type ModelSMSLogConditionInput = {
  twilioSid?: ModelStringInput | null;
  toNumber?: ModelStringInput | null;
  fromNumber?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  message?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  textingUser?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelSMSLogConditionInput | null> | null;
  or?: Array<ModelSMSLogConditionInput | null> | null;
  not?: ModelSMSLogConditionInput | null;
};

export type SMSLog = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type UpdateSMSLogInput = {
  id: string;
  twilioSid?: string | null;
  toNumber?: string | null;
  fromNumber?: string | null;
  monitoringCenterId?: string | null;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt?: string | null;
  textingUser?: string | null;
  modifiedBy?: string | null;
};

export type DeleteSMSLogInput = {
  id: string;
};

export type CreateEmailLogInput = {
  id?: string | null;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt?: string | null;
  sendingUser?: string | null;
  modifiedBy: string;
};

export type ModelEmailLogConditionInput = {
  awsMessageId?: ModelStringInput | null;
  toEmail?: ModelStringInput | null;
  fromEmail?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  sendingUser?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelEmailLogConditionInput | null> | null;
  or?: Array<ModelEmailLogConditionInput | null> | null;
  not?: ModelEmailLogConditionInput | null;
};

export type EmailLog = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type UpdateEmailLogInput = {
  id: string;
  awsMessageId?: string | null;
  toEmail?: string | null;
  fromEmail?: string | null;
  monitoringCenterId?: string | null;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt?: string | null;
  sendingUser?: string | null;
  modifiedBy?: string | null;
};

export type DeleteEmailLogInput = {
  id: string;
};

export type CreateNotificationSettingInput = {
  id?: string | null;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
};

export enum NotificationType {
  IncidentL0 = "IncidentL0",
  IncidentL1 = "IncidentL1",
  IncidentL2 = "IncidentL2",
  IncidentL3 = "IncidentL3",
  IncidentL4 = "IncidentL4",
  IncidentAssignedToMe = "IncidentAssignedToMe",
  IncidentGuardServiceAction = "IncidentGuardServiceAction",
  IncidentEmergencyOpAction = "IncidentEmergencyOpAction",
  IncidentNewCameraEvent = "IncidentNewCameraEvent",
  IncidentActionItemUpdated = "IncidentActionItemUpdated",
  IncidentNoteAdded = "IncidentNoteAdded",
  IncidentPhoneCall = "IncidentPhoneCall",
  IncidentCameraEventSeparate = "IncidentCameraEventSeparate",
  IncidentClosed = "IncidentClosed",
  IncidentReopen = "IncidentReopen",
  CameraDisconnect = "CameraDisconnect",
  CameraReconnect = "CameraReconnect",
  ErroneousAlert = "ErroneousAlert",
  Misidentification = "Misidentification",
  Muted = "Muted",
  Other = "Other",
  Snoozed = "Snoozed",
  PackageDelivery = "PackageDelivery",
  Uncategorized = "Uncategorized",
  Normal = "Normal",
  CheckIn = "CheckIn",
  CheckOut = "CheckOut",
  ClockIn = "ClockIn",
  ClockOut = "ClockOut",
  ParkingStart = "ParkingStart",
  ParkingEnd = "ParkingEnd",
  PPECheck = "PPECheck",
  GateAccess = "GateAccess",
  DoorAccess = "DoorAccess",
  TemperatureCheck = "TemperatureCheck",
  IDCheck = "IDCheck",
  WorkingAloneCheck = "WorkingAloneCheck",
  WelfareCheck = "WelfareCheck",
  ParkingViolation = "ParkingViolation",
  DeviceNotice = "DeviceNotice",
  Suspicious = "Suspicious",
  Assault = "Assault",
  DrugTrafficking = "DrugTrafficking",
  Loitering = "Loitering",
  Vandalism = "Vandalism",
  VehicleBreakIn = "VehicleBreakIn",
  Trespass = "Trespass",
  Emergency = "Emergency",
  LifeInDanger = "LifeInDanger",
  Fire = "Fire",
  MedicalDuress = "MedicalDuress",
  HoldUp = "HoldUp",
  CameraNoticeResetFailure = "CameraNoticeResetFailure",
  CameraNoticeResetSucceed = "CameraNoticeResetSucceed",
  MonitoringNoticeArmingDisarmingFailed = "MonitoringNoticeArmingDisarmingFailed"
}

export type ModelNotificationSettingConditionInput = {
  username?: ModelStringInput | null;
  notificationType?: ModelNotificationTypeInput | null;
  userGroup?: ModelStringInput | null;
  clientList?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelNotificationSettingConditionInput | null> | null;
  or?: Array<ModelNotificationSettingConditionInput | null> | null;
  not?: ModelNotificationSettingConditionInput | null;
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null;
  ne?: NotificationType | null;
};

export type NotificationSetting = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateNotificationSettingInput = {
  id: string;
  username?: string | null;
  monitoringCenterId?: string | null;
  notificationType?: NotificationType | null;
  userGroup?: string | null;
  clientList?: Array<string | null> | null;
  modifiedBy?: string | null;
};

export type DeleteNotificationSettingInput = {
  id: string;
};

export type CreateNotificationInput = {
  id?: string | null;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt?: string | null;
  modifiedBy: string;
  url: string;
  note?: string | null;
};

export enum NotificationStatus {
  Read = "Read",
  Unread = "Unread"
}

export type ModelNotificationConditionInput = {
  monitoringCenterId?: ModelStringInput | null;
  notificationType?: ModelNotificationTypeInput | null;
  status?: ModelNotificationStatusInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  url?: ModelStringInput | null;
  note?: ModelStringInput | null;
  and?: Array<ModelNotificationConditionInput | null> | null;
  or?: Array<ModelNotificationConditionInput | null> | null;
  not?: ModelNotificationConditionInput | null;
};

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null;
  ne?: NotificationStatus | null;
};

export type Notification = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type UpdateNotificationInput = {
  id: string;
  username?: string | null;
  monitoringCenterId?: string | null;
  notificationType?: NotificationType | null;
  status?: NotificationStatus | null;
  createdAt?: string | null;
  modifiedBy?: string | null;
  url?: string | null;
  note?: string | null;
};

export type DeleteNotificationInput = {
  id: string;
};

export type CreateUserInput = {
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
};

export enum Language {
  English = "English",
  Spanish = "Spanish",
  Portuguese = "Portuguese",
  French = "French",
  Chinese = "Chinese",
  Italian = "Italian",
  Dutch = "Dutch"
}

export enum GridBehavior {
  DefaultOpen = "DefaultOpen",
  DefaultClosed = "DefaultClosed"
}

export type ModelUserConditionInput = {
  vmsOperatorUserIds?: ModelStringInput | null;
  vmsOperatorUserIdAutomatedRole?: ModelStringInput | null;
  vmsAdminUserIds?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  group?: ModelUserGroupInput | null;
  clientIdList?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  integratorId?: ModelStringInput | null;
  autoAssign?: ModelBooleanInput | null;
  preferredLanguage?: ModelLanguageInput | null;
  gridEnabled?: ModelBooleanInput | null;
  gridBehavior?: ModelGridBehaviorInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelUserGroupInput = {
  eq?: UserGroup | null;
  ne?: UserGroup | null;
};

export type ModelLanguageInput = {
  eq?: Language | null;
  ne?: Language | null;
};

export type ModelGridBehaviorInput = {
  eq?: GridBehavior | null;
  ne?: GridBehavior | null;
};

export type User = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserInput = {
  username: string;
  monitoringCenterId?: string | null;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy?: string | null;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone?: string | null;
  email?: string | null;
  name?: string | null;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
};

export type DeleteUserInput = {
  username: string;
};

export type CreateUserVMSDetailsInput = {
  id?: string | null;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export enum VMSUserType {
  Admin = "Admin",
  Guard = "Guard"
}

export type ModelUserVMSDetailsConditionInput = {
  vmsUserId?: ModelIDInput | null;
  profileName?: ModelStringInput | null;
  cognitoUsernames?: ModelStringInput | null;
  authEmail?: ModelStringInput | null;
  vmsUsername?: ModelStringInput | null;
  vmsPassword?: ModelStringInput | null;
  vmsUserType?: ModelVMSUserTypeInput | null;
  apiKey?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  accessTokenExpires?: ModelStringInput | null;
  refreshTokenExpires?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelUserVMSDetailsConditionInput | null> | null;
  or?: Array<ModelUserVMSDetailsConditionInput | null> | null;
  not?: ModelUserVMSDetailsConditionInput | null;
};

export type ModelVMSUserTypeInput = {
  eq?: VMSUserType | null;
  ne?: VMSUserType | null;
};

export type UserVMSDetails = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserVMSDetailsInput = {
  id: string;
  vmsUserId?: string | null;
  profileName?: string | null;
  cognitoUsernames?: Array<string> | null;
  authEmail?: string | null;
  vmsUsername?: string | null;
  vmsPassword?: string | null;
  vmsUserType?: VMSUserType | null;
  apiKey?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteUserVMSDetailsInput = {
  id: string;
};

export type CreateCustomerUserPermissionSetInput = {
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
};

export type ModelCustomerUserPermissionSetConditionInput = {
  email?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  ownerCustomerId?: ModelIDInput | null;
  and?: Array<ModelCustomerUserPermissionSetConditionInput | null> | null;
  or?: Array<ModelCustomerUserPermissionSetConditionInput | null> | null;
  not?: ModelCustomerUserPermissionSetConditionInput | null;
};

export type CustomerUserPermissionSet = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: ModelCustomerUserPermissionConnection | null;
};

export type ModelCustomerUserPermissionConnection = {
  __typename: "ModelCustomerUserPermissionConnection";
  items: Array<CustomerUserPermission | null>;
  nextToken?: string | null;
};

export type CustomerUserPermission = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: CustomerUserPermissionSet | null;
};

export type UpdateCustomerUserPermissionSetInput = {
  username: string;
  email?: string | null;
  enabled?: boolean | null;
  modifiedBy?: string | null;
  ownerCustomerId?: string | null;
};

export type DeleteCustomerUserPermissionSetInput = {
  username: string;
};

export type CreateCustomerUserPermissionInput = {
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
};

export type ModelCustomerUserPermissionConditionInput = {
  email?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  sitePermissions?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCustomerUserPermissionConditionInput | null> | null;
  or?: Array<ModelCustomerUserPermissionConditionInput | null> | null;
  not?: ModelCustomerUserPermissionConditionInput | null;
};

export type UpdateCustomerUserPermissionInput = {
  username: string;
  email?: string | null;
  monitoringCenterId?: string | null;
  customerId: string;
  sitePermissions?: string | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
};

export type DeleteCustomerUserPermissionInput = {
  username: string;
  customerId: string;
};

export type CreateTaxInput = {
  id?: string | null;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
};

export type ModelTaxConditionInput = {
  region?: ModelStringInput | null;
  subregion?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  amount?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  taxGovId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTaxConditionInput | null> | null;
  or?: Array<ModelTaxConditionInput | null> | null;
  not?: ModelTaxConditionInput | null;
};

export type Tax = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: ModelTaxesTaxTypeConnection | null;
};

export type ModelTaxesTaxTypeConnection = {
  __typename: "ModelTaxesTaxTypeConnection";
  items: Array<TaxesTaxType | null>;
  nextToken?: string | null;
};

export type TaxesTaxType = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: Tax;
  integratorId?: string | null;
  taxType: TaxType;
};

export type TaxType = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: ModelTaxesTaxTypeConnection | null;
};

export type UpdateTaxInput = {
  id: string;
  monitoringCenterId?: string | null;
  mcIntAdminAccess?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  region?: string | null;
  subregion?: string | null;
  archived?: boolean | null;
  amount?: number | null;
  name?: string | null;
  taxGovId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteTaxInput = {
  id: string;
};

export type CreateTaxesTaxTypeInput = {
  id?: string | null;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
};

export type ModelTaxesTaxTypeConditionInput = {
  taxId?: ModelIDInput | null;
  taxTypeId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTaxesTaxTypeConditionInput | null> | null;
  or?: Array<ModelTaxesTaxTypeConditionInput | null> | null;
  not?: ModelTaxesTaxTypeConditionInput | null;
};

export type UpdateTaxesTaxTypeInput = {
  id: string;
  taxId?: string | null;
  taxTypeId?: string | null;
  mcIntAdminAccess?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
};

export type DeleteTaxesTaxTypeInput = {
  id: string;
};

export type CreateTaxTypeInput = {
  id?: string | null;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
};

export type ModelTaxTypeConditionInput = {
  archived?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTaxTypeConditionInput | null> | null;
  or?: Array<ModelTaxTypeConditionInput | null> | null;
  not?: ModelTaxTypeConditionInput | null;
};

export type UpdateTaxTypeInput = {
  id: string;
  monitoringCenterId?: string | null;
  mcIntAdminAccess?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  archived?: boolean | null;
  name?: string | null;
  modifiedBy?: string | null;
};

export type DeleteTaxTypeInput = {
  id: string;
};

export type CreateSetupFeeInput = {
  id?: string | null;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  setupFeeTaxTypeId?: string | null;
};

export type ModelSetupFeeConditionInput = {
  name?: ModelStringInput | null;
  taxTypeId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSetupFeeConditionInput | null> | null;
  or?: Array<ModelSetupFeeConditionInput | null> | null;
  not?: ModelSetupFeeConditionInput | null;
};

export type ModelCurrencyInput = {
  eq?: Currency | null;
  ne?: Currency | null;
};

export type SetupFee = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: TaxType | null;
};

export type UpdateSetupFeeInput = {
  id: string;
  name?: string | null;
  taxTypeId?: string | null;
  amount?: number | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  setupFeeTaxTypeId?: string | null;
};

export type DeleteSetupFeeInput = {
  id: string;
};

export type CreateProductInput = {
  id?: string | null;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<FXPriceInput> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
};

export type FXPriceInput = {
  currency?: Currency | null;
  price: number;
};

export enum ProductStatus {
  Draft = "Draft",
  Published = "Published"
}

export enum ProductType {
  CameraPlan = "CameraPlan",
  Hardware = "Hardware"
}

export type ModelProductConditionInput = {
  cameraPlanId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameForInvoice?: ModelStringInput | null;
  description?: ModelStringInput | null;
  productTaxTypeId?: ModelIDInput | null;
  minPrice?: ModelFloatInput | null;
  mrp?: ModelFloatInput | null;
  cost?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  status?: ModelProductStatusInput | null;
  type?: ModelProductTypeInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  publishedFor?: ModelIDInput | null;
  publishedForArchived?: ModelIDInput | null;
  and?: Array<ModelProductConditionInput | null> | null;
  or?: Array<ModelProductConditionInput | null> | null;
  not?: ModelProductConditionInput | null;
};

export type ModelProductStatusInput = {
  eq?: ProductStatus | null;
  ne?: ProductStatus | null;
};

export type ModelProductTypeInput = {
  eq?: ProductType | null;
  ne?: ProductType | null;
};

export type Product = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<FXPrice> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: TaxType | null;
};

export type FXPrice = {
  __typename: "FXPrice";
  currency?: Currency | null;
  price: number;
};

export type UpdateProductInput = {
  id: string;
  cameraPlanId?: string | null;
  name?: string | null;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice?: number | null;
  mrp?: number | null;
  cost?: number | null;
  price?: number | null;
  fxPrices?: Array<FXPriceInput> | null;
  status?: ProductStatus | null;
  type?: ProductType | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
};

export type DeleteProductInput = {
  id: string;
};

export type CreateCameraPlanInput = {
  id?: string | null;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export enum AnalyticType {
  BasicId = "BasicId",
  AdvancedId = "AdvancedId",
  FaceId = "FaceId"
}

export type ModelCameraPlanConditionInput = {
  parentId?: ModelIDInput | null;
  analyticsType?: ModelAnalyticTypeListInput | null;
  resolution?: ModelStringInput | null;
  bitRate?: ModelIntInput | null;
  operatorDuration?: ModelIntInput | null;
  supervision?: ModelDeviceHealthCheckSLAInput | null;
  storageDays?: ModelIntInput | null;
  cameraPlanProductId?: ModelIDInput | null;
  cameraPlanLivePatrolId?: ModelIDInput | null;
  cameraPlanOperatorMinutesId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelCameraPlanConditionInput | null> | null;
  or?: Array<ModelCameraPlanConditionInput | null> | null;
  not?: ModelCameraPlanConditionInput | null;
};

export type ModelAnalyticTypeListInput = {
  eq?: Array<AnalyticType | null> | null;
  ne?: Array<AnalyticType | null> | null;
  contains?: AnalyticType | null;
  notContains?: AnalyticType | null;
};

export type CameraPlan = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: Product;
  livePatrol: LivePatrol;
  operatorMinutes: OperatorMinutes;
};

export type LivePatrol = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<FXPrice> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OperatorMinutes = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<FXPrice> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraPlanInput = {
  id: string;
  parentId?: string | null;
  analyticsType?: Array<AnalyticType> | null;
  resolution?: string | null;
  bitRate?: number | null;
  operatorDuration?: number | null;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays?: number | null;
  cameraPlanProductId?: string | null;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteCameraPlanInput = {
  id: string;
};

export type CreateLivePatrolInput = {
  id?: string | null;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<FXPriceInput> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelLivePatrolConditionInput = {
  cameraPlanId?: ModelIDInput | null;
  enabled?: ModelBooleanInput | null;
  minPrice?: ModelFloatInput | null;
  mrp?: ModelFloatInput | null;
  cost?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelLivePatrolConditionInput | null> | null;
  or?: Array<ModelLivePatrolConditionInput | null> | null;
  not?: ModelLivePatrolConditionInput | null;
};

export type UpdateLivePatrolInput = {
  id: string;
  cameraPlanId?: string | null;
  enabled?: boolean | null;
  minPrice?: number | null;
  mrp?: number | null;
  cost?: number | null;
  price?: number | null;
  fxPrices?: Array<FXPriceInput> | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteLivePatrolInput = {
  id: string;
};

export type CreateOperatorMinutesInput = {
  id?: string | null;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<FXPriceInput> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelOperatorMinutesConditionInput = {
  cameraPlanId?: ModelIDInput | null;
  minPrice?: ModelFloatInput | null;
  mrp?: ModelFloatInput | null;
  cost?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelOperatorMinutesConditionInput | null> | null;
  or?: Array<ModelOperatorMinutesConditionInput | null> | null;
  not?: ModelOperatorMinutesConditionInput | null;
};

export type UpdateOperatorMinutesInput = {
  id: string;
  cameraPlanId?: string | null;
  minPrice?: number | null;
  mrp?: number | null;
  cost?: number | null;
  price?: number | null;
  fxPrices?: Array<FXPriceInput> | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteOperatorMinutesInput = {
  id: string;
};

export type CreateSiteSubscriptionInput = {
  id?: string | null;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  siteSubscriptionStripeSubscriptionId?: string | null;
};

export type ModelSiteSubscriptionConditionInput = {
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  status?: ModelActiveInactiveInput | null;
  onHold?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  term?: ModelIntInput | null;
  renewalDate?: ModelStringInput | null;
  renewalTerm?: ModelIntInput | null;
  merchant?: ModelSiteSubscriptionMerchantInput | null;
  billingDay?: ModelIntInput | null;
  billingFrequency?: ModelBillingFrequencyInput | null;
  nextBillingDay?: ModelStringInput | null;
  autorefillOn?: ModelBooleanInput | null;
  autorefillAmount?: ModelFloatInput | null;
  minimumBalance?: ModelFloatInput | null;
  currentBalance?: ModelFloatInput | null;
  currentBalanceUsd?: ModelFloatInput | null;
  maxNumberRecharges?: ModelFloatInput | null;
  currentDayRechargeCount?: ModelFloatInput | null;
  lastRefilled?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  usedOperatorMinutes?: ModelFloatInput | null;
  totalOperatorMinutes?: ModelFloatInput | null;
  transactionsLastCalculated?: ModelStringInput | null;
  secondsPerInteraction?: ModelIntInput | null;
  incidentBillingDisabled?: ModelBooleanInput | null;
  interactionBillingDisabled?: ModelBooleanInput | null;
  livePatrolBillingDisabled?: ModelBooleanInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  billingEmails?: ModelStringInput | null;
  billingEntityName?: ModelStringInput | null;
  commissionBilling?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelSiteSubscriptionConditionInput | null> | null;
  or?: Array<ModelSiteSubscriptionConditionInput | null> | null;
  not?: ModelSiteSubscriptionConditionInput | null;
};

export type ModelSiteSubscriptionMerchantInput = {
  eq?: SiteSubscriptionMerchant | null;
  ne?: SiteSubscriptionMerchant | null;
};

export type ModelBillingFrequencyInput = {
  eq?: BillingFrequency | null;
  ne?: BillingFrequency | null;
};

export type SiteSubscription = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: SubscriptionStripeDetail | null;
  cameraPlans?: ModelAppliedCameraPlanConnection | null;
  setupFees?: ModelAppliedSetupFeeConnection | null;
};

export type SubscriptionStripeDetail = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: ModelStripePaymentMethodConnection | null;
};

export type ModelStripePaymentMethodConnection = {
  __typename: "ModelStripePaymentMethodConnection";
  items: Array<StripePaymentMethod | null>;
  nextToken?: string | null;
};

export type StripePaymentMethod = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export enum StripePaymentType {
  card = "card"
}

export type ModelAppliedCameraPlanConnection = {
  __typename: "ModelAppliedCameraPlanConnection";
  items: Array<AppliedCameraPlan | null>;
  nextToken?: string | null;
};

export type AppliedCameraPlan = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: AppliedProduct;
  livePatrol: AppliedLivePatrol;
  operatorMinutes: AppliedOperatorMinutes;
};

export type AppliedProduct = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: TaxType | null;
};

export type AppliedLivePatrol = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type AppliedOperatorMinutes = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelAppliedSetupFeeConnection = {
  __typename: "ModelAppliedSetupFeeConnection";
  items: Array<AppliedSetupFee | null>;
  nextToken?: string | null;
};

export type AppliedSetupFee = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: TaxType | null;
};

export type UpdateSiteSubscriptionInput = {
  id: string;
  siteId?: string | null;
  customerId?: string | null;
  status?: ActiveInactive | null;
  onHold?: boolean | null;
  startDate?: string | null;
  term?: number | null;
  renewalDate?: string | null;
  renewalTerm?: number | null;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay?: number | null;
  billingFrequency?: BillingFrequency | null;
  nextBillingDay?: string | null;
  autorefillOn?: boolean | null;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance?: number | null;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency?: Currency | null;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  mcIntAdminAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  siteSubscriptionStripeSubscriptionId?: string | null;
};

export type DeleteSiteSubscriptionInput = {
  id: string;
};

export type CreateSubscriptionStripeDetailInput = {
  id?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
};

export type ModelSubscriptionStripeDetailConditionInput = {
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  siteSubscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  and?: Array<ModelSubscriptionStripeDetailConditionInput | null> | null;
  or?: Array<ModelSubscriptionStripeDetailConditionInput | null> | null;
  not?: ModelSubscriptionStripeDetailConditionInput | null;
};

export type UpdateSubscriptionStripeDetailInput = {
  id: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  siteSubscriptionId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
};

export type DeleteSubscriptionStripeDetailInput = {
  id: string;
};

export type CreateStripePaymentMethodInput = {
  id?: string | null;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelStripePaymentMethodConditionInput = {
  subscriptionStripeDetailId?: ModelIDInput | null;
  stripePaymentId?: ModelStringInput | null;
  stripePaymentStatus?: ModelStripePaymentStatusInput | null;
  type?: ModelStripePaymentTypeInput | null;
  name?: ModelStringInput | null;
  identifier?: ModelStringInput | null;
  preferredPayment?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelStripePaymentMethodConditionInput | null> | null;
  or?: Array<ModelStripePaymentMethodConditionInput | null> | null;
  not?: ModelStripePaymentMethodConditionInput | null;
};

export type ModelStripePaymentStatusInput = {
  eq?: StripePaymentStatus | null;
  ne?: StripePaymentStatus | null;
};

export type ModelStripePaymentTypeInput = {
  eq?: StripePaymentType | null;
  ne?: StripePaymentType | null;
};

export type UpdateStripePaymentMethodInput = {
  id: string;
  subscriptionStripeDetailId?: string | null;
  stripePaymentId?: string | null;
  stripePaymentStatus?: StripePaymentStatus | null;
  type?: StripePaymentType | null;
  name?: string | null;
  identifier?: string | null;
  preferredPayment?: boolean | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteStripePaymentMethodInput = {
  id: string;
};

export type CreateAppliedProductInput = {
  id?: string | null;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelAppliedProductConditionInput = {
  appliedCameraPlanId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameForInvoice?: ModelStringInput | null;
  description?: ModelStringInput | null;
  appliedProductTaxTypeId?: ModelIDInput | null;
  price?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedProductConditionInput | null> | null;
  or?: Array<ModelAppliedProductConditionInput | null> | null;
  not?: ModelAppliedProductConditionInput | null;
};

export type UpdateAppliedProductInput = {
  id: string;
  appliedCameraPlanId?: string | null;
  name?: string | null;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price?: number | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteAppliedProductInput = {
  id: string;
};

export type CreateAppliedCameraPlanInput = {
  id?: string | null;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  appliedCameraPlanProductId: string;
  appliedCameraPlanLivePatrolId: string;
  appliedCameraPlanOperatorMinutesId: string;
};

export type ModelAppliedCameraPlanConditionInput = {
  parentId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  subscriptionId?: ModelIDInput | null;
  analyticsType?: ModelAnalyticTypeListInput | null;
  resolution?: ModelStringInput | null;
  bitRate?: ModelIntInput | null;
  operatorDuration?: ModelIntInput | null;
  supervision?: ModelDeviceHealthCheckSLAInput | null;
  storageDays?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedCameraPlanConditionInput | null> | null;
  or?: Array<ModelAppliedCameraPlanConditionInput | null> | null;
  not?: ModelAppliedCameraPlanConditionInput | null;
};

export type UpdateAppliedCameraPlanInput = {
  id: string;
  parentId?: string | null;
  cameraId?: string | null;
  subscriptionId?: string | null;
  analyticsType?: Array<AnalyticType> | null;
  resolution?: string | null;
  bitRate?: number | null;
  operatorDuration?: number | null;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays?: number | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  appliedCameraPlanProductId?: string | null;
  appliedCameraPlanLivePatrolId?: string | null;
  appliedCameraPlanOperatorMinutesId?: string | null;
};

export type DeleteAppliedCameraPlanInput = {
  id: string;
};

export type CreateAppliedLivePatrolInput = {
  id?: string | null;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelAppliedLivePatrolConditionInput = {
  appliedCameraPlanId?: ModelIDInput | null;
  enabled?: ModelBooleanInput | null;
  price?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedLivePatrolConditionInput | null> | null;
  or?: Array<ModelAppliedLivePatrolConditionInput | null> | null;
  not?: ModelAppliedLivePatrolConditionInput | null;
};

export type UpdateAppliedLivePatrolInput = {
  id: string;
  appliedCameraPlanId?: string | null;
  enabled?: boolean | null;
  price?: number | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteAppliedLivePatrolInput = {
  id: string;
};

export type CreateAppliedOperatorMinutesInput = {
  id?: string | null;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
};

export type ModelAppliedOperatorMinutesConditionInput = {
  appliedCameraPlanId?: ModelIDInput | null;
  price?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedOperatorMinutesConditionInput | null> | null;
  or?: Array<ModelAppliedOperatorMinutesConditionInput | null> | null;
  not?: ModelAppliedOperatorMinutesConditionInput | null;
};

export type UpdateAppliedOperatorMinutesInput = {
  id: string;
  appliedCameraPlanId?: string | null;
  price?: number | null;
  currency?: Currency | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
};

export type DeleteAppliedOperatorMinutesInput = {
  id: string;
};

export type CreateAppliedSetupFeeInput = {
  id?: string | null;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  appliedSetupFeeTaxTypeId?: string | null;
};

export type ModelAppliedSetupFeeConditionInput = {
  parentId?: ModelIDInput | null;
  subscriptionId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedSetupFeeConditionInput | null> | null;
  or?: Array<ModelAppliedSetupFeeConditionInput | null> | null;
  not?: ModelAppliedSetupFeeConditionInput | null;
};

export type UpdateAppliedSetupFeeInput = {
  id: string;
  parentId?: string | null;
  subscriptionId?: string | null;
  amount?: number | null;
  name?: string | null;
  currency?: Currency | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcBillingAdminAccess?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  appliedSetupFeeTaxTypeId?: string | null;
};

export type DeleteAppliedSetupFeeInput = {
  id: string;
};

export type CreateTransactionLineItemInput = {
  id?: string | null;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
};

export enum TransactionType {
  COGS = "COGS",
  MonitoringIncluded = "MonitoringIncluded",
  MonitoringOverage = "MonitoringOverage",
  LivePatrol = "LivePatrol",
  SetupFee = "SetupFee",
  RecurringFees = "RecurringFees"
}

export type ModelTransactionLineItemConditionInput = {
  subscriptionId?: ModelIDInput | null;
  invoiceId?: ModelIDInput | null;
  connectedId?: ModelIDInput | null;
  type?: ModelTransactionTypeInput | null;
  description?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  pricePer?: ModelFloatInput | null;
  totalPrice?: ModelFloatInput | null;
  quantity?: ModelFloatInput | null;
  siteId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTransactionLineItemConditionInput | null> | null;
  or?: Array<ModelTransactionLineItemConditionInput | null> | null;
  not?: ModelTransactionLineItemConditionInput | null;
};

export type ModelTransactionTypeInput = {
  eq?: TransactionType | null;
  ne?: TransactionType | null;
};

export type TransactionLineItem = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: ModelTransactionLineItemTaxConnection | null;
};

export type ModelTransactionLineItemTaxConnection = {
  __typename: "ModelTransactionLineItemTaxConnection";
  items: Array<TransactionLineItemTax | null>;
  nextToken?: string | null;
};

export type TransactionLineItemTax = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTransactionLineItemInput = {
  id: string;
  subscriptionId?: string | null;
  invoiceId?: string | null;
  connectedId?: string | null;
  type?: TransactionType | null;
  description?: string | null;
  currency?: Currency | null;
  pricePer?: number | null;
  totalPrice?: number | null;
  quantity?: number | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type DeleteTransactionLineItemInput = {
  id: string;
};

export type CreateTransactionLineItemTaxInput = {
  id?: string | null;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
};

export type ModelTransactionLineItemTaxConditionInput = {
  transactionLineItemId?: ModelIDInput | null;
  invoiceId?: ModelIDInput | null;
  taxId?: ModelIDInput | null;
  taxGovId?: ModelStringInput | null;
  taxPercent?: ModelFloatInput | null;
  taxName?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  amount?: ModelFloatInput | null;
  subscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTransactionLineItemTaxConditionInput | null> | null;
  or?: Array<ModelTransactionLineItemTaxConditionInput | null> | null;
  not?: ModelTransactionLineItemTaxConditionInput | null;
};

export type UpdateTransactionLineItemTaxInput = {
  id: string;
  transactionLineItemId?: string | null;
  invoiceId?: string | null;
  taxId?: string | null;
  taxGovId?: string | null;
  taxPercent?: number | null;
  taxName?: string | null;
  currency?: Currency | null;
  amount?: number | null;
  subscriptionId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
};

export type DeleteTransactionLineItemTaxInput = {
  id: string;
};

export type CreateInvoiceInput = {
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  invoiceTransferPaymentId?: string | null;
};

export enum InvoiceType {
  Recurring = "Recurring",
  Overage = "Overage",
  COGS = "COGS",
  Setup = "Setup"
}

export enum InvoiceStatus {
  Open = "Open",
  PaymentRequested = "PaymentRequested",
  Paid = "Paid",
  Void = "Void"
}

export enum InvoiceMerchant {
  Integrator = "Integrator",
  MonitoringCenter = "MonitoringCenter",
  Platform = "Platform"
}

export type ModelInvoiceConditionInput = {
  subtotal?: ModelFloatInput | null;
  invoiceNumber?: ModelStringInput | null;
  total?: ModelFloatInput | null;
  transactionCurrTotal?: ModelFloatInput | null;
  notes?: ModelStringInput | null;
  type?: ModelInvoiceTypeInput | null;
  currency?: ModelCurrencyInput | null;
  transactionCurr?: ModelCurrencyInput | null;
  status?: ModelInvoiceStatusInput | null;
  merchant?: ModelInvoiceMerchantInput | null;
  subscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  transferGroup?: ModelIDInput | null;
  and?: Array<ModelInvoiceConditionInput | null> | null;
  or?: Array<ModelInvoiceConditionInput | null> | null;
  not?: ModelInvoiceConditionInput | null;
};

export type ModelInvoiceTypeInput = {
  eq?: InvoiceType | null;
  ne?: InvoiceType | null;
};

export type ModelInvoiceStatusInput = {
  eq?: InvoiceStatus | null;
  ne?: InvoiceStatus | null;
};

export type ModelInvoiceMerchantInput = {
  eq?: InvoiceMerchant | null;
  ne?: InvoiceMerchant | null;
};

export type Invoice = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: ModelTransactionLineItemConnection | null;
  payments?: ModelInvoicePaymentConnection | null;
  transferPayment?: InvoiceTransferPayment | null;
};

export type ModelTransactionLineItemConnection = {
  __typename: "ModelTransactionLineItemConnection";
  items: Array<TransactionLineItem | null>;
  nextToken?: string | null;
};

export type ModelInvoicePaymentConnection = {
  __typename: "ModelInvoicePaymentConnection";
  items: Array<InvoicePayment | null>;
  nextToken?: string | null;
};

export type InvoicePayment = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: StripePaymentMethod | null;
};

export enum PaymentType {
  Payment = "Payment",
  WalletTransfer = "WalletTransfer",
  CreditNote = "CreditNote",
  Refund = "Refund"
}

export type InvoiceTransferPayment = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export enum InvoiceTransferStatus {
  Pending = "Pending",
  Complete = "Complete",
  Canceled = "Canceled"
}

export type UpdateInvoiceInput = {
  id: string;
  subtotal?: number | null;
  invoiceNumber?: string | null;
  total?: number | null;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type?: InvoiceType | null;
  currency?: Currency | null;
  transactionCurr?: Currency | null;
  status?: InvoiceStatus | null;
  merchant?: InvoiceMerchant | null;
  subscriptionId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  invoiceTransferPaymentId?: string | null;
};

export type DeleteInvoiceInput = {
  id: string;
};

export type CreateInvoicePaymentInput = {
  id?: string | null;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  invoicePaymentPaymentMethodId?: string | null;
};

export type ModelInvoicePaymentConditionInput = {
  stripePaymentIntentId?: ModelStringInput | null;
  paymentStatus?: ModelStripePaymentStatusInput | null;
  paymentType?: ModelPaymentTypeInput | null;
  notes?: ModelStringInput | null;
  invoiceId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  amountReceived?: ModelFloatInput | null;
  amountRequested?: ModelFloatInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelInvoicePaymentConditionInput | null> | null;
  or?: Array<ModelInvoicePaymentConditionInput | null> | null;
  not?: ModelInvoicePaymentConditionInput | null;
};

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null;
  ne?: PaymentType | null;
};

export type UpdateInvoicePaymentInput = {
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus?: StripePaymentStatus | null;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  amountReceived?: number | null;
  amountRequested?: number | null;
  modifiedBy?: string | null;
  createdAt?: string | null;
  invoicePaymentPaymentMethodId?: string | null;
};

export type DeleteInvoicePaymentInput = {
  id: string;
};

export type CreateInvoiceTransferPaymentInput = {
  id?: string | null;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
};

export type ModelInvoiceTransferPaymentConditionInput = {
  destination?: ModelStringInput | null;
  transferGroup?: ModelStringInput | null;
  stripePaymentIntentId?: ModelStringInput | null;
  currency?: ModelStringInput | null;
  stripeTransferRefId?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  status?: ModelInvoiceTransferStatusInput | null;
  invoiceIds?: ModelIDInput | null;
  and?: Array<ModelInvoiceTransferPaymentConditionInput | null> | null;
  or?: Array<ModelInvoiceTransferPaymentConditionInput | null> | null;
  not?: ModelInvoiceTransferPaymentConditionInput | null;
};

export type ModelInvoiceTransferStatusInput = {
  eq?: InvoiceTransferStatus | null;
  ne?: InvoiceTransferStatus | null;
};

export type UpdateInvoiceTransferPaymentInput = {
  id: string;
  destination?: string | null;
  transferGroup?: string | null;
  stripePaymentIntentId?: string | null;
  currency?: string | null;
  stripeTransferRefId?: string | null;
  amount?: number | null;
  customerId?: string | null;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy?: string | null;
  status?: InvoiceTransferStatus | null;
  invoiceIds?: Array<string> | null;
};

export type DeleteInvoiceTransferPaymentInput = {
  id: string;
};

export type CreateWalletTransferInput = {
  id?: string | null;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt?: string | null;
  walletTransferPaymentMethodId: string;
};

export enum WalletTransferType {
  Manual = "Manual",
  Auto = "Auto"
}

export type ModelWalletTransferConditionInput = {
  subscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  conversionRate?: ModelFloatInput | null;
  stripePaymentId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  type?: ModelWalletTransferTypeInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelWalletTransferConditionInput | null> | null;
  or?: Array<ModelWalletTransferConditionInput | null> | null;
  not?: ModelWalletTransferConditionInput | null;
};

export type ModelWalletTransferTypeInput = {
  eq?: WalletTransferType | null;
  ne?: WalletTransferType | null;
};

export type WalletTransfer = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: StripePaymentMethod;
};

export type UpdateWalletTransferInput = {
  id: string;
  subscriptionId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  amount?: number | null;
  currency?: Currency | null;
  conversionRate?: number | null;
  stripePaymentId?: string | null;
  modifiedBy?: string | null;
  type?: WalletTransferType | null;
  createdAt?: string | null;
  walletTransferPaymentMethodId?: string | null;
};

export type DeleteWalletTransferInput = {
  id: string;
};

export type CreateStripePayoutDetailsInput = {
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
};

export type ModelStripePayoutDetailsConditionInput = {
  chargesEnabled?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelStripePayoutDetailsConditionInput | null> | null;
  or?: Array<ModelStripePayoutDetailsConditionInput | null> | null;
  not?: ModelStripePayoutDetailsConditionInput | null;
};

export type StripePayoutDetails = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateStripePayoutDetailsInput = {
  stripeAccId: string;
  integratorId?: string | null;
  monitoringCenterId?: string | null;
  chargesEnabled?: boolean | null;
  modifiedBy?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  archived?: boolean | null;
};

export type DeleteStripePayoutDetailsInput = {
  stripeAccId: string;
};

export type CreateExchangeRateSettingInput = {
  country: string;
  modifiedBy: string;
  rate: number;
};

export type ModelExchangeRateSettingConditionInput = {
  modifiedBy?: ModelStringInput | null;
  rate?: ModelFloatInput | null;
  and?: Array<ModelExchangeRateSettingConditionInput | null> | null;
  or?: Array<ModelExchangeRateSettingConditionInput | null> | null;
  not?: ModelExchangeRateSettingConditionInput | null;
};

export type ExchangeRateSetting = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateExchangeRateSettingInput = {
  country: string;
  modifiedBy?: string | null;
  rate?: number | null;
};

export type DeleteExchangeRateSettingInput = {
  country: string;
};

export type CreatePaymentTransferSettingsInput = {
  id?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  paymentTransferSettingsVariableTaxTypeId?: string | null;
  paymentTransferSettingsFlatTaxTypeId?: string | null;
};

export enum StripePayoutSettingsType {
  Card = "Card",
  Bank = "Bank",
  Wallet = "Wallet"
}

export type ModelPaymentTransferSettingsConditionInput = {
  monitoringCenterId?: ModelIDInput | null;
  type?: ModelStripePayoutSettingsTypeInput | null;
  variable?: ModelFloatInput | null;
  variableMaxDollars?: ModelFloatInput | null;
  flat?: ModelFloatInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelPaymentTransferSettingsConditionInput | null> | null;
  or?: Array<ModelPaymentTransferSettingsConditionInput | null> | null;
  not?: ModelPaymentTransferSettingsConditionInput | null;
};

export type ModelStripePayoutSettingsTypeInput = {
  eq?: StripePayoutSettingsType | null;
  ne?: StripePayoutSettingsType | null;
};

export type PaymentTransferSettings = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: TaxType | null;
  flatTaxType?: TaxType | null;
};

export type UpdatePaymentTransferSettingsInput = {
  id: string;
  monitoringCenterId?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  mcIntAdminAccess?: string | null;
  type?: StripePayoutSettingsType | null;
  variable?: number | null;
  variableMaxDollars?: number | null;
  flat?: number | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  paymentTransferSettingsVariableTaxTypeId?: string | null;
  paymentTransferSettingsFlatTaxTypeId?: string | null;
};

export type DeletePaymentTransferSettingsInput = {
  id: string;
};

export type CreateWebhookHealthInput = {
  id?: string | null;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
};

export type ModelWebhookHealthConditionInput = {
  vmsWebhookId?: ModelStringInput | null;
  date?: ModelStringInput | null;
  ackAvgInterval?: ModelFloatInput | null;
  acklastReceived?: ModelStringInput | null;
  ackCount?: ModelIntInput | null;
  analyticAvgInterval?: ModelFloatInput | null;
  analyticlastReceived?: ModelStringInput | null;
  analyticCount?: ModelIntInput | null;
  and?: Array<ModelWebhookHealthConditionInput | null> | null;
  or?: Array<ModelWebhookHealthConditionInput | null> | null;
  not?: ModelWebhookHealthConditionInput | null;
};

export type WebhookHealth = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWebhookHealthInput = {
  id: string;
  monitoringCenterId?: string | null;
  vmsWebhookId?: string | null;
  date?: string | null;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
};

export type DeleteWebhookHealthInput = {
  id: string;
};

export type CreateMasterInput = {
  id?: string | null;
};

export type ModelMasterConditionInput = {
  and?: Array<ModelMasterConditionInput | null> | null;
  or?: Array<ModelMasterConditionInput | null> | null;
  not?: ModelMasterConditionInput | null;
};

export type Master = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMasterInput = {
  id: string;
};

export type DeleteMasterInput = {
  id: string;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelSecondaryCameraEventFilterInput = {
  id?: ModelIDInput | null;
  cameraEventId?: ModelIDInput | null;
  vmsNotes?: ModelStringInput | null;
  vmsNotesTranslated?: ModelStringInput | null;
  vmsNotesSanitized?: ModelStringInput | null;
  disposition?: ModelDispositionInput | null;
  eventType?: ModelEventTypeInput | null;
  vmsSourceType?: ModelVMSSourceTypeInput | null;
  ackTimestamp?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  vmsUserId?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  and?: Array<ModelSecondaryCameraEventFilterInput | null> | null;
  or?: Array<ModelSecondaryCameraEventFilterInput | null> | null;
  not?: ModelSecondaryCameraEventFilterInput | null;
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null;
  cameraPlanId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameForInvoice?: ModelStringInput | null;
  description?: ModelStringInput | null;
  productTaxTypeId?: ModelIDInput | null;
  minPrice?: ModelFloatInput | null;
  mrp?: ModelFloatInput | null;
  cost?: ModelFloatInput | null;
  price?: ModelFloatInput | null;
  status?: ModelProductStatusInput | null;
  type?: ModelProductTypeInput | null;
  currency?: ModelCurrencyInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  publishedFor?: ModelIDInput | null;
  publishedForArchived?: ModelIDInput | null;
  and?: Array<ModelProductFilterInput | null> | null;
  or?: Array<ModelProductFilterInput | null> | null;
  not?: ModelProductFilterInput | null;
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection";
  items: Array<Product | null>;
  nextToken?: string | null;
};

export type ModelAppliedSetupFeeFilterInput = {
  id?: ModelIDInput | null;
  parentId?: ModelIDInput | null;
  subscriptionId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  mcBillingAdminAccess?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedSetupFeeFilterInput | null> | null;
  or?: Array<ModelAppliedSetupFeeFilterInput | null> | null;
  not?: ModelAppliedSetupFeeFilterInput | null;
};

export type ModelIncidentFilterInput = {
  id?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  status?: ModelIncidentStatusInput | null;
  incidentSiteId?: ModelIDInput | null;
  incidentCustomerId?: ModelIDInput | null;
  dispositionLevel?: ModelStringInput | null;
  primaryDisposition?: ModelStringInput | null;
  dispositionList?: ModelStringInput | null;
  closedTime?: ModelStringInput | null;
  resolvedTime?: ModelStringInput | null;
  expectedResolvedTime?: ModelStringInput | null;
  billableTime?: ModelFloatInput | null;
  assignedUsername?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  hasShareExternal?: ModelBooleanInput | null;
  resolution?: ModelStringInput | null;
  resolutionTranslated?: ModelStringInput | null;
  resolutionSanitized?: ModelStringInput | null;
  invoiceId?: ModelIDInput | null;
  and?: Array<ModelIncidentFilterInput | null> | null;
  or?: Array<ModelIncidentFilterInput | null> | null;
  not?: ModelIncidentFilterInput | null;
};

export type ModelIncidentConnection = {
  __typename: "ModelIncidentConnection";
  items: Array<Incident | null>;
  nextToken?: string | null;
};

export type ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyConditionInput = {
  eq?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null;
  le?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null;
  lt?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null;
  ge?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null;
  gt?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null;
  between?: Array<ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null> | null;
  beginsWith?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput | null;
};

export type ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyInput = {
  status?: IncidentStatus | null;
  expectedResolvedTime?: string | null;
};

export type ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyConditionInput = {
  eq?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null;
  le?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null;
  lt?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null;
  ge?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null;
  gt?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null;
  between?: Array<ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null> | null;
  beginsWith?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput | null;
};

export type ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyInput = {
  status?: IncidentStatus | null;
  createdAt?: string | null;
};

export type ModelIncidentNoteFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  note?: ModelStringInput | null;
  noteTranslated?: ModelStringInput | null;
  noteSanitized?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  writtenBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  shareExternal?: ModelBooleanInput | null;
  and?: Array<ModelIncidentNoteFilterInput | null> | null;
  or?: Array<ModelIncidentNoteFilterInput | null> | null;
  not?: ModelIncidentNoteFilterInput | null;
};

export type ModelIncidentNoteConnection = {
  __typename: "ModelIncidentNoteConnection";
  items: Array<IncidentNote | null>;
  nextToken?: string | null;
};

export type ModelIncidentActionListFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  priority?: ModelIntInput | null;
  eventType?: ModelEventTypeInput | null;
  disposition?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelIncidentActionListFilterInput | null> | null;
  or?: Array<ModelIncidentActionListFilterInput | null> | null;
  not?: ModelIncidentActionListFilterInput | null;
};

export type ModelIncidentActionListConnection = {
  __typename: "ModelIncidentActionListConnection";
  items: Array<IncidentActionList | null>;
  nextToken?: string | null;
};

export type ModelProcedureTaskFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  incidentActionItemId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  completed?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelProcedureTaskFilterInput | null> | null;
  or?: Array<ModelProcedureTaskFilterInput | null> | null;
  not?: ModelProcedureTaskFilterInput | null;
};

export type ModelIncidentBundleTimeFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  applyToAssigned?: ModelBooleanInput | null;
  bundleTime?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelIncidentBundleTimeFilterInput | null> | null;
  or?: Array<ModelIncidentBundleTimeFilterInput | null> | null;
  not?: ModelIncidentBundleTimeFilterInput | null;
};

export type ModelIncidentBundleTimeConnection = {
  __typename: "ModelIncidentBundleTimeConnection";
  items: Array<IncidentBundleTime | null>;
  nextToken?: string | null;
};

export type ModelCameraEventFilterInput = {
  id?: ModelIDInput | null;
  vmsEventId?: ModelStringInput | null;
  vmsNotes?: ModelStringInput | null;
  vmsNotesTranslated?: ModelStringInput | null;
  vmsNotesSanitized?: ModelStringInput | null;
  disposition?: ModelDispositionInput | null;
  eventType?: ModelEventTypeInput | null;
  thumbnailFilename?: ModelStringInput | null;
  triggerType?: ModelCameraEventTriggerTypeInput | null;
  objectsFound?: ModelCameraEventObjectTypeListInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  incidentPriority?: ModelIntInput | null;
  cameraEventCameraId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  startTime?: ModelStringInput | null;
  endTime?: ModelStringInput | null;
  resolvedTime?: ModelStringInput | null;
  instantaneous?: ModelBooleanInput | null;
  cameraEventUrl?: ModelStringInput | null;
  cameraEventUrlPublic?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  vmsUserId?: ModelStringInput | null;
  ackTimestamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  vmsSourceType?: ModelVMSSourceTypeInput | null;
  and?: Array<ModelCameraEventFilterInput | null> | null;
  or?: Array<ModelCameraEventFilterInput | null> | null;
  not?: ModelCameraEventFilterInput | null;
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelCustomerTypeInput | null;
  status?: ModelDataEntryStatusInput | null;
  integratorId?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  criticalNotes?: ModelStringInput | null;
  criticalNotesTranslated?: ModelStringInput | null;
  criticalNotesSanitized?: ModelStringInput | null;
  externalCriticalNotes?: ModelStringInput | null;
  externalCriticalNotesTranslated?: ModelStringInput | null;
  externalCriticalNotesSanitized?: ModelStringInput | null;
  vmsCustomerId?: ModelIntInput | null;
  vmsMCWebhookId?: ModelStringInput | null;
  vmsMonitoringCenterRoleId?: ModelIntInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  slaL1?: ModelStringInput | null;
  slaL2?: ModelStringInput | null;
  slaL3?: ModelStringInput | null;
  slaL4?: ModelStringInput | null;
  sortName?: ModelStringInput | null;
  softphoneEnabled?: ModelBooleanInput | null;
  softphoneNumber?: ModelStringInput | null;
  callbackNumber?: ModelStringInput | null;
  callbackNumberExt?: ModelStringInput | null;
  zendeskWidgetScript?: ModelStringInput | null;
  hubspotId?: ModelStringInput | null;
  hasLogo?: ModelBooleanInput | null;
  mobileLogo?: ModelStringInput | null;
  invoicePrefix?: ModelStringInput | null;
  invoiceNumber?: ModelIntInput | null;
  invoiceFooter?: ModelStringInput | null;
  errorEmail?: ModelStringInput | null;
  vmsUrl?: ModelStringInput | null;
  vmsAlarmUrl?: ModelStringInput | null;
  vmsAdminUrl?: ModelStringInput | null;
  orgDomainUrl?: ModelStringInput | null;
  fromEmail?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  allowCustomerViewPendingUntil?: ModelStringInput | null;
  and?: Array<ModelCustomerFilterInput | null> | null;
  or?: Array<ModelCustomerFilterInput | null> | null;
  not?: ModelCustomerFilterInput | null;
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection";
  items: Array<Customer | null>;
  nextToken?: string | null;
};

export type ModelVMSDetailsFilterInput = {
  id?: ModelIDInput | null;
  apiKey?: ModelStringInput | null;
  username?: ModelStringInput | null;
  password?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  accessTokenExpires?: ModelStringInput | null;
  refreshTokenExpires?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelVMSDetailsFilterInput | null> | null;
  or?: Array<ModelVMSDetailsFilterInput | null> | null;
  not?: ModelVMSDetailsFilterInput | null;
};

export type ModelVMSDetailsConnection = {
  __typename: "ModelVMSDetailsConnection";
  items: Array<VMSDetails | null>;
  nextToken?: string | null;
};

export type ModelDashboardDetailFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  awsId?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  allowedGroups?: ModelUserGroupListInput | null;
  integratorId?: ModelIDInput | null;
  username?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelDashboardDetailFilterInput | null> | null;
  or?: Array<ModelDashboardDetailFilterInput | null> | null;
  not?: ModelDashboardDetailFilterInput | null;
};

export type ModelDashboardDetailConnection = {
  __typename: "ModelDashboardDetailConnection";
  items: Array<DashboardDetail | null>;
  nextToken?: string | null;
};

export type ModelBoldBIDetailsFilterInput = {
  id?: ModelIDInput | null;
  siteUsername?: ModelStringInput | null;
  sitePassword?: ModelStringInput | null;
  siteUrl?: ModelStringInput | null;
  siteDefaultDashboardId?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  monitoringCenterId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelBoldBIDetailsFilterInput | null> | null;
  or?: Array<ModelBoldBIDetailsFilterInput | null> | null;
  not?: ModelBoldBIDetailsFilterInput | null;
};

export type ModelBoldBIDetailsConnection = {
  __typename: "ModelBoldBIDetailsConnection";
  items: Array<BoldBIDetails | null>;
  nextToken?: string | null;
};

export type ModelRapidSOSDetailsFilterInput = {
  id?: ModelIDInput | null;
  clientId?: ModelStringInput | null;
  clientSecret?: ModelStringInput | null;
  webhookEnabled?: ModelBooleanInput | null;
  agencyId?: ModelStringInput | null;
  webhookId?: ModelIntInput | null;
  customerId?: ModelStringInput | null;
  monitoringCenterId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelRapidSOSDetailsFilterInput | null> | null;
  or?: Array<ModelRapidSOSDetailsFilterInput | null> | null;
  not?: ModelRapidSOSDetailsFilterInput | null;
};

export type ModelRapidSOSDetailsConnection = {
  __typename: "ModelRapidSOSDetailsConnection";
  items: Array<RapidSOSDetails | null>;
  nextToken?: string | null;
};

export type ModelAddressFilterInput = {
  id?: ModelIDInput | null;
  lineOne?: ModelStringInput | null;
  lineTwo?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  country?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelAddressFilterInput | null> | null;
  or?: Array<ModelAddressFilterInput | null> | null;
  not?: ModelAddressFilterInput | null;
};

export type ModelAddressConnection = {
  __typename: "ModelAddressConnection";
  items: Array<Address | null>;
  nextToken?: string | null;
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  ext?: ModelStringInput | null;
  email?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  customerCanShareSecurables?: ModelBooleanInput | null;
  customerCanLoginAdminPortal?: ModelBooleanInput | null;
  and?: Array<ModelContactFilterInput | null> | null;
  or?: Array<ModelContactFilterInput | null> | null;
  not?: ModelContactFilterInput | null;
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection";
  items: Array<Contact | null>;
  nextToken?: string | null;
};

export type ModelPendingSiteFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  status?: ModelPendingEntityStatusInput | null;
  notes?: ModelStringInput | null;
  primaryEmail?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelPendingSiteFilterInput | null> | null;
  or?: Array<ModelPendingSiteFilterInput | null> | null;
  not?: ModelPendingSiteFilterInput | null;
};

export type ModelPendingSiteConnection = {
  __typename: "ModelPendingSiteConnection";
  items: Array<PendingSite | null>;
  nextToken?: string | null;
};

export type ModelSiteFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  status?: ModelDataEntryStatusInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  criticalNotes?: ModelStringInput | null;
  criticalNotesTranslated?: ModelStringInput | null;
  criticalNotesSanitized?: ModelStringInput | null;
  externalCriticalNotes?: ModelStringInput | null;
  externalCriticalNotesTranslated?: ModelStringInput | null;
  externalCriticalNotesSanitized?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  timezone?: ModelStringInput | null;
  vmsSharedMonitoringRoleId?: ModelIntInput | null;
  monitoringStatus?: ModelMonitoringStatusInput | null;
  maintainCamerasOnMonitoringRole?: ModelBooleanInput | null;
  armingRulesConfig?: ModelStringInput | null;
  monitoringState?: ModelMonitoringStatesInput | null;
  armedState?: ModelArmedStatesInput | null;
  alarmState?: ModelAlarmStatesInput | null;
  supervisionEnabled?: ModelBooleanInput | null;
  triggersEnabled?: ModelBooleanInput | null;
  muteAfterInteractionCount?: ModelIntInput | null;
  type?: ModelSiteTypeInput | null;
  alarmsCentralStation?: ModelStringInput | null;
  alarmsCentralStationPhone?: ModelStringInput | null;
  alarmsCentralStationPhoneExt?: ModelStringInput | null;
  alarmsVerbalPasscode?: ModelStringInput | null;
  alarmsCSID?: ModelStringInput | null;
  alarmsAR?: ModelStringInput | null;
  alarmsNotes?: ModelStringInput | null;
  alarmsNotesTranslated?: ModelStringInput | null;
  alarmsNotesSanitized?: ModelStringInput | null;
  alarmsPermitNum?: ModelStringInput | null;
  alarmsPermitExpires?: ModelStringInput | null;
  autoSendToTrackTik?: ModelBooleanInput | null;
  hasSiteMap?: ModelBooleanInput | null;
  condensedAddress?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  allowCustomerViewPendingUntil?: ModelStringInput | null;
  and?: Array<ModelSiteFilterInput | null> | null;
  or?: Array<ModelSiteFilterInput | null> | null;
  not?: ModelSiteFilterInput | null;
};

export type ModelSiteSupvisdSettingFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSiteSupvisdSettingFilterInput | null> | null;
  or?: Array<ModelSiteSupvisdSettingFilterInput | null> | null;
  not?: ModelSiteSupvisdSettingFilterInput | null;
};

export type ModelSiteSupvisdSettingConnection = {
  __typename: "ModelSiteSupvisdSettingConnection";
  items: Array<SiteSupvisdSetting | null>;
  nextToken?: string | null;
};

export type ModelSupvisdEventRuleFilterInput = {
  id?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  muteSitePersonRule?: ModelBooleanInput | null;
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  siteSupvisdSettingId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  ruleType?: ModelRuleTypeInput | null;
  cameraEventType?: ModelCameraEventSupervisionTypeInput | null;
  disposition?: ModelEventTypeInput | null;
  confidence?: ModelIntInput | null;
  and?: Array<ModelSupvisdEventRuleFilterInput | null> | null;
  or?: Array<ModelSupvisdEventRuleFilterInput | null> | null;
  not?: ModelSupvisdEventRuleFilterInput | null;
};

export type ModelSiteDeviceStatusConfigFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  deviceStatusEnabled?: ModelBooleanInput | null;
  statusFrequency?: ModelDeviceStatusCheckSLAInput | null;
  statusVerifiedTolerance?: ModelDeviceStatusCheckSLAInput | null;
  statusProperties?: ModelDeviceStatusPropertiesListInput | null;
  and?: Array<ModelSiteDeviceStatusConfigFilterInput | null> | null;
  or?: Array<ModelSiteDeviceStatusConfigFilterInput | null> | null;
  not?: ModelSiteDeviceStatusConfigFilterInput | null;
};

export type ModelSiteDeviceStatusConfigConnection = {
  __typename: "ModelSiteDeviceStatusConfigConnection";
  items: Array<SiteDeviceStatusConfig | null>;
  nextToken?: string | null;
};

export type ModelTriggerFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  type?: ModelTriggerTypeInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  frequencyLimit?: ModelIntInput | null;
  lastTriggered?: ModelStringInput | null;
  and?: Array<ModelTriggerFilterInput | null> | null;
  or?: Array<ModelTriggerFilterInput | null> | null;
  not?: ModelTriggerFilterInput | null;
};

export type ModelTriggerConnection = {
  __typename: "ModelTriggerConnection";
  items: Array<Trigger | null>;
  nextToken?: string | null;
};

export type ModelSiteIntegrationFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  type?: ModelIntegrationTypeInput | null;
  subType?: ModelIntegrationSubTypeInput | null;
  subTypeList?: ModelIntegrationSubTypeListInput | null;
  agency?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  phoneExt?: ModelStringInput | null;
  email?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSiteIntegrationFilterInput | null> | null;
  or?: Array<ModelSiteIntegrationFilterInput | null> | null;
  not?: ModelSiteIntegrationFilterInput | null;
};

export type ModelSiteVMSDetailsFilterInput = {
  id?: ModelIDInput | null;
  username?: ModelStringInput | null;
  password?: ModelStringInput | null;
  apiKey?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  accessTokenExpires?: ModelStringInput | null;
  refreshTokenExpires?: ModelStringInput | null;
  siteId?: ModelIDInput | null;
  siteUserId?: ModelIDInput | null;
  siteUserRoleId?: ModelIntInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSiteVMSDetailsFilterInput | null> | null;
  or?: Array<ModelSiteVMSDetailsFilterInput | null> | null;
  not?: ModelSiteVMSDetailsFilterInput | null;
};

export type ModelSiteVMSDetailsConnection = {
  __typename: "ModelSiteVMSDetailsConnection";
  items: Array<SiteVMSDetails | null>;
  nextToken?: string | null;
};

export type ModelTrackTikDetailsFilterInput = {
  id?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  apiUrl?: ModelStringInput | null;
  apiUsername?: ModelStringInput | null;
  apiPassword?: ModelStringInput | null;
  accountId?: ModelStringInput | null;
  token?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  tokenExpiry?: ModelStringInput | null;
  refreshTokenExpiry?: ModelStringInput | null;
  autoSend?: ModelBooleanInput | null;
  autoSendErrorEmail?: ModelStringInput | null;
  configured?: ModelBooleanInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTrackTikDetailsFilterInput | null> | null;
  or?: Array<ModelTrackTikDetailsFilterInput | null> | null;
  not?: ModelTrackTikDetailsFilterInput | null;
};

export type ModelTrackTikDetailsConnection = {
  __typename: "ModelTrackTikDetailsConnection";
  items: Array<TrackTikDetails | null>;
  nextToken?: string | null;
};

export type ModelTrackTikTaskFilterInput = {
  id?: ModelIDInput | null;
  trackTikDetailsId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  eventType?: ModelEventTypeInput | null;
  trackTikName?: ModelStringInput | null;
  trackTikId?: ModelIntInput | null;
  trackTikReportTemplateId?: ModelIntInput | null;
  archived?: ModelBooleanInput | null;
  modifedBy?: ModelStringInput | null;
  and?: Array<ModelTrackTikTaskFilterInput | null> | null;
  or?: Array<ModelTrackTikTaskFilterInput | null> | null;
  not?: ModelTrackTikTaskFilterInput | null;
};

export type ModelSiteEmergencyContactFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelSiteContactTypeListInput | null;
  verbalPasscode?: ModelStringInput | null;
  email?: ModelStringInput | null;
  priority?: ModelIntInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  archived?: ModelBooleanInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelSiteEmergencyContactFilterInput | null> | null;
  or?: Array<ModelSiteEmergencyContactFilterInput | null> | null;
  not?: ModelSiteEmergencyContactFilterInput | null;
};

export type ModelPhoneFilterInput = {
  id?: ModelIDInput | null;
  contactId?: ModelIDInput | null;
  type?: ModelPhoneTypeInput | null;
  number?: ModelStringInput | null;
  ext?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelPhoneFilterInput | null> | null;
  or?: Array<ModelPhoneFilterInput | null> | null;
  not?: ModelPhoneFilterInput | null;
};

export type ModelCameraFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  deviceType?: ModelDeviceTypeInput | null;
  status?: ModelDataEntryStatusInput | null;
  vmsCameraName?: ModelStringInput | null;
  description?: ModelStringInput | null;
  descriptionTranslated?: ModelStringInput | null;
  descriptionSanitized?: ModelStringInput | null;
  vmsDescription?: ModelStringInput | null;
  siteId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  criticalNotes?: ModelStringInput | null;
  criticalNotesTranslated?: ModelStringInput | null;
  criticalNotesSanitized?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  vmsCameraId?: ModelStringInput | null;
  vmsCustomerId?: ModelStringInput | null;
  testingExpiry?: ModelStringInput | null;
  armedState?: ModelArmedStatesInput | null;
  snoozeReason?: ModelStringInput | null;
  snoozeReasonTranslated?: ModelStringInput | null;
  snoozeReasonSanitized?: ModelStringInput | null;
  snoozeExpiry?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  zoneNumber?: ModelStringInput | null;
  zoneDescription?: ModelStringInput | null;
  zoneDescriptionTranslated?: ModelStringInput | null;
  zoneDescriptionSanitized?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  isOnvif?: ModelBooleanInput | null;
  videoStreamState?: ModelStringInput | null;
  deviceUrlOnline?: ModelBooleanInput | null;
  vmsConnect?: ModelBooleanInput | null;
  isP2P?: ModelBooleanInput | null;
  isAnalyticsEnabled?: ModelBooleanInput | null;
  isMotionEnabled?: ModelBooleanInput | null;
  testEventReceived?: ModelBooleanInput | null;
  healthCheckEnabled?: ModelBooleanInput | null;
  healthCheckSLA?: ModelDeviceHealthCheckSLAInput | null;
  healthCheckStatus?: ModelHealthCheckStatusInput | null;
  ignoreStatusChecks?: ModelBooleanInput | null;
  statusChecksProperties?: ModelDeviceStatusPropertiesListInput | null;
  statusLastVerified?: ModelStringInput | null;
  archiveDurationHours?: ModelIntInput | null;
  accessUrlsId?: ModelIDInput | null;
  credentialsId?: ModelIDInput | null;
  deviceBrand?: ModelStringInput | null;
  deviceModel?: ModelStringInput | null;
  vmsPlanId?: ModelIntInput | null;
  phpRegistrationCode?: ModelStringInput | null;
  serialNumber?: ModelStringInput | null;
  p2pState?: ModelStringInput | null;
  lastPowerCycle?: ModelStringInput | null;
  baselineThumbnailFilename?: ModelStringInput | null;
  currentThumbnailFilename?: ModelStringInput | null;
  sceneChangeDetectionEnabled?: ModelBooleanInput | null;
  passingSceneDetection?: ModelBooleanInput | null;
  hubspotId?: ModelStringInput | null;
  immixMonitoring?: ModelBooleanInput | null;
  hasMic?: ModelBooleanInput | null;
  hasSpeaker?: ModelBooleanInput | null;
  audioGroups?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCameraFilterInput | null> | null;
  or?: Array<ModelCameraFilterInput | null> | null;
  not?: ModelCameraFilterInput | null;
};

export type ModelObjectGroupFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameTranslated?: ModelStringInput | null;
  nameSanitized?: ModelStringInput | null;
  groupType?: ModelObjectGroupTypeInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelObjectGroupFilterInput | null> | null;
  or?: Array<ModelObjectGroupFilterInput | null> | null;
  not?: ModelObjectGroupFilterInput | null;
};

export type ModelObjectGroupConnection = {
  __typename: "ModelObjectGroupConnection";
  items: Array<ObjectGroup | null>;
  nextToken?: string | null;
};

export type ModelAudioCustomMessageTypeFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  nameTranslated?: ModelStringInput | null;
  nameSanitized?: ModelStringInput | null;
  transcript?: ModelStringInput | null;
  transcriptTranslated?: ModelStringInput | null;
  transcriptSanitized?: ModelStringInput | null;
  note?: ModelStringInput | null;
  noteTranslated?: ModelStringInput | null;
  noteSanitized?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelAudioCustomMessageTypeFilterInput | null> | null;
  or?: Array<ModelAudioCustomMessageTypeFilterInput | null> | null;
  not?: ModelAudioCustomMessageTypeFilterInput | null;
};

export type ModelAudioCustomMessageTypeConnection = {
  __typename: "ModelAudioCustomMessageTypeConnection";
  items: Array<AudioCustomMessageType | null>;
  nextToken?: string | null;
};

export type ModelCameraQualitySettingsFilterInput = {
  id?: ModelIDInput | null;
  width?: ModelIntInput | null;
  height?: ModelIntInput | null;
  videoBitRate?: ModelIntInput | null;
  frameRate?: ModelIntInput | null;
  monitoringCenterId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCameraQualitySettingsFilterInput | null> | null;
  or?: Array<ModelCameraQualitySettingsFilterInput | null> | null;
  not?: ModelCameraQualitySettingsFilterInput | null;
};

export type ModelCameraQualitySettingsConnection = {
  __typename: "ModelCameraQualitySettingsConnection";
  items: Array<CameraQualitySettings | null>;
  nextToken?: string | null;
};

export type ModelCameraSceneDetectionSettingsFilterInput = {
  id?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCameraSceneDetectionSettingsFilterInput | null> | null;
  or?: Array<ModelCameraSceneDetectionSettingsFilterInput | null> | null;
  not?: ModelCameraSceneDetectionSettingsFilterInput | null;
};

export type ModelCameraSceneDetectionSettingsConnection = {
  __typename: "ModelCameraSceneDetectionSettingsConnection";
  items: Array<CameraSceneDetectionSettings | null>;
  nextToken?: string | null;
};

export type ModelCameraSceneDetectionThresholdFilterInput = {
  id?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  cameraSceneDetectionSettingsId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  objectName?: ModelStringInput | null;
  baselineValue?: ModelFloatInput | null;
  thresholdValue?: ModelFloatInput | null;
  and?: Array<ModelCameraSceneDetectionThresholdFilterInput | null> | null;
  or?: Array<ModelCameraSceneDetectionThresholdFilterInput | null> | null;
  not?: ModelCameraSceneDetectionThresholdFilterInput | null;
};

export type ModelCameraSceneDetectionResultFilterInput = {
  id?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  passing?: ModelBooleanInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelCameraSceneDetectionResultFilterInput | null> | null;
  or?: Array<ModelCameraSceneDetectionResultFilterInput | null> | null;
  not?: ModelCameraSceneDetectionResultFilterInput | null;
};

export type ModelCameraSceneDetectionResultConnection = {
  __typename: "ModelCameraSceneDetectionResultConnection";
  items: Array<CameraSceneDetectionResult | null>;
  nextToken?: string | null;
};

export type ModelCameraSceneDetectionResultItemFilterInput = {
  id?: ModelIDInput | null;
  cameraSceneDetectionResultId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  objectName?: ModelStringInput | null;
  thresholdValue?: ModelFloatInput | null;
  actualValue?: ModelFloatInput | null;
  and?: Array<ModelCameraSceneDetectionResultItemFilterInput | null> | null;
  or?: Array<ModelCameraSceneDetectionResultItemFilterInput | null> | null;
  not?: ModelCameraSceneDetectionResultItemFilterInput | null;
};

export type ModelCameraCredentialsFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  password?: ModelStringInput | null;
  cameraId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCameraCredentialsFilterInput | null> | null;
  or?: Array<ModelCameraCredentialsFilterInput | null> | null;
  not?: ModelCameraCredentialsFilterInput | null;
};

export type ModelCameraCredentialsConnection = {
  __typename: "ModelCameraCredentialsConnection";
  items: Array<CameraCredentials | null>;
  nextToken?: string | null;
};

export type ModelCameraAccessUrlsFilterInput = {
  id?: ModelIDInput | null;
  dashStream?: ModelStringInput | null;
  hlsStream?: ModelStringInput | null;
  http?: ModelStringInput | null;
  rtmpStream?: ModelStringInput | null;
  rtsp?: ModelStringInput | null;
  snapShot?: ModelStringInput | null;
  webRtc?: ModelStringInput | null;
  cameraId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCameraAccessUrlsFilterInput | null> | null;
  or?: Array<ModelCameraAccessUrlsFilterInput | null> | null;
  not?: ModelCameraAccessUrlsFilterInput | null;
};

export type ModelCameraAccessUrlsConnection = {
  __typename: "ModelCameraAccessUrlsConnection";
  items: Array<CameraAccessUrls | null>;
  nextToken?: string | null;
};

export type ModelCameraImmixConfigFilterInput = {
  id?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  vmsCameraId?: ModelStringInput | null;
  vmsWebhookId?: ModelStringInput | null;
  immixEmail?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelCameraImmixConfigFilterInput | null> | null;
  or?: Array<ModelCameraImmixConfigFilterInput | null> | null;
  not?: ModelCameraImmixConfigFilterInput | null;
};

export type ModelCameraImmixConfigConnection = {
  __typename: "ModelCameraImmixConfigConnection";
  items: Array<CameraImmixConfig | null>;
  nextToken?: string | null;
};

export type ModelActivityLogFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  modifiedOn?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  type?: ModelActivityLogTypeInput | null;
  subSystem?: ModelActivityLogSubSystemInput | null;
  event?: ModelActivityLogEventInput | null;
  reference?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelActivityLogFilterInput | null> | null;
  or?: Array<ModelActivityLogFilterInput | null> | null;
  not?: ModelActivityLogFilterInput | null;
};

export type ModelActivityLogConnection = {
  __typename: "ModelActivityLogConnection";
  items: Array<ActivityLog | null>;
  nextToken?: string | null;
};

export type ModelSignalTestLogFilterInput = {
  id?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  vmsEventId?: ModelStringInput | null;
  starred?: ModelActiveInactiveInput | null;
  thumbnailFilename?: ModelStringInput | null;
  and?: Array<ModelSignalTestLogFilterInput | null> | null;
  or?: Array<ModelSignalTestLogFilterInput | null> | null;
  not?: ModelSignalTestLogFilterInput | null;
};

export type ModelSignalTestLogConnection = {
  __typename: "ModelSignalTestLogConnection";
  items: Array<SignalTestLog | null>;
  nextToken?: string | null;
};

export type ModelAuditLogFilterInput = {
  id?: ModelIDInput | null;
  partition?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedOn?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  area?: ModelAuditLogAreaInput | null;
  action?: ModelAuditLogActionInput | null;
  entityModel?: ModelStringInput | null;
  entityKey?: ModelStringInput | null;
  description?: ModelStringInput | null;
  newImage?: ModelStringInput | null;
  oldImage?: ModelStringInput | null;
  and?: Array<ModelAuditLogFilterInput | null> | null;
  or?: Array<ModelAuditLogFilterInput | null> | null;
  not?: ModelAuditLogFilterInput | null;
};

export type ModelAuditLogConnection = {
  __typename: "ModelAuditLogConnection";
  items: Array<AuditLog | null>;
  nextToken?: string | null;
};

export type ModelIncidentActionDetailFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelIncidentActionDetailFilterInput | null> | null;
  or?: Array<ModelIncidentActionDetailFilterInput | null> | null;
  not?: ModelIncidentActionDetailFilterInput | null;
};

export type ModelIncidentActionDetailConnection = {
  __typename: "ModelIncidentActionDetailConnection";
  items: Array<IncidentActionDetail | null>;
  nextToken?: string | null;
};

export type ModelSOPFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  eventType?: ModelEventTypeInput | null;
  eventSOPs?: ModelEventOpListInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSOPFilterInput | null> | null;
  or?: Array<ModelSOPFilterInput | null> | null;
  not?: ModelSOPFilterInput | null;
};

export type ModelRspndrEventsFilterInput = {
  id?: ModelIDInput | null;
  request_id?: ModelIDInput | null;
  tenant_id?: ModelStringInput | null;
  error?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelRspndrEventsFilterInput | null> | null;
  or?: Array<ModelRspndrEventsFilterInput | null> | null;
  not?: ModelRspndrEventsFilterInput | null;
};

export type ModelRspndrEventsConnection = {
  __typename: "ModelRspndrEventsConnection";
  items: Array<RspndrEvents | null>;
  nextToken?: string | null;
};

export type ModelRspndrIncidentFilterInput = {
  id?: ModelIDInput | null;
  incident_id?: ModelStringInput | null;
  address1?: ModelStringInput | null;
  address2?: ModelStringInput | null;
  arrived_at?: ModelStringInput | null;
  billed_to?: ModelRspndrBilledToInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  created_at?: ModelStringInput | null;
  customer_number?: ModelStringInput | null;
  dealer?: ModelStringInput | null;
  eta_at?: ModelStringInput | null;
  ext_username?: ModelStringInput | null;
  finished_at?: ModelStringInput | null;
  grounded_at?: ModelStringInput | null;
  grounding_end_confirm_acked_at?: ModelStringInput | null;
  grounding_end_confirmed_at?: ModelStringInput | null;
  grounding_end_decline_acked_at?: ModelStringInput | null;
  grounding_end_declined_at?: ModelStringInput | null;
  grounding_ended_at?: ModelStringInput | null;
  grounding_ending_at?: ModelStringInput | null;
  grounding_request_confirm_acked_at?: ModelStringInput | null;
  grounding_request_confirmed_at?: ModelStringInput | null;
  grounding_request_decline_acked_at?: ModelStringInput | null;
  grounding_request_declined_at?: ModelStringInput | null;
  grounding_requested_at?: ModelStringInput | null;
  grounding_terminate_acked_at?: ModelStringInput | null;
  grounding_terminating_at?: ModelStringInput | null;
  kind?: ModelRspndrServiceKindInput | null;
  lat?: ModelFloatInput | null;
  lng?: ModelFloatInput | null;
  master_account_number?: ModelStringInput | null;
  modified_at?: ModelStringInput | null;
  name?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  postal_code?: ModelStringInput | null;
  state?: ModelStringInput | null;
  status?: ModelRspndrIncidentStatusInput | null;
  taken_at?: ModelStringInput | null;
  tenant_id?: ModelStringInput | null;
  timezone?: ModelStringInput | null;
  triggered_at?: ModelStringInput | null;
  type?: ModelStringInput | null;
  zone?: ModelStringInput | null;
  monitoringCenterId?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  integratorId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelRspndrIncidentFilterInput | null> | null;
  or?: Array<ModelRspndrIncidentFilterInput | null> | null;
  not?: ModelRspndrIncidentFilterInput | null;
};

export type ModelRspndrIncidentConnection = {
  __typename: "ModelRspndrIncidentConnection";
  items: Array<RspndrIncident | null>;
  nextToken?: string | null;
};

export type ModelRspndrIncidentCheckItemFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  rspndrIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  description?: ModelStringInput | null;
  descriptionTranslated?: ModelStringInput | null;
  descriptionSanitized?: ModelStringInput | null;
  image_required?: ModelBooleanInput | null;
  image_url?: ModelStringInput | null;
  order?: ModelIntInput | null;
  required?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRspndrIncidentCheckItemFilterInput | null> | null;
  or?: Array<ModelRspndrIncidentCheckItemFilterInput | null> | null;
  not?: ModelRspndrIncidentCheckItemFilterInput | null;
};

export type ModelRspndrIncidentGroundingRuleFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  rspndrIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  call_police?: ModelBooleanInput | null;
  expires_at?: ModelStringInput | null;
  leave_when?: ModelRspndrLeaveWhenInput | null;
  max_duration?: ModelStringInput | null;
  order?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelRspndrIncidentGroundingRuleFilterInput | null> | null;
  or?: Array<ModelRspndrIncidentGroundingRuleFilterInput | null> | null;
  not?: ModelRspndrIncidentGroundingRuleFilterInput | null;
};

export type ModelRspndrActivityFilterInput = {
  id?: ModelIDInput | null;
  tenant_id?: ModelStringInput | null;
  request_id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  rspndrIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  error?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  type?: ModelRspndrIncidentStatusInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  and?: Array<ModelRspndrActivityFilterInput | null> | null;
  or?: Array<ModelRspndrActivityFilterInput | null> | null;
  not?: ModelRspndrActivityFilterInput | null;
};

export type ModelTrackTikIncidentFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  status?: ModelTrackTikIncidentStatusInput | null;
  reportTemplateId?: ModelIntInput | null;
  taskTypeId?: ModelIntInput | null;
  taskTypeName?: ModelStringInput | null;
  priority?: ModelTrackTikPriorityInput | null;
  trackTikId?: ModelIntInput | null;
  primaryCameraEventId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTrackTikIncidentFilterInput | null> | null;
  or?: Array<ModelTrackTikIncidentFilterInput | null> | null;
  not?: ModelTrackTikIncidentFilterInput | null;
};

export type ModelTrackTikIncidentConnection = {
  __typename: "ModelTrackTikIncidentConnection";
  items: Array<TrackTikIncident | null>;
  nextToken?: string | null;
};

export type ModelTrackTikActivityFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  trackTikIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTrackTikActivityFilterInput | null> | null;
  or?: Array<ModelTrackTikActivityFilterInput | null> | null;
  not?: ModelTrackTikActivityFilterInput | null;
};

export type ModelRapidSOSIncidentFilterInput = {
  id?: ModelIDInput | null;
  coverageType?: ModelRapidSOSCoverageTypeInput | null;
  alert_id?: ModelStringInput | null;
  covering_psap?: ModelStringInput | null;
  callflow?: ModelStringInput | null;
  zone_description?: ModelStringInput | null;
  alarm_permit_number?: ModelStringInput | null;
  visually_verified?: ModelBooleanInput | null;
  site_phone?: ModelStringInput | null;
  status?: ModelRapidSOSIncidentStatusInput | null;
  instructions?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  primaryCameraEventId?: ModelIDInput | null;
  siteIntegrationId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  customerId?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  and?: Array<ModelRapidSOSIncidentFilterInput | null> | null;
  or?: Array<ModelRapidSOSIncidentFilterInput | null> | null;
  not?: ModelRapidSOSIncidentFilterInput | null;
};

export type ModelRapidSOSIncidentConnection = {
  __typename: "ModelRapidSOSIncidentConnection";
  items: Array<RapidSOSIncident | null>;
  nextToken?: string | null;
};

export type ModelRapidSOSEventDetailsFilterInput = {
  id?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  descriptionTranslated?: ModelStringInput | null;
  descriptionSanitized?: ModelStringInput | null;
  emergency_type?: ModelRapidSOSEmergencyTypeInput | null;
  incident_time?: ModelStringInput | null;
  service_provider_name?: ModelStringInput | null;
  site_type?: ModelSiteTypeInput | null;
  source_id?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  and?: Array<ModelRapidSOSEventDetailsFilterInput | null> | null;
  or?: Array<ModelRapidSOSEventDetailsFilterInput | null> | null;
  not?: ModelRapidSOSEventDetailsFilterInput | null;
};

export type ModelRapidSOSEventDetailsConnection = {
  __typename: "ModelRapidSOSEventDetailsConnection";
  items: Array<RapidSOSEventDetails | null>;
  nextToken?: string | null;
};

export type ModelRapidSOSLocationFilterInput = {
  id?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  and?: Array<ModelRapidSOSLocationFilterInput | null> | null;
  or?: Array<ModelRapidSOSLocationFilterInput | null> | null;
  not?: ModelRapidSOSLocationFilterInput | null;
};

export type ModelRapidSOSLocationConnection = {
  __typename: "ModelRapidSOSLocationConnection";
  items: Array<RapidSOSLocation | null>;
  nextToken?: string | null;
};

export type ModelRapidSOSCivicAddressFilterInput = {
  id?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  street_1?: ModelStringInput | null;
  street_2?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  country?: ModelStringInput | null;
  zip_code?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  and?: Array<ModelRapidSOSCivicAddressFilterInput | null> | null;
  or?: Array<ModelRapidSOSCivicAddressFilterInput | null> | null;
  not?: ModelRapidSOSCivicAddressFilterInput | null;
};

export type ModelRapidSOSCivicAddressConnection = {
  __typename: "ModelRapidSOSCivicAddressConnection";
  items: Array<RapidSOSCivicAddress | null>;
  nextToken?: string | null;
};

export type ModelRapidSOSEmergencyContactFilterInput = {
  id?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  and?: Array<ModelRapidSOSEmergencyContactFilterInput | null> | null;
  or?: Array<ModelRapidSOSEmergencyContactFilterInput | null> | null;
  not?: ModelRapidSOSEmergencyContactFilterInput | null;
};

export type ModelRapidSOSActivityFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  error?: ModelStringInput | null;
  payload?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  notesTranslated?: ModelStringInput | null;
  notesSanitized?: ModelStringInput | null;
  and?: Array<ModelRapidSOSActivityFilterInput | null> | null;
  or?: Array<ModelRapidSOSActivityFilterInput | null> | null;
  not?: ModelRapidSOSActivityFilterInput | null;
};

export type ModelRapidSOSLogMessageFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  type?: ModelRapidSOSLogMessageTypeInput | null;
  modifiedBy?: ModelStringInput | null;
  writtenBy?: ModelStringInput | null;
  message?: ModelStringInput | null;
  messageTranslated?: ModelStringInput | null;
  messageSanitized?: ModelStringInput | null;
  and?: Array<ModelRapidSOSLogMessageFilterInput | null> | null;
  or?: Array<ModelRapidSOSLogMessageFilterInput | null> | null;
  not?: ModelRapidSOSLogMessageFilterInput | null;
};

export type ModelRapidSOSLogMessageConnection = {
  __typename: "ModelRapidSOSLogMessageConnection";
  items: Array<RapidSOSLogMessage | null>;
  nextToken?: string | null;
};

export type ModelRapidSOSAttachmentFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  siteIntegrationId?: ModelIDInput | null;
  rapidSOSIncidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  and?: Array<ModelRapidSOSAttachmentFilterInput | null> | null;
  or?: Array<ModelRapidSOSAttachmentFilterInput | null> | null;
  not?: ModelRapidSOSAttachmentFilterInput | null;
};

export type ModelIncidentCodeDetailFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  publicCode?: ModelStringInput | null;
  shareUrl?: ModelStringInput | null;
  locationSid?: ModelIDInput | null;
  deviceSid?: ModelIDInput | null;
  eventDate?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelIncidentCodeDetailFilterInput | null> | null;
  or?: Array<ModelIncidentCodeDetailFilterInput | null> | null;
  not?: ModelIncidentCodeDetailFilterInput | null;
};

export type ModelIncidentCodeDetailConnection = {
  __typename: "ModelIncidentCodeDetailConnection";
  items: Array<IncidentCodeDetail | null>;
  nextToken?: string | null;
};

export type ModelIncidentShareSettingsFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  code?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  expiration?: ModelStringInput | null;
  and?: Array<ModelIncidentShareSettingsFilterInput | null> | null;
  or?: Array<ModelIncidentShareSettingsFilterInput | null> | null;
  not?: ModelIncidentShareSettingsFilterInput | null;
};

export type ModelIncidentShareSettingsConnection = {
  __typename: "ModelIncidentShareSettingsConnection";
  items: Array<IncidentShareSettings | null>;
  nextToken?: string | null;
};

export type ModelIncidentDataToShareFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  monitoringCenterId?: ModelStringInput | null;
  shareType?: ModelIncidentShareTypeInput | null;
  externalIncidentNotes?: ModelBooleanInput | null;
  externalSiteCriticalNotes?: ModelBooleanInput | null;
  externalClientCriticalNotes?: ModelBooleanInput | null;
  completedActionItems?: ModelBooleanInput | null;
  objectsFound?: ModelBooleanInput | null;
  alarmStationInfo?: ModelBooleanInput | null;
  customerContactInfo?: ModelBooleanInput | null;
  videoFeedUrls?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  attachmentsList?: ModelStringInput | null;
  and?: Array<ModelIncidentDataToShareFilterInput | null> | null;
  or?: Array<ModelIncidentDataToShareFilterInput | null> | null;
  not?: ModelIncidentDataToShareFilterInput | null;
};

export type ModelIncidentDataToShareConnection = {
  __typename: "ModelIncidentDataToShareConnection";
  items: Array<IncidentDataToShare | null>;
  nextToken?: string | null;
};

export type ModelIncidentShareEntryFilterInput = {
  id?: ModelIDInput | null;
  incidentDataToShareId?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  code?: ModelStringInput | null;
  shareType?: ModelIncidentShareTypeInput | null;
  expiration?: ModelStringInput | null;
  alarmsCentralStation?: ModelStringInput | null;
  alarmsPermitNum?: ModelStringInput | null;
  alarmsCSID?: ModelStringInput | null;
  attachmentsList?: ModelStringInput | null;
  incidentOpenTime?: ModelStringInput | null;
  incidentClosedTime?: ModelStringInput | null;
  externalSiteNotes?: ModelStringInput | null;
  externalClientNotes?: ModelStringInput | null;
  and?: Array<ModelIncidentShareEntryFilterInput | null> | null;
  or?: Array<ModelIncidentShareEntryFilterInput | null> | null;
  not?: ModelIncidentShareEntryFilterInput | null;
};

export type ModelIncidentShareEntryConnection = {
  __typename: "ModelIncidentShareEntryConnection";
  items: Array<IncidentShareEntry | null>;
  nextToken?: string | null;
};

export type ModelIncidentShareSiteFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  addressOne?: ModelStringInput | null;
  addressTwo?: ModelStringInput | null;
  city?: ModelStringInput | null;
  state?: ModelStringInput | null;
  country?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  and?: Array<ModelIncidentShareSiteFilterInput | null> | null;
  or?: Array<ModelIncidentShareSiteFilterInput | null> | null;
  not?: ModelIncidentShareSiteFilterInput | null;
};

export type ModelIncidentShareSiteConnection = {
  __typename: "ModelIncidentShareSiteConnection";
  items: Array<IncidentShareSite | null>;
  nextToken?: string | null;
};

export type ModelIncidentShareIntegratorFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  logoPath?: ModelStringInput | null;
  and?: Array<ModelIncidentShareIntegratorFilterInput | null> | null;
  or?: Array<ModelIncidentShareIntegratorFilterInput | null> | null;
  not?: ModelIncidentShareIntegratorFilterInput | null;
};

export type ModelIncidentShareIntegratorConnection = {
  __typename: "ModelIncidentShareIntegratorConnection";
  items: Array<IncidentShareIntegrator | null>;
  nextToken?: string | null;
};

export type ModelIncidentShareEntryAccessFilterInput = {
  id?: ModelIDInput | null;
  incidentShareEntryId?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  code?: ModelStringInput | null;
  success?: ModelBooleanInput | null;
  accessDate?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  and?: Array<ModelIncidentShareEntryAccessFilterInput | null> | null;
  or?: Array<ModelIncidentShareEntryAccessFilterInput | null> | null;
  not?: ModelIncidentShareEntryAccessFilterInput | null;
};

export type ModelIncidentShareEntryAccessConnection = {
  __typename: "ModelIncidentShareEntryAccessConnection";
  items: Array<IncidentShareEntryAccess | null>;
  nextToken?: string | null;
};

export type ModelIncidentShareEntyNoteFilterInput = {
  id?: ModelIDInput | null;
  incidentId?: ModelIDInput | null;
  incidentNoteId?: ModelIDInput | null;
  incidentShareEntryId?: ModelIDInput | null;
  note?: ModelStringInput | null;
  noteCreatedAt?: ModelStringInput | null;
  and?: Array<ModelIncidentShareEntyNoteFilterInput | null> | null;
  or?: Array<ModelIncidentShareEntyNoteFilterInput | null> | null;
  not?: ModelIncidentShareEntyNoteFilterInput | null;
};

export type ModelIncidentShareEventFilterInput = {
  id?: ModelIDInput | null;
  cameraEventId?: ModelIDInput | null;
  startTime?: ModelStringInput | null;
  vmsCameraName?: ModelStringInput | null;
  videoFeedUrl?: ModelStringInput | null;
  eventType?: ModelEventTypeInput | null;
  disposition?: ModelDispositionInput | null;
  triggerType?: ModelCameraEventTriggerTypeInput | null;
  objectsFound?: ModelCameraEventObjectTypeListInput | null;
  incidentShareEntryId?: ModelIDInput | null;
  cameraLatitude?: ModelFloatInput | null;
  cameraLongitude?: ModelFloatInput | null;
  and?: Array<ModelIncidentShareEventFilterInput | null> | null;
  or?: Array<ModelIncidentShareEventFilterInput | null> | null;
  not?: ModelIncidentShareEventFilterInput | null;
};

export type ModelIncidentShareActionItemsFilterInput = {
  id?: ModelIDInput | null;
  incidentProcedureTaskId?: ModelIDInput | null;
  incidentShareEventId?: ModelIDInput | null;
  order?: ModelIntInput | null;
  completedAt?: ModelStringInput | null;
  description?: ModelStringInput | null;
  and?: Array<ModelIncidentShareActionItemsFilterInput | null> | null;
  or?: Array<ModelIncidentShareActionItemsFilterInput | null> | null;
  not?: ModelIncidentShareActionItemsFilterInput | null;
};

export type ModelIncidentShareContactInformationFilterInput = {
  id?: ModelIDInput | null;
  incidentShareEntryId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  ext?: ModelStringInput | null;
  email?: ModelStringInput | null;
  type?: ModelIncidentShareContactTypeInput | null;
  and?: Array<ModelIncidentShareContactInformationFilterInput | null> | null;
  or?: Array<ModelIncidentShareContactInformationFilterInput | null> | null;
  not?: ModelIncidentShareContactInformationFilterInput | null;
};

export type ModelCallNoticeFilterInput = {
  id?: ModelIDInput | null;
  fromNumber?: ModelStringInput | null;
  toNumber?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  answeredTime?: ModelStringInput | null;
  answeredBy?: ModelStringInput | null;
  twilioCallSid?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCallNoticeFilterInput | null> | null;
  or?: Array<ModelCallNoticeFilterInput | null> | null;
  not?: ModelCallNoticeFilterInput | null;
};

export type ModelCallNoticeConnection = {
  __typename: "ModelCallNoticeConnection";
  items: Array<CallNotice | null>;
  nextToken?: string | null;
};

export type ModelCallLogFilterInput = {
  id?: ModelIDInput | null;
  phoneNumber?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  customerId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  callEnd?: ModelStringInput | null;
  storageAudioUrl?: ModelStringInput | null;
  storageTranscriptUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  callingUser?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCallLogFilterInput | null> | null;
  or?: Array<ModelCallLogFilterInput | null> | null;
  not?: ModelCallLogFilterInput | null;
};

export type ModelCallLogConnection = {
  __typename: "ModelCallLogConnection";
  items: Array<CallLog | null>;
  nextToken?: string | null;
};

export type ModelSMSLogFilterInput = {
  id?: ModelIDInput | null;
  twilioSid?: ModelStringInput | null;
  toNumber?: ModelStringInput | null;
  fromNumber?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  customerId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  message?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  textingUser?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelSMSLogFilterInput | null> | null;
  or?: Array<ModelSMSLogFilterInput | null> | null;
  not?: ModelSMSLogFilterInput | null;
};

export type ModelSMSLogConnection = {
  __typename: "ModelSMSLogConnection";
  items: Array<SMSLog | null>;
  nextToken?: string | null;
};

export type ModelEmailLogFilterInput = {
  id?: ModelIDInput | null;
  awsMessageId?: ModelStringInput | null;
  toEmail?: ModelStringInput | null;
  fromEmail?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  customerId?: ModelStringInput | null;
  siteId?: ModelStringInput | null;
  incidentId?: ModelStringInput | null;
  subject?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  sendingUser?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelEmailLogFilterInput | null> | null;
  or?: Array<ModelEmailLogFilterInput | null> | null;
  not?: ModelEmailLogFilterInput | null;
};

export type ModelEmailLogConnection = {
  __typename: "ModelEmailLogConnection";
  items: Array<EmailLog | null>;
  nextToken?: string | null;
};

export type ModelNotificationSettingFilterInput = {
  id?: ModelIDInput | null;
  username?: ModelStringInput | null;
  monitoringCenterId?: ModelStringInput | null;
  notificationType?: ModelNotificationTypeInput | null;
  userGroup?: ModelStringInput | null;
  clientList?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelNotificationSettingFilterInput | null> | null;
  or?: Array<ModelNotificationSettingFilterInput | null> | null;
  not?: ModelNotificationSettingFilterInput | null;
};

export type ModelNotificationSettingConnection = {
  __typename: "ModelNotificationSettingConnection";
  items: Array<NotificationSetting | null>;
  nextToken?: string | null;
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null;
  username?: ModelStringInput | null;
  monitoringCenterId?: ModelStringInput | null;
  notificationType?: ModelNotificationTypeInput | null;
  status?: ModelNotificationStatusInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  url?: ModelStringInput | null;
  note?: ModelStringInput | null;
  and?: Array<ModelNotificationFilterInput | null> | null;
  or?: Array<ModelNotificationFilterInput | null> | null;
  not?: ModelNotificationFilterInput | null;
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection";
  items: Array<Notification | null>;
  nextToken?: string | null;
};

export type ModelNotificationNotifByUserCompositeKeyConditionInput = {
  eq?: ModelNotificationNotifByUserCompositeKeyInput | null;
  le?: ModelNotificationNotifByUserCompositeKeyInput | null;
  lt?: ModelNotificationNotifByUserCompositeKeyInput | null;
  ge?: ModelNotificationNotifByUserCompositeKeyInput | null;
  gt?: ModelNotificationNotifByUserCompositeKeyInput | null;
  between?: Array<ModelNotificationNotifByUserCompositeKeyInput | null> | null;
  beginsWith?: ModelNotificationNotifByUserCompositeKeyInput | null;
};

export type ModelNotificationNotifByUserCompositeKeyInput = {
  status?: NotificationStatus | null;
  createdAt?: string | null;
};

export type ModelUserFilterInput = {
  username?: ModelStringInput | null;
  monitoringCenterId?: ModelStringInput | null;
  vmsOperatorUserIds?: ModelStringInput | null;
  vmsOperatorUserIdAutomatedRole?: ModelStringInput | null;
  vmsAdminUserIds?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  group?: ModelUserGroupInput | null;
  clientIdList?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  integratorId?: ModelStringInput | null;
  autoAssign?: ModelBooleanInput | null;
  preferredLanguage?: ModelLanguageInput | null;
  gridEnabled?: ModelBooleanInput | null;
  gridBehavior?: ModelGridBehaviorInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type ModelUserVMSDetailsFilterInput = {
  id?: ModelIDInput | null;
  vmsUserId?: ModelIDInput | null;
  profileName?: ModelStringInput | null;
  cognitoUsernames?: ModelStringInput | null;
  authEmail?: ModelStringInput | null;
  vmsUsername?: ModelStringInput | null;
  vmsPassword?: ModelStringInput | null;
  vmsUserType?: ModelVMSUserTypeInput | null;
  apiKey?: ModelStringInput | null;
  accessToken?: ModelStringInput | null;
  refreshToken?: ModelStringInput | null;
  accessTokenExpires?: ModelStringInput | null;
  refreshTokenExpires?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelUserVMSDetailsFilterInput | null> | null;
  or?: Array<ModelUserVMSDetailsFilterInput | null> | null;
  not?: ModelUserVMSDetailsFilterInput | null;
};

export type ModelUserVMSDetailsConnection = {
  __typename: "ModelUserVMSDetailsConnection";
  items: Array<UserVMSDetails | null>;
  nextToken?: string | null;
};

export type ModelCustomerUserPermissionSetFilterInput = {
  username?: ModelStringInput | null;
  email?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  ownerCustomerId?: ModelIDInput | null;
  and?: Array<ModelCustomerUserPermissionSetFilterInput | null> | null;
  or?: Array<ModelCustomerUserPermissionSetFilterInput | null> | null;
  not?: ModelCustomerUserPermissionSetFilterInput | null;
};

export type ModelCustomerUserPermissionSetConnection = {
  __typename: "ModelCustomerUserPermissionSetConnection";
  items: Array<CustomerUserPermissionSet | null>;
  nextToken?: string | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelCustomerUserPermissionFilterInput = {
  username?: ModelStringInput | null;
  email?: ModelStringInput | null;
  monitoringCenterId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  sitePermissions?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelCustomerUserPermissionFilterInput | null> | null;
  or?: Array<ModelCustomerUserPermissionFilterInput | null> | null;
  not?: ModelCustomerUserPermissionFilterInput | null;
};

export type ModelTaxFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcIntAdminAccess?: ModelStringInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  region?: ModelStringInput | null;
  subregion?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  amount?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  taxGovId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTaxFilterInput | null> | null;
  or?: Array<ModelTaxFilterInput | null> | null;
  not?: ModelTaxFilterInput | null;
};

export type ModelTaxConnection = {
  __typename: "ModelTaxConnection";
  items: Array<Tax | null>;
  nextToken?: string | null;
};

export type ModelTaxTypeFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcIntAdminAccess?: ModelStringInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  archived?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  and?: Array<ModelTaxTypeFilterInput | null> | null;
  or?: Array<ModelTaxTypeFilterInput | null> | null;
  not?: ModelTaxTypeFilterInput | null;
};

export type ModelTaxTypeConnection = {
  __typename: "ModelTaxTypeConnection";
  items: Array<TaxType | null>;
  nextToken?: string | null;
};

export type ModelSetupFeeFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  taxTypeId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelSetupFeeFilterInput | null> | null;
  or?: Array<ModelSetupFeeFilterInput | null> | null;
  not?: ModelSetupFeeFilterInput | null;
};

export type ModelSetupFeeConnection = {
  __typename: "ModelSetupFeeConnection";
  items: Array<SetupFee | null>;
  nextToken?: string | null;
};

export type ModelCameraPlanFilterInput = {
  id?: ModelIDInput | null;
  parentId?: ModelIDInput | null;
  analyticsType?: ModelAnalyticTypeListInput | null;
  resolution?: ModelStringInput | null;
  bitRate?: ModelIntInput | null;
  operatorDuration?: ModelIntInput | null;
  supervision?: ModelDeviceHealthCheckSLAInput | null;
  storageDays?: ModelIntInput | null;
  cameraPlanProductId?: ModelIDInput | null;
  cameraPlanLivePatrolId?: ModelIDInput | null;
  cameraPlanOperatorMinutesId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelCameraPlanFilterInput | null> | null;
  or?: Array<ModelCameraPlanFilterInput | null> | null;
  not?: ModelCameraPlanFilterInput | null;
};

export type ModelCameraPlanConnection = {
  __typename: "ModelCameraPlanConnection";
  items: Array<CameraPlan | null>;
  nextToken?: string | null;
};

export type ModelSiteSubscriptionFilterInput = {
  id?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  status?: ModelActiveInactiveInput | null;
  onHold?: ModelBooleanInput | null;
  startDate?: ModelStringInput | null;
  term?: ModelIntInput | null;
  renewalDate?: ModelStringInput | null;
  renewalTerm?: ModelIntInput | null;
  merchant?: ModelSiteSubscriptionMerchantInput | null;
  billingDay?: ModelIntInput | null;
  billingFrequency?: ModelBillingFrequencyInput | null;
  nextBillingDay?: ModelStringInput | null;
  autorefillOn?: ModelBooleanInput | null;
  autorefillAmount?: ModelFloatInput | null;
  minimumBalance?: ModelFloatInput | null;
  currentBalance?: ModelFloatInput | null;
  currentBalanceUsd?: ModelFloatInput | null;
  maxNumberRecharges?: ModelFloatInput | null;
  currentDayRechargeCount?: ModelFloatInput | null;
  lastRefilled?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  usedOperatorMinutes?: ModelFloatInput | null;
  totalOperatorMinutes?: ModelFloatInput | null;
  transactionsLastCalculated?: ModelStringInput | null;
  secondsPerInteraction?: ModelIntInput | null;
  incidentBillingDisabled?: ModelBooleanInput | null;
  interactionBillingDisabled?: ModelBooleanInput | null;
  livePatrolBillingDisabled?: ModelBooleanInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  mcBillingAdminAccess?: ModelStringInput | null;
  mcIntAdminAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  billingEmails?: ModelStringInput | null;
  billingEntityName?: ModelStringInput | null;
  commissionBilling?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelSiteSubscriptionFilterInput | null> | null;
  or?: Array<ModelSiteSubscriptionFilterInput | null> | null;
  not?: ModelSiteSubscriptionFilterInput | null;
};

export type ModelSiteSubscriptionConnection = {
  __typename: "ModelSiteSubscriptionConnection";
  items: Array<SiteSubscription | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionStripeDetailFilterInput = {
  id?: ModelIDInput | null;
  archived?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  siteSubscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  mcBillingAdminAccess?: ModelStringInput | null;
  and?: Array<ModelSubscriptionStripeDetailFilterInput | null> | null;
  or?: Array<ModelSubscriptionStripeDetailFilterInput | null> | null;
  not?: ModelSubscriptionStripeDetailFilterInput | null;
};

export type ModelSubscriptionStripeDetailConnection = {
  __typename: "ModelSubscriptionStripeDetailConnection";
  items: Array<SubscriptionStripeDetail | null>;
  nextToken?: string | null;
};

export type ModelStripePaymentMethodFilterInput = {
  id?: ModelIDInput | null;
  subscriptionStripeDetailId?: ModelIDInput | null;
  stripePaymentId?: ModelStringInput | null;
  stripePaymentStatus?: ModelStripePaymentStatusInput | null;
  type?: ModelStripePaymentTypeInput | null;
  name?: ModelStringInput | null;
  identifier?: ModelStringInput | null;
  preferredPayment?: ModelBooleanInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  mcBillingAdminAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelStripePaymentMethodFilterInput | null> | null;
  or?: Array<ModelStripePaymentMethodFilterInput | null> | null;
  not?: ModelStripePaymentMethodFilterInput | null;
};

export type ModelAppliedCameraPlanFilterInput = {
  id?: ModelIDInput | null;
  parentId?: ModelIDInput | null;
  cameraId?: ModelIDInput | null;
  subscriptionId?: ModelIDInput | null;
  analyticsType?: ModelAnalyticTypeListInput | null;
  resolution?: ModelStringInput | null;
  bitRate?: ModelIntInput | null;
  operatorDuration?: ModelIntInput | null;
  supervision?: ModelDeviceHealthCheckSLAInput | null;
  storageDays?: ModelIntInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  mcBillingAdminAccess?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelAppliedCameraPlanFilterInput | null> | null;
  or?: Array<ModelAppliedCameraPlanFilterInput | null> | null;
  not?: ModelAppliedCameraPlanFilterInput | null;
};

export type ModelTransactionLineItemFilterInput = {
  id?: ModelIDInput | null;
  subscriptionId?: ModelIDInput | null;
  invoiceId?: ModelIDInput | null;
  connectedId?: ModelIDInput | null;
  type?: ModelTransactionTypeInput | null;
  description?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  pricePer?: ModelFloatInput | null;
  totalPrice?: ModelFloatInput | null;
  quantity?: ModelFloatInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTransactionLineItemFilterInput | null> | null;
  or?: Array<ModelTransactionLineItemFilterInput | null> | null;
  not?: ModelTransactionLineItemFilterInput | null;
};

export type ModelTransactionLineItemTaxFilterInput = {
  id?: ModelIDInput | null;
  transactionLineItemId?: ModelIDInput | null;
  invoiceId?: ModelIDInput | null;
  taxId?: ModelIDInput | null;
  taxGovId?: ModelStringInput | null;
  taxPercent?: ModelFloatInput | null;
  taxName?: ModelStringInput | null;
  currency?: ModelCurrencyInput | null;
  amount?: ModelFloatInput | null;
  subscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTransactionLineItemTaxFilterInput | null> | null;
  or?: Array<ModelTransactionLineItemTaxFilterInput | null> | null;
  not?: ModelTransactionLineItemTaxFilterInput | null;
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null;
  subtotal?: ModelFloatInput | null;
  invoiceNumber?: ModelStringInput | null;
  total?: ModelFloatInput | null;
  transactionCurrTotal?: ModelFloatInput | null;
  notes?: ModelStringInput | null;
  type?: ModelInvoiceTypeInput | null;
  currency?: ModelCurrencyInput | null;
  transactionCurr?: ModelCurrencyInput | null;
  status?: ModelInvoiceStatusInput | null;
  merchant?: ModelInvoiceMerchantInput | null;
  subscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  transferGroup?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  and?: Array<ModelInvoiceFilterInput | null> | null;
  or?: Array<ModelInvoiceFilterInput | null> | null;
  not?: ModelInvoiceFilterInput | null;
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection";
  items: Array<Invoice | null>;
  nextToken?: string | null;
};

export type ModelInvoicePaymentFilterInput = {
  id?: ModelIDInput | null;
  stripePaymentIntentId?: ModelStringInput | null;
  paymentStatus?: ModelStripePaymentStatusInput | null;
  paymentType?: ModelPaymentTypeInput | null;
  notes?: ModelStringInput | null;
  invoiceId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  amountReceived?: ModelFloatInput | null;
  amountRequested?: ModelFloatInput | null;
  modifiedBy?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelInvoicePaymentFilterInput | null> | null;
  or?: Array<ModelInvoicePaymentFilterInput | null> | null;
  not?: ModelInvoicePaymentFilterInput | null;
};

export type ModelInvoiceTransferPaymentFilterInput = {
  id?: ModelIDInput | null;
  destination?: ModelStringInput | null;
  transferGroup?: ModelStringInput | null;
  stripePaymentIntentId?: ModelStringInput | null;
  currency?: ModelStringInput | null;
  stripeTransferRefId?: ModelStringInput | null;
  amount?: ModelFloatInput | null;
  customerId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  status?: ModelInvoiceTransferStatusInput | null;
  invoiceIds?: ModelIDInput | null;
  and?: Array<ModelInvoiceTransferPaymentFilterInput | null> | null;
  or?: Array<ModelInvoiceTransferPaymentFilterInput | null> | null;
  not?: ModelInvoiceTransferPaymentFilterInput | null;
};

export type ModelInvoiceTransferPaymentConnection = {
  __typename: "ModelInvoiceTransferPaymentConnection";
  items: Array<InvoiceTransferPayment | null>;
  nextToken?: string | null;
};

export type ModelWalletTransferFilterInput = {
  id?: ModelIDInput | null;
  subscriptionId?: ModelIDInput | null;
  siteId?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  currency?: ModelCurrencyInput | null;
  conversionRate?: ModelFloatInput | null;
  stripePaymentId?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  type?: ModelWalletTransferTypeInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelWalletTransferFilterInput | null> | null;
  or?: Array<ModelWalletTransferFilterInput | null> | null;
  not?: ModelWalletTransferFilterInput | null;
};

export type ModelWalletTransferConnection = {
  __typename: "ModelWalletTransferConnection";
  items: Array<WalletTransfer | null>;
  nextToken?: string | null;
};

export type ModelStripePayoutDetailsFilterInput = {
  stripeAccId?: ModelStringInput | null;
  integratorId?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  chargesEnabled?: ModelBooleanInput | null;
  modifiedBy?: ModelStringInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelStripePayoutDetailsFilterInput | null> | null;
  or?: Array<ModelStripePayoutDetailsFilterInput | null> | null;
  not?: ModelStripePayoutDetailsFilterInput | null;
};

export type ModelStripePayoutDetailsConnection = {
  __typename: "ModelStripePayoutDetailsConnection";
  items: Array<StripePayoutDetails | null>;
  nextToken?: string | null;
};

export type ModelExchangeRateSettingFilterInput = {
  country?: ModelStringInput | null;
  modifiedBy?: ModelStringInput | null;
  rate?: ModelFloatInput | null;
  and?: Array<ModelExchangeRateSettingFilterInput | null> | null;
  or?: Array<ModelExchangeRateSettingFilterInput | null> | null;
  not?: ModelExchangeRateSettingFilterInput | null;
};

export type ModelExchangeRateSettingConnection = {
  __typename: "ModelExchangeRateSettingConnection";
  items: Array<ExchangeRateSetting | null>;
  nextToken?: string | null;
};

export type ModelPaymentTransferSettingsFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  mcOwnerAccess?: ModelStringInput | null;
  mcControllerAccess?: ModelStringInput | null;
  mcIntAdminAccess?: ModelStringInput | null;
  type?: ModelStripePayoutSettingsTypeInput | null;
  variable?: ModelFloatInput | null;
  variableMaxDollars?: ModelFloatInput | null;
  flat?: ModelFloatInput | null;
  modifiedBy?: ModelStringInput | null;
  archived?: ModelBooleanInput | null;
  and?: Array<ModelPaymentTransferSettingsFilterInput | null> | null;
  or?: Array<ModelPaymentTransferSettingsFilterInput | null> | null;
  not?: ModelPaymentTransferSettingsFilterInput | null;
};

export type ModelPaymentTransferSettingsConnection = {
  __typename: "ModelPaymentTransferSettingsConnection";
  items: Array<PaymentTransferSettings | null>;
  nextToken?: string | null;
};

export type ModelWebhookHealthFilterInput = {
  id?: ModelIDInput | null;
  monitoringCenterId?: ModelIDInput | null;
  vmsWebhookId?: ModelStringInput | null;
  date?: ModelStringInput | null;
  ackAvgInterval?: ModelFloatInput | null;
  acklastReceived?: ModelStringInput | null;
  ackCount?: ModelIntInput | null;
  analyticAvgInterval?: ModelFloatInput | null;
  analyticlastReceived?: ModelStringInput | null;
  analyticCount?: ModelIntInput | null;
  and?: Array<ModelWebhookHealthFilterInput | null> | null;
  or?: Array<ModelWebhookHealthFilterInput | null> | null;
  not?: ModelWebhookHealthFilterInput | null;
};

export type ModelWebhookHealthConnection = {
  __typename: "ModelWebhookHealthConnection";
  items: Array<WebhookHealth | null>;
  nextToken?: string | null;
};

export type ModelWebhookHealthWebhookHealthByDateCompositeKeyConditionInput = {
  eq?: ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null;
  le?: ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null;
  lt?: ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null;
  ge?: ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null;
  gt?: ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null;
  between?: Array<ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null> | null;
  beginsWith?: ModelWebhookHealthWebhookHealthByDateCompositeKeyInput | null;
};

export type ModelWebhookHealthWebhookHealthByDateCompositeKeyInput = {
  vmsWebhookId?: string | null;
  date?: string | null;
};

export type AddVMSDetailsMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsGetUnlinkedCamerasMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsGetUnlinkedCustomersMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsPowerCycleCameraMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsDeviceHealthCheckMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsValidateCredentialsMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsCreateSiteVMSDetailsMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsGetTroubleShootingUrlMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsListPortalProfilesMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsGetPortalCredentialsMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type RspndrDispatchIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type RspndrCancelIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type CreateManualIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type CloseIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type UpdateIncidentServerMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type DispostionBreakdownMutation = {
  __typename: "LambdaDispositionBreakdownResponse";
  statusCode?: number | null;
  l0?: number | null;
  l1?: number | null;
  l2?: number | null;
  l3?: number | null;
  l4?: number | null;
};

export type SeperateEventFromIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type UpdateEventDispositionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BulkUpdateEventDispositionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type GetTwilioTokenMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type RapidSOSManageSubscriptionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type RapidSOSCoverageCheckCreateAlertMutation = {
  __typename: "LambdaRapidSOSResponse";
  statusCode?: number | null;
  rapidSOSIncident?: string | null;
  body?: string | null;
};

export type RapidSOSDispatchIncidentMutation = {
  __typename: "LambdaRapidSOSResponse";
  statusCode?: number | null;
  rapidSOSIncident?: string | null;
  body?: string | null;
};

export type RapidSOSAddLogMessageMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type RapidSOSCancelDispatchMutation = {
  __typename: "LambdaRapidSOSResponse";
  statusCode?: number | null;
  rapidSOSIncident?: string | null;
  body?: string | null;
};

export type TracktikConfigureConnectionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type TracktikDispatchIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type TrackTikGetTaskListMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type TrackTikCloseIncidentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type RekgonitionDetectLabelsMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type GlobalSearchMutation = {
  __typename: "LambdaSearchResponse";
  statusCode: number;
  items?: Array<{
    __typename: "LambdaSearchResponseItem";
    type: string;
    id: string;
    url: string;
    overviewText: string;
  }> | null;
  errorMessage?: string | null;
  nextToken?: string | null;
};

export type HubspotConnectOrUpdateMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type ReportingIncidentlistMutation = {
  __typename: "IncidentReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<{
    __typename: "IncidentReportItem";
    id: string;
    createdAt: string;
    resolvedAt?: string | null;
    primaryDisposition: string;
    siteName: string;
    clientName: string;
    assignedTo?: string | null;
    status: string;
  }> | null;
};

export type ReportingCameraEventListMutation = {
  __typename: "CameraEventReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<{
    __typename: "CameraEventReportItem";
    id: string;
    incidentId: string;
    startTime: string;
    site: string;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    camera: string;
    disposition: string;
    eventType: EventType;
    client: string;
    integrator: string;
  }> | null;
};

export type ReportingInvoiceListMutation = {
  __typename: "InvoiceReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<{
    __typename: "InvoiceReportItem";
    id: string;
    invoiceNumber: string;
    createdAt: string;
    siteName: string;
    clientName: string;
    invoiceType: string;
    fromName: string;
    toName: string;
    status: string;
    total: number;
    currency: string;
  }> | null;
};

export type ReportingGetDashboardUrlMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type ReportingInvoiceListExportMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type ReportingCustomerPaymentsListMutation = {
  __typename: "CustomerPaymentsReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<{
    __typename: "CustomerPaymentsReportItem";
    invoiceNumber: string;
    paymentDate: string;
    billingEntityName: string;
    transactionId: string;
    amount: number;
    currency: string;
  }> | null;
};

export type ReportingCustomerPaymentsListExportMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type ReportingPayoutsListMutation = {
  __typename: "PayoutsReportResponse";
  statusCode: number;
  errorMessage?: string | null;
  nextRecord?: number | null;
  items?: Array<{
    __typename: "PayoutsReportItem";
    invoiceNumber: string;
    paymentDate: string;
    amount: number;
    transferAmount: number;
    referenceId: string;
    type: PayoutItemType;
    currency: string;
    transactionCurr: string;
  }> | null;
};

export type ReportingPayoutsListExportMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IncidentShareCreateMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IncidentShareSendLinkMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IncidentShareExpirePageMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IncidentShareUpdateMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IncidentCodeCreateMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingAddCameraPlanSubAccountMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingArchiveCameraPlanSubAccountMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingAddUpdateSubscriptionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingActivateSubscriptionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingDectivateSubscriptionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingDeleteSubscriptionMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingAddUpdateCameraPlanMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingGenerateInvoicePdfMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingConnectStripeMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingStripeSetupIntentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingStripeSavePaymentMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingUpdateDefaultPaymentMethodMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingStripeRemovePaymentMethodMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingStripeManualChargeWalletMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingInvoiceReversalMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type BillingMigratePaymentMethodMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type MonitoringChangeSiteStatusMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type MonitoringChangeStatusMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type MonitoringGetHolidaysMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type MonitoringValidateArmingRulesMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type MonitoringSyncAllDevicesMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type SupvisdEventsManageRulesMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IncidentsGetEnhancedUrlCredsMutation = {
  __typename: "SiteVMSToken";
  statusCode: number;
  accessToken?: string | null;
  refreshToken?: string | null;
};

export type AuthDeleteUserMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type AuthManageCXUserMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type CustomerArchiveMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type AudioManagementGroupsMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type AudioManagementMessageTypesMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type AudioPlayMessageMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type VmsAddCustomerMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IntAppFetchAccMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type IntAppAddEditSiteMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type UniversalQueueQueryMutation = {
  __typename: "UniversalQueueResponse";
  statusCode: number;
  rows?: Array<{
    __typename: "UniversalQueueRow";
    incidentId: string;
    monitoringCenterId: string;
    createdAt: string;
    primaryDisposition: string;
    siteName: string;
    overSLASeconds?: number | null;
    monitoringCenterName: string;
    assignedTo?: string | null;
    assignBy: string;
    username: string;
    url: string;
    verifiedAlarm: boolean;
  }> | null;
};

export type SnoozeDevicesMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type StopSnoozeMutation = {
  __typename: "LambdaResponse";
  statusCode?: number | null;
  body?: string | null;
};

export type CreateIncidentMutation = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type UpdateIncidentMutation = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type DeleteIncidentMutation = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type CreateIncidentNoteMutation = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type UpdateIncidentNoteMutation = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type DeleteIncidentNoteMutation = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type CreateIncidentActionListMutation = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateIncidentActionListMutation = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteIncidentActionListMutation = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateProcedureTaskMutation = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProcedureTaskMutation = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteProcedureTaskMutation = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentBundleTimeMutation = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentBundleTimeMutation = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentBundleTimeMutation = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraEventMutation = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type UpdateCameraEventMutation = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type DeleteCameraEventMutation = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type CreateSecondaryCameraEventMutation = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSecondaryCameraEventMutation = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSecondaryCameraEventMutation = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomerMutation = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateCustomerMutation = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteCustomerMutation = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateVMSDetailsMutation = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVMSDetailsMutation = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVMSDetailsMutation = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateDashboardDetailMutation = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDashboardDetailMutation = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDashboardDetailMutation = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateBoldBIDetailsMutation = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBoldBIDetailsMutation = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteBoldBIDetailsMutation = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRapidSOSDetailsMutation = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRapidSOSDetailsMutation = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRapidSOSDetailsMutation = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAddressMutation = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAddressMutation = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAddressMutation = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateContactMutation = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateContactMutation = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteContactMutation = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreatePendingSiteMutation = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePendingSiteMutation = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type DeletePendingSiteMutation = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CreateSiteMutation = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateSiteMutation = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteSiteMutation = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateSiteSupvisdSettingMutation = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateSiteSupvisdSettingMutation = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteSiteSupvisdSettingMutation = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateSupvisdEventRuleMutation = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSupvisdEventRuleMutation = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSupvisdEventRuleMutation = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSiteDeviceStatusConfigMutation = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSiteDeviceStatusConfigMutation = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSiteDeviceStatusConfigMutation = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type CreateTriggerMutation = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTriggerMutation = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTriggerMutation = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSiteIntegrationMutation = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateSiteIntegrationMutation = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteSiteIntegrationMutation = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateSiteVMSDetailsMutation = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSiteVMSDetailsMutation = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSiteVMSDetailsMutation = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTrackTikDetailsMutation = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateTrackTikDetailsMutation = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteTrackTikDetailsMutation = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateTrackTikTaskMutation = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTrackTikTaskMutation = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTrackTikTaskMutation = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateSiteEmergencyContactMutation = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateSiteEmergencyContactMutation = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteSiteEmergencyContactMutation = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreatePhoneMutation = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdatePhoneMutation = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeletePhoneMutation = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraMutation = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateCameraMutation = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteCameraMutation = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateObjectGroupMutation = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateObjectGroupMutation = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteObjectGroupMutation = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateAudioCustomMessageTypeMutation = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAudioCustomMessageTypeMutation = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAudioCustomMessageTypeMutation = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraQualitySettingsMutation = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraQualitySettingsMutation = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCameraQualitySettingsMutation = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraSceneDetectionSettingsMutation = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateCameraSceneDetectionSettingsMutation = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteCameraSceneDetectionSettingsMutation = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateCameraSceneDetectionThresholdMutation = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraSceneDetectionThresholdMutation = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCameraSceneDetectionThresholdMutation = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraSceneDetectionResultMutation = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateCameraSceneDetectionResultMutation = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteCameraSceneDetectionResultMutation = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateCameraSceneDetectionResultItemMutation = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraSceneDetectionResultItemMutation = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCameraSceneDetectionResultItemMutation = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraCredentialsMutation = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraCredentialsMutation = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCameraCredentialsMutation = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraAccessUrlsMutation = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraAccessUrlsMutation = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCameraAccessUrlsMutation = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateCameraImmixConfigMutation = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCameraImmixConfigMutation = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCameraImmixConfigMutation = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateActivityLogMutation = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type UpdateActivityLogMutation = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type DeleteActivityLogMutation = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type CreateSignalTestLogMutation = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type UpdateSignalTestLogMutation = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type DeleteSignalTestLogMutation = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type CreateAuditLogMutation = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type UpdateAuditLogMutation = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type DeleteAuditLogMutation = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type CreateIncidentActionDetailMutation = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type UpdateIncidentActionDetailMutation = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type DeleteIncidentActionDetailMutation = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type CreateSOPMutation = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSOPMutation = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSOPMutation = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRspndrEventsMutation = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRspndrEventsMutation = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRspndrEventsMutation = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRspndrIncidentMutation = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateRspndrIncidentMutation = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteRspndrIncidentMutation = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateRspndrIncidentCheckItemMutation = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRspndrIncidentCheckItemMutation = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRspndrIncidentCheckItemMutation = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateRspndrIncidentGroundingRuleMutation = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRspndrIncidentGroundingRuleMutation = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRspndrIncidentGroundingRuleMutation = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateRspndrActivityMutation = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type UpdateRspndrActivityMutation = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type DeleteRspndrActivityMutation = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type CreateTrackTikIncidentMutation = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateTrackTikIncidentMutation = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteTrackTikIncidentMutation = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateTrackTikActivityMutation = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTrackTikActivityMutation = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTrackTikActivityMutation = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateRapidSOSIncidentMutation = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateRapidSOSIncidentMutation = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteRapidSOSIncidentMutation = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateRapidSOSEventDetailsMutation = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type UpdateRapidSOSEventDetailsMutation = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type DeleteRapidSOSEventDetailsMutation = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type CreateRapidSOSLocationMutation = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateRapidSOSLocationMutation = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteRapidSOSLocationMutation = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateRapidSOSCivicAddressMutation = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRapidSOSCivicAddressMutation = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRapidSOSCivicAddressMutation = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateRapidSOSEmergencyContactMutation = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRapidSOSEmergencyContactMutation = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRapidSOSEmergencyContactMutation = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateRapidSOSActivityMutation = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateRapidSOSActivityMutation = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteRapidSOSActivityMutation = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateRapidSOSLogMessageMutation = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRapidSOSLogMessageMutation = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRapidSOSLogMessageMutation = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRapidSOSAttachmentMutation = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRapidSOSAttachmentMutation = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRapidSOSAttachmentMutation = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentCodeDetailMutation = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateIncidentCodeDetailMutation = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteIncidentCodeDetailMutation = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateIncidentShareSettingsMutation = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateIncidentShareSettingsMutation = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteIncidentShareSettingsMutation = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateIncidentDataToShareMutation = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentDataToShareMutation = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentDataToShareMutation = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentShareEntryMutation = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateIncidentShareEntryMutation = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteIncidentShareEntryMutation = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateIncidentShareSiteMutation = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareSiteMutation = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentShareSiteMutation = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentShareIntegratorMutation = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareIntegratorMutation = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentShareIntegratorMutation = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentShareEntryAccessMutation = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareEntryAccessMutation = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentShareEntryAccessMutation = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentShareEntyNoteMutation = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareEntyNoteMutation = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentShareEntyNoteMutation = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentShareEventMutation = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateIncidentShareEventMutation = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteIncidentShareEventMutation = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateIncidentShareActionItemsMutation = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareActionItemsMutation = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentShareActionItemsMutation = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateIncidentShareContactInformationMutation = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIncidentShareContactInformationMutation = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIncidentShareContactInformationMutation = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type CreateCallNoticeMutation = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type UpdateCallNoticeMutation = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type DeleteCallNoticeMutation = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type CreateCallLogMutation = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type UpdateCallLogMutation = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type DeleteCallLogMutation = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type CreateSMSLogMutation = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type UpdateSMSLogMutation = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type DeleteSMSLogMutation = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type CreateEmailLogMutation = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type UpdateEmailLogMutation = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type DeleteEmailLogMutation = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type CreateNotificationSettingMutation = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateNotificationSettingMutation = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteNotificationSettingMutation = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateNotificationMutation = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type UpdateNotificationMutation = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type DeleteNotificationMutation = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserVMSDetailsMutation = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserVMSDetailsMutation = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserVMSDetailsMutation = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomerUserPermissionSetMutation = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateCustomerUserPermissionSetMutation = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteCustomerUserPermissionSetMutation = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateCustomerUserPermissionMutation = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateCustomerUserPermissionMutation = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteCustomerUserPermissionMutation = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateTaxMutation = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateTaxMutation = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteTaxMutation = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateTaxesTaxTypeMutation = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: {
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  integratorId?: string | null;
  taxType: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type UpdateTaxesTaxTypeMutation = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: {
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  integratorId?: string | null;
  taxType: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type DeleteTaxesTaxTypeMutation = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: {
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  integratorId?: string | null;
  taxType: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type CreateTaxTypeMutation = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateTaxTypeMutation = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteTaxTypeMutation = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateSetupFeeMutation = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateSetupFeeMutation = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteSetupFeeMutation = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateProductMutation = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateProductMutation = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteProductMutation = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateCameraPlanMutation = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type UpdateCameraPlanMutation = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type DeleteCameraPlanMutation = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type CreateLivePatrolMutation = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateLivePatrolMutation = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteLivePatrolMutation = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateOperatorMinutesMutation = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOperatorMinutesMutation = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOperatorMinutesMutation = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSiteSubscriptionMutation = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateSiteSubscriptionMutation = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteSiteSubscriptionMutation = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateSubscriptionStripeDetailMutation = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateSubscriptionStripeDetailMutation = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteSubscriptionStripeDetailMutation = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateStripePaymentMethodMutation = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateStripePaymentMethodMutation = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteStripePaymentMethodMutation = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAppliedProductMutation = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateAppliedProductMutation = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteAppliedProductMutation = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateAppliedCameraPlanMutation = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type UpdateAppliedCameraPlanMutation = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type DeleteAppliedCameraPlanMutation = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type CreateAppliedLivePatrolMutation = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAppliedLivePatrolMutation = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAppliedLivePatrolMutation = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAppliedOperatorMinutesMutation = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAppliedOperatorMinutesMutation = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAppliedOperatorMinutesMutation = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAppliedSetupFeeMutation = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdateAppliedSetupFeeMutation = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeleteAppliedSetupFeeMutation = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateTransactionLineItemMutation = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateTransactionLineItemMutation = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteTransactionLineItemMutation = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateTransactionLineItemTaxMutation = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTransactionLineItemTaxMutation = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTransactionLineItemTaxMutation = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateInvoiceMutation = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type UpdateInvoiceMutation = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type DeleteInvoiceMutation = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type CreateInvoicePaymentMutation = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateInvoicePaymentMutation = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteInvoicePaymentMutation = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateInvoiceTransferPaymentMutation = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type UpdateInvoiceTransferPaymentMutation = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type DeleteInvoiceTransferPaymentMutation = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type CreateWalletTransferMutation = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type UpdateWalletTransferMutation = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type DeleteWalletTransferMutation = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type CreateStripePayoutDetailsMutation = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateStripePayoutDetailsMutation = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteStripePayoutDetailsMutation = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateExchangeRateSettingMutation = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateExchangeRateSettingMutation = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteExchangeRateSettingMutation = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type CreatePaymentTransferSettingsMutation = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type UpdatePaymentTransferSettingsMutation = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type DeletePaymentTransferSettingsMutation = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type CreateWebhookHealthMutation = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWebhookHealthMutation = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteWebhookHealthMutation = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateMasterMutation = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMasterMutation = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMasterMutation = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type AdditionalEventsQuery = {
  __typename: "ModelSecondaryCameraEventConnection";
  items: Array<{
    __typename: "SecondaryCameraEvent";
    id: string;
    cameraEventId: string;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    vmsSourceType: VMSSourceType;
    ackTimestamp: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProductByMonitoringCenterQuery = {
  __typename: "ModelProductConnection";
  items: Array<{
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type AppliedSetupFeeBySubQuery = {
  __typename: "ModelAppliedSetupFeeConnection";
  items: Array<{
    __typename: "AppliedSetupFee";
    id: string;
    parentId: string;
    subscriptionId: string;
    amount?: number | null;
    name: string;
    currency: Currency;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentQuery = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type ListIncidentsQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentByMonitoringCenterQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentByMonitoringCenterAndStatusQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentBySiteQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentBySiteStatusQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentBySiteAndClosedTimeQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentByStatusAndCreatedAtQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type IncidentByMCStatusCreatedAtQuery = {
  __typename: "ModelIncidentConnection";
  items: Array<{
    __typename: "Incident";
    id: string;
    integratorId: string;
    monitoringCenterId: string;
    status: IncidentStatus;
    incidentSiteId: string;
    incidentCustomerId: string;
    dispositionLevel: string;
    primaryDisposition: string;
    dispositionList: Array<string>;
    closedTime?: string | null;
    resolvedTime?: string | null;
    expectedResolvedTime: string;
    billableTime?: number | null;
    assignedUsername?: string | null;
    assignmentHistory?: Array<{
      __typename: "AssignmentHistoryItem";
      username: string;
      assignedAt: string;
    }> | null;
    modifiedBy?: string | null;
    createdAt: string;
    hasShareExternal?: boolean | null;
    resolution?: string | null;
    resolutionTranslated?: string | null;
    resolutionSanitized?: string | null;
    externalPartyResponse?: {
      __typename: "ExternalPartyResponse";
      police: boolean;
      fire: boolean;
      ambulance: boolean;
      bylaw: boolean;
      security: boolean;
      outreach: boolean;
      siteContact: boolean;
      agenciesArrived: boolean;
      notArrivedReason?: string | null;
      notArrivedReasonTranslated?: string | null;
      notArrivedReasonSanitized?: string | null;
    } | null;
    invoiceId?: string | null;
    updatedAt: string;
    events?: {
      __typename: "ModelCameraEventConnection";
      items: Array<{
        __typename: "CameraEvent";
        id: string;
        vmsEventId?: string | null;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        thumbnailFilename?: string | null;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        integratorId: string;
        monitoringCenterId: string;
        incidentId: string;
        incidentPriority: number;
        cameraEventCameraId: string;
        siteId: string;
        customerId: string;
        startTime: string;
        endTime?: string | null;
        resolvedTime?: string | null;
        instantaneous?: boolean | null;
        cameraEventUrl: string;
        cameraEventUrlPublic: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        ackTimestamp?: string | null;
        createdAt: string;
        vmsSourceType?: VMSSourceType | null;
        updatedAt: string;
        secondaryEvents?: {
          __typename: "ModelSecondaryCameraEventConnection";
          items: Array<{
            __typename: "SecondaryCameraEvent";
            id: string;
            cameraEventId: string;
            vmsNotes?: string | null;
            vmsNotesTranslated?: string | null;
            vmsNotesSanitized?: string | null;
            disposition: Disposition;
            eventType: EventType;
            vmsSourceType: VMSSourceType;
            ackTimestamp: string;
            modifiedBy?: string | null;
            vmsUserId?: string | null;
            monitoringCenterId: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        camera: {
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    site: {
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentNoteQuery = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type ListIncidentNotesQuery = {
  __typename: "ModelIncidentNoteConnection";
  items: Array<{
    __typename: "IncidentNote";
    id: string;
    incidentId: string;
    note: string;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    monitoringCenterId: string;
    modifiedBy: string;
    writtenBy?: string | null;
    createdAt: string;
    shareExternal?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type NotesByIncidentQuery = {
  __typename: "ModelIncidentNoteConnection";
  items: Array<{
    __typename: "IncidentNote";
    id: string;
    incidentId: string;
    note: string;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    monitoringCenterId: string;
    modifiedBy: string;
    writtenBy?: string | null;
    createdAt: string;
    shareExternal?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentActionListQuery = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListIncidentActionListsQuery = {
  __typename: "ModelIncidentActionListConnection";
  items: Array<{
    __typename: "IncidentActionList";
    id: string;
    incidentId: string;
    priority: number;
    eventType: EventType;
    disposition: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    tasks?: {
      __typename: "ModelProcedureTaskConnection";
      items: Array<{
        __typename: "ProcedureTask";
        id: string;
        incidentId: string;
        incidentActionItemId: string;
        order: number;
        completed?: boolean | null;
        description: string;
        modifiedBy?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ActionListByIncidentQuery = {
  __typename: "ModelIncidentActionListConnection";
  items: Array<{
    __typename: "IncidentActionList";
    id: string;
    incidentId: string;
    priority: number;
    eventType: EventType;
    disposition: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    tasks?: {
      __typename: "ModelProcedureTaskConnection";
      items: Array<{
        __typename: "ProcedureTask";
        id: string;
        incidentId: string;
        incidentActionItemId: string;
        order: number;
        completed?: boolean | null;
        description: string;
        modifiedBy?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetProcedureTaskQuery = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListProcedureTasksQuery = {
  __typename: "ModelProcedureTaskConnection";
  items: Array<{
    __typename: "ProcedureTask";
    id: string;
    incidentId: string;
    incidentActionItemId: string;
    order: number;
    completed?: boolean | null;
    description: string;
    modifiedBy?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProcedureTaskByIncidentQuery = {
  __typename: "ModelProcedureTaskConnection";
  items: Array<{
    __typename: "ProcedureTask";
    id: string;
    incidentId: string;
    incidentActionItemId: string;
    order: number;
    completed?: boolean | null;
    description: string;
    modifiedBy?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ProcedureTaskByIncidentActionQuery = {
  __typename: "ModelProcedureTaskConnection";
  items: Array<{
    __typename: "ProcedureTask";
    id: string;
    incidentId: string;
    incidentActionItemId: string;
    order: number;
    completed?: boolean | null;
    description: string;
    modifiedBy?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentBundleTimeQuery = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentBundleTimesQuery = {
  __typename: "ModelIncidentBundleTimeConnection";
  items: Array<{
    __typename: "IncidentBundleTime";
    id: string;
    monitoringCenterId: string;
    applyToAssigned: boolean;
    bundleTime: number;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type BundleTimeByMCQuery = {
  __typename: "ModelIncidentBundleTimeConnection";
  items: Array<{
    __typename: "IncidentBundleTime";
    id: string;
    monitoringCenterId: string;
    applyToAssigned: boolean;
    bundleTime: number;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraEventQuery = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type ListCameraEventsQuery = {
  __typename: "ModelCameraEventConnection";
  items: Array<{
    __typename: "CameraEvent";
    id: string;
    vmsEventId?: string | null;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    thumbnailFilename?: string | null;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    integratorId: string;
    monitoringCenterId: string;
    incidentId: string;
    incidentPriority: number;
    cameraEventCameraId: string;
    siteId: string;
    customerId: string;
    startTime: string;
    endTime?: string | null;
    resolvedTime?: string | null;
    instantaneous?: boolean | null;
    cameraEventUrl: string;
    cameraEventUrlPublic: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    ackTimestamp?: string | null;
    createdAt: string;
    vmsSourceType?: VMSSourceType | null;
    updatedAt: string;
    secondaryEvents?: {
      __typename: "ModelSecondaryCameraEventConnection";
      items: Array<{
        __typename: "SecondaryCameraEvent";
        id: string;
        cameraEventId: string;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        vmsSourceType: VMSSourceType;
        ackTimestamp: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    camera: {
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type CameraEventByMonitoringCenterQuery = {
  __typename: "ModelCameraEventConnection";
  items: Array<{
    __typename: "CameraEvent";
    id: string;
    vmsEventId?: string | null;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    thumbnailFilename?: string | null;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    integratorId: string;
    monitoringCenterId: string;
    incidentId: string;
    incidentPriority: number;
    cameraEventCameraId: string;
    siteId: string;
    customerId: string;
    startTime: string;
    endTime?: string | null;
    resolvedTime?: string | null;
    instantaneous?: boolean | null;
    cameraEventUrl: string;
    cameraEventUrlPublic: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    ackTimestamp?: string | null;
    createdAt: string;
    vmsSourceType?: VMSSourceType | null;
    updatedAt: string;
    secondaryEvents?: {
      __typename: "ModelSecondaryCameraEventConnection";
      items: Array<{
        __typename: "SecondaryCameraEvent";
        id: string;
        cameraEventId: string;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        vmsSourceType: VMSSourceType;
        ackTimestamp: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    camera: {
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type CameraEventByVmsEventIdQuery = {
  __typename: "ModelCameraEventConnection";
  items: Array<{
    __typename: "CameraEvent";
    id: string;
    vmsEventId?: string | null;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    thumbnailFilename?: string | null;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    integratorId: string;
    monitoringCenterId: string;
    incidentId: string;
    incidentPriority: number;
    cameraEventCameraId: string;
    siteId: string;
    customerId: string;
    startTime: string;
    endTime?: string | null;
    resolvedTime?: string | null;
    instantaneous?: boolean | null;
    cameraEventUrl: string;
    cameraEventUrlPublic: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    ackTimestamp?: string | null;
    createdAt: string;
    vmsSourceType?: VMSSourceType | null;
    updatedAt: string;
    secondaryEvents?: {
      __typename: "ModelSecondaryCameraEventConnection";
      items: Array<{
        __typename: "SecondaryCameraEvent";
        id: string;
        cameraEventId: string;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        vmsSourceType: VMSSourceType;
        ackTimestamp: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    camera: {
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type CameraEventByIncidentIdQuery = {
  __typename: "ModelCameraEventConnection";
  items: Array<{
    __typename: "CameraEvent";
    id: string;
    vmsEventId?: string | null;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    thumbnailFilename?: string | null;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    integratorId: string;
    monitoringCenterId: string;
    incidentId: string;
    incidentPriority: number;
    cameraEventCameraId: string;
    siteId: string;
    customerId: string;
    startTime: string;
    endTime?: string | null;
    resolvedTime?: string | null;
    instantaneous?: boolean | null;
    cameraEventUrl: string;
    cameraEventUrlPublic: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    ackTimestamp?: string | null;
    createdAt: string;
    vmsSourceType?: VMSSourceType | null;
    updatedAt: string;
    secondaryEvents?: {
      __typename: "ModelSecondaryCameraEventConnection";
      items: Array<{
        __typename: "SecondaryCameraEvent";
        id: string;
        cameraEventId: string;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        vmsSourceType: VMSSourceType;
        ackTimestamp: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    camera: {
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type CameraEventBySiteIdQuery = {
  __typename: "ModelCameraEventConnection";
  items: Array<{
    __typename: "CameraEvent";
    id: string;
    vmsEventId?: string | null;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    thumbnailFilename?: string | null;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    integratorId: string;
    monitoringCenterId: string;
    incidentId: string;
    incidentPriority: number;
    cameraEventCameraId: string;
    siteId: string;
    customerId: string;
    startTime: string;
    endTime?: string | null;
    resolvedTime?: string | null;
    instantaneous?: boolean | null;
    cameraEventUrl: string;
    cameraEventUrlPublic: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    ackTimestamp?: string | null;
    createdAt: string;
    vmsSourceType?: VMSSourceType | null;
    updatedAt: string;
    secondaryEvents?: {
      __typename: "ModelSecondaryCameraEventConnection";
      items: Array<{
        __typename: "SecondaryCameraEvent";
        id: string;
        cameraEventId: string;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        vmsSourceType: VMSSourceType;
        ackTimestamp: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    camera: {
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type CameraEventByEventTypeAndCreatedAtQuery = {
  __typename: "ModelCameraEventConnection";
  items: Array<{
    __typename: "CameraEvent";
    id: string;
    vmsEventId?: string | null;
    vmsNotes?: string | null;
    vmsNotesTranslated?: string | null;
    vmsNotesSanitized?: string | null;
    disposition: Disposition;
    eventType: EventType;
    thumbnailFilename?: string | null;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    integratorId: string;
    monitoringCenterId: string;
    incidentId: string;
    incidentPriority: number;
    cameraEventCameraId: string;
    siteId: string;
    customerId: string;
    startTime: string;
    endTime?: string | null;
    resolvedTime?: string | null;
    instantaneous?: boolean | null;
    cameraEventUrl: string;
    cameraEventUrlPublic: string;
    modifiedBy?: string | null;
    vmsUserId?: string | null;
    ackTimestamp?: string | null;
    createdAt: string;
    vmsSourceType?: VMSSourceType | null;
    updatedAt: string;
    secondaryEvents?: {
      __typename: "ModelSecondaryCameraEventConnection";
      items: Array<{
        __typename: "SecondaryCameraEvent";
        id: string;
        cameraEventId: string;
        vmsNotes?: string | null;
        vmsNotesTranslated?: string | null;
        vmsNotesSanitized?: string | null;
        disposition: Disposition;
        eventType: EventType;
        vmsSourceType: VMSSourceType;
        ackTimestamp: string;
        modifiedBy?: string | null;
        vmsUserId?: string | null;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    camera: {
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type GetCustomerQuery = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListCustomersQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CustomerByIntegratorQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CustomerByMonitoringCenterQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CustomerByVmsCustomerIdQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CustomerByInvoicePrefixQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CustomerBySoftphoneQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CustomersByStatusAndCreatedAtQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    id: string;
    name: string;
    type: CustomerType;
    status?: DataEntryStatus | null;
    integratorId?: string | null;
    monitoringCenterId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    vmsCustomerId?: number | null;
    vmsMCWebhookId?: string | null;
    vmsMonitoringCenterRoleId?: number | null;
    archived?: boolean | null;
    modifiedBy?: string | null;
    slaL1?: string | null;
    slaL2?: string | null;
    slaL3?: string | null;
    slaL4?: string | null;
    sortName?: string | null;
    softphoneEnabled?: boolean | null;
    softphoneNumber?: string | null;
    callbackNumber?: string | null;
    callbackNumberExt?: string | null;
    zendeskWidgetScript?: string | null;
    hubspotId?: string | null;
    hasLogo?: boolean | null;
    mobileLogo?: string | null;
    invoicePrefix?: string | null;
    invoiceNumber?: number | null;
    invoiceFooter?: string | null;
    errorEmail?: string | null;
    vmsUrl?: string | null;
    vmsAlarmUrl?: string | null;
    vmsAdminUrl?: string | null;
    orgDomainUrl?: string | null;
    fromEmail?: string | null;
    createdAt?: string | null;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    boldBIIntegration?: {
      __typename: "BoldBIDetails";
      id: string;
      siteUsername: string;
      sitePassword: string;
      siteUrl: string;
      siteDefaultDashboardId: string;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    rapidSOSIntegration?: {
      __typename: "RapidSOSDetails";
      id: string;
      clientId: string;
      clientSecret: string;
      webhookEnabled: boolean;
      agencyId: string;
      webhookId?: number | null;
      customerId: string;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    contact: {
      __typename: "Contact";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      phone?: string | null;
      ext?: string | null;
      email?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      customerCanShareSecurables?: boolean | null;
      customerCanLoginAdminPortal?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    sites?: {
      __typename: "ModelSiteConnection";
      items: Array<{
        __typename: "Site";
        id: string;
        name: string;
        status?: DataEntryStatus | null;
        customerId: string;
        integratorId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        externalCriticalNotes?: string | null;
        externalCriticalNotesTranslated?: string | null;
        externalCriticalNotesSanitized?: string | null;
        monitoringCenterId: string;
        archived?: boolean | null;
        modifiedBy?: string | null;
        timezone?: string | null;
        vmsSharedMonitoringRoleId?: number | null;
        monitoringStatus?: MonitoringStatus | null;
        maintainCamerasOnMonitoringRole?: boolean | null;
        armingRulesConfig?: string | null;
        monitoringState?: MonitoringStates | null;
        armedState?: ArmedStates | null;
        alarmState?: AlarmStates | null;
        supervisionEnabled?: boolean | null;
        triggersEnabled?: boolean | null;
        muteAfterInteractionCount?: number | null;
        type?: SiteType | null;
        alarmsCentralStation?: string | null;
        alarmsCentralStationPhone?: string | null;
        alarmsCentralStationPhoneExt?: string | null;
        alarmsVerbalPasscode?: string | null;
        alarmsCSID?: string | null;
        alarmsAR?: string | null;
        alarmsNotes?: string | null;
        alarmsNotesTranslated?: string | null;
        alarmsNotesSanitized?: string | null;
        alarmsPermitNum?: string | null;
        alarmsPermitExpires?: string | null;
        autoSendToTrackTik?: boolean | null;
        hasSiteMap?: boolean | null;
        condensedAddress?: string | null;
        createdAt: string;
        allowCustomerViewPendingUntil?: string | null;
        updatedAt: string;
        address: {
          __typename: "Address";
          id: string;
          lineOne: string;
          lineTwo?: string | null;
          city: string;
          state: string;
          zipCode: string;
          country: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
        };
        integrations?: {
          __typename: "ModelSiteIntegrationConnection";
          items: Array<{
            __typename: "SiteIntegration";
            id: string;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            type?: IntegrationType | null;
            subType?: IntegrationSubType | null;
            subTypeList?: Array<IntegrationSubType> | null;
            agency?: string | null;
            phone?: string | null;
            phoneExt?: string | null;
            email?: string | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
            trackTickDetails?: {
              __typename: "TrackTikDetails";
              id: string;
              siteIntegrationId: string;
              siteId: string;
              monitoringCenterId: string;
              apiUrl: string;
              apiUsername: string;
              apiPassword: string;
              accountId: string;
              token?: string | null;
              refreshToken?: string | null;
              tokenExpiry?: string | null;
              refreshTokenExpiry?: string | null;
              autoSend?: boolean | null;
              autoSendErrorEmail?: string | null;
              configured: boolean;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        contacts?: {
          __typename: "ModelSiteEmergencyContactConnection";
          items: Array<{
            __typename: "SiteEmergencyContact";
            id: string;
            siteId: string;
            name: string;
            type?: Array<SiteContactType | null> | null;
            verbalPasscode?: string | null;
            email?: string | null;
            priority: number;
            monitoringCenterId: string;
            integratorId?: string | null;
            archived?: boolean | null;
            notes?: string | null;
            notesTranslated?: string | null;
            notesSanitized?: string | null;
            modifiedBy?: string | null;
            createdAt: string;
            updatedAt: string;
            phones?: {
              __typename: "ModelPhoneConnection";
              nextToken?: string | null;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        cameras?: {
          __typename: "ModelCameraConnection";
          items: Array<{
            __typename: "Camera";
            id: string;
            name: string;
            deviceType: DeviceType;
            status: DataEntryStatus;
            vmsCameraName?: string | null;
            description?: string | null;
            descriptionTranslated?: string | null;
            descriptionSanitized?: string | null;
            vmsDescription?: string | null;
            siteId: string;
            integratorId?: string | null;
            monitoringCenterId: string;
            customerId: string;
            criticalNotes?: string | null;
            criticalNotesTranslated?: string | null;
            criticalNotesSanitized?: string | null;
            talkdownInstructions?: {
              __typename: "TalkdownInstructions";
              talkdownType?: TalkdownType | null;
              note?: string | null;
              noteTranslated?: string | null;
              noteSanitized?: string | null;
              phone?: string | null;
              url?: string | null;
            } | null;
            latitude?: number | null;
            longitude?: number | null;
            vmsCameraId?: string | null;
            vmsCustomerId?: string | null;
            testingExpiry?: string | null;
            armedState?: ArmedStates | null;
            snoozeReason?: string | null;
            snoozeReasonTranslated?: string | null;
            snoozeReasonSanitized?: string | null;
            snoozeExpiry?: string | null;
            archived?: boolean | null;
            zoneNumber?: string | null;
            zoneDescription?: string | null;
            zoneDescriptionTranslated?: string | null;
            zoneDescriptionSanitized?: string | null;
            modifiedBy?: string | null;
            isOnvif?: boolean | null;
            videoStreamState?: string | null;
            deviceUrlOnline?: boolean | null;
            vmsConnect?: boolean | null;
            isP2P?: boolean | null;
            isAnalyticsEnabled?: boolean | null;
            isMotionEnabled?: boolean | null;
            testEventReceived?: boolean | null;
            healthCheckEnabled?: boolean | null;
            healthCheckSLA?: DeviceHealthCheckSLA | null;
            healthCheckStatus?: HealthCheckStatus | null;
            ignoreStatusChecks?: boolean | null;
            statusChecksProperties?: Array<DeviceStatusProperties> | null;
            statusLastVerified?: string | null;
            archiveDurationHours?: number | null;
            accessUrlsId?: string | null;
            credentialsId?: string | null;
            deviceBrand?: string | null;
            deviceModel?: string | null;
            vmsPlanId?: number | null;
            phpRegistrationCode?: string | null;
            serialNumber?: string | null;
            p2pState?: string | null;
            lastPowerCycle?: string | null;
            baselineThumbnailFilename?: string | null;
            currentThumbnailFilename?: string | null;
            sceneChangeDetectionEnabled?: boolean | null;
            passingSceneDetection?: boolean | null;
            hubspotId?: string | null;
            immixMonitoring?: boolean | null;
            hasMic?: boolean | null;
            hasSpeaker?: boolean | null;
            audioGroups?: Array<string> | null;
            createdAt?: string | null;
            updatedAt?: string | null;
            qualitySettings?: {
              __typename: "CameraQualitySettings";
              id: string;
              width: number;
              height: number;
              videoBitRate: number;
              frameRate: number;
              monitoringCenterId: string;
              cameraId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
        sops?: {
          __typename: "ModelSOPConnection";
          items: Array<{
            __typename: "SOP";
            id: string;
            siteId: string;
            eventType: EventType;
            eventSOPs: Array<EventOp>;
            monitoringCenterId: string;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetVMSDetailsQuery = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListVMSDetailssQuery = {
  __typename: "ModelVMSDetailsConnection";
  items: Array<{
    __typename: "VMSDetails";
    id: string;
    apiKey: string;
    username: string;
    password: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VmsDetailsByIntegratorQuery = {
  __typename: "ModelVMSDetailsConnection";
  items: Array<{
    __typename: "VMSDetails";
    id: string;
    apiKey: string;
    username: string;
    password: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VsmDetailsByMonitoringCenterQuery = {
  __typename: "ModelVMSDetailsConnection";
  items: Array<{
    __typename: "VMSDetails";
    id: string;
    apiKey: string;
    username: string;
    password: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDashboardDetailQuery = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListDashboardDetailsQuery = {
  __typename: "ModelDashboardDetailConnection";
  items: Array<{
    __typename: "DashboardDetail";
    id: string;
    name: string;
    awsId: string;
    monitoringCenterId: string;
    allowedGroups: Array<UserGroup | null>;
    integratorId?: string | null;
    username?: string | null;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type DashboardDetailsByMonitoringCenterQuery = {
  __typename: "ModelDashboardDetailConnection";
  items: Array<{
    __typename: "DashboardDetail";
    id: string;
    name: string;
    awsId: string;
    monitoringCenterId: string;
    allowedGroups: Array<UserGroup | null>;
    integratorId?: string | null;
    username?: string | null;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetBoldBIDetailsQuery = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListBoldBIDetailssQuery = {
  __typename: "ModelBoldBIDetailsConnection";
  items: Array<{
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type BoldBIByCustomerQuery = {
  __typename: "ModelBoldBIDetailsConnection";
  items: Array<{
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSDetailsQuery = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRapidSOSDetailssQuery = {
  __typename: "ModelRapidSOSDetailsConnection";
  items: Array<{
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RapidSOSByCustomerQuery = {
  __typename: "ModelRapidSOSDetailsConnection";
  items: Array<{
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAddressQuery = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAddresssQuery = {
  __typename: "ModelAddressConnection";
  items: Array<{
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AddressByMonitoringCenterQuery = {
  __typename: "ModelAddressConnection";
  items: Array<{
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetContactQuery = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListContactsQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ContactByMonitoringCenterQuery = {
  __typename: "ModelContactConnection";
  items: Array<{
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPendingSiteQuery = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ListPendingSitesQuery = {
  __typename: "ModelPendingSiteConnection";
  items: Array<{
    __typename: "PendingSite";
    id: string;
    name: string;
    address?: {
      __typename: "PendingEntityAddress";
      lineOne?: string | null;
      lineTwo?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    } | null;
    contacts?: Array<{
      __typename: "PendingEntityContact";
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      types?: Array<SiteContactType> | null;
      verbalPasscode?: string | null;
    }> | null;
    status: PendingEntityStatus;
    notes?: string | null;
    primaryEmail: string;
    customerId?: string | null;
    modifiedBy: string;
    archived: boolean;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PendingSiteByEmailStatusQuery = {
  __typename: "ModelPendingSiteConnection";
  items: Array<{
    __typename: "PendingSite";
    id: string;
    name: string;
    address?: {
      __typename: "PendingEntityAddress";
      lineOne?: string | null;
      lineTwo?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    } | null;
    contacts?: Array<{
      __typename: "PendingEntityContact";
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      types?: Array<SiteContactType> | null;
      verbalPasscode?: string | null;
    }> | null;
    status: PendingEntityStatus;
    notes?: string | null;
    primaryEmail: string;
    customerId?: string | null;
    modifiedBy: string;
    archived: boolean;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PendingSiteByEmailCustomerQuery = {
  __typename: "ModelPendingSiteConnection";
  items: Array<{
    __typename: "PendingSite";
    id: string;
    name: string;
    address?: {
      __typename: "PendingEntityAddress";
      lineOne?: string | null;
      lineTwo?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
    } | null;
    contacts?: Array<{
      __typename: "PendingEntityContact";
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      types?: Array<SiteContactType> | null;
      verbalPasscode?: string | null;
    }> | null;
    status: PendingEntityStatus;
    notes?: string | null;
    primaryEmail: string;
    customerId?: string | null;
    modifiedBy: string;
    archived: boolean;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSiteQuery = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListSitesQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SiteByCustomerQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SiteByIntegratorQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SiteByMonitoringCenterQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SitesByStatusAndCreatedAtQuery = {
  __typename: "ModelSiteConnection";
  items: Array<{
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSiteSupvisdSettingQuery = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListSiteSupvisdSettingsQuery = {
  __typename: "ModelSiteSupvisdSettingConnection";
  items: Array<{
    __typename: "SiteSupvisdSetting";
    id: string;
    siteId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    rules?: {
      __typename: "ModelSupvisdEventRuleConnection";
      items: Array<{
        __typename: "SupvisdEventRule";
        id: string;
        cameraId?: string | null;
        muteSitePersonRule?: boolean | null;
        siteId: string;
        integratorId: string;
        monitoringCenterId: string;
        siteSupvisdSettingId: string;
        modifiedBy: string;
        archived?: boolean | null;
        ruleType: RuleType;
        cameraEventType: CameraEventSupervisionType;
        disposition: EventType;
        confidence?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SupvisdSettingBySiteIdQuery = {
  __typename: "ModelSiteSupvisdSettingConnection";
  items: Array<{
    __typename: "SiteSupvisdSetting";
    id: string;
    siteId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    rules?: {
      __typename: "ModelSupvisdEventRuleConnection";
      items: Array<{
        __typename: "SupvisdEventRule";
        id: string;
        cameraId?: string | null;
        muteSitePersonRule?: boolean | null;
        siteId: string;
        integratorId: string;
        monitoringCenterId: string;
        siteSupvisdSettingId: string;
        modifiedBy: string;
        archived?: boolean | null;
        ruleType: RuleType;
        cameraEventType: CameraEventSupervisionType;
        disposition: EventType;
        confidence?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSupvisdEventRuleQuery = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSupvisdEventRulesQuery = {
  __typename: "ModelSupvisdEventRuleConnection";
  items: Array<{
    __typename: "SupvisdEventRule";
    id: string;
    cameraId?: string | null;
    muteSitePersonRule?: boolean | null;
    siteId: string;
    integratorId: string;
    monitoringCenterId: string;
    siteSupvisdSettingId: string;
    modifiedBy: string;
    archived?: boolean | null;
    ruleType: RuleType;
    cameraEventType: CameraEventSupervisionType;
    disposition: EventType;
    confidence?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RulesBySiteSupvisdSettingQuery = {
  __typename: "ModelSupvisdEventRuleConnection";
  items: Array<{
    __typename: "SupvisdEventRule";
    id: string;
    cameraId?: string | null;
    muteSitePersonRule?: boolean | null;
    siteId: string;
    integratorId: string;
    monitoringCenterId: string;
    siteSupvisdSettingId: string;
    modifiedBy: string;
    archived?: boolean | null;
    ruleType: RuleType;
    cameraEventType: CameraEventSupervisionType;
    disposition: EventType;
    confidence?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSiteDeviceStatusConfigQuery = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type ListSiteDeviceStatusConfigsQuery = {
  __typename: "ModelSiteDeviceStatusConfigConnection";
  items: Array<{
    __typename: "SiteDeviceStatusConfig";
    id: string;
    siteId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    deviceStatusEnabled: boolean;
    statusFrequency: DeviceStatusCheckSLA;
    statusVerifiedTolerance: DeviceStatusCheckSLA;
    statusProperties: Array<DeviceStatusProperties>;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ConfigBySiteQuery = {
  __typename: "ModelSiteDeviceStatusConfigConnection";
  items: Array<{
    __typename: "SiteDeviceStatusConfig";
    id: string;
    siteId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    deviceStatusEnabled: boolean;
    statusFrequency: DeviceStatusCheckSLA;
    statusVerifiedTolerance: DeviceStatusCheckSLA;
    statusProperties: Array<DeviceStatusProperties>;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTriggerQuery = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTriggersQuery = {
  __typename: "ModelTriggerConnection";
  items: Array<{
    __typename: "Trigger";
    id: string;
    siteId: string;
    integratorId: string;
    customerId: string;
    monitoringCenterId: string;
    type: TriggerType;
    filters?: Array<{
      __typename: "TriggerFilter";
      filter: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
      actions?: Array<{
        __typename: "TriggerAction";
        action: TriggerActionType;
        actionSettings: string;
        frequencyLimit?: number | null;
        lastTriggered?: string | null;
      }> | null;
    }> | null;
    modifiedBy: string;
    archived?: boolean | null;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TriggersBySiteQuery = {
  __typename: "ModelTriggerConnection";
  items: Array<{
    __typename: "Trigger";
    id: string;
    siteId: string;
    integratorId: string;
    customerId: string;
    monitoringCenterId: string;
    type: TriggerType;
    filters?: Array<{
      __typename: "TriggerFilter";
      filter: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
      actions?: Array<{
        __typename: "TriggerAction";
        action: TriggerActionType;
        actionSettings: string;
        frequencyLimit?: number | null;
        lastTriggered?: string | null;
      }> | null;
    }> | null;
    modifiedBy: string;
    archived?: boolean | null;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSiteIntegrationQuery = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListSiteIntegrationsQuery = {
  __typename: "ModelSiteIntegrationConnection";
  items: Array<{
    __typename: "SiteIntegration";
    id: string;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    type?: IntegrationType | null;
    subType?: IntegrationSubType | null;
    subTypeList?: Array<IntegrationSubType> | null;
    agency?: string | null;
    phone?: string | null;
    phoneExt?: string | null;
    email?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    trackTickDetails?: {
      __typename: "TrackTikDetails";
      id: string;
      siteIntegrationId: string;
      siteId: string;
      monitoringCenterId: string;
      apiUrl: string;
      apiUsername: string;
      apiPassword: string;
      accountId: string;
      token?: string | null;
      refreshToken?: string | null;
      tokenExpiry?: string | null;
      refreshTokenExpiry?: string | null;
      autoSend?: boolean | null;
      autoSendErrorEmail?: string | null;
      configured: boolean;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taskList?: {
        __typename: "ModelTrackTikTaskConnection";
        items: Array<{
          __typename: "TrackTikTask";
          id: string;
          trackTikDetailsId: string;
          siteId: string;
          monitoringCenterId: string;
          eventType?: EventType | null;
          trackTikName: string;
          trackTikId: number;
          trackTikReportTemplateId: number;
          archived?: boolean | null;
          modifedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IntegrationsBySiteQuery = {
  __typename: "ModelSiteIntegrationConnection";
  items: Array<{
    __typename: "SiteIntegration";
    id: string;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    type?: IntegrationType | null;
    subType?: IntegrationSubType | null;
    subTypeList?: Array<IntegrationSubType> | null;
    agency?: string | null;
    phone?: string | null;
    phoneExt?: string | null;
    email?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    trackTickDetails?: {
      __typename: "TrackTikDetails";
      id: string;
      siteIntegrationId: string;
      siteId: string;
      monitoringCenterId: string;
      apiUrl: string;
      apiUsername: string;
      apiPassword: string;
      accountId: string;
      token?: string | null;
      refreshToken?: string | null;
      tokenExpiry?: string | null;
      refreshTokenExpiry?: string | null;
      autoSend?: boolean | null;
      autoSendErrorEmail?: string | null;
      configured: boolean;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taskList?: {
        __typename: "ModelTrackTikTaskConnection";
        items: Array<{
          __typename: "TrackTikTask";
          id: string;
          trackTikDetailsId: string;
          siteId: string;
          monitoringCenterId: string;
          eventType?: EventType | null;
          trackTikName: string;
          trackTikId: number;
          trackTikReportTemplateId: number;
          archived?: boolean | null;
          modifedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IntegrationsByMonitoringCenterQuery = {
  __typename: "ModelSiteIntegrationConnection";
  items: Array<{
    __typename: "SiteIntegration";
    id: string;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    type?: IntegrationType | null;
    subType?: IntegrationSubType | null;
    subTypeList?: Array<IntegrationSubType> | null;
    agency?: string | null;
    phone?: string | null;
    phoneExt?: string | null;
    email?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    trackTickDetails?: {
      __typename: "TrackTikDetails";
      id: string;
      siteIntegrationId: string;
      siteId: string;
      monitoringCenterId: string;
      apiUrl: string;
      apiUsername: string;
      apiPassword: string;
      accountId: string;
      token?: string | null;
      refreshToken?: string | null;
      tokenExpiry?: string | null;
      refreshTokenExpiry?: string | null;
      autoSend?: boolean | null;
      autoSendErrorEmail?: string | null;
      configured: boolean;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taskList?: {
        __typename: "ModelTrackTikTaskConnection";
        items: Array<{
          __typename: "TrackTikTask";
          id: string;
          trackTikDetailsId: string;
          siteId: string;
          monitoringCenterId: string;
          eventType?: EventType | null;
          trackTikName: string;
          trackTikId: number;
          trackTikReportTemplateId: number;
          archived?: boolean | null;
          modifedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IntegrationsByTypeAndSubTypeQuery = {
  __typename: "ModelSiteIntegrationConnection";
  items: Array<{
    __typename: "SiteIntegration";
    id: string;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    type?: IntegrationType | null;
    subType?: IntegrationSubType | null;
    subTypeList?: Array<IntegrationSubType> | null;
    agency?: string | null;
    phone?: string | null;
    phoneExt?: string | null;
    email?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    trackTickDetails?: {
      __typename: "TrackTikDetails";
      id: string;
      siteIntegrationId: string;
      siteId: string;
      monitoringCenterId: string;
      apiUrl: string;
      apiUsername: string;
      apiPassword: string;
      accountId: string;
      token?: string | null;
      refreshToken?: string | null;
      tokenExpiry?: string | null;
      refreshTokenExpiry?: string | null;
      autoSend?: boolean | null;
      autoSendErrorEmail?: string | null;
      configured: boolean;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taskList?: {
        __typename: "ModelTrackTikTaskConnection";
        items: Array<{
          __typename: "TrackTikTask";
          id: string;
          trackTikDetailsId: string;
          siteId: string;
          monitoringCenterId: string;
          eventType?: EventType | null;
          trackTikName: string;
          trackTikId: number;
          trackTikReportTemplateId: number;
          archived?: boolean | null;
          modifedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSiteVMSDetailsQuery = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSiteVMSDetailssQuery = {
  __typename: "ModelSiteVMSDetailsConnection";
  items: Array<{
    __typename: "SiteVMSDetails";
    id: string;
    username: string;
    password: string;
    apiKey: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    siteId: string;
    siteUserId?: string | null;
    siteUserRoleId?: number | null;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SiteVMSDetailsBySiteQuery = {
  __typename: "ModelSiteVMSDetailsConnection";
  items: Array<{
    __typename: "SiteVMSDetails";
    id: string;
    username: string;
    password: string;
    apiKey: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    siteId: string;
    siteUserId?: string | null;
    siteUserRoleId?: number | null;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTrackTikDetailsQuery = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListTrackTikDetailssQuery = {
  __typename: "ModelTrackTikDetailsConnection";
  items: Array<{
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TrackTikDetailsBySiteIdQuery = {
  __typename: "ModelTrackTikDetailsConnection";
  items: Array<{
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTrackTikTaskQuery = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTrackTikTasksQuery = {
  __typename: "ModelTrackTikTaskConnection";
  items: Array<{
    __typename: "TrackTikTask";
    id: string;
    trackTikDetailsId: string;
    siteId: string;
    monitoringCenterId: string;
    eventType?: EventType | null;
    trackTikName: string;
    trackTikId: number;
    trackTikReportTemplateId: number;
    archived?: boolean | null;
    modifedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TaskByTrackTikDetailsIdQuery = {
  __typename: "ModelTrackTikTaskConnection";
  items: Array<{
    __typename: "TrackTikTask";
    id: string;
    trackTikDetailsId: string;
    siteId: string;
    monitoringCenterId: string;
    eventType?: EventType | null;
    trackTikName: string;
    trackTikId: number;
    trackTikReportTemplateId: number;
    archived?: boolean | null;
    modifedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSiteEmergencyContactQuery = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListSiteEmergencyContactsQuery = {
  __typename: "ModelSiteEmergencyContactConnection";
  items: Array<{
    __typename: "SiteEmergencyContact";
    id: string;
    siteId: string;
    name: string;
    type?: Array<SiteContactType | null> | null;
    verbalPasscode?: string | null;
    email?: string | null;
    priority: number;
    monitoringCenterId: string;
    integratorId?: string | null;
    archived?: boolean | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    phones?: {
      __typename: "ModelPhoneConnection";
      items: Array<{
        __typename: "Phone";
        id: string;
        contactId: string;
        type?: PhoneType | null;
        number?: string | null;
        ext?: string | null;
        monitoringCenterId: string;
        integratorId?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ContactsBySiteQuery = {
  __typename: "ModelSiteEmergencyContactConnection";
  items: Array<{
    __typename: "SiteEmergencyContact";
    id: string;
    siteId: string;
    name: string;
    type?: Array<SiteContactType | null> | null;
    verbalPasscode?: string | null;
    email?: string | null;
    priority: number;
    monitoringCenterId: string;
    integratorId?: string | null;
    archived?: boolean | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    phones?: {
      __typename: "ModelPhoneConnection";
      items: Array<{
        __typename: "Phone";
        id: string;
        contactId: string;
        type?: PhoneType | null;
        number?: string | null;
        ext?: string | null;
        monitoringCenterId: string;
        integratorId?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SiteContactByMonitoringCenterQuery = {
  __typename: "ModelSiteEmergencyContactConnection";
  items: Array<{
    __typename: "SiteEmergencyContact";
    id: string;
    siteId: string;
    name: string;
    type?: Array<SiteContactType | null> | null;
    verbalPasscode?: string | null;
    email?: string | null;
    priority: number;
    monitoringCenterId: string;
    integratorId?: string | null;
    archived?: boolean | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
    phones?: {
      __typename: "ModelPhoneConnection";
      items: Array<{
        __typename: "Phone";
        id: string;
        contactId: string;
        type?: PhoneType | null;
        number?: string | null;
        ext?: string | null;
        monitoringCenterId: string;
        integratorId?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetPhoneQuery = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListPhonesQuery = {
  __typename: "ModelPhoneConnection";
  items: Array<{
    __typename: "Phone";
    id: string;
    contactId: string;
    type?: PhoneType | null;
    number?: string | null;
    ext?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PhonesByContactQuery = {
  __typename: "ModelPhoneConnection";
  items: Array<{
    __typename: "Phone";
    id: string;
    contactId: string;
    type?: PhoneType | null;
    number?: string | null;
    ext?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PhonesByMonitoringCenterQuery = {
  __typename: "ModelPhoneConnection";
  items: Array<{
    __typename: "Phone";
    id: string;
    contactId: string;
    type?: PhoneType | null;
    number?: string | null;
    ext?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PhonesByNumberQuery = {
  __typename: "ModelPhoneConnection";
  items: Array<{
    __typename: "Phone";
    id: string;
    contactId: string;
    type?: PhoneType | null;
    number?: string | null;
    ext?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraQuery = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListCamerasQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CameraByIntegratorQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CameraByCustomerQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CameraBySiteQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CameraByMonitoringCenterQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CameraByVmsCameraIdQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CameraByMCHealthStatusQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type CamerasByHealthCheckStatusAndCreatedAtQuery = {
  __typename: "ModelCameraConnection";
  items: Array<{
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetObjectGroupQuery = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListObjectGroupsQuery = {
  __typename: "ModelObjectGroupConnection";
  items: Array<{
    __typename: "ObjectGroup";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId: string;
    name: string;
    nameTranslated?: string | null;
    nameSanitized?: string | null;
    groupType: ObjectGroupType;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GroupsBySiteTypeQuery = {
  __typename: "ModelObjectGroupConnection";
  items: Array<{
    __typename: "ObjectGroup";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId: string;
    name: string;
    nameTranslated?: string | null;
    nameSanitized?: string | null;
    groupType: ObjectGroupType;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAudioCustomMessageTypeQuery = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAudioCustomMessageTypesQuery = {
  __typename: "ModelAudioCustomMessageTypeConnection";
  items: Array<{
    __typename: "AudioCustomMessageType";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId: string;
    name: string;
    nameTranslated?: string | null;
    nameSanitized?: string | null;
    transcript: string;
    transcriptTranslated?: string | null;
    transcriptSanitized?: string | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AudioMessageTypesBySiteQuery = {
  __typename: "ModelAudioCustomMessageTypeConnection";
  items: Array<{
    __typename: "AudioCustomMessageType";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId: string;
    name: string;
    nameTranslated?: string | null;
    nameSanitized?: string | null;
    transcript: string;
    transcriptTranslated?: string | null;
    transcriptSanitized?: string | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraQualitySettingsQuery = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCameraQualitySettingssQuery = {
  __typename: "ModelCameraQualitySettingsConnection";
  items: Array<{
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraSceneDetectionSettingsQuery = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListCameraSceneDetectionSettingssQuery = {
  __typename: "ModelCameraSceneDetectionSettingsConnection";
  items: Array<{
    __typename: "CameraSceneDetectionSettings";
    id: string;
    cameraId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    thresholds?: {
      __typename: "ModelCameraSceneDetectionThresholdConnection";
      items: Array<{
        __typename: "CameraSceneDetectionThreshold";
        id: string;
        cameraId: string;
        cameraSceneDetectionSettingsId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        archived?: boolean | null;
        objectName: string;
        baselineValue: number;
        thresholdValue: number;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type DetSettingsByCameraQuery = {
  __typename: "ModelCameraSceneDetectionSettingsConnection";
  items: Array<{
    __typename: "CameraSceneDetectionSettings";
    id: string;
    cameraId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    thresholds?: {
      __typename: "ModelCameraSceneDetectionThresholdConnection";
      items: Array<{
        __typename: "CameraSceneDetectionThreshold";
        id: string;
        cameraId: string;
        cameraSceneDetectionSettingsId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        archived?: boolean | null;
        objectName: string;
        baselineValue: number;
        thresholdValue: number;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraSceneDetectionThresholdQuery = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type ListCameraSceneDetectionThresholdsQuery = {
  __typename: "ModelCameraSceneDetectionThresholdConnection";
  items: Array<{
    __typename: "CameraSceneDetectionThreshold";
    id: string;
    cameraId: string;
    cameraSceneDetectionSettingsId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    objectName: string;
    baselineValue: number;
    thresholdValue: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type DetThresholdByDetIdQuery = {
  __typename: "ModelCameraSceneDetectionThresholdConnection";
  items: Array<{
    __typename: "CameraSceneDetectionThreshold";
    id: string;
    cameraId: string;
    cameraSceneDetectionSettingsId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    objectName: string;
    baselineValue: number;
    thresholdValue: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraSceneDetectionResultQuery = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListCameraSceneDetectionResultsQuery = {
  __typename: "ModelCameraSceneDetectionResultConnection";
  items: Array<{
    __typename: "CameraSceneDetectionResult";
    id: string;
    cameraId: string;
    passing: boolean;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt?: string | null;
    updatedAt: string;
    items?: {
      __typename: "ModelCameraSceneDetectionResultItemConnection";
      items: Array<{
        __typename: "CameraSceneDetectionResultItem";
        id: string;
        cameraSceneDetectionResultId: string;
        cameraId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        objectName: string;
        thresholdValue: number;
        actualValue: number;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type DetResultByCameraIdQuery = {
  __typename: "ModelCameraSceneDetectionResultConnection";
  items: Array<{
    __typename: "CameraSceneDetectionResult";
    id: string;
    cameraId: string;
    passing: boolean;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt?: string | null;
    updatedAt: string;
    items?: {
      __typename: "ModelCameraSceneDetectionResultItemConnection";
      items: Array<{
        __typename: "CameraSceneDetectionResultItem";
        id: string;
        cameraSceneDetectionResultId: string;
        cameraId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        objectName: string;
        thresholdValue: number;
        actualValue: number;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraSceneDetectionResultItemQuery = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type ListCameraSceneDetectionResultItemsQuery = {
  __typename: "ModelCameraSceneDetectionResultItemConnection";
  items: Array<{
    __typename: "CameraSceneDetectionResultItem";
    id: string;
    cameraSceneDetectionResultId: string;
    cameraId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    objectName: string;
    thresholdValue: number;
    actualValue: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ResultItemBySceneDetectionIdQuery = {
  __typename: "ModelCameraSceneDetectionResultItemConnection";
  items: Array<{
    __typename: "CameraSceneDetectionResultItem";
    id: string;
    cameraSceneDetectionResultId: string;
    cameraId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    objectName: string;
    thresholdValue: number;
    actualValue: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraCredentialsQuery = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCameraCredentialssQuery = {
  __typename: "ModelCameraCredentialsConnection";
  items: Array<{
    __typename: "CameraCredentials";
    id: string;
    name: string;
    password: string;
    cameraId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraAccessUrlsQuery = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListCameraAccessUrlssQuery = {
  __typename: "ModelCameraAccessUrlsConnection";
  items: Array<{
    __typename: "CameraAccessUrls";
    id: string;
    dashStream?: string | null;
    hlsStream?: string | null;
    http?: string | null;
    rtmpStream?: string | null;
    rtsp?: string | null;
    snapShot?: string | null;
    webRtc?: string | null;
    cameraId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraImmixConfigQuery = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCameraImmixConfigsQuery = {
  __typename: "ModelCameraImmixConfigConnection";
  items: Array<{
    __typename: "CameraImmixConfig";
    id: string;
    cameraId: string;
    vmsCameraId: string;
    vmsWebhookId?: string | null;
    immixEmail: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ImmixConfigByCameraQuery = {
  __typename: "ModelCameraImmixConfigConnection";
  items: Array<{
    __typename: "CameraImmixConfig";
    id: string;
    cameraId: string;
    vmsCameraId: string;
    vmsWebhookId?: string | null;
    immixEmail: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ImmixConfigByVMSCameraQuery = {
  __typename: "ModelCameraImmixConfigConnection";
  items: Array<{
    __typename: "CameraImmixConfig";
    id: string;
    cameraId: string;
    vmsCameraId: string;
    vmsWebhookId?: string | null;
    immixEmail: string;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetActivityLogQuery = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type ListActivityLogsQuery = {
  __typename: "ModelActivityLogConnection";
  items: Array<{
    __typename: "ActivityLog";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId?: string | null;
    cameraId?: string | null;
    modifiedOn: string;
    modifiedBy?: string | null;
    type: ActivityLogType;
    subSystem: ActivityLogSubSystem;
    event: ActivityLogEvent;
    reference?: string | null;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ActivityLogByMonitoringCenterQuery = {
  __typename: "ModelActivityLogConnection";
  items: Array<{
    __typename: "ActivityLog";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId?: string | null;
    cameraId?: string | null;
    modifiedOn: string;
    modifiedBy?: string | null;
    type: ActivityLogType;
    subSystem: ActivityLogSubSystem;
    event: ActivityLogEvent;
    reference?: string | null;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ActivityLogByIntegratorIdQuery = {
  __typename: "ModelActivityLogConnection";
  items: Array<{
    __typename: "ActivityLog";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId?: string | null;
    cameraId?: string | null;
    modifiedOn: string;
    modifiedBy?: string | null;
    type: ActivityLogType;
    subSystem: ActivityLogSubSystem;
    event: ActivityLogEvent;
    reference?: string | null;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ActivityLogByCustomerIdQuery = {
  __typename: "ModelActivityLogConnection";
  items: Array<{
    __typename: "ActivityLog";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId?: string | null;
    cameraId?: string | null;
    modifiedOn: string;
    modifiedBy?: string | null;
    type: ActivityLogType;
    subSystem: ActivityLogSubSystem;
    event: ActivityLogEvent;
    reference?: string | null;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ActivityLogBySiteIdQuery = {
  __typename: "ModelActivityLogConnection";
  items: Array<{
    __typename: "ActivityLog";
    id: string;
    monitoringCenterId: string;
    integratorId: string;
    customerId: string;
    siteId?: string | null;
    cameraId?: string | null;
    modifiedOn: string;
    modifiedBy?: string | null;
    type: ActivityLogType;
    subSystem: ActivityLogSubSystem;
    event: ActivityLogEvent;
    reference?: string | null;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSignalTestLogQuery = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type ListSignalTestLogsQuery = {
  __typename: "ModelSignalTestLogConnection";
  items: Array<{
    __typename: "SignalTestLog";
    id: string;
    cameraId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    createdAt: string;
    modifiedBy: string;
    vmsEventId: string;
    objectsFound: Array<{
      __typename: "CameraEventObject";
      object: CameraEventObjectType;
      confidence: number;
    } | null>;
    starred: ActiveInactive;
    thumbnailFilename: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SignalTestingBySiteIdQuery = {
  __typename: "ModelSignalTestLogConnection";
  items: Array<{
    __typename: "SignalTestLog";
    id: string;
    cameraId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    createdAt: string;
    modifiedBy: string;
    vmsEventId: string;
    objectsFound: Array<{
      __typename: "CameraEventObject";
      object: CameraEventObjectType;
      confidence: number;
    } | null>;
    starred: ActiveInactive;
    thumbnailFilename: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SignalTestingByCameraIdQuery = {
  __typename: "ModelSignalTestLogConnection";
  items: Array<{
    __typename: "SignalTestLog";
    id: string;
    cameraId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    createdAt: string;
    modifiedBy: string;
    vmsEventId: string;
    objectsFound: Array<{
      __typename: "CameraEventObject";
      object: CameraEventObjectType;
      confidence: number;
    } | null>;
    starred: ActiveInactive;
    thumbnailFilename: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SignalTestingByCameraStarredQuery = {
  __typename: "ModelSignalTestLogConnection";
  items: Array<{
    __typename: "SignalTestLog";
    id: string;
    cameraId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    createdAt: string;
    modifiedBy: string;
    vmsEventId: string;
    objectsFound: Array<{
      __typename: "CameraEventObject";
      object: CameraEventObjectType;
      confidence: number;
    } | null>;
    starred: ActiveInactive;
    thumbnailFilename: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAuditLogQuery = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type ListAuditLogsQuery = {
  __typename: "ModelAuditLogConnection";
  items: Array<{
    __typename: "AuditLog";
    id: string;
    partition: string;
    monitoringCenterId: string;
    modifiedOn: string;
    modifiedBy?: string | null;
    area: AuditLogArea;
    action: AuditLogAction;
    entityModel?: string | null;
    entityKey?: string | null;
    description?: string | null;
    newImage?: string | null;
    oldImage?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AuditLogByMonitoringCenterQuery = {
  __typename: "ModelAuditLogConnection";
  items: Array<{
    __typename: "AuditLog";
    id: string;
    partition: string;
    monitoringCenterId: string;
    modifiedOn: string;
    modifiedBy?: string | null;
    area: AuditLogArea;
    action: AuditLogAction;
    entityModel?: string | null;
    entityKey?: string | null;
    description?: string | null;
    newImage?: string | null;
    oldImage?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AuditLogByPartitionQuery = {
  __typename: "ModelAuditLogConnection";
  items: Array<{
    __typename: "AuditLog";
    id: string;
    partition: string;
    monitoringCenterId: string;
    modifiedOn: string;
    modifiedBy?: string | null;
    area: AuditLogArea;
    action: AuditLogAction;
    entityModel?: string | null;
    entityKey?: string | null;
    description?: string | null;
    newImage?: string | null;
    oldImage?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentActionDetailQuery = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type ListIncidentActionDetailsQuery = {
  __typename: "ModelIncidentActionDetailConnection";
  items: Array<{
    __typename: "IncidentActionDetail";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    createdAt: string;
    modifiedBy: string;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ActionsByIncidentQuery = {
  __typename: "ModelIncidentActionDetailConnection";
  items: Array<{
    __typename: "IncidentActionDetail";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    createdAt: string;
    modifiedBy: string;
    description?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSOPQuery = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSOPsQuery = {
  __typename: "ModelSOPConnection";
  items: Array<{
    __typename: "SOP";
    id: string;
    siteId: string;
    eventType: EventType;
    eventSOPs: Array<EventOp>;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SopBySiteQuery = {
  __typename: "ModelSOPConnection";
  items: Array<{
    __typename: "SOP";
    id: string;
    siteId: string;
    eventType: EventType;
    eventSOPs: Array<EventOp>;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SopMonitoringCenterQuery = {
  __typename: "ModelSOPConnection";
  items: Array<{
    __typename: "SOP";
    id: string;
    siteId: string;
    eventType: EventType;
    eventSOPs: Array<EventOp>;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRspndrEventsQuery = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRspndrEventssQuery = {
  __typename: "ModelRspndrEventsConnection";
  items: Array<{
    __typename: "RspndrEvents";
    id: string;
    request_id: string;
    tenant_id?: string | null;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RspndrEventsSortedQuery = {
  __typename: "ModelRspndrEventsConnection";
  items: Array<{
    __typename: "RspndrEvents";
    id: string;
    request_id: string;
    tenant_id?: string | null;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RspndrEventsByRequestIdQuery = {
  __typename: "ModelRspndrEventsConnection";
  items: Array<{
    __typename: "RspndrEvents";
    id: string;
    request_id: string;
    tenant_id?: string | null;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRspndrIncidentQuery = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListRspndrIncidentsQuery = {
  __typename: "ModelRspndrIncidentConnection";
  items: Array<{
    __typename: "RspndrIncident";
    id: string;
    incident_id: string;
    address1: string;
    address2?: string | null;
    arrived_at?: string | null;
    billed_to?: RspndrBilledTo | null;
    city: string;
    country?: string | null;
    created_at?: string | null;
    customer_number?: string | null;
    dealer?: string | null;
    eta_at?: string | null;
    ext_username?: string | null;
    finished_at?: string | null;
    grounded_at?: string | null;
    grounding_end_confirm_acked_at?: string | null;
    grounding_end_confirmed_at?: string | null;
    grounding_end_decline_acked_at?: string | null;
    grounding_end_declined_at?: string | null;
    grounding_ended_at?: string | null;
    grounding_ending_at?: string | null;
    grounding_request_confirm_acked_at?: string | null;
    grounding_request_confirmed_at?: string | null;
    grounding_request_decline_acked_at?: string | null;
    grounding_request_declined_at?: string | null;
    grounding_requested_at?: string | null;
    grounding_terminate_acked_at?: string | null;
    grounding_terminating_at?: string | null;
    kind?: RspndrServiceKind | null;
    lat?: number | null;
    lng?: number | null;
    master_account_number?: string | null;
    modified_at?: string | null;
    name?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    postal_code: string;
    state: string;
    status?: RspndrIncidentStatus | null;
    taken_at?: string | null;
    tenant_id: string;
    timezone?: string | null;
    triggered_at?: string | null;
    type?: string | null;
    zone?: string | null;
    monitoringCenterId: string;
    customerId: string;
    integratorId: string;
    siteId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    check_list?: {
      __typename: "ModelRspndrIncidentCheckItemConnection";
      items: Array<{
        __typename: "RspndrIncidentCheckItem";
        id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        image_required?: boolean | null;
        image_url?: string | null;
        order?: number | null;
        required?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    rules?: {
      __typename: "ModelRspndrIncidentGroundingRuleConnection";
      items: Array<{
        __typename: "RspndrIncidentGroundingRule";
        id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        call_police?: boolean | null;
        expires_at?: string | null;
        leave_when?: RspndrLeaveWhen | null;
        max_duration?: string | null;
        order?: number | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRspndrActivityConnection";
      items: Array<{
        __typename: "RspndrActivity";
        id: string;
        tenant_id?: string | null;
        request_id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        type: RspndrIncidentStatus;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RspndrByIncidentIdQuery = {
  __typename: "ModelRspndrIncidentConnection";
  items: Array<{
    __typename: "RspndrIncident";
    id: string;
    incident_id: string;
    address1: string;
    address2?: string | null;
    arrived_at?: string | null;
    billed_to?: RspndrBilledTo | null;
    city: string;
    country?: string | null;
    created_at?: string | null;
    customer_number?: string | null;
    dealer?: string | null;
    eta_at?: string | null;
    ext_username?: string | null;
    finished_at?: string | null;
    grounded_at?: string | null;
    grounding_end_confirm_acked_at?: string | null;
    grounding_end_confirmed_at?: string | null;
    grounding_end_decline_acked_at?: string | null;
    grounding_end_declined_at?: string | null;
    grounding_ended_at?: string | null;
    grounding_ending_at?: string | null;
    grounding_request_confirm_acked_at?: string | null;
    grounding_request_confirmed_at?: string | null;
    grounding_request_decline_acked_at?: string | null;
    grounding_request_declined_at?: string | null;
    grounding_requested_at?: string | null;
    grounding_terminate_acked_at?: string | null;
    grounding_terminating_at?: string | null;
    kind?: RspndrServiceKind | null;
    lat?: number | null;
    lng?: number | null;
    master_account_number?: string | null;
    modified_at?: string | null;
    name?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    postal_code: string;
    state: string;
    status?: RspndrIncidentStatus | null;
    taken_at?: string | null;
    tenant_id: string;
    timezone?: string | null;
    triggered_at?: string | null;
    type?: string | null;
    zone?: string | null;
    monitoringCenterId: string;
    customerId: string;
    integratorId: string;
    siteId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    check_list?: {
      __typename: "ModelRspndrIncidentCheckItemConnection";
      items: Array<{
        __typename: "RspndrIncidentCheckItem";
        id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        image_required?: boolean | null;
        image_url?: string | null;
        order?: number | null;
        required?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    rules?: {
      __typename: "ModelRspndrIncidentGroundingRuleConnection";
      items: Array<{
        __typename: "RspndrIncidentGroundingRule";
        id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        call_police?: boolean | null;
        expires_at?: string | null;
        leave_when?: RspndrLeaveWhen | null;
        max_duration?: string | null;
        order?: number | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRspndrActivityConnection";
      items: Array<{
        __typename: "RspndrActivity";
        id: string;
        tenant_id?: string | null;
        request_id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        type: RspndrIncidentStatus;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RspndrIncidentBySiteQuery = {
  __typename: "ModelRspndrIncidentConnection";
  items: Array<{
    __typename: "RspndrIncident";
    id: string;
    incident_id: string;
    address1: string;
    address2?: string | null;
    arrived_at?: string | null;
    billed_to?: RspndrBilledTo | null;
    city: string;
    country?: string | null;
    created_at?: string | null;
    customer_number?: string | null;
    dealer?: string | null;
    eta_at?: string | null;
    ext_username?: string | null;
    finished_at?: string | null;
    grounded_at?: string | null;
    grounding_end_confirm_acked_at?: string | null;
    grounding_end_confirmed_at?: string | null;
    grounding_end_decline_acked_at?: string | null;
    grounding_end_declined_at?: string | null;
    grounding_ended_at?: string | null;
    grounding_ending_at?: string | null;
    grounding_request_confirm_acked_at?: string | null;
    grounding_request_confirmed_at?: string | null;
    grounding_request_decline_acked_at?: string | null;
    grounding_request_declined_at?: string | null;
    grounding_requested_at?: string | null;
    grounding_terminate_acked_at?: string | null;
    grounding_terminating_at?: string | null;
    kind?: RspndrServiceKind | null;
    lat?: number | null;
    lng?: number | null;
    master_account_number?: string | null;
    modified_at?: string | null;
    name?: string | null;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    postal_code: string;
    state: string;
    status?: RspndrIncidentStatus | null;
    taken_at?: string | null;
    tenant_id: string;
    timezone?: string | null;
    triggered_at?: string | null;
    type?: string | null;
    zone?: string | null;
    monitoringCenterId: string;
    customerId: string;
    integratorId: string;
    siteId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    check_list?: {
      __typename: "ModelRspndrIncidentCheckItemConnection";
      items: Array<{
        __typename: "RspndrIncidentCheckItem";
        id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        image_required?: boolean | null;
        image_url?: string | null;
        order?: number | null;
        required?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    rules?: {
      __typename: "ModelRspndrIncidentGroundingRuleConnection";
      items: Array<{
        __typename: "RspndrIncidentGroundingRule";
        id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        call_police?: boolean | null;
        expires_at?: string | null;
        leave_when?: RspndrLeaveWhen | null;
        max_duration?: string | null;
        order?: number | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRspndrActivityConnection";
      items: Array<{
        __typename: "RspndrActivity";
        id: string;
        tenant_id?: string | null;
        request_id: string;
        incidentId: string;
        rspndrIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        type: RspndrIncidentStatus;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetRspndrIncidentCheckItemQuery = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListRspndrIncidentCheckItemsQuery = {
  __typename: "ModelRspndrIncidentCheckItemConnection";
  items: Array<{
    __typename: "RspndrIncidentCheckItem";
    id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    image_required?: boolean | null;
    image_url?: string | null;
    order?: number | null;
    required?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CheckItemByRspndrIncidentIdQuery = {
  __typename: "ModelRspndrIncidentCheckItemConnection";
  items: Array<{
    __typename: "RspndrIncidentCheckItem";
    id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    image_required?: boolean | null;
    image_url?: string | null;
    order?: number | null;
    required?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRspndrIncidentGroundingRuleQuery = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListRspndrIncidentGroundingRulesQuery = {
  __typename: "ModelRspndrIncidentGroundingRuleConnection";
  items: Array<{
    __typename: "RspndrIncidentGroundingRule";
    id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    call_police?: boolean | null;
    expires_at?: string | null;
    leave_when?: RspndrLeaveWhen | null;
    max_duration?: string | null;
    order?: number | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GroundingRuleByRspndrIncidentIdQuery = {
  __typename: "ModelRspndrIncidentGroundingRuleConnection";
  items: Array<{
    __typename: "RspndrIncidentGroundingRule";
    id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    call_police?: boolean | null;
    expires_at?: string | null;
    leave_when?: RspndrLeaveWhen | null;
    max_duration?: string | null;
    order?: number | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRspndrActivityQuery = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type ListRspndrActivitysQuery = {
  __typename: "ModelRspndrActivityConnection";
  items: Array<{
    __typename: "RspndrActivity";
    id: string;
    tenant_id?: string | null;
    request_id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    type: RspndrIncidentStatus;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RspndrActivityByIncidentIdQuery = {
  __typename: "ModelRspndrActivityConnection";
  items: Array<{
    __typename: "RspndrActivity";
    id: string;
    tenant_id?: string | null;
    request_id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    type: RspndrIncidentStatus;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RspndrActivityByRspndrIncidentIdQuery = {
  __typename: "ModelRspndrActivityConnection";
  items: Array<{
    __typename: "RspndrActivity";
    id: string;
    tenant_id?: string | null;
    request_id: string;
    incidentId: string;
    rspndrIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    type: RspndrIncidentStatus;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTrackTikIncidentQuery = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListTrackTikIncidentsQuery = {
  __typename: "ModelTrackTikIncidentConnection";
  items: Array<{
    __typename: "TrackTikIncident";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    status: TrackTikIncidentStatus;
    reportTemplateId: number;
    taskTypeId: number;
    taskTypeName: string;
    priority: TrackTikPriority;
    trackTikId: number;
    primaryCameraEventId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    activities?: {
      __typename: "ModelTrackTikActivityConnection";
      items: Array<{
        __typename: "TrackTikActivity";
        id: string;
        incidentId: string;
        trackTikIncidentId: string;
        monitoringCenterId: string;
        notes?: string | null;
        payload?: string | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    additionalDataToSend?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TrackTikByIncidentIdQuery = {
  __typename: "ModelTrackTikIncidentConnection";
  items: Array<{
    __typename: "TrackTikIncident";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    status: TrackTikIncidentStatus;
    reportTemplateId: number;
    taskTypeId: number;
    taskTypeName: string;
    priority: TrackTikPriority;
    trackTikId: number;
    primaryCameraEventId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    activities?: {
      __typename: "ModelTrackTikActivityConnection";
      items: Array<{
        __typename: "TrackTikActivity";
        id: string;
        incidentId: string;
        trackTikIncidentId: string;
        monitoringCenterId: string;
        notes?: string | null;
        payload?: string | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    additionalDataToSend?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTrackTikActivityQuery = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTrackTikActivitysQuery = {
  __typename: "ModelTrackTikActivityConnection";
  items: Array<{
    __typename: "TrackTikActivity";
    id: string;
    incidentId: string;
    trackTikIncidentId: string;
    monitoringCenterId: string;
    notes?: string | null;
    payload?: string | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TrackTikActivityByIncidentIdQuery = {
  __typename: "ModelTrackTikActivityConnection";
  items: Array<{
    __typename: "TrackTikActivity";
    id: string;
    incidentId: string;
    trackTikIncidentId: string;
    monitoringCenterId: string;
    notes?: string | null;
    payload?: string | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TrackTikActivityByTrackTikIncidentIdQuery = {
  __typename: "ModelTrackTikActivityConnection";
  items: Array<{
    __typename: "TrackTikActivity";
    id: string;
    incidentId: string;
    trackTikIncidentId: string;
    monitoringCenterId: string;
    notes?: string | null;
    payload?: string | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSIncidentQuery = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListRapidSOSIncidentsQuery = {
  __typename: "ModelRapidSOSIncidentConnection";
  items: Array<{
    __typename: "RapidSOSIncident";
    id: string;
    coverageType: RapidSOSCoverageType;
    alert_id?: string | null;
    covering_psap?: string | null;
    callflow: string;
    zone_description?: string | null;
    alarm_permit_number?: string | null;
    visually_verified: boolean;
    site_phone?: string | null;
    status: RapidSOSIncidentStatus;
    instructions?: string | null;
    createdAt: string;
    modifiedBy: string;
    primaryCameraEventId: string;
    siteIntegrationId: string;
    incidentId: string;
    siteId: string;
    customerId: string;
    monitoringCenterId: string;
    updatedAt: string;
    event: {
      __typename: "RapidSOSEventDetails";
      id: string;
      rapidSOSIncidentId: string;
      description: string;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      emergency_type: RapidSOSEmergencyType;
      incident_time?: string | null;
      service_provider_name?: string | null;
      site_type?: SiteType | null;
      source_id: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      location: {
        __typename: "RapidSOSLocation";
        id: string;
        rapidSOSIncidentId: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
        civic?: {
          __typename: "RapidSOSCivicAddress";
          id: string;
          rapidSOSIncidentId: string;
          name?: string | null;
          street_1: string;
          street_2?: string | null;
          city: string;
          state: string;
          country: string;
          zip_code: string;
          modifiedBy: string;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    };
    emergency_contacts?: {
      __typename: "ModelRapidSOSEmergencyContactConnection";
      items: Array<{
        __typename: "RapidSOSEmergencyContact";
        id: string;
        rapidSOSIncidentId: string;
        name: string;
        phone: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRapidSOSActivityConnection";
      items: Array<{
        __typename: "RapidSOSActivity";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
        logMessage?: {
          __typename: "RapidSOSLogMessage";
          id: string;
          incidentId: string;
          siteIntegrationId: string;
          rapidSOSIncidentId: string;
          monitoringCenterId: string;
          type: RapidSOSLogMessageType;
          modifiedBy: string;
          writtenBy: string;
          message?: string | null;
          messageTranslated?: string | null;
          messageSanitized?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    attachments?: {
      __typename: "ModelRapidSOSAttachmentConnection";
      items: Array<{
        __typename: "RapidSOSAttachment";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        fileName: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    additionalDataToSend?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RapidSOSByIncidentIdQuery = {
  __typename: "ModelRapidSOSIncidentConnection";
  items: Array<{
    __typename: "RapidSOSIncident";
    id: string;
    coverageType: RapidSOSCoverageType;
    alert_id?: string | null;
    covering_psap?: string | null;
    callflow: string;
    zone_description?: string | null;
    alarm_permit_number?: string | null;
    visually_verified: boolean;
    site_phone?: string | null;
    status: RapidSOSIncidentStatus;
    instructions?: string | null;
    createdAt: string;
    modifiedBy: string;
    primaryCameraEventId: string;
    siteIntegrationId: string;
    incidentId: string;
    siteId: string;
    customerId: string;
    monitoringCenterId: string;
    updatedAt: string;
    event: {
      __typename: "RapidSOSEventDetails";
      id: string;
      rapidSOSIncidentId: string;
      description: string;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      emergency_type: RapidSOSEmergencyType;
      incident_time?: string | null;
      service_provider_name?: string | null;
      site_type?: SiteType | null;
      source_id: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      location: {
        __typename: "RapidSOSLocation";
        id: string;
        rapidSOSIncidentId: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
        civic?: {
          __typename: "RapidSOSCivicAddress";
          id: string;
          rapidSOSIncidentId: string;
          name?: string | null;
          street_1: string;
          street_2?: string | null;
          city: string;
          state: string;
          country: string;
          zip_code: string;
          modifiedBy: string;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    };
    emergency_contacts?: {
      __typename: "ModelRapidSOSEmergencyContactConnection";
      items: Array<{
        __typename: "RapidSOSEmergencyContact";
        id: string;
        rapidSOSIncidentId: string;
        name: string;
        phone: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRapidSOSActivityConnection";
      items: Array<{
        __typename: "RapidSOSActivity";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
        logMessage?: {
          __typename: "RapidSOSLogMessage";
          id: string;
          incidentId: string;
          siteIntegrationId: string;
          rapidSOSIncidentId: string;
          monitoringCenterId: string;
          type: RapidSOSLogMessageType;
          modifiedBy: string;
          writtenBy: string;
          message?: string | null;
          messageTranslated?: string | null;
          messageSanitized?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    attachments?: {
      __typename: "ModelRapidSOSAttachmentConnection";
      items: Array<{
        __typename: "RapidSOSAttachment";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        fileName: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    additionalDataToSend?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RapidSOSByIncidentIdSiteIntegrationQuery = {
  __typename: "ModelRapidSOSIncidentConnection";
  items: Array<{
    __typename: "RapidSOSIncident";
    id: string;
    coverageType: RapidSOSCoverageType;
    alert_id?: string | null;
    covering_psap?: string | null;
    callflow: string;
    zone_description?: string | null;
    alarm_permit_number?: string | null;
    visually_verified: boolean;
    site_phone?: string | null;
    status: RapidSOSIncidentStatus;
    instructions?: string | null;
    createdAt: string;
    modifiedBy: string;
    primaryCameraEventId: string;
    siteIntegrationId: string;
    incidentId: string;
    siteId: string;
    customerId: string;
    monitoringCenterId: string;
    updatedAt: string;
    event: {
      __typename: "RapidSOSEventDetails";
      id: string;
      rapidSOSIncidentId: string;
      description: string;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      emergency_type: RapidSOSEmergencyType;
      incident_time?: string | null;
      service_provider_name?: string | null;
      site_type?: SiteType | null;
      source_id: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      location: {
        __typename: "RapidSOSLocation";
        id: string;
        rapidSOSIncidentId: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
        civic?: {
          __typename: "RapidSOSCivicAddress";
          id: string;
          rapidSOSIncidentId: string;
          name?: string | null;
          street_1: string;
          street_2?: string | null;
          city: string;
          state: string;
          country: string;
          zip_code: string;
          modifiedBy: string;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    };
    emergency_contacts?: {
      __typename: "ModelRapidSOSEmergencyContactConnection";
      items: Array<{
        __typename: "RapidSOSEmergencyContact";
        id: string;
        rapidSOSIncidentId: string;
        name: string;
        phone: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRapidSOSActivityConnection";
      items: Array<{
        __typename: "RapidSOSActivity";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
        logMessage?: {
          __typename: "RapidSOSLogMessage";
          id: string;
          incidentId: string;
          siteIntegrationId: string;
          rapidSOSIncidentId: string;
          monitoringCenterId: string;
          type: RapidSOSLogMessageType;
          modifiedBy: string;
          writtenBy: string;
          message?: string | null;
          messageTranslated?: string | null;
          messageSanitized?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    attachments?: {
      __typename: "ModelRapidSOSAttachmentConnection";
      items: Array<{
        __typename: "RapidSOSAttachment";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        fileName: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    additionalDataToSend?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RapidSOSByAlertIdQuery = {
  __typename: "ModelRapidSOSIncidentConnection";
  items: Array<{
    __typename: "RapidSOSIncident";
    id: string;
    coverageType: RapidSOSCoverageType;
    alert_id?: string | null;
    covering_psap?: string | null;
    callflow: string;
    zone_description?: string | null;
    alarm_permit_number?: string | null;
    visually_verified: boolean;
    site_phone?: string | null;
    status: RapidSOSIncidentStatus;
    instructions?: string | null;
    createdAt: string;
    modifiedBy: string;
    primaryCameraEventId: string;
    siteIntegrationId: string;
    incidentId: string;
    siteId: string;
    customerId: string;
    monitoringCenterId: string;
    updatedAt: string;
    event: {
      __typename: "RapidSOSEventDetails";
      id: string;
      rapidSOSIncidentId: string;
      description: string;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      emergency_type: RapidSOSEmergencyType;
      incident_time?: string | null;
      service_provider_name?: string | null;
      site_type?: SiteType | null;
      source_id: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      location: {
        __typename: "RapidSOSLocation";
        id: string;
        rapidSOSIncidentId: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
        civic?: {
          __typename: "RapidSOSCivicAddress";
          id: string;
          rapidSOSIncidentId: string;
          name?: string | null;
          street_1: string;
          street_2?: string | null;
          city: string;
          state: string;
          country: string;
          zip_code: string;
          modifiedBy: string;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    };
    emergency_contacts?: {
      __typename: "ModelRapidSOSEmergencyContactConnection";
      items: Array<{
        __typename: "RapidSOSEmergencyContact";
        id: string;
        rapidSOSIncidentId: string;
        name: string;
        phone: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    activities?: {
      __typename: "ModelRapidSOSActivityConnection";
      items: Array<{
        __typename: "RapidSOSActivity";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        error?: string | null;
        payload?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        updatedAt: string;
        logMessage?: {
          __typename: "RapidSOSLogMessage";
          id: string;
          incidentId: string;
          siteIntegrationId: string;
          rapidSOSIncidentId: string;
          monitoringCenterId: string;
          type: RapidSOSLogMessageType;
          modifiedBy: string;
          writtenBy: string;
          message?: string | null;
          messageTranslated?: string | null;
          messageSanitized?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    attachments?: {
      __typename: "ModelRapidSOSAttachmentConnection";
      items: Array<{
        __typename: "RapidSOSAttachment";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        fileName: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    additionalDataToSend?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSEventDetailsQuery = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type ListRapidSOSEventDetailssQuery = {
  __typename: "ModelRapidSOSEventDetailsConnection";
  items: Array<{
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSLocationQuery = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListRapidSOSLocationsQuery = {
  __typename: "ModelRapidSOSLocationConnection";
  items: Array<{
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSCivicAddressQuery = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type ListRapidSOSCivicAddresssQuery = {
  __typename: "ModelRapidSOSCivicAddressConnection";
  items: Array<{
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSEmergencyContactQuery = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type ListRapidSOSEmergencyContactsQuery = {
  __typename: "ModelRapidSOSEmergencyContactConnection";
  items: Array<{
    __typename: "RapidSOSEmergencyContact";
    id: string;
    rapidSOSIncidentId: string;
    name: string;
    phone: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ContactsByRapidSOSIncidentIdQuery = {
  __typename: "ModelRapidSOSEmergencyContactConnection";
  items: Array<{
    __typename: "RapidSOSEmergencyContact";
    id: string;
    rapidSOSIncidentId: string;
    name: string;
    phone: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSActivityQuery = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListRapidSOSActivitysQuery = {
  __typename: "ModelRapidSOSActivityConnection";
  items: Array<{
    __typename: "RapidSOSActivity";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
    logMessage?: {
      __typename: "RapidSOSLogMessage";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      type: RapidSOSLogMessageType;
      modifiedBy: string;
      writtenBy: string;
      message?: string | null;
      messageTranslated?: string | null;
      messageSanitized?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RapidSOSActivitySortedQuery = {
  __typename: "ModelRapidSOSActivityConnection";
  items: Array<{
    __typename: "RapidSOSActivity";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
    logMessage?: {
      __typename: "RapidSOSLogMessage";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      type: RapidSOSLogMessageType;
      modifiedBy: string;
      writtenBy: string;
      message?: string | null;
      messageTranslated?: string | null;
      messageSanitized?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RapidSOSActivityByIncidentIdQuery = {
  __typename: "ModelRapidSOSActivityConnection";
  items: Array<{
    __typename: "RapidSOSActivity";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
    logMessage?: {
      __typename: "RapidSOSLogMessage";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      type: RapidSOSLogMessageType;
      modifiedBy: string;
      writtenBy: string;
      message?: string | null;
      messageTranslated?: string | null;
      messageSanitized?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type RapidActivityByRapidSOSIncidentIdQuery = {
  __typename: "ModelRapidSOSActivityConnection";
  items: Array<{
    __typename: "RapidSOSActivity";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    error?: string | null;
    payload?: string | null;
    modifiedBy?: string | null;
    createdAt: string;
    notes?: string | null;
    notesTranslated?: string | null;
    notesSanitized?: string | null;
    updatedAt: string;
    logMessage?: {
      __typename: "RapidSOSLogMessage";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      type: RapidSOSLogMessageType;
      modifiedBy: string;
      writtenBy: string;
      message?: string | null;
      messageTranslated?: string | null;
      messageSanitized?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSLogMessageQuery = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRapidSOSLogMessagesQuery = {
  __typename: "ModelRapidSOSLogMessageConnection";
  items: Array<{
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRapidSOSAttachmentQuery = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type ListRapidSOSAttachmentsQuery = {
  __typename: "ModelRapidSOSAttachmentConnection";
  items: Array<{
    __typename: "RapidSOSAttachment";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    fileName: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RapidAttachByRapidSOSIncidentIdQuery = {
  __typename: "ModelRapidSOSAttachmentConnection";
  items: Array<{
    __typename: "RapidSOSAttachment";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    fileName: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentCodeDetailQuery = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListIncidentCodeDetailsQuery = {
  __typename: "ModelIncidentCodeDetailConnection";
  items: Array<{
    __typename: "IncidentCodeDetail";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    publicCode?: string | null;
    shareUrl?: string | null;
    locationSid: string;
    deviceSid: string;
    eventDate?: string | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    incidentDataToShare?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IncidentCodeDetailByIncidentQuery = {
  __typename: "ModelIncidentCodeDetailConnection";
  items: Array<{
    __typename: "IncidentCodeDetail";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    publicCode?: string | null;
    shareUrl?: string | null;
    locationSid: string;
    deviceSid: string;
    eventDate?: string | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    incidentDataToShare?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareSettingsQuery = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListIncidentShareSettingssQuery = {
  __typename: "ModelIncidentShareSettingsConnection";
  items: Array<{
    __typename: "IncidentShareSettings";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    code: string;
    modifiedBy: string;
    expiration: string;
    createdAt: string;
    updatedAt: string;
    incidentDataToShare?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareSettingsByIncidentQuery = {
  __typename: "ModelIncidentShareSettingsConnection";
  items: Array<{
    __typename: "IncidentShareSettings";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    code: string;
    modifiedBy: string;
    expiration: string;
    createdAt: string;
    updatedAt: string;
    incidentDataToShare?: {
      __typename: "IncidentDataToShare";
      id: string;
      incidentId: string;
      monitoringCenterId: string;
      shareType?: IncidentShareType | null;
      externalIncidentNotes?: boolean | null;
      externalSiteCriticalNotes?: boolean | null;
      externalClientCriticalNotes?: boolean | null;
      completedActionItems?: boolean | null;
      objectsFound?: boolean | null;
      alarmStationInfo?: boolean | null;
      customerContactInfo?: boolean | null;
      videoFeedUrls?: boolean | null;
      modifiedBy: string;
      attachmentsList?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentDataToShareQuery = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentDataToSharesQuery = {
  __typename: "ModelIncidentDataToShareConnection";
  items: Array<{
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IncidentDataToShareByIncidentQuery = {
  __typename: "ModelIncidentDataToShareConnection";
  items: Array<{
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareEntryQuery = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListIncidentShareEntrysQuery = {
  __typename: "ModelIncidentShareEntryConnection";
  items: Array<{
    __typename: "IncidentShareEntry";
    id: string;
    incidentDataToShareId?: string | null;
    incidentId: string;
    code: string;
    shareType: IncidentShareType;
    expiration: string;
    alarmsCentralStation?: string | null;
    alarmsPermitNum?: string | null;
    alarmsCSID?: string | null;
    attachmentsList?: Array<string> | null;
    incidentOpenTime?: string | null;
    incidentClosedTime?: string | null;
    externalSiteNotes?: string | null;
    externalClientNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    site?: {
      __typename: "IncidentShareSite";
      id: string;
      name: string;
      addressOne: string;
      addressTwo?: string | null;
      city: string;
      state?: string | null;
      country: string;
      zipCode: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    integrator?: {
      __typename: "IncidentShareIntegrator";
      id: string;
      name: string;
      logoPath?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    notes?: {
      __typename: "ModelIncidentShareEntyNoteConnection";
      items: Array<{
        __typename: "IncidentShareEntyNote";
        id: string;
        incidentId?: string | null;
        incidentNoteId?: string | null;
        incidentShareEntryId: string;
        note: string;
        noteCreatedAt: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    events?: {
      __typename: "ModelIncidentShareEventConnection";
      items: Array<{
        __typename: "IncidentShareEvent";
        id: string;
        cameraEventId?: string | null;
        startTime: string;
        vmsCameraName?: string | null;
        videoFeedUrl?: string | null;
        eventType: EventType;
        disposition: Disposition;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        incidentShareEntryId: string;
        cameraLatitude?: number | null;
        cameraLongitude?: number | null;
        createdAt: string;
        updatedAt: string;
        actionItems?: {
          __typename: "ModelIncidentShareActionItemsConnection";
          items: Array<{
            __typename: "IncidentShareActionItems";
            id: string;
            incidentProcedureTaskId?: string | null;
            incidentShareEventId: string;
            order: number;
            completedAt?: string | null;
            description: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contactInformation?: {
      __typename: "ModelIncidentShareContactInformationConnection";
      items: Array<{
        __typename: "IncidentShareContactInformation";
        id: string;
        incidentShareEntryId: string;
        name: string;
        phone: string;
        ext?: string | null;
        email?: string | null;
        type: IncidentShareContactType;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareByCodeQuery = {
  __typename: "ModelIncidentShareEntryConnection";
  items: Array<{
    __typename: "IncidentShareEntry";
    id: string;
    incidentDataToShareId?: string | null;
    incidentId: string;
    code: string;
    shareType: IncidentShareType;
    expiration: string;
    alarmsCentralStation?: string | null;
    alarmsPermitNum?: string | null;
    alarmsCSID?: string | null;
    attachmentsList?: Array<string> | null;
    incidentOpenTime?: string | null;
    incidentClosedTime?: string | null;
    externalSiteNotes?: string | null;
    externalClientNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    site?: {
      __typename: "IncidentShareSite";
      id: string;
      name: string;
      addressOne: string;
      addressTwo?: string | null;
      city: string;
      state?: string | null;
      country: string;
      zipCode: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    integrator?: {
      __typename: "IncidentShareIntegrator";
      id: string;
      name: string;
      logoPath?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    notes?: {
      __typename: "ModelIncidentShareEntyNoteConnection";
      items: Array<{
        __typename: "IncidentShareEntyNote";
        id: string;
        incidentId?: string | null;
        incidentNoteId?: string | null;
        incidentShareEntryId: string;
        note: string;
        noteCreatedAt: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    events?: {
      __typename: "ModelIncidentShareEventConnection";
      items: Array<{
        __typename: "IncidentShareEvent";
        id: string;
        cameraEventId?: string | null;
        startTime: string;
        vmsCameraName?: string | null;
        videoFeedUrl?: string | null;
        eventType: EventType;
        disposition: Disposition;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        incidentShareEntryId: string;
        cameraLatitude?: number | null;
        cameraLongitude?: number | null;
        createdAt: string;
        updatedAt: string;
        actionItems?: {
          __typename: "ModelIncidentShareActionItemsConnection";
          items: Array<{
            __typename: "IncidentShareActionItems";
            id: string;
            incidentProcedureTaskId?: string | null;
            incidentShareEventId: string;
            order: number;
            completedAt?: string | null;
            description: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contactInformation?: {
      __typename: "ModelIncidentShareContactInformationConnection";
      items: Array<{
        __typename: "IncidentShareContactInformation";
        id: string;
        incidentShareEntryId: string;
        name: string;
        phone: string;
        ext?: string | null;
        email?: string | null;
        type: IncidentShareContactType;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareByIncidentIdQuery = {
  __typename: "ModelIncidentShareEntryConnection";
  items: Array<{
    __typename: "IncidentShareEntry";
    id: string;
    incidentDataToShareId?: string | null;
    incidentId: string;
    code: string;
    shareType: IncidentShareType;
    expiration: string;
    alarmsCentralStation?: string | null;
    alarmsPermitNum?: string | null;
    alarmsCSID?: string | null;
    attachmentsList?: Array<string> | null;
    incidentOpenTime?: string | null;
    incidentClosedTime?: string | null;
    externalSiteNotes?: string | null;
    externalClientNotes?: string | null;
    createdAt: string;
    updatedAt: string;
    site?: {
      __typename: "IncidentShareSite";
      id: string;
      name: string;
      addressOne: string;
      addressTwo?: string | null;
      city: string;
      state?: string | null;
      country: string;
      zipCode: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    integrator?: {
      __typename: "IncidentShareIntegrator";
      id: string;
      name: string;
      logoPath?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    notes?: {
      __typename: "ModelIncidentShareEntyNoteConnection";
      items: Array<{
        __typename: "IncidentShareEntyNote";
        id: string;
        incidentId?: string | null;
        incidentNoteId?: string | null;
        incidentShareEntryId: string;
        note: string;
        noteCreatedAt: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    events?: {
      __typename: "ModelIncidentShareEventConnection";
      items: Array<{
        __typename: "IncidentShareEvent";
        id: string;
        cameraEventId?: string | null;
        startTime: string;
        vmsCameraName?: string | null;
        videoFeedUrl?: string | null;
        eventType: EventType;
        disposition: Disposition;
        triggerType?: CameraEventTriggerType | null;
        objectsFound?: Array<CameraEventObjectType> | null;
        incidentShareEntryId: string;
        cameraLatitude?: number | null;
        cameraLongitude?: number | null;
        createdAt: string;
        updatedAt: string;
        actionItems?: {
          __typename: "ModelIncidentShareActionItemsConnection";
          items: Array<{
            __typename: "IncidentShareActionItems";
            id: string;
            incidentProcedureTaskId?: string | null;
            incidentShareEventId: string;
            order: number;
            completedAt?: string | null;
            description: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contactInformation?: {
      __typename: "ModelIncidentShareContactInformationConnection";
      items: Array<{
        __typename: "IncidentShareContactInformation";
        id: string;
        incidentShareEntryId: string;
        name: string;
        phone: string;
        ext?: string | null;
        email?: string | null;
        type: IncidentShareContactType;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareSiteQuery = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentShareSitesQuery = {
  __typename: "ModelIncidentShareSiteConnection";
  items: Array<{
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareIntegratorQuery = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentShareIntegratorsQuery = {
  __typename: "ModelIncidentShareIntegratorConnection";
  items: Array<{
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareEntryAccessQuery = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentShareEntryAccesssQuery = {
  __typename: "ModelIncidentShareEntryAccessConnection";
  items: Array<{
    __typename: "IncidentShareEntryAccess";
    id: string;
    incidentShareEntryId?: string | null;
    incidentId?: string | null;
    code: string;
    success: boolean;
    accessDate: string;
    ipAddress: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareEntyNoteQuery = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentShareEntyNotesQuery = {
  __typename: "ModelIncidentShareEntyNoteConnection";
  items: Array<{
    __typename: "IncidentShareEntyNote";
    id: string;
    incidentId?: string | null;
    incidentNoteId?: string | null;
    incidentShareEntryId: string;
    note: string;
    noteCreatedAt: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareEntyNotesQuery = {
  __typename: "ModelIncidentShareEntyNoteConnection";
  items: Array<{
    __typename: "IncidentShareEntyNote";
    id: string;
    incidentId?: string | null;
    incidentNoteId?: string | null;
    incidentShareEntryId: string;
    note: string;
    noteCreatedAt: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareEntryNotesByNoteIdQuery = {
  __typename: "ModelIncidentShareEntyNoteConnection";
  items: Array<{
    __typename: "IncidentShareEntyNote";
    id: string;
    incidentId?: string | null;
    incidentNoteId?: string | null;
    incidentShareEntryId: string;
    note: string;
    noteCreatedAt: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareEventQuery = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListIncidentShareEventsQuery = {
  __typename: "ModelIncidentShareEventConnection";
  items: Array<{
    __typename: "IncidentShareEvent";
    id: string;
    cameraEventId?: string | null;
    startTime: string;
    vmsCameraName?: string | null;
    videoFeedUrl?: string | null;
    eventType: EventType;
    disposition: Disposition;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    incidentShareEntryId: string;
    cameraLatitude?: number | null;
    cameraLongitude?: number | null;
    createdAt: string;
    updatedAt: string;
    actionItems?: {
      __typename: "ModelIncidentShareActionItemsConnection";
      items: Array<{
        __typename: "IncidentShareActionItems";
        id: string;
        incidentProcedureTaskId?: string | null;
        incidentShareEventId: string;
        order: number;
        completedAt?: string | null;
        description: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareEventsQuery = {
  __typename: "ModelIncidentShareEventConnection";
  items: Array<{
    __typename: "IncidentShareEvent";
    id: string;
    cameraEventId?: string | null;
    startTime: string;
    vmsCameraName?: string | null;
    videoFeedUrl?: string | null;
    eventType: EventType;
    disposition: Disposition;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    incidentShareEntryId: string;
    cameraLatitude?: number | null;
    cameraLongitude?: number | null;
    createdAt: string;
    updatedAt: string;
    actionItems?: {
      __typename: "ModelIncidentShareActionItemsConnection";
      items: Array<{
        __typename: "IncidentShareActionItems";
        id: string;
        incidentProcedureTaskId?: string | null;
        incidentShareEventId: string;
        order: number;
        completedAt?: string | null;
        description: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareEventsByCameraEventIdQuery = {
  __typename: "ModelIncidentShareEventConnection";
  items: Array<{
    __typename: "IncidentShareEvent";
    id: string;
    cameraEventId?: string | null;
    startTime: string;
    vmsCameraName?: string | null;
    videoFeedUrl?: string | null;
    eventType: EventType;
    disposition: Disposition;
    triggerType?: CameraEventTriggerType | null;
    objectsFound?: Array<CameraEventObjectType> | null;
    incidentShareEntryId: string;
    cameraLatitude?: number | null;
    cameraLongitude?: number | null;
    createdAt: string;
    updatedAt: string;
    actionItems?: {
      __typename: "ModelIncidentShareActionItemsConnection";
      items: Array<{
        __typename: "IncidentShareActionItems";
        id: string;
        incidentProcedureTaskId?: string | null;
        incidentShareEventId: string;
        order: number;
        completedAt?: string | null;
        description: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareActionItemsQuery = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentShareActionItemssQuery = {
  __typename: "ModelIncidentShareActionItemsConnection";
  items: Array<{
    __typename: "IncidentShareActionItems";
    id: string;
    incidentProcedureTaskId?: string | null;
    incidentShareEventId: string;
    order: number;
    completedAt?: string | null;
    description: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareActionItemsQuery = {
  __typename: "ModelIncidentShareActionItemsConnection";
  items: Array<{
    __typename: "IncidentShareActionItems";
    id: string;
    incidentProcedureTaskId?: string | null;
    incidentShareEventId: string;
    order: number;
    completedAt?: string | null;
    description: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareActionItemsByProcIdQuery = {
  __typename: "ModelIncidentShareActionItemsConnection";
  items: Array<{
    __typename: "IncidentShareActionItems";
    id: string;
    incidentProcedureTaskId?: string | null;
    incidentShareEventId: string;
    order: number;
    completedAt?: string | null;
    description: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIncidentShareContactInformationQuery = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type ListIncidentShareContactInformationsQuery = {
  __typename: "ModelIncidentShareContactInformationConnection";
  items: Array<{
    __typename: "IncidentShareContactInformation";
    id: string;
    incidentShareEntryId: string;
    name: string;
    phone: string;
    ext?: string | null;
    email?: string | null;
    type: IncidentShareContactType;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IncidentShareContactInfoQuery = {
  __typename: "ModelIncidentShareContactInformationConnection";
  items: Array<{
    __typename: "IncidentShareContactInformation";
    id: string;
    incidentShareEntryId: string;
    name: string;
    phone: string;
    ext?: string | null;
    email?: string | null;
    type: IncidentShareContactType;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCallNoticeQuery = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type ListCallNoticesQuery = {
  __typename: "ModelCallNoticeConnection";
  items: Array<{
    __typename: "CallNotice";
    id: string;
    fromNumber: string;
    toNumber: string;
    monitoringCenterId: string;
    createdAt: string;
    answeredTime?: string | null;
    answeredBy?: string | null;
    twilioCallSid: string;
    modifiedBy: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CallNoticeByMcQuery = {
  __typename: "ModelCallNoticeConnection";
  items: Array<{
    __typename: "CallNotice";
    id: string;
    fromNumber: string;
    toNumber: string;
    monitoringCenterId: string;
    createdAt: string;
    answeredTime?: string | null;
    answeredBy?: string | null;
    twilioCallSid: string;
    modifiedBy: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCallLogQuery = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type ListCallLogsQuery = {
  __typename: "ModelCallLogConnection";
  items: Array<{
    __typename: "CallLog";
    id: string;
    phoneNumber: string;
    monitoringCenterId: string;
    customerId?: string | null;
    siteId?: string | null;
    incidentId?: string | null;
    callEnd?: string | null;
    storageAudioUrl?: string | null;
    storageTranscriptUrl?: string | null;
    createdAt: string;
    callingUser?: string | null;
    modifiedBy?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CallsByMonitoringCenterQuery = {
  __typename: "ModelCallLogConnection";
  items: Array<{
    __typename: "CallLog";
    id: string;
    phoneNumber: string;
    monitoringCenterId: string;
    customerId?: string | null;
    siteId?: string | null;
    incidentId?: string | null;
    callEnd?: string | null;
    storageAudioUrl?: string | null;
    storageTranscriptUrl?: string | null;
    createdAt: string;
    callingUser?: string | null;
    modifiedBy?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSMSLogQuery = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type ListSMSLogsQuery = {
  __typename: "ModelSMSLogConnection";
  items: Array<{
    __typename: "SMSLog";
    id: string;
    twilioSid?: string | null;
    toNumber: string;
    fromNumber: string;
    monitoringCenterId: string;
    customerId?: string | null;
    siteId?: string | null;
    incidentId?: string | null;
    message?: string | null;
    createdAt: string;
    textingUser?: string | null;
    modifiedBy: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SmsByMonitoringCenterQuery = {
  __typename: "ModelSMSLogConnection";
  items: Array<{
    __typename: "SMSLog";
    id: string;
    twilioSid?: string | null;
    toNumber: string;
    fromNumber: string;
    monitoringCenterId: string;
    customerId?: string | null;
    siteId?: string | null;
    incidentId?: string | null;
    message?: string | null;
    createdAt: string;
    textingUser?: string | null;
    modifiedBy: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEmailLogQuery = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type ListEmailLogsQuery = {
  __typename: "ModelEmailLogConnection";
  items: Array<{
    __typename: "EmailLog";
    id: string;
    awsMessageId?: string | null;
    toEmail: string;
    fromEmail: string;
    monitoringCenterId: string;
    customerId?: string | null;
    siteId?: string | null;
    incidentId?: string | null;
    subject?: string | null;
    createdAt: string;
    sendingUser?: string | null;
    modifiedBy: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EmailByMonitoringCenterQuery = {
  __typename: "ModelEmailLogConnection";
  items: Array<{
    __typename: "EmailLog";
    id: string;
    awsMessageId?: string | null;
    toEmail: string;
    fromEmail: string;
    monitoringCenterId: string;
    customerId?: string | null;
    siteId?: string | null;
    incidentId?: string | null;
    subject?: string | null;
    createdAt: string;
    sendingUser?: string | null;
    modifiedBy: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetNotificationSettingQuery = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListNotificationSettingsQuery = {
  __typename: "ModelNotificationSettingConnection";
  items: Array<{
    __typename: "NotificationSetting";
    id: string;
    username: string;
    monitoringCenterId: string;
    notificationType: NotificationType;
    userGroup: string;
    clientList?: Array<string | null> | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type NotifSettingsByMCTypeQuery = {
  __typename: "ModelNotificationSettingConnection";
  items: Array<{
    __typename: "NotificationSetting";
    id: string;
    username: string;
    monitoringCenterId: string;
    notificationType: NotificationType;
    userGroup: string;
    clientList?: Array<string | null> | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type NotifSettingsByUserQuery = {
  __typename: "ModelNotificationSettingConnection";
  items: Array<{
    __typename: "NotificationSetting";
    id: string;
    username: string;
    monitoringCenterId: string;
    notificationType: NotificationType;
    userGroup: string;
    clientList?: Array<string | null> | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetNotificationQuery = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type ListNotificationsQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    username: string;
    monitoringCenterId: string;
    notificationType: NotificationType;
    status?: NotificationStatus | null;
    createdAt: string;
    modifiedBy: string;
    url: string;
    note?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type NotifByUserQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    id: string;
    username: string;
    monitoringCenterId: string;
    notificationType: NotificationType;
    status?: NotificationStatus | null;
    createdAt: string;
    modifiedBy: string;
    url: string;
    note?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    username: string;
    monitoringCenterId: string;
    vmsOperatorUserIds?: Array<string> | null;
    vmsOperatorUserIdAutomatedRole?: Array<string> | null;
    vmsAdminUserIds?: Array<string> | null;
    modifiedBy: string;
    group?: UserGroup | null;
    clientIdList?: Array<string> | null;
    enabled?: boolean | null;
    phone: string;
    email: string;
    name: string;
    integratorId?: string | null;
    autoAssign?: boolean | null;
    preferredLanguage?: Language | null;
    gridEnabled?: boolean | null;
    gridBehavior?: GridBehavior | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUserVMSDetailsQuery = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUserVMSDetailssQuery = {
  __typename: "ModelUserVMSDetailsConnection";
  items: Array<{
    __typename: "UserVMSDetails";
    id: string;
    vmsUserId: string;
    profileName: string;
    cognitoUsernames?: Array<string> | null;
    authEmail: string;
    vmsUsername: string;
    vmsPassword: string;
    vmsUserType: VMSUserType;
    apiKey: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UserVMSDetailsByEmailQuery = {
  __typename: "ModelUserVMSDetailsConnection";
  items: Array<{
    __typename: "UserVMSDetails";
    id: string;
    vmsUserId: string;
    profileName: string;
    cognitoUsernames?: Array<string> | null;
    authEmail: string;
    vmsUsername: string;
    vmsPassword: string;
    vmsUserType: VMSUserType;
    apiKey: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UserVMSDetailsByVMSUserIdQuery = {
  __typename: "ModelUserVMSDetailsConnection";
  items: Array<{
    __typename: "UserVMSDetails";
    id: string;
    vmsUserId: string;
    profileName: string;
    cognitoUsernames?: Array<string> | null;
    authEmail: string;
    vmsUsername: string;
    vmsPassword: string;
    vmsUserType: VMSUserType;
    apiKey: string;
    accessToken?: string | null;
    refreshToken?: string | null;
    accessTokenExpires?: string | null;
    refreshTokenExpires?: string | null;
    monitoringCenterId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomerUserPermissionSetQuery = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListCustomerUserPermissionSetsQuery = {
  __typename: "ModelCustomerUserPermissionSetConnection";
  items: Array<{
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomerUserPermissionQuery = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListCustomerUserPermissionsQuery = {
  __typename: "ModelCustomerUserPermissionConnection";
  items: Array<{
    __typename: "CustomerUserPermission";
    username: string;
    email: string;
    monitoringCenterId: string;
    customerId: string;
    sitePermissions: string;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    permissionSet?: {
      __typename: "CustomerUserPermissionSet";
      username: string;
      email: string;
      enabled: boolean;
      modifiedBy: string;
      ownerCustomerId: string;
      createdAt: string;
      updatedAt: string;
      permissions?: {
        __typename: "ModelCustomerUserPermissionConnection";
        items: Array<{
          __typename: "CustomerUserPermission";
          username: string;
          email: string;
          monitoringCenterId: string;
          customerId: string;
          sitePermissions: string;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          permissionSet?: {
            __typename: "CustomerUserPermissionSet";
            username: string;
            email: string;
            enabled: boolean;
            modifiedBy: string;
            ownerCustomerId: string;
            createdAt: string;
            updatedAt: string;
            permissions?: {
              __typename: "ModelCustomerUserPermissionConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type UserPermissionByUsernameCustomerIdQuery = {
  __typename: "ModelCustomerUserPermissionConnection";
  items: Array<{
    __typename: "CustomerUserPermission";
    username: string;
    email: string;
    monitoringCenterId: string;
    customerId: string;
    sitePermissions: string;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    permissionSet?: {
      __typename: "CustomerUserPermissionSet";
      username: string;
      email: string;
      enabled: boolean;
      modifiedBy: string;
      ownerCustomerId: string;
      createdAt: string;
      updatedAt: string;
      permissions?: {
        __typename: "ModelCustomerUserPermissionConnection";
        items: Array<{
          __typename: "CustomerUserPermission";
          username: string;
          email: string;
          monitoringCenterId: string;
          customerId: string;
          sitePermissions: string;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          permissionSet?: {
            __typename: "CustomerUserPermissionSet";
            username: string;
            email: string;
            enabled: boolean;
            modifiedBy: string;
            ownerCustomerId: string;
            createdAt: string;
            updatedAt: string;
            permissions?: {
              __typename: "ModelCustomerUserPermissionConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type UserPermissionByCustomerIdQuery = {
  __typename: "ModelCustomerUserPermissionConnection";
  items: Array<{
    __typename: "CustomerUserPermission";
    username: string;
    email: string;
    monitoringCenterId: string;
    customerId: string;
    sitePermissions: string;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    permissionSet?: {
      __typename: "CustomerUserPermissionSet";
      username: string;
      email: string;
      enabled: boolean;
      modifiedBy: string;
      ownerCustomerId: string;
      createdAt: string;
      updatedAt: string;
      permissions?: {
        __typename: "ModelCustomerUserPermissionConnection";
        items: Array<{
          __typename: "CustomerUserPermission";
          username: string;
          email: string;
          monitoringCenterId: string;
          customerId: string;
          sitePermissions: string;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          permissionSet?: {
            __typename: "CustomerUserPermissionSet";
            username: string;
            email: string;
            enabled: boolean;
            modifiedBy: string;
            ownerCustomerId: string;
            createdAt: string;
            updatedAt: string;
            permissions?: {
              __typename: "ModelCustomerUserPermissionConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTaxQuery = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListTaxsQuery = {
  __typename: "ModelTaxConnection";
  items: Array<{
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TaxByMonitoringCenterQuery = {
  __typename: "ModelTaxConnection";
  items: Array<{
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TaxByMonitoringCenterRegionQuery = {
  __typename: "ModelTaxConnection";
  items: Array<{
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTaxTypeQuery = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListTaxTypesQuery = {
  __typename: "ModelTaxTypeConnection";
  items: Array<{
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TaxTypeByMonitoringCenterQuery = {
  __typename: "ModelTaxTypeConnection";
  items: Array<{
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TaxTypesByIntMCQuery = {
  __typename: "ModelTaxTypeConnection";
  items: Array<{
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSetupFeeQuery = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListSetupFeesQuery = {
  __typename: "ModelSetupFeeConnection";
  items: Array<{
    __typename: "SetupFee";
    id: string;
    name: string;
    taxTypeId: string;
    amount: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SetupFeesByMonitoringCenterQuery = {
  __typename: "ModelSetupFeeConnection";
  items: Array<{
    __typename: "SetupFee";
    id: string;
    name: string;
    taxTypeId: string;
    amount: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SetupFeesByIntMCQuery = {
  __typename: "ModelSetupFeeConnection";
  items: Array<{
    __typename: "SetupFee";
    id: string;
    name: string;
    taxTypeId: string;
    amount: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCameraPlanQuery = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type ListCameraPlansQuery = {
  __typename: "ModelCameraPlanConnection";
  items: Array<{
    __typename: "CameraPlan";
    id: string;
    parentId: string;
    analyticsType?: Array<AnalyticType> | null;
    resolution: string;
    bitRate: number;
    operatorDuration: number;
    supervision?: DeviceHealthCheckSLA | null;
    storageDays: number;
    cameraPlanProductId: string;
    cameraPlanLivePatrolId?: string | null;
    cameraPlanOperatorMinutesId?: string | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    product: {
      __typename: "Product";
      id: string;
      cameraPlanId: string;
      name: string;
      nameForInvoice?: string | null;
      description?: string | null;
      productTaxTypeId?: string | null;
      minPrice: number;
      mrp: number;
      cost: number;
      price: number;
      fxPrices?: Array<{
        __typename: "FXPrice";
        currency?: Currency | null;
        price: number;
      }> | null;
      status: ProductStatus;
      type: ProductType;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      publishedFor?: Array<string> | null;
      publishedForArchived?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    };
    livePatrol: {
      __typename: "LivePatrol";
      id: string;
      cameraPlanId: string;
      enabled?: boolean | null;
      minPrice: number;
      mrp: number;
      cost: number;
      price: number;
      fxPrices?: Array<{
        __typename: "FXPrice";
        currency?: Currency | null;
        price: number;
      }> | null;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    operatorMinutes: {
      __typename: "OperatorMinutes";
      id: string;
      cameraPlanId: string;
      minPrice: number;
      mrp: number;
      cost: number;
      price: number;
      fxPrices?: Array<{
        __typename: "FXPrice";
        currency?: Currency | null;
        price: number;
      }> | null;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type CameraPlanByIntMCQuery = {
  __typename: "ModelCameraPlanConnection";
  items: Array<{
    __typename: "CameraPlan";
    id: string;
    parentId: string;
    analyticsType?: Array<AnalyticType> | null;
    resolution: string;
    bitRate: number;
    operatorDuration: number;
    supervision?: DeviceHealthCheckSLA | null;
    storageDays: number;
    cameraPlanProductId: string;
    cameraPlanLivePatrolId?: string | null;
    cameraPlanOperatorMinutesId?: string | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    product: {
      __typename: "Product";
      id: string;
      cameraPlanId: string;
      name: string;
      nameForInvoice?: string | null;
      description?: string | null;
      productTaxTypeId?: string | null;
      minPrice: number;
      mrp: number;
      cost: number;
      price: number;
      fxPrices?: Array<{
        __typename: "FXPrice";
        currency?: Currency | null;
        price: number;
      }> | null;
      status: ProductStatus;
      type: ProductType;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      publishedFor?: Array<string> | null;
      publishedForArchived?: Array<string> | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    };
    livePatrol: {
      __typename: "LivePatrol";
      id: string;
      cameraPlanId: string;
      enabled?: boolean | null;
      minPrice: number;
      mrp: number;
      cost: number;
      price: number;
      fxPrices?: Array<{
        __typename: "FXPrice";
        currency?: Currency | null;
        price: number;
      }> | null;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    operatorMinutes: {
      __typename: "OperatorMinutes";
      id: string;
      cameraPlanId: string;
      minPrice: number;
      mrp: number;
      cost: number;
      price: number;
      fxPrices?: Array<{
        __typename: "FXPrice";
        currency?: Currency | null;
        price: number;
      }> | null;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type GetSiteSubscriptionQuery = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListSiteSubscriptionsQuery = {
  __typename: "ModelSiteSubscriptionConnection";
  items: Array<{
    __typename: "SiteSubscription";
    id: string;
    siteId: string;
    customerId: string;
    status: ActiveInactive;
    onHold?: boolean | null;
    startDate: string;
    term: number;
    renewalDate: string;
    renewalTerm: number;
    merchant?: SiteSubscriptionMerchant | null;
    billingDay: number;
    billingFrequency: BillingFrequency;
    nextBillingDay: string;
    autorefillOn: boolean;
    autorefillAmount?: number | null;
    minimumBalance?: number | null;
    currentBalance: number;
    currentBalanceUsd?: number | null;
    maxNumberRecharges?: number | null;
    currentDayRechargeCount?: number | null;
    lastRefilled?: string | null;
    currency: Currency;
    usedOperatorMinutes?: number | null;
    totalOperatorMinutes?: number | null;
    transactionsLastCalculated?: string | null;
    secondsPerInteraction?: number | null;
    incidentBillingDisabled?: boolean | null;
    interactionBillingDisabled?: boolean | null;
    livePatrolBillingDisabled?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    mcIntAdminAccess?: string | null;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    billingEmails?: Array<string> | null;
    billingEntityName?: string | null;
    commissionBilling?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    stripeSubscription?: {
      __typename: "SubscriptionStripeDetail";
      id: string;
      archived?: boolean | null;
      modifiedBy: string;
      siteSubscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      createdAt: string;
      updatedAt: string;
      paymentMethods?: {
        __typename: "ModelStripePaymentMethodConnection";
        items: Array<{
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    cameraPlans?: {
      __typename: "ModelAppliedCameraPlanConnection";
      items: Array<{
        __typename: "AppliedCameraPlan";
        id: string;
        parentId: string;
        cameraId: string;
        subscriptionId: string;
        analyticsType?: Array<AnalyticType> | null;
        resolution: string;
        bitRate: number;
        operatorDuration: number;
        supervision?: DeviceHealthCheckSLA | null;
        storageDays: number;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        product: {
          __typename: "AppliedProduct";
          id: string;
          appliedCameraPlanId: string;
          name: string;
          nameForInvoice?: string | null;
          description?: string | null;
          appliedProductTaxTypeId?: string | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          taxType?: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        };
        livePatrol: {
          __typename: "AppliedLivePatrol";
          id: string;
          appliedCameraPlanId: string;
          enabled?: boolean | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
        operatorMinutes: {
          __typename: "AppliedOperatorMinutes";
          id: string;
          appliedCameraPlanId: string;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    setupFees?: {
      __typename: "ModelAppliedSetupFeeConnection";
      items: Array<{
        __typename: "AppliedSetupFee";
        id: string;
        parentId: string;
        subscriptionId: string;
        amount?: number | null;
        name: string;
        currency: Currency;
        integratorId: string;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SubscriptionByMonitoringCenterQuery = {
  __typename: "ModelSiteSubscriptionConnection";
  items: Array<{
    __typename: "SiteSubscription";
    id: string;
    siteId: string;
    customerId: string;
    status: ActiveInactive;
    onHold?: boolean | null;
    startDate: string;
    term: number;
    renewalDate: string;
    renewalTerm: number;
    merchant?: SiteSubscriptionMerchant | null;
    billingDay: number;
    billingFrequency: BillingFrequency;
    nextBillingDay: string;
    autorefillOn: boolean;
    autorefillAmount?: number | null;
    minimumBalance?: number | null;
    currentBalance: number;
    currentBalanceUsd?: number | null;
    maxNumberRecharges?: number | null;
    currentDayRechargeCount?: number | null;
    lastRefilled?: string | null;
    currency: Currency;
    usedOperatorMinutes?: number | null;
    totalOperatorMinutes?: number | null;
    transactionsLastCalculated?: string | null;
    secondsPerInteraction?: number | null;
    incidentBillingDisabled?: boolean | null;
    interactionBillingDisabled?: boolean | null;
    livePatrolBillingDisabled?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    mcIntAdminAccess?: string | null;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    billingEmails?: Array<string> | null;
    billingEntityName?: string | null;
    commissionBilling?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    stripeSubscription?: {
      __typename: "SubscriptionStripeDetail";
      id: string;
      archived?: boolean | null;
      modifiedBy: string;
      siteSubscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      createdAt: string;
      updatedAt: string;
      paymentMethods?: {
        __typename: "ModelStripePaymentMethodConnection";
        items: Array<{
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    cameraPlans?: {
      __typename: "ModelAppliedCameraPlanConnection";
      items: Array<{
        __typename: "AppliedCameraPlan";
        id: string;
        parentId: string;
        cameraId: string;
        subscriptionId: string;
        analyticsType?: Array<AnalyticType> | null;
        resolution: string;
        bitRate: number;
        operatorDuration: number;
        supervision?: DeviceHealthCheckSLA | null;
        storageDays: number;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        product: {
          __typename: "AppliedProduct";
          id: string;
          appliedCameraPlanId: string;
          name: string;
          nameForInvoice?: string | null;
          description?: string | null;
          appliedProductTaxTypeId?: string | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          taxType?: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        };
        livePatrol: {
          __typename: "AppliedLivePatrol";
          id: string;
          appliedCameraPlanId: string;
          enabled?: boolean | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
        operatorMinutes: {
          __typename: "AppliedOperatorMinutes";
          id: string;
          appliedCameraPlanId: string;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    setupFees?: {
      __typename: "ModelAppliedSetupFeeConnection";
      items: Array<{
        __typename: "AppliedSetupFee";
        id: string;
        parentId: string;
        subscriptionId: string;
        amount?: number | null;
        name: string;
        currency: Currency;
        integratorId: string;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SubscriptionBySiteQuery = {
  __typename: "ModelSiteSubscriptionConnection";
  items: Array<{
    __typename: "SiteSubscription";
    id: string;
    siteId: string;
    customerId: string;
    status: ActiveInactive;
    onHold?: boolean | null;
    startDate: string;
    term: number;
    renewalDate: string;
    renewalTerm: number;
    merchant?: SiteSubscriptionMerchant | null;
    billingDay: number;
    billingFrequency: BillingFrequency;
    nextBillingDay: string;
    autorefillOn: boolean;
    autorefillAmount?: number | null;
    minimumBalance?: number | null;
    currentBalance: number;
    currentBalanceUsd?: number | null;
    maxNumberRecharges?: number | null;
    currentDayRechargeCount?: number | null;
    lastRefilled?: string | null;
    currency: Currency;
    usedOperatorMinutes?: number | null;
    totalOperatorMinutes?: number | null;
    transactionsLastCalculated?: string | null;
    secondsPerInteraction?: number | null;
    incidentBillingDisabled?: boolean | null;
    interactionBillingDisabled?: boolean | null;
    livePatrolBillingDisabled?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    mcIntAdminAccess?: string | null;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    billingEmails?: Array<string> | null;
    billingEntityName?: string | null;
    commissionBilling?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    stripeSubscription?: {
      __typename: "SubscriptionStripeDetail";
      id: string;
      archived?: boolean | null;
      modifiedBy: string;
      siteSubscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      createdAt: string;
      updatedAt: string;
      paymentMethods?: {
        __typename: "ModelStripePaymentMethodConnection";
        items: Array<{
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    cameraPlans?: {
      __typename: "ModelAppliedCameraPlanConnection";
      items: Array<{
        __typename: "AppliedCameraPlan";
        id: string;
        parentId: string;
        cameraId: string;
        subscriptionId: string;
        analyticsType?: Array<AnalyticType> | null;
        resolution: string;
        bitRate: number;
        operatorDuration: number;
        supervision?: DeviceHealthCheckSLA | null;
        storageDays: number;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        product: {
          __typename: "AppliedProduct";
          id: string;
          appliedCameraPlanId: string;
          name: string;
          nameForInvoice?: string | null;
          description?: string | null;
          appliedProductTaxTypeId?: string | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          taxType?: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        };
        livePatrol: {
          __typename: "AppliedLivePatrol";
          id: string;
          appliedCameraPlanId: string;
          enabled?: boolean | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
        operatorMinutes: {
          __typename: "AppliedOperatorMinutes";
          id: string;
          appliedCameraPlanId: string;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    setupFees?: {
      __typename: "ModelAppliedSetupFeeConnection";
      items: Array<{
        __typename: "AppliedSetupFee";
        id: string;
        parentId: string;
        subscriptionId: string;
        amount?: number | null;
        name: string;
        currency: Currency;
        integratorId: string;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SubscriptionsByStatusQuery = {
  __typename: "ModelSiteSubscriptionConnection";
  items: Array<{
    __typename: "SiteSubscription";
    id: string;
    siteId: string;
    customerId: string;
    status: ActiveInactive;
    onHold?: boolean | null;
    startDate: string;
    term: number;
    renewalDate: string;
    renewalTerm: number;
    merchant?: SiteSubscriptionMerchant | null;
    billingDay: number;
    billingFrequency: BillingFrequency;
    nextBillingDay: string;
    autorefillOn: boolean;
    autorefillAmount?: number | null;
    minimumBalance?: number | null;
    currentBalance: number;
    currentBalanceUsd?: number | null;
    maxNumberRecharges?: number | null;
    currentDayRechargeCount?: number | null;
    lastRefilled?: string | null;
    currency: Currency;
    usedOperatorMinutes?: number | null;
    totalOperatorMinutes?: number | null;
    transactionsLastCalculated?: string | null;
    secondsPerInteraction?: number | null;
    incidentBillingDisabled?: boolean | null;
    interactionBillingDisabled?: boolean | null;
    livePatrolBillingDisabled?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    mcIntAdminAccess?: string | null;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    billingEmails?: Array<string> | null;
    billingEntityName?: string | null;
    commissionBilling?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    stripeSubscription?: {
      __typename: "SubscriptionStripeDetail";
      id: string;
      archived?: boolean | null;
      modifiedBy: string;
      siteSubscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      createdAt: string;
      updatedAt: string;
      paymentMethods?: {
        __typename: "ModelStripePaymentMethodConnection";
        items: Array<{
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    cameraPlans?: {
      __typename: "ModelAppliedCameraPlanConnection";
      items: Array<{
        __typename: "AppliedCameraPlan";
        id: string;
        parentId: string;
        cameraId: string;
        subscriptionId: string;
        analyticsType?: Array<AnalyticType> | null;
        resolution: string;
        bitRate: number;
        operatorDuration: number;
        supervision?: DeviceHealthCheckSLA | null;
        storageDays: number;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        product: {
          __typename: "AppliedProduct";
          id: string;
          appliedCameraPlanId: string;
          name: string;
          nameForInvoice?: string | null;
          description?: string | null;
          appliedProductTaxTypeId?: string | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          taxType?: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        };
        livePatrol: {
          __typename: "AppliedLivePatrol";
          id: string;
          appliedCameraPlanId: string;
          enabled?: boolean | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
        operatorMinutes: {
          __typename: "AppliedOperatorMinutes";
          id: string;
          appliedCameraPlanId: string;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    setupFees?: {
      __typename: "ModelAppliedSetupFeeConnection";
      items: Array<{
        __typename: "AppliedSetupFee";
        id: string;
        parentId: string;
        subscriptionId: string;
        amount?: number | null;
        name: string;
        currency: Currency;
        integratorId: string;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type SiteSubscriptionsByStatusAndCreatedAtQuery = {
  __typename: "ModelSiteSubscriptionConnection";
  items: Array<{
    __typename: "SiteSubscription";
    id: string;
    siteId: string;
    customerId: string;
    status: ActiveInactive;
    onHold?: boolean | null;
    startDate: string;
    term: number;
    renewalDate: string;
    renewalTerm: number;
    merchant?: SiteSubscriptionMerchant | null;
    billingDay: number;
    billingFrequency: BillingFrequency;
    nextBillingDay: string;
    autorefillOn: boolean;
    autorefillAmount?: number | null;
    minimumBalance?: number | null;
    currentBalance: number;
    currentBalanceUsd?: number | null;
    maxNumberRecharges?: number | null;
    currentDayRechargeCount?: number | null;
    lastRefilled?: string | null;
    currency: Currency;
    usedOperatorMinutes?: number | null;
    totalOperatorMinutes?: number | null;
    transactionsLastCalculated?: string | null;
    secondsPerInteraction?: number | null;
    incidentBillingDisabled?: boolean | null;
    interactionBillingDisabled?: boolean | null;
    livePatrolBillingDisabled?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    mcIntAdminAccess?: string | null;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    billingEmails?: Array<string> | null;
    billingEntityName?: string | null;
    commissionBilling?: boolean | null;
    createdAt?: string | null;
    updatedAt: string;
    stripeSubscription?: {
      __typename: "SubscriptionStripeDetail";
      id: string;
      archived?: boolean | null;
      modifiedBy: string;
      siteSubscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      createdAt: string;
      updatedAt: string;
      paymentMethods?: {
        __typename: "ModelStripePaymentMethodConnection";
        items: Array<{
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    cameraPlans?: {
      __typename: "ModelAppliedCameraPlanConnection";
      items: Array<{
        __typename: "AppliedCameraPlan";
        id: string;
        parentId: string;
        cameraId: string;
        subscriptionId: string;
        analyticsType?: Array<AnalyticType> | null;
        resolution: string;
        bitRate: number;
        operatorDuration: number;
        supervision?: DeviceHealthCheckSLA | null;
        storageDays: number;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        product: {
          __typename: "AppliedProduct";
          id: string;
          appliedCameraPlanId: string;
          name: string;
          nameForInvoice?: string | null;
          description?: string | null;
          appliedProductTaxTypeId?: string | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          taxType?: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        };
        livePatrol: {
          __typename: "AppliedLivePatrol";
          id: string;
          appliedCameraPlanId: string;
          enabled?: boolean | null;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
        operatorMinutes: {
          __typename: "AppliedOperatorMinutes";
          id: string;
          appliedCameraPlanId: string;
          price: number;
          currency: Currency;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
    setupFees?: {
      __typename: "ModelAppliedSetupFeeConnection";
      items: Array<{
        __typename: "AppliedSetupFee";
        id: string;
        parentId: string;
        subscriptionId: string;
        amount?: number | null;
        name: string;
        currency: Currency;
        integratorId: string;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetSubscriptionStripeDetailQuery = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListSubscriptionStripeDetailsQuery = {
  __typename: "ModelSubscriptionStripeDetailConnection";
  items: Array<{
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type StripeDetailBySiteQuery = {
  __typename: "ModelSubscriptionStripeDetailConnection";
  items: Array<{
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type StripeDetailBySubscriptionQuery = {
  __typename: "ModelSubscriptionStripeDetailConnection";
  items: Array<{
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetStripePaymentMethodQuery = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListStripePaymentMethodsQuery = {
  __typename: "ModelStripePaymentMethodConnection";
  items: Array<{
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PaymentDetailByStripeIdQuery = {
  __typename: "ModelStripePaymentMethodConnection";
  items: Array<{
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type PaymentMethodByStripePaymentIdQuery = {
  __typename: "ModelStripePaymentMethodConnection";
  items: Array<{
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAppliedCameraPlanQuery = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type ListAppliedCameraPlansQuery = {
  __typename: "ModelAppliedCameraPlanConnection";
  items: Array<{
    __typename: "AppliedCameraPlan";
    id: string;
    parentId: string;
    cameraId: string;
    subscriptionId: string;
    analyticsType?: Array<AnalyticType> | null;
    resolution: string;
    bitRate: number;
    operatorDuration: number;
    supervision?: DeviceHealthCheckSLA | null;
    storageDays: number;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    product: {
      __typename: "AppliedProduct";
      id: string;
      appliedCameraPlanId: string;
      name: string;
      nameForInvoice?: string | null;
      description?: string | null;
      appliedProductTaxTypeId?: string | null;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    };
    livePatrol: {
      __typename: "AppliedLivePatrol";
      id: string;
      appliedCameraPlanId: string;
      enabled?: boolean | null;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    operatorMinutes: {
      __typename: "AppliedOperatorMinutes";
      id: string;
      appliedCameraPlanId: string;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type AppliedCameraPlanBySubQuery = {
  __typename: "ModelAppliedCameraPlanConnection";
  items: Array<{
    __typename: "AppliedCameraPlan";
    id: string;
    parentId: string;
    cameraId: string;
    subscriptionId: string;
    analyticsType?: Array<AnalyticType> | null;
    resolution: string;
    bitRate: number;
    operatorDuration: number;
    supervision?: DeviceHealthCheckSLA | null;
    storageDays: number;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    product: {
      __typename: "AppliedProduct";
      id: string;
      appliedCameraPlanId: string;
      name: string;
      nameForInvoice?: string | null;
      description?: string | null;
      appliedProductTaxTypeId?: string | null;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    };
    livePatrol: {
      __typename: "AppliedLivePatrol";
      id: string;
      appliedCameraPlanId: string;
      enabled?: boolean | null;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    operatorMinutes: {
      __typename: "AppliedOperatorMinutes";
      id: string;
      appliedCameraPlanId: string;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type AppliedCameraPlanByCameraQuery = {
  __typename: "ModelAppliedCameraPlanConnection";
  items: Array<{
    __typename: "AppliedCameraPlan";
    id: string;
    parentId: string;
    cameraId: string;
    subscriptionId: string;
    analyticsType?: Array<AnalyticType> | null;
    resolution: string;
    bitRate: number;
    operatorDuration: number;
    supervision?: DeviceHealthCheckSLA | null;
    storageDays: number;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    product: {
      __typename: "AppliedProduct";
      id: string;
      appliedCameraPlanId: string;
      name: string;
      nameForInvoice?: string | null;
      description?: string | null;
      appliedProductTaxTypeId?: string | null;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    };
    livePatrol: {
      __typename: "AppliedLivePatrol";
      id: string;
      appliedCameraPlanId: string;
      enabled?: boolean | null;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
    operatorMinutes: {
      __typename: "AppliedOperatorMinutes";
      id: string;
      appliedCameraPlanId: string;
      price: number;
      currency: Currency;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type GetTransactionLineItemQuery = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type ListTransactionLineItemsQuery = {
  __typename: "ModelTransactionLineItemConnection";
  items: Array<{
    __typename: "TransactionLineItem";
    id: string;
    subscriptionId: string;
    invoiceId?: string | null;
    connectedId: string;
    type: TransactionType;
    description: string;
    currency: Currency;
    pricePer: number;
    totalPrice: number;
    quantity: number;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTransactionLineItemTaxConnection";
      items: Array<{
        __typename: "TransactionLineItemTax";
        id: string;
        transactionLineItemId: string;
        invoiceId?: string | null;
        taxId: string;
        taxGovId: string;
        taxPercent: number;
        taxName: string;
        currency: Currency;
        amount: number;
        subscriptionId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TransactionLinesByInvoiceQuery = {
  __typename: "ModelTransactionLineItemConnection";
  items: Array<{
    __typename: "TransactionLineItem";
    id: string;
    subscriptionId: string;
    invoiceId?: string | null;
    connectedId: string;
    type: TransactionType;
    description: string;
    currency: Currency;
    pricePer: number;
    totalPrice: number;
    quantity: number;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTransactionLineItemTaxConnection";
      items: Array<{
        __typename: "TransactionLineItemTax";
        id: string;
        transactionLineItemId: string;
        invoiceId?: string | null;
        taxId: string;
        taxGovId: string;
        taxPercent: number;
        taxName: string;
        currency: Currency;
        amount: number;
        subscriptionId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TransactionLinesBySiteQuery = {
  __typename: "ModelTransactionLineItemConnection";
  items: Array<{
    __typename: "TransactionLineItem";
    id: string;
    subscriptionId: string;
    invoiceId?: string | null;
    connectedId: string;
    type: TransactionType;
    description: string;
    currency: Currency;
    pricePer: number;
    totalPrice: number;
    quantity: number;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTransactionLineItemTaxConnection";
      items: Array<{
        __typename: "TransactionLineItemTax";
        id: string;
        transactionLineItemId: string;
        invoiceId?: string | null;
        taxId: string;
        taxGovId: string;
        taxPercent: number;
        taxName: string;
        currency: Currency;
        amount: number;
        subscriptionId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTransactionLineItemTaxQuery = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTransactionLineItemTaxsQuery = {
  __typename: "ModelTransactionLineItemTaxConnection";
  items: Array<{
    __typename: "TransactionLineItemTax";
    id: string;
    transactionLineItemId: string;
    invoiceId?: string | null;
    taxId: string;
    taxGovId: string;
    taxPercent: number;
    taxName: string;
    currency: Currency;
    amount: number;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TransactionLineItemTaxByLineItemQuery = {
  __typename: "ModelTransactionLineItemTaxConnection";
  items: Array<{
    __typename: "TransactionLineItemTax";
    id: string;
    transactionLineItemId: string;
    invoiceId?: string | null;
    taxId: string;
    taxGovId: string;
    taxPercent: number;
    taxName: string;
    currency: Currency;
    amount: number;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetInvoiceQuery = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type ListInvoicesQuery = {
  __typename: "ModelInvoiceConnection";
  items: Array<{
    __typename: "Invoice";
    id?: string | null;
    subtotal: number;
    invoiceNumber: string;
    total: number;
    transactionCurrTotal?: number | null;
    notes?: string | null;
    type: InvoiceType;
    currency: Currency;
    transactionCurr?: Currency | null;
    status: InvoiceStatus;
    merchant: InvoiceMerchant;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    transferGroup?: string | null;
    mcOwnerAccess?: string | null;
    mcControllerAccess?: string | null;
    updatedAt: string;
    lineItems?: {
      __typename: "ModelTransactionLineItemConnection";
      items: Array<{
        __typename: "TransactionLineItem";
        id: string;
        subscriptionId: string;
        invoiceId?: string | null;
        connectedId: string;
        type: TransactionType;
        description: string;
        currency: Currency;
        pricePer: number;
        totalPrice: number;
        quantity: number;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTransactionLineItemTaxConnection";
          items: Array<{
            __typename: "TransactionLineItemTax";
            id: string;
            transactionLineItemId: string;
            invoiceId?: string | null;
            taxId: string;
            taxGovId: string;
            taxPercent: number;
            taxName: string;
            currency: Currency;
            amount: number;
            subscriptionId: string;
            siteId: string;
            customerId: string;
            integratorId: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    payments?: {
      __typename: "ModelInvoicePaymentConnection";
      items: Array<{
        __typename: "InvoicePayment";
        id: string;
        stripePaymentIntentId?: string | null;
        paymentStatus: StripePaymentStatus;
        paymentType?: PaymentType | null;
        notes?: string | null;
        invoiceId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        amountReceived: number;
        amountRequested: number;
        modifiedBy: string;
        createdAt?: string | null;
        updatedAt: string;
        paymentMethod?: {
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    transferPayment?: {
      __typename: "InvoiceTransferPayment";
      id: string;
      destination?: string | null;
      transferGroup: string;
      stripePaymentIntentId?: string | null;
      currency: string;
      stripeTransferRefId?: string | null;
      amount: number;
      customerId: string;
      monitoringCenterId?: string | null;
      integratorId?: string | null;
      createdAt?: string | null;
      modifiedBy: string;
      status: InvoiceTransferStatus;
      invoiceIds?: Array<string> | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type InvoicesBySiteQuery = {
  __typename: "ModelInvoiceConnection";
  items: Array<{
    __typename: "Invoice";
    id?: string | null;
    subtotal: number;
    invoiceNumber: string;
    total: number;
    transactionCurrTotal?: number | null;
    notes?: string | null;
    type: InvoiceType;
    currency: Currency;
    transactionCurr?: Currency | null;
    status: InvoiceStatus;
    merchant: InvoiceMerchant;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    transferGroup?: string | null;
    mcOwnerAccess?: string | null;
    mcControllerAccess?: string | null;
    updatedAt: string;
    lineItems?: {
      __typename: "ModelTransactionLineItemConnection";
      items: Array<{
        __typename: "TransactionLineItem";
        id: string;
        subscriptionId: string;
        invoiceId?: string | null;
        connectedId: string;
        type: TransactionType;
        description: string;
        currency: Currency;
        pricePer: number;
        totalPrice: number;
        quantity: number;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTransactionLineItemTaxConnection";
          items: Array<{
            __typename: "TransactionLineItemTax";
            id: string;
            transactionLineItemId: string;
            invoiceId?: string | null;
            taxId: string;
            taxGovId: string;
            taxPercent: number;
            taxName: string;
            currency: Currency;
            amount: number;
            subscriptionId: string;
            siteId: string;
            customerId: string;
            integratorId: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    payments?: {
      __typename: "ModelInvoicePaymentConnection";
      items: Array<{
        __typename: "InvoicePayment";
        id: string;
        stripePaymentIntentId?: string | null;
        paymentStatus: StripePaymentStatus;
        paymentType?: PaymentType | null;
        notes?: string | null;
        invoiceId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        amountReceived: number;
        amountRequested: number;
        modifiedBy: string;
        createdAt?: string | null;
        updatedAt: string;
        paymentMethod?: {
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    transferPayment?: {
      __typename: "InvoiceTransferPayment";
      id: string;
      destination?: string | null;
      transferGroup: string;
      stripePaymentIntentId?: string | null;
      currency: string;
      stripeTransferRefId?: string | null;
      amount: number;
      customerId: string;
      monitoringCenterId?: string | null;
      integratorId?: string | null;
      createdAt?: string | null;
      modifiedBy: string;
      status: InvoiceTransferStatus;
      invoiceIds?: Array<string> | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type InvoicesByStatusQuery = {
  __typename: "ModelInvoiceConnection";
  items: Array<{
    __typename: "Invoice";
    id?: string | null;
    subtotal: number;
    invoiceNumber: string;
    total: number;
    transactionCurrTotal?: number | null;
    notes?: string | null;
    type: InvoiceType;
    currency: Currency;
    transactionCurr?: Currency | null;
    status: InvoiceStatus;
    merchant: InvoiceMerchant;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    transferGroup?: string | null;
    mcOwnerAccess?: string | null;
    mcControllerAccess?: string | null;
    updatedAt: string;
    lineItems?: {
      __typename: "ModelTransactionLineItemConnection";
      items: Array<{
        __typename: "TransactionLineItem";
        id: string;
        subscriptionId: string;
        invoiceId?: string | null;
        connectedId: string;
        type: TransactionType;
        description: string;
        currency: Currency;
        pricePer: number;
        totalPrice: number;
        quantity: number;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTransactionLineItemTaxConnection";
          items: Array<{
            __typename: "TransactionLineItemTax";
            id: string;
            transactionLineItemId: string;
            invoiceId?: string | null;
            taxId: string;
            taxGovId: string;
            taxPercent: number;
            taxName: string;
            currency: Currency;
            amount: number;
            subscriptionId: string;
            siteId: string;
            customerId: string;
            integratorId: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    payments?: {
      __typename: "ModelInvoicePaymentConnection";
      items: Array<{
        __typename: "InvoicePayment";
        id: string;
        stripePaymentIntentId?: string | null;
        paymentStatus: StripePaymentStatus;
        paymentType?: PaymentType | null;
        notes?: string | null;
        invoiceId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        amountReceived: number;
        amountRequested: number;
        modifiedBy: string;
        createdAt?: string | null;
        updatedAt: string;
        paymentMethod?: {
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    transferPayment?: {
      __typename: "InvoiceTransferPayment";
      id: string;
      destination?: string | null;
      transferGroup: string;
      stripePaymentIntentId?: string | null;
      currency: string;
      stripeTransferRefId?: string | null;
      amount: number;
      customerId: string;
      monitoringCenterId?: string | null;
      integratorId?: string | null;
      createdAt?: string | null;
      modifiedBy: string;
      status: InvoiceTransferStatus;
      invoiceIds?: Array<string> | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type InvoicesByStatusOrderedQuery = {
  __typename: "ModelInvoiceConnection";
  items: Array<{
    __typename: "Invoice";
    id?: string | null;
    subtotal: number;
    invoiceNumber: string;
    total: number;
    transactionCurrTotal?: number | null;
    notes?: string | null;
    type: InvoiceType;
    currency: Currency;
    transactionCurr?: Currency | null;
    status: InvoiceStatus;
    merchant: InvoiceMerchant;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    modifiedBy: string;
    createdAt: string;
    transferGroup?: string | null;
    mcOwnerAccess?: string | null;
    mcControllerAccess?: string | null;
    updatedAt: string;
    lineItems?: {
      __typename: "ModelTransactionLineItemConnection";
      items: Array<{
        __typename: "TransactionLineItem";
        id: string;
        subscriptionId: string;
        invoiceId?: string | null;
        connectedId: string;
        type: TransactionType;
        description: string;
        currency: Currency;
        pricePer: number;
        totalPrice: number;
        quantity: number;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTransactionLineItemTaxConnection";
          items: Array<{
            __typename: "TransactionLineItemTax";
            id: string;
            transactionLineItemId: string;
            invoiceId?: string | null;
            taxId: string;
            taxGovId: string;
            taxPercent: number;
            taxName: string;
            currency: Currency;
            amount: number;
            subscriptionId: string;
            siteId: string;
            customerId: string;
            integratorId: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    payments?: {
      __typename: "ModelInvoicePaymentConnection";
      items: Array<{
        __typename: "InvoicePayment";
        id: string;
        stripePaymentIntentId?: string | null;
        paymentStatus: StripePaymentStatus;
        paymentType?: PaymentType | null;
        notes?: string | null;
        invoiceId: string;
        siteId: string;
        customerId: string;
        integratorId: string;
        monitoringCenterId: string;
        amountReceived: number;
        amountRequested: number;
        modifiedBy: string;
        createdAt?: string | null;
        updatedAt: string;
        paymentMethod?: {
          __typename: "StripePaymentMethod";
          id: string;
          subscriptionStripeDetailId: string;
          stripePaymentId: string;
          stripePaymentStatus?: StripePaymentStatus | null;
          type: StripePaymentType;
          name: string;
          identifier: string;
          preferredPayment?: boolean | null;
          monitoringCenterId: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          mcBillingAdminAccess: string;
          integratorId: string;
          modifiedBy: string;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    transferPayment?: {
      __typename: "InvoiceTransferPayment";
      id: string;
      destination?: string | null;
      transferGroup: string;
      stripePaymentIntentId?: string | null;
      currency: string;
      stripeTransferRefId?: string | null;
      amount: number;
      customerId: string;
      monitoringCenterId?: string | null;
      integratorId?: string | null;
      createdAt?: string | null;
      modifiedBy: string;
      status: InvoiceTransferStatus;
      invoiceIds?: Array<string> | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetInvoicePaymentQuery = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListInvoicePaymentsQuery = {
  __typename: "ModelInvoicePaymentConnection";
  items: Array<{
    __typename: "InvoicePayment";
    id: string;
    stripePaymentIntentId?: string | null;
    paymentStatus: StripePaymentStatus;
    paymentType?: PaymentType | null;
    notes?: string | null;
    invoiceId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    amountReceived: number;
    amountRequested: number;
    modifiedBy: string;
    createdAt?: string | null;
    updatedAt: string;
    paymentMethod?: {
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type PaymentsByInvoiceQuery = {
  __typename: "ModelInvoicePaymentConnection";
  items: Array<{
    __typename: "InvoicePayment";
    id: string;
    stripePaymentIntentId?: string | null;
    paymentStatus: StripePaymentStatus;
    paymentType?: PaymentType | null;
    notes?: string | null;
    invoiceId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    amountReceived: number;
    amountRequested: number;
    modifiedBy: string;
    createdAt?: string | null;
    updatedAt: string;
    paymentMethod?: {
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type InvoicePaymentByStatusAndCreatedAtQuery = {
  __typename: "ModelInvoicePaymentConnection";
  items: Array<{
    __typename: "InvoicePayment";
    id: string;
    stripePaymentIntentId?: string | null;
    paymentStatus: StripePaymentStatus;
    paymentType?: PaymentType | null;
    notes?: string | null;
    invoiceId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    amountReceived: number;
    amountRequested: number;
    modifiedBy: string;
    createdAt?: string | null;
    updatedAt: string;
    paymentMethod?: {
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetInvoiceTransferPaymentQuery = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type ListInvoiceTransferPaymentsQuery = {
  __typename: "ModelInvoiceTransferPaymentConnection";
  items: Array<{
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TransfersByGroupQuery = {
  __typename: "ModelInvoiceTransferPaymentConnection";
  items: Array<{
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type InvoiceTransferPaymentByStatusAndCreatedAtQuery = {
  __typename: "ModelInvoiceTransferPaymentConnection";
  items: Array<{
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetWalletTransferQuery = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type ListWalletTransfersQuery = {
  __typename: "ModelWalletTransferConnection";
  items: Array<{
    __typename: "WalletTransfer";
    id: string;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    amount: number;
    currency: Currency;
    conversionRate?: number | null;
    stripePaymentId: string;
    modifiedBy: string;
    type: WalletTransferType;
    createdAt: string;
    updatedAt: string;
    paymentMethod: {
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type WalletTransfersBySubscriptionQuery = {
  __typename: "ModelWalletTransferConnection";
  items: Array<{
    __typename: "WalletTransfer";
    id: string;
    subscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    amount: number;
    currency: Currency;
    conversionRate?: number | null;
    stripePaymentId: string;
    modifiedBy: string;
    type: WalletTransferType;
    createdAt: string;
    updatedAt: string;
    paymentMethod: {
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    };
  } | null>;
  nextToken?: string | null;
};

export type GetStripePayoutDetailsQuery = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListStripePayoutDetailssQuery = {
  __typename: "ModelStripePayoutDetailsConnection";
  items: Array<{
    __typename: "StripePayoutDetails";
    stripeAccId: string;
    integratorId: string;
    monitoringCenterId: string;
    chargesEnabled: boolean;
    modifiedBy: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StripePayoutDetailsByIntQuery = {
  __typename: "ModelStripePayoutDetailsConnection";
  items: Array<{
    __typename: "StripePayoutDetails";
    stripeAccId: string;
    integratorId: string;
    monitoringCenterId: string;
    chargesEnabled: boolean;
    modifiedBy: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetExchangeRateSettingQuery = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type ListExchangeRateSettingsQuery = {
  __typename: "ModelExchangeRateSettingConnection";
  items: Array<{
    __typename: "ExchangeRateSetting";
    country: string;
    modifiedBy: string;
    rate: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPaymentTransferSettingsQuery = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type ListPaymentTransferSettingssQuery = {
  __typename: "ModelPaymentTransferSettingsConnection";
  items: Array<{
    __typename: "PaymentTransferSettings";
    id: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcIntAdminAccess: string;
    type: StripePayoutSettingsType;
    variable: number;
    variableMaxDollars?: number | null;
    flat: number;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    variableTaxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    flatTaxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type PaymentTransferSettingsByMCQuery = {
  __typename: "ModelPaymentTransferSettingsConnection";
  items: Array<{
    __typename: "PaymentTransferSettings";
    id: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcIntAdminAccess: string;
    type: StripePayoutSettingsType;
    variable: number;
    variableMaxDollars?: number | null;
    flat: number;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    variableTaxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
    flatTaxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetWebhookHealthQuery = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListWebhookHealthsQuery = {
  __typename: "ModelWebhookHealthConnection";
  items: Array<{
    __typename: "WebhookHealth";
    id: string;
    monitoringCenterId: string;
    vmsWebhookId: string;
    date: string;
    ackAvgInterval?: number | null;
    acklastReceived?: string | null;
    ackCount?: number | null;
    analyticAvgInterval?: number | null;
    analyticlastReceived?: string | null;
    analyticCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type WebhookHealthByDateQuery = {
  __typename: "ModelWebhookHealthConnection";
  items: Array<{
    __typename: "WebhookHealth";
    id: string;
    monitoringCenterId: string;
    vmsWebhookId: string;
    date: string;
    ackAvgInterval?: number | null;
    acklastReceived?: string | null;
    ackCount?: number | null;
    analyticAvgInterval?: number | null;
    analyticlastReceived?: string | null;
    analyticCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateIncidentSubscription = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type OnUpdateIncidentSubscription = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type OnDeleteIncidentSubscription = {
  __typename: "Incident";
  id: string;
  integratorId: string;
  monitoringCenterId: string;
  status: IncidentStatus;
  incidentSiteId: string;
  incidentCustomerId: string;
  dispositionLevel: string;
  primaryDisposition: string;
  dispositionList: Array<string>;
  closedTime?: string | null;
  resolvedTime?: string | null;
  expectedResolvedTime: string;
  billableTime?: number | null;
  assignedUsername?: string | null;
  assignmentHistory?: Array<{
    __typename: "AssignmentHistoryItem";
    username: string;
    assignedAt: string;
  }> | null;
  modifiedBy?: string | null;
  createdAt: string;
  hasShareExternal?: boolean | null;
  resolution?: string | null;
  resolutionTranslated?: string | null;
  resolutionSanitized?: string | null;
  externalPartyResponse?: {
    __typename: "ExternalPartyResponse";
    police: boolean;
    fire: boolean;
    ambulance: boolean;
    bylaw: boolean;
    security: boolean;
    outreach: boolean;
    siteContact: boolean;
    agenciesArrived: boolean;
    notArrivedReason?: string | null;
    notArrivedReasonTranslated?: string | null;
    notArrivedReasonSanitized?: string | null;
  } | null;
  invoiceId?: string | null;
  updatedAt: string;
  events?: {
    __typename: "ModelCameraEventConnection";
    items: Array<{
      __typename: "CameraEvent";
      id: string;
      vmsEventId?: string | null;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      thumbnailFilename?: string | null;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      integratorId: string;
      monitoringCenterId: string;
      incidentId: string;
      incidentPriority: number;
      cameraEventCameraId: string;
      siteId: string;
      customerId: string;
      startTime: string;
      endTime?: string | null;
      resolvedTime?: string | null;
      instantaneous?: boolean | null;
      cameraEventUrl: string;
      cameraEventUrlPublic: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      ackTimestamp?: string | null;
      createdAt: string;
      vmsSourceType?: VMSSourceType | null;
      updatedAt: string;
      secondaryEvents?: {
        __typename: "ModelSecondaryCameraEventConnection";
        items: Array<{
          __typename: "SecondaryCameraEvent";
          id: string;
          cameraEventId: string;
          vmsNotes?: string | null;
          vmsNotesTranslated?: string | null;
          vmsNotesSanitized?: string | null;
          disposition: Disposition;
          eventType: EventType;
          vmsSourceType: VMSSourceType;
          ackTimestamp: string;
          modifiedBy?: string | null;
          vmsUserId?: string | null;
          monitoringCenterId: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      camera: {
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  site: {
    __typename: "Site";
    id: string;
    name: string;
    status?: DataEntryStatus | null;
    customerId: string;
    integratorId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    externalCriticalNotes?: string | null;
    externalCriticalNotesTranslated?: string | null;
    externalCriticalNotesSanitized?: string | null;
    monitoringCenterId: string;
    archived?: boolean | null;
    modifiedBy?: string | null;
    timezone?: string | null;
    vmsSharedMonitoringRoleId?: number | null;
    monitoringStatus?: MonitoringStatus | null;
    maintainCamerasOnMonitoringRole?: boolean | null;
    armingRulesConfig?: string | null;
    monitoringState?: MonitoringStates | null;
    armedState?: ArmedStates | null;
    alarmState?: AlarmStates | null;
    supervisionEnabled?: boolean | null;
    triggersEnabled?: boolean | null;
    muteAfterInteractionCount?: number | null;
    type?: SiteType | null;
    alarmsCentralStation?: string | null;
    alarmsCentralStationPhone?: string | null;
    alarmsCentralStationPhoneExt?: string | null;
    alarmsVerbalPasscode?: string | null;
    alarmsCSID?: string | null;
    alarmsAR?: string | null;
    alarmsNotes?: string | null;
    alarmsNotesTranslated?: string | null;
    alarmsNotesSanitized?: string | null;
    alarmsPermitNum?: string | null;
    alarmsPermitExpires?: string | null;
    autoSendToTrackTik?: boolean | null;
    hasSiteMap?: boolean | null;
    condensedAddress?: string | null;
    createdAt: string;
    allowCustomerViewPendingUntil?: string | null;
    updatedAt: string;
    address: {
      __typename: "Address";
      id: string;
      lineOne: string;
      lineTwo?: string | null;
      city: string;
      state: string;
      zipCode: string;
      country: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
    };
    integrations?: {
      __typename: "ModelSiteIntegrationConnection";
      items: Array<{
        __typename: "SiteIntegration";
        id: string;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        type?: IntegrationType | null;
        subType?: IntegrationSubType | null;
        subTypeList?: Array<IntegrationSubType> | null;
        agency?: string | null;
        phone?: string | null;
        phoneExt?: string | null;
        email?: string | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        trackTickDetails?: {
          __typename: "TrackTikDetails";
          id: string;
          siteIntegrationId: string;
          siteId: string;
          monitoringCenterId: string;
          apiUrl: string;
          apiUsername: string;
          apiPassword: string;
          accountId: string;
          token?: string | null;
          refreshToken?: string | null;
          tokenExpiry?: string | null;
          refreshTokenExpiry?: string | null;
          autoSend?: boolean | null;
          autoSendErrorEmail?: string | null;
          configured: boolean;
          archived?: boolean | null;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taskList?: {
            __typename: "ModelTrackTikTaskConnection";
            items: Array<{
              __typename: "TrackTikTask";
              id: string;
              trackTikDetailsId: string;
              siteId: string;
              monitoringCenterId: string;
              eventType?: EventType | null;
              trackTikName: string;
              trackTikId: number;
              trackTikReportTemplateId: number;
              archived?: boolean | null;
              modifedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    contacts?: {
      __typename: "ModelSiteEmergencyContactConnection";
      items: Array<{
        __typename: "SiteEmergencyContact";
        id: string;
        siteId: string;
        name: string;
        type?: Array<SiteContactType | null> | null;
        verbalPasscode?: string | null;
        email?: string | null;
        priority: number;
        monitoringCenterId: string;
        integratorId?: string | null;
        archived?: boolean | null;
        notes?: string | null;
        notesTranslated?: string | null;
        notesSanitized?: string | null;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
        phones?: {
          __typename: "ModelPhoneConnection";
          items: Array<{
            __typename: "Phone";
            id: string;
            contactId: string;
            type?: PhoneType | null;
            number?: string | null;
            ext?: string | null;
            monitoringCenterId: string;
            integratorId?: string | null;
            modifiedBy?: string | null;
            archived?: boolean | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    cameras?: {
      __typename: "ModelCameraConnection";
      items: Array<{
        __typename: "Camera";
        id: string;
        name: string;
        deviceType: DeviceType;
        status: DataEntryStatus;
        vmsCameraName?: string | null;
        description?: string | null;
        descriptionTranslated?: string | null;
        descriptionSanitized?: string | null;
        vmsDescription?: string | null;
        siteId: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        customerId: string;
        criticalNotes?: string | null;
        criticalNotesTranslated?: string | null;
        criticalNotesSanitized?: string | null;
        talkdownInstructions?: {
          __typename: "TalkdownInstructions";
          talkdownType?: TalkdownType | null;
          note?: string | null;
          noteTranslated?: string | null;
          noteSanitized?: string | null;
          phone?: string | null;
          url?: string | null;
          messageDetails?: Array<{
            __typename: "TalkdownMessageDetail";
            messageType: AudioMessageType;
            messageTypeId?: string | null;
            urlSuffix: string;
          } | null> | null;
          hostConfiguration?: {
            __typename: "TalkdownHostConfiguration";
            baseUrl?: string | null;
            username?: string | null;
            password?: string | null;
            method: HttpRequestType;
          } | null;
        } | null;
        latitude?: number | null;
        longitude?: number | null;
        vmsCameraId?: string | null;
        vmsCustomerId?: string | null;
        testingExpiry?: string | null;
        armedState?: ArmedStates | null;
        snoozeReason?: string | null;
        snoozeReasonTranslated?: string | null;
        snoozeReasonSanitized?: string | null;
        snoozeExpiry?: string | null;
        archived?: boolean | null;
        zoneNumber?: string | null;
        zoneDescription?: string | null;
        zoneDescriptionTranslated?: string | null;
        zoneDescriptionSanitized?: string | null;
        modifiedBy?: string | null;
        isOnvif?: boolean | null;
        videoStreamState?: string | null;
        deviceUrlOnline?: boolean | null;
        vmsConnect?: boolean | null;
        isP2P?: boolean | null;
        isAnalyticsEnabled?: boolean | null;
        isMotionEnabled?: boolean | null;
        testEventReceived?: boolean | null;
        healthCheckEnabled?: boolean | null;
        healthCheckSLA?: DeviceHealthCheckSLA | null;
        healthCheckStatus?: HealthCheckStatus | null;
        ignoreStatusChecks?: boolean | null;
        statusChecksProperties?: Array<DeviceStatusProperties> | null;
        statusLastVerified?: string | null;
        archiveDurationHours?: number | null;
        accessUrlsId?: string | null;
        credentialsId?: string | null;
        deviceBrand?: string | null;
        deviceModel?: string | null;
        vmsPlanId?: number | null;
        phpRegistrationCode?: string | null;
        serialNumber?: string | null;
        p2pState?: string | null;
        lastPowerCycle?: string | null;
        baselineThumbnailFilename?: string | null;
        currentThumbnailFilename?: string | null;
        sceneChangeDetectionEnabled?: boolean | null;
        passingSceneDetection?: boolean | null;
        hubspotId?: string | null;
        immixMonitoring?: boolean | null;
        hasMic?: boolean | null;
        hasSpeaker?: boolean | null;
        audioGroups?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        qualitySettings?: {
          __typename: "CameraQualitySettings";
          id: string;
          width: number;
          height: number;
          videoBitRate: number;
          frameRate: number;
          monitoringCenterId: string;
          cameraId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    sops?: {
      __typename: "ModelSOPConnection";
      items: Array<{
        __typename: "SOP";
        id: string;
        siteId: string;
        eventType: EventType;
        eventSOPs: Array<EventOp>;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type OnCreateIncidentNoteSubscription = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type OnUpdateIncidentNoteSubscription = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type OnDeleteIncidentNoteSubscription = {
  __typename: "IncidentNote";
  id: string;
  incidentId: string;
  note: string;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  writtenBy?: string | null;
  createdAt: string;
  shareExternal?: boolean | null;
  updatedAt: string;
};

export type OnCreateIncidentActionListSubscription = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateIncidentActionListSubscription = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteIncidentActionListSubscription = {
  __typename: "IncidentActionList";
  id: string;
  incidentId: string;
  priority: number;
  eventType: EventType;
  disposition: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  tasks?: {
    __typename: "ModelProcedureTaskConnection";
    items: Array<{
      __typename: "ProcedureTask";
      id: string;
      incidentId: string;
      incidentActionItemId: string;
      order: number;
      completed?: boolean | null;
      description: string;
      modifiedBy?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateProcedureTaskSubscription = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProcedureTaskSubscription = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProcedureTaskSubscription = {
  __typename: "ProcedureTask";
  id: string;
  incidentId: string;
  incidentActionItemId: string;
  order: number;
  completed?: boolean | null;
  description: string;
  modifiedBy?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentBundleTimeSubscription = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentBundleTimeSubscription = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentBundleTimeSubscription = {
  __typename: "IncidentBundleTime";
  id: string;
  monitoringCenterId: string;
  applyToAssigned: boolean;
  bundleTime: number;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraEventSubscription = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type OnUpdateCameraEventSubscription = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type OnDeleteCameraEventSubscription = {
  __typename: "CameraEvent";
  id: string;
  vmsEventId?: string | null;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  thumbnailFilename?: string | null;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  integratorId: string;
  monitoringCenterId: string;
  incidentId: string;
  incidentPriority: number;
  cameraEventCameraId: string;
  siteId: string;
  customerId: string;
  startTime: string;
  endTime?: string | null;
  resolvedTime?: string | null;
  instantaneous?: boolean | null;
  cameraEventUrl: string;
  cameraEventUrlPublic: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  ackTimestamp?: string | null;
  createdAt: string;
  vmsSourceType?: VMSSourceType | null;
  updatedAt: string;
  secondaryEvents?: {
    __typename: "ModelSecondaryCameraEventConnection";
    items: Array<{
      __typename: "SecondaryCameraEvent";
      id: string;
      cameraEventId: string;
      vmsNotes?: string | null;
      vmsNotesTranslated?: string | null;
      vmsNotesSanitized?: string | null;
      disposition: Disposition;
      eventType: EventType;
      vmsSourceType: VMSSourceType;
      ackTimestamp: string;
      modifiedBy?: string | null;
      vmsUserId?: string | null;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  camera: {
    __typename: "Camera";
    id: string;
    name: string;
    deviceType: DeviceType;
    status: DataEntryStatus;
    vmsCameraName?: string | null;
    description?: string | null;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    vmsDescription?: string | null;
    siteId: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    customerId: string;
    criticalNotes?: string | null;
    criticalNotesTranslated?: string | null;
    criticalNotesSanitized?: string | null;
    talkdownInstructions?: {
      __typename: "TalkdownInstructions";
      talkdownType?: TalkdownType | null;
      note?: string | null;
      noteTranslated?: string | null;
      noteSanitized?: string | null;
      phone?: string | null;
      url?: string | null;
      messageDetails?: Array<{
        __typename: "TalkdownMessageDetail";
        messageType: AudioMessageType;
        messageTypeId?: string | null;
        urlSuffix: string;
      } | null> | null;
      hostConfiguration?: {
        __typename: "TalkdownHostConfiguration";
        baseUrl?: string | null;
        username?: string | null;
        password?: string | null;
        method: HttpRequestType;
      } | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    vmsCameraId?: string | null;
    vmsCustomerId?: string | null;
    testingExpiry?: string | null;
    armedState?: ArmedStates | null;
    snoozeReason?: string | null;
    snoozeReasonTranslated?: string | null;
    snoozeReasonSanitized?: string | null;
    snoozeExpiry?: string | null;
    archived?: boolean | null;
    zoneNumber?: string | null;
    zoneDescription?: string | null;
    zoneDescriptionTranslated?: string | null;
    zoneDescriptionSanitized?: string | null;
    modifiedBy?: string | null;
    isOnvif?: boolean | null;
    videoStreamState?: string | null;
    deviceUrlOnline?: boolean | null;
    vmsConnect?: boolean | null;
    isP2P?: boolean | null;
    isAnalyticsEnabled?: boolean | null;
    isMotionEnabled?: boolean | null;
    testEventReceived?: boolean | null;
    healthCheckEnabled?: boolean | null;
    healthCheckSLA?: DeviceHealthCheckSLA | null;
    healthCheckStatus?: HealthCheckStatus | null;
    ignoreStatusChecks?: boolean | null;
    statusChecksProperties?: Array<DeviceStatusProperties> | null;
    statusLastVerified?: string | null;
    archiveDurationHours?: number | null;
    accessUrlsId?: string | null;
    credentialsId?: string | null;
    deviceBrand?: string | null;
    deviceModel?: string | null;
    vmsPlanId?: number | null;
    phpRegistrationCode?: string | null;
    serialNumber?: string | null;
    p2pState?: string | null;
    lastPowerCycle?: string | null;
    baselineThumbnailFilename?: string | null;
    currentThumbnailFilename?: string | null;
    sceneChangeDetectionEnabled?: boolean | null;
    passingSceneDetection?: boolean | null;
    hubspotId?: string | null;
    immixMonitoring?: boolean | null;
    hasMic?: boolean | null;
    hasSpeaker?: boolean | null;
    audioGroups?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    qualitySettings?: {
      __typename: "CameraQualitySettings";
      id: string;
      width: number;
      height: number;
      videoBitRate: number;
      frameRate: number;
      monitoringCenterId: string;
      cameraId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type OnCreateSecondaryCameraEventSubscription = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSecondaryCameraEventSubscription = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSecondaryCameraEventSubscription = {
  __typename: "SecondaryCameraEvent";
  id: string;
  cameraEventId: string;
  vmsNotes?: string | null;
  vmsNotesTranslated?: string | null;
  vmsNotesSanitized?: string | null;
  disposition: Disposition;
  eventType: EventType;
  vmsSourceType: VMSSourceType;
  ackTimestamp: string;
  modifiedBy?: string | null;
  vmsUserId?: string | null;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomerSubscription = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateCustomerSubscription = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteCustomerSubscription = {
  __typename: "Customer";
  id: string;
  name: string;
  type: CustomerType;
  status?: DataEntryStatus | null;
  integratorId?: string | null;
  monitoringCenterId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  vmsCustomerId?: number | null;
  vmsMCWebhookId?: string | null;
  vmsMonitoringCenterRoleId?: number | null;
  archived?: boolean | null;
  modifiedBy?: string | null;
  slaL1?: string | null;
  slaL2?: string | null;
  slaL3?: string | null;
  slaL4?: string | null;
  sortName?: string | null;
  softphoneEnabled?: boolean | null;
  softphoneNumber?: string | null;
  callbackNumber?: string | null;
  callbackNumberExt?: string | null;
  zendeskWidgetScript?: string | null;
  hubspotId?: string | null;
  hasLogo?: boolean | null;
  mobileLogo?: string | null;
  invoicePrefix?: string | null;
  invoiceNumber?: number | null;
  invoiceFooter?: string | null;
  errorEmail?: string | null;
  vmsUrl?: string | null;
  vmsAlarmUrl?: string | null;
  vmsAdminUrl?: string | null;
  orgDomainUrl?: string | null;
  fromEmail?: string | null;
  createdAt?: string | null;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  boldBIIntegration?: {
    __typename: "BoldBIDetails";
    id: string;
    siteUsername: string;
    sitePassword: string;
    siteUrl: string;
    siteDefaultDashboardId: string;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  rapidSOSIntegration?: {
    __typename: "RapidSOSDetails";
    id: string;
    clientId: string;
    clientSecret: string;
    webhookEnabled: boolean;
    agencyId: string;
    webhookId?: number | null;
    customerId: string;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contact: {
    __typename: "Contact";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    phone?: string | null;
    ext?: string | null;
    email?: string | null;
    monitoringCenterId: string;
    integratorId?: string | null;
    modifiedBy?: string | null;
    customerCanShareSecurables?: boolean | null;
    customerCanLoginAdminPortal?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  sites?: {
    __typename: "ModelSiteConnection";
    items: Array<{
      __typename: "Site";
      id: string;
      name: string;
      status?: DataEntryStatus | null;
      customerId: string;
      integratorId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      externalCriticalNotes?: string | null;
      externalCriticalNotesTranslated?: string | null;
      externalCriticalNotesSanitized?: string | null;
      monitoringCenterId: string;
      archived?: boolean | null;
      modifiedBy?: string | null;
      timezone?: string | null;
      vmsSharedMonitoringRoleId?: number | null;
      monitoringStatus?: MonitoringStatus | null;
      maintainCamerasOnMonitoringRole?: boolean | null;
      armingRulesConfig?: string | null;
      monitoringState?: MonitoringStates | null;
      armedState?: ArmedStates | null;
      alarmState?: AlarmStates | null;
      supervisionEnabled?: boolean | null;
      triggersEnabled?: boolean | null;
      muteAfterInteractionCount?: number | null;
      type?: SiteType | null;
      alarmsCentralStation?: string | null;
      alarmsCentralStationPhone?: string | null;
      alarmsCentralStationPhoneExt?: string | null;
      alarmsVerbalPasscode?: string | null;
      alarmsCSID?: string | null;
      alarmsAR?: string | null;
      alarmsNotes?: string | null;
      alarmsNotesTranslated?: string | null;
      alarmsNotesSanitized?: string | null;
      alarmsPermitNum?: string | null;
      alarmsPermitExpires?: string | null;
      autoSendToTrackTik?: boolean | null;
      hasSiteMap?: boolean | null;
      condensedAddress?: string | null;
      createdAt: string;
      allowCustomerViewPendingUntil?: string | null;
      updatedAt: string;
      address: {
        __typename: "Address";
        id: string;
        lineOne: string;
        lineTwo?: string | null;
        city: string;
        state: string;
        zipCode: string;
        country: string;
        integratorId?: string | null;
        monitoringCenterId: string;
        modifiedBy?: string | null;
        createdAt: string;
        updatedAt: string;
      };
      integrations?: {
        __typename: "ModelSiteIntegrationConnection";
        items: Array<{
          __typename: "SiteIntegration";
          id: string;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          type?: IntegrationType | null;
          subType?: IntegrationSubType | null;
          subTypeList?: Array<IntegrationSubType> | null;
          agency?: string | null;
          phone?: string | null;
          phoneExt?: string | null;
          email?: string | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
          trackTickDetails?: {
            __typename: "TrackTikDetails";
            id: string;
            siteIntegrationId: string;
            siteId: string;
            monitoringCenterId: string;
            apiUrl: string;
            apiUsername: string;
            apiPassword: string;
            accountId: string;
            token?: string | null;
            refreshToken?: string | null;
            tokenExpiry?: string | null;
            refreshTokenExpiry?: string | null;
            autoSend?: boolean | null;
            autoSendErrorEmail?: string | null;
            configured: boolean;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taskList?: {
              __typename: "ModelTrackTikTaskConnection";
              nextToken?: string | null;
            } | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      contacts?: {
        __typename: "ModelSiteEmergencyContactConnection";
        items: Array<{
          __typename: "SiteEmergencyContact";
          id: string;
          siteId: string;
          name: string;
          type?: Array<SiteContactType | null> | null;
          verbalPasscode?: string | null;
          email?: string | null;
          priority: number;
          monitoringCenterId: string;
          integratorId?: string | null;
          archived?: boolean | null;
          notes?: string | null;
          notesTranslated?: string | null;
          notesSanitized?: string | null;
          modifiedBy?: string | null;
          createdAt: string;
          updatedAt: string;
          phones?: {
            __typename: "ModelPhoneConnection";
            items: Array<{
              __typename: "Phone";
              id: string;
              contactId: string;
              type?: PhoneType | null;
              number?: string | null;
              ext?: string | null;
              monitoringCenterId: string;
              integratorId?: string | null;
              modifiedBy?: string | null;
              archived?: boolean | null;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      cameras?: {
        __typename: "ModelCameraConnection";
        items: Array<{
          __typename: "Camera";
          id: string;
          name: string;
          deviceType: DeviceType;
          status: DataEntryStatus;
          vmsCameraName?: string | null;
          description?: string | null;
          descriptionTranslated?: string | null;
          descriptionSanitized?: string | null;
          vmsDescription?: string | null;
          siteId: string;
          integratorId?: string | null;
          monitoringCenterId: string;
          customerId: string;
          criticalNotes?: string | null;
          criticalNotesTranslated?: string | null;
          criticalNotesSanitized?: string | null;
          talkdownInstructions?: {
            __typename: "TalkdownInstructions";
            talkdownType?: TalkdownType | null;
            note?: string | null;
            noteTranslated?: string | null;
            noteSanitized?: string | null;
            phone?: string | null;
            url?: string | null;
            messageDetails?: Array<{
              __typename: "TalkdownMessageDetail";
              messageType: AudioMessageType;
              messageTypeId?: string | null;
              urlSuffix: string;
            } | null> | null;
            hostConfiguration?: {
              __typename: "TalkdownHostConfiguration";
              baseUrl?: string | null;
              username?: string | null;
              password?: string | null;
              method: HttpRequestType;
            } | null;
          } | null;
          latitude?: number | null;
          longitude?: number | null;
          vmsCameraId?: string | null;
          vmsCustomerId?: string | null;
          testingExpiry?: string | null;
          armedState?: ArmedStates | null;
          snoozeReason?: string | null;
          snoozeReasonTranslated?: string | null;
          snoozeReasonSanitized?: string | null;
          snoozeExpiry?: string | null;
          archived?: boolean | null;
          zoneNumber?: string | null;
          zoneDescription?: string | null;
          zoneDescriptionTranslated?: string | null;
          zoneDescriptionSanitized?: string | null;
          modifiedBy?: string | null;
          isOnvif?: boolean | null;
          videoStreamState?: string | null;
          deviceUrlOnline?: boolean | null;
          vmsConnect?: boolean | null;
          isP2P?: boolean | null;
          isAnalyticsEnabled?: boolean | null;
          isMotionEnabled?: boolean | null;
          testEventReceived?: boolean | null;
          healthCheckEnabled?: boolean | null;
          healthCheckSLA?: DeviceHealthCheckSLA | null;
          healthCheckStatus?: HealthCheckStatus | null;
          ignoreStatusChecks?: boolean | null;
          statusChecksProperties?: Array<DeviceStatusProperties> | null;
          statusLastVerified?: string | null;
          archiveDurationHours?: number | null;
          accessUrlsId?: string | null;
          credentialsId?: string | null;
          deviceBrand?: string | null;
          deviceModel?: string | null;
          vmsPlanId?: number | null;
          phpRegistrationCode?: string | null;
          serialNumber?: string | null;
          p2pState?: string | null;
          lastPowerCycle?: string | null;
          baselineThumbnailFilename?: string | null;
          currentThumbnailFilename?: string | null;
          sceneChangeDetectionEnabled?: boolean | null;
          passingSceneDetection?: boolean | null;
          hubspotId?: string | null;
          immixMonitoring?: boolean | null;
          hasMic?: boolean | null;
          hasSpeaker?: boolean | null;
          audioGroups?: Array<string> | null;
          createdAt?: string | null;
          updatedAt?: string | null;
          qualitySettings?: {
            __typename: "CameraQualitySettings";
            id: string;
            width: number;
            height: number;
            videoBitRate: number;
            frameRate: number;
            monitoringCenterId: string;
            cameraId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null;
        } | null>;
        nextToken?: string | null;
      } | null;
      sops?: {
        __typename: "ModelSOPConnection";
        items: Array<{
          __typename: "SOP";
          id: string;
          siteId: string;
          eventType: EventType;
          eventSOPs: Array<EventOp>;
          monitoringCenterId: string;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateVMSDetailsSubscription = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVMSDetailsSubscription = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVMSDetailsSubscription = {
  __typename: "VMSDetails";
  id: string;
  apiKey: string;
  username: string;
  password: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDashboardDetailSubscription = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDashboardDetailSubscription = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDashboardDetailSubscription = {
  __typename: "DashboardDetail";
  id: string;
  name: string;
  awsId: string;
  monitoringCenterId: string;
  allowedGroups: Array<UserGroup | null>;
  integratorId?: string | null;
  username?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateBoldBIDetailsSubscription = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateBoldBIDetailsSubscription = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteBoldBIDetailsSubscription = {
  __typename: "BoldBIDetails";
  id: string;
  siteUsername: string;
  sitePassword: string;
  siteUrl: string;
  siteDefaultDashboardId: string;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRapidSOSDetailsSubscription = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRapidSOSDetailsSubscription = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRapidSOSDetailsSubscription = {
  __typename: "RapidSOSDetails";
  id: string;
  clientId: string;
  clientSecret: string;
  webhookEnabled: boolean;
  agencyId: string;
  webhookId?: number | null;
  customerId: string;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAddressSubscription = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAddressSubscription = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAddressSubscription = {
  __typename: "Address";
  id: string;
  lineOne: string;
  lineTwo?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateContactSubscription = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateContactSubscription = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteContactSubscription = {
  __typename: "Contact";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  phone?: string | null;
  ext?: string | null;
  email?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  customerCanShareSecurables?: boolean | null;
  customerCanLoginAdminPortal?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePendingSiteSubscription = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePendingSiteSubscription = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePendingSiteSubscription = {
  __typename: "PendingSite";
  id: string;
  name: string;
  address?: {
    __typename: "PendingEntityAddress";
    lineOne?: string | null;
    lineTwo?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
  } | null;
  contacts?: Array<{
    __typename: "PendingEntityContact";
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    email?: string | null;
    types?: Array<SiteContactType> | null;
    verbalPasscode?: string | null;
  }> | null;
  status: PendingEntityStatus;
  notes?: string | null;
  primaryEmail: string;
  customerId?: string | null;
  modifiedBy: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSiteSubscription = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateSiteSubscription = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteSiteSubscription = {
  __typename: "Site";
  id: string;
  name: string;
  status?: DataEntryStatus | null;
  customerId: string;
  integratorId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  externalCriticalNotes?: string | null;
  externalCriticalNotesTranslated?: string | null;
  externalCriticalNotesSanitized?: string | null;
  monitoringCenterId: string;
  archived?: boolean | null;
  modifiedBy?: string | null;
  timezone?: string | null;
  vmsSharedMonitoringRoleId?: number | null;
  monitoringStatus?: MonitoringStatus | null;
  maintainCamerasOnMonitoringRole?: boolean | null;
  armingRulesConfig?: string | null;
  monitoringState?: MonitoringStates | null;
  armedState?: ArmedStates | null;
  alarmState?: AlarmStates | null;
  supervisionEnabled?: boolean | null;
  triggersEnabled?: boolean | null;
  muteAfterInteractionCount?: number | null;
  type?: SiteType | null;
  alarmsCentralStation?: string | null;
  alarmsCentralStationPhone?: string | null;
  alarmsCentralStationPhoneExt?: string | null;
  alarmsVerbalPasscode?: string | null;
  alarmsCSID?: string | null;
  alarmsAR?: string | null;
  alarmsNotes?: string | null;
  alarmsNotesTranslated?: string | null;
  alarmsNotesSanitized?: string | null;
  alarmsPermitNum?: string | null;
  alarmsPermitExpires?: string | null;
  autoSendToTrackTik?: boolean | null;
  hasSiteMap?: boolean | null;
  condensedAddress?: string | null;
  createdAt: string;
  allowCustomerViewPendingUntil?: string | null;
  updatedAt: string;
  address: {
    __typename: "Address";
    id: string;
    lineOne: string;
    lineTwo?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    integratorId?: string | null;
    monitoringCenterId: string;
    modifiedBy?: string | null;
    createdAt: string;
    updatedAt: string;
  };
  integrations?: {
    __typename: "ModelSiteIntegrationConnection";
    items: Array<{
      __typename: "SiteIntegration";
      id: string;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      type?: IntegrationType | null;
      subType?: IntegrationSubType | null;
      subTypeList?: Array<IntegrationSubType> | null;
      agency?: string | null;
      phone?: string | null;
      phoneExt?: string | null;
      email?: string | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      trackTickDetails?: {
        __typename: "TrackTikDetails";
        id: string;
        siteIntegrationId: string;
        siteId: string;
        monitoringCenterId: string;
        apiUrl: string;
        apiUsername: string;
        apiPassword: string;
        accountId: string;
        token?: string | null;
        refreshToken?: string | null;
        tokenExpiry?: string | null;
        refreshTokenExpiry?: string | null;
        autoSend?: boolean | null;
        autoSendErrorEmail?: string | null;
        configured: boolean;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taskList?: {
          __typename: "ModelTrackTikTaskConnection";
          items: Array<{
            __typename: "TrackTikTask";
            id: string;
            trackTikDetailsId: string;
            siteId: string;
            monitoringCenterId: string;
            eventType?: EventType | null;
            trackTikName: string;
            trackTikId: number;
            trackTikReportTemplateId: number;
            archived?: boolean | null;
            modifedBy: string;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contacts?: {
    __typename: "ModelSiteEmergencyContactConnection";
    items: Array<{
      __typename: "SiteEmergencyContact";
      id: string;
      siteId: string;
      name: string;
      type?: Array<SiteContactType | null> | null;
      verbalPasscode?: string | null;
      email?: string | null;
      priority: number;
      monitoringCenterId: string;
      integratorId?: string | null;
      archived?: boolean | null;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      updatedAt: string;
      phones?: {
        __typename: "ModelPhoneConnection";
        items: Array<{
          __typename: "Phone";
          id: string;
          contactId: string;
          type?: PhoneType | null;
          number?: string | null;
          ext?: string | null;
          monitoringCenterId: string;
          integratorId?: string | null;
          modifiedBy?: string | null;
          archived?: boolean | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  cameras?: {
    __typename: "ModelCameraConnection";
    items: Array<{
      __typename: "Camera";
      id: string;
      name: string;
      deviceType: DeviceType;
      status: DataEntryStatus;
      vmsCameraName?: string | null;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      vmsDescription?: string | null;
      siteId: string;
      integratorId?: string | null;
      monitoringCenterId: string;
      customerId: string;
      criticalNotes?: string | null;
      criticalNotesTranslated?: string | null;
      criticalNotesSanitized?: string | null;
      talkdownInstructions?: {
        __typename: "TalkdownInstructions";
        talkdownType?: TalkdownType | null;
        note?: string | null;
        noteTranslated?: string | null;
        noteSanitized?: string | null;
        phone?: string | null;
        url?: string | null;
        messageDetails?: Array<{
          __typename: "TalkdownMessageDetail";
          messageType: AudioMessageType;
          messageTypeId?: string | null;
          urlSuffix: string;
        } | null> | null;
        hostConfiguration?: {
          __typename: "TalkdownHostConfiguration";
          baseUrl?: string | null;
          username?: string | null;
          password?: string | null;
          method: HttpRequestType;
        } | null;
      } | null;
      latitude?: number | null;
      longitude?: number | null;
      vmsCameraId?: string | null;
      vmsCustomerId?: string | null;
      testingExpiry?: string | null;
      armedState?: ArmedStates | null;
      snoozeReason?: string | null;
      snoozeReasonTranslated?: string | null;
      snoozeReasonSanitized?: string | null;
      snoozeExpiry?: string | null;
      archived?: boolean | null;
      zoneNumber?: string | null;
      zoneDescription?: string | null;
      zoneDescriptionTranslated?: string | null;
      zoneDescriptionSanitized?: string | null;
      modifiedBy?: string | null;
      isOnvif?: boolean | null;
      videoStreamState?: string | null;
      deviceUrlOnline?: boolean | null;
      vmsConnect?: boolean | null;
      isP2P?: boolean | null;
      isAnalyticsEnabled?: boolean | null;
      isMotionEnabled?: boolean | null;
      testEventReceived?: boolean | null;
      healthCheckEnabled?: boolean | null;
      healthCheckSLA?: DeviceHealthCheckSLA | null;
      healthCheckStatus?: HealthCheckStatus | null;
      ignoreStatusChecks?: boolean | null;
      statusChecksProperties?: Array<DeviceStatusProperties> | null;
      statusLastVerified?: string | null;
      archiveDurationHours?: number | null;
      accessUrlsId?: string | null;
      credentialsId?: string | null;
      deviceBrand?: string | null;
      deviceModel?: string | null;
      vmsPlanId?: number | null;
      phpRegistrationCode?: string | null;
      serialNumber?: string | null;
      p2pState?: string | null;
      lastPowerCycle?: string | null;
      baselineThumbnailFilename?: string | null;
      currentThumbnailFilename?: string | null;
      sceneChangeDetectionEnabled?: boolean | null;
      passingSceneDetection?: boolean | null;
      hubspotId?: string | null;
      immixMonitoring?: boolean | null;
      hasMic?: boolean | null;
      hasSpeaker?: boolean | null;
      audioGroups?: Array<string> | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      qualitySettings?: {
        __typename: "CameraQualitySettings";
        id: string;
        width: number;
        height: number;
        videoBitRate: number;
        frameRate: number;
        monitoringCenterId: string;
        cameraId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  sops?: {
    __typename: "ModelSOPConnection";
    items: Array<{
      __typename: "SOP";
      id: string;
      siteId: string;
      eventType: EventType;
      eventSOPs: Array<EventOp>;
      monitoringCenterId: string;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateSiteSupvisdSettingSubscription = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateSiteSupvisdSettingSubscription = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteSiteSupvisdSettingSubscription = {
  __typename: "SiteSupvisdSetting";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  rules?: {
    __typename: "ModelSupvisdEventRuleConnection";
    items: Array<{
      __typename: "SupvisdEventRule";
      id: string;
      cameraId?: string | null;
      muteSitePersonRule?: boolean | null;
      siteId: string;
      integratorId: string;
      monitoringCenterId: string;
      siteSupvisdSettingId: string;
      modifiedBy: string;
      archived?: boolean | null;
      ruleType: RuleType;
      cameraEventType: CameraEventSupervisionType;
      disposition: EventType;
      confidence?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateSupvisdEventRuleSubscription = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSupvisdEventRuleSubscription = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSupvisdEventRuleSubscription = {
  __typename: "SupvisdEventRule";
  id: string;
  cameraId?: string | null;
  muteSitePersonRule?: boolean | null;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  siteSupvisdSettingId: string;
  modifiedBy: string;
  archived?: boolean | null;
  ruleType: RuleType;
  cameraEventType: CameraEventSupervisionType;
  disposition: EventType;
  confidence?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSiteDeviceStatusConfigSubscription = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSiteDeviceStatusConfigSubscription = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSiteDeviceStatusConfigSubscription = {
  __typename: "SiteDeviceStatusConfig";
  id: string;
  siteId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  deviceStatusEnabled: boolean;
  statusFrequency: DeviceStatusCheckSLA;
  statusVerifiedTolerance: DeviceStatusCheckSLA;
  statusProperties: Array<DeviceStatusProperties>;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTriggerSubscription = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTriggerSubscription = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTriggerSubscription = {
  __typename: "Trigger";
  id: string;
  siteId: string;
  integratorId: string;
  customerId: string;
  monitoringCenterId: string;
  type: TriggerType;
  filters?: Array<{
    __typename: "TriggerFilter";
    filter: string;
    frequencyLimit?: number | null;
    lastTriggered?: string | null;
    actions?: Array<{
      __typename: "TriggerAction";
      action: TriggerActionType;
      actionSettings: string;
      frequencyLimit?: number | null;
      lastTriggered?: string | null;
    }> | null;
  }> | null;
  modifiedBy: string;
  archived?: boolean | null;
  frequencyLimit?: number | null;
  lastTriggered?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSiteIntegrationSubscription = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateSiteIntegrationSubscription = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteSiteIntegrationSubscription = {
  __typename: "SiteIntegration";
  id: string;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  type?: IntegrationType | null;
  subType?: IntegrationSubType | null;
  subTypeList?: Array<IntegrationSubType> | null;
  agency?: string | null;
  phone?: string | null;
  phoneExt?: string | null;
  email?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  trackTickDetails?: {
    __typename: "TrackTikDetails";
    id: string;
    siteIntegrationId: string;
    siteId: string;
    monitoringCenterId: string;
    apiUrl: string;
    apiUsername: string;
    apiPassword: string;
    accountId: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpiry?: string | null;
    refreshTokenExpiry?: string | null;
    autoSend?: boolean | null;
    autoSendErrorEmail?: string | null;
    configured: boolean;
    archived?: boolean | null;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taskList?: {
      __typename: "ModelTrackTikTaskConnection";
      items: Array<{
        __typename: "TrackTikTask";
        id: string;
        trackTikDetailsId: string;
        siteId: string;
        monitoringCenterId: string;
        eventType?: EventType | null;
        trackTikName: string;
        trackTikId: number;
        trackTikReportTemplateId: number;
        archived?: boolean | null;
        modifedBy: string;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateSiteVMSDetailsSubscription = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSiteVMSDetailsSubscription = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSiteVMSDetailsSubscription = {
  __typename: "SiteVMSDetails";
  id: string;
  username: string;
  password: string;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  siteId: string;
  siteUserId?: string | null;
  siteUserRoleId?: number | null;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTrackTikDetailsSubscription = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateTrackTikDetailsSubscription = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteTrackTikDetailsSubscription = {
  __typename: "TrackTikDetails";
  id: string;
  siteIntegrationId: string;
  siteId: string;
  monitoringCenterId: string;
  apiUrl: string;
  apiUsername: string;
  apiPassword: string;
  accountId: string;
  token?: string | null;
  refreshToken?: string | null;
  tokenExpiry?: string | null;
  refreshTokenExpiry?: string | null;
  autoSend?: boolean | null;
  autoSendErrorEmail?: string | null;
  configured: boolean;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taskList?: {
    __typename: "ModelTrackTikTaskConnection";
    items: Array<{
      __typename: "TrackTikTask";
      id: string;
      trackTikDetailsId: string;
      siteId: string;
      monitoringCenterId: string;
      eventType?: EventType | null;
      trackTikName: string;
      trackTikId: number;
      trackTikReportTemplateId: number;
      archived?: boolean | null;
      modifedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateTrackTikTaskSubscription = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTrackTikTaskSubscription = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTrackTikTaskSubscription = {
  __typename: "TrackTikTask";
  id: string;
  trackTikDetailsId: string;
  siteId: string;
  monitoringCenterId: string;
  eventType?: EventType | null;
  trackTikName: string;
  trackTikId: number;
  trackTikReportTemplateId: number;
  archived?: boolean | null;
  modifedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSiteEmergencyContactSubscription = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateSiteEmergencyContactSubscription = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteSiteEmergencyContactSubscription = {
  __typename: "SiteEmergencyContact";
  id: string;
  siteId: string;
  name: string;
  type?: Array<SiteContactType | null> | null;
  verbalPasscode?: string | null;
  email?: string | null;
  priority: number;
  monitoringCenterId: string;
  integratorId?: string | null;
  archived?: boolean | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
  phones?: {
    __typename: "ModelPhoneConnection";
    items: Array<{
      __typename: "Phone";
      id: string;
      contactId: string;
      type?: PhoneType | null;
      number?: string | null;
      ext?: string | null;
      monitoringCenterId: string;
      integratorId?: string | null;
      modifiedBy?: string | null;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreatePhoneSubscription = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdatePhoneSubscription = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeletePhoneSubscription = {
  __typename: "Phone";
  id: string;
  contactId: string;
  type?: PhoneType | null;
  number?: string | null;
  ext?: string | null;
  monitoringCenterId: string;
  integratorId?: string | null;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraSubscription = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateCameraSubscription = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteCameraSubscription = {
  __typename: "Camera";
  id: string;
  name: string;
  deviceType: DeviceType;
  status: DataEntryStatus;
  vmsCameraName?: string | null;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  vmsDescription?: string | null;
  siteId: string;
  integratorId?: string | null;
  monitoringCenterId: string;
  customerId: string;
  criticalNotes?: string | null;
  criticalNotesTranslated?: string | null;
  criticalNotesSanitized?: string | null;
  talkdownInstructions?: {
    __typename: "TalkdownInstructions";
    talkdownType?: TalkdownType | null;
    note?: string | null;
    noteTranslated?: string | null;
    noteSanitized?: string | null;
    phone?: string | null;
    url?: string | null;
    messageDetails?: Array<{
      __typename: "TalkdownMessageDetail";
      messageType: AudioMessageType;
      messageTypeId?: string | null;
      urlSuffix: string;
    } | null> | null;
    hostConfiguration?: {
      __typename: "TalkdownHostConfiguration";
      baseUrl?: string | null;
      username?: string | null;
      password?: string | null;
      method: HttpRequestType;
    } | null;
  } | null;
  latitude?: number | null;
  longitude?: number | null;
  vmsCameraId?: string | null;
  vmsCustomerId?: string | null;
  testingExpiry?: string | null;
  armedState?: ArmedStates | null;
  snoozeReason?: string | null;
  snoozeReasonTranslated?: string | null;
  snoozeReasonSanitized?: string | null;
  snoozeExpiry?: string | null;
  archived?: boolean | null;
  zoneNumber?: string | null;
  zoneDescription?: string | null;
  zoneDescriptionTranslated?: string | null;
  zoneDescriptionSanitized?: string | null;
  modifiedBy?: string | null;
  isOnvif?: boolean | null;
  videoStreamState?: string | null;
  deviceUrlOnline?: boolean | null;
  vmsConnect?: boolean | null;
  isP2P?: boolean | null;
  isAnalyticsEnabled?: boolean | null;
  isMotionEnabled?: boolean | null;
  testEventReceived?: boolean | null;
  healthCheckEnabled?: boolean | null;
  healthCheckSLA?: DeviceHealthCheckSLA | null;
  healthCheckStatus?: HealthCheckStatus | null;
  ignoreStatusChecks?: boolean | null;
  statusChecksProperties?: Array<DeviceStatusProperties> | null;
  statusLastVerified?: string | null;
  archiveDurationHours?: number | null;
  accessUrlsId?: string | null;
  credentialsId?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  vmsPlanId?: number | null;
  phpRegistrationCode?: string | null;
  serialNumber?: string | null;
  p2pState?: string | null;
  lastPowerCycle?: string | null;
  baselineThumbnailFilename?: string | null;
  currentThumbnailFilename?: string | null;
  sceneChangeDetectionEnabled?: boolean | null;
  passingSceneDetection?: boolean | null;
  hubspotId?: string | null;
  immixMonitoring?: boolean | null;
  hasMic?: boolean | null;
  hasSpeaker?: boolean | null;
  audioGroups?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  qualitySettings?: {
    __typename: "CameraQualitySettings";
    id: string;
    width: number;
    height: number;
    videoBitRate: number;
    frameRate: number;
    monitoringCenterId: string;
    cameraId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateObjectGroupSubscription = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateObjectGroupSubscription = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteObjectGroupSubscription = {
  __typename: "ObjectGroup";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  groupType: ObjectGroupType;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAudioCustomMessageTypeSubscription = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAudioCustomMessageTypeSubscription = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAudioCustomMessageTypeSubscription = {
  __typename: "AudioCustomMessageType";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId: string;
  name: string;
  nameTranslated?: string | null;
  nameSanitized?: string | null;
  transcript: string;
  transcriptTranslated?: string | null;
  transcriptSanitized?: string | null;
  note?: string | null;
  noteTranslated?: string | null;
  noteSanitized?: string | null;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraQualitySettingsSubscription = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCameraQualitySettingsSubscription = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCameraQualitySettingsSubscription = {
  __typename: "CameraQualitySettings";
  id: string;
  width: number;
  height: number;
  videoBitRate: number;
  frameRate: number;
  monitoringCenterId: string;
  cameraId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraSceneDetectionSettingsSubscription = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateCameraSceneDetectionSettingsSubscription = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteCameraSceneDetectionSettingsSubscription = {
  __typename: "CameraSceneDetectionSettings";
  id: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  thresholds?: {
    __typename: "ModelCameraSceneDetectionThresholdConnection";
    items: Array<{
      __typename: "CameraSceneDetectionThreshold";
      id: string;
      cameraId: string;
      cameraSceneDetectionSettingsId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      archived?: boolean | null;
      objectName: string;
      baselineValue: number;
      thresholdValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateCameraSceneDetectionThresholdSubscription = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCameraSceneDetectionThresholdSubscription = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCameraSceneDetectionThresholdSubscription = {
  __typename: "CameraSceneDetectionThreshold";
  id: string;
  cameraId: string;
  cameraSceneDetectionSettingsId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  objectName: string;
  baselineValue: number;
  thresholdValue: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraSceneDetectionResultSubscription = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateCameraSceneDetectionResultSubscription = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteCameraSceneDetectionResultSubscription = {
  __typename: "CameraSceneDetectionResult";
  id: string;
  cameraId: string;
  passing: boolean;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  items?: {
    __typename: "ModelCameraSceneDetectionResultItemConnection";
    items: Array<{
      __typename: "CameraSceneDetectionResultItem";
      id: string;
      cameraSceneDetectionResultId: string;
      cameraId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      objectName: string;
      thresholdValue: number;
      actualValue: number;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateCameraSceneDetectionResultItemSubscription = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCameraSceneDetectionResultItemSubscription = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCameraSceneDetectionResultItemSubscription = {
  __typename: "CameraSceneDetectionResultItem";
  id: string;
  cameraSceneDetectionResultId: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  objectName: string;
  thresholdValue: number;
  actualValue: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraCredentialsSubscription = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCameraCredentialsSubscription = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCameraCredentialsSubscription = {
  __typename: "CameraCredentials";
  id: string;
  name: string;
  password: string;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraAccessUrlsSubscription = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCameraAccessUrlsSubscription = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCameraAccessUrlsSubscription = {
  __typename: "CameraAccessUrls";
  id: string;
  dashStream?: string | null;
  hlsStream?: string | null;
  http?: string | null;
  rtmpStream?: string | null;
  rtsp?: string | null;
  snapShot?: string | null;
  webRtc?: string | null;
  cameraId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCameraImmixConfigSubscription = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCameraImmixConfigSubscription = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCameraImmixConfigSubscription = {
  __typename: "CameraImmixConfig";
  id: string;
  cameraId: string;
  vmsCameraId: string;
  vmsWebhookId?: string | null;
  immixEmail: string;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateActivityLogSubscription = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type OnUpdateActivityLogSubscription = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type OnDeleteActivityLogSubscription = {
  __typename: "ActivityLog";
  id: string;
  monitoringCenterId: string;
  integratorId: string;
  customerId: string;
  siteId?: string | null;
  cameraId?: string | null;
  modifiedOn: string;
  modifiedBy?: string | null;
  type: ActivityLogType;
  subSystem: ActivityLogSubSystem;
  event: ActivityLogEvent;
  reference?: string | null;
  description?: string | null;
  updatedAt: string;
};

export type OnCreateSignalTestLogSubscription = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type OnUpdateSignalTestLogSubscription = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type OnDeleteSignalTestLogSubscription = {
  __typename: "SignalTestLog";
  id: string;
  cameraId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  vmsEventId: string;
  objectsFound: Array<{
    __typename: "CameraEventObject";
    object: CameraEventObjectType;
    confidence: number;
  } | null>;
  starred: ActiveInactive;
  thumbnailFilename: string;
  updatedAt: string;
};

export type OnCreateAuditLogSubscription = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type OnUpdateAuditLogSubscription = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type OnDeleteAuditLogSubscription = {
  __typename: "AuditLog";
  id: string;
  partition: string;
  monitoringCenterId: string;
  modifiedOn: string;
  modifiedBy?: string | null;
  area: AuditLogArea;
  action: AuditLogAction;
  entityModel?: string | null;
  entityKey?: string | null;
  description?: string | null;
  newImage?: string | null;
  oldImage?: string | null;
  updatedAt: string;
};

export type OnCreateIncidentActionDetailSubscription = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type OnUpdateIncidentActionDetailSubscription = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type OnDeleteIncidentActionDetailSubscription = {
  __typename: "IncidentActionDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  createdAt: string;
  modifiedBy: string;
  description?: string | null;
  updatedAt: string;
};

export type OnCreateSOPSubscription = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSOPSubscription = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSOPSubscription = {
  __typename: "SOP";
  id: string;
  siteId: string;
  eventType: EventType;
  eventSOPs: Array<EventOp>;
  monitoringCenterId: string;
  modifiedBy?: string | null;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRspndrEventsSubscription = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRspndrEventsSubscription = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRspndrEventsSubscription = {
  __typename: "RspndrEvents";
  id: string;
  request_id: string;
  tenant_id?: string | null;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRspndrIncidentSubscription = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateRspndrIncidentSubscription = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteRspndrIncidentSubscription = {
  __typename: "RspndrIncident";
  id: string;
  incident_id: string;
  address1: string;
  address2?: string | null;
  arrived_at?: string | null;
  billed_to?: RspndrBilledTo | null;
  city: string;
  country?: string | null;
  created_at?: string | null;
  customer_number?: string | null;
  dealer?: string | null;
  eta_at?: string | null;
  ext_username?: string | null;
  finished_at?: string | null;
  grounded_at?: string | null;
  grounding_end_confirm_acked_at?: string | null;
  grounding_end_confirmed_at?: string | null;
  grounding_end_decline_acked_at?: string | null;
  grounding_end_declined_at?: string | null;
  grounding_ended_at?: string | null;
  grounding_ending_at?: string | null;
  grounding_request_confirm_acked_at?: string | null;
  grounding_request_confirmed_at?: string | null;
  grounding_request_decline_acked_at?: string | null;
  grounding_request_declined_at?: string | null;
  grounding_requested_at?: string | null;
  grounding_terminate_acked_at?: string | null;
  grounding_terminating_at?: string | null;
  kind?: RspndrServiceKind | null;
  lat?: number | null;
  lng?: number | null;
  master_account_number?: string | null;
  modified_at?: string | null;
  name?: string | null;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  postal_code: string;
  state: string;
  status?: RspndrIncidentStatus | null;
  taken_at?: string | null;
  tenant_id: string;
  timezone?: string | null;
  triggered_at?: string | null;
  type?: string | null;
  zone?: string | null;
  monitoringCenterId: string;
  customerId: string;
  integratorId: string;
  siteId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  check_list?: {
    __typename: "ModelRspndrIncidentCheckItemConnection";
    items: Array<{
      __typename: "RspndrIncidentCheckItem";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      description?: string | null;
      descriptionTranslated?: string | null;
      descriptionSanitized?: string | null;
      image_required?: boolean | null;
      image_url?: string | null;
      order?: number | null;
      required?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  rules?: {
    __typename: "ModelRspndrIncidentGroundingRuleConnection";
    items: Array<{
      __typename: "RspndrIncidentGroundingRule";
      id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      call_police?: boolean | null;
      expires_at?: string | null;
      leave_when?: RspndrLeaveWhen | null;
      max_duration?: string | null;
      order?: number | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRspndrActivityConnection";
    items: Array<{
      __typename: "RspndrActivity";
      id: string;
      tenant_id?: string | null;
      request_id: string;
      incidentId: string;
      rspndrIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      type: RspndrIncidentStatus;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateRspndrIncidentCheckItemSubscription = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRspndrIncidentCheckItemSubscription = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRspndrIncidentCheckItemSubscription = {
  __typename: "RspndrIncidentCheckItem";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  description?: string | null;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  image_required?: boolean | null;
  image_url?: string | null;
  order?: number | null;
  required?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRspndrIncidentGroundingRuleSubscription = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRspndrIncidentGroundingRuleSubscription = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRspndrIncidentGroundingRuleSubscription = {
  __typename: "RspndrIncidentGroundingRule";
  id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  call_police?: boolean | null;
  expires_at?: string | null;
  leave_when?: RspndrLeaveWhen | null;
  max_duration?: string | null;
  order?: number | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRspndrActivitySubscription = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type OnUpdateRspndrActivitySubscription = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type OnDeleteRspndrActivitySubscription = {
  __typename: "RspndrActivity";
  id: string;
  tenant_id?: string | null;
  request_id: string;
  incidentId: string;
  rspndrIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  type: RspndrIncidentStatus;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
};

export type OnCreateTrackTikIncidentSubscription = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateTrackTikIncidentSubscription = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteTrackTikIncidentSubscription = {
  __typename: "TrackTikIncident";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  status: TrackTikIncidentStatus;
  reportTemplateId: number;
  taskTypeId: number;
  taskTypeName: string;
  priority: TrackTikPriority;
  trackTikId: number;
  primaryCameraEventId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  activities?: {
    __typename: "ModelTrackTikActivityConnection";
    items: Array<{
      __typename: "TrackTikActivity";
      id: string;
      incidentId: string;
      trackTikIncidentId: string;
      monitoringCenterId: string;
      notes?: string | null;
      payload?: string | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateTrackTikActivitySubscription = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTrackTikActivitySubscription = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTrackTikActivitySubscription = {
  __typename: "TrackTikActivity";
  id: string;
  incidentId: string;
  trackTikIncidentId: string;
  monitoringCenterId: string;
  notes?: string | null;
  payload?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRapidSOSIncidentSubscription = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateRapidSOSIncidentSubscription = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteRapidSOSIncidentSubscription = {
  __typename: "RapidSOSIncident";
  id: string;
  coverageType: RapidSOSCoverageType;
  alert_id?: string | null;
  covering_psap?: string | null;
  callflow: string;
  zone_description?: string | null;
  alarm_permit_number?: string | null;
  visually_verified: boolean;
  site_phone?: string | null;
  status: RapidSOSIncidentStatus;
  instructions?: string | null;
  createdAt: string;
  modifiedBy: string;
  primaryCameraEventId: string;
  siteIntegrationId: string;
  incidentId: string;
  siteId: string;
  customerId: string;
  monitoringCenterId: string;
  updatedAt: string;
  event: {
    __typename: "RapidSOSEventDetails";
    id: string;
    rapidSOSIncidentId: string;
    description: string;
    descriptionTranslated?: string | null;
    descriptionSanitized?: string | null;
    emergency_type: RapidSOSEmergencyType;
    incident_time?: string | null;
    service_provider_name?: string | null;
    site_type?: SiteType | null;
    source_id: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    location: {
      __typename: "RapidSOSLocation";
      id: string;
      rapidSOSIncidentId: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
      civic?: {
        __typename: "RapidSOSCivicAddress";
        id: string;
        rapidSOSIncidentId: string;
        name?: string | null;
        street_1: string;
        street_2?: string | null;
        city: string;
        state: string;
        country: string;
        zip_code: string;
        modifiedBy: string;
        monitoringCenterId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    };
  };
  emergency_contacts?: {
    __typename: "ModelRapidSOSEmergencyContactConnection";
    items: Array<{
      __typename: "RapidSOSEmergencyContact";
      id: string;
      rapidSOSIncidentId: string;
      name: string;
      phone: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  activities?: {
    __typename: "ModelRapidSOSActivityConnection";
    items: Array<{
      __typename: "RapidSOSActivity";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      error?: string | null;
      payload?: string | null;
      modifiedBy?: string | null;
      createdAt: string;
      notes?: string | null;
      notesTranslated?: string | null;
      notesSanitized?: string | null;
      updatedAt: string;
      logMessage?: {
        __typename: "RapidSOSLogMessage";
        id: string;
        incidentId: string;
        siteIntegrationId: string;
        rapidSOSIncidentId: string;
        monitoringCenterId: string;
        type: RapidSOSLogMessageType;
        modifiedBy: string;
        writtenBy: string;
        message?: string | null;
        messageTranslated?: string | null;
        messageSanitized?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  attachments?: {
    __typename: "ModelRapidSOSAttachmentConnection";
    items: Array<{
      __typename: "RapidSOSAttachment";
      id: string;
      incidentId: string;
      siteIntegrationId: string;
      rapidSOSIncidentId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  additionalDataToSend?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateRapidSOSEventDetailsSubscription = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type OnUpdateRapidSOSEventDetailsSubscription = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type OnDeleteRapidSOSEventDetailsSubscription = {
  __typename: "RapidSOSEventDetails";
  id: string;
  rapidSOSIncidentId: string;
  description: string;
  descriptionTranslated?: string | null;
  descriptionSanitized?: string | null;
  emergency_type: RapidSOSEmergencyType;
  incident_time?: string | null;
  service_provider_name?: string | null;
  site_type?: SiteType | null;
  source_id: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  location: {
    __typename: "RapidSOSLocation";
    id: string;
    rapidSOSIncidentId: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
    civic?: {
      __typename: "RapidSOSCivicAddress";
      id: string;
      rapidSOSIncidentId: string;
      name?: string | null;
      street_1: string;
      street_2?: string | null;
      city: string;
      state: string;
      country: string;
      zip_code: string;
      modifiedBy: string;
      monitoringCenterId: string;
      createdAt: string;
      updatedAt: string;
    } | null;
  };
};

export type OnCreateRapidSOSLocationSubscription = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateRapidSOSLocationSubscription = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteRapidSOSLocationSubscription = {
  __typename: "RapidSOSLocation";
  id: string;
  rapidSOSIncidentId: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
  civic?: {
    __typename: "RapidSOSCivicAddress";
    id: string;
    rapidSOSIncidentId: string;
    name?: string | null;
    street_1: string;
    street_2?: string | null;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    modifiedBy: string;
    monitoringCenterId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateRapidSOSCivicAddressSubscription = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRapidSOSCivicAddressSubscription = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRapidSOSCivicAddressSubscription = {
  __typename: "RapidSOSCivicAddress";
  id: string;
  rapidSOSIncidentId: string;
  name?: string | null;
  street_1: string;
  street_2?: string | null;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRapidSOSEmergencyContactSubscription = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRapidSOSEmergencyContactSubscription = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRapidSOSEmergencyContactSubscription = {
  __typename: "RapidSOSEmergencyContact";
  id: string;
  rapidSOSIncidentId: string;
  name: string;
  phone: string;
  modifiedBy: string;
  monitoringCenterId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRapidSOSActivitySubscription = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateRapidSOSActivitySubscription = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteRapidSOSActivitySubscription = {
  __typename: "RapidSOSActivity";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  error?: string | null;
  payload?: string | null;
  modifiedBy?: string | null;
  createdAt: string;
  notes?: string | null;
  notesTranslated?: string | null;
  notesSanitized?: string | null;
  updatedAt: string;
  logMessage?: {
    __typename: "RapidSOSLogMessage";
    id: string;
    incidentId: string;
    siteIntegrationId: string;
    rapidSOSIncidentId: string;
    monitoringCenterId: string;
    type: RapidSOSLogMessageType;
    modifiedBy: string;
    writtenBy: string;
    message?: string | null;
    messageTranslated?: string | null;
    messageSanitized?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateRapidSOSLogMessageSubscription = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRapidSOSLogMessageSubscription = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRapidSOSLogMessageSubscription = {
  __typename: "RapidSOSLogMessage";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  type: RapidSOSLogMessageType;
  modifiedBy: string;
  writtenBy: string;
  message?: string | null;
  messageTranslated?: string | null;
  messageSanitized?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRapidSOSAttachmentSubscription = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRapidSOSAttachmentSubscription = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRapidSOSAttachmentSubscription = {
  __typename: "RapidSOSAttachment";
  id: string;
  incidentId: string;
  siteIntegrationId: string;
  rapidSOSIncidentId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentCodeDetailSubscription = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateIncidentCodeDetailSubscription = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteIncidentCodeDetailSubscription = {
  __typename: "IncidentCodeDetail";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  publicCode?: string | null;
  shareUrl?: string | null;
  locationSid: string;
  deviceSid: string;
  eventDate?: string | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateIncidentShareSettingsSubscription = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateIncidentShareSettingsSubscription = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteIncidentShareSettingsSubscription = {
  __typename: "IncidentShareSettings";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  code: string;
  modifiedBy: string;
  expiration: string;
  createdAt: string;
  updatedAt: string;
  incidentDataToShare?: {
    __typename: "IncidentDataToShare";
    id: string;
    incidentId: string;
    monitoringCenterId: string;
    shareType?: IncidentShareType | null;
    externalIncidentNotes?: boolean | null;
    externalSiteCriticalNotes?: boolean | null;
    externalClientCriticalNotes?: boolean | null;
    completedActionItems?: boolean | null;
    objectsFound?: boolean | null;
    alarmStationInfo?: boolean | null;
    customerContactInfo?: boolean | null;
    videoFeedUrls?: boolean | null;
    modifiedBy: string;
    attachmentsList?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateIncidentDataToShareSubscription = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentDataToShareSubscription = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentDataToShareSubscription = {
  __typename: "IncidentDataToShare";
  id: string;
  incidentId: string;
  monitoringCenterId: string;
  shareType?: IncidentShareType | null;
  externalIncidentNotes?: boolean | null;
  externalSiteCriticalNotes?: boolean | null;
  externalClientCriticalNotes?: boolean | null;
  completedActionItems?: boolean | null;
  objectsFound?: boolean | null;
  alarmStationInfo?: boolean | null;
  customerContactInfo?: boolean | null;
  videoFeedUrls?: boolean | null;
  modifiedBy: string;
  attachmentsList?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentShareEntrySubscription = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateIncidentShareEntrySubscription = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteIncidentShareEntrySubscription = {
  __typename: "IncidentShareEntry";
  id: string;
  incidentDataToShareId?: string | null;
  incidentId: string;
  code: string;
  shareType: IncidentShareType;
  expiration: string;
  alarmsCentralStation?: string | null;
  alarmsPermitNum?: string | null;
  alarmsCSID?: string | null;
  attachmentsList?: Array<string> | null;
  incidentOpenTime?: string | null;
  incidentClosedTime?: string | null;
  externalSiteNotes?: string | null;
  externalClientNotes?: string | null;
  createdAt: string;
  updatedAt: string;
  site?: {
    __typename: "IncidentShareSite";
    id: string;
    name: string;
    addressOne: string;
    addressTwo?: string | null;
    city: string;
    state?: string | null;
    country: string;
    zipCode: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  integrator?: {
    __typename: "IncidentShareIntegrator";
    id: string;
    name: string;
    logoPath?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  notes?: {
    __typename: "ModelIncidentShareEntyNoteConnection";
    items: Array<{
      __typename: "IncidentShareEntyNote";
      id: string;
      incidentId?: string | null;
      incidentNoteId?: string | null;
      incidentShareEntryId: string;
      note: string;
      noteCreatedAt: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  events?: {
    __typename: "ModelIncidentShareEventConnection";
    items: Array<{
      __typename: "IncidentShareEvent";
      id: string;
      cameraEventId?: string | null;
      startTime: string;
      vmsCameraName?: string | null;
      videoFeedUrl?: string | null;
      eventType: EventType;
      disposition: Disposition;
      triggerType?: CameraEventTriggerType | null;
      objectsFound?: Array<CameraEventObjectType> | null;
      incidentShareEntryId: string;
      cameraLatitude?: number | null;
      cameraLongitude?: number | null;
      createdAt: string;
      updatedAt: string;
      actionItems?: {
        __typename: "ModelIncidentShareActionItemsConnection";
        items: Array<{
          __typename: "IncidentShareActionItems";
          id: string;
          incidentProcedureTaskId?: string | null;
          incidentShareEventId: string;
          order: number;
          completedAt?: string | null;
          description: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  contactInformation?: {
    __typename: "ModelIncidentShareContactInformationConnection";
    items: Array<{
      __typename: "IncidentShareContactInformation";
      id: string;
      incidentShareEntryId: string;
      name: string;
      phone: string;
      ext?: string | null;
      email?: string | null;
      type: IncidentShareContactType;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateIncidentShareSiteSubscription = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentShareSiteSubscription = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentShareSiteSubscription = {
  __typename: "IncidentShareSite";
  id: string;
  name: string;
  addressOne: string;
  addressTwo?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zipCode: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentShareIntegratorSubscription = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentShareIntegratorSubscription = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentShareIntegratorSubscription = {
  __typename: "IncidentShareIntegrator";
  id: string;
  name: string;
  logoPath?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentShareEntryAccessSubscription = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentShareEntryAccessSubscription = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentShareEntryAccessSubscription = {
  __typename: "IncidentShareEntryAccess";
  id: string;
  incidentShareEntryId?: string | null;
  incidentId?: string | null;
  code: string;
  success: boolean;
  accessDate: string;
  ipAddress: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentShareEntyNoteSubscription = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentShareEntyNoteSubscription = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentShareEntyNoteSubscription = {
  __typename: "IncidentShareEntyNote";
  id: string;
  incidentId?: string | null;
  incidentNoteId?: string | null;
  incidentShareEntryId: string;
  note: string;
  noteCreatedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentShareEventSubscription = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateIncidentShareEventSubscription = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteIncidentShareEventSubscription = {
  __typename: "IncidentShareEvent";
  id: string;
  cameraEventId?: string | null;
  startTime: string;
  vmsCameraName?: string | null;
  videoFeedUrl?: string | null;
  eventType: EventType;
  disposition: Disposition;
  triggerType?: CameraEventTriggerType | null;
  objectsFound?: Array<CameraEventObjectType> | null;
  incidentShareEntryId: string;
  cameraLatitude?: number | null;
  cameraLongitude?: number | null;
  createdAt: string;
  updatedAt: string;
  actionItems?: {
    __typename: "ModelIncidentShareActionItemsConnection";
    items: Array<{
      __typename: "IncidentShareActionItems";
      id: string;
      incidentProcedureTaskId?: string | null;
      incidentShareEventId: string;
      order: number;
      completedAt?: string | null;
      description: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateIncidentShareActionItemsSubscription = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentShareActionItemsSubscription = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentShareActionItemsSubscription = {
  __typename: "IncidentShareActionItems";
  id: string;
  incidentProcedureTaskId?: string | null;
  incidentShareEventId: string;
  order: number;
  completedAt?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIncidentShareContactInformationSubscription = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIncidentShareContactInformationSubscription = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIncidentShareContactInformationSubscription = {
  __typename: "IncidentShareContactInformation";
  id: string;
  incidentShareEntryId: string;
  name: string;
  phone: string;
  ext?: string | null;
  email?: string | null;
  type: IncidentShareContactType;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCallNoticeSubscription = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type OnUpdateCallNoticeSubscription = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type OnDeleteCallNoticeSubscription = {
  __typename: "CallNotice";
  id: string;
  fromNumber: string;
  toNumber: string;
  monitoringCenterId: string;
  createdAt: string;
  answeredTime?: string | null;
  answeredBy?: string | null;
  twilioCallSid: string;
  modifiedBy: string;
  updatedAt: string;
};

export type OnCreateCallLogSubscription = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type OnUpdateCallLogSubscription = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type OnDeleteCallLogSubscription = {
  __typename: "CallLog";
  id: string;
  phoneNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  callEnd?: string | null;
  storageAudioUrl?: string | null;
  storageTranscriptUrl?: string | null;
  createdAt: string;
  callingUser?: string | null;
  modifiedBy?: string | null;
  updatedAt: string;
};

export type OnCreateSMSLogSubscription = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type OnUpdateSMSLogSubscription = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type OnDeleteSMSLogSubscription = {
  __typename: "SMSLog";
  id: string;
  twilioSid?: string | null;
  toNumber: string;
  fromNumber: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  message?: string | null;
  createdAt: string;
  textingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type OnCreateEmailLogSubscription = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type OnUpdateEmailLogSubscription = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type OnDeleteEmailLogSubscription = {
  __typename: "EmailLog";
  id: string;
  awsMessageId?: string | null;
  toEmail: string;
  fromEmail: string;
  monitoringCenterId: string;
  customerId?: string | null;
  siteId?: string | null;
  incidentId?: string | null;
  subject?: string | null;
  createdAt: string;
  sendingUser?: string | null;
  modifiedBy: string;
  updatedAt: string;
};

export type OnCreateNotificationSettingSubscription = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateNotificationSettingSubscription = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteNotificationSettingSubscription = {
  __typename: "NotificationSetting";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  userGroup: string;
  clientList?: Array<string | null> | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateNotificationSubscription = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type OnUpdateNotificationSubscription = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type OnDeleteNotificationSubscription = {
  __typename: "Notification";
  id: string;
  username: string;
  monitoringCenterId: string;
  notificationType: NotificationType;
  status?: NotificationStatus | null;
  createdAt: string;
  modifiedBy: string;
  url: string;
  note?: string | null;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  username: string;
  monitoringCenterId: string;
  vmsOperatorUserIds?: Array<string> | null;
  vmsOperatorUserIdAutomatedRole?: Array<string> | null;
  vmsAdminUserIds?: Array<string> | null;
  modifiedBy: string;
  group?: UserGroup | null;
  clientIdList?: Array<string> | null;
  enabled?: boolean | null;
  phone: string;
  email: string;
  name: string;
  integratorId?: string | null;
  autoAssign?: boolean | null;
  preferredLanguage?: Language | null;
  gridEnabled?: boolean | null;
  gridBehavior?: GridBehavior | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserVMSDetailsSubscription = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserVMSDetailsSubscription = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserVMSDetailsSubscription = {
  __typename: "UserVMSDetails";
  id: string;
  vmsUserId: string;
  profileName: string;
  cognitoUsernames?: Array<string> | null;
  authEmail: string;
  vmsUsername: string;
  vmsPassword: string;
  vmsUserType: VMSUserType;
  apiKey: string;
  accessToken?: string | null;
  refreshToken?: string | null;
  accessTokenExpires?: string | null;
  refreshTokenExpires?: string | null;
  monitoringCenterId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomerUserPermissionSetSubscription = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateCustomerUserPermissionSetSubscription = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteCustomerUserPermissionSetSubscription = {
  __typename: "CustomerUserPermissionSet";
  username: string;
  email: string;
  enabled: boolean;
  modifiedBy: string;
  ownerCustomerId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: {
    __typename: "ModelCustomerUserPermissionConnection";
    items: Array<{
      __typename: "CustomerUserPermission";
      username: string;
      email: string;
      monitoringCenterId: string;
      customerId: string;
      sitePermissions: string;
      archived?: boolean | null;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      permissionSet?: {
        __typename: "CustomerUserPermissionSet";
        username: string;
        email: string;
        enabled: boolean;
        modifiedBy: string;
        ownerCustomerId: string;
        createdAt: string;
        updatedAt: string;
        permissions?: {
          __typename: "ModelCustomerUserPermissionConnection";
          items: Array<{
            __typename: "CustomerUserPermission";
            username: string;
            email: string;
            monitoringCenterId: string;
            customerId: string;
            sitePermissions: string;
            archived?: boolean | null;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            permissionSet?: {
              __typename: "CustomerUserPermissionSet";
              username: string;
              email: string;
              enabled: boolean;
              modifiedBy: string;
              ownerCustomerId: string;
              createdAt: string;
              updatedAt: string;
            } | null;
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateCustomerUserPermissionSubscription = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateCustomerUserPermissionSubscription = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteCustomerUserPermissionSubscription = {
  __typename: "CustomerUserPermission";
  username: string;
  email: string;
  monitoringCenterId: string;
  customerId: string;
  sitePermissions: string;
  archived?: boolean | null;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  permissionSet?: {
    __typename: "CustomerUserPermissionSet";
    username: string;
    email: string;
    enabled: boolean;
    modifiedBy: string;
    ownerCustomerId: string;
    createdAt: string;
    updatedAt: string;
    permissions?: {
      __typename: "ModelCustomerUserPermissionConnection";
      items: Array<{
        __typename: "CustomerUserPermission";
        username: string;
        email: string;
        monitoringCenterId: string;
        customerId: string;
        sitePermissions: string;
        archived?: boolean | null;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        permissionSet?: {
          __typename: "CustomerUserPermissionSet";
          username: string;
          email: string;
          enabled: boolean;
          modifiedBy: string;
          ownerCustomerId: string;
          createdAt: string;
          updatedAt: string;
          permissions?: {
            __typename: "ModelCustomerUserPermissionConnection";
            items: Array<{
              __typename: "CustomerUserPermission";
              username: string;
              email: string;
              monitoringCenterId: string;
              customerId: string;
              sitePermissions: string;
              archived?: boolean | null;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateTaxSubscription = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateTaxSubscription = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteTaxSubscription = {
  __typename: "Tax";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  region: string;
  subregion?: string | null;
  archived?: boolean | null;
  amount: number;
  name: string;
  taxGovId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxTypes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateTaxesTaxTypeSubscription = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: {
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  integratorId?: string | null;
  taxType: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type OnUpdateTaxesTaxTypeSubscription = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: {
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  integratorId?: string | null;
  taxType: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type OnDeleteTaxesTaxTypeSubscription = {
  __typename: "TaxesTaxType";
  id: string;
  taxId: string;
  taxTypeId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  tax: {
    __typename: "Tax";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    region: string;
    subregion?: string | null;
    archived?: boolean | null;
    amount: number;
    name: string;
    taxGovId: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxTypes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  integratorId?: string | null;
  taxType: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  };
};

export type OnCreateTaxTypeSubscription = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateTaxTypeSubscription = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteTaxTypeSubscription = {
  __typename: "TaxType";
  id: string;
  monitoringCenterId: string;
  mcIntAdminAccess: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId?: string | null;
  archived?: boolean | null;
  name: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTaxesTaxTypeConnection";
    items: Array<{
      __typename: "TaxesTaxType";
      id: string;
      taxId: string;
      taxTypeId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      tax: {
        __typename: "Tax";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        region: string;
        subregion?: string | null;
        archived?: boolean | null;
        amount: number;
        name: string;
        taxGovId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxTypes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
      integratorId?: string | null;
      taxType: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateSetupFeeSubscription = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateSetupFeeSubscription = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteSetupFeeSubscription = {
  __typename: "SetupFee";
  id: string;
  name: string;
  taxTypeId: string;
  amount: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateProductSubscription = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateProductSubscription = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteProductSubscription = {
  __typename: "Product";
  id: string;
  cameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  productTaxTypeId?: string | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  status: ProductStatus;
  type: ProductType;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  publishedFor?: Array<string> | null;
  publishedForArchived?: Array<string> | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateCameraPlanSubscription = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnUpdateCameraPlanSubscription = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnDeleteCameraPlanSubscription = {
  __typename: "CameraPlan";
  id: string;
  parentId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  cameraPlanProductId: string;
  cameraPlanLivePatrolId?: string | null;
  cameraPlanOperatorMinutesId?: string | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    cameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    productTaxTypeId?: string | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    status: ProductStatus;
    type: ProductType;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    publishedFor?: Array<string> | null;
    publishedForArchived?: Array<string> | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "LivePatrol";
    id: string;
    cameraPlanId: string;
    enabled?: boolean | null;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "OperatorMinutes";
    id: string;
    cameraPlanId: string;
    minPrice: number;
    mrp: number;
    cost: number;
    price: number;
    fxPrices?: Array<{
      __typename: "FXPrice";
      currency?: Currency | null;
      price: number;
    }> | null;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnCreateLivePatrolSubscription = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateLivePatrolSubscription = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteLivePatrolSubscription = {
  __typename: "LivePatrol";
  id: string;
  cameraPlanId: string;
  enabled?: boolean | null;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateOperatorMinutesSubscription = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOperatorMinutesSubscription = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOperatorMinutesSubscription = {
  __typename: "OperatorMinutes";
  id: string;
  cameraPlanId: string;
  minPrice: number;
  mrp: number;
  cost: number;
  price: number;
  fxPrices?: Array<{
    __typename: "FXPrice";
    currency?: Currency | null;
    price: number;
  }> | null;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSiteSubscriptionSubscription = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateSiteSubscriptionSubscription = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteSiteSubscriptionSubscription = {
  __typename: "SiteSubscription";
  id: string;
  siteId: string;
  customerId: string;
  status: ActiveInactive;
  onHold?: boolean | null;
  startDate: string;
  term: number;
  renewalDate: string;
  renewalTerm: number;
  merchant?: SiteSubscriptionMerchant | null;
  billingDay: number;
  billingFrequency: BillingFrequency;
  nextBillingDay: string;
  autorefillOn: boolean;
  autorefillAmount?: number | null;
  minimumBalance?: number | null;
  currentBalance: number;
  currentBalanceUsd?: number | null;
  maxNumberRecharges?: number | null;
  currentDayRechargeCount?: number | null;
  lastRefilled?: string | null;
  currency: Currency;
  usedOperatorMinutes?: number | null;
  totalOperatorMinutes?: number | null;
  transactionsLastCalculated?: string | null;
  secondsPerInteraction?: number | null;
  incidentBillingDisabled?: boolean | null;
  interactionBillingDisabled?: boolean | null;
  livePatrolBillingDisabled?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  mcIntAdminAccess?: string | null;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  billingEmails?: Array<string> | null;
  billingEntityName?: string | null;
  commissionBilling?: boolean | null;
  createdAt?: string | null;
  updatedAt: string;
  stripeSubscription?: {
    __typename: "SubscriptionStripeDetail";
    id: string;
    archived?: boolean | null;
    modifiedBy: string;
    siteSubscriptionId: string;
    siteId: string;
    customerId: string;
    integratorId: string;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    createdAt: string;
    updatedAt: string;
    paymentMethods?: {
      __typename: "ModelStripePaymentMethodConnection";
      items: Array<{
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  cameraPlans?: {
    __typename: "ModelAppliedCameraPlanConnection";
    items: Array<{
      __typename: "AppliedCameraPlan";
      id: string;
      parentId: string;
      cameraId: string;
      subscriptionId: string;
      analyticsType?: Array<AnalyticType> | null;
      resolution: string;
      bitRate: number;
      operatorDuration: number;
      supervision?: DeviceHealthCheckSLA | null;
      storageDays: number;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      product: {
        __typename: "AppliedProduct";
        id: string;
        appliedCameraPlanId: string;
        name: string;
        nameForInvoice?: string | null;
        description?: string | null;
        appliedProductTaxTypeId?: string | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
        taxType?: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        } | null;
      };
      livePatrol: {
        __typename: "AppliedLivePatrol";
        id: string;
        appliedCameraPlanId: string;
        enabled?: boolean | null;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
      operatorMinutes: {
        __typename: "AppliedOperatorMinutes";
        id: string;
        appliedCameraPlanId: string;
        price: number;
        currency: Currency;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      };
    } | null>;
    nextToken?: string | null;
  } | null;
  setupFees?: {
    __typename: "ModelAppliedSetupFeeConnection";
    items: Array<{
      __typename: "AppliedSetupFee";
      id: string;
      parentId: string;
      subscriptionId: string;
      amount?: number | null;
      name: string;
      currency: Currency;
      integratorId: string;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
      taxType?: {
        __typename: "TaxType";
        id: string;
        monitoringCenterId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        integratorId?: string | null;
        archived?: boolean | null;
        name: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        taxes?: {
          __typename: "ModelTaxesTaxTypeConnection";
          items: Array<{
            __typename: "TaxesTaxType";
            id: string;
            taxId: string;
            taxTypeId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            monitoringCenterId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            tax: {
              __typename: "Tax";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              region: string;
              subregion?: string | null;
              archived?: boolean | null;
              amount: number;
              name: string;
              taxGovId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
            integratorId?: string | null;
            taxType: {
              __typename: "TaxType";
              id: string;
              monitoringCenterId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              integratorId?: string | null;
              archived?: boolean | null;
              name: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
            };
          } | null>;
          nextToken?: string | null;
        } | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateSubscriptionStripeDetailSubscription = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateSubscriptionStripeDetailSubscription = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteSubscriptionStripeDetailSubscription = {
  __typename: "SubscriptionStripeDetail";
  id: string;
  archived?: boolean | null;
  modifiedBy: string;
  siteSubscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  createdAt: string;
  updatedAt: string;
  paymentMethods?: {
    __typename: "ModelStripePaymentMethodConnection";
    items: Array<{
      __typename: "StripePaymentMethod";
      id: string;
      subscriptionStripeDetailId: string;
      stripePaymentId: string;
      stripePaymentStatus?: StripePaymentStatus | null;
      type: StripePaymentType;
      name: string;
      identifier: string;
      preferredPayment?: boolean | null;
      monitoringCenterId: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      mcBillingAdminAccess: string;
      integratorId: string;
      modifiedBy: string;
      archived?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateStripePaymentMethodSubscription = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateStripePaymentMethodSubscription = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteStripePaymentMethodSubscription = {
  __typename: "StripePaymentMethod";
  id: string;
  subscriptionStripeDetailId: string;
  stripePaymentId: string;
  stripePaymentStatus?: StripePaymentStatus | null;
  type: StripePaymentType;
  name: string;
  identifier: string;
  preferredPayment?: boolean | null;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAppliedProductSubscription = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateAppliedProductSubscription = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteAppliedProductSubscription = {
  __typename: "AppliedProduct";
  id: string;
  appliedCameraPlanId: string;
  name: string;
  nameForInvoice?: string | null;
  description?: string | null;
  appliedProductTaxTypeId?: string | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateAppliedCameraPlanSubscription = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnUpdateAppliedCameraPlanSubscription = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnDeleteAppliedCameraPlanSubscription = {
  __typename: "AppliedCameraPlan";
  id: string;
  parentId: string;
  cameraId: string;
  subscriptionId: string;
  analyticsType?: Array<AnalyticType> | null;
  resolution: string;
  bitRate: number;
  operatorDuration: number;
  supervision?: DeviceHealthCheckSLA | null;
  storageDays: number;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "AppliedProduct";
    id: string;
    appliedCameraPlanId: string;
    name: string;
    nameForInvoice?: string | null;
    description?: string | null;
    appliedProductTaxTypeId?: string | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
    taxType?: {
      __typename: "TaxType";
      id: string;
      monitoringCenterId: string;
      mcIntAdminAccess: string;
      mcOwnerAccess: string;
      mcControllerAccess: string;
      integratorId?: string | null;
      archived?: boolean | null;
      name: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTaxesTaxTypeConnection";
        items: Array<{
          __typename: "TaxesTaxType";
          id: string;
          taxId: string;
          taxTypeId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          tax: {
            __typename: "Tax";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            region: string;
            subregion?: string | null;
            archived?: boolean | null;
            amount: number;
            name: string;
            taxGovId: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxTypes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
          integratorId?: string | null;
          taxType: {
            __typename: "TaxType";
            id: string;
            monitoringCenterId: string;
            mcIntAdminAccess: string;
            mcOwnerAccess: string;
            mcControllerAccess: string;
            integratorId?: string | null;
            archived?: boolean | null;
            name: string;
            modifiedBy: string;
            createdAt: string;
            updatedAt: string;
            taxes?: {
              __typename: "ModelTaxesTaxTypeConnection";
              nextToken?: string | null;
            } | null;
          };
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null;
  };
  livePatrol: {
    __typename: "AppliedLivePatrol";
    id: string;
    appliedCameraPlanId: string;
    enabled?: boolean | null;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
  operatorMinutes: {
    __typename: "AppliedOperatorMinutes";
    id: string;
    appliedCameraPlanId: string;
    price: number;
    currency: Currency;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnCreateAppliedLivePatrolSubscription = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAppliedLivePatrolSubscription = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAppliedLivePatrolSubscription = {
  __typename: "AppliedLivePatrol";
  id: string;
  appliedCameraPlanId: string;
  enabled?: boolean | null;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAppliedOperatorMinutesSubscription = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAppliedOperatorMinutesSubscription = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAppliedOperatorMinutesSubscription = {
  __typename: "AppliedOperatorMinutes";
  id: string;
  appliedCameraPlanId: string;
  price: number;
  currency: Currency;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  integratorId: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAppliedSetupFeeSubscription = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdateAppliedSetupFeeSubscription = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeleteAppliedSetupFeeSubscription = {
  __typename: "AppliedSetupFee";
  id: string;
  parentId: string;
  subscriptionId: string;
  amount?: number | null;
  name: string;
  currency: Currency;
  integratorId: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcBillingAdminAccess: string;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  taxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateTransactionLineItemSubscription = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnUpdateTransactionLineItemSubscription = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteTransactionLineItemSubscription = {
  __typename: "TransactionLineItem";
  id: string;
  subscriptionId: string;
  invoiceId?: string | null;
  connectedId: string;
  type: TransactionType;
  description: string;
  currency: Currency;
  pricePer: number;
  totalPrice: number;
  quantity: number;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
  taxes?: {
    __typename: "ModelTransactionLineItemTaxConnection";
    items: Array<{
      __typename: "TransactionLineItemTax";
      id: string;
      transactionLineItemId: string;
      invoiceId?: string | null;
      taxId: string;
      taxGovId: string;
      taxPercent: number;
      taxName: string;
      currency: Currency;
      amount: number;
      subscriptionId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateTransactionLineItemTaxSubscription = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTransactionLineItemTaxSubscription = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTransactionLineItemTaxSubscription = {
  __typename: "TransactionLineItemTax";
  id: string;
  transactionLineItemId: string;
  invoiceId?: string | null;
  taxId: string;
  taxGovId: string;
  taxPercent: number;
  taxName: string;
  currency: Currency;
  amount: number;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateInvoiceSubscription = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateInvoiceSubscription = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteInvoiceSubscription = {
  __typename: "Invoice";
  id?: string | null;
  subtotal: number;
  invoiceNumber: string;
  total: number;
  transactionCurrTotal?: number | null;
  notes?: string | null;
  type: InvoiceType;
  currency: Currency;
  transactionCurr?: Currency | null;
  status: InvoiceStatus;
  merchant: InvoiceMerchant;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  modifiedBy: string;
  createdAt: string;
  transferGroup?: string | null;
  mcOwnerAccess?: string | null;
  mcControllerAccess?: string | null;
  updatedAt: string;
  lineItems?: {
    __typename: "ModelTransactionLineItemConnection";
    items: Array<{
      __typename: "TransactionLineItem";
      id: string;
      subscriptionId: string;
      invoiceId?: string | null;
      connectedId: string;
      type: TransactionType;
      description: string;
      currency: Currency;
      pricePer: number;
      totalPrice: number;
      quantity: number;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      modifiedBy: string;
      createdAt: string;
      updatedAt: string;
      taxes?: {
        __typename: "ModelTransactionLineItemTaxConnection";
        items: Array<{
          __typename: "TransactionLineItemTax";
          id: string;
          transactionLineItemId: string;
          invoiceId?: string | null;
          taxId: string;
          taxGovId: string;
          taxPercent: number;
          taxName: string;
          currency: Currency;
          amount: number;
          subscriptionId: string;
          siteId: string;
          customerId: string;
          integratorId: string;
          monitoringCenterId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  payments?: {
    __typename: "ModelInvoicePaymentConnection";
    items: Array<{
      __typename: "InvoicePayment";
      id: string;
      stripePaymentIntentId?: string | null;
      paymentStatus: StripePaymentStatus;
      paymentType?: PaymentType | null;
      notes?: string | null;
      invoiceId: string;
      siteId: string;
      customerId: string;
      integratorId: string;
      monitoringCenterId: string;
      amountReceived: number;
      amountRequested: number;
      modifiedBy: string;
      createdAt?: string | null;
      updatedAt: string;
      paymentMethod?: {
        __typename: "StripePaymentMethod";
        id: string;
        subscriptionStripeDetailId: string;
        stripePaymentId: string;
        stripePaymentStatus?: StripePaymentStatus | null;
        type: StripePaymentType;
        name: string;
        identifier: string;
        preferredPayment?: boolean | null;
        monitoringCenterId: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        mcBillingAdminAccess: string;
        integratorId: string;
        modifiedBy: string;
        archived?: boolean | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  transferPayment?: {
    __typename: "InvoiceTransferPayment";
    id: string;
    destination?: string | null;
    transferGroup: string;
    stripePaymentIntentId?: string | null;
    currency: string;
    stripeTransferRefId?: string | null;
    amount: number;
    customerId: string;
    monitoringCenterId?: string | null;
    integratorId?: string | null;
    createdAt?: string | null;
    modifiedBy: string;
    status: InvoiceTransferStatus;
    invoiceIds?: Array<string> | null;
    updatedAt: string;
  } | null;
};

export type OnCreateInvoicePaymentSubscription = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateInvoicePaymentSubscription = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteInvoicePaymentSubscription = {
  __typename: "InvoicePayment";
  id: string;
  stripePaymentIntentId?: string | null;
  paymentStatus: StripePaymentStatus;
  paymentType?: PaymentType | null;
  notes?: string | null;
  invoiceId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amountReceived: number;
  amountRequested: number;
  modifiedBy: string;
  createdAt?: string | null;
  updatedAt: string;
  paymentMethod?: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateInvoiceTransferPaymentSubscription = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type OnUpdateInvoiceTransferPaymentSubscription = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type OnDeleteInvoiceTransferPaymentSubscription = {
  __typename: "InvoiceTransferPayment";
  id: string;
  destination?: string | null;
  transferGroup: string;
  stripePaymentIntentId?: string | null;
  currency: string;
  stripeTransferRefId?: string | null;
  amount: number;
  customerId: string;
  monitoringCenterId?: string | null;
  integratorId?: string | null;
  createdAt?: string | null;
  modifiedBy: string;
  status: InvoiceTransferStatus;
  invoiceIds?: Array<string> | null;
  updatedAt: string;
};

export type OnCreateWalletTransferSubscription = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnUpdateWalletTransferSubscription = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnDeleteWalletTransferSubscription = {
  __typename: "WalletTransfer";
  id: string;
  subscriptionId: string;
  siteId: string;
  customerId: string;
  integratorId: string;
  monitoringCenterId: string;
  amount: number;
  currency: Currency;
  conversionRate?: number | null;
  stripePaymentId: string;
  modifiedBy: string;
  type: WalletTransferType;
  createdAt: string;
  updatedAt: string;
  paymentMethod: {
    __typename: "StripePaymentMethod";
    id: string;
    subscriptionStripeDetailId: string;
    stripePaymentId: string;
    stripePaymentStatus?: StripePaymentStatus | null;
    type: StripePaymentType;
    name: string;
    identifier: string;
    preferredPayment?: boolean | null;
    monitoringCenterId: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    mcBillingAdminAccess: string;
    integratorId: string;
    modifiedBy: string;
    archived?: boolean | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type OnCreateStripePayoutDetailsSubscription = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateStripePayoutDetailsSubscription = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteStripePayoutDetailsSubscription = {
  __typename: "StripePayoutDetails";
  stripeAccId: string;
  integratorId: string;
  monitoringCenterId: string;
  chargesEnabled: boolean;
  modifiedBy: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateExchangeRateSettingSubscription = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateExchangeRateSettingSubscription = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteExchangeRateSettingSubscription = {
  __typename: "ExchangeRateSetting";
  country: string;
  modifiedBy: string;
  rate: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePaymentTransferSettingsSubscription = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnUpdatePaymentTransferSettingsSubscription = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnDeletePaymentTransferSettingsSubscription = {
  __typename: "PaymentTransferSettings";
  id: string;
  monitoringCenterId: string;
  mcOwnerAccess: string;
  mcControllerAccess: string;
  mcIntAdminAccess: string;
  type: StripePayoutSettingsType;
  variable: number;
  variableMaxDollars?: number | null;
  flat: number;
  modifiedBy: string;
  archived?: boolean | null;
  createdAt: string;
  updatedAt: string;
  variableTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  flatTaxType?: {
    __typename: "TaxType";
    id: string;
    monitoringCenterId: string;
    mcIntAdminAccess: string;
    mcOwnerAccess: string;
    mcControllerAccess: string;
    integratorId?: string | null;
    archived?: boolean | null;
    name: string;
    modifiedBy: string;
    createdAt: string;
    updatedAt: string;
    taxes?: {
      __typename: "ModelTaxesTaxTypeConnection";
      items: Array<{
        __typename: "TaxesTaxType";
        id: string;
        taxId: string;
        taxTypeId: string;
        mcIntAdminAccess: string;
        mcOwnerAccess: string;
        mcControllerAccess: string;
        monitoringCenterId: string;
        modifiedBy: string;
        createdAt: string;
        updatedAt: string;
        tax: {
          __typename: "Tax";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          region: string;
          subregion?: string | null;
          archived?: boolean | null;
          amount: number;
          name: string;
          taxGovId: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxTypes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
        integratorId?: string | null;
        taxType: {
          __typename: "TaxType";
          id: string;
          monitoringCenterId: string;
          mcIntAdminAccess: string;
          mcOwnerAccess: string;
          mcControllerAccess: string;
          integratorId?: string | null;
          archived?: boolean | null;
          name: string;
          modifiedBy: string;
          createdAt: string;
          updatedAt: string;
          taxes?: {
            __typename: "ModelTaxesTaxTypeConnection";
            items: Array<{
              __typename: "TaxesTaxType";
              id: string;
              taxId: string;
              taxTypeId: string;
              mcIntAdminAccess: string;
              mcOwnerAccess: string;
              mcControllerAccess: string;
              monitoringCenterId: string;
              modifiedBy: string;
              createdAt: string;
              updatedAt: string;
              integratorId?: string | null;
            } | null>;
            nextToken?: string | null;
          } | null;
        };
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

export type OnCreateWebhookHealthSubscription = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateWebhookHealthSubscription = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteWebhookHealthSubscription = {
  __typename: "WebhookHealth";
  id: string;
  monitoringCenterId: string;
  vmsWebhookId: string;
  date: string;
  ackAvgInterval?: number | null;
  acklastReceived?: string | null;
  ackCount?: number | null;
  analyticAvgInterval?: number | null;
  analyticlastReceived?: string | null;
  analyticCount?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMasterSubscription = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMasterSubscription = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMasterSubscription = {
  __typename: "Master";
  id?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async AddVMSDetails(
    customerId: string,
    apiKey: string,
    username: string,
    password: string
  ): Promise<AddVMSDetailsMutation> {
    const statement = `mutation AddVMSDetails($customerId: ID!, $apiKey: String!, $username: String!, $password: String!) {
        addVMSDetails(
          customerId: $customerId
          apiKey: $apiKey
          username: $username
          password: $password
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      apiKey,
      username,
      password
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddVMSDetailsMutation>response.data.addVMSDetails;
  }
  async VmsGetUnlinkedCameras(
    integratorId: string,
    vmsCustomerId: number
  ): Promise<VmsGetUnlinkedCamerasMutation> {
    const statement = `mutation VmsGetUnlinkedCameras($integratorId: ID!, $vmsCustomerId: Int!) {
        vmsGetUnlinkedCameras(
          integratorId: $integratorId
          vmsCustomerId: $vmsCustomerId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      integratorId,
      vmsCustomerId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsGetUnlinkedCamerasMutation>response.data.vmsGetUnlinkedCameras;
  }
  async VmsGetUnlinkedCustomers(
    integratorId: string,
    vmsCustomerId: number
  ): Promise<VmsGetUnlinkedCustomersMutation> {
    const statement = `mutation VmsGetUnlinkedCustomers($integratorId: ID!, $vmsCustomerId: Int!) {
        vmsGetUnlinkedCustomers(
          integratorId: $integratorId
          vmsCustomerId: $vmsCustomerId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      integratorId,
      vmsCustomerId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsGetUnlinkedCustomersMutation>(
      response.data.vmsGetUnlinkedCustomers
    );
  }
  async VmsPowerCycleCamera(
    cameraId: string
  ): Promise<VmsPowerCycleCameraMutation> {
    const statement = `mutation VmsPowerCycleCamera($cameraId: ID!) {
        vmsPowerCycleCamera(cameraId: $cameraId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      cameraId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsPowerCycleCameraMutation>response.data.vmsPowerCycleCamera;
  }
  async VmsDeviceHealthCheck(
    cameraId?: string
  ): Promise<VmsDeviceHealthCheckMutation> {
    const statement = `mutation VmsDeviceHealthCheck($cameraId: ID) {
        vmsDeviceHealthCheck(cameraId: $cameraId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsDeviceHealthCheckMutation>response.data.vmsDeviceHealthCheck;
  }
  async VmsValidateCredentials(
    apiKey: string,
    username: string,
    password: string
  ): Promise<VmsValidateCredentialsMutation> {
    const statement = `mutation VmsValidateCredentials($apiKey: String!, $username: String!, $password: String!) {
        vmsValidateCredentials(
          apiKey: $apiKey
          username: $username
          password: $password
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      apiKey,
      username,
      password
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsValidateCredentialsMutation>response.data.vmsValidateCredentials;
  }
  async VmsCreateSiteVMSDetails(
    siteId: string,
    username?: string,
    password?: string,
    autoCreateUser?: boolean
  ): Promise<VmsCreateSiteVMSDetailsMutation> {
    const statement = `mutation VmsCreateSiteVMSDetails($siteId: String!, $username: String, $password: String, $autoCreateUser: Boolean) {
        vmsCreateSiteVMSDetails(
          siteId: $siteId
          username: $username
          password: $password
          autoCreateUser: $autoCreateUser
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (password) {
      gqlAPIServiceArguments.password = password;
    }
    if (autoCreateUser) {
      gqlAPIServiceArguments.autoCreateUser = autoCreateUser;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsCreateSiteVMSDetailsMutation>(
      response.data.vmsCreateSiteVMSDetails
    );
  }
  async VmsGetTroubleShootingUrl(
    cameraId: string
  ): Promise<VmsGetTroubleShootingUrlMutation> {
    const statement = `mutation VmsGetTroubleShootingUrl($cameraId: ID!) {
        vmsGetTroubleShootingUrl(cameraId: $cameraId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      cameraId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsGetTroubleShootingUrlMutation>(
      response.data.vmsGetTroubleShootingUrl
    );
  }
  async VmsListPortalProfiles(
    portalType: VMSPortalType
  ): Promise<VmsListPortalProfilesMutation> {
    const statement = `mutation VmsListPortalProfiles($portalType: VMSPortalType!) {
        vmsListPortalProfiles(portalType: $portalType) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      portalType
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsListPortalProfilesMutation>response.data.vmsListPortalProfiles;
  }
  async VmsGetPortalCredentials(
    vmsUserId: string
  ): Promise<VmsGetPortalCredentialsMutation> {
    const statement = `mutation VmsGetPortalCredentials($vmsUserId: String!) {
        vmsGetPortalCredentials(vmsUserId: $vmsUserId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vmsUserId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsGetPortalCredentialsMutation>(
      response.data.vmsGetPortalCredentials
    );
  }
  async RspndrDispatchIncident(
    input?: DispatchRspndrIncidentInput
  ): Promise<RspndrDispatchIncidentMutation> {
    const statement = `mutation RspndrDispatchIncident($input: DispatchRspndrIncidentInput) {
        rspndrDispatchIncident(input: $input) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrDispatchIncidentMutation>response.data.rspndrDispatchIncident;
  }
  async RspndrCancelIncident(
    input?: RspndrApiCancelIncident
  ): Promise<RspndrCancelIncidentMutation> {
    const statement = `mutation RspndrCancelIncident($input: RspndrApiCancelIncident) {
        rspndrCancelIncident(input: $input) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrCancelIncidentMutation>response.data.rspndrCancelIncident;
  }
  async CreateManualIncident(
    customer: CreateIncidentCustomerInput,
    site: CreateIncidentSiteInput,
    incidentStatus: IncidentStatus,
    eventType: EventType,
    incidentTimestamp: string,
    notes: string,
    modifiedBy: string,
    camera: CreateIncidentCameraInput
  ): Promise<CreateManualIncidentMutation> {
    const statement = `mutation CreateManualIncident($customer: CreateIncidentCustomerInput!, $site: CreateIncidentSiteInput!, $incidentStatus: IncidentStatus!, $eventType: EventType!, $incidentTimestamp: AWSDateTime!, $notes: String!, $modifiedBy: String!, $camera: CreateIncidentCameraInput!) {
        createManualIncident(
          customer: $customer
          site: $site
          incidentStatus: $incidentStatus
          eventType: $eventType
          incidentTimestamp: $incidentTimestamp
          notes: $notes
          modifiedBy: $modifiedBy
          camera: $camera
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customer,
      site,
      incidentStatus,
      eventType,
      incidentTimestamp,
      notes,
      modifiedBy,
      camera
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateManualIncidentMutation>response.data.createManualIncident;
  }
  async CloseIncident(
    incidentId: string,
    resolution: string,
    resolutionNote: string,
    billableTime: number,
    externalPartyResponse?: ExternalPartyResponseInput
  ): Promise<CloseIncidentMutation> {
    const statement = `mutation CloseIncident($incidentId: ID!, $resolution: String!, $resolutionNote: String!, $billableTime: Float!, $externalPartyResponse: ExternalPartyResponseInput) {
        closeIncident(
          incidentId: $incidentId
          resolution: $resolution
          resolutionNote: $resolutionNote
          billableTime: $billableTime
          externalPartyResponse: $externalPartyResponse
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      resolution,
      resolutionNote,
      billableTime
    };
    if (externalPartyResponse) {
      gqlAPIServiceArguments.externalPartyResponse = externalPartyResponse;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CloseIncidentMutation>response.data.closeIncident;
  }
  async UpdateIncidentServer(
    incidentInput?: IncidentInput
  ): Promise<UpdateIncidentServerMutation> {
    const statement = `mutation UpdateIncidentServer($incidentInput: IncidentInput) {
        updateIncidentServer(incidentInput: $incidentInput) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentInput) {
      gqlAPIServiceArguments.incidentInput = incidentInput;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentServerMutation>response.data.updateIncidentServer;
  }
  async DispostionBreakdown(
    username: string
  ): Promise<DispostionBreakdownMutation> {
    const statement = `mutation DispostionBreakdown($username: String!) {
        dispostionBreakdown(username: $username) {
          __typename
          statusCode
          l0
          l1
          l2
          l3
          l4
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DispostionBreakdownMutation>response.data.dispostionBreakdown;
  }
  async SeperateEventFromIncident(
    incidentId: string,
    eventId: string
  ): Promise<SeperateEventFromIncidentMutation> {
    const statement = `mutation SeperateEventFromIncident($incidentId: ID!, $eventId: ID!) {
        seperateEventFromIncident(incidentId: $incidentId, eventId: $eventId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      eventId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SeperateEventFromIncidentMutation>(
      response.data.seperateEventFromIncident
    );
  }
  async UpdateEventDisposition(
    incidentId: string,
    eventId: string,
    newDisposition?: EventType
  ): Promise<UpdateEventDispositionMutation> {
    const statement = `mutation UpdateEventDisposition($incidentId: ID!, $eventId: ID!, $newDisposition: EventType) {
        updateEventDisposition(
          incidentId: $incidentId
          eventId: $eventId
          newDisposition: $newDisposition
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      eventId
    };
    if (newDisposition) {
      gqlAPIServiceArguments.newDisposition = newDisposition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEventDispositionMutation>response.data.updateEventDisposition;
  }
  async BulkUpdateEventDisposition(
    incidentId: string,
    newEventType: EventType,
    cameraEvents?: Array<UpdateEventDispositionInput>
  ): Promise<BulkUpdateEventDispositionMutation> {
    const statement = `mutation BulkUpdateEventDisposition($incidentId: ID!, $newEventType: EventType!, $cameraEvents: [UpdateEventDispositionInput!]) {
        bulkUpdateEventDisposition(
          incidentId: $incidentId
          newEventType: $newEventType
          cameraEvents: $cameraEvents
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      newEventType
    };
    if (cameraEvents) {
      gqlAPIServiceArguments.cameraEvents = cameraEvents;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BulkUpdateEventDispositionMutation>(
      response.data.bulkUpdateEventDisposition
    );
  }
  async GetTwilioToken(
    monitoringCenterId?: string
  ): Promise<GetTwilioTokenMutation> {
    const statement = `mutation GetTwilioToken($monitoringCenterId: String) {
        getTwilioToken(monitoringCenterId: $monitoringCenterId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTwilioTokenMutation>response.data.getTwilioToken;
  }
  async RapidSOSManageSubscription(
    create: boolean,
    detailsId: string
  ): Promise<RapidSOSManageSubscriptionMutation> {
    const statement = `mutation RapidSOSManageSubscription($create: Boolean!, $detailsId: ID!) {
        rapidSOSManageSubscription(create: $create, detailsId: $detailsId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      create,
      detailsId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSManageSubscriptionMutation>(
      response.data.rapidSOSManageSubscription
    );
  }
  async RapidSOSCoverageCheckCreateAlert(
    incidentId: string,
    emergencyType: RapidSOSEmergencyType,
    primaryCameraEventId: string,
    siteIntegrationId: string,
    fileNames: string
  ): Promise<RapidSOSCoverageCheckCreateAlertMutation> {
    const statement = `mutation RapidSOSCoverageCheckCreateAlert($incidentId: ID!, $emergencyType: RapidSOSEmergencyType!, $primaryCameraEventId: ID!, $siteIntegrationId: ID!, $fileNames: String!) {
        rapidSOSCoverageCheckCreateAlert(
          incidentId: $incidentId
          emergencyType: $emergencyType
          primaryCameraEventId: $primaryCameraEventId
          siteIntegrationId: $siteIntegrationId
          fileNames: $fileNames
        ) {
          __typename
          statusCode
          rapidSOSIncident
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      emergencyType,
      primaryCameraEventId,
      siteIntegrationId,
      fileNames
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSCoverageCheckCreateAlertMutation>(
      response.data.rapidSOSCoverageCheckCreateAlert
    );
  }
  async RapidSOSDispatchIncident(
    rapidSOSIncidentId: string,
    alertId: string
  ): Promise<RapidSOSDispatchIncidentMutation> {
    const statement = `mutation RapidSOSDispatchIncident($rapidSOSIncidentId: ID!, $alertId: String!) {
        rapidSOSDispatchIncident(
          rapidSOSIncidentId: $rapidSOSIncidentId
          alertId: $alertId
        ) {
          __typename
          statusCode
          rapidSOSIncident
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rapidSOSIncidentId,
      alertId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSDispatchIncidentMutation>(
      response.data.rapidSOSDispatchIncident
    );
  }
  async RapidSOSAddLogMessage(
    rapidSOSIncidentId: string,
    logMessageType: RapidSOSLogMessageType,
    messageBody: string
  ): Promise<RapidSOSAddLogMessageMutation> {
    const statement = `mutation RapidSOSAddLogMessage($rapidSOSIncidentId: ID!, $logMessageType: RapidSOSLogMessageType!, $messageBody: String!) {
        rapidSOSAddLogMessage(
          rapidSOSIncidentId: $rapidSOSIncidentId
          logMessageType: $logMessageType
          messageBody: $messageBody
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rapidSOSIncidentId,
      logMessageType,
      messageBody
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSAddLogMessageMutation>response.data.rapidSOSAddLogMessage;
  }
  async RapidSOSCancelDispatch(
    rapidSOSIncidentId: string,
    alertId: string,
    cancelReason: string
  ): Promise<RapidSOSCancelDispatchMutation> {
    const statement = `mutation RapidSOSCancelDispatch($rapidSOSIncidentId: ID!, $alertId: String!, $cancelReason: String!) {
        rapidSOSCancelDispatch(
          rapidSOSIncidentId: $rapidSOSIncidentId
          alertId: $alertId
          cancelReason: $cancelReason
        ) {
          __typename
          statusCode
          rapidSOSIncident
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rapidSOSIncidentId,
      alertId,
      cancelReason
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSCancelDispatchMutation>response.data.rapidSOSCancelDispatch;
  }
  async TracktikConfigureConnection(
    trackTikId: string,
    apiUrl: string,
    apiUsername: string,
    apiPassword: string
  ): Promise<TracktikConfigureConnectionMutation> {
    const statement = `mutation TracktikConfigureConnection($trackTikId: ID!, $apiUrl: String!, $apiUsername: String!, $apiPassword: String!) {
        tracktikConfigureConnection(
          trackTikId: $trackTikId
          apiUrl: $apiUrl
          apiUsername: $apiUsername
          apiPassword: $apiPassword
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      trackTikId,
      apiUrl,
      apiUsername,
      apiPassword
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TracktikConfigureConnectionMutation>(
      response.data.tracktikConfigureConnection
    );
  }
  async TracktikDispatchIncident(
    incidentId: string,
    primaryCameraEventId: string,
    taskId: number,
    reportTemplateId: number,
    taskName: string,
    priority: TrackTikPriority,
    modifiedBy: string,
    externalNotes?: boolean,
    externalSiteCriticalNotes?: boolean,
    externalClientCriticalNotes?: boolean
  ): Promise<TracktikDispatchIncidentMutation> {
    const statement = `mutation TracktikDispatchIncident($incidentId: ID!, $primaryCameraEventId: ID!, $taskId: Int!, $reportTemplateId: Int!, $taskName: String!, $priority: TrackTikPriority!, $externalNotes: Boolean, $externalSiteCriticalNotes: Boolean, $externalClientCriticalNotes: Boolean, $modifiedBy: String!) {
        tracktikDispatchIncident(
          incidentId: $incidentId
          primaryCameraEventId: $primaryCameraEventId
          taskId: $taskId
          reportTemplateId: $reportTemplateId
          taskName: $taskName
          priority: $priority
          externalNotes: $externalNotes
          externalSiteCriticalNotes: $externalSiteCriticalNotes
          externalClientCriticalNotes: $externalClientCriticalNotes
          modifiedBy: $modifiedBy
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      primaryCameraEventId,
      taskId,
      reportTemplateId,
      taskName,
      priority,
      modifiedBy
    };
    if (externalNotes) {
      gqlAPIServiceArguments.externalNotes = externalNotes;
    }
    if (externalSiteCriticalNotes) {
      gqlAPIServiceArguments.externalSiteCriticalNotes = externalSiteCriticalNotes;
    }
    if (externalClientCriticalNotes) {
      gqlAPIServiceArguments.externalClientCriticalNotes = externalClientCriticalNotes;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TracktikDispatchIncidentMutation>(
      response.data.tracktikDispatchIncident
    );
  }
  async TrackTikGetTaskList(
    siteId: string
  ): Promise<TrackTikGetTaskListMutation> {
    const statement = `mutation TrackTikGetTaskList($siteId: ID!) {
        trackTikGetTaskList(siteId: $siteId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrackTikGetTaskListMutation>response.data.trackTikGetTaskList;
  }
  async TrackTikCloseIncident(
    modifiedBy: string,
    trackTikIncidentId: string,
    reason: string,
    siteId: string
  ): Promise<TrackTikCloseIncidentMutation> {
    const statement = `mutation TrackTikCloseIncident($modifiedBy: String!, $trackTikIncidentId: ID!, $reason: String!, $siteId: ID!) {
        trackTikCloseIncident(
          modifiedBy: $modifiedBy
          trackTikIncidentId: $trackTikIncidentId
          reason: $reason
          siteId: $siteId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      modifiedBy,
      trackTikIncidentId,
      reason,
      siteId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrackTikCloseIncidentMutation>response.data.trackTikCloseIncident;
  }
  async RekgonitionDetectLabels(
    filename: string
  ): Promise<RekgonitionDetectLabelsMutation> {
    const statement = `mutation RekgonitionDetectLabels($filename: String!) {
        rekgonitionDetectLabels(filename: $filename) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      filename
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RekgonitionDetectLabelsMutation>(
      response.data.rekgonitionDetectLabels
    );
  }
  async GlobalSearch(
    searchFor: string,
    searchBy: string,
    searchTerm: string,
    nextToken?: string
  ): Promise<GlobalSearchMutation> {
    const statement = `mutation GlobalSearch($searchFor: String!, $searchBy: String!, $searchTerm: String!, $nextToken: String) {
        globalSearch(
          searchFor: $searchFor
          searchBy: $searchBy
          searchTerm: $searchTerm
          nextToken: $nextToken
        ) {
          __typename
          statusCode
          items {
            __typename
            type
            id
            url
            overviewText
          }
          errorMessage
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      searchFor,
      searchBy,
      searchTerm
    };
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GlobalSearchMutation>response.data.globalSearch;
  }
  async HubspotConnectOrUpdate(
    entityId: string,
    entityType: string
  ): Promise<HubspotConnectOrUpdateMutation> {
    const statement = `mutation HubspotConnectOrUpdate($entityId: ID!, $entityType: String!) {
        hubspotConnectOrUpdate(entityId: $entityId, entityType: $entityType) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
      entityType
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <HubspotConnectOrUpdateMutation>response.data.hubspotConnectOrUpdate;
  }
  async ReportingIncidentlist(
    limit: number,
    offset: number,
    filters?: IncidentSearchFilters
  ): Promise<ReportingIncidentlistMutation> {
    const statement = `mutation ReportingIncidentlist($filters: IncidentSearchFilters, $limit: Int!, $offset: Int!) {
        reportingIncidentlist(filters: $filters, limit: $limit, offset: $offset) {
          __typename
          statusCode
          errorMessage
          nextRecord
          items {
            __typename
            id
            createdAt
            resolvedAt
            primaryDisposition
            siteName
            clientName
            assignedTo
            status
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      limit,
      offset
    };
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingIncidentlistMutation>response.data.reportingIncidentlist;
  }
  async ReportingCameraEventList(
    limit: number,
    offset: number,
    filters?: CameraEventSearchFilters
  ): Promise<ReportingCameraEventListMutation> {
    const statement = `mutation ReportingCameraEventList($filters: CameraEventSearchFilters, $limit: Int!, $offset: Int!) {
        reportingCameraEventList(filters: $filters, limit: $limit, offset: $offset) {
          __typename
          statusCode
          errorMessage
          nextRecord
          items {
            __typename
            id
            incidentId
            startTime
            site
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            camera
            disposition
            eventType
            client
            integrator
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      limit,
      offset
    };
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingCameraEventListMutation>(
      response.data.reportingCameraEventList
    );
  }
  async ReportingInvoiceList(
    limit: number,
    offset: number,
    filters?: InvoiceSearchFilters,
    listingBills?: boolean
  ): Promise<ReportingInvoiceListMutation> {
    const statement = `mutation ReportingInvoiceList($filters: InvoiceSearchFilters, $limit: Int!, $offset: Int!, $listingBills: Boolean) {
        reportingInvoiceList(
          filters: $filters
          limit: $limit
          offset: $offset
          listingBills: $listingBills
        ) {
          __typename
          statusCode
          errorMessage
          nextRecord
          items {
            __typename
            id
            invoiceNumber
            createdAt
            siteName
            clientName
            invoiceType
            fromName
            toName
            status
            total
            currency
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      limit,
      offset
    };
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    if (listingBills) {
      gqlAPIServiceArguments.listingBills = listingBills;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingInvoiceListMutation>response.data.reportingInvoiceList;
  }
  async ReportingGetDashboardUrl(
    dashboardId?: string
  ): Promise<ReportingGetDashboardUrlMutation> {
    const statement = `mutation ReportingGetDashboardUrl($dashboardId: String) {
        reportingGetDashboardUrl(dashboardId: $dashboardId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (dashboardId) {
      gqlAPIServiceArguments.dashboardId = dashboardId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingGetDashboardUrlMutation>(
      response.data.reportingGetDashboardUrl
    );
  }
  async ReportingInvoiceListExport(
    filters?: InvoiceSearchFilters,
    listingBills?: boolean
  ): Promise<ReportingInvoiceListExportMutation> {
    const statement = `mutation ReportingInvoiceListExport($filters: InvoiceSearchFilters, $listingBills: Boolean) {
        reportingInvoiceListExport(filters: $filters, listingBills: $listingBills) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    if (listingBills) {
      gqlAPIServiceArguments.listingBills = listingBills;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingInvoiceListExportMutation>(
      response.data.reportingInvoiceListExport
    );
  }
  async ReportingCustomerPaymentsList(
    limit: number,
    offset: number,
    filters?: CustomerPaymentsSearchFilters
  ): Promise<ReportingCustomerPaymentsListMutation> {
    const statement = `mutation ReportingCustomerPaymentsList($filters: CustomerPaymentsSearchFilters, $limit: Int!, $offset: Int!) {
        reportingCustomerPaymentsList(filters: $filters, limit: $limit, offset: $offset) {
          __typename
          statusCode
          errorMessage
          nextRecord
          items {
            __typename
            invoiceNumber
            paymentDate
            billingEntityName
            transactionId
            amount
            currency
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      limit,
      offset
    };
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingCustomerPaymentsListMutation>(
      response.data.reportingCustomerPaymentsList
    );
  }
  async ReportingCustomerPaymentsListExport(
    filters?: CustomerPaymentsSearchFilters,
    exporting?: boolean
  ): Promise<ReportingCustomerPaymentsListExportMutation> {
    const statement = `mutation ReportingCustomerPaymentsListExport($filters: CustomerPaymentsSearchFilters, $exporting: Boolean) {
        reportingCustomerPaymentsListExport(filters: $filters, exporting: $exporting) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    if (exporting) {
      gqlAPIServiceArguments.exporting = exporting;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingCustomerPaymentsListExportMutation>(
      response.data.reportingCustomerPaymentsListExport
    );
  }
  async ReportingPayoutsList(
    limit: number,
    offset: number,
    filters?: PayoutsSearchFilters
  ): Promise<ReportingPayoutsListMutation> {
    const statement = `mutation ReportingPayoutsList($filters: PayoutsSearchFilters, $limit: Int!, $offset: Int!) {
        reportingPayoutsList(filters: $filters, limit: $limit, offset: $offset) {
          __typename
          statusCode
          errorMessage
          nextRecord
          items {
            __typename
            invoiceNumber
            paymentDate
            amount
            transferAmount
            referenceId
            type
            currency
            transactionCurr
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      limit,
      offset
    };
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingPayoutsListMutation>response.data.reportingPayoutsList;
  }
  async ReportingPayoutsListExport(
    filters?: PayoutsSearchFilters,
    exporting?: boolean
  ): Promise<ReportingPayoutsListExportMutation> {
    const statement = `mutation ReportingPayoutsListExport($filters: PayoutsSearchFilters, $exporting: Boolean) {
        reportingPayoutsListExport(filters: $filters, exporting: $exporting) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    if (exporting) {
      gqlAPIServiceArguments.exporting = exporting;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportingPayoutsListExportMutation>(
      response.data.reportingPayoutsListExport
    );
  }
  async IncidentShareCreate(
    incidentDataToShare: IncidentDataToShareInput,
    expiration?: string
  ): Promise<IncidentShareCreateMutation> {
    const statement = `mutation IncidentShareCreate($incidentDataToShare: IncidentDataToShareInput!, $expiration: AWSDateTime) {
        incidentShareCreate(
          incidentDataToShare: $incidentDataToShare
          expiration: $expiration
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentDataToShare
    };
    if (expiration) {
      gqlAPIServiceArguments.expiration = expiration;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareCreateMutation>response.data.incidentShareCreate;
  }
  async IncidentShareSendLink(
    toData: string,
    codeUrl: string,
    incidentId: string,
    sendType: string
  ): Promise<IncidentShareSendLinkMutation> {
    const statement = `mutation IncidentShareSendLink($toData: String!, $codeUrl: String!, $incidentId: ID!, $sendType: String!) {
        incidentShareSendLink(
          toData: $toData
          codeUrl: $codeUrl
          incidentId: $incidentId
          sendType: $sendType
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      toData,
      codeUrl,
      incidentId,
      sendType
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareSendLinkMutation>response.data.incidentShareSendLink;
  }
  async IncidentShareExpirePage(
    incidentId: string,
    code: string
  ): Promise<IncidentShareExpirePageMutation> {
    const statement = `mutation IncidentShareExpirePage($incidentId: ID!, $code: String!) {
        incidentShareExpirePage(incidentId: $incidentId, code: $code) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentId,
      code
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareExpirePageMutation>(
      response.data.incidentShareExpirePage
    );
  }
  async IncidentShareUpdate(
    incidentDataToShare: IncidentDataToShareInput,
    incidentShareSettingsId: string,
    expiration?: string
  ): Promise<IncidentShareUpdateMutation> {
    const statement = `mutation IncidentShareUpdate($incidentDataToShare: IncidentDataToShareInput!, $expiration: AWSDateTime, $incidentShareSettingsId: ID!) {
        incidentShareUpdate(
          incidentDataToShare: $incidentDataToShare
          expiration: $expiration
          incidentShareSettingsId: $incidentShareSettingsId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentDataToShare,
      incidentShareSettingsId
    };
    if (expiration) {
      gqlAPIServiceArguments.expiration = expiration;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareUpdateMutation>response.data.incidentShareUpdate;
  }
  async IncidentCodeCreate(
    incidentDataToShare: IncidentDataToShareInput
  ): Promise<IncidentCodeCreateMutation> {
    const statement = `mutation IncidentCodeCreate($incidentDataToShare: IncidentDataToShareInput!) {
        incidentCodeCreate(incidentDataToShare: $incidentDataToShare) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      incidentDataToShare
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentCodeCreateMutation>response.data.incidentCodeCreate;
  }
  async BillingAddCameraPlanSubAccount(
    subAccountIds: Array<string | null>,
    cameraPlanId: string
  ): Promise<BillingAddCameraPlanSubAccountMutation> {
    const statement = `mutation BillingAddCameraPlanSubAccount($subAccountIds: [ID]!, $cameraPlanId: ID!) {
        billingAddCameraPlanSubAccount(
          subAccountIds: $subAccountIds
          cameraPlanId: $cameraPlanId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subAccountIds,
      cameraPlanId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingAddCameraPlanSubAccountMutation>(
      response.data.billingAddCameraPlanSubAccount
    );
  }
  async BillingArchiveCameraPlanSubAccount(
    parentCameraPlanId: string,
    subAccountId?: string,
    cameraPlanId?: string
  ): Promise<BillingArchiveCameraPlanSubAccountMutation> {
    const statement = `mutation BillingArchiveCameraPlanSubAccount($parentCameraPlanId: ID!, $subAccountId: ID, $cameraPlanId: ID) {
        billingArchiveCameraPlanSubAccount(
          parentCameraPlanId: $parentCameraPlanId
          subAccountId: $subAccountId
          cameraPlanId: $cameraPlanId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentCameraPlanId
    };
    if (subAccountId) {
      gqlAPIServiceArguments.subAccountId = subAccountId;
    }
    if (cameraPlanId) {
      gqlAPIServiceArguments.cameraPlanId = cameraPlanId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingArchiveCameraPlanSubAccountMutation>(
      response.data.billingArchiveCameraPlanSubAccount
    );
  }
  async BillingAddUpdateSubscription(
    input: CreateUpdateSiteSubscription
  ): Promise<BillingAddUpdateSubscriptionMutation> {
    const statement = `mutation BillingAddUpdateSubscription($input: CreateUpdateSiteSubscription!) {
        billingAddUpdateSubscription(input: $input) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingAddUpdateSubscriptionMutation>(
      response.data.billingAddUpdateSubscription
    );
  }
  async BillingActivateSubscription(
    subId: string,
    initialBilling: boolean
  ): Promise<BillingActivateSubscriptionMutation> {
    const statement = `mutation BillingActivateSubscription($subId: ID!, $initialBilling: Boolean!) {
        billingActivateSubscription(subId: $subId, initialBilling: $initialBilling) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subId,
      initialBilling
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingActivateSubscriptionMutation>(
      response.data.billingActivateSubscription
    );
  }
  async BillingDectivateSubscription(
    subId: string
  ): Promise<BillingDectivateSubscriptionMutation> {
    const statement = `mutation BillingDectivateSubscription($subId: ID!) {
        billingDectivateSubscription(subId: $subId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingDectivateSubscriptionMutation>(
      response.data.billingDectivateSubscription
    );
  }
  async BillingDeleteSubscription(
    subId: string
  ): Promise<BillingDeleteSubscriptionMutation> {
    const statement = `mutation BillingDeleteSubscription($subId: ID!) {
        billingDeleteSubscription(subId: $subId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingDeleteSubscriptionMutation>(
      response.data.billingDeleteSubscription
    );
  }
  async BillingAddUpdateCameraPlan(
    subscriptionId: string,
    merchantId: string,
    planOwnerId: string,
    cameraId: string,
    currency: Currency,
    newPlanId?: string,
    oldPlanId?: string
  ): Promise<BillingAddUpdateCameraPlanMutation> {
    const statement = `mutation BillingAddUpdateCameraPlan($subscriptionId: ID!, $merchantId: ID!, $planOwnerId: ID!, $cameraId: ID!, $currency: Currency!, $newPlanId: ID, $oldPlanId: ID) {
        billingAddUpdateCameraPlan(
          subscriptionId: $subscriptionId
          merchantId: $merchantId
          planOwnerId: $planOwnerId
          cameraId: $cameraId
          currency: $currency
          newPlanId: $newPlanId
          oldPlanId: $oldPlanId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subscriptionId,
      merchantId,
      planOwnerId,
      cameraId,
      currency
    };
    if (newPlanId) {
      gqlAPIServiceArguments.newPlanId = newPlanId;
    }
    if (oldPlanId) {
      gqlAPIServiceArguments.oldPlanId = oldPlanId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingAddUpdateCameraPlanMutation>(
      response.data.billingAddUpdateCameraPlan
    );
  }
  async BillingGenerateInvoicePdf(
    invoiceId: string
  ): Promise<BillingGenerateInvoicePdfMutation> {
    const statement = `mutation BillingGenerateInvoicePdf($invoiceId: ID!) {
        billingGenerateInvoicePdf(invoiceId: $invoiceId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      invoiceId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingGenerateInvoicePdfMutation>(
      response.data.billingGenerateInvoicePdf
    );
  }
  async BillingConnectStripe(
    stripeAccountId?: string
  ): Promise<BillingConnectStripeMutation> {
    const statement = `mutation BillingConnectStripe($stripeAccountId: ID) {
        billingConnectStripe(stripeAccountId: $stripeAccountId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (stripeAccountId) {
      gqlAPIServiceArguments.stripeAccountId = stripeAccountId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingConnectStripeMutation>response.data.billingConnectStripe;
  }
  async BillingStripeSetupIntent(
    subscriptionId: string,
    site: StripeSiteItem,
    stripeCustomerId?: string
  ): Promise<BillingStripeSetupIntentMutation> {
    const statement = `mutation BillingStripeSetupIntent($subscriptionId: ID!, $site: StripeSiteItem!, $stripeCustomerId: String) {
        billingStripeSetupIntent(
          subscriptionId: $subscriptionId
          site: $site
          stripeCustomerId: $stripeCustomerId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      subscriptionId,
      site
    };
    if (stripeCustomerId) {
      gqlAPIServiceArguments.stripeCustomerId = stripeCustomerId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingStripeSetupIntentMutation>(
      response.data.billingStripeSetupIntent
    );
  }
  async BillingStripeSavePayment(
    setupIntent: StripeSetupIntent
  ): Promise<BillingStripeSavePaymentMutation> {
    const statement = `mutation BillingStripeSavePayment($setupIntent: StripeSetupIntent!) {
        billingStripeSavePayment(setupIntent: $setupIntent) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      setupIntent
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingStripeSavePaymentMutation>(
      response.data.billingStripeSavePayment
    );
  }
  async BillingUpdateDefaultPaymentMethod(
    newDefaultId: string,
    oldDefaultId: string,
    subscriptionId: string
  ): Promise<BillingUpdateDefaultPaymentMethodMutation> {
    const statement = `mutation BillingUpdateDefaultPaymentMethod($newDefaultId: ID!, $oldDefaultId: ID!, $subscriptionId: ID!) {
        billingUpdateDefaultPaymentMethod(
          newDefaultId: $newDefaultId
          oldDefaultId: $oldDefaultId
          subscriptionId: $subscriptionId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      newDefaultId,
      oldDefaultId,
      subscriptionId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingUpdateDefaultPaymentMethodMutation>(
      response.data.billingUpdateDefaultPaymentMethod
    );
  }
  async BillingStripeRemovePaymentMethod(
    paymentMethodId: string,
    subscriptionId: string
  ): Promise<BillingStripeRemovePaymentMethodMutation> {
    const statement = `mutation BillingStripeRemovePaymentMethod($paymentMethodId: ID!, $subscriptionId: ID!) {
        billingStripeRemovePaymentMethod(
          paymentMethodId: $paymentMethodId
          subscriptionId: $subscriptionId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      paymentMethodId,
      subscriptionId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingStripeRemovePaymentMethodMutation>(
      response.data.billingStripeRemovePaymentMethod
    );
  }
  async BillingStripeManualChargeWallet(
    paymentMethodId: string,
    subscriptionId: string,
    amount: number
  ): Promise<BillingStripeManualChargeWalletMutation> {
    const statement = `mutation BillingStripeManualChargeWallet($paymentMethodId: ID!, $subscriptionId: ID!, $amount: Float!) {
        billingStripeManualChargeWallet(
          paymentMethodId: $paymentMethodId
          subscriptionId: $subscriptionId
          amount: $amount
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      paymentMethodId,
      subscriptionId,
      amount
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingStripeManualChargeWalletMutation>(
      response.data.billingStripeManualChargeWallet
    );
  }
  async BillingInvoiceReversal(
    invoiceId: string,
    type: string,
    reason: string,
    amount?: number
  ): Promise<BillingInvoiceReversalMutation> {
    const statement = `mutation BillingInvoiceReversal($invoiceId: ID!, $type: String!, $reason: String!, $amount: Float) {
        billingInvoiceReversal(
          invoiceId: $invoiceId
          type: $type
          reason: $reason
          amount: $amount
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      invoiceId,
      type,
      reason
    };
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingInvoiceReversalMutation>response.data.billingInvoiceReversal;
  }
  async BillingMigratePaymentMethod(
    newSubscriptionId: string,
    paymentMethodId?: string,
    oldSubscriptionId?: string
  ): Promise<BillingMigratePaymentMethodMutation> {
    const statement = `mutation BillingMigratePaymentMethod($newSubscriptionId: ID!, $paymentMethodId: ID, $oldSubscriptionId: ID) {
        billingMigratePaymentMethod(
          newSubscriptionId: $newSubscriptionId
          paymentMethodId: $paymentMethodId
          oldSubscriptionId: $oldSubscriptionId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      newSubscriptionId
    };
    if (paymentMethodId) {
      gqlAPIServiceArguments.paymentMethodId = paymentMethodId;
    }
    if (oldSubscriptionId) {
      gqlAPIServiceArguments.oldSubscriptionId = oldSubscriptionId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillingMigratePaymentMethodMutation>(
      response.data.billingMigratePaymentMethod
    );
  }
  async MonitoringChangeSiteStatus(
    siteId: string,
    newStatus: MonitoringStatus
  ): Promise<MonitoringChangeSiteStatusMutation> {
    const statement = `mutation MonitoringChangeSiteStatus($siteId: ID!, $newStatus: MonitoringStatus!) {
        monitoringChangeSiteStatus(siteId: $siteId, newStatus: $newStatus) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId,
      newStatus
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MonitoringChangeSiteStatusMutation>(
      response.data.monitoringChangeSiteStatus
    );
  }
  async MonitoringChangeStatus(
    siteId: string,
    newArmedState?: ArmedStates,
    newMonitoringState?: MonitoringStates,
    deviceIds?: Array<string>
  ): Promise<MonitoringChangeStatusMutation> {
    const statement = `mutation MonitoringChangeStatus($siteId: ID!, $newArmedState: ArmedStates, $newMonitoringState: MonitoringStates, $deviceIds: [ID!]) {
        monitoringChangeStatus(
          siteId: $siteId
          newArmedState: $newArmedState
          newMonitoringState: $newMonitoringState
          deviceIds: $deviceIds
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId
    };
    if (newArmedState) {
      gqlAPIServiceArguments.newArmedState = newArmedState;
    }
    if (newMonitoringState) {
      gqlAPIServiceArguments.newMonitoringState = newMonitoringState;
    }
    if (deviceIds) {
      gqlAPIServiceArguments.deviceIds = deviceIds;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MonitoringChangeStatusMutation>response.data.monitoringChangeStatus;
  }
  async MonitoringGetHolidays(
    country: string,
    region: string,
    observe: string
  ): Promise<MonitoringGetHolidaysMutation> {
    const statement = `mutation MonitoringGetHolidays($country: String!, $region: String!, $observe: AWSJSON!) {
        monitoringGetHolidays(country: $country, region: $region, observe: $observe) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      region,
      observe
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MonitoringGetHolidaysMutation>response.data.monitoringGetHolidays;
  }
  async MonitoringValidateArmingRules(
    armingRulesConfig: string,
    timezone: string,
    startDate: string,
    endDate: string,
    locale: string
  ): Promise<MonitoringValidateArmingRulesMutation> {
    const statement = `mutation MonitoringValidateArmingRules($armingRulesConfig: AWSJSON!, $timezone: String!, $startDate: AWSDateTime!, $endDate: AWSDateTime!, $locale: String!) {
        monitoringValidateArmingRules(
          armingRulesConfig: $armingRulesConfig
          timezone: $timezone
          startDate: $startDate
          endDate: $endDate
          locale: $locale
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      armingRulesConfig,
      timezone,
      startDate,
      endDate,
      locale
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MonitoringValidateArmingRulesMutation>(
      response.data.monitoringValidateArmingRules
    );
  }
  async MonitoringSyncAllDevices(): Promise<MonitoringSyncAllDevicesMutation> {
    const statement = `mutation MonitoringSyncAllDevices {
        monitoringSyncAllDevices {
          __typename
          statusCode
          body
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <MonitoringSyncAllDevicesMutation>(
      response.data.monitoringSyncAllDevices
    );
  }
  async SupvisdEventsManageRules(
    settingsInput: SupvisdEventSettingsInput
  ): Promise<SupvisdEventsManageRulesMutation> {
    const statement = `mutation SupvisdEventsManageRules($settingsInput: SupvisdEventSettingsInput!) {
        supvisdEventsManageRules(settingsInput: $settingsInput) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      settingsInput
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SupvisdEventsManageRulesMutation>(
      response.data.supvisdEventsManageRules
    );
  }
  async IncidentsGetEnhancedUrlCreds(
    siteId?: string,
    incidentId?: string
  ): Promise<IncidentsGetEnhancedUrlCredsMutation> {
    const statement = `mutation IncidentsGetEnhancedUrlCreds($siteId: ID, $incidentId: ID) {
        incidentsGetEnhancedUrlCreds(siteId: $siteId, incidentId: $incidentId) {
          __typename
          statusCode
          accessToken
          refreshToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentsGetEnhancedUrlCredsMutation>(
      response.data.incidentsGetEnhancedUrlCreds
    );
  }
  async AuthDeleteUser(username: string): Promise<AuthDeleteUserMutation> {
    const statement = `mutation AuthDeleteUser($username: String!) {
        authDeleteUser(username: $username) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuthDeleteUserMutation>response.data.authDeleteUser;
  }
  async AuthManageCXUser(
    action: AuthCXUserAction,
    accountId: string,
    userDetails?: CXUserDetailsInput
  ): Promise<AuthManageCXUserMutation> {
    const statement = `mutation AuthManageCXUser($action: AuthCXUserAction!, $accountId: ID!, $userDetails: CXUserDetailsInput) {
        authManageCXUser(
          action: $action
          accountId: $accountId
          userDetails: $userDetails
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      action,
      accountId
    };
    if (userDetails) {
      gqlAPIServiceArguments.userDetails = userDetails;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuthManageCXUserMutation>response.data.authManageCXUser;
  }
  async CustomerArchive(
    customerId: string,
    deleteVMSAccountAndCameras: boolean,
    showTheMess: boolean,
    treatAsCrossThreaded: boolean,
    dryRunDelete: boolean,
    deleteMap?: string
  ): Promise<CustomerArchiveMutation> {
    const statement = `mutation CustomerArchive($customerId: ID!, $deleteVMSAccountAndCameras: Boolean!, $showTheMess: Boolean!, $treatAsCrossThreaded: Boolean!, $dryRunDelete: Boolean!, $deleteMap: AWSJSON) {
        customerArchive(
          customerId: $customerId
          deleteVMSAccountAndCameras: $deleteVMSAccountAndCameras
          showTheMess: $showTheMess
          treatAsCrossThreaded: $treatAsCrossThreaded
          dryRunDelete: $dryRunDelete
          deleteMap: $deleteMap
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      deleteVMSAccountAndCameras,
      showTheMess,
      treatAsCrossThreaded,
      dryRunDelete
    };
    if (deleteMap) {
      gqlAPIServiceArguments.deleteMap = deleteMap;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerArchiveMutation>response.data.customerArchive;
  }
  async AudioManagementGroups(
    action: AudioManagementAction,
    siteId: string,
    groups?: Array<AudioGroupDetailInput>
  ): Promise<AudioManagementGroupsMutation> {
    const statement = `mutation AudioManagementGroups($action: AudioManagementAction!, $groups: [AudioGroupDetailInput!], $siteId: ID!) {
        audioManagementGroups(action: $action, groups: $groups, siteId: $siteId) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      action,
      siteId
    };
    if (groups) {
      gqlAPIServiceArguments.groups = groups;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AudioManagementGroupsMutation>response.data.audioManagementGroups;
  }
  async AudioManagementMessageTypes(
    action: AudioManagementAction,
    siteId: string,
    messages?: Array<AudioCustomMessageTypeInput>
  ): Promise<AudioManagementMessageTypesMutation> {
    const statement = `mutation AudioManagementMessageTypes($action: AudioManagementAction!, $messages: [AudioCustomMessageTypeInput!], $siteId: ID!) {
        audioManagementMessageTypes(
          action: $action
          messages: $messages
          siteId: $siteId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      action,
      siteId
    };
    if (messages) {
      gqlAPIServiceArguments.messages = messages;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AudioManagementMessageTypesMutation>(
      response.data.audioManagementMessageTypes
    );
  }
  async AudioPlayMessage(
    messageType: AudioMessageType,
    siteId: string,
    deviceIds?: Array<string>,
    audioGroups?: Array<string>,
    messageId?: string,
    incidentID?: string
  ): Promise<AudioPlayMessageMutation> {
    const statement = `mutation AudioPlayMessage($deviceIds: [ID!], $audioGroups: [ID!], $messageType: AudioMessageType!, $messageId: ID, $siteId: ID!, $incidentID: ID) {
        audioPlayMessage(
          deviceIds: $deviceIds
          audioGroups: $audioGroups
          messageType: $messageType
          messageId: $messageId
          siteId: $siteId
          incidentID: $incidentID
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      messageType,
      siteId
    };
    if (deviceIds) {
      gqlAPIServiceArguments.deviceIds = deviceIds;
    }
    if (audioGroups) {
      gqlAPIServiceArguments.audioGroups = audioGroups;
    }
    if (messageId) {
      gqlAPIServiceArguments.messageId = messageId;
    }
    if (incidentID) {
      gqlAPIServiceArguments.incidentID = incidentID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AudioPlayMessageMutation>response.data.audioPlayMessage;
  }
  async VmsAddCustomer(
    customer: CreateVMSCustomerInput
  ): Promise<VmsAddCustomerMutation> {
    const statement = `mutation VmsAddCustomer($customer: CreateVMSCustomerInput!) {
        vmsAddCustomer(customer: $customer) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customer
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsAddCustomerMutation>response.data.vmsAddCustomer;
  }
  async IntAppFetchAcc(): Promise<IntAppFetchAccMutation> {
    const statement = `mutation IntAppFetchAcc {
        intAppFetchAcc {
          __typename
          statusCode
          body
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <IntAppFetchAccMutation>response.data.intAppFetchAcc;
  }
  async IntAppAddEditSite(
    adding?: boolean,
    site?: AddEditSiteInput,
    contacts?: Array<AddEditEmergencyContactInput>,
    address?: CreateVMSAddressInput,
    siteId?: string
  ): Promise<IntAppAddEditSiteMutation> {
    const statement = `mutation IntAppAddEditSite($adding: Boolean, $site: AddEditSiteInput, $contacts: [AddEditEmergencyContactInput!], $address: CreateVMSAddressInput, $siteId: ID) {
        intAppAddEditSite(
          adding: $adding
          site: $site
          contacts: $contacts
          address: $address
          siteId: $siteId
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (adding) {
      gqlAPIServiceArguments.adding = adding;
    }
    if (site) {
      gqlAPIServiceArguments.site = site;
    }
    if (contacts) {
      gqlAPIServiceArguments.contacts = contacts;
    }
    if (address) {
      gqlAPIServiceArguments.address = address;
    }
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IntAppAddEditSiteMutation>response.data.intAppAddEditSite;
  }
  async UniversalQueueQuery(): Promise<UniversalQueueQueryMutation> {
    const statement = `mutation UniversalQueueQuery {
        universalQueueQuery {
          __typename
          statusCode
          rows {
            __typename
            incidentId
            monitoringCenterId
            createdAt
            primaryDisposition
            siteName
            overSLASeconds
            monitoringCenterName
            assignedTo
            assignBy
            username
            url
            verifiedAlarm
          }
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <UniversalQueueQueryMutation>response.data.universalQueueQuery;
  }
  async SnoozeDevices(
    siteId: string,
    cameraIds: Array<string>,
    snoozeReason: string,
    snoozeTime: number
  ): Promise<SnoozeDevicesMutation> {
    const statement = `mutation SnoozeDevices($siteId: ID!, $cameraIds: [ID!]!, $snoozeReason: String!, $snoozeTime: Int!) {
        snoozeDevices(
          siteId: $siteId
          cameraIds: $cameraIds
          snoozeReason: $snoozeReason
          snoozeTime: $snoozeTime
        ) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId,
      cameraIds,
      snoozeReason,
      snoozeTime
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SnoozeDevicesMutation>response.data.snoozeDevices;
  }
  async StopSnooze(
    siteId: string,
    cameraIds: Array<string | null>
  ): Promise<StopSnoozeMutation> {
    const statement = `mutation StopSnooze($siteId: ID!, $cameraIds: [ID]!) {
        stopSnooze(siteId: $siteId, cameraIds: $cameraIds) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      siteId,
      cameraIds
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StopSnoozeMutation>response.data.stopSnooze;
  }
  async CreateIncident(
    input: CreateIncidentInput,
    condition?: ModelIncidentConditionInput
  ): Promise<CreateIncidentMutation> {
    const statement = `mutation CreateIncident($input: CreateIncidentInput!, $condition: ModelIncidentConditionInput) {
        createIncident(input: $input, condition: $condition) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentMutation>response.data.createIncident;
  }
  async UpdateIncident(
    input: UpdateIncidentInput,
    condition?: ModelIncidentConditionInput
  ): Promise<UpdateIncidentMutation> {
    const statement = `mutation UpdateIncident($input: UpdateIncidentInput!, $condition: ModelIncidentConditionInput) {
        updateIncident(input: $input, condition: $condition) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentMutation>response.data.updateIncident;
  }
  async DeleteIncident(
    input: DeleteIncidentInput,
    condition?: ModelIncidentConditionInput
  ): Promise<DeleteIncidentMutation> {
    const statement = `mutation DeleteIncident($input: DeleteIncidentInput!, $condition: ModelIncidentConditionInput) {
        deleteIncident(input: $input, condition: $condition) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentMutation>response.data.deleteIncident;
  }
  async CreateIncidentNote(
    input: CreateIncidentNoteInput,
    condition?: ModelIncidentNoteConditionInput
  ): Promise<CreateIncidentNoteMutation> {
    const statement = `mutation CreateIncidentNote($input: CreateIncidentNoteInput!, $condition: ModelIncidentNoteConditionInput) {
        createIncidentNote(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentNoteMutation>response.data.createIncidentNote;
  }
  async UpdateIncidentNote(
    input: UpdateIncidentNoteInput,
    condition?: ModelIncidentNoteConditionInput
  ): Promise<UpdateIncidentNoteMutation> {
    const statement = `mutation UpdateIncidentNote($input: UpdateIncidentNoteInput!, $condition: ModelIncidentNoteConditionInput) {
        updateIncidentNote(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentNoteMutation>response.data.updateIncidentNote;
  }
  async DeleteIncidentNote(
    input: DeleteIncidentNoteInput,
    condition?: ModelIncidentNoteConditionInput
  ): Promise<DeleteIncidentNoteMutation> {
    const statement = `mutation DeleteIncidentNote($input: DeleteIncidentNoteInput!, $condition: ModelIncidentNoteConditionInput) {
        deleteIncidentNote(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentNoteMutation>response.data.deleteIncidentNote;
  }
  async CreateIncidentActionList(
    input: CreateIncidentActionListInput,
    condition?: ModelIncidentActionListConditionInput
  ): Promise<CreateIncidentActionListMutation> {
    const statement = `mutation CreateIncidentActionList($input: CreateIncidentActionListInput!, $condition: ModelIncidentActionListConditionInput) {
        createIncidentActionList(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentActionListMutation>(
      response.data.createIncidentActionList
    );
  }
  async UpdateIncidentActionList(
    input: UpdateIncidentActionListInput,
    condition?: ModelIncidentActionListConditionInput
  ): Promise<UpdateIncidentActionListMutation> {
    const statement = `mutation UpdateIncidentActionList($input: UpdateIncidentActionListInput!, $condition: ModelIncidentActionListConditionInput) {
        updateIncidentActionList(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentActionListMutation>(
      response.data.updateIncidentActionList
    );
  }
  async DeleteIncidentActionList(
    input: DeleteIncidentActionListInput,
    condition?: ModelIncidentActionListConditionInput
  ): Promise<DeleteIncidentActionListMutation> {
    const statement = `mutation DeleteIncidentActionList($input: DeleteIncidentActionListInput!, $condition: ModelIncidentActionListConditionInput) {
        deleteIncidentActionList(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentActionListMutation>(
      response.data.deleteIncidentActionList
    );
  }
  async CreateProcedureTask(
    input: CreateProcedureTaskInput,
    condition?: ModelProcedureTaskConditionInput
  ): Promise<CreateProcedureTaskMutation> {
    const statement = `mutation CreateProcedureTask($input: CreateProcedureTaskInput!, $condition: ModelProcedureTaskConditionInput) {
        createProcedureTask(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProcedureTaskMutation>response.data.createProcedureTask;
  }
  async UpdateProcedureTask(
    input: UpdateProcedureTaskInput,
    condition?: ModelProcedureTaskConditionInput
  ): Promise<UpdateProcedureTaskMutation> {
    const statement = `mutation UpdateProcedureTask($input: UpdateProcedureTaskInput!, $condition: ModelProcedureTaskConditionInput) {
        updateProcedureTask(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProcedureTaskMutation>response.data.updateProcedureTask;
  }
  async DeleteProcedureTask(
    input: DeleteProcedureTaskInput,
    condition?: ModelProcedureTaskConditionInput
  ): Promise<DeleteProcedureTaskMutation> {
    const statement = `mutation DeleteProcedureTask($input: DeleteProcedureTaskInput!, $condition: ModelProcedureTaskConditionInput) {
        deleteProcedureTask(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProcedureTaskMutation>response.data.deleteProcedureTask;
  }
  async CreateIncidentBundleTime(
    input: CreateIncidentBundleTimeInput,
    condition?: ModelIncidentBundleTimeConditionInput
  ): Promise<CreateIncidentBundleTimeMutation> {
    const statement = `mutation CreateIncidentBundleTime($input: CreateIncidentBundleTimeInput!, $condition: ModelIncidentBundleTimeConditionInput) {
        createIncidentBundleTime(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentBundleTimeMutation>(
      response.data.createIncidentBundleTime
    );
  }
  async UpdateIncidentBundleTime(
    input: UpdateIncidentBundleTimeInput,
    condition?: ModelIncidentBundleTimeConditionInput
  ): Promise<UpdateIncidentBundleTimeMutation> {
    const statement = `mutation UpdateIncidentBundleTime($input: UpdateIncidentBundleTimeInput!, $condition: ModelIncidentBundleTimeConditionInput) {
        updateIncidentBundleTime(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentBundleTimeMutation>(
      response.data.updateIncidentBundleTime
    );
  }
  async DeleteIncidentBundleTime(
    input: DeleteIncidentBundleTimeInput,
    condition?: ModelIncidentBundleTimeConditionInput
  ): Promise<DeleteIncidentBundleTimeMutation> {
    const statement = `mutation DeleteIncidentBundleTime($input: DeleteIncidentBundleTimeInput!, $condition: ModelIncidentBundleTimeConditionInput) {
        deleteIncidentBundleTime(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentBundleTimeMutation>(
      response.data.deleteIncidentBundleTime
    );
  }
  async CreateCameraEvent(
    input: CreateCameraEventInput,
    condition?: ModelCameraEventConditionInput
  ): Promise<CreateCameraEventMutation> {
    const statement = `mutation CreateCameraEvent($input: CreateCameraEventInput!, $condition: ModelCameraEventConditionInput) {
        createCameraEvent(input: $input, condition: $condition) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraEventMutation>response.data.createCameraEvent;
  }
  async UpdateCameraEvent(
    input: UpdateCameraEventInput,
    condition?: ModelCameraEventConditionInput
  ): Promise<UpdateCameraEventMutation> {
    const statement = `mutation UpdateCameraEvent($input: UpdateCameraEventInput!, $condition: ModelCameraEventConditionInput) {
        updateCameraEvent(input: $input, condition: $condition) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraEventMutation>response.data.updateCameraEvent;
  }
  async DeleteCameraEvent(
    input: DeleteCameraEventInput,
    condition?: ModelCameraEventConditionInput
  ): Promise<DeleteCameraEventMutation> {
    const statement = `mutation DeleteCameraEvent($input: DeleteCameraEventInput!, $condition: ModelCameraEventConditionInput) {
        deleteCameraEvent(input: $input, condition: $condition) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraEventMutation>response.data.deleteCameraEvent;
  }
  async CreateSecondaryCameraEvent(
    input: CreateSecondaryCameraEventInput,
    condition?: ModelSecondaryCameraEventConditionInput
  ): Promise<CreateSecondaryCameraEventMutation> {
    const statement = `mutation CreateSecondaryCameraEvent($input: CreateSecondaryCameraEventInput!, $condition: ModelSecondaryCameraEventConditionInput) {
        createSecondaryCameraEvent(input: $input, condition: $condition) {
          __typename
          id
          cameraEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          vmsSourceType
          ackTimestamp
          modifiedBy
          vmsUserId
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSecondaryCameraEventMutation>(
      response.data.createSecondaryCameraEvent
    );
  }
  async UpdateSecondaryCameraEvent(
    input: UpdateSecondaryCameraEventInput,
    condition?: ModelSecondaryCameraEventConditionInput
  ): Promise<UpdateSecondaryCameraEventMutation> {
    const statement = `mutation UpdateSecondaryCameraEvent($input: UpdateSecondaryCameraEventInput!, $condition: ModelSecondaryCameraEventConditionInput) {
        updateSecondaryCameraEvent(input: $input, condition: $condition) {
          __typename
          id
          cameraEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          vmsSourceType
          ackTimestamp
          modifiedBy
          vmsUserId
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSecondaryCameraEventMutation>(
      response.data.updateSecondaryCameraEvent
    );
  }
  async DeleteSecondaryCameraEvent(
    input: DeleteSecondaryCameraEventInput,
    condition?: ModelSecondaryCameraEventConditionInput
  ): Promise<DeleteSecondaryCameraEventMutation> {
    const statement = `mutation DeleteSecondaryCameraEvent($input: DeleteSecondaryCameraEventInput!, $condition: ModelSecondaryCameraEventConditionInput) {
        deleteSecondaryCameraEvent(input: $input, condition: $condition) {
          __typename
          id
          cameraEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          vmsSourceType
          ackTimestamp
          modifiedBy
          vmsUserId
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSecondaryCameraEventMutation>(
      response.data.deleteSecondaryCameraEvent
    );
  }
  async CreateCustomer(
    input: CreateCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<CreateCustomerMutation> {
    const statement = `mutation CreateCustomer($input: CreateCustomerInput!, $condition: ModelCustomerConditionInput) {
        createCustomer(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerMutation>response.data.createCustomer;
  }
  async UpdateCustomer(
    input: UpdateCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<UpdateCustomerMutation> {
    const statement = `mutation UpdateCustomer($input: UpdateCustomerInput!, $condition: ModelCustomerConditionInput) {
        updateCustomer(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerMutation>response.data.updateCustomer;
  }
  async DeleteCustomer(
    input: DeleteCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<DeleteCustomerMutation> {
    const statement = `mutation DeleteCustomer($input: DeleteCustomerInput!, $condition: ModelCustomerConditionInput) {
        deleteCustomer(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerMutation>response.data.deleteCustomer;
  }
  async CreateVMSDetails(
    input: CreateVMSDetailsInput,
    condition?: ModelVMSDetailsConditionInput
  ): Promise<CreateVMSDetailsMutation> {
    const statement = `mutation CreateVMSDetails($input: CreateVMSDetailsInput!, $condition: ModelVMSDetailsConditionInput) {
        createVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVMSDetailsMutation>response.data.createVMSDetails;
  }
  async UpdateVMSDetails(
    input: UpdateVMSDetailsInput,
    condition?: ModelVMSDetailsConditionInput
  ): Promise<UpdateVMSDetailsMutation> {
    const statement = `mutation UpdateVMSDetails($input: UpdateVMSDetailsInput!, $condition: ModelVMSDetailsConditionInput) {
        updateVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVMSDetailsMutation>response.data.updateVMSDetails;
  }
  async DeleteVMSDetails(
    input: DeleteVMSDetailsInput,
    condition?: ModelVMSDetailsConditionInput
  ): Promise<DeleteVMSDetailsMutation> {
    const statement = `mutation DeleteVMSDetails($input: DeleteVMSDetailsInput!, $condition: ModelVMSDetailsConditionInput) {
        deleteVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVMSDetailsMutation>response.data.deleteVMSDetails;
  }
  async CreateDashboardDetail(
    input: CreateDashboardDetailInput,
    condition?: ModelDashboardDetailConditionInput
  ): Promise<CreateDashboardDetailMutation> {
    const statement = `mutation CreateDashboardDetail($input: CreateDashboardDetailInput!, $condition: ModelDashboardDetailConditionInput) {
        createDashboardDetail(input: $input, condition: $condition) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDashboardDetailMutation>response.data.createDashboardDetail;
  }
  async UpdateDashboardDetail(
    input: UpdateDashboardDetailInput,
    condition?: ModelDashboardDetailConditionInput
  ): Promise<UpdateDashboardDetailMutation> {
    const statement = `mutation UpdateDashboardDetail($input: UpdateDashboardDetailInput!, $condition: ModelDashboardDetailConditionInput) {
        updateDashboardDetail(input: $input, condition: $condition) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDashboardDetailMutation>response.data.updateDashboardDetail;
  }
  async DeleteDashboardDetail(
    input: DeleteDashboardDetailInput,
    condition?: ModelDashboardDetailConditionInput
  ): Promise<DeleteDashboardDetailMutation> {
    const statement = `mutation DeleteDashboardDetail($input: DeleteDashboardDetailInput!, $condition: ModelDashboardDetailConditionInput) {
        deleteDashboardDetail(input: $input, condition: $condition) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDashboardDetailMutation>response.data.deleteDashboardDetail;
  }
  async CreateBoldBIDetails(
    input: CreateBoldBIDetailsInput,
    condition?: ModelBoldBIDetailsConditionInput
  ): Promise<CreateBoldBIDetailsMutation> {
    const statement = `mutation CreateBoldBIDetails($input: CreateBoldBIDetailsInput!, $condition: ModelBoldBIDetailsConditionInput) {
        createBoldBIDetails(input: $input, condition: $condition) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBoldBIDetailsMutation>response.data.createBoldBIDetails;
  }
  async UpdateBoldBIDetails(
    input: UpdateBoldBIDetailsInput,
    condition?: ModelBoldBIDetailsConditionInput
  ): Promise<UpdateBoldBIDetailsMutation> {
    const statement = `mutation UpdateBoldBIDetails($input: UpdateBoldBIDetailsInput!, $condition: ModelBoldBIDetailsConditionInput) {
        updateBoldBIDetails(input: $input, condition: $condition) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBoldBIDetailsMutation>response.data.updateBoldBIDetails;
  }
  async DeleteBoldBIDetails(
    input: DeleteBoldBIDetailsInput,
    condition?: ModelBoldBIDetailsConditionInput
  ): Promise<DeleteBoldBIDetailsMutation> {
    const statement = `mutation DeleteBoldBIDetails($input: DeleteBoldBIDetailsInput!, $condition: ModelBoldBIDetailsConditionInput) {
        deleteBoldBIDetails(input: $input, condition: $condition) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBoldBIDetailsMutation>response.data.deleteBoldBIDetails;
  }
  async CreateRapidSOSDetails(
    input: CreateRapidSOSDetailsInput,
    condition?: ModelRapidSOSDetailsConditionInput
  ): Promise<CreateRapidSOSDetailsMutation> {
    const statement = `mutation CreateRapidSOSDetails($input: CreateRapidSOSDetailsInput!, $condition: ModelRapidSOSDetailsConditionInput) {
        createRapidSOSDetails(input: $input, condition: $condition) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSDetailsMutation>response.data.createRapidSOSDetails;
  }
  async UpdateRapidSOSDetails(
    input: UpdateRapidSOSDetailsInput,
    condition?: ModelRapidSOSDetailsConditionInput
  ): Promise<UpdateRapidSOSDetailsMutation> {
    const statement = `mutation UpdateRapidSOSDetails($input: UpdateRapidSOSDetailsInput!, $condition: ModelRapidSOSDetailsConditionInput) {
        updateRapidSOSDetails(input: $input, condition: $condition) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSDetailsMutation>response.data.updateRapidSOSDetails;
  }
  async DeleteRapidSOSDetails(
    input: DeleteRapidSOSDetailsInput,
    condition?: ModelRapidSOSDetailsConditionInput
  ): Promise<DeleteRapidSOSDetailsMutation> {
    const statement = `mutation DeleteRapidSOSDetails($input: DeleteRapidSOSDetailsInput!, $condition: ModelRapidSOSDetailsConditionInput) {
        deleteRapidSOSDetails(input: $input, condition: $condition) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSDetailsMutation>response.data.deleteRapidSOSDetails;
  }
  async CreateAddress(
    input: CreateAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<CreateAddressMutation> {
    const statement = `mutation CreateAddress($input: CreateAddressInput!, $condition: ModelAddressConditionInput) {
        createAddress(input: $input, condition: $condition) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAddressMutation>response.data.createAddress;
  }
  async UpdateAddress(
    input: UpdateAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<UpdateAddressMutation> {
    const statement = `mutation UpdateAddress($input: UpdateAddressInput!, $condition: ModelAddressConditionInput) {
        updateAddress(input: $input, condition: $condition) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAddressMutation>response.data.updateAddress;
  }
  async DeleteAddress(
    input: DeleteAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<DeleteAddressMutation> {
    const statement = `mutation DeleteAddress($input: DeleteAddressInput!, $condition: ModelAddressConditionInput) {
        deleteAddress(input: $input, condition: $condition) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAddressMutation>response.data.deleteAddress;
  }
  async CreateContact(
    input: CreateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<CreateContactMutation> {
    const statement = `mutation CreateContact($input: CreateContactInput!, $condition: ModelContactConditionInput) {
        createContact(input: $input, condition: $condition) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContactMutation>response.data.createContact;
  }
  async UpdateContact(
    input: UpdateContactInput,
    condition?: ModelContactConditionInput
  ): Promise<UpdateContactMutation> {
    const statement = `mutation UpdateContact($input: UpdateContactInput!, $condition: ModelContactConditionInput) {
        updateContact(input: $input, condition: $condition) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContactMutation>response.data.updateContact;
  }
  async DeleteContact(
    input: DeleteContactInput,
    condition?: ModelContactConditionInput
  ): Promise<DeleteContactMutation> {
    const statement = `mutation DeleteContact($input: DeleteContactInput!, $condition: ModelContactConditionInput) {
        deleteContact(input: $input, condition: $condition) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContactMutation>response.data.deleteContact;
  }
  async CreatePendingSite(
    input: CreatePendingSiteInput,
    condition?: ModelPendingSiteConditionInput
  ): Promise<CreatePendingSiteMutation> {
    const statement = `mutation CreatePendingSite($input: CreatePendingSiteInput!, $condition: ModelPendingSiteConditionInput) {
        createPendingSite(input: $input, condition: $condition) {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePendingSiteMutation>response.data.createPendingSite;
  }
  async UpdatePendingSite(
    input: UpdatePendingSiteInput,
    condition?: ModelPendingSiteConditionInput
  ): Promise<UpdatePendingSiteMutation> {
    const statement = `mutation UpdatePendingSite($input: UpdatePendingSiteInput!, $condition: ModelPendingSiteConditionInput) {
        updatePendingSite(input: $input, condition: $condition) {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePendingSiteMutation>response.data.updatePendingSite;
  }
  async DeletePendingSite(
    input: DeletePendingSiteInput,
    condition?: ModelPendingSiteConditionInput
  ): Promise<DeletePendingSiteMutation> {
    const statement = `mutation DeletePendingSite($input: DeletePendingSiteInput!, $condition: ModelPendingSiteConditionInput) {
        deletePendingSite(input: $input, condition: $condition) {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePendingSiteMutation>response.data.deletePendingSite;
  }
  async CreateSite(
    input: CreateSiteInput,
    condition?: ModelSiteConditionInput
  ): Promise<CreateSiteMutation> {
    const statement = `mutation CreateSite($input: CreateSiteInput!, $condition: ModelSiteConditionInput) {
        createSite(input: $input, condition: $condition) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteMutation>response.data.createSite;
  }
  async UpdateSite(
    input: UpdateSiteInput,
    condition?: ModelSiteConditionInput
  ): Promise<UpdateSiteMutation> {
    const statement = `mutation UpdateSite($input: UpdateSiteInput!, $condition: ModelSiteConditionInput) {
        updateSite(input: $input, condition: $condition) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteMutation>response.data.updateSite;
  }
  async DeleteSite(
    input: DeleteSiteInput,
    condition?: ModelSiteConditionInput
  ): Promise<DeleteSiteMutation> {
    const statement = `mutation DeleteSite($input: DeleteSiteInput!, $condition: ModelSiteConditionInput) {
        deleteSite(input: $input, condition: $condition) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteMutation>response.data.deleteSite;
  }
  async CreateSiteSupvisdSetting(
    input: CreateSiteSupvisdSettingInput,
    condition?: ModelSiteSupvisdSettingConditionInput
  ): Promise<CreateSiteSupvisdSettingMutation> {
    const statement = `mutation CreateSiteSupvisdSetting($input: CreateSiteSupvisdSettingInput!, $condition: ModelSiteSupvisdSettingConditionInput) {
        createSiteSupvisdSetting(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteSupvisdSettingMutation>(
      response.data.createSiteSupvisdSetting
    );
  }
  async UpdateSiteSupvisdSetting(
    input: UpdateSiteSupvisdSettingInput,
    condition?: ModelSiteSupvisdSettingConditionInput
  ): Promise<UpdateSiteSupvisdSettingMutation> {
    const statement = `mutation UpdateSiteSupvisdSetting($input: UpdateSiteSupvisdSettingInput!, $condition: ModelSiteSupvisdSettingConditionInput) {
        updateSiteSupvisdSetting(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteSupvisdSettingMutation>(
      response.data.updateSiteSupvisdSetting
    );
  }
  async DeleteSiteSupvisdSetting(
    input: DeleteSiteSupvisdSettingInput,
    condition?: ModelSiteSupvisdSettingConditionInput
  ): Promise<DeleteSiteSupvisdSettingMutation> {
    const statement = `mutation DeleteSiteSupvisdSetting($input: DeleteSiteSupvisdSettingInput!, $condition: ModelSiteSupvisdSettingConditionInput) {
        deleteSiteSupvisdSetting(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteSupvisdSettingMutation>(
      response.data.deleteSiteSupvisdSetting
    );
  }
  async CreateSupvisdEventRule(
    input: CreateSupvisdEventRuleInput,
    condition?: ModelSupvisdEventRuleConditionInput
  ): Promise<CreateSupvisdEventRuleMutation> {
    const statement = `mutation CreateSupvisdEventRule($input: CreateSupvisdEventRuleInput!, $condition: ModelSupvisdEventRuleConditionInput) {
        createSupvisdEventRule(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSupvisdEventRuleMutation>response.data.createSupvisdEventRule;
  }
  async UpdateSupvisdEventRule(
    input: UpdateSupvisdEventRuleInput,
    condition?: ModelSupvisdEventRuleConditionInput
  ): Promise<UpdateSupvisdEventRuleMutation> {
    const statement = `mutation UpdateSupvisdEventRule($input: UpdateSupvisdEventRuleInput!, $condition: ModelSupvisdEventRuleConditionInput) {
        updateSupvisdEventRule(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSupvisdEventRuleMutation>response.data.updateSupvisdEventRule;
  }
  async DeleteSupvisdEventRule(
    input: DeleteSupvisdEventRuleInput,
    condition?: ModelSupvisdEventRuleConditionInput
  ): Promise<DeleteSupvisdEventRuleMutation> {
    const statement = `mutation DeleteSupvisdEventRule($input: DeleteSupvisdEventRuleInput!, $condition: ModelSupvisdEventRuleConditionInput) {
        deleteSupvisdEventRule(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSupvisdEventRuleMutation>response.data.deleteSupvisdEventRule;
  }
  async CreateSiteDeviceStatusConfig(
    input: CreateSiteDeviceStatusConfigInput,
    condition?: ModelSiteDeviceStatusConfigConditionInput
  ): Promise<CreateSiteDeviceStatusConfigMutation> {
    const statement = `mutation CreateSiteDeviceStatusConfig($input: CreateSiteDeviceStatusConfigInput!, $condition: ModelSiteDeviceStatusConfigConditionInput) {
        createSiteDeviceStatusConfig(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteDeviceStatusConfigMutation>(
      response.data.createSiteDeviceStatusConfig
    );
  }
  async UpdateSiteDeviceStatusConfig(
    input: UpdateSiteDeviceStatusConfigInput,
    condition?: ModelSiteDeviceStatusConfigConditionInput
  ): Promise<UpdateSiteDeviceStatusConfigMutation> {
    const statement = `mutation UpdateSiteDeviceStatusConfig($input: UpdateSiteDeviceStatusConfigInput!, $condition: ModelSiteDeviceStatusConfigConditionInput) {
        updateSiteDeviceStatusConfig(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteDeviceStatusConfigMutation>(
      response.data.updateSiteDeviceStatusConfig
    );
  }
  async DeleteSiteDeviceStatusConfig(
    input: DeleteSiteDeviceStatusConfigInput,
    condition?: ModelSiteDeviceStatusConfigConditionInput
  ): Promise<DeleteSiteDeviceStatusConfigMutation> {
    const statement = `mutation DeleteSiteDeviceStatusConfig($input: DeleteSiteDeviceStatusConfigInput!, $condition: ModelSiteDeviceStatusConfigConditionInput) {
        deleteSiteDeviceStatusConfig(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteDeviceStatusConfigMutation>(
      response.data.deleteSiteDeviceStatusConfig
    );
  }
  async CreateTrigger(
    input: CreateTriggerInput,
    condition?: ModelTriggerConditionInput
  ): Promise<CreateTriggerMutation> {
    const statement = `mutation CreateTrigger($input: CreateTriggerInput!, $condition: ModelTriggerConditionInput) {
        createTrigger(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTriggerMutation>response.data.createTrigger;
  }
  async UpdateTrigger(
    input: UpdateTriggerInput,
    condition?: ModelTriggerConditionInput
  ): Promise<UpdateTriggerMutation> {
    const statement = `mutation UpdateTrigger($input: UpdateTriggerInput!, $condition: ModelTriggerConditionInput) {
        updateTrigger(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTriggerMutation>response.data.updateTrigger;
  }
  async DeleteTrigger(
    input: DeleteTriggerInput,
    condition?: ModelTriggerConditionInput
  ): Promise<DeleteTriggerMutation> {
    const statement = `mutation DeleteTrigger($input: DeleteTriggerInput!, $condition: ModelTriggerConditionInput) {
        deleteTrigger(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTriggerMutation>response.data.deleteTrigger;
  }
  async CreateSiteIntegration(
    input: CreateSiteIntegrationInput,
    condition?: ModelSiteIntegrationConditionInput
  ): Promise<CreateSiteIntegrationMutation> {
    const statement = `mutation CreateSiteIntegration($input: CreateSiteIntegrationInput!, $condition: ModelSiteIntegrationConditionInput) {
        createSiteIntegration(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteIntegrationMutation>response.data.createSiteIntegration;
  }
  async UpdateSiteIntegration(
    input: UpdateSiteIntegrationInput,
    condition?: ModelSiteIntegrationConditionInput
  ): Promise<UpdateSiteIntegrationMutation> {
    const statement = `mutation UpdateSiteIntegration($input: UpdateSiteIntegrationInput!, $condition: ModelSiteIntegrationConditionInput) {
        updateSiteIntegration(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteIntegrationMutation>response.data.updateSiteIntegration;
  }
  async DeleteSiteIntegration(
    input: DeleteSiteIntegrationInput,
    condition?: ModelSiteIntegrationConditionInput
  ): Promise<DeleteSiteIntegrationMutation> {
    const statement = `mutation DeleteSiteIntegration($input: DeleteSiteIntegrationInput!, $condition: ModelSiteIntegrationConditionInput) {
        deleteSiteIntegration(input: $input, condition: $condition) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteIntegrationMutation>response.data.deleteSiteIntegration;
  }
  async CreateSiteVMSDetails(
    input: CreateSiteVMSDetailsInput,
    condition?: ModelSiteVMSDetailsConditionInput
  ): Promise<CreateSiteVMSDetailsMutation> {
    const statement = `mutation CreateSiteVMSDetails($input: CreateSiteVMSDetailsInput!, $condition: ModelSiteVMSDetailsConditionInput) {
        createSiteVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteVMSDetailsMutation>response.data.createSiteVMSDetails;
  }
  async UpdateSiteVMSDetails(
    input: UpdateSiteVMSDetailsInput,
    condition?: ModelSiteVMSDetailsConditionInput
  ): Promise<UpdateSiteVMSDetailsMutation> {
    const statement = `mutation UpdateSiteVMSDetails($input: UpdateSiteVMSDetailsInput!, $condition: ModelSiteVMSDetailsConditionInput) {
        updateSiteVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteVMSDetailsMutation>response.data.updateSiteVMSDetails;
  }
  async DeleteSiteVMSDetails(
    input: DeleteSiteVMSDetailsInput,
    condition?: ModelSiteVMSDetailsConditionInput
  ): Promise<DeleteSiteVMSDetailsMutation> {
    const statement = `mutation DeleteSiteVMSDetails($input: DeleteSiteVMSDetailsInput!, $condition: ModelSiteVMSDetailsConditionInput) {
        deleteSiteVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteVMSDetailsMutation>response.data.deleteSiteVMSDetails;
  }
  async CreateTrackTikDetails(
    input: CreateTrackTikDetailsInput,
    condition?: ModelTrackTikDetailsConditionInput
  ): Promise<CreateTrackTikDetailsMutation> {
    const statement = `mutation CreateTrackTikDetails($input: CreateTrackTikDetailsInput!, $condition: ModelTrackTikDetailsConditionInput) {
        createTrackTikDetails(input: $input, condition: $condition) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTrackTikDetailsMutation>response.data.createTrackTikDetails;
  }
  async UpdateTrackTikDetails(
    input: UpdateTrackTikDetailsInput,
    condition?: ModelTrackTikDetailsConditionInput
  ): Promise<UpdateTrackTikDetailsMutation> {
    const statement = `mutation UpdateTrackTikDetails($input: UpdateTrackTikDetailsInput!, $condition: ModelTrackTikDetailsConditionInput) {
        updateTrackTikDetails(input: $input, condition: $condition) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTrackTikDetailsMutation>response.data.updateTrackTikDetails;
  }
  async DeleteTrackTikDetails(
    input: DeleteTrackTikDetailsInput,
    condition?: ModelTrackTikDetailsConditionInput
  ): Promise<DeleteTrackTikDetailsMutation> {
    const statement = `mutation DeleteTrackTikDetails($input: DeleteTrackTikDetailsInput!, $condition: ModelTrackTikDetailsConditionInput) {
        deleteTrackTikDetails(input: $input, condition: $condition) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTrackTikDetailsMutation>response.data.deleteTrackTikDetails;
  }
  async CreateTrackTikTask(
    input: CreateTrackTikTaskInput,
    condition?: ModelTrackTikTaskConditionInput
  ): Promise<CreateTrackTikTaskMutation> {
    const statement = `mutation CreateTrackTikTask($input: CreateTrackTikTaskInput!, $condition: ModelTrackTikTaskConditionInput) {
        createTrackTikTask(input: $input, condition: $condition) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTrackTikTaskMutation>response.data.createTrackTikTask;
  }
  async UpdateTrackTikTask(
    input: UpdateTrackTikTaskInput,
    condition?: ModelTrackTikTaskConditionInput
  ): Promise<UpdateTrackTikTaskMutation> {
    const statement = `mutation UpdateTrackTikTask($input: UpdateTrackTikTaskInput!, $condition: ModelTrackTikTaskConditionInput) {
        updateTrackTikTask(input: $input, condition: $condition) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTrackTikTaskMutation>response.data.updateTrackTikTask;
  }
  async DeleteTrackTikTask(
    input: DeleteTrackTikTaskInput,
    condition?: ModelTrackTikTaskConditionInput
  ): Promise<DeleteTrackTikTaskMutation> {
    const statement = `mutation DeleteTrackTikTask($input: DeleteTrackTikTaskInput!, $condition: ModelTrackTikTaskConditionInput) {
        deleteTrackTikTask(input: $input, condition: $condition) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTrackTikTaskMutation>response.data.deleteTrackTikTask;
  }
  async CreateSiteEmergencyContact(
    input: CreateSiteEmergencyContactInput,
    condition?: ModelSiteEmergencyContactConditionInput
  ): Promise<CreateSiteEmergencyContactMutation> {
    const statement = `mutation CreateSiteEmergencyContact($input: CreateSiteEmergencyContactInput!, $condition: ModelSiteEmergencyContactConditionInput) {
        createSiteEmergencyContact(input: $input, condition: $condition) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteEmergencyContactMutation>(
      response.data.createSiteEmergencyContact
    );
  }
  async UpdateSiteEmergencyContact(
    input: UpdateSiteEmergencyContactInput,
    condition?: ModelSiteEmergencyContactConditionInput
  ): Promise<UpdateSiteEmergencyContactMutation> {
    const statement = `mutation UpdateSiteEmergencyContact($input: UpdateSiteEmergencyContactInput!, $condition: ModelSiteEmergencyContactConditionInput) {
        updateSiteEmergencyContact(input: $input, condition: $condition) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteEmergencyContactMutation>(
      response.data.updateSiteEmergencyContact
    );
  }
  async DeleteSiteEmergencyContact(
    input: DeleteSiteEmergencyContactInput,
    condition?: ModelSiteEmergencyContactConditionInput
  ): Promise<DeleteSiteEmergencyContactMutation> {
    const statement = `mutation DeleteSiteEmergencyContact($input: DeleteSiteEmergencyContactInput!, $condition: ModelSiteEmergencyContactConditionInput) {
        deleteSiteEmergencyContact(input: $input, condition: $condition) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteEmergencyContactMutation>(
      response.data.deleteSiteEmergencyContact
    );
  }
  async CreatePhone(
    input: CreatePhoneInput,
    condition?: ModelPhoneConditionInput
  ): Promise<CreatePhoneMutation> {
    const statement = `mutation CreatePhone($input: CreatePhoneInput!, $condition: ModelPhoneConditionInput) {
        createPhone(input: $input, condition: $condition) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePhoneMutation>response.data.createPhone;
  }
  async UpdatePhone(
    input: UpdatePhoneInput,
    condition?: ModelPhoneConditionInput
  ): Promise<UpdatePhoneMutation> {
    const statement = `mutation UpdatePhone($input: UpdatePhoneInput!, $condition: ModelPhoneConditionInput) {
        updatePhone(input: $input, condition: $condition) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePhoneMutation>response.data.updatePhone;
  }
  async DeletePhone(
    input: DeletePhoneInput,
    condition?: ModelPhoneConditionInput
  ): Promise<DeletePhoneMutation> {
    const statement = `mutation DeletePhone($input: DeletePhoneInput!, $condition: ModelPhoneConditionInput) {
        deletePhone(input: $input, condition: $condition) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePhoneMutation>response.data.deletePhone;
  }
  async CreateCamera(
    input: CreateCameraInput,
    condition?: ModelCameraConditionInput
  ): Promise<CreateCameraMutation> {
    const statement = `mutation CreateCamera($input: CreateCameraInput!, $condition: ModelCameraConditionInput) {
        createCamera(input: $input, condition: $condition) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraMutation>response.data.createCamera;
  }
  async UpdateCamera(
    input: UpdateCameraInput,
    condition?: ModelCameraConditionInput
  ): Promise<UpdateCameraMutation> {
    const statement = `mutation UpdateCamera($input: UpdateCameraInput!, $condition: ModelCameraConditionInput) {
        updateCamera(input: $input, condition: $condition) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraMutation>response.data.updateCamera;
  }
  async DeleteCamera(
    input: DeleteCameraInput,
    condition?: ModelCameraConditionInput
  ): Promise<DeleteCameraMutation> {
    const statement = `mutation DeleteCamera($input: DeleteCameraInput!, $condition: ModelCameraConditionInput) {
        deleteCamera(input: $input, condition: $condition) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraMutation>response.data.deleteCamera;
  }
  async CreateObjectGroup(
    input: CreateObjectGroupInput,
    condition?: ModelObjectGroupConditionInput
  ): Promise<CreateObjectGroupMutation> {
    const statement = `mutation CreateObjectGroup($input: CreateObjectGroupInput!, $condition: ModelObjectGroupConditionInput) {
        createObjectGroup(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateObjectGroupMutation>response.data.createObjectGroup;
  }
  async UpdateObjectGroup(
    input: UpdateObjectGroupInput,
    condition?: ModelObjectGroupConditionInput
  ): Promise<UpdateObjectGroupMutation> {
    const statement = `mutation UpdateObjectGroup($input: UpdateObjectGroupInput!, $condition: ModelObjectGroupConditionInput) {
        updateObjectGroup(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateObjectGroupMutation>response.data.updateObjectGroup;
  }
  async DeleteObjectGroup(
    input: DeleteObjectGroupInput,
    condition?: ModelObjectGroupConditionInput
  ): Promise<DeleteObjectGroupMutation> {
    const statement = `mutation DeleteObjectGroup($input: DeleteObjectGroupInput!, $condition: ModelObjectGroupConditionInput) {
        deleteObjectGroup(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteObjectGroupMutation>response.data.deleteObjectGroup;
  }
  async CreateAudioCustomMessageType(
    input: CreateAudioCustomMessageTypeInput,
    condition?: ModelAudioCustomMessageTypeConditionInput
  ): Promise<CreateAudioCustomMessageTypeMutation> {
    const statement = `mutation CreateAudioCustomMessageType($input: CreateAudioCustomMessageTypeInput!, $condition: ModelAudioCustomMessageTypeConditionInput) {
        createAudioCustomMessageType(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAudioCustomMessageTypeMutation>(
      response.data.createAudioCustomMessageType
    );
  }
  async UpdateAudioCustomMessageType(
    input: UpdateAudioCustomMessageTypeInput,
    condition?: ModelAudioCustomMessageTypeConditionInput
  ): Promise<UpdateAudioCustomMessageTypeMutation> {
    const statement = `mutation UpdateAudioCustomMessageType($input: UpdateAudioCustomMessageTypeInput!, $condition: ModelAudioCustomMessageTypeConditionInput) {
        updateAudioCustomMessageType(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAudioCustomMessageTypeMutation>(
      response.data.updateAudioCustomMessageType
    );
  }
  async DeleteAudioCustomMessageType(
    input: DeleteAudioCustomMessageTypeInput,
    condition?: ModelAudioCustomMessageTypeConditionInput
  ): Promise<DeleteAudioCustomMessageTypeMutation> {
    const statement = `mutation DeleteAudioCustomMessageType($input: DeleteAudioCustomMessageTypeInput!, $condition: ModelAudioCustomMessageTypeConditionInput) {
        deleteAudioCustomMessageType(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAudioCustomMessageTypeMutation>(
      response.data.deleteAudioCustomMessageType
    );
  }
  async CreateCameraQualitySettings(
    input: CreateCameraQualitySettingsInput,
    condition?: ModelCameraQualitySettingsConditionInput
  ): Promise<CreateCameraQualitySettingsMutation> {
    const statement = `mutation CreateCameraQualitySettings($input: CreateCameraQualitySettingsInput!, $condition: ModelCameraQualitySettingsConditionInput) {
        createCameraQualitySettings(input: $input, condition: $condition) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraQualitySettingsMutation>(
      response.data.createCameraQualitySettings
    );
  }
  async UpdateCameraQualitySettings(
    input: UpdateCameraQualitySettingsInput,
    condition?: ModelCameraQualitySettingsConditionInput
  ): Promise<UpdateCameraQualitySettingsMutation> {
    const statement = `mutation UpdateCameraQualitySettings($input: UpdateCameraQualitySettingsInput!, $condition: ModelCameraQualitySettingsConditionInput) {
        updateCameraQualitySettings(input: $input, condition: $condition) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraQualitySettingsMutation>(
      response.data.updateCameraQualitySettings
    );
  }
  async DeleteCameraQualitySettings(
    input: DeleteCameraQualitySettingsInput,
    condition?: ModelCameraQualitySettingsConditionInput
  ): Promise<DeleteCameraQualitySettingsMutation> {
    const statement = `mutation DeleteCameraQualitySettings($input: DeleteCameraQualitySettingsInput!, $condition: ModelCameraQualitySettingsConditionInput) {
        deleteCameraQualitySettings(input: $input, condition: $condition) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraQualitySettingsMutation>(
      response.data.deleteCameraQualitySettings
    );
  }
  async CreateCameraSceneDetectionSettings(
    input: CreateCameraSceneDetectionSettingsInput,
    condition?: ModelCameraSceneDetectionSettingsConditionInput
  ): Promise<CreateCameraSceneDetectionSettingsMutation> {
    const statement = `mutation CreateCameraSceneDetectionSettings($input: CreateCameraSceneDetectionSettingsInput!, $condition: ModelCameraSceneDetectionSettingsConditionInput) {
        createCameraSceneDetectionSettings(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraSceneDetectionSettingsMutation>(
      response.data.createCameraSceneDetectionSettings
    );
  }
  async UpdateCameraSceneDetectionSettings(
    input: UpdateCameraSceneDetectionSettingsInput,
    condition?: ModelCameraSceneDetectionSettingsConditionInput
  ): Promise<UpdateCameraSceneDetectionSettingsMutation> {
    const statement = `mutation UpdateCameraSceneDetectionSettings($input: UpdateCameraSceneDetectionSettingsInput!, $condition: ModelCameraSceneDetectionSettingsConditionInput) {
        updateCameraSceneDetectionSettings(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraSceneDetectionSettingsMutation>(
      response.data.updateCameraSceneDetectionSettings
    );
  }
  async DeleteCameraSceneDetectionSettings(
    input: DeleteCameraSceneDetectionSettingsInput,
    condition?: ModelCameraSceneDetectionSettingsConditionInput
  ): Promise<DeleteCameraSceneDetectionSettingsMutation> {
    const statement = `mutation DeleteCameraSceneDetectionSettings($input: DeleteCameraSceneDetectionSettingsInput!, $condition: ModelCameraSceneDetectionSettingsConditionInput) {
        deleteCameraSceneDetectionSettings(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraSceneDetectionSettingsMutation>(
      response.data.deleteCameraSceneDetectionSettings
    );
  }
  async CreateCameraSceneDetectionThreshold(
    input: CreateCameraSceneDetectionThresholdInput,
    condition?: ModelCameraSceneDetectionThresholdConditionInput
  ): Promise<CreateCameraSceneDetectionThresholdMutation> {
    const statement = `mutation CreateCameraSceneDetectionThreshold($input: CreateCameraSceneDetectionThresholdInput!, $condition: ModelCameraSceneDetectionThresholdConditionInput) {
        createCameraSceneDetectionThreshold(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraSceneDetectionThresholdMutation>(
      response.data.createCameraSceneDetectionThreshold
    );
  }
  async UpdateCameraSceneDetectionThreshold(
    input: UpdateCameraSceneDetectionThresholdInput,
    condition?: ModelCameraSceneDetectionThresholdConditionInput
  ): Promise<UpdateCameraSceneDetectionThresholdMutation> {
    const statement = `mutation UpdateCameraSceneDetectionThreshold($input: UpdateCameraSceneDetectionThresholdInput!, $condition: ModelCameraSceneDetectionThresholdConditionInput) {
        updateCameraSceneDetectionThreshold(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraSceneDetectionThresholdMutation>(
      response.data.updateCameraSceneDetectionThreshold
    );
  }
  async DeleteCameraSceneDetectionThreshold(
    input: DeleteCameraSceneDetectionThresholdInput,
    condition?: ModelCameraSceneDetectionThresholdConditionInput
  ): Promise<DeleteCameraSceneDetectionThresholdMutation> {
    const statement = `mutation DeleteCameraSceneDetectionThreshold($input: DeleteCameraSceneDetectionThresholdInput!, $condition: ModelCameraSceneDetectionThresholdConditionInput) {
        deleteCameraSceneDetectionThreshold(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraSceneDetectionThresholdMutation>(
      response.data.deleteCameraSceneDetectionThreshold
    );
  }
  async CreateCameraSceneDetectionResult(
    input: CreateCameraSceneDetectionResultInput,
    condition?: ModelCameraSceneDetectionResultConditionInput
  ): Promise<CreateCameraSceneDetectionResultMutation> {
    const statement = `mutation CreateCameraSceneDetectionResult($input: CreateCameraSceneDetectionResultInput!, $condition: ModelCameraSceneDetectionResultConditionInput) {
        createCameraSceneDetectionResult(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraSceneDetectionResultMutation>(
      response.data.createCameraSceneDetectionResult
    );
  }
  async UpdateCameraSceneDetectionResult(
    input: UpdateCameraSceneDetectionResultInput,
    condition?: ModelCameraSceneDetectionResultConditionInput
  ): Promise<UpdateCameraSceneDetectionResultMutation> {
    const statement = `mutation UpdateCameraSceneDetectionResult($input: UpdateCameraSceneDetectionResultInput!, $condition: ModelCameraSceneDetectionResultConditionInput) {
        updateCameraSceneDetectionResult(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraSceneDetectionResultMutation>(
      response.data.updateCameraSceneDetectionResult
    );
  }
  async DeleteCameraSceneDetectionResult(
    input: DeleteCameraSceneDetectionResultInput,
    condition?: ModelCameraSceneDetectionResultConditionInput
  ): Promise<DeleteCameraSceneDetectionResultMutation> {
    const statement = `mutation DeleteCameraSceneDetectionResult($input: DeleteCameraSceneDetectionResultInput!, $condition: ModelCameraSceneDetectionResultConditionInput) {
        deleteCameraSceneDetectionResult(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraSceneDetectionResultMutation>(
      response.data.deleteCameraSceneDetectionResult
    );
  }
  async CreateCameraSceneDetectionResultItem(
    input: CreateCameraSceneDetectionResultItemInput,
    condition?: ModelCameraSceneDetectionResultItemConditionInput
  ): Promise<CreateCameraSceneDetectionResultItemMutation> {
    const statement = `mutation CreateCameraSceneDetectionResultItem($input: CreateCameraSceneDetectionResultItemInput!, $condition: ModelCameraSceneDetectionResultItemConditionInput) {
        createCameraSceneDetectionResultItem(input: $input, condition: $condition) {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraSceneDetectionResultItemMutation>(
      response.data.createCameraSceneDetectionResultItem
    );
  }
  async UpdateCameraSceneDetectionResultItem(
    input: UpdateCameraSceneDetectionResultItemInput,
    condition?: ModelCameraSceneDetectionResultItemConditionInput
  ): Promise<UpdateCameraSceneDetectionResultItemMutation> {
    const statement = `mutation UpdateCameraSceneDetectionResultItem($input: UpdateCameraSceneDetectionResultItemInput!, $condition: ModelCameraSceneDetectionResultItemConditionInput) {
        updateCameraSceneDetectionResultItem(input: $input, condition: $condition) {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraSceneDetectionResultItemMutation>(
      response.data.updateCameraSceneDetectionResultItem
    );
  }
  async DeleteCameraSceneDetectionResultItem(
    input: DeleteCameraSceneDetectionResultItemInput,
    condition?: ModelCameraSceneDetectionResultItemConditionInput
  ): Promise<DeleteCameraSceneDetectionResultItemMutation> {
    const statement = `mutation DeleteCameraSceneDetectionResultItem($input: DeleteCameraSceneDetectionResultItemInput!, $condition: ModelCameraSceneDetectionResultItemConditionInput) {
        deleteCameraSceneDetectionResultItem(input: $input, condition: $condition) {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraSceneDetectionResultItemMutation>(
      response.data.deleteCameraSceneDetectionResultItem
    );
  }
  async CreateCameraCredentials(
    input: CreateCameraCredentialsInput,
    condition?: ModelCameraCredentialsConditionInput
  ): Promise<CreateCameraCredentialsMutation> {
    const statement = `mutation CreateCameraCredentials($input: CreateCameraCredentialsInput!, $condition: ModelCameraCredentialsConditionInput) {
        createCameraCredentials(input: $input, condition: $condition) {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraCredentialsMutation>(
      response.data.createCameraCredentials
    );
  }
  async UpdateCameraCredentials(
    input: UpdateCameraCredentialsInput,
    condition?: ModelCameraCredentialsConditionInput
  ): Promise<UpdateCameraCredentialsMutation> {
    const statement = `mutation UpdateCameraCredentials($input: UpdateCameraCredentialsInput!, $condition: ModelCameraCredentialsConditionInput) {
        updateCameraCredentials(input: $input, condition: $condition) {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraCredentialsMutation>(
      response.data.updateCameraCredentials
    );
  }
  async DeleteCameraCredentials(
    input: DeleteCameraCredentialsInput,
    condition?: ModelCameraCredentialsConditionInput
  ): Promise<DeleteCameraCredentialsMutation> {
    const statement = `mutation DeleteCameraCredentials($input: DeleteCameraCredentialsInput!, $condition: ModelCameraCredentialsConditionInput) {
        deleteCameraCredentials(input: $input, condition: $condition) {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraCredentialsMutation>(
      response.data.deleteCameraCredentials
    );
  }
  async CreateCameraAccessUrls(
    input: CreateCameraAccessUrlsInput,
    condition?: ModelCameraAccessUrlsConditionInput
  ): Promise<CreateCameraAccessUrlsMutation> {
    const statement = `mutation CreateCameraAccessUrls($input: CreateCameraAccessUrlsInput!, $condition: ModelCameraAccessUrlsConditionInput) {
        createCameraAccessUrls(input: $input, condition: $condition) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraAccessUrlsMutation>response.data.createCameraAccessUrls;
  }
  async UpdateCameraAccessUrls(
    input: UpdateCameraAccessUrlsInput,
    condition?: ModelCameraAccessUrlsConditionInput
  ): Promise<UpdateCameraAccessUrlsMutation> {
    const statement = `mutation UpdateCameraAccessUrls($input: UpdateCameraAccessUrlsInput!, $condition: ModelCameraAccessUrlsConditionInput) {
        updateCameraAccessUrls(input: $input, condition: $condition) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraAccessUrlsMutation>response.data.updateCameraAccessUrls;
  }
  async DeleteCameraAccessUrls(
    input: DeleteCameraAccessUrlsInput,
    condition?: ModelCameraAccessUrlsConditionInput
  ): Promise<DeleteCameraAccessUrlsMutation> {
    const statement = `mutation DeleteCameraAccessUrls($input: DeleteCameraAccessUrlsInput!, $condition: ModelCameraAccessUrlsConditionInput) {
        deleteCameraAccessUrls(input: $input, condition: $condition) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraAccessUrlsMutation>response.data.deleteCameraAccessUrls;
  }
  async CreateCameraImmixConfig(
    input: CreateCameraImmixConfigInput,
    condition?: ModelCameraImmixConfigConditionInput
  ): Promise<CreateCameraImmixConfigMutation> {
    const statement = `mutation CreateCameraImmixConfig($input: CreateCameraImmixConfigInput!, $condition: ModelCameraImmixConfigConditionInput) {
        createCameraImmixConfig(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraImmixConfigMutation>(
      response.data.createCameraImmixConfig
    );
  }
  async UpdateCameraImmixConfig(
    input: UpdateCameraImmixConfigInput,
    condition?: ModelCameraImmixConfigConditionInput
  ): Promise<UpdateCameraImmixConfigMutation> {
    const statement = `mutation UpdateCameraImmixConfig($input: UpdateCameraImmixConfigInput!, $condition: ModelCameraImmixConfigConditionInput) {
        updateCameraImmixConfig(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraImmixConfigMutation>(
      response.data.updateCameraImmixConfig
    );
  }
  async DeleteCameraImmixConfig(
    input: DeleteCameraImmixConfigInput,
    condition?: ModelCameraImmixConfigConditionInput
  ): Promise<DeleteCameraImmixConfigMutation> {
    const statement = `mutation DeleteCameraImmixConfig($input: DeleteCameraImmixConfigInput!, $condition: ModelCameraImmixConfigConditionInput) {
        deleteCameraImmixConfig(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraImmixConfigMutation>(
      response.data.deleteCameraImmixConfig
    );
  }
  async CreateActivityLog(
    input: CreateActivityLogInput,
    condition?: ModelActivityLogConditionInput
  ): Promise<CreateActivityLogMutation> {
    const statement = `mutation CreateActivityLog($input: CreateActivityLogInput!, $condition: ModelActivityLogConditionInput) {
        createActivityLog(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateActivityLogMutation>response.data.createActivityLog;
  }
  async UpdateActivityLog(
    input: UpdateActivityLogInput,
    condition?: ModelActivityLogConditionInput
  ): Promise<UpdateActivityLogMutation> {
    const statement = `mutation UpdateActivityLog($input: UpdateActivityLogInput!, $condition: ModelActivityLogConditionInput) {
        updateActivityLog(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateActivityLogMutation>response.data.updateActivityLog;
  }
  async DeleteActivityLog(
    input: DeleteActivityLogInput,
    condition?: ModelActivityLogConditionInput
  ): Promise<DeleteActivityLogMutation> {
    const statement = `mutation DeleteActivityLog($input: DeleteActivityLogInput!, $condition: ModelActivityLogConditionInput) {
        deleteActivityLog(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteActivityLogMutation>response.data.deleteActivityLog;
  }
  async CreateSignalTestLog(
    input: CreateSignalTestLogInput,
    condition?: ModelSignalTestLogConditionInput
  ): Promise<CreateSignalTestLogMutation> {
    const statement = `mutation CreateSignalTestLog($input: CreateSignalTestLogInput!, $condition: ModelSignalTestLogConditionInput) {
        createSignalTestLog(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSignalTestLogMutation>response.data.createSignalTestLog;
  }
  async UpdateSignalTestLog(
    input: UpdateSignalTestLogInput,
    condition?: ModelSignalTestLogConditionInput
  ): Promise<UpdateSignalTestLogMutation> {
    const statement = `mutation UpdateSignalTestLog($input: UpdateSignalTestLogInput!, $condition: ModelSignalTestLogConditionInput) {
        updateSignalTestLog(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSignalTestLogMutation>response.data.updateSignalTestLog;
  }
  async DeleteSignalTestLog(
    input: DeleteSignalTestLogInput,
    condition?: ModelSignalTestLogConditionInput
  ): Promise<DeleteSignalTestLogMutation> {
    const statement = `mutation DeleteSignalTestLog($input: DeleteSignalTestLogInput!, $condition: ModelSignalTestLogConditionInput) {
        deleteSignalTestLog(input: $input, condition: $condition) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSignalTestLogMutation>response.data.deleteSignalTestLog;
  }
  async CreateAuditLog(
    input: CreateAuditLogInput,
    condition?: ModelAuditLogConditionInput
  ): Promise<CreateAuditLogMutation> {
    const statement = `mutation CreateAuditLog($input: CreateAuditLogInput!, $condition: ModelAuditLogConditionInput) {
        createAuditLog(input: $input, condition: $condition) {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAuditLogMutation>response.data.createAuditLog;
  }
  async UpdateAuditLog(
    input: UpdateAuditLogInput,
    condition?: ModelAuditLogConditionInput
  ): Promise<UpdateAuditLogMutation> {
    const statement = `mutation UpdateAuditLog($input: UpdateAuditLogInput!, $condition: ModelAuditLogConditionInput) {
        updateAuditLog(input: $input, condition: $condition) {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAuditLogMutation>response.data.updateAuditLog;
  }
  async DeleteAuditLog(
    input: DeleteAuditLogInput,
    condition?: ModelAuditLogConditionInput
  ): Promise<DeleteAuditLogMutation> {
    const statement = `mutation DeleteAuditLog($input: DeleteAuditLogInput!, $condition: ModelAuditLogConditionInput) {
        deleteAuditLog(input: $input, condition: $condition) {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAuditLogMutation>response.data.deleteAuditLog;
  }
  async CreateIncidentActionDetail(
    input: CreateIncidentActionDetailInput,
    condition?: ModelIncidentActionDetailConditionInput
  ): Promise<CreateIncidentActionDetailMutation> {
    const statement = `mutation CreateIncidentActionDetail($input: CreateIncidentActionDetailInput!, $condition: ModelIncidentActionDetailConditionInput) {
        createIncidentActionDetail(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentActionDetailMutation>(
      response.data.createIncidentActionDetail
    );
  }
  async UpdateIncidentActionDetail(
    input: UpdateIncidentActionDetailInput,
    condition?: ModelIncidentActionDetailConditionInput
  ): Promise<UpdateIncidentActionDetailMutation> {
    const statement = `mutation UpdateIncidentActionDetail($input: UpdateIncidentActionDetailInput!, $condition: ModelIncidentActionDetailConditionInput) {
        updateIncidentActionDetail(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentActionDetailMutation>(
      response.data.updateIncidentActionDetail
    );
  }
  async DeleteIncidentActionDetail(
    input: DeleteIncidentActionDetailInput,
    condition?: ModelIncidentActionDetailConditionInput
  ): Promise<DeleteIncidentActionDetailMutation> {
    const statement = `mutation DeleteIncidentActionDetail($input: DeleteIncidentActionDetailInput!, $condition: ModelIncidentActionDetailConditionInput) {
        deleteIncidentActionDetail(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentActionDetailMutation>(
      response.data.deleteIncidentActionDetail
    );
  }
  async CreateSOP(
    input: CreateSOPInput,
    condition?: ModelSOPConditionInput
  ): Promise<CreateSOPMutation> {
    const statement = `mutation CreateSOP($input: CreateSOPInput!, $condition: ModelSOPConditionInput) {
        createSOP(input: $input, condition: $condition) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSOPMutation>response.data.createSOP;
  }
  async UpdateSOP(
    input: UpdateSOPInput,
    condition?: ModelSOPConditionInput
  ): Promise<UpdateSOPMutation> {
    const statement = `mutation UpdateSOP($input: UpdateSOPInput!, $condition: ModelSOPConditionInput) {
        updateSOP(input: $input, condition: $condition) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSOPMutation>response.data.updateSOP;
  }
  async DeleteSOP(
    input: DeleteSOPInput,
    condition?: ModelSOPConditionInput
  ): Promise<DeleteSOPMutation> {
    const statement = `mutation DeleteSOP($input: DeleteSOPInput!, $condition: ModelSOPConditionInput) {
        deleteSOP(input: $input, condition: $condition) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSOPMutation>response.data.deleteSOP;
  }
  async CreateRspndrEvents(
    input: CreateRspndrEventsInput,
    condition?: ModelRspndrEventsConditionInput
  ): Promise<CreateRspndrEventsMutation> {
    const statement = `mutation CreateRspndrEvents($input: CreateRspndrEventsInput!, $condition: ModelRspndrEventsConditionInput) {
        createRspndrEvents(input: $input, condition: $condition) {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRspndrEventsMutation>response.data.createRspndrEvents;
  }
  async UpdateRspndrEvents(
    input: UpdateRspndrEventsInput,
    condition?: ModelRspndrEventsConditionInput
  ): Promise<UpdateRspndrEventsMutation> {
    const statement = `mutation UpdateRspndrEvents($input: UpdateRspndrEventsInput!, $condition: ModelRspndrEventsConditionInput) {
        updateRspndrEvents(input: $input, condition: $condition) {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRspndrEventsMutation>response.data.updateRspndrEvents;
  }
  async DeleteRspndrEvents(
    input: DeleteRspndrEventsInput,
    condition?: ModelRspndrEventsConditionInput
  ): Promise<DeleteRspndrEventsMutation> {
    const statement = `mutation DeleteRspndrEvents($input: DeleteRspndrEventsInput!, $condition: ModelRspndrEventsConditionInput) {
        deleteRspndrEvents(input: $input, condition: $condition) {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRspndrEventsMutation>response.data.deleteRspndrEvents;
  }
  async CreateRspndrIncident(
    input: CreateRspndrIncidentInput,
    condition?: ModelRspndrIncidentConditionInput
  ): Promise<CreateRspndrIncidentMutation> {
    const statement = `mutation CreateRspndrIncident($input: CreateRspndrIncidentInput!, $condition: ModelRspndrIncidentConditionInput) {
        createRspndrIncident(input: $input, condition: $condition) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRspndrIncidentMutation>response.data.createRspndrIncident;
  }
  async UpdateRspndrIncident(
    input: UpdateRspndrIncidentInput,
    condition?: ModelRspndrIncidentConditionInput
  ): Promise<UpdateRspndrIncidentMutation> {
    const statement = `mutation UpdateRspndrIncident($input: UpdateRspndrIncidentInput!, $condition: ModelRspndrIncidentConditionInput) {
        updateRspndrIncident(input: $input, condition: $condition) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRspndrIncidentMutation>response.data.updateRspndrIncident;
  }
  async DeleteRspndrIncident(
    input: DeleteRspndrIncidentInput,
    condition?: ModelRspndrIncidentConditionInput
  ): Promise<DeleteRspndrIncidentMutation> {
    const statement = `mutation DeleteRspndrIncident($input: DeleteRspndrIncidentInput!, $condition: ModelRspndrIncidentConditionInput) {
        deleteRspndrIncident(input: $input, condition: $condition) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRspndrIncidentMutation>response.data.deleteRspndrIncident;
  }
  async CreateRspndrIncidentCheckItem(
    input: CreateRspndrIncidentCheckItemInput,
    condition?: ModelRspndrIncidentCheckItemConditionInput
  ): Promise<CreateRspndrIncidentCheckItemMutation> {
    const statement = `mutation CreateRspndrIncidentCheckItem($input: CreateRspndrIncidentCheckItemInput!, $condition: ModelRspndrIncidentCheckItemConditionInput) {
        createRspndrIncidentCheckItem(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRspndrIncidentCheckItemMutation>(
      response.data.createRspndrIncidentCheckItem
    );
  }
  async UpdateRspndrIncidentCheckItem(
    input: UpdateRspndrIncidentCheckItemInput,
    condition?: ModelRspndrIncidentCheckItemConditionInput
  ): Promise<UpdateRspndrIncidentCheckItemMutation> {
    const statement = `mutation UpdateRspndrIncidentCheckItem($input: UpdateRspndrIncidentCheckItemInput!, $condition: ModelRspndrIncidentCheckItemConditionInput) {
        updateRspndrIncidentCheckItem(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRspndrIncidentCheckItemMutation>(
      response.data.updateRspndrIncidentCheckItem
    );
  }
  async DeleteRspndrIncidentCheckItem(
    input: DeleteRspndrIncidentCheckItemInput,
    condition?: ModelRspndrIncidentCheckItemConditionInput
  ): Promise<DeleteRspndrIncidentCheckItemMutation> {
    const statement = `mutation DeleteRspndrIncidentCheckItem($input: DeleteRspndrIncidentCheckItemInput!, $condition: ModelRspndrIncidentCheckItemConditionInput) {
        deleteRspndrIncidentCheckItem(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRspndrIncidentCheckItemMutation>(
      response.data.deleteRspndrIncidentCheckItem
    );
  }
  async CreateRspndrIncidentGroundingRule(
    input: CreateRspndrIncidentGroundingRuleInput,
    condition?: ModelRspndrIncidentGroundingRuleConditionInput
  ): Promise<CreateRspndrIncidentGroundingRuleMutation> {
    const statement = `mutation CreateRspndrIncidentGroundingRule($input: CreateRspndrIncidentGroundingRuleInput!, $condition: ModelRspndrIncidentGroundingRuleConditionInput) {
        createRspndrIncidentGroundingRule(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRspndrIncidentGroundingRuleMutation>(
      response.data.createRspndrIncidentGroundingRule
    );
  }
  async UpdateRspndrIncidentGroundingRule(
    input: UpdateRspndrIncidentGroundingRuleInput,
    condition?: ModelRspndrIncidentGroundingRuleConditionInput
  ): Promise<UpdateRspndrIncidentGroundingRuleMutation> {
    const statement = `mutation UpdateRspndrIncidentGroundingRule($input: UpdateRspndrIncidentGroundingRuleInput!, $condition: ModelRspndrIncidentGroundingRuleConditionInput) {
        updateRspndrIncidentGroundingRule(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRspndrIncidentGroundingRuleMutation>(
      response.data.updateRspndrIncidentGroundingRule
    );
  }
  async DeleteRspndrIncidentGroundingRule(
    input: DeleteRspndrIncidentGroundingRuleInput,
    condition?: ModelRspndrIncidentGroundingRuleConditionInput
  ): Promise<DeleteRspndrIncidentGroundingRuleMutation> {
    const statement = `mutation DeleteRspndrIncidentGroundingRule($input: DeleteRspndrIncidentGroundingRuleInput!, $condition: ModelRspndrIncidentGroundingRuleConditionInput) {
        deleteRspndrIncidentGroundingRule(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRspndrIncidentGroundingRuleMutation>(
      response.data.deleteRspndrIncidentGroundingRule
    );
  }
  async CreateRspndrActivity(
    input: CreateRspndrActivityInput,
    condition?: ModelRspndrActivityConditionInput
  ): Promise<CreateRspndrActivityMutation> {
    const statement = `mutation CreateRspndrActivity($input: CreateRspndrActivityInput!, $condition: ModelRspndrActivityConditionInput) {
        createRspndrActivity(input: $input, condition: $condition) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRspndrActivityMutation>response.data.createRspndrActivity;
  }
  async UpdateRspndrActivity(
    input: UpdateRspndrActivityInput,
    condition?: ModelRspndrActivityConditionInput
  ): Promise<UpdateRspndrActivityMutation> {
    const statement = `mutation UpdateRspndrActivity($input: UpdateRspndrActivityInput!, $condition: ModelRspndrActivityConditionInput) {
        updateRspndrActivity(input: $input, condition: $condition) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRspndrActivityMutation>response.data.updateRspndrActivity;
  }
  async DeleteRspndrActivity(
    input: DeleteRspndrActivityInput,
    condition?: ModelRspndrActivityConditionInput
  ): Promise<DeleteRspndrActivityMutation> {
    const statement = `mutation DeleteRspndrActivity($input: DeleteRspndrActivityInput!, $condition: ModelRspndrActivityConditionInput) {
        deleteRspndrActivity(input: $input, condition: $condition) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRspndrActivityMutation>response.data.deleteRspndrActivity;
  }
  async CreateTrackTikIncident(
    input: CreateTrackTikIncidentInput,
    condition?: ModelTrackTikIncidentConditionInput
  ): Promise<CreateTrackTikIncidentMutation> {
    const statement = `mutation CreateTrackTikIncident($input: CreateTrackTikIncidentInput!, $condition: ModelTrackTikIncidentConditionInput) {
        createTrackTikIncident(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTrackTikIncidentMutation>response.data.createTrackTikIncident;
  }
  async UpdateTrackTikIncident(
    input: UpdateTrackTikIncidentInput,
    condition?: ModelTrackTikIncidentConditionInput
  ): Promise<UpdateTrackTikIncidentMutation> {
    const statement = `mutation UpdateTrackTikIncident($input: UpdateTrackTikIncidentInput!, $condition: ModelTrackTikIncidentConditionInput) {
        updateTrackTikIncident(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTrackTikIncidentMutation>response.data.updateTrackTikIncident;
  }
  async DeleteTrackTikIncident(
    input: DeleteTrackTikIncidentInput,
    condition?: ModelTrackTikIncidentConditionInput
  ): Promise<DeleteTrackTikIncidentMutation> {
    const statement = `mutation DeleteTrackTikIncident($input: DeleteTrackTikIncidentInput!, $condition: ModelTrackTikIncidentConditionInput) {
        deleteTrackTikIncident(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTrackTikIncidentMutation>response.data.deleteTrackTikIncident;
  }
  async CreateTrackTikActivity(
    input: CreateTrackTikActivityInput,
    condition?: ModelTrackTikActivityConditionInput
  ): Promise<CreateTrackTikActivityMutation> {
    const statement = `mutation CreateTrackTikActivity($input: CreateTrackTikActivityInput!, $condition: ModelTrackTikActivityConditionInput) {
        createTrackTikActivity(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTrackTikActivityMutation>response.data.createTrackTikActivity;
  }
  async UpdateTrackTikActivity(
    input: UpdateTrackTikActivityInput,
    condition?: ModelTrackTikActivityConditionInput
  ): Promise<UpdateTrackTikActivityMutation> {
    const statement = `mutation UpdateTrackTikActivity($input: UpdateTrackTikActivityInput!, $condition: ModelTrackTikActivityConditionInput) {
        updateTrackTikActivity(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTrackTikActivityMutation>response.data.updateTrackTikActivity;
  }
  async DeleteTrackTikActivity(
    input: DeleteTrackTikActivityInput,
    condition?: ModelTrackTikActivityConditionInput
  ): Promise<DeleteTrackTikActivityMutation> {
    const statement = `mutation DeleteTrackTikActivity($input: DeleteTrackTikActivityInput!, $condition: ModelTrackTikActivityConditionInput) {
        deleteTrackTikActivity(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTrackTikActivityMutation>response.data.deleteTrackTikActivity;
  }
  async CreateRapidSOSIncident(
    input: CreateRapidSOSIncidentInput,
    condition?: ModelRapidSOSIncidentConditionInput
  ): Promise<CreateRapidSOSIncidentMutation> {
    const statement = `mutation CreateRapidSOSIncident($input: CreateRapidSOSIncidentInput!, $condition: ModelRapidSOSIncidentConditionInput) {
        createRapidSOSIncident(input: $input, condition: $condition) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSIncidentMutation>response.data.createRapidSOSIncident;
  }
  async UpdateRapidSOSIncident(
    input: UpdateRapidSOSIncidentInput,
    condition?: ModelRapidSOSIncidentConditionInput
  ): Promise<UpdateRapidSOSIncidentMutation> {
    const statement = `mutation UpdateRapidSOSIncident($input: UpdateRapidSOSIncidentInput!, $condition: ModelRapidSOSIncidentConditionInput) {
        updateRapidSOSIncident(input: $input, condition: $condition) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSIncidentMutation>response.data.updateRapidSOSIncident;
  }
  async DeleteRapidSOSIncident(
    input: DeleteRapidSOSIncidentInput,
    condition?: ModelRapidSOSIncidentConditionInput
  ): Promise<DeleteRapidSOSIncidentMutation> {
    const statement = `mutation DeleteRapidSOSIncident($input: DeleteRapidSOSIncidentInput!, $condition: ModelRapidSOSIncidentConditionInput) {
        deleteRapidSOSIncident(input: $input, condition: $condition) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSIncidentMutation>response.data.deleteRapidSOSIncident;
  }
  async CreateRapidSOSEventDetails(
    input: CreateRapidSOSEventDetailsInput,
    condition?: ModelRapidSOSEventDetailsConditionInput
  ): Promise<CreateRapidSOSEventDetailsMutation> {
    const statement = `mutation CreateRapidSOSEventDetails($input: CreateRapidSOSEventDetailsInput!, $condition: ModelRapidSOSEventDetailsConditionInput) {
        createRapidSOSEventDetails(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSEventDetailsMutation>(
      response.data.createRapidSOSEventDetails
    );
  }
  async UpdateRapidSOSEventDetails(
    input: UpdateRapidSOSEventDetailsInput,
    condition?: ModelRapidSOSEventDetailsConditionInput
  ): Promise<UpdateRapidSOSEventDetailsMutation> {
    const statement = `mutation UpdateRapidSOSEventDetails($input: UpdateRapidSOSEventDetailsInput!, $condition: ModelRapidSOSEventDetailsConditionInput) {
        updateRapidSOSEventDetails(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSEventDetailsMutation>(
      response.data.updateRapidSOSEventDetails
    );
  }
  async DeleteRapidSOSEventDetails(
    input: DeleteRapidSOSEventDetailsInput,
    condition?: ModelRapidSOSEventDetailsConditionInput
  ): Promise<DeleteRapidSOSEventDetailsMutation> {
    const statement = `mutation DeleteRapidSOSEventDetails($input: DeleteRapidSOSEventDetailsInput!, $condition: ModelRapidSOSEventDetailsConditionInput) {
        deleteRapidSOSEventDetails(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSEventDetailsMutation>(
      response.data.deleteRapidSOSEventDetails
    );
  }
  async CreateRapidSOSLocation(
    input: CreateRapidSOSLocationInput,
    condition?: ModelRapidSOSLocationConditionInput
  ): Promise<CreateRapidSOSLocationMutation> {
    const statement = `mutation CreateRapidSOSLocation($input: CreateRapidSOSLocationInput!, $condition: ModelRapidSOSLocationConditionInput) {
        createRapidSOSLocation(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSLocationMutation>response.data.createRapidSOSLocation;
  }
  async UpdateRapidSOSLocation(
    input: UpdateRapidSOSLocationInput,
    condition?: ModelRapidSOSLocationConditionInput
  ): Promise<UpdateRapidSOSLocationMutation> {
    const statement = `mutation UpdateRapidSOSLocation($input: UpdateRapidSOSLocationInput!, $condition: ModelRapidSOSLocationConditionInput) {
        updateRapidSOSLocation(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSLocationMutation>response.data.updateRapidSOSLocation;
  }
  async DeleteRapidSOSLocation(
    input: DeleteRapidSOSLocationInput,
    condition?: ModelRapidSOSLocationConditionInput
  ): Promise<DeleteRapidSOSLocationMutation> {
    const statement = `mutation DeleteRapidSOSLocation($input: DeleteRapidSOSLocationInput!, $condition: ModelRapidSOSLocationConditionInput) {
        deleteRapidSOSLocation(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSLocationMutation>response.data.deleteRapidSOSLocation;
  }
  async CreateRapidSOSCivicAddress(
    input: CreateRapidSOSCivicAddressInput,
    condition?: ModelRapidSOSCivicAddressConditionInput
  ): Promise<CreateRapidSOSCivicAddressMutation> {
    const statement = `mutation CreateRapidSOSCivicAddress($input: CreateRapidSOSCivicAddressInput!, $condition: ModelRapidSOSCivicAddressConditionInput) {
        createRapidSOSCivicAddress(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSCivicAddressMutation>(
      response.data.createRapidSOSCivicAddress
    );
  }
  async UpdateRapidSOSCivicAddress(
    input: UpdateRapidSOSCivicAddressInput,
    condition?: ModelRapidSOSCivicAddressConditionInput
  ): Promise<UpdateRapidSOSCivicAddressMutation> {
    const statement = `mutation UpdateRapidSOSCivicAddress($input: UpdateRapidSOSCivicAddressInput!, $condition: ModelRapidSOSCivicAddressConditionInput) {
        updateRapidSOSCivicAddress(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSCivicAddressMutation>(
      response.data.updateRapidSOSCivicAddress
    );
  }
  async DeleteRapidSOSCivicAddress(
    input: DeleteRapidSOSCivicAddressInput,
    condition?: ModelRapidSOSCivicAddressConditionInput
  ): Promise<DeleteRapidSOSCivicAddressMutation> {
    const statement = `mutation DeleteRapidSOSCivicAddress($input: DeleteRapidSOSCivicAddressInput!, $condition: ModelRapidSOSCivicAddressConditionInput) {
        deleteRapidSOSCivicAddress(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSCivicAddressMutation>(
      response.data.deleteRapidSOSCivicAddress
    );
  }
  async CreateRapidSOSEmergencyContact(
    input: CreateRapidSOSEmergencyContactInput,
    condition?: ModelRapidSOSEmergencyContactConditionInput
  ): Promise<CreateRapidSOSEmergencyContactMutation> {
    const statement = `mutation CreateRapidSOSEmergencyContact($input: CreateRapidSOSEmergencyContactInput!, $condition: ModelRapidSOSEmergencyContactConditionInput) {
        createRapidSOSEmergencyContact(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSEmergencyContactMutation>(
      response.data.createRapidSOSEmergencyContact
    );
  }
  async UpdateRapidSOSEmergencyContact(
    input: UpdateRapidSOSEmergencyContactInput,
    condition?: ModelRapidSOSEmergencyContactConditionInput
  ): Promise<UpdateRapidSOSEmergencyContactMutation> {
    const statement = `mutation UpdateRapidSOSEmergencyContact($input: UpdateRapidSOSEmergencyContactInput!, $condition: ModelRapidSOSEmergencyContactConditionInput) {
        updateRapidSOSEmergencyContact(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSEmergencyContactMutation>(
      response.data.updateRapidSOSEmergencyContact
    );
  }
  async DeleteRapidSOSEmergencyContact(
    input: DeleteRapidSOSEmergencyContactInput,
    condition?: ModelRapidSOSEmergencyContactConditionInput
  ): Promise<DeleteRapidSOSEmergencyContactMutation> {
    const statement = `mutation DeleteRapidSOSEmergencyContact($input: DeleteRapidSOSEmergencyContactInput!, $condition: ModelRapidSOSEmergencyContactConditionInput) {
        deleteRapidSOSEmergencyContact(input: $input, condition: $condition) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSEmergencyContactMutation>(
      response.data.deleteRapidSOSEmergencyContact
    );
  }
  async CreateRapidSOSActivity(
    input: CreateRapidSOSActivityInput,
    condition?: ModelRapidSOSActivityConditionInput
  ): Promise<CreateRapidSOSActivityMutation> {
    const statement = `mutation CreateRapidSOSActivity($input: CreateRapidSOSActivityInput!, $condition: ModelRapidSOSActivityConditionInput) {
        createRapidSOSActivity(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSActivityMutation>response.data.createRapidSOSActivity;
  }
  async UpdateRapidSOSActivity(
    input: UpdateRapidSOSActivityInput,
    condition?: ModelRapidSOSActivityConditionInput
  ): Promise<UpdateRapidSOSActivityMutation> {
    const statement = `mutation UpdateRapidSOSActivity($input: UpdateRapidSOSActivityInput!, $condition: ModelRapidSOSActivityConditionInput) {
        updateRapidSOSActivity(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSActivityMutation>response.data.updateRapidSOSActivity;
  }
  async DeleteRapidSOSActivity(
    input: DeleteRapidSOSActivityInput,
    condition?: ModelRapidSOSActivityConditionInput
  ): Promise<DeleteRapidSOSActivityMutation> {
    const statement = `mutation DeleteRapidSOSActivity($input: DeleteRapidSOSActivityInput!, $condition: ModelRapidSOSActivityConditionInput) {
        deleteRapidSOSActivity(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSActivityMutation>response.data.deleteRapidSOSActivity;
  }
  async CreateRapidSOSLogMessage(
    input: CreateRapidSOSLogMessageInput,
    condition?: ModelRapidSOSLogMessageConditionInput
  ): Promise<CreateRapidSOSLogMessageMutation> {
    const statement = `mutation CreateRapidSOSLogMessage($input: CreateRapidSOSLogMessageInput!, $condition: ModelRapidSOSLogMessageConditionInput) {
        createRapidSOSLogMessage(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSLogMessageMutation>(
      response.data.createRapidSOSLogMessage
    );
  }
  async UpdateRapidSOSLogMessage(
    input: UpdateRapidSOSLogMessageInput,
    condition?: ModelRapidSOSLogMessageConditionInput
  ): Promise<UpdateRapidSOSLogMessageMutation> {
    const statement = `mutation UpdateRapidSOSLogMessage($input: UpdateRapidSOSLogMessageInput!, $condition: ModelRapidSOSLogMessageConditionInput) {
        updateRapidSOSLogMessage(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSLogMessageMutation>(
      response.data.updateRapidSOSLogMessage
    );
  }
  async DeleteRapidSOSLogMessage(
    input: DeleteRapidSOSLogMessageInput,
    condition?: ModelRapidSOSLogMessageConditionInput
  ): Promise<DeleteRapidSOSLogMessageMutation> {
    const statement = `mutation DeleteRapidSOSLogMessage($input: DeleteRapidSOSLogMessageInput!, $condition: ModelRapidSOSLogMessageConditionInput) {
        deleteRapidSOSLogMessage(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSLogMessageMutation>(
      response.data.deleteRapidSOSLogMessage
    );
  }
  async CreateRapidSOSAttachment(
    input: CreateRapidSOSAttachmentInput,
    condition?: ModelRapidSOSAttachmentConditionInput
  ): Promise<CreateRapidSOSAttachmentMutation> {
    const statement = `mutation CreateRapidSOSAttachment($input: CreateRapidSOSAttachmentInput!, $condition: ModelRapidSOSAttachmentConditionInput) {
        createRapidSOSAttachment(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRapidSOSAttachmentMutation>(
      response.data.createRapidSOSAttachment
    );
  }
  async UpdateRapidSOSAttachment(
    input: UpdateRapidSOSAttachmentInput,
    condition?: ModelRapidSOSAttachmentConditionInput
  ): Promise<UpdateRapidSOSAttachmentMutation> {
    const statement = `mutation UpdateRapidSOSAttachment($input: UpdateRapidSOSAttachmentInput!, $condition: ModelRapidSOSAttachmentConditionInput) {
        updateRapidSOSAttachment(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRapidSOSAttachmentMutation>(
      response.data.updateRapidSOSAttachment
    );
  }
  async DeleteRapidSOSAttachment(
    input: DeleteRapidSOSAttachmentInput,
    condition?: ModelRapidSOSAttachmentConditionInput
  ): Promise<DeleteRapidSOSAttachmentMutation> {
    const statement = `mutation DeleteRapidSOSAttachment($input: DeleteRapidSOSAttachmentInput!, $condition: ModelRapidSOSAttachmentConditionInput) {
        deleteRapidSOSAttachment(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRapidSOSAttachmentMutation>(
      response.data.deleteRapidSOSAttachment
    );
  }
  async CreateIncidentCodeDetail(
    input: CreateIncidentCodeDetailInput,
    condition?: ModelIncidentCodeDetailConditionInput
  ): Promise<CreateIncidentCodeDetailMutation> {
    const statement = `mutation CreateIncidentCodeDetail($input: CreateIncidentCodeDetailInput!, $condition: ModelIncidentCodeDetailConditionInput) {
        createIncidentCodeDetail(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentCodeDetailMutation>(
      response.data.createIncidentCodeDetail
    );
  }
  async UpdateIncidentCodeDetail(
    input: UpdateIncidentCodeDetailInput,
    condition?: ModelIncidentCodeDetailConditionInput
  ): Promise<UpdateIncidentCodeDetailMutation> {
    const statement = `mutation UpdateIncidentCodeDetail($input: UpdateIncidentCodeDetailInput!, $condition: ModelIncidentCodeDetailConditionInput) {
        updateIncidentCodeDetail(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentCodeDetailMutation>(
      response.data.updateIncidentCodeDetail
    );
  }
  async DeleteIncidentCodeDetail(
    input: DeleteIncidentCodeDetailInput,
    condition?: ModelIncidentCodeDetailConditionInput
  ): Promise<DeleteIncidentCodeDetailMutation> {
    const statement = `mutation DeleteIncidentCodeDetail($input: DeleteIncidentCodeDetailInput!, $condition: ModelIncidentCodeDetailConditionInput) {
        deleteIncidentCodeDetail(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentCodeDetailMutation>(
      response.data.deleteIncidentCodeDetail
    );
  }
  async CreateIncidentShareSettings(
    input: CreateIncidentShareSettingsInput,
    condition?: ModelIncidentShareSettingsConditionInput
  ): Promise<CreateIncidentShareSettingsMutation> {
    const statement = `mutation CreateIncidentShareSettings($input: CreateIncidentShareSettingsInput!, $condition: ModelIncidentShareSettingsConditionInput) {
        createIncidentShareSettings(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareSettingsMutation>(
      response.data.createIncidentShareSettings
    );
  }
  async UpdateIncidentShareSettings(
    input: UpdateIncidentShareSettingsInput,
    condition?: ModelIncidentShareSettingsConditionInput
  ): Promise<UpdateIncidentShareSettingsMutation> {
    const statement = `mutation UpdateIncidentShareSettings($input: UpdateIncidentShareSettingsInput!, $condition: ModelIncidentShareSettingsConditionInput) {
        updateIncidentShareSettings(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareSettingsMutation>(
      response.data.updateIncidentShareSettings
    );
  }
  async DeleteIncidentShareSettings(
    input: DeleteIncidentShareSettingsInput,
    condition?: ModelIncidentShareSettingsConditionInput
  ): Promise<DeleteIncidentShareSettingsMutation> {
    const statement = `mutation DeleteIncidentShareSettings($input: DeleteIncidentShareSettingsInput!, $condition: ModelIncidentShareSettingsConditionInput) {
        deleteIncidentShareSettings(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareSettingsMutation>(
      response.data.deleteIncidentShareSettings
    );
  }
  async CreateIncidentDataToShare(
    input: CreateIncidentDataToShareInput,
    condition?: ModelIncidentDataToShareConditionInput
  ): Promise<CreateIncidentDataToShareMutation> {
    const statement = `mutation CreateIncidentDataToShare($input: CreateIncidentDataToShareInput!, $condition: ModelIncidentDataToShareConditionInput) {
        createIncidentDataToShare(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentDataToShareMutation>(
      response.data.createIncidentDataToShare
    );
  }
  async UpdateIncidentDataToShare(
    input: UpdateIncidentDataToShareInput,
    condition?: ModelIncidentDataToShareConditionInput
  ): Promise<UpdateIncidentDataToShareMutation> {
    const statement = `mutation UpdateIncidentDataToShare($input: UpdateIncidentDataToShareInput!, $condition: ModelIncidentDataToShareConditionInput) {
        updateIncidentDataToShare(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentDataToShareMutation>(
      response.data.updateIncidentDataToShare
    );
  }
  async DeleteIncidentDataToShare(
    input: DeleteIncidentDataToShareInput,
    condition?: ModelIncidentDataToShareConditionInput
  ): Promise<DeleteIncidentDataToShareMutation> {
    const statement = `mutation DeleteIncidentDataToShare($input: DeleteIncidentDataToShareInput!, $condition: ModelIncidentDataToShareConditionInput) {
        deleteIncidentDataToShare(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentDataToShareMutation>(
      response.data.deleteIncidentDataToShare
    );
  }
  async CreateIncidentShareEntry(
    input: CreateIncidentShareEntryInput,
    condition?: ModelIncidentShareEntryConditionInput
  ): Promise<CreateIncidentShareEntryMutation> {
    const statement = `mutation CreateIncidentShareEntry($input: CreateIncidentShareEntryInput!, $condition: ModelIncidentShareEntryConditionInput) {
        createIncidentShareEntry(input: $input, condition: $condition) {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareEntryMutation>(
      response.data.createIncidentShareEntry
    );
  }
  async UpdateIncidentShareEntry(
    input: UpdateIncidentShareEntryInput,
    condition?: ModelIncidentShareEntryConditionInput
  ): Promise<UpdateIncidentShareEntryMutation> {
    const statement = `mutation UpdateIncidentShareEntry($input: UpdateIncidentShareEntryInput!, $condition: ModelIncidentShareEntryConditionInput) {
        updateIncidentShareEntry(input: $input, condition: $condition) {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareEntryMutation>(
      response.data.updateIncidentShareEntry
    );
  }
  async DeleteIncidentShareEntry(
    input: DeleteIncidentShareEntryInput,
    condition?: ModelIncidentShareEntryConditionInput
  ): Promise<DeleteIncidentShareEntryMutation> {
    const statement = `mutation DeleteIncidentShareEntry($input: DeleteIncidentShareEntryInput!, $condition: ModelIncidentShareEntryConditionInput) {
        deleteIncidentShareEntry(input: $input, condition: $condition) {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareEntryMutation>(
      response.data.deleteIncidentShareEntry
    );
  }
  async CreateIncidentShareSite(
    input: CreateIncidentShareSiteInput,
    condition?: ModelIncidentShareSiteConditionInput
  ): Promise<CreateIncidentShareSiteMutation> {
    const statement = `mutation CreateIncidentShareSite($input: CreateIncidentShareSiteInput!, $condition: ModelIncidentShareSiteConditionInput) {
        createIncidentShareSite(input: $input, condition: $condition) {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareSiteMutation>(
      response.data.createIncidentShareSite
    );
  }
  async UpdateIncidentShareSite(
    input: UpdateIncidentShareSiteInput,
    condition?: ModelIncidentShareSiteConditionInput
  ): Promise<UpdateIncidentShareSiteMutation> {
    const statement = `mutation UpdateIncidentShareSite($input: UpdateIncidentShareSiteInput!, $condition: ModelIncidentShareSiteConditionInput) {
        updateIncidentShareSite(input: $input, condition: $condition) {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareSiteMutation>(
      response.data.updateIncidentShareSite
    );
  }
  async DeleteIncidentShareSite(
    input: DeleteIncidentShareSiteInput,
    condition?: ModelIncidentShareSiteConditionInput
  ): Promise<DeleteIncidentShareSiteMutation> {
    const statement = `mutation DeleteIncidentShareSite($input: DeleteIncidentShareSiteInput!, $condition: ModelIncidentShareSiteConditionInput) {
        deleteIncidentShareSite(input: $input, condition: $condition) {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareSiteMutation>(
      response.data.deleteIncidentShareSite
    );
  }
  async CreateIncidentShareIntegrator(
    input: CreateIncidentShareIntegratorInput,
    condition?: ModelIncidentShareIntegratorConditionInput
  ): Promise<CreateIncidentShareIntegratorMutation> {
    const statement = `mutation CreateIncidentShareIntegrator($input: CreateIncidentShareIntegratorInput!, $condition: ModelIncidentShareIntegratorConditionInput) {
        createIncidentShareIntegrator(input: $input, condition: $condition) {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareIntegratorMutation>(
      response.data.createIncidentShareIntegrator
    );
  }
  async UpdateIncidentShareIntegrator(
    input: UpdateIncidentShareIntegratorInput,
    condition?: ModelIncidentShareIntegratorConditionInput
  ): Promise<UpdateIncidentShareIntegratorMutation> {
    const statement = `mutation UpdateIncidentShareIntegrator($input: UpdateIncidentShareIntegratorInput!, $condition: ModelIncidentShareIntegratorConditionInput) {
        updateIncidentShareIntegrator(input: $input, condition: $condition) {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareIntegratorMutation>(
      response.data.updateIncidentShareIntegrator
    );
  }
  async DeleteIncidentShareIntegrator(
    input: DeleteIncidentShareIntegratorInput,
    condition?: ModelIncidentShareIntegratorConditionInput
  ): Promise<DeleteIncidentShareIntegratorMutation> {
    const statement = `mutation DeleteIncidentShareIntegrator($input: DeleteIncidentShareIntegratorInput!, $condition: ModelIncidentShareIntegratorConditionInput) {
        deleteIncidentShareIntegrator(input: $input, condition: $condition) {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareIntegratorMutation>(
      response.data.deleteIncidentShareIntegrator
    );
  }
  async CreateIncidentShareEntryAccess(
    input: CreateIncidentShareEntryAccessInput,
    condition?: ModelIncidentShareEntryAccessConditionInput
  ): Promise<CreateIncidentShareEntryAccessMutation> {
    const statement = `mutation CreateIncidentShareEntryAccess($input: CreateIncidentShareEntryAccessInput!, $condition: ModelIncidentShareEntryAccessConditionInput) {
        createIncidentShareEntryAccess(input: $input, condition: $condition) {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareEntryAccessMutation>(
      response.data.createIncidentShareEntryAccess
    );
  }
  async UpdateIncidentShareEntryAccess(
    input: UpdateIncidentShareEntryAccessInput,
    condition?: ModelIncidentShareEntryAccessConditionInput
  ): Promise<UpdateIncidentShareEntryAccessMutation> {
    const statement = `mutation UpdateIncidentShareEntryAccess($input: UpdateIncidentShareEntryAccessInput!, $condition: ModelIncidentShareEntryAccessConditionInput) {
        updateIncidentShareEntryAccess(input: $input, condition: $condition) {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareEntryAccessMutation>(
      response.data.updateIncidentShareEntryAccess
    );
  }
  async DeleteIncidentShareEntryAccess(
    input: DeleteIncidentShareEntryAccessInput,
    condition?: ModelIncidentShareEntryAccessConditionInput
  ): Promise<DeleteIncidentShareEntryAccessMutation> {
    const statement = `mutation DeleteIncidentShareEntryAccess($input: DeleteIncidentShareEntryAccessInput!, $condition: ModelIncidentShareEntryAccessConditionInput) {
        deleteIncidentShareEntryAccess(input: $input, condition: $condition) {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareEntryAccessMutation>(
      response.data.deleteIncidentShareEntryAccess
    );
  }
  async CreateIncidentShareEntyNote(
    input: CreateIncidentShareEntyNoteInput,
    condition?: ModelIncidentShareEntyNoteConditionInput
  ): Promise<CreateIncidentShareEntyNoteMutation> {
    const statement = `mutation CreateIncidentShareEntyNote($input: CreateIncidentShareEntyNoteInput!, $condition: ModelIncidentShareEntyNoteConditionInput) {
        createIncidentShareEntyNote(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareEntyNoteMutation>(
      response.data.createIncidentShareEntyNote
    );
  }
  async UpdateIncidentShareEntyNote(
    input: UpdateIncidentShareEntyNoteInput,
    condition?: ModelIncidentShareEntyNoteConditionInput
  ): Promise<UpdateIncidentShareEntyNoteMutation> {
    const statement = `mutation UpdateIncidentShareEntyNote($input: UpdateIncidentShareEntyNoteInput!, $condition: ModelIncidentShareEntyNoteConditionInput) {
        updateIncidentShareEntyNote(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareEntyNoteMutation>(
      response.data.updateIncidentShareEntyNote
    );
  }
  async DeleteIncidentShareEntyNote(
    input: DeleteIncidentShareEntyNoteInput,
    condition?: ModelIncidentShareEntyNoteConditionInput
  ): Promise<DeleteIncidentShareEntyNoteMutation> {
    const statement = `mutation DeleteIncidentShareEntyNote($input: DeleteIncidentShareEntyNoteInput!, $condition: ModelIncidentShareEntyNoteConditionInput) {
        deleteIncidentShareEntyNote(input: $input, condition: $condition) {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareEntyNoteMutation>(
      response.data.deleteIncidentShareEntyNote
    );
  }
  async CreateIncidentShareEvent(
    input: CreateIncidentShareEventInput,
    condition?: ModelIncidentShareEventConditionInput
  ): Promise<CreateIncidentShareEventMutation> {
    const statement = `mutation CreateIncidentShareEvent($input: CreateIncidentShareEventInput!, $condition: ModelIncidentShareEventConditionInput) {
        createIncidentShareEvent(input: $input, condition: $condition) {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareEventMutation>(
      response.data.createIncidentShareEvent
    );
  }
  async UpdateIncidentShareEvent(
    input: UpdateIncidentShareEventInput,
    condition?: ModelIncidentShareEventConditionInput
  ): Promise<UpdateIncidentShareEventMutation> {
    const statement = `mutation UpdateIncidentShareEvent($input: UpdateIncidentShareEventInput!, $condition: ModelIncidentShareEventConditionInput) {
        updateIncidentShareEvent(input: $input, condition: $condition) {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareEventMutation>(
      response.data.updateIncidentShareEvent
    );
  }
  async DeleteIncidentShareEvent(
    input: DeleteIncidentShareEventInput,
    condition?: ModelIncidentShareEventConditionInput
  ): Promise<DeleteIncidentShareEventMutation> {
    const statement = `mutation DeleteIncidentShareEvent($input: DeleteIncidentShareEventInput!, $condition: ModelIncidentShareEventConditionInput) {
        deleteIncidentShareEvent(input: $input, condition: $condition) {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareEventMutation>(
      response.data.deleteIncidentShareEvent
    );
  }
  async CreateIncidentShareActionItems(
    input: CreateIncidentShareActionItemsInput,
    condition?: ModelIncidentShareActionItemsConditionInput
  ): Promise<CreateIncidentShareActionItemsMutation> {
    const statement = `mutation CreateIncidentShareActionItems($input: CreateIncidentShareActionItemsInput!, $condition: ModelIncidentShareActionItemsConditionInput) {
        createIncidentShareActionItems(input: $input, condition: $condition) {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareActionItemsMutation>(
      response.data.createIncidentShareActionItems
    );
  }
  async UpdateIncidentShareActionItems(
    input: UpdateIncidentShareActionItemsInput,
    condition?: ModelIncidentShareActionItemsConditionInput
  ): Promise<UpdateIncidentShareActionItemsMutation> {
    const statement = `mutation UpdateIncidentShareActionItems($input: UpdateIncidentShareActionItemsInput!, $condition: ModelIncidentShareActionItemsConditionInput) {
        updateIncidentShareActionItems(input: $input, condition: $condition) {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareActionItemsMutation>(
      response.data.updateIncidentShareActionItems
    );
  }
  async DeleteIncidentShareActionItems(
    input: DeleteIncidentShareActionItemsInput,
    condition?: ModelIncidentShareActionItemsConditionInput
  ): Promise<DeleteIncidentShareActionItemsMutation> {
    const statement = `mutation DeleteIncidentShareActionItems($input: DeleteIncidentShareActionItemsInput!, $condition: ModelIncidentShareActionItemsConditionInput) {
        deleteIncidentShareActionItems(input: $input, condition: $condition) {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareActionItemsMutation>(
      response.data.deleteIncidentShareActionItems
    );
  }
  async CreateIncidentShareContactInformation(
    input: CreateIncidentShareContactInformationInput,
    condition?: ModelIncidentShareContactInformationConditionInput
  ): Promise<CreateIncidentShareContactInformationMutation> {
    const statement = `mutation CreateIncidentShareContactInformation($input: CreateIncidentShareContactInformationInput!, $condition: ModelIncidentShareContactInformationConditionInput) {
        createIncidentShareContactInformation(input: $input, condition: $condition) {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIncidentShareContactInformationMutation>(
      response.data.createIncidentShareContactInformation
    );
  }
  async UpdateIncidentShareContactInformation(
    input: UpdateIncidentShareContactInformationInput,
    condition?: ModelIncidentShareContactInformationConditionInput
  ): Promise<UpdateIncidentShareContactInformationMutation> {
    const statement = `mutation UpdateIncidentShareContactInformation($input: UpdateIncidentShareContactInformationInput!, $condition: ModelIncidentShareContactInformationConditionInput) {
        updateIncidentShareContactInformation(input: $input, condition: $condition) {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIncidentShareContactInformationMutation>(
      response.data.updateIncidentShareContactInformation
    );
  }
  async DeleteIncidentShareContactInformation(
    input: DeleteIncidentShareContactInformationInput,
    condition?: ModelIncidentShareContactInformationConditionInput
  ): Promise<DeleteIncidentShareContactInformationMutation> {
    const statement = `mutation DeleteIncidentShareContactInformation($input: DeleteIncidentShareContactInformationInput!, $condition: ModelIncidentShareContactInformationConditionInput) {
        deleteIncidentShareContactInformation(input: $input, condition: $condition) {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIncidentShareContactInformationMutation>(
      response.data.deleteIncidentShareContactInformation
    );
  }
  async CreateCallNotice(
    input: CreateCallNoticeInput,
    condition?: ModelCallNoticeConditionInput
  ): Promise<CreateCallNoticeMutation> {
    const statement = `mutation CreateCallNotice($input: CreateCallNoticeInput!, $condition: ModelCallNoticeConditionInput) {
        createCallNotice(input: $input, condition: $condition) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallNoticeMutation>response.data.createCallNotice;
  }
  async UpdateCallNotice(
    input: UpdateCallNoticeInput,
    condition?: ModelCallNoticeConditionInput
  ): Promise<UpdateCallNoticeMutation> {
    const statement = `mutation UpdateCallNotice($input: UpdateCallNoticeInput!, $condition: ModelCallNoticeConditionInput) {
        updateCallNotice(input: $input, condition: $condition) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallNoticeMutation>response.data.updateCallNotice;
  }
  async DeleteCallNotice(
    input: DeleteCallNoticeInput,
    condition?: ModelCallNoticeConditionInput
  ): Promise<DeleteCallNoticeMutation> {
    const statement = `mutation DeleteCallNotice($input: DeleteCallNoticeInput!, $condition: ModelCallNoticeConditionInput) {
        deleteCallNotice(input: $input, condition: $condition) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallNoticeMutation>response.data.deleteCallNotice;
  }
  async CreateCallLog(
    input: CreateCallLogInput,
    condition?: ModelCallLogConditionInput
  ): Promise<CreateCallLogMutation> {
    const statement = `mutation CreateCallLog($input: CreateCallLogInput!, $condition: ModelCallLogConditionInput) {
        createCallLog(input: $input, condition: $condition) {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCallLogMutation>response.data.createCallLog;
  }
  async UpdateCallLog(
    input: UpdateCallLogInput,
    condition?: ModelCallLogConditionInput
  ): Promise<UpdateCallLogMutation> {
    const statement = `mutation UpdateCallLog($input: UpdateCallLogInput!, $condition: ModelCallLogConditionInput) {
        updateCallLog(input: $input, condition: $condition) {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCallLogMutation>response.data.updateCallLog;
  }
  async DeleteCallLog(
    input: DeleteCallLogInput,
    condition?: ModelCallLogConditionInput
  ): Promise<DeleteCallLogMutation> {
    const statement = `mutation DeleteCallLog($input: DeleteCallLogInput!, $condition: ModelCallLogConditionInput) {
        deleteCallLog(input: $input, condition: $condition) {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCallLogMutation>response.data.deleteCallLog;
  }
  async CreateSMSLog(
    input: CreateSMSLogInput,
    condition?: ModelSMSLogConditionInput
  ): Promise<CreateSMSLogMutation> {
    const statement = `mutation CreateSMSLog($input: CreateSMSLogInput!, $condition: ModelSMSLogConditionInput) {
        createSMSLog(input: $input, condition: $condition) {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSMSLogMutation>response.data.createSMSLog;
  }
  async UpdateSMSLog(
    input: UpdateSMSLogInput,
    condition?: ModelSMSLogConditionInput
  ): Promise<UpdateSMSLogMutation> {
    const statement = `mutation UpdateSMSLog($input: UpdateSMSLogInput!, $condition: ModelSMSLogConditionInput) {
        updateSMSLog(input: $input, condition: $condition) {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSMSLogMutation>response.data.updateSMSLog;
  }
  async DeleteSMSLog(
    input: DeleteSMSLogInput,
    condition?: ModelSMSLogConditionInput
  ): Promise<DeleteSMSLogMutation> {
    const statement = `mutation DeleteSMSLog($input: DeleteSMSLogInput!, $condition: ModelSMSLogConditionInput) {
        deleteSMSLog(input: $input, condition: $condition) {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSMSLogMutation>response.data.deleteSMSLog;
  }
  async CreateEmailLog(
    input: CreateEmailLogInput,
    condition?: ModelEmailLogConditionInput
  ): Promise<CreateEmailLogMutation> {
    const statement = `mutation CreateEmailLog($input: CreateEmailLogInput!, $condition: ModelEmailLogConditionInput) {
        createEmailLog(input: $input, condition: $condition) {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmailLogMutation>response.data.createEmailLog;
  }
  async UpdateEmailLog(
    input: UpdateEmailLogInput,
    condition?: ModelEmailLogConditionInput
  ): Promise<UpdateEmailLogMutation> {
    const statement = `mutation UpdateEmailLog($input: UpdateEmailLogInput!, $condition: ModelEmailLogConditionInput) {
        updateEmailLog(input: $input, condition: $condition) {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmailLogMutation>response.data.updateEmailLog;
  }
  async DeleteEmailLog(
    input: DeleteEmailLogInput,
    condition?: ModelEmailLogConditionInput
  ): Promise<DeleteEmailLogMutation> {
    const statement = `mutation DeleteEmailLog($input: DeleteEmailLogInput!, $condition: ModelEmailLogConditionInput) {
        deleteEmailLog(input: $input, condition: $condition) {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmailLogMutation>response.data.deleteEmailLog;
  }
  async CreateNotificationSetting(
    input: CreateNotificationSettingInput,
    condition?: ModelNotificationSettingConditionInput
  ): Promise<CreateNotificationSettingMutation> {
    const statement = `mutation CreateNotificationSetting($input: CreateNotificationSettingInput!, $condition: ModelNotificationSettingConditionInput) {
        createNotificationSetting(input: $input, condition: $condition) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationSettingMutation>(
      response.data.createNotificationSetting
    );
  }
  async UpdateNotificationSetting(
    input: UpdateNotificationSettingInput,
    condition?: ModelNotificationSettingConditionInput
  ): Promise<UpdateNotificationSettingMutation> {
    const statement = `mutation UpdateNotificationSetting($input: UpdateNotificationSettingInput!, $condition: ModelNotificationSettingConditionInput) {
        updateNotificationSetting(input: $input, condition: $condition) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationSettingMutation>(
      response.data.updateNotificationSetting
    );
  }
  async DeleteNotificationSetting(
    input: DeleteNotificationSettingInput,
    condition?: ModelNotificationSettingConditionInput
  ): Promise<DeleteNotificationSettingMutation> {
    const statement = `mutation DeleteNotificationSetting($input: DeleteNotificationSettingInput!, $condition: ModelNotificationSettingConditionInput) {
        deleteNotificationSetting(input: $input, condition: $condition) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationSettingMutation>(
      response.data.deleteNotificationSetting
    );
  }
  async CreateNotification(
    input: CreateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<CreateNotificationMutation> {
    const statement = `mutation CreateNotification($input: CreateNotificationInput!, $condition: ModelNotificationConditionInput) {
        createNotification(input: $input, condition: $condition) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationMutation>response.data.createNotification;
  }
  async UpdateNotification(
    input: UpdateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<UpdateNotificationMutation> {
    const statement = `mutation UpdateNotification($input: UpdateNotificationInput!, $condition: ModelNotificationConditionInput) {
        updateNotification(input: $input, condition: $condition) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationMutation>response.data.updateNotification;
  }
  async DeleteNotification(
    input: DeleteNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<DeleteNotificationMutation> {
    const statement = `mutation DeleteNotification($input: DeleteNotificationInput!, $condition: ModelNotificationConditionInput) {
        deleteNotification(input: $input, condition: $condition) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationMutation>response.data.deleteNotification;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateUserVMSDetails(
    input: CreateUserVMSDetailsInput,
    condition?: ModelUserVMSDetailsConditionInput
  ): Promise<CreateUserVMSDetailsMutation> {
    const statement = `mutation CreateUserVMSDetails($input: CreateUserVMSDetailsInput!, $condition: ModelUserVMSDetailsConditionInput) {
        createUserVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserVMSDetailsMutation>response.data.createUserVMSDetails;
  }
  async UpdateUserVMSDetails(
    input: UpdateUserVMSDetailsInput,
    condition?: ModelUserVMSDetailsConditionInput
  ): Promise<UpdateUserVMSDetailsMutation> {
    const statement = `mutation UpdateUserVMSDetails($input: UpdateUserVMSDetailsInput!, $condition: ModelUserVMSDetailsConditionInput) {
        updateUserVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserVMSDetailsMutation>response.data.updateUserVMSDetails;
  }
  async DeleteUserVMSDetails(
    input: DeleteUserVMSDetailsInput,
    condition?: ModelUserVMSDetailsConditionInput
  ): Promise<DeleteUserVMSDetailsMutation> {
    const statement = `mutation DeleteUserVMSDetails($input: DeleteUserVMSDetailsInput!, $condition: ModelUserVMSDetailsConditionInput) {
        deleteUserVMSDetails(input: $input, condition: $condition) {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserVMSDetailsMutation>response.data.deleteUserVMSDetails;
  }
  async CreateCustomerUserPermissionSet(
    input: CreateCustomerUserPermissionSetInput,
    condition?: ModelCustomerUserPermissionSetConditionInput
  ): Promise<CreateCustomerUserPermissionSetMutation> {
    const statement = `mutation CreateCustomerUserPermissionSet($input: CreateCustomerUserPermissionSetInput!, $condition: ModelCustomerUserPermissionSetConditionInput) {
        createCustomerUserPermissionSet(input: $input, condition: $condition) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerUserPermissionSetMutation>(
      response.data.createCustomerUserPermissionSet
    );
  }
  async UpdateCustomerUserPermissionSet(
    input: UpdateCustomerUserPermissionSetInput,
    condition?: ModelCustomerUserPermissionSetConditionInput
  ): Promise<UpdateCustomerUserPermissionSetMutation> {
    const statement = `mutation UpdateCustomerUserPermissionSet($input: UpdateCustomerUserPermissionSetInput!, $condition: ModelCustomerUserPermissionSetConditionInput) {
        updateCustomerUserPermissionSet(input: $input, condition: $condition) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerUserPermissionSetMutation>(
      response.data.updateCustomerUserPermissionSet
    );
  }
  async DeleteCustomerUserPermissionSet(
    input: DeleteCustomerUserPermissionSetInput,
    condition?: ModelCustomerUserPermissionSetConditionInput
  ): Promise<DeleteCustomerUserPermissionSetMutation> {
    const statement = `mutation DeleteCustomerUserPermissionSet($input: DeleteCustomerUserPermissionSetInput!, $condition: ModelCustomerUserPermissionSetConditionInput) {
        deleteCustomerUserPermissionSet(input: $input, condition: $condition) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerUserPermissionSetMutation>(
      response.data.deleteCustomerUserPermissionSet
    );
  }
  async CreateCustomerUserPermission(
    input: CreateCustomerUserPermissionInput,
    condition?: ModelCustomerUserPermissionConditionInput
  ): Promise<CreateCustomerUserPermissionMutation> {
    const statement = `mutation CreateCustomerUserPermission($input: CreateCustomerUserPermissionInput!, $condition: ModelCustomerUserPermissionConditionInput) {
        createCustomerUserPermission(input: $input, condition: $condition) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerUserPermissionMutation>(
      response.data.createCustomerUserPermission
    );
  }
  async UpdateCustomerUserPermission(
    input: UpdateCustomerUserPermissionInput,
    condition?: ModelCustomerUserPermissionConditionInput
  ): Promise<UpdateCustomerUserPermissionMutation> {
    const statement = `mutation UpdateCustomerUserPermission($input: UpdateCustomerUserPermissionInput!, $condition: ModelCustomerUserPermissionConditionInput) {
        updateCustomerUserPermission(input: $input, condition: $condition) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerUserPermissionMutation>(
      response.data.updateCustomerUserPermission
    );
  }
  async DeleteCustomerUserPermission(
    input: DeleteCustomerUserPermissionInput,
    condition?: ModelCustomerUserPermissionConditionInput
  ): Promise<DeleteCustomerUserPermissionMutation> {
    const statement = `mutation DeleteCustomerUserPermission($input: DeleteCustomerUserPermissionInput!, $condition: ModelCustomerUserPermissionConditionInput) {
        deleteCustomerUserPermission(input: $input, condition: $condition) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerUserPermissionMutation>(
      response.data.deleteCustomerUserPermission
    );
  }
  async CreateTax(
    input: CreateTaxInput,
    condition?: ModelTaxConditionInput
  ): Promise<CreateTaxMutation> {
    const statement = `mutation CreateTax($input: CreateTaxInput!, $condition: ModelTaxConditionInput) {
        createTax(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxMutation>response.data.createTax;
  }
  async UpdateTax(
    input: UpdateTaxInput,
    condition?: ModelTaxConditionInput
  ): Promise<UpdateTaxMutation> {
    const statement = `mutation UpdateTax($input: UpdateTaxInput!, $condition: ModelTaxConditionInput) {
        updateTax(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxMutation>response.data.updateTax;
  }
  async DeleteTax(
    input: DeleteTaxInput,
    condition?: ModelTaxConditionInput
  ): Promise<DeleteTaxMutation> {
    const statement = `mutation DeleteTax($input: DeleteTaxInput!, $condition: ModelTaxConditionInput) {
        deleteTax(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxMutation>response.data.deleteTax;
  }
  async CreateTaxesTaxType(
    input: CreateTaxesTaxTypeInput,
    condition?: ModelTaxesTaxTypeConditionInput
  ): Promise<CreateTaxesTaxTypeMutation> {
    const statement = `mutation CreateTaxesTaxType($input: CreateTaxesTaxTypeInput!, $condition: ModelTaxesTaxTypeConditionInput) {
        createTaxesTaxType(input: $input, condition: $condition) {
          __typename
          id
          taxId
          taxTypeId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          tax {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          integratorId
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxesTaxTypeMutation>response.data.createTaxesTaxType;
  }
  async UpdateTaxesTaxType(
    input: UpdateTaxesTaxTypeInput,
    condition?: ModelTaxesTaxTypeConditionInput
  ): Promise<UpdateTaxesTaxTypeMutation> {
    const statement = `mutation UpdateTaxesTaxType($input: UpdateTaxesTaxTypeInput!, $condition: ModelTaxesTaxTypeConditionInput) {
        updateTaxesTaxType(input: $input, condition: $condition) {
          __typename
          id
          taxId
          taxTypeId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          tax {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          integratorId
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxesTaxTypeMutation>response.data.updateTaxesTaxType;
  }
  async DeleteTaxesTaxType(
    input: DeleteTaxesTaxTypeInput,
    condition?: ModelTaxesTaxTypeConditionInput
  ): Promise<DeleteTaxesTaxTypeMutation> {
    const statement = `mutation DeleteTaxesTaxType($input: DeleteTaxesTaxTypeInput!, $condition: ModelTaxesTaxTypeConditionInput) {
        deleteTaxesTaxType(input: $input, condition: $condition) {
          __typename
          id
          taxId
          taxTypeId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          tax {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          integratorId
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxesTaxTypeMutation>response.data.deleteTaxesTaxType;
  }
  async CreateTaxType(
    input: CreateTaxTypeInput,
    condition?: ModelTaxTypeConditionInput
  ): Promise<CreateTaxTypeMutation> {
    const statement = `mutation CreateTaxType($input: CreateTaxTypeInput!, $condition: ModelTaxTypeConditionInput) {
        createTaxType(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxTypeMutation>response.data.createTaxType;
  }
  async UpdateTaxType(
    input: UpdateTaxTypeInput,
    condition?: ModelTaxTypeConditionInput
  ): Promise<UpdateTaxTypeMutation> {
    const statement = `mutation UpdateTaxType($input: UpdateTaxTypeInput!, $condition: ModelTaxTypeConditionInput) {
        updateTaxType(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxTypeMutation>response.data.updateTaxType;
  }
  async DeleteTaxType(
    input: DeleteTaxTypeInput,
    condition?: ModelTaxTypeConditionInput
  ): Promise<DeleteTaxTypeMutation> {
    const statement = `mutation DeleteTaxType($input: DeleteTaxTypeInput!, $condition: ModelTaxTypeConditionInput) {
        deleteTaxType(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxTypeMutation>response.data.deleteTaxType;
  }
  async CreateSetupFee(
    input: CreateSetupFeeInput,
    condition?: ModelSetupFeeConditionInput
  ): Promise<CreateSetupFeeMutation> {
    const statement = `mutation CreateSetupFee($input: CreateSetupFeeInput!, $condition: ModelSetupFeeConditionInput) {
        createSetupFee(input: $input, condition: $condition) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSetupFeeMutation>response.data.createSetupFee;
  }
  async UpdateSetupFee(
    input: UpdateSetupFeeInput,
    condition?: ModelSetupFeeConditionInput
  ): Promise<UpdateSetupFeeMutation> {
    const statement = `mutation UpdateSetupFee($input: UpdateSetupFeeInput!, $condition: ModelSetupFeeConditionInput) {
        updateSetupFee(input: $input, condition: $condition) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSetupFeeMutation>response.data.updateSetupFee;
  }
  async DeleteSetupFee(
    input: DeleteSetupFeeInput,
    condition?: ModelSetupFeeConditionInput
  ): Promise<DeleteSetupFeeMutation> {
    const statement = `mutation DeleteSetupFee($input: DeleteSetupFeeInput!, $condition: ModelSetupFeeConditionInput) {
        deleteSetupFee(input: $input, condition: $condition) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSetupFeeMutation>response.data.deleteSetupFee;
  }
  async CreateProduct(
    input: CreateProductInput,
    condition?: ModelProductConditionInput
  ): Promise<CreateProductMutation> {
    const statement = `mutation CreateProduct($input: CreateProductInput!, $condition: ModelProductConditionInput) {
        createProduct(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          name
          nameForInvoice
          description
          productTaxTypeId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          status
          type
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          publishedFor
          publishedForArchived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductMutation>response.data.createProduct;
  }
  async UpdateProduct(
    input: UpdateProductInput,
    condition?: ModelProductConditionInput
  ): Promise<UpdateProductMutation> {
    const statement = `mutation UpdateProduct($input: UpdateProductInput!, $condition: ModelProductConditionInput) {
        updateProduct(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          name
          nameForInvoice
          description
          productTaxTypeId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          status
          type
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          publishedFor
          publishedForArchived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductMutation>response.data.updateProduct;
  }
  async DeleteProduct(
    input: DeleteProductInput,
    condition?: ModelProductConditionInput
  ): Promise<DeleteProductMutation> {
    const statement = `mutation DeleteProduct($input: DeleteProductInput!, $condition: ModelProductConditionInput) {
        deleteProduct(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          name
          nameForInvoice
          description
          productTaxTypeId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          status
          type
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          publishedFor
          publishedForArchived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductMutation>response.data.deleteProduct;
  }
  async CreateCameraPlan(
    input: CreateCameraPlanInput,
    condition?: ModelCameraPlanConditionInput
  ): Promise<CreateCameraPlanMutation> {
    const statement = `mutation CreateCameraPlan($input: CreateCameraPlanInput!, $condition: ModelCameraPlanConditionInput) {
        createCameraPlan(input: $input, condition: $condition) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCameraPlanMutation>response.data.createCameraPlan;
  }
  async UpdateCameraPlan(
    input: UpdateCameraPlanInput,
    condition?: ModelCameraPlanConditionInput
  ): Promise<UpdateCameraPlanMutation> {
    const statement = `mutation UpdateCameraPlan($input: UpdateCameraPlanInput!, $condition: ModelCameraPlanConditionInput) {
        updateCameraPlan(input: $input, condition: $condition) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCameraPlanMutation>response.data.updateCameraPlan;
  }
  async DeleteCameraPlan(
    input: DeleteCameraPlanInput,
    condition?: ModelCameraPlanConditionInput
  ): Promise<DeleteCameraPlanMutation> {
    const statement = `mutation DeleteCameraPlan($input: DeleteCameraPlanInput!, $condition: ModelCameraPlanConditionInput) {
        deleteCameraPlan(input: $input, condition: $condition) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCameraPlanMutation>response.data.deleteCameraPlan;
  }
  async CreateLivePatrol(
    input: CreateLivePatrolInput,
    condition?: ModelLivePatrolConditionInput
  ): Promise<CreateLivePatrolMutation> {
    const statement = `mutation CreateLivePatrol($input: CreateLivePatrolInput!, $condition: ModelLivePatrolConditionInput) {
        createLivePatrol(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          enabled
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLivePatrolMutation>response.data.createLivePatrol;
  }
  async UpdateLivePatrol(
    input: UpdateLivePatrolInput,
    condition?: ModelLivePatrolConditionInput
  ): Promise<UpdateLivePatrolMutation> {
    const statement = `mutation UpdateLivePatrol($input: UpdateLivePatrolInput!, $condition: ModelLivePatrolConditionInput) {
        updateLivePatrol(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          enabled
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLivePatrolMutation>response.data.updateLivePatrol;
  }
  async DeleteLivePatrol(
    input: DeleteLivePatrolInput,
    condition?: ModelLivePatrolConditionInput
  ): Promise<DeleteLivePatrolMutation> {
    const statement = `mutation DeleteLivePatrol($input: DeleteLivePatrolInput!, $condition: ModelLivePatrolConditionInput) {
        deleteLivePatrol(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          enabled
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLivePatrolMutation>response.data.deleteLivePatrol;
  }
  async CreateOperatorMinutes(
    input: CreateOperatorMinutesInput,
    condition?: ModelOperatorMinutesConditionInput
  ): Promise<CreateOperatorMinutesMutation> {
    const statement = `mutation CreateOperatorMinutes($input: CreateOperatorMinutesInput!, $condition: ModelOperatorMinutesConditionInput) {
        createOperatorMinutes(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOperatorMinutesMutation>response.data.createOperatorMinutes;
  }
  async UpdateOperatorMinutes(
    input: UpdateOperatorMinutesInput,
    condition?: ModelOperatorMinutesConditionInput
  ): Promise<UpdateOperatorMinutesMutation> {
    const statement = `mutation UpdateOperatorMinutes($input: UpdateOperatorMinutesInput!, $condition: ModelOperatorMinutesConditionInput) {
        updateOperatorMinutes(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOperatorMinutesMutation>response.data.updateOperatorMinutes;
  }
  async DeleteOperatorMinutes(
    input: DeleteOperatorMinutesInput,
    condition?: ModelOperatorMinutesConditionInput
  ): Promise<DeleteOperatorMinutesMutation> {
    const statement = `mutation DeleteOperatorMinutes($input: DeleteOperatorMinutesInput!, $condition: ModelOperatorMinutesConditionInput) {
        deleteOperatorMinutes(input: $input, condition: $condition) {
          __typename
          id
          cameraPlanId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOperatorMinutesMutation>response.data.deleteOperatorMinutes;
  }
  async CreateSiteSubscription(
    input: CreateSiteSubscriptionInput,
    condition?: ModelSiteSubscriptionConditionInput
  ): Promise<CreateSiteSubscriptionMutation> {
    const statement = `mutation CreateSiteSubscription($input: CreateSiteSubscriptionInput!, $condition: ModelSiteSubscriptionConditionInput) {
        createSiteSubscription(input: $input, condition: $condition) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSiteSubscriptionMutation>response.data.createSiteSubscription;
  }
  async UpdateSiteSubscription(
    input: UpdateSiteSubscriptionInput,
    condition?: ModelSiteSubscriptionConditionInput
  ): Promise<UpdateSiteSubscriptionMutation> {
    const statement = `mutation UpdateSiteSubscription($input: UpdateSiteSubscriptionInput!, $condition: ModelSiteSubscriptionConditionInput) {
        updateSiteSubscription(input: $input, condition: $condition) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSiteSubscriptionMutation>response.data.updateSiteSubscription;
  }
  async DeleteSiteSubscription(
    input: DeleteSiteSubscriptionInput,
    condition?: ModelSiteSubscriptionConditionInput
  ): Promise<DeleteSiteSubscriptionMutation> {
    const statement = `mutation DeleteSiteSubscription($input: DeleteSiteSubscriptionInput!, $condition: ModelSiteSubscriptionConditionInput) {
        deleteSiteSubscription(input: $input, condition: $condition) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSiteSubscriptionMutation>response.data.deleteSiteSubscription;
  }
  async CreateSubscriptionStripeDetail(
    input: CreateSubscriptionStripeDetailInput,
    condition?: ModelSubscriptionStripeDetailConditionInput
  ): Promise<CreateSubscriptionStripeDetailMutation> {
    const statement = `mutation CreateSubscriptionStripeDetail($input: CreateSubscriptionStripeDetailInput!, $condition: ModelSubscriptionStripeDetailConditionInput) {
        createSubscriptionStripeDetail(input: $input, condition: $condition) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubscriptionStripeDetailMutation>(
      response.data.createSubscriptionStripeDetail
    );
  }
  async UpdateSubscriptionStripeDetail(
    input: UpdateSubscriptionStripeDetailInput,
    condition?: ModelSubscriptionStripeDetailConditionInput
  ): Promise<UpdateSubscriptionStripeDetailMutation> {
    const statement = `mutation UpdateSubscriptionStripeDetail($input: UpdateSubscriptionStripeDetailInput!, $condition: ModelSubscriptionStripeDetailConditionInput) {
        updateSubscriptionStripeDetail(input: $input, condition: $condition) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSubscriptionStripeDetailMutation>(
      response.data.updateSubscriptionStripeDetail
    );
  }
  async DeleteSubscriptionStripeDetail(
    input: DeleteSubscriptionStripeDetailInput,
    condition?: ModelSubscriptionStripeDetailConditionInput
  ): Promise<DeleteSubscriptionStripeDetailMutation> {
    const statement = `mutation DeleteSubscriptionStripeDetail($input: DeleteSubscriptionStripeDetailInput!, $condition: ModelSubscriptionStripeDetailConditionInput) {
        deleteSubscriptionStripeDetail(input: $input, condition: $condition) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSubscriptionStripeDetailMutation>(
      response.data.deleteSubscriptionStripeDetail
    );
  }
  async CreateStripePaymentMethod(
    input: CreateStripePaymentMethodInput,
    condition?: ModelStripePaymentMethodConditionInput
  ): Promise<CreateStripePaymentMethodMutation> {
    const statement = `mutation CreateStripePaymentMethod($input: CreateStripePaymentMethodInput!, $condition: ModelStripePaymentMethodConditionInput) {
        createStripePaymentMethod(input: $input, condition: $condition) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStripePaymentMethodMutation>(
      response.data.createStripePaymentMethod
    );
  }
  async UpdateStripePaymentMethod(
    input: UpdateStripePaymentMethodInput,
    condition?: ModelStripePaymentMethodConditionInput
  ): Promise<UpdateStripePaymentMethodMutation> {
    const statement = `mutation UpdateStripePaymentMethod($input: UpdateStripePaymentMethodInput!, $condition: ModelStripePaymentMethodConditionInput) {
        updateStripePaymentMethod(input: $input, condition: $condition) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStripePaymentMethodMutation>(
      response.data.updateStripePaymentMethod
    );
  }
  async DeleteStripePaymentMethod(
    input: DeleteStripePaymentMethodInput,
    condition?: ModelStripePaymentMethodConditionInput
  ): Promise<DeleteStripePaymentMethodMutation> {
    const statement = `mutation DeleteStripePaymentMethod($input: DeleteStripePaymentMethodInput!, $condition: ModelStripePaymentMethodConditionInput) {
        deleteStripePaymentMethod(input: $input, condition: $condition) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStripePaymentMethodMutation>(
      response.data.deleteStripePaymentMethod
    );
  }
  async CreateAppliedProduct(
    input: CreateAppliedProductInput,
    condition?: ModelAppliedProductConditionInput
  ): Promise<CreateAppliedProductMutation> {
    const statement = `mutation CreateAppliedProduct($input: CreateAppliedProductInput!, $condition: ModelAppliedProductConditionInput) {
        createAppliedProduct(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          name
          nameForInvoice
          description
          appliedProductTaxTypeId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAppliedProductMutation>response.data.createAppliedProduct;
  }
  async UpdateAppliedProduct(
    input: UpdateAppliedProductInput,
    condition?: ModelAppliedProductConditionInput
  ): Promise<UpdateAppliedProductMutation> {
    const statement = `mutation UpdateAppliedProduct($input: UpdateAppliedProductInput!, $condition: ModelAppliedProductConditionInput) {
        updateAppliedProduct(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          name
          nameForInvoice
          description
          appliedProductTaxTypeId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAppliedProductMutation>response.data.updateAppliedProduct;
  }
  async DeleteAppliedProduct(
    input: DeleteAppliedProductInput,
    condition?: ModelAppliedProductConditionInput
  ): Promise<DeleteAppliedProductMutation> {
    const statement = `mutation DeleteAppliedProduct($input: DeleteAppliedProductInput!, $condition: ModelAppliedProductConditionInput) {
        deleteAppliedProduct(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          name
          nameForInvoice
          description
          appliedProductTaxTypeId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAppliedProductMutation>response.data.deleteAppliedProduct;
  }
  async CreateAppliedCameraPlan(
    input: CreateAppliedCameraPlanInput,
    condition?: ModelAppliedCameraPlanConditionInput
  ): Promise<CreateAppliedCameraPlanMutation> {
    const statement = `mutation CreateAppliedCameraPlan($input: CreateAppliedCameraPlanInput!, $condition: ModelAppliedCameraPlanConditionInput) {
        createAppliedCameraPlan(input: $input, condition: $condition) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAppliedCameraPlanMutation>(
      response.data.createAppliedCameraPlan
    );
  }
  async UpdateAppliedCameraPlan(
    input: UpdateAppliedCameraPlanInput,
    condition?: ModelAppliedCameraPlanConditionInput
  ): Promise<UpdateAppliedCameraPlanMutation> {
    const statement = `mutation UpdateAppliedCameraPlan($input: UpdateAppliedCameraPlanInput!, $condition: ModelAppliedCameraPlanConditionInput) {
        updateAppliedCameraPlan(input: $input, condition: $condition) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAppliedCameraPlanMutation>(
      response.data.updateAppliedCameraPlan
    );
  }
  async DeleteAppliedCameraPlan(
    input: DeleteAppliedCameraPlanInput,
    condition?: ModelAppliedCameraPlanConditionInput
  ): Promise<DeleteAppliedCameraPlanMutation> {
    const statement = `mutation DeleteAppliedCameraPlan($input: DeleteAppliedCameraPlanInput!, $condition: ModelAppliedCameraPlanConditionInput) {
        deleteAppliedCameraPlan(input: $input, condition: $condition) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAppliedCameraPlanMutation>(
      response.data.deleteAppliedCameraPlan
    );
  }
  async CreateAppliedLivePatrol(
    input: CreateAppliedLivePatrolInput,
    condition?: ModelAppliedLivePatrolConditionInput
  ): Promise<CreateAppliedLivePatrolMutation> {
    const statement = `mutation CreateAppliedLivePatrol($input: CreateAppliedLivePatrolInput!, $condition: ModelAppliedLivePatrolConditionInput) {
        createAppliedLivePatrol(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          enabled
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAppliedLivePatrolMutation>(
      response.data.createAppliedLivePatrol
    );
  }
  async UpdateAppliedLivePatrol(
    input: UpdateAppliedLivePatrolInput,
    condition?: ModelAppliedLivePatrolConditionInput
  ): Promise<UpdateAppliedLivePatrolMutation> {
    const statement = `mutation UpdateAppliedLivePatrol($input: UpdateAppliedLivePatrolInput!, $condition: ModelAppliedLivePatrolConditionInput) {
        updateAppliedLivePatrol(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          enabled
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAppliedLivePatrolMutation>(
      response.data.updateAppliedLivePatrol
    );
  }
  async DeleteAppliedLivePatrol(
    input: DeleteAppliedLivePatrolInput,
    condition?: ModelAppliedLivePatrolConditionInput
  ): Promise<DeleteAppliedLivePatrolMutation> {
    const statement = `mutation DeleteAppliedLivePatrol($input: DeleteAppliedLivePatrolInput!, $condition: ModelAppliedLivePatrolConditionInput) {
        deleteAppliedLivePatrol(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          enabled
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAppliedLivePatrolMutation>(
      response.data.deleteAppliedLivePatrol
    );
  }
  async CreateAppliedOperatorMinutes(
    input: CreateAppliedOperatorMinutesInput,
    condition?: ModelAppliedOperatorMinutesConditionInput
  ): Promise<CreateAppliedOperatorMinutesMutation> {
    const statement = `mutation CreateAppliedOperatorMinutes($input: CreateAppliedOperatorMinutesInput!, $condition: ModelAppliedOperatorMinutesConditionInput) {
        createAppliedOperatorMinutes(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAppliedOperatorMinutesMutation>(
      response.data.createAppliedOperatorMinutes
    );
  }
  async UpdateAppliedOperatorMinutes(
    input: UpdateAppliedOperatorMinutesInput,
    condition?: ModelAppliedOperatorMinutesConditionInput
  ): Promise<UpdateAppliedOperatorMinutesMutation> {
    const statement = `mutation UpdateAppliedOperatorMinutes($input: UpdateAppliedOperatorMinutesInput!, $condition: ModelAppliedOperatorMinutesConditionInput) {
        updateAppliedOperatorMinutes(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAppliedOperatorMinutesMutation>(
      response.data.updateAppliedOperatorMinutes
    );
  }
  async DeleteAppliedOperatorMinutes(
    input: DeleteAppliedOperatorMinutesInput,
    condition?: ModelAppliedOperatorMinutesConditionInput
  ): Promise<DeleteAppliedOperatorMinutesMutation> {
    const statement = `mutation DeleteAppliedOperatorMinutes($input: DeleteAppliedOperatorMinutesInput!, $condition: ModelAppliedOperatorMinutesConditionInput) {
        deleteAppliedOperatorMinutes(input: $input, condition: $condition) {
          __typename
          id
          appliedCameraPlanId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAppliedOperatorMinutesMutation>(
      response.data.deleteAppliedOperatorMinutes
    );
  }
  async CreateAppliedSetupFee(
    input: CreateAppliedSetupFeeInput,
    condition?: ModelAppliedSetupFeeConditionInput
  ): Promise<CreateAppliedSetupFeeMutation> {
    const statement = `mutation CreateAppliedSetupFee($input: CreateAppliedSetupFeeInput!, $condition: ModelAppliedSetupFeeConditionInput) {
        createAppliedSetupFee(input: $input, condition: $condition) {
          __typename
          id
          parentId
          subscriptionId
          amount
          name
          currency
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAppliedSetupFeeMutation>response.data.createAppliedSetupFee;
  }
  async UpdateAppliedSetupFee(
    input: UpdateAppliedSetupFeeInput,
    condition?: ModelAppliedSetupFeeConditionInput
  ): Promise<UpdateAppliedSetupFeeMutation> {
    const statement = `mutation UpdateAppliedSetupFee($input: UpdateAppliedSetupFeeInput!, $condition: ModelAppliedSetupFeeConditionInput) {
        updateAppliedSetupFee(input: $input, condition: $condition) {
          __typename
          id
          parentId
          subscriptionId
          amount
          name
          currency
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAppliedSetupFeeMutation>response.data.updateAppliedSetupFee;
  }
  async DeleteAppliedSetupFee(
    input: DeleteAppliedSetupFeeInput,
    condition?: ModelAppliedSetupFeeConditionInput
  ): Promise<DeleteAppliedSetupFeeMutation> {
    const statement = `mutation DeleteAppliedSetupFee($input: DeleteAppliedSetupFeeInput!, $condition: ModelAppliedSetupFeeConditionInput) {
        deleteAppliedSetupFee(input: $input, condition: $condition) {
          __typename
          id
          parentId
          subscriptionId
          amount
          name
          currency
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAppliedSetupFeeMutation>response.data.deleteAppliedSetupFee;
  }
  async CreateTransactionLineItem(
    input: CreateTransactionLineItemInput,
    condition?: ModelTransactionLineItemConditionInput
  ): Promise<CreateTransactionLineItemMutation> {
    const statement = `mutation CreateTransactionLineItem($input: CreateTransactionLineItemInput!, $condition: ModelTransactionLineItemConditionInput) {
        createTransactionLineItem(input: $input, condition: $condition) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTransactionLineItemMutation>(
      response.data.createTransactionLineItem
    );
  }
  async UpdateTransactionLineItem(
    input: UpdateTransactionLineItemInput,
    condition?: ModelTransactionLineItemConditionInput
  ): Promise<UpdateTransactionLineItemMutation> {
    const statement = `mutation UpdateTransactionLineItem($input: UpdateTransactionLineItemInput!, $condition: ModelTransactionLineItemConditionInput) {
        updateTransactionLineItem(input: $input, condition: $condition) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTransactionLineItemMutation>(
      response.data.updateTransactionLineItem
    );
  }
  async DeleteTransactionLineItem(
    input: DeleteTransactionLineItemInput,
    condition?: ModelTransactionLineItemConditionInput
  ): Promise<DeleteTransactionLineItemMutation> {
    const statement = `mutation DeleteTransactionLineItem($input: DeleteTransactionLineItemInput!, $condition: ModelTransactionLineItemConditionInput) {
        deleteTransactionLineItem(input: $input, condition: $condition) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTransactionLineItemMutation>(
      response.data.deleteTransactionLineItem
    );
  }
  async CreateTransactionLineItemTax(
    input: CreateTransactionLineItemTaxInput,
    condition?: ModelTransactionLineItemTaxConditionInput
  ): Promise<CreateTransactionLineItemTaxMutation> {
    const statement = `mutation CreateTransactionLineItemTax($input: CreateTransactionLineItemTaxInput!, $condition: ModelTransactionLineItemTaxConditionInput) {
        createTransactionLineItemTax(input: $input, condition: $condition) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTransactionLineItemTaxMutation>(
      response.data.createTransactionLineItemTax
    );
  }
  async UpdateTransactionLineItemTax(
    input: UpdateTransactionLineItemTaxInput,
    condition?: ModelTransactionLineItemTaxConditionInput
  ): Promise<UpdateTransactionLineItemTaxMutation> {
    const statement = `mutation UpdateTransactionLineItemTax($input: UpdateTransactionLineItemTaxInput!, $condition: ModelTransactionLineItemTaxConditionInput) {
        updateTransactionLineItemTax(input: $input, condition: $condition) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTransactionLineItemTaxMutation>(
      response.data.updateTransactionLineItemTax
    );
  }
  async DeleteTransactionLineItemTax(
    input: DeleteTransactionLineItemTaxInput,
    condition?: ModelTransactionLineItemTaxConditionInput
  ): Promise<DeleteTransactionLineItemTaxMutation> {
    const statement = `mutation DeleteTransactionLineItemTax($input: DeleteTransactionLineItemTaxInput!, $condition: ModelTransactionLineItemTaxConditionInput) {
        deleteTransactionLineItemTax(input: $input, condition: $condition) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTransactionLineItemTaxMutation>(
      response.data.deleteTransactionLineItemTax
    );
  }
  async CreateInvoice(
    input: CreateInvoiceInput,
    condition?: ModelInvoiceConditionInput
  ): Promise<CreateInvoiceMutation> {
    const statement = `mutation CreateInvoice($input: CreateInvoiceInput!, $condition: ModelInvoiceConditionInput) {
        createInvoice(input: $input, condition: $condition) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInvoiceMutation>response.data.createInvoice;
  }
  async UpdateInvoice(
    input: UpdateInvoiceInput,
    condition?: ModelInvoiceConditionInput
  ): Promise<UpdateInvoiceMutation> {
    const statement = `mutation UpdateInvoice($input: UpdateInvoiceInput!, $condition: ModelInvoiceConditionInput) {
        updateInvoice(input: $input, condition: $condition) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInvoiceMutation>response.data.updateInvoice;
  }
  async DeleteInvoice(
    input: DeleteInvoiceInput,
    condition?: ModelInvoiceConditionInput
  ): Promise<DeleteInvoiceMutation> {
    const statement = `mutation DeleteInvoice($input: DeleteInvoiceInput!, $condition: ModelInvoiceConditionInput) {
        deleteInvoice(input: $input, condition: $condition) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInvoiceMutation>response.data.deleteInvoice;
  }
  async CreateInvoicePayment(
    input: CreateInvoicePaymentInput,
    condition?: ModelInvoicePaymentConditionInput
  ): Promise<CreateInvoicePaymentMutation> {
    const statement = `mutation CreateInvoicePayment($input: CreateInvoicePaymentInput!, $condition: ModelInvoicePaymentConditionInput) {
        createInvoicePayment(input: $input, condition: $condition) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInvoicePaymentMutation>response.data.createInvoicePayment;
  }
  async UpdateInvoicePayment(
    input: UpdateInvoicePaymentInput,
    condition?: ModelInvoicePaymentConditionInput
  ): Promise<UpdateInvoicePaymentMutation> {
    const statement = `mutation UpdateInvoicePayment($input: UpdateInvoicePaymentInput!, $condition: ModelInvoicePaymentConditionInput) {
        updateInvoicePayment(input: $input, condition: $condition) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInvoicePaymentMutation>response.data.updateInvoicePayment;
  }
  async DeleteInvoicePayment(
    input: DeleteInvoicePaymentInput,
    condition?: ModelInvoicePaymentConditionInput
  ): Promise<DeleteInvoicePaymentMutation> {
    const statement = `mutation DeleteInvoicePayment($input: DeleteInvoicePaymentInput!, $condition: ModelInvoicePaymentConditionInput) {
        deleteInvoicePayment(input: $input, condition: $condition) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInvoicePaymentMutation>response.data.deleteInvoicePayment;
  }
  async CreateInvoiceTransferPayment(
    input: CreateInvoiceTransferPaymentInput,
    condition?: ModelInvoiceTransferPaymentConditionInput
  ): Promise<CreateInvoiceTransferPaymentMutation> {
    const statement = `mutation CreateInvoiceTransferPayment($input: CreateInvoiceTransferPaymentInput!, $condition: ModelInvoiceTransferPaymentConditionInput) {
        createInvoiceTransferPayment(input: $input, condition: $condition) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInvoiceTransferPaymentMutation>(
      response.data.createInvoiceTransferPayment
    );
  }
  async UpdateInvoiceTransferPayment(
    input: UpdateInvoiceTransferPaymentInput,
    condition?: ModelInvoiceTransferPaymentConditionInput
  ): Promise<UpdateInvoiceTransferPaymentMutation> {
    const statement = `mutation UpdateInvoiceTransferPayment($input: UpdateInvoiceTransferPaymentInput!, $condition: ModelInvoiceTransferPaymentConditionInput) {
        updateInvoiceTransferPayment(input: $input, condition: $condition) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInvoiceTransferPaymentMutation>(
      response.data.updateInvoiceTransferPayment
    );
  }
  async DeleteInvoiceTransferPayment(
    input: DeleteInvoiceTransferPaymentInput,
    condition?: ModelInvoiceTransferPaymentConditionInput
  ): Promise<DeleteInvoiceTransferPaymentMutation> {
    const statement = `mutation DeleteInvoiceTransferPayment($input: DeleteInvoiceTransferPaymentInput!, $condition: ModelInvoiceTransferPaymentConditionInput) {
        deleteInvoiceTransferPayment(input: $input, condition: $condition) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInvoiceTransferPaymentMutation>(
      response.data.deleteInvoiceTransferPayment
    );
  }
  async CreateWalletTransfer(
    input: CreateWalletTransferInput,
    condition?: ModelWalletTransferConditionInput
  ): Promise<CreateWalletTransferMutation> {
    const statement = `mutation CreateWalletTransfer($input: CreateWalletTransferInput!, $condition: ModelWalletTransferConditionInput) {
        createWalletTransfer(input: $input, condition: $condition) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWalletTransferMutation>response.data.createWalletTransfer;
  }
  async UpdateWalletTransfer(
    input: UpdateWalletTransferInput,
    condition?: ModelWalletTransferConditionInput
  ): Promise<UpdateWalletTransferMutation> {
    const statement = `mutation UpdateWalletTransfer($input: UpdateWalletTransferInput!, $condition: ModelWalletTransferConditionInput) {
        updateWalletTransfer(input: $input, condition: $condition) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWalletTransferMutation>response.data.updateWalletTransfer;
  }
  async DeleteWalletTransfer(
    input: DeleteWalletTransferInput,
    condition?: ModelWalletTransferConditionInput
  ): Promise<DeleteWalletTransferMutation> {
    const statement = `mutation DeleteWalletTransfer($input: DeleteWalletTransferInput!, $condition: ModelWalletTransferConditionInput) {
        deleteWalletTransfer(input: $input, condition: $condition) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWalletTransferMutation>response.data.deleteWalletTransfer;
  }
  async CreateStripePayoutDetails(
    input: CreateStripePayoutDetailsInput,
    condition?: ModelStripePayoutDetailsConditionInput
  ): Promise<CreateStripePayoutDetailsMutation> {
    const statement = `mutation CreateStripePayoutDetails($input: CreateStripePayoutDetailsInput!, $condition: ModelStripePayoutDetailsConditionInput) {
        createStripePayoutDetails(input: $input, condition: $condition) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStripePayoutDetailsMutation>(
      response.data.createStripePayoutDetails
    );
  }
  async UpdateStripePayoutDetails(
    input: UpdateStripePayoutDetailsInput,
    condition?: ModelStripePayoutDetailsConditionInput
  ): Promise<UpdateStripePayoutDetailsMutation> {
    const statement = `mutation UpdateStripePayoutDetails($input: UpdateStripePayoutDetailsInput!, $condition: ModelStripePayoutDetailsConditionInput) {
        updateStripePayoutDetails(input: $input, condition: $condition) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStripePayoutDetailsMutation>(
      response.data.updateStripePayoutDetails
    );
  }
  async DeleteStripePayoutDetails(
    input: DeleteStripePayoutDetailsInput,
    condition?: ModelStripePayoutDetailsConditionInput
  ): Promise<DeleteStripePayoutDetailsMutation> {
    const statement = `mutation DeleteStripePayoutDetails($input: DeleteStripePayoutDetailsInput!, $condition: ModelStripePayoutDetailsConditionInput) {
        deleteStripePayoutDetails(input: $input, condition: $condition) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStripePayoutDetailsMutation>(
      response.data.deleteStripePayoutDetails
    );
  }
  async CreateExchangeRateSetting(
    input: CreateExchangeRateSettingInput,
    condition?: ModelExchangeRateSettingConditionInput
  ): Promise<CreateExchangeRateSettingMutation> {
    const statement = `mutation CreateExchangeRateSetting($input: CreateExchangeRateSettingInput!, $condition: ModelExchangeRateSettingConditionInput) {
        createExchangeRateSetting(input: $input, condition: $condition) {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExchangeRateSettingMutation>(
      response.data.createExchangeRateSetting
    );
  }
  async UpdateExchangeRateSetting(
    input: UpdateExchangeRateSettingInput,
    condition?: ModelExchangeRateSettingConditionInput
  ): Promise<UpdateExchangeRateSettingMutation> {
    const statement = `mutation UpdateExchangeRateSetting($input: UpdateExchangeRateSettingInput!, $condition: ModelExchangeRateSettingConditionInput) {
        updateExchangeRateSetting(input: $input, condition: $condition) {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExchangeRateSettingMutation>(
      response.data.updateExchangeRateSetting
    );
  }
  async DeleteExchangeRateSetting(
    input: DeleteExchangeRateSettingInput,
    condition?: ModelExchangeRateSettingConditionInput
  ): Promise<DeleteExchangeRateSettingMutation> {
    const statement = `mutation DeleteExchangeRateSetting($input: DeleteExchangeRateSettingInput!, $condition: ModelExchangeRateSettingConditionInput) {
        deleteExchangeRateSetting(input: $input, condition: $condition) {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExchangeRateSettingMutation>(
      response.data.deleteExchangeRateSetting
    );
  }
  async CreatePaymentTransferSettings(
    input: CreatePaymentTransferSettingsInput,
    condition?: ModelPaymentTransferSettingsConditionInput
  ): Promise<CreatePaymentTransferSettingsMutation> {
    const statement = `mutation CreatePaymentTransferSettings($input: CreatePaymentTransferSettingsInput!, $condition: ModelPaymentTransferSettingsConditionInput) {
        createPaymentTransferSettings(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePaymentTransferSettingsMutation>(
      response.data.createPaymentTransferSettings
    );
  }
  async UpdatePaymentTransferSettings(
    input: UpdatePaymentTransferSettingsInput,
    condition?: ModelPaymentTransferSettingsConditionInput
  ): Promise<UpdatePaymentTransferSettingsMutation> {
    const statement = `mutation UpdatePaymentTransferSettings($input: UpdatePaymentTransferSettingsInput!, $condition: ModelPaymentTransferSettingsConditionInput) {
        updatePaymentTransferSettings(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePaymentTransferSettingsMutation>(
      response.data.updatePaymentTransferSettings
    );
  }
  async DeletePaymentTransferSettings(
    input: DeletePaymentTransferSettingsInput,
    condition?: ModelPaymentTransferSettingsConditionInput
  ): Promise<DeletePaymentTransferSettingsMutation> {
    const statement = `mutation DeletePaymentTransferSettings($input: DeletePaymentTransferSettingsInput!, $condition: ModelPaymentTransferSettingsConditionInput) {
        deletePaymentTransferSettings(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePaymentTransferSettingsMutation>(
      response.data.deletePaymentTransferSettings
    );
  }
  async CreateWebhookHealth(
    input: CreateWebhookHealthInput,
    condition?: ModelWebhookHealthConditionInput
  ): Promise<CreateWebhookHealthMutation> {
    const statement = `mutation CreateWebhookHealth($input: CreateWebhookHealthInput!, $condition: ModelWebhookHealthConditionInput) {
        createWebhookHealth(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWebhookHealthMutation>response.data.createWebhookHealth;
  }
  async UpdateWebhookHealth(
    input: UpdateWebhookHealthInput,
    condition?: ModelWebhookHealthConditionInput
  ): Promise<UpdateWebhookHealthMutation> {
    const statement = `mutation UpdateWebhookHealth($input: UpdateWebhookHealthInput!, $condition: ModelWebhookHealthConditionInput) {
        updateWebhookHealth(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWebhookHealthMutation>response.data.updateWebhookHealth;
  }
  async DeleteWebhookHealth(
    input: DeleteWebhookHealthInput,
    condition?: ModelWebhookHealthConditionInput
  ): Promise<DeleteWebhookHealthMutation> {
    const statement = `mutation DeleteWebhookHealth($input: DeleteWebhookHealthInput!, $condition: ModelWebhookHealthConditionInput) {
        deleteWebhookHealth(input: $input, condition: $condition) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWebhookHealthMutation>response.data.deleteWebhookHealth;
  }
  async CreateMaster(
    input: CreateMasterInput,
    condition?: ModelMasterConditionInput
  ): Promise<CreateMasterMutation> {
    const statement = `mutation CreateMaster($input: CreateMasterInput!, $condition: ModelMasterConditionInput) {
        createMaster(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMasterMutation>response.data.createMaster;
  }
  async UpdateMaster(
    input: UpdateMasterInput,
    condition?: ModelMasterConditionInput
  ): Promise<UpdateMasterMutation> {
    const statement = `mutation UpdateMaster($input: UpdateMasterInput!, $condition: ModelMasterConditionInput) {
        updateMaster(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMasterMutation>response.data.updateMaster;
  }
  async DeleteMaster(
    input: DeleteMasterInput,
    condition?: ModelMasterConditionInput
  ): Promise<DeleteMasterMutation> {
    const statement = `mutation DeleteMaster($input: DeleteMasterInput!, $condition: ModelMasterConditionInput) {
        deleteMaster(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMasterMutation>response.data.deleteMaster;
  }
  async AdditionalEvents(
    cameraEventId?: string,
    ackTimestamp?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSecondaryCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AdditionalEventsQuery> {
    const statement = `query AdditionalEvents($cameraEventId: ID, $ackTimestamp: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSecondaryCameraEventFilterInput, $limit: Int, $nextToken: String) {
        additionalEvents(
          cameraEventId: $cameraEventId
          ackTimestamp: $ackTimestamp
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            vmsSourceType
            ackTimestamp
            modifiedBy
            vmsUserId
            monitoringCenterId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraEventId) {
      gqlAPIServiceArguments.cameraEventId = cameraEventId;
    }
    if (ackTimestamp) {
      gqlAPIServiceArguments.ackTimestamp = ackTimestamp;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AdditionalEventsQuery>response.data.additionalEvents;
  }
  async ProductByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductByMonitoringCenterQuery> {
    const statement = `query ProductByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        productByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductByMonitoringCenterQuery>(
      response.data.productByMonitoringCenter
    );
  }
  async AppliedSetupFeeBySub(
    subscriptionId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAppliedSetupFeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AppliedSetupFeeBySubQuery> {
    const statement = `query AppliedSetupFeeBySub($subscriptionId: ID, $sortDirection: ModelSortDirection, $filter: ModelAppliedSetupFeeFilterInput, $limit: Int, $nextToken: String) {
        appliedSetupFeeBySub(
          subscriptionId: $subscriptionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            parentId
            subscriptionId
            amount
            name
            currency
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (subscriptionId) {
      gqlAPIServiceArguments.subscriptionId = subscriptionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AppliedSetupFeeBySubQuery>response.data.appliedSetupFeeBySub;
  }
  async GetIncident(id: string): Promise<GetIncidentQuery> {
    const statement = `query GetIncident($id: ID!) {
        getIncident(id: $id) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentQuery>response.data.getIncident;
  }
  async ListIncidents(
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentsQuery> {
    const statement = `query ListIncidents($filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        listIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentsQuery>response.data.listIncidents;
  }
  async IncidentByMonitoringCenter(
    monitoringCenterId?: string,
    expectedResolvedTime?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentByMonitoringCenterQuery> {
    const statement = `query IncidentByMonitoringCenter($monitoringCenterId: ID, $expectedResolvedTime: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          expectedResolvedTime: $expectedResolvedTime
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (expectedResolvedTime) {
      gqlAPIServiceArguments.expectedResolvedTime = expectedResolvedTime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentByMonitoringCenterQuery>(
      response.data.incidentByMonitoringCenter
    );
  }
  async IncidentByMonitoringCenterAndStatus(
    monitoringCenterId?: string,
    statusExpectedResolvedTime?: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentByMonitoringCenterAndStatusQuery> {
    const statement = `query IncidentByMonitoringCenterAndStatus($monitoringCenterId: ID, $statusExpectedResolvedTime: ModelIncidentIncidentByMonitoringCenterAndStatusCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentByMonitoringCenterAndStatus(
          monitoringCenterId: $monitoringCenterId
          statusExpectedResolvedTime: $statusExpectedResolvedTime
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (statusExpectedResolvedTime) {
      gqlAPIServiceArguments.statusExpectedResolvedTime = statusExpectedResolvedTime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentByMonitoringCenterAndStatusQuery>(
      response.data.incidentByMonitoringCenterAndStatus
    );
  }
  async IncidentBySite(
    incidentSiteId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentBySiteQuery> {
    const statement = `query IncidentBySite($incidentSiteId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentBySite(
          incidentSiteId: $incidentSiteId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentSiteId) {
      gqlAPIServiceArguments.incidentSiteId = incidentSiteId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentBySiteQuery>response.data.incidentBySite;
  }
  async IncidentBySiteStatus(
    incidentSiteId?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentBySiteStatusQuery> {
    const statement = `query IncidentBySiteStatus($incidentSiteId: ID, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentBySiteStatus(
          incidentSiteId: $incidentSiteId
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentSiteId) {
      gqlAPIServiceArguments.incidentSiteId = incidentSiteId;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentBySiteStatusQuery>response.data.incidentBySiteStatus;
  }
  async IncidentBySiteAndClosedTime(
    incidentSiteId?: string,
    closedTime?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentBySiteAndClosedTimeQuery> {
    const statement = `query IncidentBySiteAndClosedTime($incidentSiteId: ID, $closedTime: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentBySiteAndClosedTime(
          incidentSiteId: $incidentSiteId
          closedTime: $closedTime
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentSiteId) {
      gqlAPIServiceArguments.incidentSiteId = incidentSiteId;
    }
    if (closedTime) {
      gqlAPIServiceArguments.closedTime = closedTime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentBySiteAndClosedTimeQuery>(
      response.data.incidentBySiteAndClosedTime
    );
  }
  async IncidentByStatusAndCreatedAt(
    status?: IncidentStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentByStatusAndCreatedAtQuery> {
    const statement = `query IncidentByStatusAndCreatedAt($status: IncidentStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentByStatusAndCreatedAt(
          status: $status
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentByStatusAndCreatedAtQuery>(
      response.data.incidentByStatusAndCreatedAt
    );
  }
  async IncidentByMCStatusCreatedAt(
    monitoringCenterId?: string,
    statusCreatedAt?: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentByMCStatusCreatedAtQuery> {
    const statement = `query IncidentByMCStatusCreatedAt($monitoringCenterId: ID, $statusCreatedAt: ModelIncidentIncidentByMCStatusCreatedAtCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentFilterInput, $limit: Int, $nextToken: String) {
        incidentByMCStatusCreatedAt(
          monitoringCenterId: $monitoringCenterId
          statusCreatedAt: $statusCreatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            integratorId
            monitoringCenterId
            status
            incidentSiteId
            incidentCustomerId
            dispositionLevel
            primaryDisposition
            dispositionList
            closedTime
            resolvedTime
            expectedResolvedTime
            billableTime
            assignedUsername
            assignmentHistory {
              __typename
              username
              assignedAt
            }
            modifiedBy
            createdAt
            hasShareExternal
            resolution
            resolutionTranslated
            resolutionSanitized
            externalPartyResponse {
              __typename
              police
              fire
              ambulance
              bylaw
              security
              outreach
              siteContact
              agenciesArrived
              notArrivedReason
              notArrivedReasonTranslated
              notArrivedReasonSanitized
            }
            invoiceId
            updatedAt
            events {
              __typename
              items {
                __typename
                id
                vmsEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                thumbnailFilename
                triggerType
                objectsFound
                integratorId
                monitoringCenterId
                incidentId
                incidentPriority
                cameraEventCameraId
                siteId
                customerId
                startTime
                endTime
                resolvedTime
                instantaneous
                cameraEventUrl
                cameraEventUrlPublic
                modifiedBy
                vmsUserId
                ackTimestamp
                createdAt
                vmsSourceType
                updatedAt
                secondaryEvents {
                  __typename
                  items {
                    __typename
                    id
                    cameraEventId
                    vmsNotes
                    vmsNotesTranslated
                    vmsNotesSanitized
                    disposition
                    eventType
                    vmsSourceType
                    ackTimestamp
                    modifiedBy
                    vmsUserId
                    monitoringCenterId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                camera {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
              }
              nextToken
            }
            site {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (statusCreatedAt) {
      gqlAPIServiceArguments.statusCreatedAt = statusCreatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentByMCStatusCreatedAtQuery>(
      response.data.incidentByMCStatusCreatedAt
    );
  }
  async GetIncidentNote(id: string): Promise<GetIncidentNoteQuery> {
    const statement = `query GetIncidentNote($id: ID!) {
        getIncidentNote(id: $id) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentNoteQuery>response.data.getIncidentNote;
  }
  async ListIncidentNotes(
    filter?: ModelIncidentNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentNotesQuery> {
    const statement = `query ListIncidentNotes($filter: ModelIncidentNoteFilterInput, $limit: Int, $nextToken: String) {
        listIncidentNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            note
            noteTranslated
            noteSanitized
            monitoringCenterId
            modifiedBy
            writtenBy
            createdAt
            shareExternal
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentNotesQuery>response.data.listIncidentNotes;
  }
  async NotesByIncident(
    incidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<NotesByIncidentQuery> {
    const statement = `query NotesByIncident($incidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentNoteFilterInput, $limit: Int, $nextToken: String) {
        notesByIncident(
          incidentId: $incidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            note
            noteTranslated
            noteSanitized
            monitoringCenterId
            modifiedBy
            writtenBy
            createdAt
            shareExternal
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotesByIncidentQuery>response.data.notesByIncident;
  }
  async GetIncidentActionList(id: string): Promise<GetIncidentActionListQuery> {
    const statement = `query GetIncidentActionList($id: ID!) {
        getIncidentActionList(id: $id) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentActionListQuery>response.data.getIncidentActionList;
  }
  async ListIncidentActionLists(
    filter?: ModelIncidentActionListFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentActionListsQuery> {
    const statement = `query ListIncidentActionLists($filter: ModelIncidentActionListFilterInput, $limit: Int, $nextToken: String) {
        listIncidentActionLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            priority
            eventType
            disposition
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
            tasks {
              __typename
              items {
                __typename
                id
                incidentId
                incidentActionItemId
                order
                completed
                description
                modifiedBy
                monitoringCenterId
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentActionListsQuery>response.data.listIncidentActionLists;
  }
  async ActionListByIncident(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentActionListFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ActionListByIncidentQuery> {
    const statement = `query ActionListByIncident($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentActionListFilterInput, $limit: Int, $nextToken: String) {
        actionListByIncident(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            priority
            eventType
            disposition
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
            tasks {
              __typename
              items {
                __typename
                id
                incidentId
                incidentActionItemId
                order
                completed
                description
                modifiedBy
                monitoringCenterId
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ActionListByIncidentQuery>response.data.actionListByIncident;
  }
  async GetProcedureTask(id: string): Promise<GetProcedureTaskQuery> {
    const statement = `query GetProcedureTask($id: ID!) {
        getProcedureTask(id: $id) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProcedureTaskQuery>response.data.getProcedureTask;
  }
  async ListProcedureTasks(
    filter?: ModelProcedureTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProcedureTasksQuery> {
    const statement = `query ListProcedureTasks($filter: ModelProcedureTaskFilterInput, $limit: Int, $nextToken: String) {
        listProcedureTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            incidentActionItemId
            order
            completed
            description
            modifiedBy
            monitoringCenterId
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProcedureTasksQuery>response.data.listProcedureTasks;
  }
  async ProcedureTaskByIncident(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProcedureTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProcedureTaskByIncidentQuery> {
    const statement = `query ProcedureTaskByIncident($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelProcedureTaskFilterInput, $limit: Int, $nextToken: String) {
        procedureTaskByIncident(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            incidentActionItemId
            order
            completed
            description
            modifiedBy
            monitoringCenterId
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProcedureTaskByIncidentQuery>response.data.procedureTaskByIncident;
  }
  async ProcedureTaskByIncidentAction(
    incidentActionItemId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProcedureTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProcedureTaskByIncidentActionQuery> {
    const statement = `query ProcedureTaskByIncidentAction($incidentActionItemId: ID, $sortDirection: ModelSortDirection, $filter: ModelProcedureTaskFilterInput, $limit: Int, $nextToken: String) {
        procedureTaskByIncidentAction(
          incidentActionItemId: $incidentActionItemId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            incidentActionItemId
            order
            completed
            description
            modifiedBy
            monitoringCenterId
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentActionItemId) {
      gqlAPIServiceArguments.incidentActionItemId = incidentActionItemId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProcedureTaskByIncidentActionQuery>(
      response.data.procedureTaskByIncidentAction
    );
  }
  async GetIncidentBundleTime(id: string): Promise<GetIncidentBundleTimeQuery> {
    const statement = `query GetIncidentBundleTime($id: ID!) {
        getIncidentBundleTime(id: $id) {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentBundleTimeQuery>response.data.getIncidentBundleTime;
  }
  async ListIncidentBundleTimes(
    filter?: ModelIncidentBundleTimeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentBundleTimesQuery> {
    const statement = `query ListIncidentBundleTimes($filter: ModelIncidentBundleTimeFilterInput, $limit: Int, $nextToken: String) {
        listIncidentBundleTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            applyToAssigned
            bundleTime
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentBundleTimesQuery>response.data.listIncidentBundleTimes;
  }
  async BundleTimeByMC(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentBundleTimeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BundleTimeByMCQuery> {
    const statement = `query BundleTimeByMC($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentBundleTimeFilterInput, $limit: Int, $nextToken: String) {
        bundleTimeByMC(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            applyToAssigned
            bundleTime
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BundleTimeByMCQuery>response.data.bundleTimeByMC;
  }
  async GetCameraEvent(id: string): Promise<GetCameraEventQuery> {
    const statement = `query GetCameraEvent($id: ID!) {
        getCameraEvent(id: $id) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraEventQuery>response.data.getCameraEvent;
  }
  async ListCameraEvents(
    filter?: ModelCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraEventsQuery> {
    const statement = `query ListCameraEvents($filter: ModelCameraEventFilterInput, $limit: Int, $nextToken: String) {
        listCameraEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vmsEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            thumbnailFilename
            triggerType
            objectsFound
            integratorId
            monitoringCenterId
            incidentId
            incidentPriority
            cameraEventCameraId
            siteId
            customerId
            startTime
            endTime
            resolvedTime
            instantaneous
            cameraEventUrl
            cameraEventUrlPublic
            modifiedBy
            vmsUserId
            ackTimestamp
            createdAt
            vmsSourceType
            updatedAt
            secondaryEvents {
              __typename
              items {
                __typename
                id
                cameraEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                vmsSourceType
                ackTimestamp
                modifiedBy
                vmsUserId
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            camera {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraEventsQuery>response.data.listCameraEvents;
  }
  async CameraEventByMonitoringCenter(
    monitoringCenterId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraEventByMonitoringCenterQuery> {
    const statement = `query CameraEventByMonitoringCenter($monitoringCenterId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraEventFilterInput, $limit: Int, $nextToken: String) {
        cameraEventByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            thumbnailFilename
            triggerType
            objectsFound
            integratorId
            monitoringCenterId
            incidentId
            incidentPriority
            cameraEventCameraId
            siteId
            customerId
            startTime
            endTime
            resolvedTime
            instantaneous
            cameraEventUrl
            cameraEventUrlPublic
            modifiedBy
            vmsUserId
            ackTimestamp
            createdAt
            vmsSourceType
            updatedAt
            secondaryEvents {
              __typename
              items {
                __typename
                id
                cameraEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                vmsSourceType
                ackTimestamp
                modifiedBy
                vmsUserId
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            camera {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraEventByMonitoringCenterQuery>(
      response.data.cameraEventByMonitoringCenter
    );
  }
  async CameraEventByVmsEventId(
    vmsEventId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraEventByVmsEventIdQuery> {
    const statement = `query CameraEventByVmsEventId($vmsEventId: String, $sortDirection: ModelSortDirection, $filter: ModelCameraEventFilterInput, $limit: Int, $nextToken: String) {
        cameraEventByVmsEventId(
          vmsEventId: $vmsEventId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            thumbnailFilename
            triggerType
            objectsFound
            integratorId
            monitoringCenterId
            incidentId
            incidentPriority
            cameraEventCameraId
            siteId
            customerId
            startTime
            endTime
            resolvedTime
            instantaneous
            cameraEventUrl
            cameraEventUrlPublic
            modifiedBy
            vmsUserId
            ackTimestamp
            createdAt
            vmsSourceType
            updatedAt
            secondaryEvents {
              __typename
              items {
                __typename
                id
                cameraEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                vmsSourceType
                ackTimestamp
                modifiedBy
                vmsUserId
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            camera {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vmsEventId) {
      gqlAPIServiceArguments.vmsEventId = vmsEventId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraEventByVmsEventIdQuery>response.data.cameraEventByVmsEventId;
  }
  async CameraEventByIncidentId(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraEventByIncidentIdQuery> {
    const statement = `query CameraEventByIncidentId($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraEventFilterInput, $limit: Int, $nextToken: String) {
        cameraEventByIncidentId(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            thumbnailFilename
            triggerType
            objectsFound
            integratorId
            monitoringCenterId
            incidentId
            incidentPriority
            cameraEventCameraId
            siteId
            customerId
            startTime
            endTime
            resolvedTime
            instantaneous
            cameraEventUrl
            cameraEventUrlPublic
            modifiedBy
            vmsUserId
            ackTimestamp
            createdAt
            vmsSourceType
            updatedAt
            secondaryEvents {
              __typename
              items {
                __typename
                id
                cameraEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                vmsSourceType
                ackTimestamp
                modifiedBy
                vmsUserId
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            camera {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraEventByIncidentIdQuery>response.data.cameraEventByIncidentId;
  }
  async CameraEventBySiteId(
    siteId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraEventBySiteIdQuery> {
    const statement = `query CameraEventBySiteId($siteId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraEventFilterInput, $limit: Int, $nextToken: String) {
        cameraEventBySiteId(
          siteId: $siteId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            thumbnailFilename
            triggerType
            objectsFound
            integratorId
            monitoringCenterId
            incidentId
            incidentPriority
            cameraEventCameraId
            siteId
            customerId
            startTime
            endTime
            resolvedTime
            instantaneous
            cameraEventUrl
            cameraEventUrlPublic
            modifiedBy
            vmsUserId
            ackTimestamp
            createdAt
            vmsSourceType
            updatedAt
            secondaryEvents {
              __typename
              items {
                __typename
                id
                cameraEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                vmsSourceType
                ackTimestamp
                modifiedBy
                vmsUserId
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            camera {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraEventBySiteIdQuery>response.data.cameraEventBySiteId;
  }
  async CameraEventByEventTypeAndCreatedAt(
    eventType?: EventType,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraEventByEventTypeAndCreatedAtQuery> {
    const statement = `query CameraEventByEventTypeAndCreatedAt($eventType: EventType, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraEventFilterInput, $limit: Int, $nextToken: String) {
        cameraEventByEventTypeAndCreatedAt(
          eventType: $eventType
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsEventId
            vmsNotes
            vmsNotesTranslated
            vmsNotesSanitized
            disposition
            eventType
            thumbnailFilename
            triggerType
            objectsFound
            integratorId
            monitoringCenterId
            incidentId
            incidentPriority
            cameraEventCameraId
            siteId
            customerId
            startTime
            endTime
            resolvedTime
            instantaneous
            cameraEventUrl
            cameraEventUrlPublic
            modifiedBy
            vmsUserId
            ackTimestamp
            createdAt
            vmsSourceType
            updatedAt
            secondaryEvents {
              __typename
              items {
                __typename
                id
                cameraEventId
                vmsNotes
                vmsNotesTranslated
                vmsNotesSanitized
                disposition
                eventType
                vmsSourceType
                ackTimestamp
                modifiedBy
                vmsUserId
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            camera {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (eventType) {
      gqlAPIServiceArguments.eventType = eventType;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraEventByEventTypeAndCreatedAtQuery>(
      response.data.cameraEventByEventTypeAndCreatedAt
    );
  }
  async GetCustomer(id: string): Promise<GetCustomerQuery> {
    const statement = `query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerQuery>response.data.getCustomer;
  }
  async ListCustomers(
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomersQuery> {
    const statement = `query ListCustomers($filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersQuery>response.data.listCustomers;
  }
  async CustomerByIntegrator(
    integratorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerByIntegratorQuery> {
    const statement = `query CustomerByIntegrator($integratorId: String, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customerByIntegrator(
          integratorId: $integratorId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerByIntegratorQuery>response.data.customerByIntegrator;
  }
  async CustomerByMonitoringCenter(
    monitoringCenterId?: string,
    sortName?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerByMonitoringCenterQuery> {
    const statement = `query CustomerByMonitoringCenter($monitoringCenterId: ID, $sortName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customerByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortName: $sortName
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortName) {
      gqlAPIServiceArguments.sortName = sortName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerByMonitoringCenterQuery>(
      response.data.customerByMonitoringCenter
    );
  }
  async CustomerByVmsCustomerId(
    vmsCustomerId?: number,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerByVmsCustomerIdQuery> {
    const statement = `query CustomerByVmsCustomerId($vmsCustomerId: Int, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customerByVmsCustomerId(
          vmsCustomerId: $vmsCustomerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vmsCustomerId) {
      gqlAPIServiceArguments.vmsCustomerId = vmsCustomerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerByVmsCustomerIdQuery>response.data.customerByVmsCustomerId;
  }
  async CustomerByInvoicePrefix(
    invoicePrefix?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerByInvoicePrefixQuery> {
    const statement = `query CustomerByInvoicePrefix($invoicePrefix: String, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customerByInvoicePrefix(
          invoicePrefix: $invoicePrefix
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (invoicePrefix) {
      gqlAPIServiceArguments.invoicePrefix = invoicePrefix;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerByInvoicePrefixQuery>response.data.customerByInvoicePrefix;
  }
  async CustomerBySoftphone(
    softphoneNumber?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomerBySoftphoneQuery> {
    const statement = `query CustomerBySoftphone($softphoneNumber: String, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customerBySoftphone(
          softphoneNumber: $softphoneNumber
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (softphoneNumber) {
      gqlAPIServiceArguments.softphoneNumber = softphoneNumber;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomerBySoftphoneQuery>response.data.customerBySoftphone;
  }
  async CustomersByStatusAndCreatedAt(
    status?: DataEntryStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomersByStatusAndCreatedAtQuery> {
    const statement = `query CustomersByStatusAndCreatedAt($status: DataEntryStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customersByStatusAndCreatedAt(
          status: $status
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            status
            integratorId
            monitoringCenterId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            vmsCustomerId
            vmsMCWebhookId
            vmsMonitoringCenterRoleId
            archived
            modifiedBy
            slaL1
            slaL2
            slaL3
            slaL4
            sortName
            softphoneEnabled
            softphoneNumber
            callbackNumber
            callbackNumberExt
            zendeskWidgetScript
            hubspotId
            hasLogo
            mobileLogo
            invoicePrefix
            invoiceNumber
            invoiceFooter
            errorEmail
            vmsUrl
            vmsAlarmUrl
            vmsAdminUrl
            orgDomainUrl
            fromEmail
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            boldBIIntegration {
              __typename
              id
              siteUsername
              sitePassword
              siteUrl
              siteDefaultDashboardId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            rapidSOSIntegration {
              __typename
              id
              clientId
              clientSecret
              webhookEnabled
              agencyId
              webhookId
              customerId
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            contact {
              __typename
              id
              firstName
              lastName
              name
              phone
              ext
              email
              monitoringCenterId
              integratorId
              modifiedBy
              customerCanShareSecurables
              customerCanLoginAdminPortal
              createdAt
              updatedAt
            }
            sites {
              __typename
              items {
                __typename
                id
                name
                status
                customerId
                integratorId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                externalCriticalNotes
                externalCriticalNotesTranslated
                externalCriticalNotesSanitized
                monitoringCenterId
                archived
                modifiedBy
                timezone
                vmsSharedMonitoringRoleId
                monitoringStatus
                maintainCamerasOnMonitoringRole
                armingRulesConfig
                monitoringState
                armedState
                alarmState
                supervisionEnabled
                triggersEnabled
                muteAfterInteractionCount
                type
                alarmsCentralStation
                alarmsCentralStationPhone
                alarmsCentralStationPhoneExt
                alarmsVerbalPasscode
                alarmsCSID
                alarmsAR
                alarmsNotes
                alarmsNotesTranslated
                alarmsNotesSanitized
                alarmsPermitNum
                alarmsPermitExpires
                autoSendToTrackTik
                hasSiteMap
                condensedAddress
                createdAt
                allowCustomerViewPendingUntil
                updatedAt
                address {
                  __typename
                  id
                  lineOne
                  lineTwo
                  city
                  state
                  zipCode
                  country
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                integrations {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    integratorId
                    monitoringCenterId
                    type
                    subType
                    subTypeList
                    agency
                    phone
                    phoneExt
                    email
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                    trackTickDetails {
                      __typename
                      id
                      siteIntegrationId
                      siteId
                      monitoringCenterId
                      apiUrl
                      apiUsername
                      apiPassword
                      accountId
                      token
                      refreshToken
                      tokenExpiry
                      refreshTokenExpiry
                      autoSend
                      autoSendErrorEmail
                      configured
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                contacts {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    name
                    type
                    verbalPasscode
                    email
                    priority
                    monitoringCenterId
                    integratorId
                    archived
                    notes
                    notesTranslated
                    notesSanitized
                    modifiedBy
                    createdAt
                    updatedAt
                    phones {
                      __typename
                      nextToken
                    }
                  }
                  nextToken
                }
                cameras {
                  __typename
                  items {
                    __typename
                    id
                    name
                    deviceType
                    status
                    vmsCameraName
                    description
                    descriptionTranslated
                    descriptionSanitized
                    vmsDescription
                    siteId
                    integratorId
                    monitoringCenterId
                    customerId
                    criticalNotes
                    criticalNotesTranslated
                    criticalNotesSanitized
                    talkdownInstructions {
                      __typename
                      talkdownType
                      note
                      noteTranslated
                      noteSanitized
                      phone
                      url
                    }
                    latitude
                    longitude
                    vmsCameraId
                    vmsCustomerId
                    testingExpiry
                    armedState
                    snoozeReason
                    snoozeReasonTranslated
                    snoozeReasonSanitized
                    snoozeExpiry
                    archived
                    zoneNumber
                    zoneDescription
                    zoneDescriptionTranslated
                    zoneDescriptionSanitized
                    modifiedBy
                    isOnvif
                    videoStreamState
                    deviceUrlOnline
                    vmsConnect
                    isP2P
                    isAnalyticsEnabled
                    isMotionEnabled
                    testEventReceived
                    healthCheckEnabled
                    healthCheckSLA
                    healthCheckStatus
                    ignoreStatusChecks
                    statusChecksProperties
                    statusLastVerified
                    archiveDurationHours
                    accessUrlsId
                    credentialsId
                    deviceBrand
                    deviceModel
                    vmsPlanId
                    phpRegistrationCode
                    serialNumber
                    p2pState
                    lastPowerCycle
                    baselineThumbnailFilename
                    currentThumbnailFilename
                    sceneChangeDetectionEnabled
                    passingSceneDetection
                    hubspotId
                    immixMonitoring
                    hasMic
                    hasSpeaker
                    audioGroups
                    createdAt
                    updatedAt
                    qualitySettings {
                      __typename
                      id
                      width
                      height
                      videoBitRate
                      frameRate
                      monitoringCenterId
                      cameraId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
                sops {
                  __typename
                  items {
                    __typename
                    id
                    siteId
                    eventType
                    eventSOPs
                    monitoringCenterId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomersByStatusAndCreatedAtQuery>(
      response.data.customersByStatusAndCreatedAt
    );
  }
  async GetVMSDetails(id: string): Promise<GetVMSDetailsQuery> {
    const statement = `query GetVMSDetails($id: ID!) {
        getVMSDetails(id: $id) {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVMSDetailsQuery>response.data.getVMSDetails;
  }
  async ListVMSDetailss(
    filter?: ModelVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVMSDetailssQuery> {
    const statement = `query ListVMSDetailss($filter: ModelVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        listVMSDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            apiKey
            username
            password
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVMSDetailssQuery>response.data.listVMSDetailss;
  }
  async VmsDetailsByIntegrator(
    integratorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VmsDetailsByIntegratorQuery> {
    const statement = `query VmsDetailsByIntegrator($integratorId: ID, $sortDirection: ModelSortDirection, $filter: ModelVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        vmsDetailsByIntegrator(
          integratorId: $integratorId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            apiKey
            username
            password
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VmsDetailsByIntegratorQuery>response.data.vmsDetailsByIntegrator;
  }
  async VsmDetailsByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VsmDetailsByMonitoringCenterQuery> {
    const statement = `query VsmDetailsByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        vsmDetailsByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            apiKey
            username
            password
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VsmDetailsByMonitoringCenterQuery>(
      response.data.vsmDetailsByMonitoringCenter
    );
  }
  async GetDashboardDetail(id: string): Promise<GetDashboardDetailQuery> {
    const statement = `query GetDashboardDetail($id: ID!) {
        getDashboardDetail(id: $id) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDashboardDetailQuery>response.data.getDashboardDetail;
  }
  async ListDashboardDetails(
    filter?: ModelDashboardDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDashboardDetailsQuery> {
    const statement = `query ListDashboardDetails($filter: ModelDashboardDetailFilterInput, $limit: Int, $nextToken: String) {
        listDashboardDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            awsId
            monitoringCenterId
            allowedGroups
            integratorId
            username
            archived
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDashboardDetailsQuery>response.data.listDashboardDetails;
  }
  async DashboardDetailsByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDashboardDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DashboardDetailsByMonitoringCenterQuery> {
    const statement = `query DashboardDetailsByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelDashboardDetailFilterInput, $limit: Int, $nextToken: String) {
        dashboardDetailsByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            awsId
            monitoringCenterId
            allowedGroups
            integratorId
            username
            archived
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DashboardDetailsByMonitoringCenterQuery>(
      response.data.dashboardDetailsByMonitoringCenter
    );
  }
  async GetBoldBIDetails(id: string): Promise<GetBoldBIDetailsQuery> {
    const statement = `query GetBoldBIDetails($id: ID!) {
        getBoldBIDetails(id: $id) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBoldBIDetailsQuery>response.data.getBoldBIDetails;
  }
  async ListBoldBIDetailss(
    filter?: ModelBoldBIDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBoldBIDetailssQuery> {
    const statement = `query ListBoldBIDetailss($filter: ModelBoldBIDetailsFilterInput, $limit: Int, $nextToken: String) {
        listBoldBIDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBoldBIDetailssQuery>response.data.listBoldBIDetailss;
  }
  async BoldBIByCustomer(
    customerId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelBoldBIDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BoldBIByCustomerQuery> {
    const statement = `query BoldBIByCustomer($customerId: String, $sortDirection: ModelSortDirection, $filter: ModelBoldBIDetailsFilterInput, $limit: Int, $nextToken: String) {
        boldBIByCustomer(
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BoldBIByCustomerQuery>response.data.boldBIByCustomer;
  }
  async GetRapidSOSDetails(id: string): Promise<GetRapidSOSDetailsQuery> {
    const statement = `query GetRapidSOSDetails($id: ID!) {
        getRapidSOSDetails(id: $id) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSDetailsQuery>response.data.getRapidSOSDetails;
  }
  async ListRapidSOSDetailss(
    filter?: ModelRapidSOSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSDetailssQuery> {
    const statement = `query ListRapidSOSDetailss($filter: ModelRapidSOSDetailsFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSDetailssQuery>response.data.listRapidSOSDetailss;
  }
  async RapidSOSByCustomer(
    customerId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidSOSByCustomerQuery> {
    const statement = `query RapidSOSByCustomer($customerId: String, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSDetailsFilterInput, $limit: Int, $nextToken: String) {
        rapidSOSByCustomer(
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSByCustomerQuery>response.data.rapidSOSByCustomer;
  }
  async GetAddress(id: string): Promise<GetAddressQuery> {
    const statement = `query GetAddress($id: ID!) {
        getAddress(id: $id) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAddressQuery>response.data.getAddress;
  }
  async ListAddresss(
    filter?: ModelAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAddresssQuery> {
    const statement = `query ListAddresss($filter: ModelAddressFilterInput, $limit: Int, $nextToken: String) {
        listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAddresssQuery>response.data.listAddresss;
  }
  async AddressByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AddressByMonitoringCenterQuery> {
    const statement = `query AddressByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelAddressFilterInput, $limit: Int, $nextToken: String) {
        addressByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddressByMonitoringCenterQuery>(
      response.data.addressByMonitoringCenter
    );
  }
  async GetContact(id: string): Promise<GetContactQuery> {
    const statement = `query GetContact($id: ID!) {
        getContact(id: $id) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContactQuery>response.data.getContact;
  }
  async ListContacts(
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContactsQuery> {
    const statement = `query ListContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContactsQuery>response.data.listContacts;
  }
  async ContactByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ContactByMonitoringCenterQuery> {
    const statement = `query ContactByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
        contactByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ContactByMonitoringCenterQuery>(
      response.data.contactByMonitoringCenter
    );
  }
  async GetPendingSite(id: string): Promise<GetPendingSiteQuery> {
    const statement = `query GetPendingSite($id: ID!) {
        getPendingSite(id: $id) {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPendingSiteQuery>response.data.getPendingSite;
  }
  async ListPendingSites(
    filter?: ModelPendingSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPendingSitesQuery> {
    const statement = `query ListPendingSites($filter: ModelPendingSiteFilterInput, $limit: Int, $nextToken: String) {
        listPendingSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            address {
              __typename
              lineOne
              lineTwo
              city
              state
              zipCode
              country
            }
            contacts {
              __typename
              firstName
              lastName
              phone
              email
              types
              verbalPasscode
            }
            status
            notes
            primaryEmail
            customerId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPendingSitesQuery>response.data.listPendingSites;
  }
  async PendingSiteByEmailStatus(
    primaryEmail?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelPendingSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PendingSiteByEmailStatusQuery> {
    const statement = `query PendingSiteByEmailStatus($primaryEmail: String, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelPendingSiteFilterInput, $limit: Int, $nextToken: String) {
        pendingSiteByEmailStatus(
          primaryEmail: $primaryEmail
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            address {
              __typename
              lineOne
              lineTwo
              city
              state
              zipCode
              country
            }
            contacts {
              __typename
              firstName
              lastName
              phone
              email
              types
              verbalPasscode
            }
            status
            notes
            primaryEmail
            customerId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (primaryEmail) {
      gqlAPIServiceArguments.primaryEmail = primaryEmail;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PendingSiteByEmailStatusQuery>(
      response.data.pendingSiteByEmailStatus
    );
  }
  async PendingSiteByEmailCustomer(
    primaryEmail?: string,
    customerId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelPendingSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PendingSiteByEmailCustomerQuery> {
    const statement = `query PendingSiteByEmailCustomer($primaryEmail: String, $customerId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelPendingSiteFilterInput, $limit: Int, $nextToken: String) {
        pendingSiteByEmailCustomer(
          primaryEmail: $primaryEmail
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            address {
              __typename
              lineOne
              lineTwo
              city
              state
              zipCode
              country
            }
            contacts {
              __typename
              firstName
              lastName
              phone
              email
              types
              verbalPasscode
            }
            status
            notes
            primaryEmail
            customerId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (primaryEmail) {
      gqlAPIServiceArguments.primaryEmail = primaryEmail;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PendingSiteByEmailCustomerQuery>(
      response.data.pendingSiteByEmailCustomer
    );
  }
  async GetSite(id: string): Promise<GetSiteQuery> {
    const statement = `query GetSite($id: ID!) {
        getSite(id: $id) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteQuery>response.data.getSite;
  }
  async ListSites(
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSitesQuery> {
    const statement = `query ListSites($filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSitesQuery>response.data.listSites;
  }
  async SiteByCustomer(
    customerId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SiteByCustomerQuery> {
    const statement = `query SiteByCustomer($customerId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        siteByCustomer(
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SiteByCustomerQuery>response.data.siteByCustomer;
  }
  async SiteByIntegrator(
    integratorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SiteByIntegratorQuery> {
    const statement = `query SiteByIntegrator($integratorId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        siteByIntegrator(
          integratorId: $integratorId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SiteByIntegratorQuery>response.data.siteByIntegrator;
  }
  async SiteByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SiteByMonitoringCenterQuery> {
    const statement = `query SiteByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        siteByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SiteByMonitoringCenterQuery>response.data.siteByMonitoringCenter;
  }
  async SitesByStatusAndCreatedAt(
    status?: DataEntryStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SitesByStatusAndCreatedAtQuery> {
    const statement = `query SitesByStatusAndCreatedAt($status: DataEntryStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSiteFilterInput, $limit: Int, $nextToken: String) {
        sitesByStatusAndCreatedAt(
          status: $status
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SitesByStatusAndCreatedAtQuery>(
      response.data.sitesByStatusAndCreatedAt
    );
  }
  async GetSiteSupvisdSetting(id: string): Promise<GetSiteSupvisdSettingQuery> {
    const statement = `query GetSiteSupvisdSetting($id: ID!) {
        getSiteSupvisdSetting(id: $id) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteSupvisdSettingQuery>response.data.getSiteSupvisdSetting;
  }
  async ListSiteSupvisdSettings(
    filter?: ModelSiteSupvisdSettingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSiteSupvisdSettingsQuery> {
    const statement = `query ListSiteSupvisdSettings($filter: ModelSiteSupvisdSettingFilterInput, $limit: Int, $nextToken: String) {
        listSiteSupvisdSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
            rules {
              __typename
              items {
                __typename
                id
                cameraId
                muteSitePersonRule
                siteId
                integratorId
                monitoringCenterId
                siteSupvisdSettingId
                modifiedBy
                archived
                ruleType
                cameraEventType
                disposition
                confidence
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSiteSupvisdSettingsQuery>response.data.listSiteSupvisdSettings;
  }
  async SupvisdSettingBySiteId(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteSupvisdSettingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SupvisdSettingBySiteIdQuery> {
    const statement = `query SupvisdSettingBySiteId($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteSupvisdSettingFilterInput, $limit: Int, $nextToken: String) {
        supvisdSettingBySiteId(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
            rules {
              __typename
              items {
                __typename
                id
                cameraId
                muteSitePersonRule
                siteId
                integratorId
                monitoringCenterId
                siteSupvisdSettingId
                modifiedBy
                archived
                ruleType
                cameraEventType
                disposition
                confidence
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SupvisdSettingBySiteIdQuery>response.data.supvisdSettingBySiteId;
  }
  async GetSupvisdEventRule(id: string): Promise<GetSupvisdEventRuleQuery> {
    const statement = `query GetSupvisdEventRule($id: ID!) {
        getSupvisdEventRule(id: $id) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSupvisdEventRuleQuery>response.data.getSupvisdEventRule;
  }
  async ListSupvisdEventRules(
    filter?: ModelSupvisdEventRuleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSupvisdEventRulesQuery> {
    const statement = `query ListSupvisdEventRules($filter: ModelSupvisdEventRuleFilterInput, $limit: Int, $nextToken: String) {
        listSupvisdEventRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cameraId
            muteSitePersonRule
            siteId
            integratorId
            monitoringCenterId
            siteSupvisdSettingId
            modifiedBy
            archived
            ruleType
            cameraEventType
            disposition
            confidence
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSupvisdEventRulesQuery>response.data.listSupvisdEventRules;
  }
  async RulesBySiteSupvisdSetting(
    siteSupvisdSettingId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSupvisdEventRuleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RulesBySiteSupvisdSettingQuery> {
    const statement = `query RulesBySiteSupvisdSetting($siteSupvisdSettingId: ID, $sortDirection: ModelSortDirection, $filter: ModelSupvisdEventRuleFilterInput, $limit: Int, $nextToken: String) {
        rulesBySiteSupvisdSetting(
          siteSupvisdSettingId: $siteSupvisdSettingId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            muteSitePersonRule
            siteId
            integratorId
            monitoringCenterId
            siteSupvisdSettingId
            modifiedBy
            archived
            ruleType
            cameraEventType
            disposition
            confidence
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteSupvisdSettingId) {
      gqlAPIServiceArguments.siteSupvisdSettingId = siteSupvisdSettingId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RulesBySiteSupvisdSettingQuery>(
      response.data.rulesBySiteSupvisdSetting
    );
  }
  async GetSiteDeviceStatusConfig(
    id: string
  ): Promise<GetSiteDeviceStatusConfigQuery> {
    const statement = `query GetSiteDeviceStatusConfig($id: ID!) {
        getSiteDeviceStatusConfig(id: $id) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteDeviceStatusConfigQuery>(
      response.data.getSiteDeviceStatusConfig
    );
  }
  async ListSiteDeviceStatusConfigs(
    filter?: ModelSiteDeviceStatusConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSiteDeviceStatusConfigsQuery> {
    const statement = `query ListSiteDeviceStatusConfigs($filter: ModelSiteDeviceStatusConfigFilterInput, $limit: Int, $nextToken: String) {
        listSiteDeviceStatusConfigs(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            modifiedBy
            archived
            deviceStatusEnabled
            statusFrequency
            statusVerifiedTolerance
            statusProperties
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSiteDeviceStatusConfigsQuery>(
      response.data.listSiteDeviceStatusConfigs
    );
  }
  async ConfigBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteDeviceStatusConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ConfigBySiteQuery> {
    const statement = `query ConfigBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteDeviceStatusConfigFilterInput, $limit: Int, $nextToken: String) {
        configBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            modifiedBy
            archived
            deviceStatusEnabled
            statusFrequency
            statusVerifiedTolerance
            statusProperties
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfigBySiteQuery>response.data.configBySite;
  }
  async GetTrigger(id: string): Promise<GetTriggerQuery> {
    const statement = `query GetTrigger($id: ID!) {
        getTrigger(id: $id) {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTriggerQuery>response.data.getTrigger;
  }
  async ListTriggers(
    filter?: ModelTriggerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTriggersQuery> {
    const statement = `query ListTriggers($filter: ModelTriggerFilterInput, $limit: Int, $nextToken: String) {
        listTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            customerId
            monitoringCenterId
            type
            filters {
              __typename
              filter
              frequencyLimit
              lastTriggered
              actions {
                __typename
                action
                actionSettings
                frequencyLimit
                lastTriggered
              }
            }
            modifiedBy
            archived
            frequencyLimit
            lastTriggered
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTriggersQuery>response.data.listTriggers;
  }
  async TriggersBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTriggerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TriggersBySiteQuery> {
    const statement = `query TriggersBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelTriggerFilterInput, $limit: Int, $nextToken: String) {
        triggersBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            customerId
            monitoringCenterId
            type
            filters {
              __typename
              filter
              frequencyLimit
              lastTriggered
              actions {
                __typename
                action
                actionSettings
                frequencyLimit
                lastTriggered
              }
            }
            modifiedBy
            archived
            frequencyLimit
            lastTriggered
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TriggersBySiteQuery>response.data.triggersBySite;
  }
  async GetSiteIntegration(id: string): Promise<GetSiteIntegrationQuery> {
    const statement = `query GetSiteIntegration($id: ID!) {
        getSiteIntegration(id: $id) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteIntegrationQuery>response.data.getSiteIntegration;
  }
  async ListSiteIntegrations(
    filter?: ModelSiteIntegrationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSiteIntegrationsQuery> {
    const statement = `query ListSiteIntegrations($filter: ModelSiteIntegrationFilterInput, $limit: Int, $nextToken: String) {
        listSiteIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            type
            subType
            subTypeList
            agency
            phone
            phoneExt
            email
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            archived
            createdAt
            updatedAt
            trackTickDetails {
              __typename
              id
              siteIntegrationId
              siteId
              monitoringCenterId
              apiUrl
              apiUsername
              apiPassword
              accountId
              token
              refreshToken
              tokenExpiry
              refreshTokenExpiry
              autoSend
              autoSendErrorEmail
              configured
              archived
              modifiedBy
              createdAt
              updatedAt
              taskList {
                __typename
                items {
                  __typename
                  id
                  trackTikDetailsId
                  siteId
                  monitoringCenterId
                  eventType
                  trackTikName
                  trackTikId
                  trackTikReportTemplateId
                  archived
                  modifedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSiteIntegrationsQuery>response.data.listSiteIntegrations;
  }
  async IntegrationsBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteIntegrationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IntegrationsBySiteQuery> {
    const statement = `query IntegrationsBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteIntegrationFilterInput, $limit: Int, $nextToken: String) {
        integrationsBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            type
            subType
            subTypeList
            agency
            phone
            phoneExt
            email
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            archived
            createdAt
            updatedAt
            trackTickDetails {
              __typename
              id
              siteIntegrationId
              siteId
              monitoringCenterId
              apiUrl
              apiUsername
              apiPassword
              accountId
              token
              refreshToken
              tokenExpiry
              refreshTokenExpiry
              autoSend
              autoSendErrorEmail
              configured
              archived
              modifiedBy
              createdAt
              updatedAt
              taskList {
                __typename
                items {
                  __typename
                  id
                  trackTikDetailsId
                  siteId
                  monitoringCenterId
                  eventType
                  trackTikName
                  trackTikId
                  trackTikReportTemplateId
                  archived
                  modifedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IntegrationsBySiteQuery>response.data.integrationsBySite;
  }
  async IntegrationsByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteIntegrationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IntegrationsByMonitoringCenterQuery> {
    const statement = `query IntegrationsByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteIntegrationFilterInput, $limit: Int, $nextToken: String) {
        integrationsByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            type
            subType
            subTypeList
            agency
            phone
            phoneExt
            email
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            archived
            createdAt
            updatedAt
            trackTickDetails {
              __typename
              id
              siteIntegrationId
              siteId
              monitoringCenterId
              apiUrl
              apiUsername
              apiPassword
              accountId
              token
              refreshToken
              tokenExpiry
              refreshTokenExpiry
              autoSend
              autoSendErrorEmail
              configured
              archived
              modifiedBy
              createdAt
              updatedAt
              taskList {
                __typename
                items {
                  __typename
                  id
                  trackTikDetailsId
                  siteId
                  monitoringCenterId
                  eventType
                  trackTikName
                  trackTikId
                  trackTikReportTemplateId
                  archived
                  modifedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IntegrationsByMonitoringCenterQuery>(
      response.data.integrationsByMonitoringCenter
    );
  }
  async IntegrationsByTypeAndSubType(
    type?: IntegrationType,
    subType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteIntegrationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IntegrationsByTypeAndSubTypeQuery> {
    const statement = `query IntegrationsByTypeAndSubType($type: IntegrationType, $subType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSiteIntegrationFilterInput, $limit: Int, $nextToken: String) {
        integrationsByTypeAndSubType(
          type: $type
          subType: $subType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            integratorId
            monitoringCenterId
            type
            subType
            subTypeList
            agency
            phone
            phoneExt
            email
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            archived
            createdAt
            updatedAt
            trackTickDetails {
              __typename
              id
              siteIntegrationId
              siteId
              monitoringCenterId
              apiUrl
              apiUsername
              apiPassword
              accountId
              token
              refreshToken
              tokenExpiry
              refreshTokenExpiry
              autoSend
              autoSendErrorEmail
              configured
              archived
              modifiedBy
              createdAt
              updatedAt
              taskList {
                __typename
                items {
                  __typename
                  id
                  trackTikDetailsId
                  siteId
                  monitoringCenterId
                  eventType
                  trackTikName
                  trackTikId
                  trackTikReportTemplateId
                  archived
                  modifedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (subType) {
      gqlAPIServiceArguments.subType = subType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IntegrationsByTypeAndSubTypeQuery>(
      response.data.integrationsByTypeAndSubType
    );
  }
  async GetSiteVMSDetails(id: string): Promise<GetSiteVMSDetailsQuery> {
    const statement = `query GetSiteVMSDetails($id: ID!) {
        getSiteVMSDetails(id: $id) {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteVMSDetailsQuery>response.data.getSiteVMSDetails;
  }
  async ListSiteVMSDetailss(
    filter?: ModelSiteVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSiteVMSDetailssQuery> {
    const statement = `query ListSiteVMSDetailss($filter: ModelSiteVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        listSiteVMSDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            password
            apiKey
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            siteId
            siteUserId
            siteUserRoleId
            integratorId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSiteVMSDetailssQuery>response.data.listSiteVMSDetailss;
  }
  async SiteVMSDetailsBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SiteVMSDetailsBySiteQuery> {
    const statement = `query SiteVMSDetailsBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        siteVMSDetailsBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            username
            password
            apiKey
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            siteId
            siteUserId
            siteUserRoleId
            integratorId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SiteVMSDetailsBySiteQuery>response.data.siteVMSDetailsBySite;
  }
  async GetTrackTikDetails(id: string): Promise<GetTrackTikDetailsQuery> {
    const statement = `query GetTrackTikDetails($id: ID!) {
        getTrackTikDetails(id: $id) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTrackTikDetailsQuery>response.data.getTrackTikDetails;
  }
  async ListTrackTikDetailss(
    filter?: ModelTrackTikDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrackTikDetailssQuery> {
    const statement = `query ListTrackTikDetailss($filter: ModelTrackTikDetailsFilterInput, $limit: Int, $nextToken: String) {
        listTrackTikDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTrackTikDetailssQuery>response.data.listTrackTikDetailss;
  }
  async TrackTikDetailsBySiteId(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTrackTikDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TrackTikDetailsBySiteIdQuery> {
    const statement = `query TrackTikDetailsBySiteId($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelTrackTikDetailsFilterInput, $limit: Int, $nextToken: String) {
        trackTikDetailsBySiteId(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrackTikDetailsBySiteIdQuery>response.data.trackTikDetailsBySiteId;
  }
  async GetTrackTikTask(id: string): Promise<GetTrackTikTaskQuery> {
    const statement = `query GetTrackTikTask($id: ID!) {
        getTrackTikTask(id: $id) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTrackTikTaskQuery>response.data.getTrackTikTask;
  }
  async ListTrackTikTasks(
    filter?: ModelTrackTikTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrackTikTasksQuery> {
    const statement = `query ListTrackTikTasks($filter: ModelTrackTikTaskFilterInput, $limit: Int, $nextToken: String) {
        listTrackTikTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            trackTikDetailsId
            siteId
            monitoringCenterId
            eventType
            trackTikName
            trackTikId
            trackTikReportTemplateId
            archived
            modifedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTrackTikTasksQuery>response.data.listTrackTikTasks;
  }
  async TaskByTrackTikDetailsId(
    trackTikDetailsId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTrackTikTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskByTrackTikDetailsIdQuery> {
    const statement = `query TaskByTrackTikDetailsId($trackTikDetailsId: ID, $sortDirection: ModelSortDirection, $filter: ModelTrackTikTaskFilterInput, $limit: Int, $nextToken: String) {
        taskByTrackTikDetailsId(
          trackTikDetailsId: $trackTikDetailsId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            trackTikDetailsId
            siteId
            monitoringCenterId
            eventType
            trackTikName
            trackTikId
            trackTikReportTemplateId
            archived
            modifedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (trackTikDetailsId) {
      gqlAPIServiceArguments.trackTikDetailsId = trackTikDetailsId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaskByTrackTikDetailsIdQuery>response.data.taskByTrackTikDetailsId;
  }
  async GetSiteEmergencyContact(
    id: string
  ): Promise<GetSiteEmergencyContactQuery> {
    const statement = `query GetSiteEmergencyContact($id: ID!) {
        getSiteEmergencyContact(id: $id) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteEmergencyContactQuery>response.data.getSiteEmergencyContact;
  }
  async ListSiteEmergencyContacts(
    filter?: ModelSiteEmergencyContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSiteEmergencyContactsQuery> {
    const statement = `query ListSiteEmergencyContacts($filter: ModelSiteEmergencyContactFilterInput, $limit: Int, $nextToken: String) {
        listSiteEmergencyContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            name
            type
            verbalPasscode
            email
            priority
            monitoringCenterId
            integratorId
            archived
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            createdAt
            updatedAt
            phones {
              __typename
              items {
                __typename
                id
                contactId
                type
                number
                ext
                monitoringCenterId
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSiteEmergencyContactsQuery>(
      response.data.listSiteEmergencyContacts
    );
  }
  async ContactsBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteEmergencyContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ContactsBySiteQuery> {
    const statement = `query ContactsBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteEmergencyContactFilterInput, $limit: Int, $nextToken: String) {
        contactsBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            name
            type
            verbalPasscode
            email
            priority
            monitoringCenterId
            integratorId
            archived
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            createdAt
            updatedAt
            phones {
              __typename
              items {
                __typename
                id
                contactId
                type
                number
                ext
                monitoringCenterId
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ContactsBySiteQuery>response.data.contactsBySite;
  }
  async SiteContactByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteEmergencyContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SiteContactByMonitoringCenterQuery> {
    const statement = `query SiteContactByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteEmergencyContactFilterInput, $limit: Int, $nextToken: String) {
        siteContactByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            name
            type
            verbalPasscode
            email
            priority
            monitoringCenterId
            integratorId
            archived
            notes
            notesTranslated
            notesSanitized
            modifiedBy
            createdAt
            updatedAt
            phones {
              __typename
              items {
                __typename
                id
                contactId
                type
                number
                ext
                monitoringCenterId
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SiteContactByMonitoringCenterQuery>(
      response.data.siteContactByMonitoringCenter
    );
  }
  async GetPhone(id: string): Promise<GetPhoneQuery> {
    const statement = `query GetPhone($id: ID!) {
        getPhone(id: $id) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPhoneQuery>response.data.getPhone;
  }
  async ListPhones(
    filter?: ModelPhoneFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPhonesQuery> {
    const statement = `query ListPhones($filter: ModelPhoneFilterInput, $limit: Int, $nextToken: String) {
        listPhones(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            contactId
            type
            number
            ext
            monitoringCenterId
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPhonesQuery>response.data.listPhones;
  }
  async PhonesByContact(
    contactId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPhoneFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PhonesByContactQuery> {
    const statement = `query PhonesByContact($contactId: ID, $sortDirection: ModelSortDirection, $filter: ModelPhoneFilterInput, $limit: Int, $nextToken: String) {
        phonesByContact(
          contactId: $contactId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            contactId
            type
            number
            ext
            monitoringCenterId
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (contactId) {
      gqlAPIServiceArguments.contactId = contactId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PhonesByContactQuery>response.data.phonesByContact;
  }
  async PhonesByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPhoneFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PhonesByMonitoringCenterQuery> {
    const statement = `query PhonesByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelPhoneFilterInput, $limit: Int, $nextToken: String) {
        phonesByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            contactId
            type
            number
            ext
            monitoringCenterId
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PhonesByMonitoringCenterQuery>(
      response.data.phonesByMonitoringCenter
    );
  }
  async PhonesByNumber(
    monitoringCenterId?: string,
    number?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelPhoneFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PhonesByNumberQuery> {
    const statement = `query PhonesByNumber($monitoringCenterId: ID, $number: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelPhoneFilterInput, $limit: Int, $nextToken: String) {
        phonesByNumber(
          monitoringCenterId: $monitoringCenterId
          number: $number
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            contactId
            type
            number
            ext
            monitoringCenterId
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (number) {
      gqlAPIServiceArguments.number = number;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PhonesByNumberQuery>response.data.phonesByNumber;
  }
  async GetCamera(id: string): Promise<GetCameraQuery> {
    const statement = `query GetCamera($id: ID!) {
        getCamera(id: $id) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraQuery>response.data.getCamera;
  }
  async ListCameras(
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCamerasQuery> {
    const statement = `query ListCameras($filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCamerasQuery>response.data.listCameras;
  }
  async CameraByIntegrator(
    integratorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraByIntegratorQuery> {
    const statement = `query CameraByIntegrator($integratorId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        cameraByIntegrator(
          integratorId: $integratorId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraByIntegratorQuery>response.data.cameraByIntegrator;
  }
  async CameraByCustomer(
    customerId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraByCustomerQuery> {
    const statement = `query CameraByCustomer($customerId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        cameraByCustomer(
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraByCustomerQuery>response.data.cameraByCustomer;
  }
  async CameraBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraBySiteQuery> {
    const statement = `query CameraBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        cameraBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraBySiteQuery>response.data.cameraBySite;
  }
  async CameraByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraByMonitoringCenterQuery> {
    const statement = `query CameraByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        cameraByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraByMonitoringCenterQuery>(
      response.data.cameraByMonitoringCenter
    );
  }
  async CameraByVmsCameraId(
    vmsCameraId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraByVmsCameraIdQuery> {
    const statement = `query CameraByVmsCameraId($vmsCameraId: String, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        cameraByVmsCameraId(
          vmsCameraId: $vmsCameraId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vmsCameraId) {
      gqlAPIServiceArguments.vmsCameraId = vmsCameraId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraByVmsCameraIdQuery>response.data.cameraByVmsCameraId;
  }
  async CameraByMCHealthStatus(
    monitoringCenterId?: string,
    healthCheckStatus?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraByMCHealthStatusQuery> {
    const statement = `query CameraByMCHealthStatus($monitoringCenterId: ID, $healthCheckStatus: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        cameraByMCHealthStatus(
          monitoringCenterId: $monitoringCenterId
          healthCheckStatus: $healthCheckStatus
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (healthCheckStatus) {
      gqlAPIServiceArguments.healthCheckStatus = healthCheckStatus;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraByMCHealthStatusQuery>response.data.cameraByMCHealthStatus;
  }
  async CamerasByHealthCheckStatusAndCreatedAt(
    healthCheckStatus?: HealthCheckStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CamerasByHealthCheckStatusAndCreatedAtQuery> {
    const statement = `query CamerasByHealthCheckStatusAndCreatedAt($healthCheckStatus: HealthCheckStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraFilterInput, $limit: Int, $nextToken: String) {
        camerasByHealthCheckStatusAndCreatedAt(
          healthCheckStatus: $healthCheckStatus
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (healthCheckStatus) {
      gqlAPIServiceArguments.healthCheckStatus = healthCheckStatus;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CamerasByHealthCheckStatusAndCreatedAtQuery>(
      response.data.camerasByHealthCheckStatusAndCreatedAt
    );
  }
  async GetObjectGroup(id: string): Promise<GetObjectGroupQuery> {
    const statement = `query GetObjectGroup($id: ID!) {
        getObjectGroup(id: $id) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetObjectGroupQuery>response.data.getObjectGroup;
  }
  async ListObjectGroups(
    filter?: ModelObjectGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListObjectGroupsQuery> {
    const statement = `query ListObjectGroups($filter: ModelObjectGroupFilterInput, $limit: Int, $nextToken: String) {
        listObjectGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            name
            nameTranslated
            nameSanitized
            groupType
            archived
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListObjectGroupsQuery>response.data.listObjectGroups;
  }
  async GroupsBySiteType(
    siteId?: string,
    groupType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelObjectGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GroupsBySiteTypeQuery> {
    const statement = `query GroupsBySiteType($siteId: ID, $groupType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelObjectGroupFilterInput, $limit: Int, $nextToken: String) {
        groupsBySiteType(
          siteId: $siteId
          groupType: $groupType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            name
            nameTranslated
            nameSanitized
            groupType
            archived
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (groupType) {
      gqlAPIServiceArguments.groupType = groupType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GroupsBySiteTypeQuery>response.data.groupsBySiteType;
  }
  async GetAudioCustomMessageType(
    id: string
  ): Promise<GetAudioCustomMessageTypeQuery> {
    const statement = `query GetAudioCustomMessageType($id: ID!) {
        getAudioCustomMessageType(id: $id) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAudioCustomMessageTypeQuery>(
      response.data.getAudioCustomMessageType
    );
  }
  async ListAudioCustomMessageTypes(
    filter?: ModelAudioCustomMessageTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAudioCustomMessageTypesQuery> {
    const statement = `query ListAudioCustomMessageTypes($filter: ModelAudioCustomMessageTypeFilterInput, $limit: Int, $nextToken: String) {
        listAudioCustomMessageTypes(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            name
            nameTranslated
            nameSanitized
            transcript
            transcriptTranslated
            transcriptSanitized
            note
            noteTranslated
            noteSanitized
            archived
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAudioCustomMessageTypesQuery>(
      response.data.listAudioCustomMessageTypes
    );
  }
  async AudioMessageTypesBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAudioCustomMessageTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AudioMessageTypesBySiteQuery> {
    const statement = `query AudioMessageTypesBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelAudioCustomMessageTypeFilterInput, $limit: Int, $nextToken: String) {
        audioMessageTypesBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            name
            nameTranslated
            nameSanitized
            transcript
            transcriptTranslated
            transcriptSanitized
            note
            noteTranslated
            noteSanitized
            archived
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AudioMessageTypesBySiteQuery>response.data.audioMessageTypesBySite;
  }
  async GetCameraQualitySettings(
    id: string
  ): Promise<GetCameraQualitySettingsQuery> {
    const statement = `query GetCameraQualitySettings($id: ID!) {
        getCameraQualitySettings(id: $id) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraQualitySettingsQuery>(
      response.data.getCameraQualitySettings
    );
  }
  async ListCameraQualitySettingss(
    filter?: ModelCameraQualitySettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraQualitySettingssQuery> {
    const statement = `query ListCameraQualitySettingss($filter: ModelCameraQualitySettingsFilterInput, $limit: Int, $nextToken: String) {
        listCameraQualitySettingss(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraQualitySettingssQuery>(
      response.data.listCameraQualitySettingss
    );
  }
  async GetCameraSceneDetectionSettings(
    id: string
  ): Promise<GetCameraSceneDetectionSettingsQuery> {
    const statement = `query GetCameraSceneDetectionSettings($id: ID!) {
        getCameraSceneDetectionSettings(id: $id) {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraSceneDetectionSettingsQuery>(
      response.data.getCameraSceneDetectionSettings
    );
  }
  async ListCameraSceneDetectionSettingss(
    filter?: ModelCameraSceneDetectionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraSceneDetectionSettingssQuery> {
    const statement = `query ListCameraSceneDetectionSettingss($filter: ModelCameraSceneDetectionSettingsFilterInput, $limit: Int, $nextToken: String) {
        listCameraSceneDetectionSettingss(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
            thresholds {
              __typename
              items {
                __typename
                id
                cameraId
                cameraSceneDetectionSettingsId
                monitoringCenterId
                modifiedBy
                archived
                objectName
                baselineValue
                thresholdValue
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraSceneDetectionSettingssQuery>(
      response.data.listCameraSceneDetectionSettingss
    );
  }
  async DetSettingsByCamera(
    cameraId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraSceneDetectionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DetSettingsByCameraQuery> {
    const statement = `query DetSettingsByCamera($cameraId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraSceneDetectionSettingsFilterInput, $limit: Int, $nextToken: String) {
        detSettingsByCamera(
          cameraId: $cameraId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
            thresholds {
              __typename
              items {
                __typename
                id
                cameraId
                cameraSceneDetectionSettingsId
                monitoringCenterId
                modifiedBy
                archived
                objectName
                baselineValue
                thresholdValue
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DetSettingsByCameraQuery>response.data.detSettingsByCamera;
  }
  async GetCameraSceneDetectionThreshold(
    id: string
  ): Promise<GetCameraSceneDetectionThresholdQuery> {
    const statement = `query GetCameraSceneDetectionThreshold($id: ID!) {
        getCameraSceneDetectionThreshold(id: $id) {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraSceneDetectionThresholdQuery>(
      response.data.getCameraSceneDetectionThreshold
    );
  }
  async ListCameraSceneDetectionThresholds(
    filter?: ModelCameraSceneDetectionThresholdFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraSceneDetectionThresholdsQuery> {
    const statement = `query ListCameraSceneDetectionThresholds($filter: ModelCameraSceneDetectionThresholdFilterInput, $limit: Int, $nextToken: String) {
        listCameraSceneDetectionThresholds(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            cameraSceneDetectionSettingsId
            monitoringCenterId
            modifiedBy
            archived
            objectName
            baselineValue
            thresholdValue
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraSceneDetectionThresholdsQuery>(
      response.data.listCameraSceneDetectionThresholds
    );
  }
  async DetThresholdByDetId(
    cameraSceneDetectionSettingsId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraSceneDetectionThresholdFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DetThresholdByDetIdQuery> {
    const statement = `query DetThresholdByDetId($cameraSceneDetectionSettingsId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraSceneDetectionThresholdFilterInput, $limit: Int, $nextToken: String) {
        detThresholdByDetId(
          cameraSceneDetectionSettingsId: $cameraSceneDetectionSettingsId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            cameraSceneDetectionSettingsId
            monitoringCenterId
            modifiedBy
            archived
            objectName
            baselineValue
            thresholdValue
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraSceneDetectionSettingsId) {
      gqlAPIServiceArguments.cameraSceneDetectionSettingsId = cameraSceneDetectionSettingsId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DetThresholdByDetIdQuery>response.data.detThresholdByDetId;
  }
  async GetCameraSceneDetectionResult(
    id: string
  ): Promise<GetCameraSceneDetectionResultQuery> {
    const statement = `query GetCameraSceneDetectionResult($id: ID!) {
        getCameraSceneDetectionResult(id: $id) {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraSceneDetectionResultQuery>(
      response.data.getCameraSceneDetectionResult
    );
  }
  async ListCameraSceneDetectionResults(
    filter?: ModelCameraSceneDetectionResultFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraSceneDetectionResultsQuery> {
    const statement = `query ListCameraSceneDetectionResults($filter: ModelCameraSceneDetectionResultFilterInput, $limit: Int, $nextToken: String) {
        listCameraSceneDetectionResults(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            passing
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
            items {
              __typename
              items {
                __typename
                id
                cameraSceneDetectionResultId
                cameraId
                monitoringCenterId
                modifiedBy
                objectName
                thresholdValue
                actualValue
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraSceneDetectionResultsQuery>(
      response.data.listCameraSceneDetectionResults
    );
  }
  async DetResultByCameraId(
    cameraId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraSceneDetectionResultFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DetResultByCameraIdQuery> {
    const statement = `query DetResultByCameraId($cameraId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraSceneDetectionResultFilterInput, $limit: Int, $nextToken: String) {
        detResultByCameraId(
          cameraId: $cameraId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            passing
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
            items {
              __typename
              items {
                __typename
                id
                cameraSceneDetectionResultId
                cameraId
                monitoringCenterId
                modifiedBy
                objectName
                thresholdValue
                actualValue
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DetResultByCameraIdQuery>response.data.detResultByCameraId;
  }
  async GetCameraSceneDetectionResultItem(
    id: string
  ): Promise<GetCameraSceneDetectionResultItemQuery> {
    const statement = `query GetCameraSceneDetectionResultItem($id: ID!) {
        getCameraSceneDetectionResultItem(id: $id) {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraSceneDetectionResultItemQuery>(
      response.data.getCameraSceneDetectionResultItem
    );
  }
  async ListCameraSceneDetectionResultItems(
    filter?: ModelCameraSceneDetectionResultItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraSceneDetectionResultItemsQuery> {
    const statement = `query ListCameraSceneDetectionResultItems($filter: ModelCameraSceneDetectionResultItemFilterInput, $limit: Int, $nextToken: String) {
        listCameraSceneDetectionResultItems(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraSceneDetectionResultId
            cameraId
            monitoringCenterId
            modifiedBy
            objectName
            thresholdValue
            actualValue
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraSceneDetectionResultItemsQuery>(
      response.data.listCameraSceneDetectionResultItems
    );
  }
  async ResultItemBySceneDetectionId(
    cameraSceneDetectionResultId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraSceneDetectionResultItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ResultItemBySceneDetectionIdQuery> {
    const statement = `query ResultItemBySceneDetectionId($cameraSceneDetectionResultId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraSceneDetectionResultItemFilterInput, $limit: Int, $nextToken: String) {
        resultItemBySceneDetectionId(
          cameraSceneDetectionResultId: $cameraSceneDetectionResultId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraSceneDetectionResultId
            cameraId
            monitoringCenterId
            modifiedBy
            objectName
            thresholdValue
            actualValue
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraSceneDetectionResultId) {
      gqlAPIServiceArguments.cameraSceneDetectionResultId = cameraSceneDetectionResultId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ResultItemBySceneDetectionIdQuery>(
      response.data.resultItemBySceneDetectionId
    );
  }
  async GetCameraCredentials(id: string): Promise<GetCameraCredentialsQuery> {
    const statement = `query GetCameraCredentials($id: ID!) {
        getCameraCredentials(id: $id) {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraCredentialsQuery>response.data.getCameraCredentials;
  }
  async ListCameraCredentialss(
    filter?: ModelCameraCredentialsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraCredentialssQuery> {
    const statement = `query ListCameraCredentialss($filter: ModelCameraCredentialsFilterInput, $limit: Int, $nextToken: String) {
        listCameraCredentialss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            password
            cameraId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraCredentialssQuery>response.data.listCameraCredentialss;
  }
  async GetCameraAccessUrls(id: string): Promise<GetCameraAccessUrlsQuery> {
    const statement = `query GetCameraAccessUrls($id: ID!) {
        getCameraAccessUrls(id: $id) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraAccessUrlsQuery>response.data.getCameraAccessUrls;
  }
  async ListCameraAccessUrlss(
    filter?: ModelCameraAccessUrlsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraAccessUrlssQuery> {
    const statement = `query ListCameraAccessUrlss($filter: ModelCameraAccessUrlsFilterInput, $limit: Int, $nextToken: String) {
        listCameraAccessUrlss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            dashStream
            hlsStream
            http
            rtmpStream
            rtsp
            snapShot
            webRtc
            cameraId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraAccessUrlssQuery>response.data.listCameraAccessUrlss;
  }
  async GetCameraImmixConfig(id: string): Promise<GetCameraImmixConfigQuery> {
    const statement = `query GetCameraImmixConfig($id: ID!) {
        getCameraImmixConfig(id: $id) {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraImmixConfigQuery>response.data.getCameraImmixConfig;
  }
  async ListCameraImmixConfigs(
    filter?: ModelCameraImmixConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraImmixConfigsQuery> {
    const statement = `query ListCameraImmixConfigs($filter: ModelCameraImmixConfigFilterInput, $limit: Int, $nextToken: String) {
        listCameraImmixConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cameraId
            vmsCameraId
            vmsWebhookId
            immixEmail
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraImmixConfigsQuery>response.data.listCameraImmixConfigs;
  }
  async ImmixConfigByCamera(
    cameraId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraImmixConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ImmixConfigByCameraQuery> {
    const statement = `query ImmixConfigByCamera($cameraId: ID, $sortDirection: ModelSortDirection, $filter: ModelCameraImmixConfigFilterInput, $limit: Int, $nextToken: String) {
        immixConfigByCamera(
          cameraId: $cameraId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            vmsCameraId
            vmsWebhookId
            immixEmail
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ImmixConfigByCameraQuery>response.data.immixConfigByCamera;
  }
  async ImmixConfigByVMSCamera(
    vmsCameraId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraImmixConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ImmixConfigByVMSCameraQuery> {
    const statement = `query ImmixConfigByVMSCamera($vmsCameraId: String, $sortDirection: ModelSortDirection, $filter: ModelCameraImmixConfigFilterInput, $limit: Int, $nextToken: String) {
        immixConfigByVMSCamera(
          vmsCameraId: $vmsCameraId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            vmsCameraId
            vmsWebhookId
            immixEmail
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vmsCameraId) {
      gqlAPIServiceArguments.vmsCameraId = vmsCameraId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ImmixConfigByVMSCameraQuery>response.data.immixConfigByVMSCamera;
  }
  async GetActivityLog(id: string): Promise<GetActivityLogQuery> {
    const statement = `query GetActivityLog($id: ID!) {
        getActivityLog(id: $id) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetActivityLogQuery>response.data.getActivityLog;
  }
  async ListActivityLogs(
    filter?: ModelActivityLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListActivityLogsQuery> {
    const statement = `query ListActivityLogs($filter: ModelActivityLogFilterInput, $limit: Int, $nextToken: String) {
        listActivityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            cameraId
            modifiedOn
            modifiedBy
            type
            subSystem
            event
            reference
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListActivityLogsQuery>response.data.listActivityLogs;
  }
  async ActivityLogByMonitoringCenter(
    monitoringCenterId?: string,
    modifiedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelActivityLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ActivityLogByMonitoringCenterQuery> {
    const statement = `query ActivityLogByMonitoringCenter($monitoringCenterId: ID, $modifiedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelActivityLogFilterInput, $limit: Int, $nextToken: String) {
        activityLogByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          modifiedOn: $modifiedOn
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            cameraId
            modifiedOn
            modifiedBy
            type
            subSystem
            event
            reference
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (modifiedOn) {
      gqlAPIServiceArguments.modifiedOn = modifiedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ActivityLogByMonitoringCenterQuery>(
      response.data.activityLogByMonitoringCenter
    );
  }
  async ActivityLogByIntegratorId(
    integratorId?: string,
    modifiedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelActivityLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ActivityLogByIntegratorIdQuery> {
    const statement = `query ActivityLogByIntegratorId($integratorId: ID, $modifiedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelActivityLogFilterInput, $limit: Int, $nextToken: String) {
        activityLogByIntegratorId(
          integratorId: $integratorId
          modifiedOn: $modifiedOn
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            cameraId
            modifiedOn
            modifiedBy
            type
            subSystem
            event
            reference
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (modifiedOn) {
      gqlAPIServiceArguments.modifiedOn = modifiedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ActivityLogByIntegratorIdQuery>(
      response.data.activityLogByIntegratorId
    );
  }
  async ActivityLogByCustomerId(
    customerId?: string,
    modifiedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelActivityLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ActivityLogByCustomerIdQuery> {
    const statement = `query ActivityLogByCustomerId($customerId: ID, $modifiedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelActivityLogFilterInput, $limit: Int, $nextToken: String) {
        activityLogByCustomerId(
          customerId: $customerId
          modifiedOn: $modifiedOn
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            cameraId
            modifiedOn
            modifiedBy
            type
            subSystem
            event
            reference
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (modifiedOn) {
      gqlAPIServiceArguments.modifiedOn = modifiedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ActivityLogByCustomerIdQuery>response.data.activityLogByCustomerId;
  }
  async ActivityLogBySiteId(
    siteId?: string,
    modifiedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelActivityLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ActivityLogBySiteIdQuery> {
    const statement = `query ActivityLogBySiteId($siteId: ID, $modifiedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelActivityLogFilterInput, $limit: Int, $nextToken: String) {
        activityLogBySiteId(
          siteId: $siteId
          modifiedOn: $modifiedOn
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            integratorId
            customerId
            siteId
            cameraId
            modifiedOn
            modifiedBy
            type
            subSystem
            event
            reference
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (modifiedOn) {
      gqlAPIServiceArguments.modifiedOn = modifiedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ActivityLogBySiteIdQuery>response.data.activityLogBySiteId;
  }
  async GetSignalTestLog(id: string): Promise<GetSignalTestLogQuery> {
    const statement = `query GetSignalTestLog($id: ID!) {
        getSignalTestLog(id: $id) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSignalTestLogQuery>response.data.getSignalTestLog;
  }
  async ListSignalTestLogs(
    filter?: ModelSignalTestLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSignalTestLogsQuery> {
    const statement = `query ListSignalTestLogs($filter: ModelSignalTestLogFilterInput, $limit: Int, $nextToken: String) {
        listSignalTestLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cameraId
            siteId
            customerId
            integratorId
            monitoringCenterId
            createdAt
            modifiedBy
            vmsEventId
            objectsFound {
              __typename
              object
              confidence
            }
            starred
            thumbnailFilename
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSignalTestLogsQuery>response.data.listSignalTestLogs;
  }
  async SignalTestingBySiteId(
    siteId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSignalTestLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SignalTestingBySiteIdQuery> {
    const statement = `query SignalTestingBySiteId($siteId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSignalTestLogFilterInput, $limit: Int, $nextToken: String) {
        signalTestingBySiteId(
          siteId: $siteId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            siteId
            customerId
            integratorId
            monitoringCenterId
            createdAt
            modifiedBy
            vmsEventId
            objectsFound {
              __typename
              object
              confidence
            }
            starred
            thumbnailFilename
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SignalTestingBySiteIdQuery>response.data.signalTestingBySiteId;
  }
  async SignalTestingByCameraId(
    cameraId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSignalTestLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SignalTestingByCameraIdQuery> {
    const statement = `query SignalTestingByCameraId($cameraId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSignalTestLogFilterInput, $limit: Int, $nextToken: String) {
        signalTestingByCameraId(
          cameraId: $cameraId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            siteId
            customerId
            integratorId
            monitoringCenterId
            createdAt
            modifiedBy
            vmsEventId
            objectsFound {
              __typename
              object
              confidence
            }
            starred
            thumbnailFilename
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SignalTestingByCameraIdQuery>response.data.signalTestingByCameraId;
  }
  async SignalTestingByCameraStarred(
    cameraId?: string,
    starred?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSignalTestLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SignalTestingByCameraStarredQuery> {
    const statement = `query SignalTestingByCameraStarred($cameraId: ID, $starred: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSignalTestLogFilterInput, $limit: Int, $nextToken: String) {
        signalTestingByCameraStarred(
          cameraId: $cameraId
          starred: $starred
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraId
            siteId
            customerId
            integratorId
            monitoringCenterId
            createdAt
            modifiedBy
            vmsEventId
            objectsFound {
              __typename
              object
              confidence
            }
            starred
            thumbnailFilename
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    if (starred) {
      gqlAPIServiceArguments.starred = starred;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SignalTestingByCameraStarredQuery>(
      response.data.signalTestingByCameraStarred
    );
  }
  async GetAuditLog(id: string): Promise<GetAuditLogQuery> {
    const statement = `query GetAuditLog($id: ID!) {
        getAuditLog(id: $id) {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAuditLogQuery>response.data.getAuditLog;
  }
  async ListAuditLogs(
    filter?: ModelAuditLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAuditLogsQuery> {
    const statement = `query ListAuditLogs($filter: ModelAuditLogFilterInput, $limit: Int, $nextToken: String) {
        listAuditLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            partition
            monitoringCenterId
            modifiedOn
            modifiedBy
            area
            action
            entityModel
            entityKey
            description
            newImage
            oldImage
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAuditLogsQuery>response.data.listAuditLogs;
  }
  async AuditLogByMonitoringCenter(
    monitoringCenterId?: string,
    modifiedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuditLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AuditLogByMonitoringCenterQuery> {
    const statement = `query AuditLogByMonitoringCenter($monitoringCenterId: ID, $modifiedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAuditLogFilterInput, $limit: Int, $nextToken: String) {
        auditLogByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          modifiedOn: $modifiedOn
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            partition
            monitoringCenterId
            modifiedOn
            modifiedBy
            area
            action
            entityModel
            entityKey
            description
            newImage
            oldImage
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (modifiedOn) {
      gqlAPIServiceArguments.modifiedOn = modifiedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuditLogByMonitoringCenterQuery>(
      response.data.auditLogByMonitoringCenter
    );
  }
  async AuditLogByPartition(
    partition?: string,
    modifiedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAuditLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AuditLogByPartitionQuery> {
    const statement = `query AuditLogByPartition($partition: String, $modifiedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAuditLogFilterInput, $limit: Int, $nextToken: String) {
        auditLogByPartition(
          partition: $partition
          modifiedOn: $modifiedOn
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            partition
            monitoringCenterId
            modifiedOn
            modifiedBy
            area
            action
            entityModel
            entityKey
            description
            newImage
            oldImage
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (partition) {
      gqlAPIServiceArguments.partition = partition;
    }
    if (modifiedOn) {
      gqlAPIServiceArguments.modifiedOn = modifiedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuditLogByPartitionQuery>response.data.auditLogByPartition;
  }
  async GetIncidentActionDetail(
    id: string
  ): Promise<GetIncidentActionDetailQuery> {
    const statement = `query GetIncidentActionDetail($id: ID!) {
        getIncidentActionDetail(id: $id) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentActionDetailQuery>response.data.getIncidentActionDetail;
  }
  async ListIncidentActionDetails(
    filter?: ModelIncidentActionDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentActionDetailsQuery> {
    const statement = `query ListIncidentActionDetails($filter: ModelIncidentActionDetailFilterInput, $limit: Int, $nextToken: String) {
        listIncidentActionDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            createdAt
            modifiedBy
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentActionDetailsQuery>(
      response.data.listIncidentActionDetails
    );
  }
  async ActionsByIncident(
    incidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentActionDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ActionsByIncidentQuery> {
    const statement = `query ActionsByIncident($incidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelIncidentActionDetailFilterInput, $limit: Int, $nextToken: String) {
        actionsByIncident(
          incidentId: $incidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            createdAt
            modifiedBy
            description
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ActionsByIncidentQuery>response.data.actionsByIncident;
  }
  async GetSOP(id: string): Promise<GetSOPQuery> {
    const statement = `query GetSOP($id: ID!) {
        getSOP(id: $id) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSOPQuery>response.data.getSOP;
  }
  async ListSOPs(
    filter?: ModelSOPFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSOPsQuery> {
    const statement = `query ListSOPs($filter: ModelSOPFilterInput, $limit: Int, $nextToken: String) {
        listSOPs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            eventType
            eventSOPs
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSOPsQuery>response.data.listSOPs;
  }
  async SopBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSOPFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SopBySiteQuery> {
    const statement = `query SopBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSOPFilterInput, $limit: Int, $nextToken: String) {
        sopBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            eventType
            eventSOPs
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SopBySiteQuery>response.data.sopBySite;
  }
  async SopMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSOPFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SopMonitoringCenterQuery> {
    const statement = `query SopMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelSOPFilterInput, $limit: Int, $nextToken: String) {
        sopMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            eventType
            eventSOPs
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SopMonitoringCenterQuery>response.data.sopMonitoringCenter;
  }
  async GetRspndrEvents(id: string): Promise<GetRspndrEventsQuery> {
    const statement = `query GetRspndrEvents($id: ID!) {
        getRspndrEvents(id: $id) {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRspndrEventsQuery>response.data.getRspndrEvents;
  }
  async ListRspndrEventss(
    filter?: ModelRspndrEventsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRspndrEventssQuery> {
    const statement = `query ListRspndrEventss($filter: ModelRspndrEventsFilterInput, $limit: Int, $nextToken: String) {
        listRspndrEventss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            request_id
            tenant_id
            error
            payload
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRspndrEventssQuery>response.data.listRspndrEventss;
  }
  async RspndrEventsSorted(
    modifiedBy?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrEventsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RspndrEventsSortedQuery> {
    const statement = `query RspndrEventsSorted($modifiedBy: String, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRspndrEventsFilterInput, $limit: Int, $nextToken: String) {
        rspndrEventsSorted(
          modifiedBy: $modifiedBy
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            request_id
            tenant_id
            error
            payload
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (modifiedBy) {
      gqlAPIServiceArguments.modifiedBy = modifiedBy;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrEventsSortedQuery>response.data.rspndrEventsSorted;
  }
  async RspndrEventsByRequestId(
    request_id?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrEventsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RspndrEventsByRequestIdQuery> {
    const statement = `query RspndrEventsByRequestId($request_id: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRspndrEventsFilterInput, $limit: Int, $nextToken: String) {
        rspndrEventsByRequestId(
          request_id: $request_id
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            request_id
            tenant_id
            error
            payload
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (request_id) {
      gqlAPIServiceArguments.request_id = request_id;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrEventsByRequestIdQuery>response.data.rspndrEventsByRequestId;
  }
  async GetRspndrIncident(id: string): Promise<GetRspndrIncidentQuery> {
    const statement = `query GetRspndrIncident($id: ID!) {
        getRspndrIncident(id: $id) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRspndrIncidentQuery>response.data.getRspndrIncident;
  }
  async ListRspndrIncidents(
    filter?: ModelRspndrIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRspndrIncidentsQuery> {
    const statement = `query ListRspndrIncidents($filter: ModelRspndrIncidentFilterInput, $limit: Int, $nextToken: String) {
        listRspndrIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incident_id
            address1
            address2
            arrived_at
            billed_to
            city
            country
            created_at
            customer_number
            dealer
            eta_at
            ext_username
            finished_at
            grounded_at
            grounding_end_confirm_acked_at
            grounding_end_confirmed_at
            grounding_end_decline_acked_at
            grounding_end_declined_at
            grounding_ended_at
            grounding_ending_at
            grounding_request_confirm_acked_at
            grounding_request_confirmed_at
            grounding_request_decline_acked_at
            grounding_request_declined_at
            grounding_requested_at
            grounding_terminate_acked_at
            grounding_terminating_at
            kind
            lat
            lng
            master_account_number
            modified_at
            name
            notes
            notesTranslated
            notesSanitized
            postal_code
            state
            status
            taken_at
            tenant_id
            timezone
            triggered_at
            type
            zone
            monitoringCenterId
            customerId
            integratorId
            siteId
            modifiedBy
            createdAt
            updatedAt
            check_list {
              __typename
              items {
                __typename
                id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                description
                descriptionTranslated
                descriptionSanitized
                image_required
                image_url
                order
                required
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            rules {
              __typename
              items {
                __typename
                id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                call_police
                expires_at
                leave_when
                max_duration
                order
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                tenant_id
                request_id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                type
                notes
                notesTranslated
                notesSanitized
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRspndrIncidentsQuery>response.data.listRspndrIncidents;
  }
  async RspndrByIncidentId(
    incident_id?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RspndrByIncidentIdQuery> {
    const statement = `query RspndrByIncidentId($incident_id: String, $sortDirection: ModelSortDirection, $filter: ModelRspndrIncidentFilterInput, $limit: Int, $nextToken: String) {
        rspndrByIncidentId(
          incident_id: $incident_id
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incident_id
            address1
            address2
            arrived_at
            billed_to
            city
            country
            created_at
            customer_number
            dealer
            eta_at
            ext_username
            finished_at
            grounded_at
            grounding_end_confirm_acked_at
            grounding_end_confirmed_at
            grounding_end_decline_acked_at
            grounding_end_declined_at
            grounding_ended_at
            grounding_ending_at
            grounding_request_confirm_acked_at
            grounding_request_confirmed_at
            grounding_request_decline_acked_at
            grounding_request_declined_at
            grounding_requested_at
            grounding_terminate_acked_at
            grounding_terminating_at
            kind
            lat
            lng
            master_account_number
            modified_at
            name
            notes
            notesTranslated
            notesSanitized
            postal_code
            state
            status
            taken_at
            tenant_id
            timezone
            triggered_at
            type
            zone
            monitoringCenterId
            customerId
            integratorId
            siteId
            modifiedBy
            createdAt
            updatedAt
            check_list {
              __typename
              items {
                __typename
                id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                description
                descriptionTranslated
                descriptionSanitized
                image_required
                image_url
                order
                required
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            rules {
              __typename
              items {
                __typename
                id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                call_police
                expires_at
                leave_when
                max_duration
                order
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                tenant_id
                request_id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                type
                notes
                notesTranslated
                notesSanitized
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incident_id) {
      gqlAPIServiceArguments.incident_id = incident_id;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrByIncidentIdQuery>response.data.rspndrByIncidentId;
  }
  async RspndrIncidentBySite(
    siteId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RspndrIncidentBySiteQuery> {
    const statement = `query RspndrIncidentBySite($siteId: String, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRspndrIncidentFilterInput, $limit: Int, $nextToken: String) {
        rspndrIncidentBySite(
          siteId: $siteId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incident_id
            address1
            address2
            arrived_at
            billed_to
            city
            country
            created_at
            customer_number
            dealer
            eta_at
            ext_username
            finished_at
            grounded_at
            grounding_end_confirm_acked_at
            grounding_end_confirmed_at
            grounding_end_decline_acked_at
            grounding_end_declined_at
            grounding_ended_at
            grounding_ending_at
            grounding_request_confirm_acked_at
            grounding_request_confirmed_at
            grounding_request_decline_acked_at
            grounding_request_declined_at
            grounding_requested_at
            grounding_terminate_acked_at
            grounding_terminating_at
            kind
            lat
            lng
            master_account_number
            modified_at
            name
            notes
            notesTranslated
            notesSanitized
            postal_code
            state
            status
            taken_at
            tenant_id
            timezone
            triggered_at
            type
            zone
            monitoringCenterId
            customerId
            integratorId
            siteId
            modifiedBy
            createdAt
            updatedAt
            check_list {
              __typename
              items {
                __typename
                id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                description
                descriptionTranslated
                descriptionSanitized
                image_required
                image_url
                order
                required
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            rules {
              __typename
              items {
                __typename
                id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                call_police
                expires_at
                leave_when
                max_duration
                order
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                tenant_id
                request_id
                incidentId
                rspndrIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                type
                notes
                notesTranslated
                notesSanitized
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrIncidentBySiteQuery>response.data.rspndrIncidentBySite;
  }
  async GetRspndrIncidentCheckItem(
    id: string
  ): Promise<GetRspndrIncidentCheckItemQuery> {
    const statement = `query GetRspndrIncidentCheckItem($id: ID!) {
        getRspndrIncidentCheckItem(id: $id) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRspndrIncidentCheckItemQuery>(
      response.data.getRspndrIncidentCheckItem
    );
  }
  async ListRspndrIncidentCheckItems(
    filter?: ModelRspndrIncidentCheckItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRspndrIncidentCheckItemsQuery> {
    const statement = `query ListRspndrIncidentCheckItems($filter: ModelRspndrIncidentCheckItemFilterInput, $limit: Int, $nextToken: String) {
        listRspndrIncidentCheckItems(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            description
            descriptionTranslated
            descriptionSanitized
            image_required
            image_url
            order
            required
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRspndrIncidentCheckItemsQuery>(
      response.data.listRspndrIncidentCheckItems
    );
  }
  async CheckItemByRspndrIncidentId(
    rspndrIncidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrIncidentCheckItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CheckItemByRspndrIncidentIdQuery> {
    const statement = `query CheckItemByRspndrIncidentId($rspndrIncidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelRspndrIncidentCheckItemFilterInput, $limit: Int, $nextToken: String) {
        checkItemByRspndrIncidentId(
          rspndrIncidentId: $rspndrIncidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            description
            descriptionTranslated
            descriptionSanitized
            image_required
            image_url
            order
            required
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rspndrIncidentId) {
      gqlAPIServiceArguments.rspndrIncidentId = rspndrIncidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CheckItemByRspndrIncidentIdQuery>(
      response.data.checkItemByRspndrIncidentId
    );
  }
  async GetRspndrIncidentGroundingRule(
    id: string
  ): Promise<GetRspndrIncidentGroundingRuleQuery> {
    const statement = `query GetRspndrIncidentGroundingRule($id: ID!) {
        getRspndrIncidentGroundingRule(id: $id) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRspndrIncidentGroundingRuleQuery>(
      response.data.getRspndrIncidentGroundingRule
    );
  }
  async ListRspndrIncidentGroundingRules(
    filter?: ModelRspndrIncidentGroundingRuleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRspndrIncidentGroundingRulesQuery> {
    const statement = `query ListRspndrIncidentGroundingRules($filter: ModelRspndrIncidentGroundingRuleFilterInput, $limit: Int, $nextToken: String) {
        listRspndrIncidentGroundingRules(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            call_police
            expires_at
            leave_when
            max_duration
            order
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRspndrIncidentGroundingRulesQuery>(
      response.data.listRspndrIncidentGroundingRules
    );
  }
  async GroundingRuleByRspndrIncidentId(
    rspndrIncidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrIncidentGroundingRuleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GroundingRuleByRspndrIncidentIdQuery> {
    const statement = `query GroundingRuleByRspndrIncidentId($rspndrIncidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelRspndrIncidentGroundingRuleFilterInput, $limit: Int, $nextToken: String) {
        groundingRuleByRspndrIncidentId(
          rspndrIncidentId: $rspndrIncidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            call_police
            expires_at
            leave_when
            max_duration
            order
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rspndrIncidentId) {
      gqlAPIServiceArguments.rspndrIncidentId = rspndrIncidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GroundingRuleByRspndrIncidentIdQuery>(
      response.data.groundingRuleByRspndrIncidentId
    );
  }
  async GetRspndrActivity(id: string): Promise<GetRspndrActivityQuery> {
    const statement = `query GetRspndrActivity($id: ID!) {
        getRspndrActivity(id: $id) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRspndrActivityQuery>response.data.getRspndrActivity;
  }
  async ListRspndrActivitys(
    filter?: ModelRspndrActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRspndrActivitysQuery> {
    const statement = `query ListRspndrActivitys($filter: ModelRspndrActivityFilterInput, $limit: Int, $nextToken: String) {
        listRspndrActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            tenant_id
            request_id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            type
            notes
            notesTranslated
            notesSanitized
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRspndrActivitysQuery>response.data.listRspndrActivitys;
  }
  async RspndrActivityByIncidentId(
    incidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RspndrActivityByIncidentIdQuery> {
    const statement = `query RspndrActivityByIncidentId($incidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRspndrActivityFilterInput, $limit: Int, $nextToken: String) {
        rspndrActivityByIncidentId(
          incidentId: $incidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            tenant_id
            request_id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            type
            notes
            notesTranslated
            notesSanitized
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrActivityByIncidentIdQuery>(
      response.data.rspndrActivityByIncidentId
    );
  }
  async RspndrActivityByRspndrIncidentId(
    rspndrIncidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRspndrActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RspndrActivityByRspndrIncidentIdQuery> {
    const statement = `query RspndrActivityByRspndrIncidentId($rspndrIncidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRspndrActivityFilterInput, $limit: Int, $nextToken: String) {
        rspndrActivityByRspndrIncidentId(
          rspndrIncidentId: $rspndrIncidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            tenant_id
            request_id
            incidentId
            rspndrIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            type
            notes
            notesTranslated
            notesSanitized
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rspndrIncidentId) {
      gqlAPIServiceArguments.rspndrIncidentId = rspndrIncidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RspndrActivityByRspndrIncidentIdQuery>(
      response.data.rspndrActivityByRspndrIncidentId
    );
  }
  async GetTrackTikIncident(id: string): Promise<GetTrackTikIncidentQuery> {
    const statement = `query GetTrackTikIncident($id: ID!) {
        getTrackTikIncident(id: $id) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTrackTikIncidentQuery>response.data.getTrackTikIncident;
  }
  async ListTrackTikIncidents(
    filter?: ModelTrackTikIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrackTikIncidentsQuery> {
    const statement = `query ListTrackTikIncidents($filter: ModelTrackTikIncidentFilterInput, $limit: Int, $nextToken: String) {
        listTrackTikIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            status
            reportTemplateId
            taskTypeId
            taskTypeName
            priority
            trackTikId
            primaryCameraEventId
            modifiedBy
            createdAt
            updatedAt
            activities {
              __typename
              items {
                __typename
                id
                incidentId
                trackTikIncidentId
                monitoringCenterId
                notes
                payload
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            additionalDataToSend {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTrackTikIncidentsQuery>response.data.listTrackTikIncidents;
  }
  async TrackTikByIncidentId(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTrackTikIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TrackTikByIncidentIdQuery> {
    const statement = `query TrackTikByIncidentId($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelTrackTikIncidentFilterInput, $limit: Int, $nextToken: String) {
        trackTikByIncidentId(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            status
            reportTemplateId
            taskTypeId
            taskTypeName
            priority
            trackTikId
            primaryCameraEventId
            modifiedBy
            createdAt
            updatedAt
            activities {
              __typename
              items {
                __typename
                id
                incidentId
                trackTikIncidentId
                monitoringCenterId
                notes
                payload
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
            additionalDataToSend {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrackTikByIncidentIdQuery>response.data.trackTikByIncidentId;
  }
  async GetTrackTikActivity(id: string): Promise<GetTrackTikActivityQuery> {
    const statement = `query GetTrackTikActivity($id: ID!) {
        getTrackTikActivity(id: $id) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTrackTikActivityQuery>response.data.getTrackTikActivity;
  }
  async ListTrackTikActivitys(
    filter?: ModelTrackTikActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrackTikActivitysQuery> {
    const statement = `query ListTrackTikActivitys($filter: ModelTrackTikActivityFilterInput, $limit: Int, $nextToken: String) {
        listTrackTikActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            trackTikIncidentId
            monitoringCenterId
            notes
            payload
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTrackTikActivitysQuery>response.data.listTrackTikActivitys;
  }
  async TrackTikActivityByIncidentId(
    incidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTrackTikActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TrackTikActivityByIncidentIdQuery> {
    const statement = `query TrackTikActivityByIncidentId($incidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTrackTikActivityFilterInput, $limit: Int, $nextToken: String) {
        trackTikActivityByIncidentId(
          incidentId: $incidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            trackTikIncidentId
            monitoringCenterId
            notes
            payload
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrackTikActivityByIncidentIdQuery>(
      response.data.trackTikActivityByIncidentId
    );
  }
  async TrackTikActivityByTrackTikIncidentId(
    trackTikIncidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTrackTikActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TrackTikActivityByTrackTikIncidentIdQuery> {
    const statement = `query TrackTikActivityByTrackTikIncidentId($trackTikIncidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTrackTikActivityFilterInput, $limit: Int, $nextToken: String) {
        trackTikActivityByTrackTikIncidentId(
          trackTikIncidentId: $trackTikIncidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            trackTikIncidentId
            monitoringCenterId
            notes
            payload
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (trackTikIncidentId) {
      gqlAPIServiceArguments.trackTikIncidentId = trackTikIncidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TrackTikActivityByTrackTikIncidentIdQuery>(
      response.data.trackTikActivityByTrackTikIncidentId
    );
  }
  async GetRapidSOSIncident(id: string): Promise<GetRapidSOSIncidentQuery> {
    const statement = `query GetRapidSOSIncident($id: ID!) {
        getRapidSOSIncident(id: $id) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSIncidentQuery>response.data.getRapidSOSIncident;
  }
  async ListRapidSOSIncidents(
    filter?: ModelRapidSOSIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSIncidentsQuery> {
    const statement = `query ListRapidSOSIncidents($filter: ModelRapidSOSIncidentFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSIncidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            coverageType
            alert_id
            covering_psap
            callflow
            zone_description
            alarm_permit_number
            visually_verified
            site_phone
            status
            instructions
            createdAt
            modifiedBy
            primaryCameraEventId
            siteIntegrationId
            incidentId
            siteId
            customerId
            monitoringCenterId
            updatedAt
            event {
              __typename
              id
              rapidSOSIncidentId
              description
              descriptionTranslated
              descriptionSanitized
              emergency_type
              incident_time
              service_provider_name
              site_type
              source_id
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              location {
                __typename
                id
                rapidSOSIncidentId
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
                civic {
                  __typename
                  id
                  rapidSOSIncidentId
                  name
                  street_1
                  street_2
                  city
                  state
                  country
                  zip_code
                  modifiedBy
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
              }
            }
            emergency_contacts {
              __typename
              items {
                __typename
                id
                rapidSOSIncidentId
                name
                phone
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                notes
                notesTranslated
                notesSanitized
                updatedAt
                logMessage {
                  __typename
                  id
                  incidentId
                  siteIntegrationId
                  rapidSOSIncidentId
                  monitoringCenterId
                  type
                  modifiedBy
                  writtenBy
                  message
                  messageTranslated
                  messageSanitized
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            attachments {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                modifiedBy
                fileName
                createdAt
                updatedAt
              }
              nextToken
            }
            additionalDataToSend {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSIncidentsQuery>response.data.listRapidSOSIncidents;
  }
  async RapidSOSByIncidentId(
    incidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidSOSByIncidentIdQuery> {
    const statement = `query RapidSOSByIncidentId($incidentId: String, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSIncidentFilterInput, $limit: Int, $nextToken: String) {
        rapidSOSByIncidentId(
          incidentId: $incidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            coverageType
            alert_id
            covering_psap
            callflow
            zone_description
            alarm_permit_number
            visually_verified
            site_phone
            status
            instructions
            createdAt
            modifiedBy
            primaryCameraEventId
            siteIntegrationId
            incidentId
            siteId
            customerId
            monitoringCenterId
            updatedAt
            event {
              __typename
              id
              rapidSOSIncidentId
              description
              descriptionTranslated
              descriptionSanitized
              emergency_type
              incident_time
              service_provider_name
              site_type
              source_id
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              location {
                __typename
                id
                rapidSOSIncidentId
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
                civic {
                  __typename
                  id
                  rapidSOSIncidentId
                  name
                  street_1
                  street_2
                  city
                  state
                  country
                  zip_code
                  modifiedBy
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
              }
            }
            emergency_contacts {
              __typename
              items {
                __typename
                id
                rapidSOSIncidentId
                name
                phone
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                notes
                notesTranslated
                notesSanitized
                updatedAt
                logMessage {
                  __typename
                  id
                  incidentId
                  siteIntegrationId
                  rapidSOSIncidentId
                  monitoringCenterId
                  type
                  modifiedBy
                  writtenBy
                  message
                  messageTranslated
                  messageSanitized
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            attachments {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                modifiedBy
                fileName
                createdAt
                updatedAt
              }
              nextToken
            }
            additionalDataToSend {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSByIncidentIdQuery>response.data.rapidSOSByIncidentId;
  }
  async RapidSOSByIncidentIdSiteIntegration(
    incidentId?: string,
    siteIntegrationId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidSOSByIncidentIdSiteIntegrationQuery> {
    const statement = `query RapidSOSByIncidentIdSiteIntegration($incidentId: String, $siteIntegrationId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSIncidentFilterInput, $limit: Int, $nextToken: String) {
        rapidSOSByIncidentIdSiteIntegration(
          incidentId: $incidentId
          siteIntegrationId: $siteIntegrationId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            coverageType
            alert_id
            covering_psap
            callflow
            zone_description
            alarm_permit_number
            visually_verified
            site_phone
            status
            instructions
            createdAt
            modifiedBy
            primaryCameraEventId
            siteIntegrationId
            incidentId
            siteId
            customerId
            monitoringCenterId
            updatedAt
            event {
              __typename
              id
              rapidSOSIncidentId
              description
              descriptionTranslated
              descriptionSanitized
              emergency_type
              incident_time
              service_provider_name
              site_type
              source_id
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              location {
                __typename
                id
                rapidSOSIncidentId
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
                civic {
                  __typename
                  id
                  rapidSOSIncidentId
                  name
                  street_1
                  street_2
                  city
                  state
                  country
                  zip_code
                  modifiedBy
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
              }
            }
            emergency_contacts {
              __typename
              items {
                __typename
                id
                rapidSOSIncidentId
                name
                phone
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                notes
                notesTranslated
                notesSanitized
                updatedAt
                logMessage {
                  __typename
                  id
                  incidentId
                  siteIntegrationId
                  rapidSOSIncidentId
                  monitoringCenterId
                  type
                  modifiedBy
                  writtenBy
                  message
                  messageTranslated
                  messageSanitized
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            attachments {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                modifiedBy
                fileName
                createdAt
                updatedAt
              }
              nextToken
            }
            additionalDataToSend {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (siteIntegrationId) {
      gqlAPIServiceArguments.siteIntegrationId = siteIntegrationId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSByIncidentIdSiteIntegrationQuery>(
      response.data.rapidSOSByIncidentIdSiteIntegration
    );
  }
  async RapidSOSByAlertId(
    alert_id?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSIncidentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidSOSByAlertIdQuery> {
    const statement = `query RapidSOSByAlertId($alert_id: String, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSIncidentFilterInput, $limit: Int, $nextToken: String) {
        rapidSOSByAlertId(
          alert_id: $alert_id
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            coverageType
            alert_id
            covering_psap
            callflow
            zone_description
            alarm_permit_number
            visually_verified
            site_phone
            status
            instructions
            createdAt
            modifiedBy
            primaryCameraEventId
            siteIntegrationId
            incidentId
            siteId
            customerId
            monitoringCenterId
            updatedAt
            event {
              __typename
              id
              rapidSOSIncidentId
              description
              descriptionTranslated
              descriptionSanitized
              emergency_type
              incident_time
              service_provider_name
              site_type
              source_id
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              location {
                __typename
                id
                rapidSOSIncidentId
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
                civic {
                  __typename
                  id
                  rapidSOSIncidentId
                  name
                  street_1
                  street_2
                  city
                  state
                  country
                  zip_code
                  modifiedBy
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
              }
            }
            emergency_contacts {
              __typename
              items {
                __typename
                id
                rapidSOSIncidentId
                name
                phone
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
              nextToken
            }
            activities {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                error
                payload
                modifiedBy
                createdAt
                notes
                notesTranslated
                notesSanitized
                updatedAt
                logMessage {
                  __typename
                  id
                  incidentId
                  siteIntegrationId
                  rapidSOSIncidentId
                  monitoringCenterId
                  type
                  modifiedBy
                  writtenBy
                  message
                  messageTranslated
                  messageSanitized
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            attachments {
              __typename
              items {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                modifiedBy
                fileName
                createdAt
                updatedAt
              }
              nextToken
            }
            additionalDataToSend {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (alert_id) {
      gqlAPIServiceArguments.alert_id = alert_id;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSByAlertIdQuery>response.data.rapidSOSByAlertId;
  }
  async GetRapidSOSEventDetails(
    id: string
  ): Promise<GetRapidSOSEventDetailsQuery> {
    const statement = `query GetRapidSOSEventDetails($id: ID!) {
        getRapidSOSEventDetails(id: $id) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSEventDetailsQuery>response.data.getRapidSOSEventDetails;
  }
  async ListRapidSOSEventDetailss(
    filter?: ModelRapidSOSEventDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSEventDetailssQuery> {
    const statement = `query ListRapidSOSEventDetailss($filter: ModelRapidSOSEventDetailsFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSEventDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSEventDetailssQuery>(
      response.data.listRapidSOSEventDetailss
    );
  }
  async GetRapidSOSLocation(id: string): Promise<GetRapidSOSLocationQuery> {
    const statement = `query GetRapidSOSLocation($id: ID!) {
        getRapidSOSLocation(id: $id) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSLocationQuery>response.data.getRapidSOSLocation;
  }
  async ListRapidSOSLocations(
    filter?: ModelRapidSOSLocationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSLocationsQuery> {
    const statement = `query ListRapidSOSLocations($filter: ModelRapidSOSLocationFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSLocationsQuery>response.data.listRapidSOSLocations;
  }
  async GetRapidSOSCivicAddress(
    id: string
  ): Promise<GetRapidSOSCivicAddressQuery> {
    const statement = `query GetRapidSOSCivicAddress($id: ID!) {
        getRapidSOSCivicAddress(id: $id) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSCivicAddressQuery>response.data.getRapidSOSCivicAddress;
  }
  async ListRapidSOSCivicAddresss(
    filter?: ModelRapidSOSCivicAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSCivicAddresssQuery> {
    const statement = `query ListRapidSOSCivicAddresss($filter: ModelRapidSOSCivicAddressFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSCivicAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSCivicAddresssQuery>(
      response.data.listRapidSOSCivicAddresss
    );
  }
  async GetRapidSOSEmergencyContact(
    id: string
  ): Promise<GetRapidSOSEmergencyContactQuery> {
    const statement = `query GetRapidSOSEmergencyContact($id: ID!) {
        getRapidSOSEmergencyContact(id: $id) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSEmergencyContactQuery>(
      response.data.getRapidSOSEmergencyContact
    );
  }
  async ListRapidSOSEmergencyContacts(
    filter?: ModelRapidSOSEmergencyContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSEmergencyContactsQuery> {
    const statement = `query ListRapidSOSEmergencyContacts($filter: ModelRapidSOSEmergencyContactFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSEmergencyContacts(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            rapidSOSIncidentId
            name
            phone
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSEmergencyContactsQuery>(
      response.data.listRapidSOSEmergencyContacts
    );
  }
  async ContactsByRapidSOSIncidentId(
    rapidSOSIncidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSEmergencyContactFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ContactsByRapidSOSIncidentIdQuery> {
    const statement = `query ContactsByRapidSOSIncidentId($rapidSOSIncidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSEmergencyContactFilterInput, $limit: Int, $nextToken: String) {
        contactsByRapidSOSIncidentId(
          rapidSOSIncidentId: $rapidSOSIncidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            rapidSOSIncidentId
            name
            phone
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rapidSOSIncidentId) {
      gqlAPIServiceArguments.rapidSOSIncidentId = rapidSOSIncidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ContactsByRapidSOSIncidentIdQuery>(
      response.data.contactsByRapidSOSIncidentId
    );
  }
  async GetRapidSOSActivity(id: string): Promise<GetRapidSOSActivityQuery> {
    const statement = `query GetRapidSOSActivity($id: ID!) {
        getRapidSOSActivity(id: $id) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSActivityQuery>response.data.getRapidSOSActivity;
  }
  async ListRapidSOSActivitys(
    filter?: ModelRapidSOSActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSActivitysQuery> {
    const statement = `query ListRapidSOSActivitys($filter: ModelRapidSOSActivityFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            notes
            notesTranslated
            notesSanitized
            updatedAt
            logMessage {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              type
              modifiedBy
              writtenBy
              message
              messageTranslated
              messageSanitized
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSActivitysQuery>response.data.listRapidSOSActivitys;
  }
  async RapidSOSActivitySorted(
    modifiedBy?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidSOSActivitySortedQuery> {
    const statement = `query RapidSOSActivitySorted($modifiedBy: String, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSActivityFilterInput, $limit: Int, $nextToken: String) {
        rapidSOSActivitySorted(
          modifiedBy: $modifiedBy
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            notes
            notesTranslated
            notesSanitized
            updatedAt
            logMessage {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              type
              modifiedBy
              writtenBy
              message
              messageTranslated
              messageSanitized
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (modifiedBy) {
      gqlAPIServiceArguments.modifiedBy = modifiedBy;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSActivitySortedQuery>response.data.rapidSOSActivitySorted;
  }
  async RapidSOSActivityByIncidentId(
    incidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidSOSActivityByIncidentIdQuery> {
    const statement = `query RapidSOSActivityByIncidentId($incidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSActivityFilterInput, $limit: Int, $nextToken: String) {
        rapidSOSActivityByIncidentId(
          incidentId: $incidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            notes
            notesTranslated
            notesSanitized
            updatedAt
            logMessage {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              type
              modifiedBy
              writtenBy
              message
              messageTranslated
              messageSanitized
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidSOSActivityByIncidentIdQuery>(
      response.data.rapidSOSActivityByIncidentId
    );
  }
  async RapidActivityByRapidSOSIncidentId(
    rapidSOSIncidentId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSActivityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidActivityByRapidSOSIncidentIdQuery> {
    const statement = `query RapidActivityByRapidSOSIncidentId($rapidSOSIncidentId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSActivityFilterInput, $limit: Int, $nextToken: String) {
        rapidActivityByRapidSOSIncidentId(
          rapidSOSIncidentId: $rapidSOSIncidentId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            error
            payload
            modifiedBy
            createdAt
            notes
            notesTranslated
            notesSanitized
            updatedAt
            logMessage {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              type
              modifiedBy
              writtenBy
              message
              messageTranslated
              messageSanitized
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rapidSOSIncidentId) {
      gqlAPIServiceArguments.rapidSOSIncidentId = rapidSOSIncidentId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidActivityByRapidSOSIncidentIdQuery>(
      response.data.rapidActivityByRapidSOSIncidentId
    );
  }
  async GetRapidSOSLogMessage(id: string): Promise<GetRapidSOSLogMessageQuery> {
    const statement = `query GetRapidSOSLogMessage($id: ID!) {
        getRapidSOSLogMessage(id: $id) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSLogMessageQuery>response.data.getRapidSOSLogMessage;
  }
  async ListRapidSOSLogMessages(
    filter?: ModelRapidSOSLogMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSLogMessagesQuery> {
    const statement = `query ListRapidSOSLogMessages($filter: ModelRapidSOSLogMessageFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSLogMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSLogMessagesQuery>response.data.listRapidSOSLogMessages;
  }
  async GetRapidSOSAttachment(id: string): Promise<GetRapidSOSAttachmentQuery> {
    const statement = `query GetRapidSOSAttachment($id: ID!) {
        getRapidSOSAttachment(id: $id) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRapidSOSAttachmentQuery>response.data.getRapidSOSAttachment;
  }
  async ListRapidSOSAttachments(
    filter?: ModelRapidSOSAttachmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRapidSOSAttachmentsQuery> {
    const statement = `query ListRapidSOSAttachments($filter: ModelRapidSOSAttachmentFilterInput, $limit: Int, $nextToken: String) {
        listRapidSOSAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            modifiedBy
            fileName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRapidSOSAttachmentsQuery>response.data.listRapidSOSAttachments;
  }
  async RapidAttachByRapidSOSIncidentId(
    rapidSOSIncidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRapidSOSAttachmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RapidAttachByRapidSOSIncidentIdQuery> {
    const statement = `query RapidAttachByRapidSOSIncidentId($rapidSOSIncidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelRapidSOSAttachmentFilterInput, $limit: Int, $nextToken: String) {
        rapidAttachByRapidSOSIncidentId(
          rapidSOSIncidentId: $rapidSOSIncidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            modifiedBy
            fileName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rapidSOSIncidentId) {
      gqlAPIServiceArguments.rapidSOSIncidentId = rapidSOSIncidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RapidAttachByRapidSOSIncidentIdQuery>(
      response.data.rapidAttachByRapidSOSIncidentId
    );
  }
  async GetIncidentCodeDetail(id: string): Promise<GetIncidentCodeDetailQuery> {
    const statement = `query GetIncidentCodeDetail($id: ID!) {
        getIncidentCodeDetail(id: $id) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentCodeDetailQuery>response.data.getIncidentCodeDetail;
  }
  async ListIncidentCodeDetails(
    filter?: ModelIncidentCodeDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentCodeDetailsQuery> {
    const statement = `query ListIncidentCodeDetails($filter: ModelIncidentCodeDetailFilterInput, $limit: Int, $nextToken: String) {
        listIncidentCodeDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            publicCode
            shareUrl
            locationSid
            deviceSid
            eventDate
            modifiedBy
            createdAt
            updatedAt
            incidentDataToShare {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentCodeDetailsQuery>response.data.listIncidentCodeDetails;
  }
  async IncidentCodeDetailByIncident(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentCodeDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentCodeDetailByIncidentQuery> {
    const statement = `query IncidentCodeDetailByIncident($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentCodeDetailFilterInput, $limit: Int, $nextToken: String) {
        incidentCodeDetailByIncident(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            publicCode
            shareUrl
            locationSid
            deviceSid
            eventDate
            modifiedBy
            createdAt
            updatedAt
            incidentDataToShare {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentCodeDetailByIncidentQuery>(
      response.data.incidentCodeDetailByIncident
    );
  }
  async GetIncidentShareSettings(
    id: string
  ): Promise<GetIncidentShareSettingsQuery> {
    const statement = `query GetIncidentShareSettings($id: ID!) {
        getIncidentShareSettings(id: $id) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareSettingsQuery>(
      response.data.getIncidentShareSettings
    );
  }
  async ListIncidentShareSettingss(
    filter?: ModelIncidentShareSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareSettingssQuery> {
    const statement = `query ListIncidentShareSettingss($filter: ModelIncidentShareSettingsFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareSettingss(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            code
            modifiedBy
            expiration
            createdAt
            updatedAt
            incidentDataToShare {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareSettingssQuery>(
      response.data.listIncidentShareSettingss
    );
  }
  async IncidentShareSettingsByIncident(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareSettingsByIncidentQuery> {
    const statement = `query IncidentShareSettingsByIncident($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareSettingsFilterInput, $limit: Int, $nextToken: String) {
        incidentShareSettingsByIncident(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            code
            modifiedBy
            expiration
            createdAt
            updatedAt
            incidentDataToShare {
              __typename
              id
              incidentId
              monitoringCenterId
              shareType
              externalIncidentNotes
              externalSiteCriticalNotes
              externalClientCriticalNotes
              completedActionItems
              objectsFound
              alarmStationInfo
              customerContactInfo
              videoFeedUrls
              modifiedBy
              attachmentsList
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareSettingsByIncidentQuery>(
      response.data.incidentShareSettingsByIncident
    );
  }
  async GetIncidentDataToShare(
    id: string
  ): Promise<GetIncidentDataToShareQuery> {
    const statement = `query GetIncidentDataToShare($id: ID!) {
        getIncidentDataToShare(id: $id) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentDataToShareQuery>response.data.getIncidentDataToShare;
  }
  async ListIncidentDataToShares(
    filter?: ModelIncidentDataToShareFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentDataToSharesQuery> {
    const statement = `query ListIncidentDataToShares($filter: ModelIncidentDataToShareFilterInput, $limit: Int, $nextToken: String) {
        listIncidentDataToShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentDataToSharesQuery>(
      response.data.listIncidentDataToShares
    );
  }
  async IncidentDataToShareByIncident(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentDataToShareFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentDataToShareByIncidentQuery> {
    const statement = `query IncidentDataToShareByIncident($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentDataToShareFilterInput, $limit: Int, $nextToken: String) {
        incidentDataToShareByIncident(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentDataToShareByIncidentQuery>(
      response.data.incidentDataToShareByIncident
    );
  }
  async GetIncidentShareEntry(id: string): Promise<GetIncidentShareEntryQuery> {
    const statement = `query GetIncidentShareEntry($id: ID!) {
        getIncidentShareEntry(id: $id) {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareEntryQuery>response.data.getIncidentShareEntry;
  }
  async ListIncidentShareEntrys(
    filter?: ModelIncidentShareEntryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareEntrysQuery> {
    const statement = `query ListIncidentShareEntrys($filter: ModelIncidentShareEntryFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            incidentDataToShareId
            incidentId
            code
            shareType
            expiration
            alarmsCentralStation
            alarmsPermitNum
            alarmsCSID
            attachmentsList
            incidentOpenTime
            incidentClosedTime
            externalSiteNotes
            externalClientNotes
            createdAt
            updatedAt
            site {
              __typename
              id
              name
              addressOne
              addressTwo
              city
              state
              country
              zipCode
              createdAt
              updatedAt
            }
            integrator {
              __typename
              id
              name
              logoPath
              createdAt
              updatedAt
            }
            notes {
              __typename
              items {
                __typename
                id
                incidentId
                incidentNoteId
                incidentShareEntryId
                note
                noteCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              __typename
              items {
                __typename
                id
                cameraEventId
                startTime
                vmsCameraName
                videoFeedUrl
                eventType
                disposition
                triggerType
                objectsFound
                incidentShareEntryId
                cameraLatitude
                cameraLongitude
                createdAt
                updatedAt
                actionItems {
                  __typename
                  items {
                    __typename
                    id
                    incidentProcedureTaskId
                    incidentShareEventId
                    order
                    completedAt
                    description
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            contactInformation {
              __typename
              items {
                __typename
                id
                incidentShareEntryId
                name
                phone
                ext
                email
                type
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareEntrysQuery>response.data.listIncidentShareEntrys;
  }
  async IncidentShareByCode(
    code?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareEntryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareByCodeQuery> {
    const statement = `query IncidentShareByCode($code: String, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareEntryFilterInput, $limit: Int, $nextToken: String) {
        incidentShareByCode(
          code: $code
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentDataToShareId
            incidentId
            code
            shareType
            expiration
            alarmsCentralStation
            alarmsPermitNum
            alarmsCSID
            attachmentsList
            incidentOpenTime
            incidentClosedTime
            externalSiteNotes
            externalClientNotes
            createdAt
            updatedAt
            site {
              __typename
              id
              name
              addressOne
              addressTwo
              city
              state
              country
              zipCode
              createdAt
              updatedAt
            }
            integrator {
              __typename
              id
              name
              logoPath
              createdAt
              updatedAt
            }
            notes {
              __typename
              items {
                __typename
                id
                incidentId
                incidentNoteId
                incidentShareEntryId
                note
                noteCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              __typename
              items {
                __typename
                id
                cameraEventId
                startTime
                vmsCameraName
                videoFeedUrl
                eventType
                disposition
                triggerType
                objectsFound
                incidentShareEntryId
                cameraLatitude
                cameraLongitude
                createdAt
                updatedAt
                actionItems {
                  __typename
                  items {
                    __typename
                    id
                    incidentProcedureTaskId
                    incidentShareEventId
                    order
                    completedAt
                    description
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            contactInformation {
              __typename
              items {
                __typename
                id
                incidentShareEntryId
                name
                phone
                ext
                email
                type
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (code) {
      gqlAPIServiceArguments.code = code;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareByCodeQuery>response.data.incidentShareByCode;
  }
  async IncidentShareByIncidentId(
    incidentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareEntryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareByIncidentIdQuery> {
    const statement = `query IncidentShareByIncidentId($incidentId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareEntryFilterInput, $limit: Int, $nextToken: String) {
        incidentShareByIncidentId(
          incidentId: $incidentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentDataToShareId
            incidentId
            code
            shareType
            expiration
            alarmsCentralStation
            alarmsPermitNum
            alarmsCSID
            attachmentsList
            incidentOpenTime
            incidentClosedTime
            externalSiteNotes
            externalClientNotes
            createdAt
            updatedAt
            site {
              __typename
              id
              name
              addressOne
              addressTwo
              city
              state
              country
              zipCode
              createdAt
              updatedAt
            }
            integrator {
              __typename
              id
              name
              logoPath
              createdAt
              updatedAt
            }
            notes {
              __typename
              items {
                __typename
                id
                incidentId
                incidentNoteId
                incidentShareEntryId
                note
                noteCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              __typename
              items {
                __typename
                id
                cameraEventId
                startTime
                vmsCameraName
                videoFeedUrl
                eventType
                disposition
                triggerType
                objectsFound
                incidentShareEntryId
                cameraLatitude
                cameraLongitude
                createdAt
                updatedAt
                actionItems {
                  __typename
                  items {
                    __typename
                    id
                    incidentProcedureTaskId
                    incidentShareEventId
                    order
                    completedAt
                    description
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            contactInformation {
              __typename
              items {
                __typename
                id
                incidentShareEntryId
                name
                phone
                ext
                email
                type
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentId) {
      gqlAPIServiceArguments.incidentId = incidentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareByIncidentIdQuery>(
      response.data.incidentShareByIncidentId
    );
  }
  async GetIncidentShareSite(id: string): Promise<GetIncidentShareSiteQuery> {
    const statement = `query GetIncidentShareSite($id: ID!) {
        getIncidentShareSite(id: $id) {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareSiteQuery>response.data.getIncidentShareSite;
  }
  async ListIncidentShareSites(
    filter?: ModelIncidentShareSiteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareSitesQuery> {
    const statement = `query ListIncidentShareSites($filter: ModelIncidentShareSiteFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareSitesQuery>response.data.listIncidentShareSites;
  }
  async GetIncidentShareIntegrator(
    id: string
  ): Promise<GetIncidentShareIntegratorQuery> {
    const statement = `query GetIncidentShareIntegrator($id: ID!) {
        getIncidentShareIntegrator(id: $id) {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareIntegratorQuery>(
      response.data.getIncidentShareIntegrator
    );
  }
  async ListIncidentShareIntegrators(
    filter?: ModelIncidentShareIntegratorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareIntegratorsQuery> {
    const statement = `query ListIncidentShareIntegrators($filter: ModelIncidentShareIntegratorFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareIntegrators(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareIntegratorsQuery>(
      response.data.listIncidentShareIntegrators
    );
  }
  async GetIncidentShareEntryAccess(
    id: string
  ): Promise<GetIncidentShareEntryAccessQuery> {
    const statement = `query GetIncidentShareEntryAccess($id: ID!) {
        getIncidentShareEntryAccess(id: $id) {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareEntryAccessQuery>(
      response.data.getIncidentShareEntryAccess
    );
  }
  async ListIncidentShareEntryAccesss(
    filter?: ModelIncidentShareEntryAccessFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareEntryAccesssQuery> {
    const statement = `query ListIncidentShareEntryAccesss($filter: ModelIncidentShareEntryAccessFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareEntryAccesss(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentShareEntryId
            incidentId
            code
            success
            accessDate
            ipAddress
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareEntryAccesssQuery>(
      response.data.listIncidentShareEntryAccesss
    );
  }
  async GetIncidentShareEntyNote(
    id: string
  ): Promise<GetIncidentShareEntyNoteQuery> {
    const statement = `query GetIncidentShareEntyNote($id: ID!) {
        getIncidentShareEntyNote(id: $id) {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareEntyNoteQuery>(
      response.data.getIncidentShareEntyNote
    );
  }
  async ListIncidentShareEntyNotes(
    filter?: ModelIncidentShareEntyNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareEntyNotesQuery> {
    const statement = `query ListIncidentShareEntyNotes($filter: ModelIncidentShareEntyNoteFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareEntyNotes(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            incidentNoteId
            incidentShareEntryId
            note
            noteCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareEntyNotesQuery>(
      response.data.listIncidentShareEntyNotes
    );
  }
  async IncidentShareEntyNotes(
    incidentShareEntryId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareEntyNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareEntyNotesQuery> {
    const statement = `query IncidentShareEntyNotes($incidentShareEntryId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareEntyNoteFilterInput, $limit: Int, $nextToken: String) {
        incidentShareEntyNotes(
          incidentShareEntryId: $incidentShareEntryId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            incidentNoteId
            incidentShareEntryId
            note
            noteCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentShareEntryId) {
      gqlAPIServiceArguments.incidentShareEntryId = incidentShareEntryId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareEntyNotesQuery>response.data.incidentShareEntyNotes;
  }
  async IncidentShareEntryNotesByNoteId(
    incidentNoteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareEntyNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareEntryNotesByNoteIdQuery> {
    const statement = `query IncidentShareEntryNotesByNoteId($incidentNoteId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareEntyNoteFilterInput, $limit: Int, $nextToken: String) {
        incidentShareEntryNotesByNoteId(
          incidentNoteId: $incidentNoteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentId
            incidentNoteId
            incidentShareEntryId
            note
            noteCreatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentNoteId) {
      gqlAPIServiceArguments.incidentNoteId = incidentNoteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareEntryNotesByNoteIdQuery>(
      response.data.incidentShareEntryNotesByNoteId
    );
  }
  async GetIncidentShareEvent(id: string): Promise<GetIncidentShareEventQuery> {
    const statement = `query GetIncidentShareEvent($id: ID!) {
        getIncidentShareEvent(id: $id) {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareEventQuery>response.data.getIncidentShareEvent;
  }
  async ListIncidentShareEvents(
    filter?: ModelIncidentShareEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareEventsQuery> {
    const statement = `query ListIncidentShareEvents($filter: ModelIncidentShareEventFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cameraEventId
            startTime
            vmsCameraName
            videoFeedUrl
            eventType
            disposition
            triggerType
            objectsFound
            incidentShareEntryId
            cameraLatitude
            cameraLongitude
            createdAt
            updatedAt
            actionItems {
              __typename
              items {
                __typename
                id
                incidentProcedureTaskId
                incidentShareEventId
                order
                completedAt
                description
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareEventsQuery>response.data.listIncidentShareEvents;
  }
  async IncidentShareEvents(
    incidentShareEntryId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareEventsQuery> {
    const statement = `query IncidentShareEvents($incidentShareEntryId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareEventFilterInput, $limit: Int, $nextToken: String) {
        incidentShareEvents(
          incidentShareEntryId: $incidentShareEntryId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraEventId
            startTime
            vmsCameraName
            videoFeedUrl
            eventType
            disposition
            triggerType
            objectsFound
            incidentShareEntryId
            cameraLatitude
            cameraLongitude
            createdAt
            updatedAt
            actionItems {
              __typename
              items {
                __typename
                id
                incidentProcedureTaskId
                incidentShareEventId
                order
                completedAt
                description
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentShareEntryId) {
      gqlAPIServiceArguments.incidentShareEntryId = incidentShareEntryId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareEventsQuery>response.data.incidentShareEvents;
  }
  async IncidentShareEventsByCameraEventId(
    cameraEventId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareEventsByCameraEventIdQuery> {
    const statement = `query IncidentShareEventsByCameraEventId($cameraEventId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareEventFilterInput, $limit: Int, $nextToken: String) {
        incidentShareEventsByCameraEventId(
          cameraEventId: $cameraEventId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            cameraEventId
            startTime
            vmsCameraName
            videoFeedUrl
            eventType
            disposition
            triggerType
            objectsFound
            incidentShareEntryId
            cameraLatitude
            cameraLongitude
            createdAt
            updatedAt
            actionItems {
              __typename
              items {
                __typename
                id
                incidentProcedureTaskId
                incidentShareEventId
                order
                completedAt
                description
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraEventId) {
      gqlAPIServiceArguments.cameraEventId = cameraEventId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareEventsByCameraEventIdQuery>(
      response.data.incidentShareEventsByCameraEventId
    );
  }
  async GetIncidentShareActionItems(
    id: string
  ): Promise<GetIncidentShareActionItemsQuery> {
    const statement = `query GetIncidentShareActionItems($id: ID!) {
        getIncidentShareActionItems(id: $id) {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareActionItemsQuery>(
      response.data.getIncidentShareActionItems
    );
  }
  async ListIncidentShareActionItemss(
    filter?: ModelIncidentShareActionItemsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareActionItemssQuery> {
    const statement = `query ListIncidentShareActionItemss($filter: ModelIncidentShareActionItemsFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareActionItemss(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentProcedureTaskId
            incidentShareEventId
            order
            completedAt
            description
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareActionItemssQuery>(
      response.data.listIncidentShareActionItemss
    );
  }
  async IncidentShareActionItems(
    incidentShareEventId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareActionItemsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareActionItemsQuery> {
    const statement = `query IncidentShareActionItems($incidentShareEventId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareActionItemsFilterInput, $limit: Int, $nextToken: String) {
        incidentShareActionItems(
          incidentShareEventId: $incidentShareEventId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentProcedureTaskId
            incidentShareEventId
            order
            completedAt
            description
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentShareEventId) {
      gqlAPIServiceArguments.incidentShareEventId = incidentShareEventId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareActionItemsQuery>(
      response.data.incidentShareActionItems
    );
  }
  async IncidentShareActionItemsByProcId(
    incidentProcedureTaskId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareActionItemsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareActionItemsByProcIdQuery> {
    const statement = `query IncidentShareActionItemsByProcId($incidentProcedureTaskId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareActionItemsFilterInput, $limit: Int, $nextToken: String) {
        incidentShareActionItemsByProcId(
          incidentProcedureTaskId: $incidentProcedureTaskId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentProcedureTaskId
            incidentShareEventId
            order
            completedAt
            description
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentProcedureTaskId) {
      gqlAPIServiceArguments.incidentProcedureTaskId = incidentProcedureTaskId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareActionItemsByProcIdQuery>(
      response.data.incidentShareActionItemsByProcId
    );
  }
  async GetIncidentShareContactInformation(
    id: string
  ): Promise<GetIncidentShareContactInformationQuery> {
    const statement = `query GetIncidentShareContactInformation($id: ID!) {
        getIncidentShareContactInformation(id: $id) {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIncidentShareContactInformationQuery>(
      response.data.getIncidentShareContactInformation
    );
  }
  async ListIncidentShareContactInformations(
    filter?: ModelIncidentShareContactInformationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIncidentShareContactInformationsQuery> {
    const statement = `query ListIncidentShareContactInformations($filter: ModelIncidentShareContactInformationFilterInput, $limit: Int, $nextToken: String) {
        listIncidentShareContactInformations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentShareEntryId
            name
            phone
            ext
            email
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIncidentShareContactInformationsQuery>(
      response.data.listIncidentShareContactInformations
    );
  }
  async IncidentShareContactInfo(
    incidentShareEntryId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIncidentShareContactInformationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IncidentShareContactInfoQuery> {
    const statement = `query IncidentShareContactInfo($incidentShareEntryId: ID, $sortDirection: ModelSortDirection, $filter: ModelIncidentShareContactInformationFilterInput, $limit: Int, $nextToken: String) {
        incidentShareContactInfo(
          incidentShareEntryId: $incidentShareEntryId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            incidentShareEntryId
            name
            phone
            ext
            email
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (incidentShareEntryId) {
      gqlAPIServiceArguments.incidentShareEntryId = incidentShareEntryId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncidentShareContactInfoQuery>(
      response.data.incidentShareContactInfo
    );
  }
  async GetCallNotice(id: string): Promise<GetCallNoticeQuery> {
    const statement = `query GetCallNotice($id: ID!) {
        getCallNotice(id: $id) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallNoticeQuery>response.data.getCallNotice;
  }
  async ListCallNotices(
    filter?: ModelCallNoticeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallNoticesQuery> {
    const statement = `query ListCallNotices($filter: ModelCallNoticeFilterInput, $limit: Int, $nextToken: String) {
        listCallNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            fromNumber
            toNumber
            monitoringCenterId
            createdAt
            answeredTime
            answeredBy
            twilioCallSid
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallNoticesQuery>response.data.listCallNotices;
  }
  async CallNoticeByMc(
    monitoringCenterId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallNoticeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallNoticeByMcQuery> {
    const statement = `query CallNoticeByMc($monitoringCenterId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallNoticeFilterInput, $limit: Int, $nextToken: String) {
        callNoticeByMc(
          monitoringCenterId: $monitoringCenterId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            fromNumber
            toNumber
            monitoringCenterId
            createdAt
            answeredTime
            answeredBy
            twilioCallSid
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallNoticeByMcQuery>response.data.callNoticeByMc;
  }
  async GetCallLog(id: string): Promise<GetCallLogQuery> {
    const statement = `query GetCallLog($id: ID!) {
        getCallLog(id: $id) {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCallLogQuery>response.data.getCallLog;
  }
  async ListCallLogs(
    filter?: ModelCallLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCallLogsQuery> {
    const statement = `query ListCallLogs($filter: ModelCallLogFilterInput, $limit: Int, $nextToken: String) {
        listCallLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            phoneNumber
            monitoringCenterId
            customerId
            siteId
            incidentId
            callEnd
            storageAudioUrl
            storageTranscriptUrl
            createdAt
            callingUser
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCallLogsQuery>response.data.listCallLogs;
  }
  async CallsByMonitoringCenter(
    monitoringCenterId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCallLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CallsByMonitoringCenterQuery> {
    const statement = `query CallsByMonitoringCenter($monitoringCenterId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCallLogFilterInput, $limit: Int, $nextToken: String) {
        callsByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            phoneNumber
            monitoringCenterId
            customerId
            siteId
            incidentId
            callEnd
            storageAudioUrl
            storageTranscriptUrl
            createdAt
            callingUser
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CallsByMonitoringCenterQuery>response.data.callsByMonitoringCenter;
  }
  async GetSMSLog(id: string): Promise<GetSMSLogQuery> {
    const statement = `query GetSMSLog($id: ID!) {
        getSMSLog(id: $id) {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSMSLogQuery>response.data.getSMSLog;
  }
  async ListSMSLogs(
    filter?: ModelSMSLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSMSLogsQuery> {
    const statement = `query ListSMSLogs($filter: ModelSMSLogFilterInput, $limit: Int, $nextToken: String) {
        listSMSLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            twilioSid
            toNumber
            fromNumber
            monitoringCenterId
            customerId
            siteId
            incidentId
            message
            createdAt
            textingUser
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSMSLogsQuery>response.data.listSMSLogs;
  }
  async SmsByMonitoringCenter(
    monitoringCenterId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSMSLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SmsByMonitoringCenterQuery> {
    const statement = `query SmsByMonitoringCenter($monitoringCenterId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSMSLogFilterInput, $limit: Int, $nextToken: String) {
        smsByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            twilioSid
            toNumber
            fromNumber
            monitoringCenterId
            customerId
            siteId
            incidentId
            message
            createdAt
            textingUser
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SmsByMonitoringCenterQuery>response.data.smsByMonitoringCenter;
  }
  async GetEmailLog(id: string): Promise<GetEmailLogQuery> {
    const statement = `query GetEmailLog($id: ID!) {
        getEmailLog(id: $id) {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmailLogQuery>response.data.getEmailLog;
  }
  async ListEmailLogs(
    filter?: ModelEmailLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEmailLogsQuery> {
    const statement = `query ListEmailLogs($filter: ModelEmailLogFilterInput, $limit: Int, $nextToken: String) {
        listEmailLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            awsMessageId
            toEmail
            fromEmail
            monitoringCenterId
            customerId
            siteId
            incidentId
            subject
            createdAt
            sendingUser
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmailLogsQuery>response.data.listEmailLogs;
  }
  async EmailByMonitoringCenter(
    monitoringCenterId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEmailLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EmailByMonitoringCenterQuery> {
    const statement = `query EmailByMonitoringCenter($monitoringCenterId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEmailLogFilterInput, $limit: Int, $nextToken: String) {
        emailByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            awsMessageId
            toEmail
            fromEmail
            monitoringCenterId
            customerId
            siteId
            incidentId
            subject
            createdAt
            sendingUser
            modifiedBy
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EmailByMonitoringCenterQuery>response.data.emailByMonitoringCenter;
  }
  async GetNotificationSetting(
    id: string
  ): Promise<GetNotificationSettingQuery> {
    const statement = `query GetNotificationSetting($id: ID!) {
        getNotificationSetting(id: $id) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationSettingQuery>response.data.getNotificationSetting;
  }
  async ListNotificationSettings(
    filter?: ModelNotificationSettingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotificationSettingsQuery> {
    const statement = `query ListNotificationSettings($filter: ModelNotificationSettingFilterInput, $limit: Int, $nextToken: String) {
        listNotificationSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            monitoringCenterId
            notificationType
            userGroup
            clientList
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationSettingsQuery>(
      response.data.listNotificationSettings
    );
  }
  async NotifSettingsByMCType(
    monitoringCenterId?: string,
    notificationType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelNotificationSettingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<NotifSettingsByMCTypeQuery> {
    const statement = `query NotifSettingsByMCType($monitoringCenterId: String, $notificationType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelNotificationSettingFilterInput, $limit: Int, $nextToken: String) {
        notifSettingsByMCType(
          monitoringCenterId: $monitoringCenterId
          notificationType: $notificationType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            username
            monitoringCenterId
            notificationType
            userGroup
            clientList
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (notificationType) {
      gqlAPIServiceArguments.notificationType = notificationType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifSettingsByMCTypeQuery>response.data.notifSettingsByMCType;
  }
  async NotifSettingsByUser(
    username?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelNotificationSettingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<NotifSettingsByUserQuery> {
    const statement = `query NotifSettingsByUser($username: String, $sortDirection: ModelSortDirection, $filter: ModelNotificationSettingFilterInput, $limit: Int, $nextToken: String) {
        notifSettingsByUser(
          username: $username
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            username
            monitoringCenterId
            notificationType
            userGroup
            clientList
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifSettingsByUserQuery>response.data.notifSettingsByUser;
  }
  async GetNotification(id: string): Promise<GetNotificationQuery> {
    const statement = `query GetNotification($id: ID!) {
        getNotification(id: $id) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationQuery>response.data.getNotification;
  }
  async ListNotifications(
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotificationsQuery> {
    const statement = `query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            username
            monitoringCenterId
            notificationType
            status
            createdAt
            modifiedBy
            url
            note
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationsQuery>response.data.listNotifications;
  }
  async NotifByUser(
    username?: string,
    statusCreatedAt?: ModelNotificationNotifByUserCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<NotifByUserQuery> {
    const statement = `query NotifByUser($username: String, $statusCreatedAt: ModelNotificationNotifByUserCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        notifByUser(
          username: $username
          statusCreatedAt: $statusCreatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            username
            monitoringCenterId
            notificationType
            status
            createdAt
            modifiedBy
            url
            note
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (statusCreatedAt) {
      gqlAPIServiceArguments.statusCreatedAt = statusCreatedAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <NotifByUserQuery>response.data.notifByUser;
  }
  async GetUser(username: string): Promise<GetUserQuery> {
    const statement = `query GetUser($username: String!) {
        getUser(username: $username) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    username?: string,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($username: String, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listUsers(
          username: $username
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            username
            monitoringCenterId
            vmsOperatorUserIds
            vmsOperatorUserIdAutomatedRole
            vmsAdminUserIds
            modifiedBy
            group
            clientIdList
            enabled
            phone
            email
            name
            integratorId
            autoAssign
            preferredLanguage
            gridEnabled
            gridBehavior
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetUserVMSDetails(id: string): Promise<GetUserVMSDetailsQuery> {
    const statement = `query GetUserVMSDetails($id: ID!) {
        getUserVMSDetails(id: $id) {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserVMSDetailsQuery>response.data.getUserVMSDetails;
  }
  async ListUserVMSDetailss(
    filter?: ModelUserVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserVMSDetailssQuery> {
    const statement = `query ListUserVMSDetailss($filter: ModelUserVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        listUserVMSDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vmsUserId
            profileName
            cognitoUsernames
            authEmail
            vmsUsername
            vmsPassword
            vmsUserType
            apiKey
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserVMSDetailssQuery>response.data.listUserVMSDetailss;
  }
  async UserVMSDetailsByEmail(
    authEmail?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserVMSDetailsByEmailQuery> {
    const statement = `query UserVMSDetailsByEmail($authEmail: String, $sortDirection: ModelSortDirection, $filter: ModelUserVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        userVMSDetailsByEmail(
          authEmail: $authEmail
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsUserId
            profileName
            cognitoUsernames
            authEmail
            vmsUsername
            vmsPassword
            vmsUserType
            apiKey
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authEmail) {
      gqlAPIServiceArguments.authEmail = authEmail;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserVMSDetailsByEmailQuery>response.data.userVMSDetailsByEmail;
  }
  async UserVMSDetailsByVMSUserId(
    vmsUserId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserVMSDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserVMSDetailsByVMSUserIdQuery> {
    const statement = `query UserVMSDetailsByVMSUserId($vmsUserId: ID, $sortDirection: ModelSortDirection, $filter: ModelUserVMSDetailsFilterInput, $limit: Int, $nextToken: String) {
        userVMSDetailsByVMSUserId(
          vmsUserId: $vmsUserId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vmsUserId
            profileName
            cognitoUsernames
            authEmail
            vmsUsername
            vmsPassword
            vmsUserType
            apiKey
            accessToken
            refreshToken
            accessTokenExpires
            refreshTokenExpires
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vmsUserId) {
      gqlAPIServiceArguments.vmsUserId = vmsUserId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserVMSDetailsByVMSUserIdQuery>(
      response.data.userVMSDetailsByVMSUserId
    );
  }
  async GetCustomerUserPermissionSet(
    username: string
  ): Promise<GetCustomerUserPermissionSetQuery> {
    const statement = `query GetCustomerUserPermissionSet($username: String!) {
        getCustomerUserPermissionSet(username: $username) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerUserPermissionSetQuery>(
      response.data.getCustomerUserPermissionSet
    );
  }
  async ListCustomerUserPermissionSets(
    username?: string,
    filter?: ModelCustomerUserPermissionSetFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCustomerUserPermissionSetsQuery> {
    const statement = `query ListCustomerUserPermissionSets($username: String, $filter: ModelCustomerUserPermissionSetFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCustomerUserPermissionSets(
          username: $username
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerUserPermissionSetsQuery>(
      response.data.listCustomerUserPermissionSets
    );
  }
  async GetCustomerUserPermission(
    username: string,
    customerId: string
  ): Promise<GetCustomerUserPermissionQuery> {
    const statement = `query GetCustomerUserPermission($username: String!, $customerId: ID!) {
        getCustomerUserPermission(username: $username, customerId: $customerId) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      username,
      customerId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerUserPermissionQuery>(
      response.data.getCustomerUserPermission
    );
  }
  async ListCustomerUserPermissions(
    username?: string,
    customerId?: ModelIDKeyConditionInput,
    filter?: ModelCustomerUserPermissionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCustomerUserPermissionsQuery> {
    const statement = `query ListCustomerUserPermissions($username: String, $customerId: ModelIDKeyConditionInput, $filter: ModelCustomerUserPermissionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCustomerUserPermissions(
          username: $username
          customerId: $customerId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            username
            email
            monitoringCenterId
            customerId
            sitePermissions
            archived
            modifiedBy
            createdAt
            updatedAt
            permissionSet {
              __typename
              username
              email
              enabled
              modifiedBy
              ownerCustomerId
              createdAt
              updatedAt
              permissions {
                __typename
                items {
                  __typename
                  username
                  email
                  monitoringCenterId
                  customerId
                  sitePermissions
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  permissionSet {
                    __typename
                    username
                    email
                    enabled
                    modifiedBy
                    ownerCustomerId
                    createdAt
                    updatedAt
                    permissions {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerUserPermissionsQuery>(
      response.data.listCustomerUserPermissions
    );
  }
  async UserPermissionByUsernameCustomerId(
    username?: string,
    customerId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerUserPermissionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserPermissionByUsernameCustomerIdQuery> {
    const statement = `query UserPermissionByUsernameCustomerId($username: String, $customerId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCustomerUserPermissionFilterInput, $limit: Int, $nextToken: String) {
        userPermissionByUsernameCustomerId(
          username: $username
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            username
            email
            monitoringCenterId
            customerId
            sitePermissions
            archived
            modifiedBy
            createdAt
            updatedAt
            permissionSet {
              __typename
              username
              email
              enabled
              modifiedBy
              ownerCustomerId
              createdAt
              updatedAt
              permissions {
                __typename
                items {
                  __typename
                  username
                  email
                  monitoringCenterId
                  customerId
                  sitePermissions
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  permissionSet {
                    __typename
                    username
                    email
                    enabled
                    modifiedBy
                    ownerCustomerId
                    createdAt
                    updatedAt
                    permissions {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserPermissionByUsernameCustomerIdQuery>(
      response.data.userPermissionByUsernameCustomerId
    );
  }
  async UserPermissionByCustomerId(
    customerId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerUserPermissionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserPermissionByCustomerIdQuery> {
    const statement = `query UserPermissionByCustomerId($customerId: ID, $sortDirection: ModelSortDirection, $filter: ModelCustomerUserPermissionFilterInput, $limit: Int, $nextToken: String) {
        userPermissionByCustomerId(
          customerId: $customerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            username
            email
            monitoringCenterId
            customerId
            sitePermissions
            archived
            modifiedBy
            createdAt
            updatedAt
            permissionSet {
              __typename
              username
              email
              enabled
              modifiedBy
              ownerCustomerId
              createdAt
              updatedAt
              permissions {
                __typename
                items {
                  __typename
                  username
                  email
                  monitoringCenterId
                  customerId
                  sitePermissions
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  permissionSet {
                    __typename
                    username
                    email
                    enabled
                    modifiedBy
                    ownerCustomerId
                    createdAt
                    updatedAt
                    permissions {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserPermissionByCustomerIdQuery>(
      response.data.userPermissionByCustomerId
    );
  }
  async GetTax(id: string): Promise<GetTaxQuery> {
    const statement = `query GetTax($id: ID!) {
        getTax(id: $id) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxQuery>response.data.getTax;
  }
  async ListTaxs(
    filter?: ModelTaxFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaxsQuery> {
    const statement = `query ListTaxs($filter: ModelTaxFilterInput, $limit: Int, $nextToken: String) {
        listTaxs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxsQuery>response.data.listTaxs;
  }
  async TaxByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaxFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaxByMonitoringCenterQuery> {
    const statement = `query TaxByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelTaxFilterInput, $limit: Int, $nextToken: String) {
        taxByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaxByMonitoringCenterQuery>response.data.taxByMonitoringCenter;
  }
  async TaxByMonitoringCenterRegion(
    monitoringCenterId?: string,
    region?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaxFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaxByMonitoringCenterRegionQuery> {
    const statement = `query TaxByMonitoringCenterRegion($monitoringCenterId: ID, $region: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTaxFilterInput, $limit: Int, $nextToken: String) {
        taxByMonitoringCenterRegion(
          monitoringCenterId: $monitoringCenterId
          region: $region
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (region) {
      gqlAPIServiceArguments.region = region;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaxByMonitoringCenterRegionQuery>(
      response.data.taxByMonitoringCenterRegion
    );
  }
  async GetTaxType(id: string): Promise<GetTaxTypeQuery> {
    const statement = `query GetTaxType($id: ID!) {
        getTaxType(id: $id) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxTypeQuery>response.data.getTaxType;
  }
  async ListTaxTypes(
    filter?: ModelTaxTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaxTypesQuery> {
    const statement = `query ListTaxTypes($filter: ModelTaxTypeFilterInput, $limit: Int, $nextToken: String) {
        listTaxTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxTypesQuery>response.data.listTaxTypes;
  }
  async TaxTypeByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaxTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaxTypeByMonitoringCenterQuery> {
    const statement = `query TaxTypeByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelTaxTypeFilterInput, $limit: Int, $nextToken: String) {
        taxTypeByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaxTypeByMonitoringCenterQuery>(
      response.data.taxTypeByMonitoringCenter
    );
  }
  async TaxTypesByIntMC(
    integratorId?: string,
    monitoringCenterId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaxTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaxTypesByIntMCQuery> {
    const statement = `query TaxTypesByIntMC($integratorId: ID, $monitoringCenterId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTaxTypeFilterInput, $limit: Int, $nextToken: String) {
        taxTypesByIntMC(
          integratorId: $integratorId
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaxTypesByIntMCQuery>response.data.taxTypesByIntMC;
  }
  async GetSetupFee(id: string): Promise<GetSetupFeeQuery> {
    const statement = `query GetSetupFee($id: ID!) {
        getSetupFee(id: $id) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSetupFeeQuery>response.data.getSetupFee;
  }
  async ListSetupFees(
    filter?: ModelSetupFeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSetupFeesQuery> {
    const statement = `query ListSetupFees($filter: ModelSetupFeeFilterInput, $limit: Int, $nextToken: String) {
        listSetupFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            taxTypeId
            amount
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSetupFeesQuery>response.data.listSetupFees;
  }
  async SetupFeesByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSetupFeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SetupFeesByMonitoringCenterQuery> {
    const statement = `query SetupFeesByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelSetupFeeFilterInput, $limit: Int, $nextToken: String) {
        setupFeesByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            taxTypeId
            amount
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SetupFeesByMonitoringCenterQuery>(
      response.data.setupFeesByMonitoringCenter
    );
  }
  async SetupFeesByIntMC(
    integratorId?: string,
    monitoringCenterId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSetupFeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SetupFeesByIntMCQuery> {
    const statement = `query SetupFeesByIntMC($integratorId: ID, $monitoringCenterId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSetupFeeFilterInput, $limit: Int, $nextToken: String) {
        setupFeesByIntMC(
          integratorId: $integratorId
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            taxTypeId
            amount
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SetupFeesByIntMCQuery>response.data.setupFeesByIntMC;
  }
  async GetCameraPlan(id: string): Promise<GetCameraPlanQuery> {
    const statement = `query GetCameraPlan($id: ID!) {
        getCameraPlan(id: $id) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCameraPlanQuery>response.data.getCameraPlan;
  }
  async ListCameraPlans(
    filter?: ModelCameraPlanFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCameraPlansQuery> {
    const statement = `query ListCameraPlans($filter: ModelCameraPlanFilterInput, $limit: Int, $nextToken: String) {
        listCameraPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            parentId
            analyticsType
            resolution
            bitRate
            operatorDuration
            supervision
            storageDays
            cameraPlanProductId
            cameraPlanLivePatrolId
            cameraPlanOperatorMinutesId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            product {
              __typename
              id
              cameraPlanId
              name
              nameForInvoice
              description
              productTaxTypeId
              minPrice
              mrp
              cost
              price
              fxPrices {
                __typename
                currency
                price
              }
              status
              type
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              integratorId
              modifiedBy
              archived
              publishedFor
              publishedForArchived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            livePatrol {
              __typename
              id
              cameraPlanId
              enabled
              minPrice
              mrp
              cost
              price
              fxPrices {
                __typename
                currency
                price
              }
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            operatorMinutes {
              __typename
              id
              cameraPlanId
              minPrice
              mrp
              cost
              price
              fxPrices {
                __typename
                currency
                price
              }
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCameraPlansQuery>response.data.listCameraPlans;
  }
  async CameraPlanByIntMC(
    integratorId?: string,
    monitoringCenterId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCameraPlanFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CameraPlanByIntMCQuery> {
    const statement = `query CameraPlanByIntMC($integratorId: ID, $monitoringCenterId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCameraPlanFilterInput, $limit: Int, $nextToken: String) {
        cameraPlanByIntMC(
          integratorId: $integratorId
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            parentId
            analyticsType
            resolution
            bitRate
            operatorDuration
            supervision
            storageDays
            cameraPlanProductId
            cameraPlanLivePatrolId
            cameraPlanOperatorMinutesId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            product {
              __typename
              id
              cameraPlanId
              name
              nameForInvoice
              description
              productTaxTypeId
              minPrice
              mrp
              cost
              price
              fxPrices {
                __typename
                currency
                price
              }
              status
              type
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              integratorId
              modifiedBy
              archived
              publishedFor
              publishedForArchived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            livePatrol {
              __typename
              id
              cameraPlanId
              enabled
              minPrice
              mrp
              cost
              price
              fxPrices {
                __typename
                currency
                price
              }
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            operatorMinutes {
              __typename
              id
              cameraPlanId
              minPrice
              mrp
              cost
              price
              fxPrices {
                __typename
                currency
                price
              }
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CameraPlanByIntMCQuery>response.data.cameraPlanByIntMC;
  }
  async GetSiteSubscription(id: string): Promise<GetSiteSubscriptionQuery> {
    const statement = `query GetSiteSubscription($id: ID!) {
        getSiteSubscription(id: $id) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSiteSubscriptionQuery>response.data.getSiteSubscription;
  }
  async ListSiteSubscriptions(
    filter?: ModelSiteSubscriptionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSiteSubscriptionsQuery> {
    const statement = `query ListSiteSubscriptions($filter: ModelSiteSubscriptionFilterInput, $limit: Int, $nextToken: String) {
        listSiteSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            siteId
            customerId
            status
            onHold
            startDate
            term
            renewalDate
            renewalTerm
            merchant
            billingDay
            billingFrequency
            nextBillingDay
            autorefillOn
            autorefillAmount
            minimumBalance
            currentBalance
            currentBalanceUsd
            maxNumberRecharges
            currentDayRechargeCount
            lastRefilled
            currency
            usedOperatorMinutes
            totalOperatorMinutes
            transactionsLastCalculated
            secondsPerInteraction
            incidentBillingDisabled
            interactionBillingDisabled
            livePatrolBillingDisabled
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            mcIntAdminAccess
            integratorId
            modifiedBy
            archived
            billingEmails
            billingEntityName
            commissionBilling
            createdAt
            updatedAt
            stripeSubscription {
              __typename
              id
              archived
              modifiedBy
              siteSubscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              createdAt
              updatedAt
              paymentMethods {
                __typename
                items {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            cameraPlans {
              __typename
              items {
                __typename
                id
                parentId
                cameraId
                subscriptionId
                analyticsType
                resolution
                bitRate
                operatorDuration
                supervision
                storageDays
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                product {
                  __typename
                  id
                  appliedCameraPlanId
                  name
                  nameForInvoice
                  description
                  appliedProductTaxTypeId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                livePatrol {
                  __typename
                  id
                  appliedCameraPlanId
                  enabled
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                operatorMinutes {
                  __typename
                  id
                  appliedCameraPlanId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            setupFees {
              __typename
              items {
                __typename
                id
                parentId
                subscriptionId
                amount
                name
                currency
                integratorId
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSiteSubscriptionsQuery>response.data.listSiteSubscriptions;
  }
  async SubscriptionByMonitoringCenter(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteSubscriptionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubscriptionByMonitoringCenterQuery> {
    const statement = `query SubscriptionByMonitoringCenter($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteSubscriptionFilterInput, $limit: Int, $nextToken: String) {
        subscriptionByMonitoringCenter(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            customerId
            status
            onHold
            startDate
            term
            renewalDate
            renewalTerm
            merchant
            billingDay
            billingFrequency
            nextBillingDay
            autorefillOn
            autorefillAmount
            minimumBalance
            currentBalance
            currentBalanceUsd
            maxNumberRecharges
            currentDayRechargeCount
            lastRefilled
            currency
            usedOperatorMinutes
            totalOperatorMinutes
            transactionsLastCalculated
            secondsPerInteraction
            incidentBillingDisabled
            interactionBillingDisabled
            livePatrolBillingDisabled
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            mcIntAdminAccess
            integratorId
            modifiedBy
            archived
            billingEmails
            billingEntityName
            commissionBilling
            createdAt
            updatedAt
            stripeSubscription {
              __typename
              id
              archived
              modifiedBy
              siteSubscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              createdAt
              updatedAt
              paymentMethods {
                __typename
                items {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            cameraPlans {
              __typename
              items {
                __typename
                id
                parentId
                cameraId
                subscriptionId
                analyticsType
                resolution
                bitRate
                operatorDuration
                supervision
                storageDays
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                product {
                  __typename
                  id
                  appliedCameraPlanId
                  name
                  nameForInvoice
                  description
                  appliedProductTaxTypeId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                livePatrol {
                  __typename
                  id
                  appliedCameraPlanId
                  enabled
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                operatorMinutes {
                  __typename
                  id
                  appliedCameraPlanId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            setupFees {
              __typename
              items {
                __typename
                id
                parentId
                subscriptionId
                amount
                name
                currency
                integratorId
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubscriptionByMonitoringCenterQuery>(
      response.data.subscriptionByMonitoringCenter
    );
  }
  async SubscriptionBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteSubscriptionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubscriptionBySiteQuery> {
    const statement = `query SubscriptionBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSiteSubscriptionFilterInput, $limit: Int, $nextToken: String) {
        subscriptionBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            customerId
            status
            onHold
            startDate
            term
            renewalDate
            renewalTerm
            merchant
            billingDay
            billingFrequency
            nextBillingDay
            autorefillOn
            autorefillAmount
            minimumBalance
            currentBalance
            currentBalanceUsd
            maxNumberRecharges
            currentDayRechargeCount
            lastRefilled
            currency
            usedOperatorMinutes
            totalOperatorMinutes
            transactionsLastCalculated
            secondsPerInteraction
            incidentBillingDisabled
            interactionBillingDisabled
            livePatrolBillingDisabled
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            mcIntAdminAccess
            integratorId
            modifiedBy
            archived
            billingEmails
            billingEntityName
            commissionBilling
            createdAt
            updatedAt
            stripeSubscription {
              __typename
              id
              archived
              modifiedBy
              siteSubscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              createdAt
              updatedAt
              paymentMethods {
                __typename
                items {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            cameraPlans {
              __typename
              items {
                __typename
                id
                parentId
                cameraId
                subscriptionId
                analyticsType
                resolution
                bitRate
                operatorDuration
                supervision
                storageDays
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                product {
                  __typename
                  id
                  appliedCameraPlanId
                  name
                  nameForInvoice
                  description
                  appliedProductTaxTypeId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                livePatrol {
                  __typename
                  id
                  appliedCameraPlanId
                  enabled
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                operatorMinutes {
                  __typename
                  id
                  appliedCameraPlanId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            setupFees {
              __typename
              items {
                __typename
                id
                parentId
                subscriptionId
                amount
                name
                currency
                integratorId
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubscriptionBySiteQuery>response.data.subscriptionBySite;
  }
  async SubscriptionsByStatus(
    status?: ActiveInactive,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteSubscriptionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SubscriptionsByStatusQuery> {
    const statement = `query SubscriptionsByStatus($status: ActiveInactive, $sortDirection: ModelSortDirection, $filter: ModelSiteSubscriptionFilterInput, $limit: Int, $nextToken: String) {
        subscriptionsByStatus(
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            customerId
            status
            onHold
            startDate
            term
            renewalDate
            renewalTerm
            merchant
            billingDay
            billingFrequency
            nextBillingDay
            autorefillOn
            autorefillAmount
            minimumBalance
            currentBalance
            currentBalanceUsd
            maxNumberRecharges
            currentDayRechargeCount
            lastRefilled
            currency
            usedOperatorMinutes
            totalOperatorMinutes
            transactionsLastCalculated
            secondsPerInteraction
            incidentBillingDisabled
            interactionBillingDisabled
            livePatrolBillingDisabled
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            mcIntAdminAccess
            integratorId
            modifiedBy
            archived
            billingEmails
            billingEntityName
            commissionBilling
            createdAt
            updatedAt
            stripeSubscription {
              __typename
              id
              archived
              modifiedBy
              siteSubscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              createdAt
              updatedAt
              paymentMethods {
                __typename
                items {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            cameraPlans {
              __typename
              items {
                __typename
                id
                parentId
                cameraId
                subscriptionId
                analyticsType
                resolution
                bitRate
                operatorDuration
                supervision
                storageDays
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                product {
                  __typename
                  id
                  appliedCameraPlanId
                  name
                  nameForInvoice
                  description
                  appliedProductTaxTypeId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                livePatrol {
                  __typename
                  id
                  appliedCameraPlanId
                  enabled
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                operatorMinutes {
                  __typename
                  id
                  appliedCameraPlanId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            setupFees {
              __typename
              items {
                __typename
                id
                parentId
                subscriptionId
                amount
                name
                currency
                integratorId
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubscriptionsByStatusQuery>response.data.subscriptionsByStatus;
  }
  async SiteSubscriptionsByStatusAndCreatedAt(
    status?: ActiveInactive,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSiteSubscriptionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SiteSubscriptionsByStatusAndCreatedAtQuery> {
    const statement = `query SiteSubscriptionsByStatusAndCreatedAt($status: ActiveInactive, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSiteSubscriptionFilterInput, $limit: Int, $nextToken: String) {
        siteSubscriptionsByStatusAndCreatedAt(
          status: $status
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            siteId
            customerId
            status
            onHold
            startDate
            term
            renewalDate
            renewalTerm
            merchant
            billingDay
            billingFrequency
            nextBillingDay
            autorefillOn
            autorefillAmount
            minimumBalance
            currentBalance
            currentBalanceUsd
            maxNumberRecharges
            currentDayRechargeCount
            lastRefilled
            currency
            usedOperatorMinutes
            totalOperatorMinutes
            transactionsLastCalculated
            secondsPerInteraction
            incidentBillingDisabled
            interactionBillingDisabled
            livePatrolBillingDisabled
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            mcIntAdminAccess
            integratorId
            modifiedBy
            archived
            billingEmails
            billingEntityName
            commissionBilling
            createdAt
            updatedAt
            stripeSubscription {
              __typename
              id
              archived
              modifiedBy
              siteSubscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              createdAt
              updatedAt
              paymentMethods {
                __typename
                items {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            cameraPlans {
              __typename
              items {
                __typename
                id
                parentId
                cameraId
                subscriptionId
                analyticsType
                resolution
                bitRate
                operatorDuration
                supervision
                storageDays
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                product {
                  __typename
                  id
                  appliedCameraPlanId
                  name
                  nameForInvoice
                  description
                  appliedProductTaxTypeId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                livePatrol {
                  __typename
                  id
                  appliedCameraPlanId
                  enabled
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                operatorMinutes {
                  __typename
                  id
                  appliedCameraPlanId
                  price
                  currency
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            setupFees {
              __typename
              items {
                __typename
                id
                parentId
                subscriptionId
                amount
                name
                currency
                integratorId
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SiteSubscriptionsByStatusAndCreatedAtQuery>(
      response.data.siteSubscriptionsByStatusAndCreatedAt
    );
  }
  async GetSubscriptionStripeDetail(
    id: string
  ): Promise<GetSubscriptionStripeDetailQuery> {
    const statement = `query GetSubscriptionStripeDetail($id: ID!) {
        getSubscriptionStripeDetail(id: $id) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSubscriptionStripeDetailQuery>(
      response.data.getSubscriptionStripeDetail
    );
  }
  async ListSubscriptionStripeDetails(
    filter?: ModelSubscriptionStripeDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSubscriptionStripeDetailsQuery> {
    const statement = `query ListSubscriptionStripeDetails($filter: ModelSubscriptionStripeDetailFilterInput, $limit: Int, $nextToken: String) {
        listSubscriptionStripeDetails(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSubscriptionStripeDetailsQuery>(
      response.data.listSubscriptionStripeDetails
    );
  }
  async StripeDetailBySite(
    siteId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubscriptionStripeDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StripeDetailBySiteQuery> {
    const statement = `query StripeDetailBySite($siteId: ID, $sortDirection: ModelSortDirection, $filter: ModelSubscriptionStripeDetailFilterInput, $limit: Int, $nextToken: String) {
        stripeDetailBySite(
          siteId: $siteId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StripeDetailBySiteQuery>response.data.stripeDetailBySite;
  }
  async StripeDetailBySubscription(
    siteSubscriptionId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSubscriptionStripeDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StripeDetailBySubscriptionQuery> {
    const statement = `query StripeDetailBySubscription($siteSubscriptionId: ID, $sortDirection: ModelSortDirection, $filter: ModelSubscriptionStripeDetailFilterInput, $limit: Int, $nextToken: String) {
        stripeDetailBySubscription(
          siteSubscriptionId: $siteSubscriptionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteSubscriptionId) {
      gqlAPIServiceArguments.siteSubscriptionId = siteSubscriptionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StripeDetailBySubscriptionQuery>(
      response.data.stripeDetailBySubscription
    );
  }
  async GetStripePaymentMethod(
    id: string
  ): Promise<GetStripePaymentMethodQuery> {
    const statement = `query GetStripePaymentMethod($id: ID!) {
        getStripePaymentMethod(id: $id) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStripePaymentMethodQuery>response.data.getStripePaymentMethod;
  }
  async ListStripePaymentMethods(
    filter?: ModelStripePaymentMethodFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListStripePaymentMethodsQuery> {
    const statement = `query ListStripePaymentMethods($filter: ModelStripePaymentMethodFilterInput, $limit: Int, $nextToken: String) {
        listStripePaymentMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStripePaymentMethodsQuery>(
      response.data.listStripePaymentMethods
    );
  }
  async PaymentDetailByStripeId(
    subscriptionStripeDetailId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelStripePaymentMethodFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PaymentDetailByStripeIdQuery> {
    const statement = `query PaymentDetailByStripeId($subscriptionStripeDetailId: ID, $sortDirection: ModelSortDirection, $filter: ModelStripePaymentMethodFilterInput, $limit: Int, $nextToken: String) {
        paymentDetailByStripeId(
          subscriptionStripeDetailId: $subscriptionStripeDetailId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (subscriptionStripeDetailId) {
      gqlAPIServiceArguments.subscriptionStripeDetailId = subscriptionStripeDetailId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentDetailByStripeIdQuery>response.data.paymentDetailByStripeId;
  }
  async PaymentMethodByStripePaymentId(
    stripePaymentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelStripePaymentMethodFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PaymentMethodByStripePaymentIdQuery> {
    const statement = `query PaymentMethodByStripePaymentId($stripePaymentId: String, $sortDirection: ModelSortDirection, $filter: ModelStripePaymentMethodFilterInput, $limit: Int, $nextToken: String) {
        paymentMethodByStripePaymentId(
          stripePaymentId: $stripePaymentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (stripePaymentId) {
      gqlAPIServiceArguments.stripePaymentId = stripePaymentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentMethodByStripePaymentIdQuery>(
      response.data.paymentMethodByStripePaymentId
    );
  }
  async GetAppliedCameraPlan(id: string): Promise<GetAppliedCameraPlanQuery> {
    const statement = `query GetAppliedCameraPlan($id: ID!) {
        getAppliedCameraPlan(id: $id) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAppliedCameraPlanQuery>response.data.getAppliedCameraPlan;
  }
  async ListAppliedCameraPlans(
    filter?: ModelAppliedCameraPlanFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAppliedCameraPlansQuery> {
    const statement = `query ListAppliedCameraPlans($filter: ModelAppliedCameraPlanFilterInput, $limit: Int, $nextToken: String) {
        listAppliedCameraPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            parentId
            cameraId
            subscriptionId
            analyticsType
            resolution
            bitRate
            operatorDuration
            supervision
            storageDays
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            product {
              __typename
              id
              appliedCameraPlanId
              name
              nameForInvoice
              description
              appliedProductTaxTypeId
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            livePatrol {
              __typename
              id
              appliedCameraPlanId
              enabled
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            operatorMinutes {
              __typename
              id
              appliedCameraPlanId
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAppliedCameraPlansQuery>response.data.listAppliedCameraPlans;
  }
  async AppliedCameraPlanBySub(
    subscriptionId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAppliedCameraPlanFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AppliedCameraPlanBySubQuery> {
    const statement = `query AppliedCameraPlanBySub($subscriptionId: ID, $sortDirection: ModelSortDirection, $filter: ModelAppliedCameraPlanFilterInput, $limit: Int, $nextToken: String) {
        appliedCameraPlanBySub(
          subscriptionId: $subscriptionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            parentId
            cameraId
            subscriptionId
            analyticsType
            resolution
            bitRate
            operatorDuration
            supervision
            storageDays
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            product {
              __typename
              id
              appliedCameraPlanId
              name
              nameForInvoice
              description
              appliedProductTaxTypeId
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            livePatrol {
              __typename
              id
              appliedCameraPlanId
              enabled
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            operatorMinutes {
              __typename
              id
              appliedCameraPlanId
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (subscriptionId) {
      gqlAPIServiceArguments.subscriptionId = subscriptionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AppliedCameraPlanBySubQuery>response.data.appliedCameraPlanBySub;
  }
  async AppliedCameraPlanByCamera(
    cameraId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAppliedCameraPlanFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AppliedCameraPlanByCameraQuery> {
    const statement = `query AppliedCameraPlanByCamera($cameraId: ID, $sortDirection: ModelSortDirection, $filter: ModelAppliedCameraPlanFilterInput, $limit: Int, $nextToken: String) {
        appliedCameraPlanByCamera(
          cameraId: $cameraId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            parentId
            cameraId
            subscriptionId
            analyticsType
            resolution
            bitRate
            operatorDuration
            supervision
            storageDays
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            product {
              __typename
              id
              appliedCameraPlanId
              name
              nameForInvoice
              description
              appliedProductTaxTypeId
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            livePatrol {
              __typename
              id
              appliedCameraPlanId
              enabled
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            operatorMinutes {
              __typename
              id
              appliedCameraPlanId
              price
              currency
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cameraId) {
      gqlAPIServiceArguments.cameraId = cameraId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AppliedCameraPlanByCameraQuery>(
      response.data.appliedCameraPlanByCamera
    );
  }
  async GetTransactionLineItem(
    id: string
  ): Promise<GetTransactionLineItemQuery> {
    const statement = `query GetTransactionLineItem($id: ID!) {
        getTransactionLineItem(id: $id) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTransactionLineItemQuery>response.data.getTransactionLineItem;
  }
  async ListTransactionLineItems(
    filter?: ModelTransactionLineItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTransactionLineItemsQuery> {
    const statement = `query ListTransactionLineItems($filter: ModelTransactionLineItemFilterInput, $limit: Int, $nextToken: String) {
        listTransactionLineItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subscriptionId
            invoiceId
            connectedId
            type
            description
            currency
            pricePer
            totalPrice
            quantity
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                transactionLineItemId
                invoiceId
                taxId
                taxGovId
                taxPercent
                taxName
                currency
                amount
                subscriptionId
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTransactionLineItemsQuery>(
      response.data.listTransactionLineItems
    );
  }
  async TransactionLinesByInvoice(
    invoiceId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTransactionLineItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TransactionLinesByInvoiceQuery> {
    const statement = `query TransactionLinesByInvoice($invoiceId: ID, $sortDirection: ModelSortDirection, $filter: ModelTransactionLineItemFilterInput, $limit: Int, $nextToken: String) {
        transactionLinesByInvoice(
          invoiceId: $invoiceId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subscriptionId
            invoiceId
            connectedId
            type
            description
            currency
            pricePer
            totalPrice
            quantity
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                transactionLineItemId
                invoiceId
                taxId
                taxGovId
                taxPercent
                taxName
                currency
                amount
                subscriptionId
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (invoiceId) {
      gqlAPIServiceArguments.invoiceId = invoiceId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransactionLinesByInvoiceQuery>(
      response.data.transactionLinesByInvoice
    );
  }
  async TransactionLinesBySite(
    siteId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTransactionLineItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TransactionLinesBySiteQuery> {
    const statement = `query TransactionLinesBySite($siteId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTransactionLineItemFilterInput, $limit: Int, $nextToken: String) {
        transactionLinesBySite(
          siteId: $siteId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subscriptionId
            invoiceId
            connectedId
            type
            description
            currency
            pricePer
            totalPrice
            quantity
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                transactionLineItemId
                invoiceId
                taxId
                taxGovId
                taxPercent
                taxName
                currency
                amount
                subscriptionId
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransactionLinesBySiteQuery>response.data.transactionLinesBySite;
  }
  async GetTransactionLineItemTax(
    id: string
  ): Promise<GetTransactionLineItemTaxQuery> {
    const statement = `query GetTransactionLineItemTax($id: ID!) {
        getTransactionLineItemTax(id: $id) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTransactionLineItemTaxQuery>(
      response.data.getTransactionLineItemTax
    );
  }
  async ListTransactionLineItemTaxs(
    filter?: ModelTransactionLineItemTaxFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTransactionLineItemTaxsQuery> {
    const statement = `query ListTransactionLineItemTaxs($filter: ModelTransactionLineItemTaxFilterInput, $limit: Int, $nextToken: String) {
        listTransactionLineItemTaxs(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            transactionLineItemId
            invoiceId
            taxId
            taxGovId
            taxPercent
            taxName
            currency
            amount
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTransactionLineItemTaxsQuery>(
      response.data.listTransactionLineItemTaxs
    );
  }
  async TransactionLineItemTaxByLineItem(
    transactionLineItemId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTransactionLineItemTaxFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TransactionLineItemTaxByLineItemQuery> {
    const statement = `query TransactionLineItemTaxByLineItem($transactionLineItemId: ID, $sortDirection: ModelSortDirection, $filter: ModelTransactionLineItemTaxFilterInput, $limit: Int, $nextToken: String) {
        transactionLineItemTaxByLineItem(
          transactionLineItemId: $transactionLineItemId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            transactionLineItemId
            invoiceId
            taxId
            taxGovId
            taxPercent
            taxName
            currency
            amount
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (transactionLineItemId) {
      gqlAPIServiceArguments.transactionLineItemId = transactionLineItemId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransactionLineItemTaxByLineItemQuery>(
      response.data.transactionLineItemTaxByLineItem
    );
  }
  async GetInvoice(id: string): Promise<GetInvoiceQuery> {
    const statement = `query GetInvoice($id: ID!) {
        getInvoice(id: $id) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInvoiceQuery>response.data.getInvoice;
  }
  async ListInvoices(
    filter?: ModelInvoiceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInvoicesQuery> {
    const statement = `query ListInvoices($filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
        listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subtotal
            invoiceNumber
            total
            transactionCurrTotal
            notes
            type
            currency
            transactionCurr
            status
            merchant
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            transferGroup
            mcOwnerAccess
            mcControllerAccess
            updatedAt
            lineItems {
              __typename
              items {
                __typename
                id
                subscriptionId
                invoiceId
                connectedId
                type
                description
                currency
                pricePer
                totalPrice
                quantity
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    transactionLineItemId
                    invoiceId
                    taxId
                    taxGovId
                    taxPercent
                    taxName
                    currency
                    amount
                    subscriptionId
                    siteId
                    customerId
                    integratorId
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            payments {
              __typename
              items {
                __typename
                id
                stripePaymentIntentId
                paymentStatus
                paymentType
                notes
                invoiceId
                siteId
                customerId
                integratorId
                monitoringCenterId
                amountReceived
                amountRequested
                modifiedBy
                createdAt
                updatedAt
                paymentMethod {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            transferPayment {
              __typename
              id
              destination
              transferGroup
              stripePaymentIntentId
              currency
              stripeTransferRefId
              amount
              customerId
              monitoringCenterId
              integratorId
              createdAt
              modifiedBy
              status
              invoiceIds
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInvoicesQuery>response.data.listInvoices;
  }
  async InvoicesBySite(
    siteId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoiceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InvoicesBySiteQuery> {
    const statement = `query InvoicesBySite($siteId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
        invoicesBySite(
          siteId: $siteId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subtotal
            invoiceNumber
            total
            transactionCurrTotal
            notes
            type
            currency
            transactionCurr
            status
            merchant
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            transferGroup
            mcOwnerAccess
            mcControllerAccess
            updatedAt
            lineItems {
              __typename
              items {
                __typename
                id
                subscriptionId
                invoiceId
                connectedId
                type
                description
                currency
                pricePer
                totalPrice
                quantity
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    transactionLineItemId
                    invoiceId
                    taxId
                    taxGovId
                    taxPercent
                    taxName
                    currency
                    amount
                    subscriptionId
                    siteId
                    customerId
                    integratorId
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            payments {
              __typename
              items {
                __typename
                id
                stripePaymentIntentId
                paymentStatus
                paymentType
                notes
                invoiceId
                siteId
                customerId
                integratorId
                monitoringCenterId
                amountReceived
                amountRequested
                modifiedBy
                createdAt
                updatedAt
                paymentMethod {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            transferPayment {
              __typename
              id
              destination
              transferGroup
              stripePaymentIntentId
              currency
              stripeTransferRefId
              amount
              customerId
              monitoringCenterId
              integratorId
              createdAt
              modifiedBy
              status
              invoiceIds
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (siteId) {
      gqlAPIServiceArguments.siteId = siteId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InvoicesBySiteQuery>response.data.invoicesBySite;
  }
  async InvoicesByStatus(
    status?: InvoiceStatus,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoiceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InvoicesByStatusQuery> {
    const statement = `query InvoicesByStatus($status: InvoiceStatus, $sortDirection: ModelSortDirection, $filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
        invoicesByStatus(
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subtotal
            invoiceNumber
            total
            transactionCurrTotal
            notes
            type
            currency
            transactionCurr
            status
            merchant
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            transferGroup
            mcOwnerAccess
            mcControllerAccess
            updatedAt
            lineItems {
              __typename
              items {
                __typename
                id
                subscriptionId
                invoiceId
                connectedId
                type
                description
                currency
                pricePer
                totalPrice
                quantity
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    transactionLineItemId
                    invoiceId
                    taxId
                    taxGovId
                    taxPercent
                    taxName
                    currency
                    amount
                    subscriptionId
                    siteId
                    customerId
                    integratorId
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            payments {
              __typename
              items {
                __typename
                id
                stripePaymentIntentId
                paymentStatus
                paymentType
                notes
                invoiceId
                siteId
                customerId
                integratorId
                monitoringCenterId
                amountReceived
                amountRequested
                modifiedBy
                createdAt
                updatedAt
                paymentMethod {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            transferPayment {
              __typename
              id
              destination
              transferGroup
              stripePaymentIntentId
              currency
              stripeTransferRefId
              amount
              customerId
              monitoringCenterId
              integratorId
              createdAt
              modifiedBy
              status
              invoiceIds
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InvoicesByStatusQuery>response.data.invoicesByStatus;
  }
  async InvoicesByStatusOrdered(
    status?: InvoiceStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoiceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InvoicesByStatusOrderedQuery> {
    const statement = `query InvoicesByStatusOrdered($status: InvoiceStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
        invoicesByStatusOrdered(
          status: $status
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subtotal
            invoiceNumber
            total
            transactionCurrTotal
            notes
            type
            currency
            transactionCurr
            status
            merchant
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            transferGroup
            mcOwnerAccess
            mcControllerAccess
            updatedAt
            lineItems {
              __typename
              items {
                __typename
                id
                subscriptionId
                invoiceId
                connectedId
                type
                description
                currency
                pricePer
                totalPrice
                quantity
                siteId
                customerId
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    transactionLineItemId
                    invoiceId
                    taxId
                    taxGovId
                    taxPercent
                    taxName
                    currency
                    amount
                    subscriptionId
                    siteId
                    customerId
                    integratorId
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            payments {
              __typename
              items {
                __typename
                id
                stripePaymentIntentId
                paymentStatus
                paymentType
                notes
                invoiceId
                siteId
                customerId
                integratorId
                monitoringCenterId
                amountReceived
                amountRequested
                modifiedBy
                createdAt
                updatedAt
                paymentMethod {
                  __typename
                  id
                  subscriptionStripeDetailId
                  stripePaymentId
                  stripePaymentStatus
                  type
                  name
                  identifier
                  preferredPayment
                  monitoringCenterId
                  mcOwnerAccess
                  mcControllerAccess
                  mcBillingAdminAccess
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            transferPayment {
              __typename
              id
              destination
              transferGroup
              stripePaymentIntentId
              currency
              stripeTransferRefId
              amount
              customerId
              monitoringCenterId
              integratorId
              createdAt
              modifiedBy
              status
              invoiceIds
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InvoicesByStatusOrderedQuery>response.data.invoicesByStatusOrdered;
  }
  async GetInvoicePayment(id: string): Promise<GetInvoicePaymentQuery> {
    const statement = `query GetInvoicePayment($id: ID!) {
        getInvoicePayment(id: $id) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInvoicePaymentQuery>response.data.getInvoicePayment;
  }
  async ListInvoicePayments(
    filter?: ModelInvoicePaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInvoicePaymentsQuery> {
    const statement = `query ListInvoicePayments($filter: ModelInvoicePaymentFilterInput, $limit: Int, $nextToken: String) {
        listInvoicePayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            stripePaymentIntentId
            paymentStatus
            paymentType
            notes
            invoiceId
            siteId
            customerId
            integratorId
            monitoringCenterId
            amountReceived
            amountRequested
            modifiedBy
            createdAt
            updatedAt
            paymentMethod {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInvoicePaymentsQuery>response.data.listInvoicePayments;
  }
  async PaymentsByInvoice(
    invoiceId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoicePaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PaymentsByInvoiceQuery> {
    const statement = `query PaymentsByInvoice($invoiceId: ID, $sortDirection: ModelSortDirection, $filter: ModelInvoicePaymentFilterInput, $limit: Int, $nextToken: String) {
        paymentsByInvoice(
          invoiceId: $invoiceId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            stripePaymentIntentId
            paymentStatus
            paymentType
            notes
            invoiceId
            siteId
            customerId
            integratorId
            monitoringCenterId
            amountReceived
            amountRequested
            modifiedBy
            createdAt
            updatedAt
            paymentMethod {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (invoiceId) {
      gqlAPIServiceArguments.invoiceId = invoiceId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentsByInvoiceQuery>response.data.paymentsByInvoice;
  }
  async InvoicePaymentByStatusAndCreatedAt(
    paymentStatus?: StripePaymentStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoicePaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InvoicePaymentByStatusAndCreatedAtQuery> {
    const statement = `query InvoicePaymentByStatusAndCreatedAt($paymentStatus: StripePaymentStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelInvoicePaymentFilterInput, $limit: Int, $nextToken: String) {
        invoicePaymentByStatusAndCreatedAt(
          paymentStatus: $paymentStatus
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            stripePaymentIntentId
            paymentStatus
            paymentType
            notes
            invoiceId
            siteId
            customerId
            integratorId
            monitoringCenterId
            amountReceived
            amountRequested
            modifiedBy
            createdAt
            updatedAt
            paymentMethod {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (paymentStatus) {
      gqlAPIServiceArguments.paymentStatus = paymentStatus;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InvoicePaymentByStatusAndCreatedAtQuery>(
      response.data.invoicePaymentByStatusAndCreatedAt
    );
  }
  async GetInvoiceTransferPayment(
    id: string
  ): Promise<GetInvoiceTransferPaymentQuery> {
    const statement = `query GetInvoiceTransferPayment($id: ID!) {
        getInvoiceTransferPayment(id: $id) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInvoiceTransferPaymentQuery>(
      response.data.getInvoiceTransferPayment
    );
  }
  async ListInvoiceTransferPayments(
    filter?: ModelInvoiceTransferPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInvoiceTransferPaymentsQuery> {
    const statement = `query ListInvoiceTransferPayments($filter: ModelInvoiceTransferPaymentFilterInput, $limit: Int, $nextToken: String) {
        listInvoiceTransferPayments(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInvoiceTransferPaymentsQuery>(
      response.data.listInvoiceTransferPayments
    );
  }
  async TransfersByGroup(
    transferGroup?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoiceTransferPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TransfersByGroupQuery> {
    const statement = `query TransfersByGroup($transferGroup: String, $sortDirection: ModelSortDirection, $filter: ModelInvoiceTransferPaymentFilterInput, $limit: Int, $nextToken: String) {
        transfersByGroup(
          transferGroup: $transferGroup
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (transferGroup) {
      gqlAPIServiceArguments.transferGroup = transferGroup;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransfersByGroupQuery>response.data.transfersByGroup;
  }
  async InvoiceTransferPaymentByStatusAndCreatedAt(
    status?: InvoiceTransferStatus,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelInvoiceTransferPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InvoiceTransferPaymentByStatusAndCreatedAtQuery> {
    const statement = `query InvoiceTransferPaymentByStatusAndCreatedAt($status: InvoiceTransferStatus, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelInvoiceTransferPaymentFilterInput, $limit: Int, $nextToken: String) {
        invoiceTransferPaymentByStatusAndCreatedAt(
          status: $status
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InvoiceTransferPaymentByStatusAndCreatedAtQuery>(
      response.data.invoiceTransferPaymentByStatusAndCreatedAt
    );
  }
  async GetWalletTransfer(id: string): Promise<GetWalletTransferQuery> {
    const statement = `query GetWalletTransfer($id: ID!) {
        getWalletTransfer(id: $id) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWalletTransferQuery>response.data.getWalletTransfer;
  }
  async ListWalletTransfers(
    filter?: ModelWalletTransferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWalletTransfersQuery> {
    const statement = `query ListWalletTransfers($filter: ModelWalletTransferFilterInput, $limit: Int, $nextToken: String) {
        listWalletTransfers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            amount
            currency
            conversionRate
            stripePaymentId
            modifiedBy
            type
            createdAt
            updatedAt
            paymentMethod {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWalletTransfersQuery>response.data.listWalletTransfers;
  }
  async WalletTransfersBySubscription(
    subscriptionId?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelWalletTransferFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WalletTransfersBySubscriptionQuery> {
    const statement = `query WalletTransfersBySubscription($subscriptionId: ID, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWalletTransferFilterInput, $limit: Int, $nextToken: String) {
        walletTransfersBySubscription(
          subscriptionId: $subscriptionId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            amount
            currency
            conversionRate
            stripePaymentId
            modifiedBy
            type
            createdAt
            updatedAt
            paymentMethod {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (subscriptionId) {
      gqlAPIServiceArguments.subscriptionId = subscriptionId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WalletTransfersBySubscriptionQuery>(
      response.data.walletTransfersBySubscription
    );
  }
  async GetStripePayoutDetails(
    stripeAccId: string
  ): Promise<GetStripePayoutDetailsQuery> {
    const statement = `query GetStripePayoutDetails($stripeAccId: String!) {
        getStripePayoutDetails(stripeAccId: $stripeAccId) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      stripeAccId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStripePayoutDetailsQuery>response.data.getStripePayoutDetails;
  }
  async ListStripePayoutDetailss(
    stripeAccId?: string,
    filter?: ModelStripePayoutDetailsFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListStripePayoutDetailssQuery> {
    const statement = `query ListStripePayoutDetailss($stripeAccId: String, $filter: ModelStripePayoutDetailsFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listStripePayoutDetailss(
          stripeAccId: $stripeAccId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            stripeAccId
            integratorId
            monitoringCenterId
            chargesEnabled
            modifiedBy
            mcOwnerAccess
            mcControllerAccess
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (stripeAccId) {
      gqlAPIServiceArguments.stripeAccId = stripeAccId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStripePayoutDetailssQuery>(
      response.data.listStripePayoutDetailss
    );
  }
  async StripePayoutDetailsByInt(
    integratorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelStripePayoutDetailsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StripePayoutDetailsByIntQuery> {
    const statement = `query StripePayoutDetailsByInt($integratorId: ID, $sortDirection: ModelSortDirection, $filter: ModelStripePayoutDetailsFilterInput, $limit: Int, $nextToken: String) {
        stripePayoutDetailsByInt(
          integratorId: $integratorId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            stripeAccId
            integratorId
            monitoringCenterId
            chargesEnabled
            modifiedBy
            mcOwnerAccess
            mcControllerAccess
            archived
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StripePayoutDetailsByIntQuery>(
      response.data.stripePayoutDetailsByInt
    );
  }
  async GetExchangeRateSetting(
    country: string
  ): Promise<GetExchangeRateSettingQuery> {
    const statement = `query GetExchangeRateSetting($country: String!) {
        getExchangeRateSetting(country: $country) {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExchangeRateSettingQuery>response.data.getExchangeRateSetting;
  }
  async ListExchangeRateSettings(
    country?: string,
    filter?: ModelExchangeRateSettingFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListExchangeRateSettingsQuery> {
    const statement = `query ListExchangeRateSettings($country: String, $filter: ModelExchangeRateSettingFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listExchangeRateSettings(
          country: $country
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            country
            modifiedBy
            rate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (country) {
      gqlAPIServiceArguments.country = country;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExchangeRateSettingsQuery>(
      response.data.listExchangeRateSettings
    );
  }
  async GetPaymentTransferSettings(
    id: string
  ): Promise<GetPaymentTransferSettingsQuery> {
    const statement = `query GetPaymentTransferSettings($id: ID!) {
        getPaymentTransferSettings(id: $id) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPaymentTransferSettingsQuery>(
      response.data.getPaymentTransferSettings
    );
  }
  async ListPaymentTransferSettingss(
    filter?: ModelPaymentTransferSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPaymentTransferSettingssQuery> {
    const statement = `query ListPaymentTransferSettingss($filter: ModelPaymentTransferSettingsFilterInput, $limit: Int, $nextToken: String) {
        listPaymentTransferSettingss(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcIntAdminAccess
            type
            variable
            variableMaxDollars
            flat
            modifiedBy
            archived
            createdAt
            updatedAt
            variableTaxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
            flatTaxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPaymentTransferSettingssQuery>(
      response.data.listPaymentTransferSettingss
    );
  }
  async PaymentTransferSettingsByMC(
    monitoringCenterId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPaymentTransferSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PaymentTransferSettingsByMCQuery> {
    const statement = `query PaymentTransferSettingsByMC($monitoringCenterId: ID, $sortDirection: ModelSortDirection, $filter: ModelPaymentTransferSettingsFilterInput, $limit: Int, $nextToken: String) {
        paymentTransferSettingsByMC(
          monitoringCenterId: $monitoringCenterId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcIntAdminAccess
            type
            variable
            variableMaxDollars
            flat
            modifiedBy
            archived
            createdAt
            updatedAt
            variableTaxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
            flatTaxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentTransferSettingsByMCQuery>(
      response.data.paymentTransferSettingsByMC
    );
  }
  async GetWebhookHealth(id: string): Promise<GetWebhookHealthQuery> {
    const statement = `query GetWebhookHealth($id: ID!) {
        getWebhookHealth(id: $id) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWebhookHealthQuery>response.data.getWebhookHealth;
  }
  async ListWebhookHealths(
    filter?: ModelWebhookHealthFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWebhookHealthsQuery> {
    const statement = `query ListWebhookHealths($filter: ModelWebhookHealthFilterInput, $limit: Int, $nextToken: String) {
        listWebhookHealths(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            vmsWebhookId
            date
            ackAvgInterval
            acklastReceived
            ackCount
            analyticAvgInterval
            analyticlastReceived
            analyticCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWebhookHealthsQuery>response.data.listWebhookHealths;
  }
  async WebhookHealthByDate(
    monitoringCenterId?: string,
    vmsWebhookIdDate?: ModelWebhookHealthWebhookHealthByDateCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelWebhookHealthFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WebhookHealthByDateQuery> {
    const statement = `query WebhookHealthByDate($monitoringCenterId: ID, $vmsWebhookIdDate: ModelWebhookHealthWebhookHealthByDateCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelWebhookHealthFilterInput, $limit: Int, $nextToken: String) {
        webhookHealthByDate(
          monitoringCenterId: $monitoringCenterId
          vmsWebhookIdDate: $vmsWebhookIdDate
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            monitoringCenterId
            vmsWebhookId
            date
            ackAvgInterval
            acklastReceived
            ackCount
            analyticAvgInterval
            analyticlastReceived
            analyticCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (vmsWebhookIdDate) {
      gqlAPIServiceArguments.vmsWebhookIdDate = vmsWebhookIdDate;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WebhookHealthByDateQuery>response.data.webhookHealthByDate;
  }
  OnCreateIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateIncident">>
  > {
    const statement = `subscription OnCreateIncident($monitoringCenterId: String) {
        onCreateIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateIncident">>
    >;
  }

  OnUpdateIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateIncident">>
  > {
    const statement = `subscription OnUpdateIncident($monitoringCenterId: String) {
        onUpdateIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateIncident">>
    >;
  }

  OnDeleteIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteIncident">>
  > {
    const statement = `subscription OnDeleteIncident($monitoringCenterId: String) {
        onDeleteIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          integratorId
          monitoringCenterId
          status
          incidentSiteId
          incidentCustomerId
          dispositionLevel
          primaryDisposition
          dispositionList
          closedTime
          resolvedTime
          expectedResolvedTime
          billableTime
          assignedUsername
          assignmentHistory {
            __typename
            username
            assignedAt
          }
          modifiedBy
          createdAt
          hasShareExternal
          resolution
          resolutionTranslated
          resolutionSanitized
          externalPartyResponse {
            __typename
            police
            fire
            ambulance
            bylaw
            security
            outreach
            siteContact
            agenciesArrived
            notArrivedReason
            notArrivedReasonTranslated
            notArrivedReasonSanitized
          }
          invoiceId
          updatedAt
          events {
            __typename
            items {
              __typename
              id
              vmsEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              thumbnailFilename
              triggerType
              objectsFound
              integratorId
              monitoringCenterId
              incidentId
              incidentPriority
              cameraEventCameraId
              siteId
              customerId
              startTime
              endTime
              resolvedTime
              instantaneous
              cameraEventUrl
              cameraEventUrlPublic
              modifiedBy
              vmsUserId
              ackTimestamp
              createdAt
              vmsSourceType
              updatedAt
              secondaryEvents {
                __typename
                items {
                  __typename
                  id
                  cameraEventId
                  vmsNotes
                  vmsNotesTranslated
                  vmsNotesSanitized
                  disposition
                  eventType
                  vmsSourceType
                  ackTimestamp
                  modifiedBy
                  vmsUserId
                  monitoringCenterId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              camera {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          site {
            __typename
            id
            name
            status
            customerId
            integratorId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            externalCriticalNotes
            externalCriticalNotesTranslated
            externalCriticalNotesSanitized
            monitoringCenterId
            archived
            modifiedBy
            timezone
            vmsSharedMonitoringRoleId
            monitoringStatus
            maintainCamerasOnMonitoringRole
            armingRulesConfig
            monitoringState
            armedState
            alarmState
            supervisionEnabled
            triggersEnabled
            muteAfterInteractionCount
            type
            alarmsCentralStation
            alarmsCentralStationPhone
            alarmsCentralStationPhoneExt
            alarmsVerbalPasscode
            alarmsCSID
            alarmsAR
            alarmsNotes
            alarmsNotesTranslated
            alarmsNotesSanitized
            alarmsPermitNum
            alarmsPermitExpires
            autoSendToTrackTik
            hasSiteMap
            condensedAddress
            createdAt
            allowCustomerViewPendingUntil
            updatedAt
            address {
              __typename
              id
              lineOne
              lineTwo
              city
              state
              zipCode
              country
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            integrations {
              __typename
              items {
                __typename
                id
                siteId
                integratorId
                monitoringCenterId
                type
                subType
                subTypeList
                agency
                phone
                phoneExt
                email
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                archived
                createdAt
                updatedAt
                trackTickDetails {
                  __typename
                  id
                  siteIntegrationId
                  siteId
                  monitoringCenterId
                  apiUrl
                  apiUsername
                  apiPassword
                  accountId
                  token
                  refreshToken
                  tokenExpiry
                  refreshTokenExpiry
                  autoSend
                  autoSendErrorEmail
                  configured
                  archived
                  modifiedBy
                  createdAt
                  updatedAt
                  taskList {
                    __typename
                    items {
                      __typename
                      id
                      trackTikDetailsId
                      siteId
                      monitoringCenterId
                      eventType
                      trackTikName
                      trackTikId
                      trackTikReportTemplateId
                      archived
                      modifedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            contacts {
              __typename
              items {
                __typename
                id
                siteId
                name
                type
                verbalPasscode
                email
                priority
                monitoringCenterId
                integratorId
                archived
                notes
                notesTranslated
                notesSanitized
                modifiedBy
                createdAt
                updatedAt
                phones {
                  __typename
                  items {
                    __typename
                    id
                    contactId
                    type
                    number
                    ext
                    monitoringCenterId
                    integratorId
                    modifiedBy
                    archived
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
              nextToken
            }
            cameras {
              __typename
              items {
                __typename
                id
                name
                deviceType
                status
                vmsCameraName
                description
                descriptionTranslated
                descriptionSanitized
                vmsDescription
                siteId
                integratorId
                monitoringCenterId
                customerId
                criticalNotes
                criticalNotesTranslated
                criticalNotesSanitized
                talkdownInstructions {
                  __typename
                  talkdownType
                  note
                  noteTranslated
                  noteSanitized
                  phone
                  url
                  messageDetails {
                    __typename
                    messageType
                    messageTypeId
                    urlSuffix
                  }
                  hostConfiguration {
                    __typename
                    baseUrl
                    username
                    password
                    method
                  }
                }
                latitude
                longitude
                vmsCameraId
                vmsCustomerId
                testingExpiry
                armedState
                snoozeReason
                snoozeReasonTranslated
                snoozeReasonSanitized
                snoozeExpiry
                archived
                zoneNumber
                zoneDescription
                zoneDescriptionTranslated
                zoneDescriptionSanitized
                modifiedBy
                isOnvif
                videoStreamState
                deviceUrlOnline
                vmsConnect
                isP2P
                isAnalyticsEnabled
                isMotionEnabled
                testEventReceived
                healthCheckEnabled
                healthCheckSLA
                healthCheckStatus
                ignoreStatusChecks
                statusChecksProperties
                statusLastVerified
                archiveDurationHours
                accessUrlsId
                credentialsId
                deviceBrand
                deviceModel
                vmsPlanId
                phpRegistrationCode
                serialNumber
                p2pState
                lastPowerCycle
                baselineThumbnailFilename
                currentThumbnailFilename
                sceneChangeDetectionEnabled
                passingSceneDetection
                hubspotId
                immixMonitoring
                hasMic
                hasSpeaker
                audioGroups
                createdAt
                updatedAt
                qualitySettings {
                  __typename
                  id
                  width
                  height
                  videoBitRate
                  frameRate
                  monitoringCenterId
                  cameraId
                  modifiedBy
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            sops {
              __typename
              items {
                __typename
                id
                siteId
                eventType
                eventSOPs
                monitoringCenterId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteIncident">>
    >;
  }

  OnCreateIncidentNoteListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateIncidentNote">>
  > {
    const statement = `subscription OnCreateIncidentNote($monitoringCenterId: String) {
        onCreateIncidentNote(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIncidentNote">
      >
    >;
  }

  OnUpdateIncidentNoteListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateIncidentNote">>
  > {
    const statement = `subscription OnUpdateIncidentNote($monitoringCenterId: String) {
        onUpdateIncidentNote(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIncidentNote">
      >
    >;
  }

  OnDeleteIncidentNoteListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteIncidentNote">>
  > {
    const statement = `subscription OnDeleteIncidentNote($monitoringCenterId: String) {
        onDeleteIncidentNote(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          note
          noteTranslated
          noteSanitized
          monitoringCenterId
          modifiedBy
          writtenBy
          createdAt
          shareExternal
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIncidentNote">
      >
    >;
  }

  OnCreateIncidentActionListListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentActionList">
    >
  > {
    const statement = `subscription OnCreateIncidentActionList($monitoringCenterId: String) {
        onCreateIncidentActionList(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIncidentActionList">
      >
    >;
  }

  OnUpdateIncidentActionListListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentActionList">
    >
  > {
    const statement = `subscription OnUpdateIncidentActionList($monitoringCenterId: String) {
        onUpdateIncidentActionList(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIncidentActionList">
      >
    >;
  }

  OnDeleteIncidentActionListListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentActionList">
    >
  > {
    const statement = `subscription OnDeleteIncidentActionList($monitoringCenterId: String) {
        onDeleteIncidentActionList(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          priority
          eventType
          disposition
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          tasks {
            __typename
            items {
              __typename
              id
              incidentId
              incidentActionItemId
              order
              completed
              description
              modifiedBy
              monitoringCenterId
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIncidentActionList">
      >
    >;
  }

  OnCreateProcedureTaskListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProcedureTask">>
  > {
    const statement = `subscription OnCreateProcedureTask($monitoringCenterId: String) {
        onCreateProcedureTask(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateProcedureTask">
      >
    >;
  }

  OnUpdateProcedureTaskListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProcedureTask">>
  > {
    const statement = `subscription OnUpdateProcedureTask($monitoringCenterId: String) {
        onUpdateProcedureTask(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateProcedureTask">
      >
    >;
  }

  OnDeleteProcedureTaskListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProcedureTask">>
  > {
    const statement = `subscription OnDeleteProcedureTask($monitoringCenterId: String) {
        onDeleteProcedureTask(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          incidentActionItemId
          order
          completed
          description
          modifiedBy
          monitoringCenterId
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteProcedureTask">
      >
    >;
  }

  OnCreateIncidentBundleTimeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentBundleTime">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentBundleTime {
        onCreateIncidentBundleTime {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentBundleTime">
    >
  >;

  OnUpdateIncidentBundleTimeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentBundleTime">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentBundleTime {
        onUpdateIncidentBundleTime {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentBundleTime">
    >
  >;

  OnDeleteIncidentBundleTimeListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentBundleTime">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentBundleTime {
        onDeleteIncidentBundleTime {
          __typename
          id
          monitoringCenterId
          applyToAssigned
          bundleTime
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentBundleTime">
    >
  >;

  OnCreateCameraEventListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCameraEvent">>
  > {
    const statement = `subscription OnCreateCameraEvent($monitoringCenterId: String) {
        onCreateCameraEvent(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCameraEvent">>
    >;
  }

  OnUpdateCameraEventListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCameraEvent">>
  > {
    const statement = `subscription OnUpdateCameraEvent($monitoringCenterId: String) {
        onUpdateCameraEvent(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCameraEvent">>
    >;
  }

  OnDeleteCameraEventListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCameraEvent">>
  > {
    const statement = `subscription OnDeleteCameraEvent($monitoringCenterId: String) {
        onDeleteCameraEvent(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          vmsEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          thumbnailFilename
          triggerType
          objectsFound
          integratorId
          monitoringCenterId
          incidentId
          incidentPriority
          cameraEventCameraId
          siteId
          customerId
          startTime
          endTime
          resolvedTime
          instantaneous
          cameraEventUrl
          cameraEventUrlPublic
          modifiedBy
          vmsUserId
          ackTimestamp
          createdAt
          vmsSourceType
          updatedAt
          secondaryEvents {
            __typename
            items {
              __typename
              id
              cameraEventId
              vmsNotes
              vmsNotesTranslated
              vmsNotesSanitized
              disposition
              eventType
              vmsSourceType
              ackTimestamp
              modifiedBy
              vmsUserId
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          camera {
            __typename
            id
            name
            deviceType
            status
            vmsCameraName
            description
            descriptionTranslated
            descriptionSanitized
            vmsDescription
            siteId
            integratorId
            monitoringCenterId
            customerId
            criticalNotes
            criticalNotesTranslated
            criticalNotesSanitized
            talkdownInstructions {
              __typename
              talkdownType
              note
              noteTranslated
              noteSanitized
              phone
              url
              messageDetails {
                __typename
                messageType
                messageTypeId
                urlSuffix
              }
              hostConfiguration {
                __typename
                baseUrl
                username
                password
                method
              }
            }
            latitude
            longitude
            vmsCameraId
            vmsCustomerId
            testingExpiry
            armedState
            snoozeReason
            snoozeReasonTranslated
            snoozeReasonSanitized
            snoozeExpiry
            archived
            zoneNumber
            zoneDescription
            zoneDescriptionTranslated
            zoneDescriptionSanitized
            modifiedBy
            isOnvif
            videoStreamState
            deviceUrlOnline
            vmsConnect
            isP2P
            isAnalyticsEnabled
            isMotionEnabled
            testEventReceived
            healthCheckEnabled
            healthCheckSLA
            healthCheckStatus
            ignoreStatusChecks
            statusChecksProperties
            statusLastVerified
            archiveDurationHours
            accessUrlsId
            credentialsId
            deviceBrand
            deviceModel
            vmsPlanId
            phpRegistrationCode
            serialNumber
            p2pState
            lastPowerCycle
            baselineThumbnailFilename
            currentThumbnailFilename
            sceneChangeDetectionEnabled
            passingSceneDetection
            hubspotId
            immixMonitoring
            hasMic
            hasSpeaker
            audioGroups
            createdAt
            updatedAt
            qualitySettings {
              __typename
              id
              width
              height
              videoBitRate
              frameRate
              monitoringCenterId
              cameraId
              modifiedBy
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCameraEvent">>
    >;
  }

  OnCreateSecondaryCameraEventListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSecondaryCameraEvent">
    >
  > {
    const statement = `subscription OnCreateSecondaryCameraEvent($monitoringCenterId: String) {
        onCreateSecondaryCameraEvent(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          cameraEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          vmsSourceType
          ackTimestamp
          modifiedBy
          vmsUserId
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSecondaryCameraEvent">
      >
    >;
  }

  OnUpdateSecondaryCameraEventListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSecondaryCameraEvent">
    >
  > {
    const statement = `subscription OnUpdateSecondaryCameraEvent($monitoringCenterId: String) {
        onUpdateSecondaryCameraEvent(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          cameraEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          vmsSourceType
          ackTimestamp
          modifiedBy
          vmsUserId
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSecondaryCameraEvent">
      >
    >;
  }

  OnDeleteSecondaryCameraEventListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSecondaryCameraEvent">
    >
  > {
    const statement = `subscription OnDeleteSecondaryCameraEvent($monitoringCenterId: String) {
        onDeleteSecondaryCameraEvent(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          cameraEventId
          vmsNotes
          vmsNotesTranslated
          vmsNotesSanitized
          disposition
          eventType
          vmsSourceType
          ackTimestamp
          modifiedBy
          vmsUserId
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSecondaryCameraEvent">
      >
    >;
  }

  OnCreateCustomerListener(
    monitoringCenterId?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomer">>
  > {
    const statement = `subscription OnCreateCustomer($monitoringCenterId: String, $id: String) {
        onCreateCustomer(monitoringCenterId: $monitoringCenterId, id: $id) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomer">>
    >;
  }

  OnUpdateCustomerListener(
    monitoringCenterId?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomer">>
  > {
    const statement = `subscription OnUpdateCustomer($monitoringCenterId: String, $id: String) {
        onUpdateCustomer(monitoringCenterId: $monitoringCenterId, id: $id) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomer">>
    >;
  }

  OnDeleteCustomerListener(
    monitoringCenterId?: string,
    id?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomer">>
  > {
    const statement = `subscription OnDeleteCustomer($monitoringCenterId: String, $id: String) {
        onDeleteCustomer(monitoringCenterId: $monitoringCenterId, id: $id) {
          __typename
          id
          name
          type
          status
          integratorId
          monitoringCenterId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          vmsCustomerId
          vmsMCWebhookId
          vmsMonitoringCenterRoleId
          archived
          modifiedBy
          slaL1
          slaL2
          slaL3
          slaL4
          sortName
          softphoneEnabled
          softphoneNumber
          callbackNumber
          callbackNumberExt
          zendeskWidgetScript
          hubspotId
          hasLogo
          mobileLogo
          invoicePrefix
          invoiceNumber
          invoiceFooter
          errorEmail
          vmsUrl
          vmsAlarmUrl
          vmsAdminUrl
          orgDomainUrl
          fromEmail
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          boldBIIntegration {
            __typename
            id
            siteUsername
            sitePassword
            siteUrl
            siteDefaultDashboardId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          rapidSOSIntegration {
            __typename
            id
            clientId
            clientSecret
            webhookEnabled
            agencyId
            webhookId
            customerId
            monitoringCenterId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          contact {
            __typename
            id
            firstName
            lastName
            name
            phone
            ext
            email
            monitoringCenterId
            integratorId
            modifiedBy
            customerCanShareSecurables
            customerCanLoginAdminPortal
            createdAt
            updatedAt
          }
          sites {
            __typename
            items {
              __typename
              id
              name
              status
              customerId
              integratorId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              externalCriticalNotes
              externalCriticalNotesTranslated
              externalCriticalNotesSanitized
              monitoringCenterId
              archived
              modifiedBy
              timezone
              vmsSharedMonitoringRoleId
              monitoringStatus
              maintainCamerasOnMonitoringRole
              armingRulesConfig
              monitoringState
              armedState
              alarmState
              supervisionEnabled
              triggersEnabled
              muteAfterInteractionCount
              type
              alarmsCentralStation
              alarmsCentralStationPhone
              alarmsCentralStationPhoneExt
              alarmsVerbalPasscode
              alarmsCSID
              alarmsAR
              alarmsNotes
              alarmsNotesTranslated
              alarmsNotesSanitized
              alarmsPermitNum
              alarmsPermitExpires
              autoSendToTrackTik
              hasSiteMap
              condensedAddress
              createdAt
              allowCustomerViewPendingUntil
              updatedAt
              address {
                __typename
                id
                lineOne
                lineTwo
                city
                state
                zipCode
                country
                integratorId
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
              }
              integrations {
                __typename
                items {
                  __typename
                  id
                  siteId
                  integratorId
                  monitoringCenterId
                  type
                  subType
                  subTypeList
                  agency
                  phone
                  phoneExt
                  email
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                  trackTickDetails {
                    __typename
                    id
                    siteIntegrationId
                    siteId
                    monitoringCenterId
                    apiUrl
                    apiUsername
                    apiPassword
                    accountId
                    token
                    refreshToken
                    tokenExpiry
                    refreshTokenExpiry
                    autoSend
                    autoSendErrorEmail
                    configured
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    taskList {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
              contacts {
                __typename
                items {
                  __typename
                  id
                  siteId
                  name
                  type
                  verbalPasscode
                  email
                  priority
                  monitoringCenterId
                  integratorId
                  archived
                  notes
                  notesTranslated
                  notesSanitized
                  modifiedBy
                  createdAt
                  updatedAt
                  phones {
                    __typename
                    items {
                      __typename
                      id
                      contactId
                      type
                      number
                      ext
                      monitoringCenterId
                      integratorId
                      modifiedBy
                      archived
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
              cameras {
                __typename
                items {
                  __typename
                  id
                  name
                  deviceType
                  status
                  vmsCameraName
                  description
                  descriptionTranslated
                  descriptionSanitized
                  vmsDescription
                  siteId
                  integratorId
                  monitoringCenterId
                  customerId
                  criticalNotes
                  criticalNotesTranslated
                  criticalNotesSanitized
                  talkdownInstructions {
                    __typename
                    talkdownType
                    note
                    noteTranslated
                    noteSanitized
                    phone
                    url
                    messageDetails {
                      __typename
                      messageType
                      messageTypeId
                      urlSuffix
                    }
                    hostConfiguration {
                      __typename
                      baseUrl
                      username
                      password
                      method
                    }
                  }
                  latitude
                  longitude
                  vmsCameraId
                  vmsCustomerId
                  testingExpiry
                  armedState
                  snoozeReason
                  snoozeReasonTranslated
                  snoozeReasonSanitized
                  snoozeExpiry
                  archived
                  zoneNumber
                  zoneDescription
                  zoneDescriptionTranslated
                  zoneDescriptionSanitized
                  modifiedBy
                  isOnvif
                  videoStreamState
                  deviceUrlOnline
                  vmsConnect
                  isP2P
                  isAnalyticsEnabled
                  isMotionEnabled
                  testEventReceived
                  healthCheckEnabled
                  healthCheckSLA
                  healthCheckStatus
                  ignoreStatusChecks
                  statusChecksProperties
                  statusLastVerified
                  archiveDurationHours
                  accessUrlsId
                  credentialsId
                  deviceBrand
                  deviceModel
                  vmsPlanId
                  phpRegistrationCode
                  serialNumber
                  p2pState
                  lastPowerCycle
                  baselineThumbnailFilename
                  currentThumbnailFilename
                  sceneChangeDetectionEnabled
                  passingSceneDetection
                  hubspotId
                  immixMonitoring
                  hasMic
                  hasSpeaker
                  audioGroups
                  createdAt
                  updatedAt
                  qualitySettings {
                    __typename
                    id
                    width
                    height
                    videoBitRate
                    frameRate
                    monitoringCenterId
                    cameraId
                    modifiedBy
                    createdAt
                    updatedAt
                  }
                }
                nextToken
              }
              sops {
                __typename
                items {
                  __typename
                  id
                  siteId
                  eventType
                  eventSOPs
                  monitoringCenterId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomer">>
    >;
  }

  OnCreateVMSDetailsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVMSDetails">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVMSDetails {
        onCreateVMSDetails {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVMSDetails">>
  >;

  OnUpdateVMSDetailsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVMSDetails">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVMSDetails {
        onUpdateVMSDetails {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVMSDetails">>
  >;

  OnDeleteVMSDetailsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVMSDetails">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVMSDetails {
        onDeleteVMSDetails {
          __typename
          id
          apiKey
          username
          password
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVMSDetails">>
  >;

  OnCreateDashboardDetailListener(
    monitoringCenterId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateDashboardDetail">
    >
  > {
    const statement = `subscription OnCreateDashboardDetail($monitoringCenterId: String, $integratorId: String) {
        onCreateDashboardDetail(
          monitoringCenterId: $monitoringCenterId
          integratorId: $integratorId
        ) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateDashboardDetail">
      >
    >;
  }

  OnUpdateDashboardDetailListener(
    monitoringCenterId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateDashboardDetail">
    >
  > {
    const statement = `subscription OnUpdateDashboardDetail($monitoringCenterId: String, $integratorId: String) {
        onUpdateDashboardDetail(
          monitoringCenterId: $monitoringCenterId
          integratorId: $integratorId
        ) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateDashboardDetail">
      >
    >;
  }

  OnDeleteDashboardDetailListener(
    monitoringCenterId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteDashboardDetail">
    >
  > {
    const statement = `subscription OnDeleteDashboardDetail($monitoringCenterId: String, $integratorId: String) {
        onDeleteDashboardDetail(
          monitoringCenterId: $monitoringCenterId
          integratorId: $integratorId
        ) {
          __typename
          id
          name
          awsId
          monitoringCenterId
          allowedGroups
          integratorId
          username
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteDashboardDetail">
      >
    >;
  }

  OnCreateBoldBIDetailsListener(
    customerId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBoldBIDetails">>
  > {
    const statement = `subscription OnCreateBoldBIDetails($customerId: String) {
        onCreateBoldBIDetails(customerId: $customerId) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateBoldBIDetails">
      >
    >;
  }

  OnUpdateBoldBIDetailsListener(
    customerId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBoldBIDetails">>
  > {
    const statement = `subscription OnUpdateBoldBIDetails($customerId: String) {
        onUpdateBoldBIDetails(customerId: $customerId) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateBoldBIDetails">
      >
    >;
  }

  OnDeleteBoldBIDetailsListener(
    customerId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBoldBIDetails">>
  > {
    const statement = `subscription OnDeleteBoldBIDetails($customerId: String) {
        onDeleteBoldBIDetails(customerId: $customerId) {
          __typename
          id
          siteUsername
          sitePassword
          siteUrl
          siteDefaultDashboardId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteBoldBIDetails">
      >
    >;
  }

  OnCreateRapidSOSDetailsListener(
    customerId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSDetails">
    >
  > {
    const statement = `subscription OnCreateRapidSOSDetails($customerId: String) {
        onCreateRapidSOSDetails(customerId: $customerId) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSDetails">
      >
    >;
  }

  OnUpdateRapidSOSDetailsListener(
    customerId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSDetails">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSDetails($customerId: String) {
        onUpdateRapidSOSDetails(customerId: $customerId) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSDetails">
      >
    >;
  }

  OnDeleteRapidSOSDetailsListener(
    customerId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSDetails">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSDetails($customerId: String) {
        onDeleteRapidSOSDetails(customerId: $customerId) {
          __typename
          id
          clientId
          clientSecret
          webhookEnabled
          agencyId
          webhookId
          customerId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSDetails">
      >
    >;
  }

  OnCreateAddressListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAddress">>
  > {
    const statement = `subscription OnCreateAddress($monitoringCenterId: String) {
        onCreateAddress(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAddress">>
    >;
  }

  OnUpdateAddressListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAddress">>
  > {
    const statement = `subscription OnUpdateAddress($monitoringCenterId: String) {
        onUpdateAddress(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAddress">>
    >;
  }

  OnDeleteAddressListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAddress">>
  > {
    const statement = `subscription OnDeleteAddress($monitoringCenterId: String) {
        onDeleteAddress(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          lineOne
          lineTwo
          city
          state
          zipCode
          country
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAddress">>
    >;
  }

  OnCreateContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContact">>
  > {
    const statement = `subscription OnCreateContact($monitoringCenterId: String) {
        onCreateContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContact">>
    >;
  }

  OnUpdateContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContact">>
  > {
    const statement = `subscription OnUpdateContact($monitoringCenterId: String) {
        onUpdateContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContact">>
    >;
  }

  OnDeleteContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContact">>
  > {
    const statement = `subscription OnDeleteContact($monitoringCenterId: String) {
        onDeleteContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          firstName
          lastName
          name
          phone
          ext
          email
          monitoringCenterId
          integratorId
          modifiedBy
          customerCanShareSecurables
          customerCanLoginAdminPortal
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContact">>
    >;
  }

  OnCreatePendingSiteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePendingSite">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePendingSite {
        onCreatePendingSite {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePendingSite">>
  >;

  OnUpdatePendingSiteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePendingSite">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePendingSite {
        onUpdatePendingSite {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePendingSite">>
  >;

  OnDeletePendingSiteListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePendingSite">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePendingSite {
        onDeletePendingSite {
          __typename
          id
          name
          address {
            __typename
            lineOne
            lineTwo
            city
            state
            zipCode
            country
          }
          contacts {
            __typename
            firstName
            lastName
            phone
            email
            types
            verbalPasscode
          }
          status
          notes
          primaryEmail
          customerId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePendingSite">>
  >;

  OnCreateSiteListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSite">>
  > {
    const statement = `subscription OnCreateSite($monitoringCenterId: String) {
        onCreateSite(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSite">>
    >;
  }

  OnUpdateSiteListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSite">>
  > {
    const statement = `subscription OnUpdateSite($monitoringCenterId: String) {
        onUpdateSite(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSite">>
    >;
  }

  OnDeleteSiteListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSite">>
  > {
    const statement = `subscription OnDeleteSite($monitoringCenterId: String) {
        onDeleteSite(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          name
          status
          customerId
          integratorId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          externalCriticalNotes
          externalCriticalNotesTranslated
          externalCriticalNotesSanitized
          monitoringCenterId
          archived
          modifiedBy
          timezone
          vmsSharedMonitoringRoleId
          monitoringStatus
          maintainCamerasOnMonitoringRole
          armingRulesConfig
          monitoringState
          armedState
          alarmState
          supervisionEnabled
          triggersEnabled
          muteAfterInteractionCount
          type
          alarmsCentralStation
          alarmsCentralStationPhone
          alarmsCentralStationPhoneExt
          alarmsVerbalPasscode
          alarmsCSID
          alarmsAR
          alarmsNotes
          alarmsNotesTranslated
          alarmsNotesSanitized
          alarmsPermitNum
          alarmsPermitExpires
          autoSendToTrackTik
          hasSiteMap
          condensedAddress
          createdAt
          allowCustomerViewPendingUntil
          updatedAt
          address {
            __typename
            id
            lineOne
            lineTwo
            city
            state
            zipCode
            country
            integratorId
            monitoringCenterId
            modifiedBy
            createdAt
            updatedAt
          }
          integrations {
            __typename
            items {
              __typename
              id
              siteId
              integratorId
              monitoringCenterId
              type
              subType
              subTypeList
              agency
              phone
              phoneExt
              email
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              archived
              createdAt
              updatedAt
              trackTickDetails {
                __typename
                id
                siteIntegrationId
                siteId
                monitoringCenterId
                apiUrl
                apiUsername
                apiPassword
                accountId
                token
                refreshToken
                tokenExpiry
                refreshTokenExpiry
                autoSend
                autoSendErrorEmail
                configured
                archived
                modifiedBy
                createdAt
                updatedAt
                taskList {
                  __typename
                  items {
                    __typename
                    id
                    trackTikDetailsId
                    siteId
                    monitoringCenterId
                    eventType
                    trackTikName
                    trackTikId
                    trackTikReportTemplateId
                    archived
                    modifedBy
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
          contacts {
            __typename
            items {
              __typename
              id
              siteId
              name
              type
              verbalPasscode
              email
              priority
              monitoringCenterId
              integratorId
              archived
              notes
              notesTranslated
              notesSanitized
              modifiedBy
              createdAt
              updatedAt
              phones {
                __typename
                items {
                  __typename
                  id
                  contactId
                  type
                  number
                  ext
                  monitoringCenterId
                  integratorId
                  modifiedBy
                  archived
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          cameras {
            __typename
            items {
              __typename
              id
              name
              deviceType
              status
              vmsCameraName
              description
              descriptionTranslated
              descriptionSanitized
              vmsDescription
              siteId
              integratorId
              monitoringCenterId
              customerId
              criticalNotes
              criticalNotesTranslated
              criticalNotesSanitized
              talkdownInstructions {
                __typename
                talkdownType
                note
                noteTranslated
                noteSanitized
                phone
                url
                messageDetails {
                  __typename
                  messageType
                  messageTypeId
                  urlSuffix
                }
                hostConfiguration {
                  __typename
                  baseUrl
                  username
                  password
                  method
                }
              }
              latitude
              longitude
              vmsCameraId
              vmsCustomerId
              testingExpiry
              armedState
              snoozeReason
              snoozeReasonTranslated
              snoozeReasonSanitized
              snoozeExpiry
              archived
              zoneNumber
              zoneDescription
              zoneDescriptionTranslated
              zoneDescriptionSanitized
              modifiedBy
              isOnvif
              videoStreamState
              deviceUrlOnline
              vmsConnect
              isP2P
              isAnalyticsEnabled
              isMotionEnabled
              testEventReceived
              healthCheckEnabled
              healthCheckSLA
              healthCheckStatus
              ignoreStatusChecks
              statusChecksProperties
              statusLastVerified
              archiveDurationHours
              accessUrlsId
              credentialsId
              deviceBrand
              deviceModel
              vmsPlanId
              phpRegistrationCode
              serialNumber
              p2pState
              lastPowerCycle
              baselineThumbnailFilename
              currentThumbnailFilename
              sceneChangeDetectionEnabled
              passingSceneDetection
              hubspotId
              immixMonitoring
              hasMic
              hasSpeaker
              audioGroups
              createdAt
              updatedAt
              qualitySettings {
                __typename
                id
                width
                height
                videoBitRate
                frameRate
                monitoringCenterId
                cameraId
                modifiedBy
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          sops {
            __typename
            items {
              __typename
              id
              siteId
              eventType
              eventSOPs
              monitoringCenterId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSite">>
    >;
  }

  OnCreateSiteSupvisdSettingListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteSupvisdSetting">
    >
  > {
    const statement = `subscription OnCreateSiteSupvisdSetting($integratorId: String) {
        onCreateSiteSupvisdSetting(integratorId: $integratorId) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSiteSupvisdSetting">
      >
    >;
  }

  OnUpdateSiteSupvisdSettingListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteSupvisdSetting">
    >
  > {
    const statement = `subscription OnUpdateSiteSupvisdSetting($integratorId: String) {
        onUpdateSiteSupvisdSetting(integratorId: $integratorId) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSiteSupvisdSetting">
      >
    >;
  }

  OnDeleteSiteSupvisdSettingListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteSupvisdSetting">
    >
  > {
    const statement = `subscription OnDeleteSiteSupvisdSetting($integratorId: String) {
        onDeleteSiteSupvisdSetting(integratorId: $integratorId) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          rules {
            __typename
            items {
              __typename
              id
              cameraId
              muteSitePersonRule
              siteId
              integratorId
              monitoringCenterId
              siteSupvisdSettingId
              modifiedBy
              archived
              ruleType
              cameraEventType
              disposition
              confidence
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSiteSupvisdSetting">
      >
    >;
  }

  OnCreateSupvisdEventRuleListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSupvisdEventRule">
    >
  > {
    const statement = `subscription OnCreateSupvisdEventRule($integratorId: String) {
        onCreateSupvisdEventRule(integratorId: $integratorId) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSupvisdEventRule">
      >
    >;
  }

  OnUpdateSupvisdEventRuleListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSupvisdEventRule">
    >
  > {
    const statement = `subscription OnUpdateSupvisdEventRule($integratorId: String) {
        onUpdateSupvisdEventRule(integratorId: $integratorId) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSupvisdEventRule">
      >
    >;
  }

  OnDeleteSupvisdEventRuleListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSupvisdEventRule">
    >
  > {
    const statement = `subscription OnDeleteSupvisdEventRule($integratorId: String) {
        onDeleteSupvisdEventRule(integratorId: $integratorId) {
          __typename
          id
          cameraId
          muteSitePersonRule
          siteId
          integratorId
          monitoringCenterId
          siteSupvisdSettingId
          modifiedBy
          archived
          ruleType
          cameraEventType
          disposition
          confidence
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSupvisdEventRule">
      >
    >;
  }

  OnCreateSiteDeviceStatusConfigListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteDeviceStatusConfig">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSiteDeviceStatusConfig {
        onCreateSiteDeviceStatusConfig {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteDeviceStatusConfig">
    >
  >;

  OnUpdateSiteDeviceStatusConfigListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteDeviceStatusConfig">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSiteDeviceStatusConfig {
        onUpdateSiteDeviceStatusConfig {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteDeviceStatusConfig">
    >
  >;

  OnDeleteSiteDeviceStatusConfigListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteDeviceStatusConfig">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSiteDeviceStatusConfig {
        onDeleteSiteDeviceStatusConfig {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          deviceStatusEnabled
          statusFrequency
          statusVerifiedTolerance
          statusProperties
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteDeviceStatusConfig">
    >
  >;

  OnCreateTriggerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTrigger">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTrigger {
        onCreateTrigger {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTrigger">>
  >;

  OnUpdateTriggerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTrigger">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTrigger {
        onUpdateTrigger {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTrigger">>
  >;

  OnDeleteTriggerListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTrigger">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTrigger {
        onDeleteTrigger {
          __typename
          id
          siteId
          integratorId
          customerId
          monitoringCenterId
          type
          filters {
            __typename
            filter
            frequencyLimit
            lastTriggered
            actions {
              __typename
              action
              actionSettings
              frequencyLimit
              lastTriggered
            }
          }
          modifiedBy
          archived
          frequencyLimit
          lastTriggered
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTrigger">>
  >;

  OnCreateSiteIntegrationListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteIntegration">
    >
  > {
    const statement = `subscription OnCreateSiteIntegration($monitoringCenterId: String) {
        onCreateSiteIntegration(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSiteIntegration">
      >
    >;
  }

  OnUpdateSiteIntegrationListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteIntegration">
    >
  > {
    const statement = `subscription OnUpdateSiteIntegration($monitoringCenterId: String) {
        onUpdateSiteIntegration(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSiteIntegration">
      >
    >;
  }

  OnDeleteSiteIntegrationListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteIntegration">
    >
  > {
    const statement = `subscription OnDeleteSiteIntegration($monitoringCenterId: String) {
        onDeleteSiteIntegration(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          integratorId
          monitoringCenterId
          type
          subType
          subTypeList
          agency
          phone
          phoneExt
          email
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          archived
          createdAt
          updatedAt
          trackTickDetails {
            __typename
            id
            siteIntegrationId
            siteId
            monitoringCenterId
            apiUrl
            apiUsername
            apiPassword
            accountId
            token
            refreshToken
            tokenExpiry
            refreshTokenExpiry
            autoSend
            autoSendErrorEmail
            configured
            archived
            modifiedBy
            createdAt
            updatedAt
            taskList {
              __typename
              items {
                __typename
                id
                trackTikDetailsId
                siteId
                monitoringCenterId
                eventType
                trackTikName
                trackTikId
                trackTikReportTemplateId
                archived
                modifedBy
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSiteIntegration">
      >
    >;
  }

  OnCreateSiteVMSDetailsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteVMSDetails">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSiteVMSDetails {
        onCreateSiteVMSDetails {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteVMSDetails">
    >
  >;

  OnUpdateSiteVMSDetailsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteVMSDetails">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSiteVMSDetails {
        onUpdateSiteVMSDetails {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteVMSDetails">
    >
  >;

  OnDeleteSiteVMSDetailsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteVMSDetails">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSiteVMSDetails {
        onDeleteSiteVMSDetails {
          __typename
          id
          username
          password
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          siteId
          siteUserId
          siteUserRoleId
          integratorId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteVMSDetails">
    >
  >;

  OnCreateTrackTikDetailsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTrackTikDetails">
    >
  > {
    const statement = `subscription OnCreateTrackTikDetails($monitoringCenterId: String) {
        onCreateTrackTikDetails(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTrackTikDetails">
      >
    >;
  }

  OnUpdateTrackTikDetailsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTrackTikDetails">
    >
  > {
    const statement = `subscription OnUpdateTrackTikDetails($monitoringCenterId: String) {
        onUpdateTrackTikDetails(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTrackTikDetails">
      >
    >;
  }

  OnDeleteTrackTikDetailsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTrackTikDetails">
    >
  > {
    const statement = `subscription OnDeleteTrackTikDetails($monitoringCenterId: String) {
        onDeleteTrackTikDetails(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteIntegrationId
          siteId
          monitoringCenterId
          apiUrl
          apiUsername
          apiPassword
          accountId
          token
          refreshToken
          tokenExpiry
          refreshTokenExpiry
          autoSend
          autoSendErrorEmail
          configured
          archived
          modifiedBy
          createdAt
          updatedAt
          taskList {
            __typename
            items {
              __typename
              id
              trackTikDetailsId
              siteId
              monitoringCenterId
              eventType
              trackTikName
              trackTikId
              trackTikReportTemplateId
              archived
              modifedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTrackTikDetails">
      >
    >;
  }

  OnCreateTrackTikTaskListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTrackTikTask">>
  > {
    const statement = `subscription OnCreateTrackTikTask($monitoringCenterId: String) {
        onCreateTrackTikTask(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTrackTikTask">
      >
    >;
  }

  OnUpdateTrackTikTaskListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTrackTikTask">>
  > {
    const statement = `subscription OnUpdateTrackTikTask($monitoringCenterId: String) {
        onUpdateTrackTikTask(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTrackTikTask">
      >
    >;
  }

  OnDeleteTrackTikTaskListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTrackTikTask">>
  > {
    const statement = `subscription OnDeleteTrackTikTask($monitoringCenterId: String) {
        onDeleteTrackTikTask(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          trackTikDetailsId
          siteId
          monitoringCenterId
          eventType
          trackTikName
          trackTikId
          trackTikReportTemplateId
          archived
          modifedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTrackTikTask">
      >
    >;
  }

  OnCreateSiteEmergencyContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteEmergencyContact">
    >
  > {
    const statement = `subscription OnCreateSiteEmergencyContact($monitoringCenterId: String) {
        onCreateSiteEmergencyContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSiteEmergencyContact">
      >
    >;
  }

  OnUpdateSiteEmergencyContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteEmergencyContact">
    >
  > {
    const statement = `subscription OnUpdateSiteEmergencyContact($monitoringCenterId: String) {
        onUpdateSiteEmergencyContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSiteEmergencyContact">
      >
    >;
  }

  OnDeleteSiteEmergencyContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteEmergencyContact">
    >
  > {
    const statement = `subscription OnDeleteSiteEmergencyContact($monitoringCenterId: String) {
        onDeleteSiteEmergencyContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          name
          type
          verbalPasscode
          email
          priority
          monitoringCenterId
          integratorId
          archived
          notes
          notesTranslated
          notesSanitized
          modifiedBy
          createdAt
          updatedAt
          phones {
            __typename
            items {
              __typename
              id
              contactId
              type
              number
              ext
              monitoringCenterId
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSiteEmergencyContact">
      >
    >;
  }

  OnCreatePhoneListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePhone">>
  > {
    const statement = `subscription OnCreatePhone($monitoringCenterId: String) {
        onCreatePhone(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePhone">>
    >;
  }

  OnUpdatePhoneListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePhone">>
  > {
    const statement = `subscription OnUpdatePhone($monitoringCenterId: String) {
        onUpdatePhone(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePhone">>
    >;
  }

  OnDeletePhoneListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePhone">>
  > {
    const statement = `subscription OnDeletePhone($monitoringCenterId: String) {
        onDeletePhone(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          contactId
          type
          number
          ext
          monitoringCenterId
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePhone">>
    >;
  }

  OnCreateCameraListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCamera">>
  > {
    const statement = `subscription OnCreateCamera($monitoringCenterId: String) {
        onCreateCamera(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCamera">>
    >;
  }

  OnUpdateCameraListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCamera">>
  > {
    const statement = `subscription OnUpdateCamera($monitoringCenterId: String) {
        onUpdateCamera(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCamera">>
    >;
  }

  OnDeleteCameraListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCamera">>
  > {
    const statement = `subscription OnDeleteCamera($monitoringCenterId: String) {
        onDeleteCamera(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          name
          deviceType
          status
          vmsCameraName
          description
          descriptionTranslated
          descriptionSanitized
          vmsDescription
          siteId
          integratorId
          monitoringCenterId
          customerId
          criticalNotes
          criticalNotesTranslated
          criticalNotesSanitized
          talkdownInstructions {
            __typename
            talkdownType
            note
            noteTranslated
            noteSanitized
            phone
            url
            messageDetails {
              __typename
              messageType
              messageTypeId
              urlSuffix
            }
            hostConfiguration {
              __typename
              baseUrl
              username
              password
              method
            }
          }
          latitude
          longitude
          vmsCameraId
          vmsCustomerId
          testingExpiry
          armedState
          snoozeReason
          snoozeReasonTranslated
          snoozeReasonSanitized
          snoozeExpiry
          archived
          zoneNumber
          zoneDescription
          zoneDescriptionTranslated
          zoneDescriptionSanitized
          modifiedBy
          isOnvif
          videoStreamState
          deviceUrlOnline
          vmsConnect
          isP2P
          isAnalyticsEnabled
          isMotionEnabled
          testEventReceived
          healthCheckEnabled
          healthCheckSLA
          healthCheckStatus
          ignoreStatusChecks
          statusChecksProperties
          statusLastVerified
          archiveDurationHours
          accessUrlsId
          credentialsId
          deviceBrand
          deviceModel
          vmsPlanId
          phpRegistrationCode
          serialNumber
          p2pState
          lastPowerCycle
          baselineThumbnailFilename
          currentThumbnailFilename
          sceneChangeDetectionEnabled
          passingSceneDetection
          hubspotId
          immixMonitoring
          hasMic
          hasSpeaker
          audioGroups
          createdAt
          updatedAt
          qualitySettings {
            __typename
            id
            width
            height
            videoBitRate
            frameRate
            monitoringCenterId
            cameraId
            modifiedBy
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCamera">>
    >;
  }

  OnCreateObjectGroupListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateObjectGroup">>
  > {
    const statement = `subscription OnCreateObjectGroup($monitoringCenterId: String) {
        onCreateObjectGroup(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateObjectGroup">>
    >;
  }

  OnUpdateObjectGroupListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateObjectGroup">>
  > {
    const statement = `subscription OnUpdateObjectGroup($monitoringCenterId: String) {
        onUpdateObjectGroup(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateObjectGroup">>
    >;
  }

  OnDeleteObjectGroupListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteObjectGroup">>
  > {
    const statement = `subscription OnDeleteObjectGroup($monitoringCenterId: String) {
        onDeleteObjectGroup(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          groupType
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteObjectGroup">>
    >;
  }

  OnCreateAudioCustomMessageTypeListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAudioCustomMessageType">
    >
  > {
    const statement = `subscription OnCreateAudioCustomMessageType($monitoringCenterId: String) {
        onCreateAudioCustomMessageType(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAudioCustomMessageType">
      >
    >;
  }

  OnUpdateAudioCustomMessageTypeListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAudioCustomMessageType">
    >
  > {
    const statement = `subscription OnUpdateAudioCustomMessageType($monitoringCenterId: String) {
        onUpdateAudioCustomMessageType(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAudioCustomMessageType">
      >
    >;
  }

  OnDeleteAudioCustomMessageTypeListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAudioCustomMessageType">
    >
  > {
    const statement = `subscription OnDeleteAudioCustomMessageType($monitoringCenterId: String) {
        onDeleteAudioCustomMessageType(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          name
          nameTranslated
          nameSanitized
          transcript
          transcriptTranslated
          transcriptSanitized
          note
          noteTranslated
          noteSanitized
          archived
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAudioCustomMessageType">
      >
    >;
  }

  OnCreateCameraQualitySettingsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraQualitySettings">
    >
  > {
    const statement = `subscription OnCreateCameraQualitySettings($monitoringCenterId: String) {
        onCreateCameraQualitySettings(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCameraQualitySettings">
      >
    >;
  }

  OnUpdateCameraQualitySettingsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraQualitySettings">
    >
  > {
    const statement = `subscription OnUpdateCameraQualitySettings($monitoringCenterId: String) {
        onUpdateCameraQualitySettings(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCameraQualitySettings">
      >
    >;
  }

  OnDeleteCameraQualitySettingsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraQualitySettings">
    >
  > {
    const statement = `subscription OnDeleteCameraQualitySettings($monitoringCenterId: String) {
        onDeleteCameraQualitySettings(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          width
          height
          videoBitRate
          frameRate
          monitoringCenterId
          cameraId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCameraQualitySettings">
      >
    >;
  }

  OnCreateCameraSceneDetectionSettingsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionSettings">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCameraSceneDetectionSettings {
        onCreateCameraSceneDetectionSettings {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionSettings">
    >
  >;

  OnUpdateCameraSceneDetectionSettingsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionSettings">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCameraSceneDetectionSettings {
        onUpdateCameraSceneDetectionSettings {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionSettings">
    >
  >;

  OnDeleteCameraSceneDetectionSettingsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionSettings">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCameraSceneDetectionSettings {
        onDeleteCameraSceneDetectionSettings {
          __typename
          id
          cameraId
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
          thresholds {
            __typename
            items {
              __typename
              id
              cameraId
              cameraSceneDetectionSettingsId
              monitoringCenterId
              modifiedBy
              archived
              objectName
              baselineValue
              thresholdValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionSettings">
    >
  >;

  OnCreateCameraSceneDetectionThresholdListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionThreshold">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCameraSceneDetectionThreshold {
        onCreateCameraSceneDetectionThreshold {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionThreshold">
    >
  >;

  OnUpdateCameraSceneDetectionThresholdListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionThreshold">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCameraSceneDetectionThreshold {
        onUpdateCameraSceneDetectionThreshold {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionThreshold">
    >
  >;

  OnDeleteCameraSceneDetectionThresholdListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionThreshold">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCameraSceneDetectionThreshold {
        onDeleteCameraSceneDetectionThreshold {
          __typename
          id
          cameraId
          cameraSceneDetectionSettingsId
          monitoringCenterId
          modifiedBy
          archived
          objectName
          baselineValue
          thresholdValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionThreshold">
    >
  >;

  OnCreateCameraSceneDetectionResultListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionResult">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCameraSceneDetectionResult {
        onCreateCameraSceneDetectionResult {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionResult">
    >
  >;

  OnUpdateCameraSceneDetectionResultListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionResult">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCameraSceneDetectionResult {
        onUpdateCameraSceneDetectionResult {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionResult">
    >
  >;

  OnDeleteCameraSceneDetectionResultListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionResult">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCameraSceneDetectionResult {
        onDeleteCameraSceneDetectionResult {
          __typename
          id
          cameraId
          passing
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          items {
            __typename
            items {
              __typename
              id
              cameraSceneDetectionResultId
              cameraId
              monitoringCenterId
              modifiedBy
              objectName
              thresholdValue
              actualValue
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionResult">
    >
  >;

  OnCreateCameraSceneDetectionResultItemListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionResultItem">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCameraSceneDetectionResultItem {
        onCreateCameraSceneDetectionResultItem {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraSceneDetectionResultItem">
    >
  >;

  OnUpdateCameraSceneDetectionResultItemListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionResultItem">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCameraSceneDetectionResultItem {
        onUpdateCameraSceneDetectionResultItem {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraSceneDetectionResultItem">
    >
  >;

  OnDeleteCameraSceneDetectionResultItemListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionResultItem">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCameraSceneDetectionResultItem {
        onDeleteCameraSceneDetectionResultItem {
          __typename
          id
          cameraSceneDetectionResultId
          cameraId
          monitoringCenterId
          modifiedBy
          objectName
          thresholdValue
          actualValue
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraSceneDetectionResultItem">
    >
  >;

  OnCreateCameraCredentialsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraCredentials">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCameraCredentials {
        onCreateCameraCredentials {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraCredentials">
    >
  >;

  OnUpdateCameraCredentialsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraCredentials">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCameraCredentials {
        onUpdateCameraCredentials {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraCredentials">
    >
  >;

  OnDeleteCameraCredentialsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraCredentials">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCameraCredentials {
        onDeleteCameraCredentials {
          __typename
          id
          name
          password
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraCredentials">
    >
  >;

  OnCreateCameraAccessUrlsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraAccessUrls">
    >
  > {
    const statement = `subscription OnCreateCameraAccessUrls($monitoringCenterId: String) {
        onCreateCameraAccessUrls(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCameraAccessUrls">
      >
    >;
  }

  OnUpdateCameraAccessUrlsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraAccessUrls">
    >
  > {
    const statement = `subscription OnUpdateCameraAccessUrls($monitoringCenterId: String) {
        onUpdateCameraAccessUrls(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCameraAccessUrls">
      >
    >;
  }

  OnDeleteCameraAccessUrlsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraAccessUrls">
    >
  > {
    const statement = `subscription OnDeleteCameraAccessUrls($monitoringCenterId: String) {
        onDeleteCameraAccessUrls(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          dashStream
          hlsStream
          http
          rtmpStream
          rtsp
          snapShot
          webRtc
          cameraId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCameraAccessUrls">
      >
    >;
  }

  OnCreateCameraImmixConfigListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraImmixConfig">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCameraImmixConfig {
        onCreateCameraImmixConfig {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCameraImmixConfig">
    >
  >;

  OnUpdateCameraImmixConfigListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraImmixConfig">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCameraImmixConfig {
        onUpdateCameraImmixConfig {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCameraImmixConfig">
    >
  >;

  OnDeleteCameraImmixConfigListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraImmixConfig">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCameraImmixConfig {
        onDeleteCameraImmixConfig {
          __typename
          id
          cameraId
          vmsCameraId
          vmsWebhookId
          immixEmail
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCameraImmixConfig">
    >
  >;

  OnCreateActivityLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateActivityLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateActivityLog {
        onCreateActivityLog {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateActivityLog">>
  >;

  OnUpdateActivityLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateActivityLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateActivityLog {
        onUpdateActivityLog {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateActivityLog">>
  >;

  OnDeleteActivityLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteActivityLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteActivityLog {
        onDeleteActivityLog {
          __typename
          id
          monitoringCenterId
          integratorId
          customerId
          siteId
          cameraId
          modifiedOn
          modifiedBy
          type
          subSystem
          event
          reference
          description
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteActivityLog">>
  >;

  OnCreateSignalTestLogListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSignalTestLog">>
  > {
    const statement = `subscription OnCreateSignalTestLog($integratorId: String) {
        onCreateSignalTestLog(integratorId: $integratorId) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSignalTestLog">
      >
    >;
  }

  OnUpdateSignalTestLogListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSignalTestLog">>
  > {
    const statement = `subscription OnUpdateSignalTestLog($integratorId: String) {
        onUpdateSignalTestLog(integratorId: $integratorId) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSignalTestLog">
      >
    >;
  }

  OnDeleteSignalTestLogListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSignalTestLog">>
  > {
    const statement = `subscription OnDeleteSignalTestLog($integratorId: String) {
        onDeleteSignalTestLog(integratorId: $integratorId) {
          __typename
          id
          cameraId
          siteId
          customerId
          integratorId
          monitoringCenterId
          createdAt
          modifiedBy
          vmsEventId
          objectsFound {
            __typename
            object
            confidence
          }
          starred
          thumbnailFilename
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSignalTestLog">
      >
    >;
  }

  OnCreateAuditLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAuditLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAuditLog {
        onCreateAuditLog {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAuditLog">>
  >;

  OnUpdateAuditLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAuditLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAuditLog {
        onUpdateAuditLog {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAuditLog">>
  >;

  OnDeleteAuditLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAuditLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAuditLog {
        onDeleteAuditLog {
          __typename
          id
          partition
          monitoringCenterId
          modifiedOn
          modifiedBy
          area
          action
          entityModel
          entityKey
          description
          newImage
          oldImage
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAuditLog">>
  >;

  OnCreateIncidentActionDetailListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentActionDetail">
    >
  > {
    const statement = `subscription OnCreateIncidentActionDetail($monitoringCenterId: String) {
        onCreateIncidentActionDetail(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIncidentActionDetail">
      >
    >;
  }

  OnUpdateIncidentActionDetailListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentActionDetail">
    >
  > {
    const statement = `subscription OnUpdateIncidentActionDetail($monitoringCenterId: String) {
        onUpdateIncidentActionDetail(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIncidentActionDetail">
      >
    >;
  }

  OnDeleteIncidentActionDetailListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentActionDetail">
    >
  > {
    const statement = `subscription OnDeleteIncidentActionDetail($monitoringCenterId: String) {
        onDeleteIncidentActionDetail(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          createdAt
          modifiedBy
          description
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIncidentActionDetail">
      >
    >;
  }

  OnCreateSOPListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSOP">>
  > {
    const statement = `subscription OnCreateSOP($monitoringCenterId: String) {
        onCreateSOP(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSOP">>
    >;
  }

  OnUpdateSOPListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSOP">>
  > {
    const statement = `subscription OnUpdateSOP($monitoringCenterId: String) {
        onUpdateSOP(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSOP">>
    >;
  }

  OnDeleteSOPListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSOP">>
  > {
    const statement = `subscription OnDeleteSOP($monitoringCenterId: String) {
        onDeleteSOP(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          siteId
          eventType
          eventSOPs
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSOP">>
    >;
  }

  OnCreateRspndrEventsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRspndrEvents">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateRspndrEvents {
        onCreateRspndrEvents {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRspndrEvents">>
  >;

  OnUpdateRspndrEventsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRspndrEvents">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateRspndrEvents {
        onUpdateRspndrEvents {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRspndrEvents">>
  >;

  OnDeleteRspndrEventsListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRspndrEvents">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteRspndrEvents {
        onDeleteRspndrEvents {
          __typename
          id
          request_id
          tenant_id
          error
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRspndrEvents">>
  >;

  OnCreateRspndrIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRspndrIncident">
    >
  > {
    const statement = `subscription OnCreateRspndrIncident($monitoringCenterId: String) {
        onCreateRspndrIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRspndrIncident">
      >
    >;
  }

  OnUpdateRspndrIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRspndrIncident">
    >
  > {
    const statement = `subscription OnUpdateRspndrIncident($monitoringCenterId: String) {
        onUpdateRspndrIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRspndrIncident">
      >
    >;
  }

  OnDeleteRspndrIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRspndrIncident">
    >
  > {
    const statement = `subscription OnDeleteRspndrIncident($monitoringCenterId: String) {
        onDeleteRspndrIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incident_id
          address1
          address2
          arrived_at
          billed_to
          city
          country
          created_at
          customer_number
          dealer
          eta_at
          ext_username
          finished_at
          grounded_at
          grounding_end_confirm_acked_at
          grounding_end_confirmed_at
          grounding_end_decline_acked_at
          grounding_end_declined_at
          grounding_ended_at
          grounding_ending_at
          grounding_request_confirm_acked_at
          grounding_request_confirmed_at
          grounding_request_decline_acked_at
          grounding_request_declined_at
          grounding_requested_at
          grounding_terminate_acked_at
          grounding_terminating_at
          kind
          lat
          lng
          master_account_number
          modified_at
          name
          notes
          notesTranslated
          notesSanitized
          postal_code
          state
          status
          taken_at
          tenant_id
          timezone
          triggered_at
          type
          zone
          monitoringCenterId
          customerId
          integratorId
          siteId
          modifiedBy
          createdAt
          updatedAt
          check_list {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              description
              descriptionTranslated
              descriptionSanitized
              image_required
              image_url
              order
              required
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          rules {
            __typename
            items {
              __typename
              id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              call_police
              expires_at
              leave_when
              max_duration
              order
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              tenant_id
              request_id
              incidentId
              rspndrIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              type
              notes
              notesTranslated
              notesSanitized
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRspndrIncident">
      >
    >;
  }

  OnCreateRspndrIncidentCheckItemListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRspndrIncidentCheckItem">
    >
  > {
    const statement = `subscription OnCreateRspndrIncidentCheckItem($monitoringCenterId: String) {
        onCreateRspndrIncidentCheckItem(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRspndrIncidentCheckItem">
      >
    >;
  }

  OnUpdateRspndrIncidentCheckItemListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRspndrIncidentCheckItem">
    >
  > {
    const statement = `subscription OnUpdateRspndrIncidentCheckItem($monitoringCenterId: String) {
        onUpdateRspndrIncidentCheckItem(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRspndrIncidentCheckItem">
      >
    >;
  }

  OnDeleteRspndrIncidentCheckItemListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRspndrIncidentCheckItem">
    >
  > {
    const statement = `subscription OnDeleteRspndrIncidentCheckItem($monitoringCenterId: String) {
        onDeleteRspndrIncidentCheckItem(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          description
          descriptionTranslated
          descriptionSanitized
          image_required
          image_url
          order
          required
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRspndrIncidentCheckItem">
      >
    >;
  }

  OnCreateRspndrIncidentGroundingRuleListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRspndrIncidentGroundingRule">
    >
  > {
    const statement = `subscription OnCreateRspndrIncidentGroundingRule($monitoringCenterId: String) {
        onCreateRspndrIncidentGroundingRule(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRspndrIncidentGroundingRule">
      >
    >;
  }

  OnUpdateRspndrIncidentGroundingRuleListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRspndrIncidentGroundingRule">
    >
  > {
    const statement = `subscription OnUpdateRspndrIncidentGroundingRule($monitoringCenterId: String) {
        onUpdateRspndrIncidentGroundingRule(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRspndrIncidentGroundingRule">
      >
    >;
  }

  OnDeleteRspndrIncidentGroundingRuleListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRspndrIncidentGroundingRule">
    >
  > {
    const statement = `subscription OnDeleteRspndrIncidentGroundingRule($monitoringCenterId: String) {
        onDeleteRspndrIncidentGroundingRule(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          call_police
          expires_at
          leave_when
          max_duration
          order
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRspndrIncidentGroundingRule">
      >
    >;
  }

  OnCreateRspndrActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRspndrActivity">
    >
  > {
    const statement = `subscription OnCreateRspndrActivity($monitoringCenterId: String) {
        onCreateRspndrActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRspndrActivity">
      >
    >;
  }

  OnUpdateRspndrActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRspndrActivity">
    >
  > {
    const statement = `subscription OnUpdateRspndrActivity($monitoringCenterId: String) {
        onUpdateRspndrActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRspndrActivity">
      >
    >;
  }

  OnDeleteRspndrActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRspndrActivity">
    >
  > {
    const statement = `subscription OnDeleteRspndrActivity($monitoringCenterId: String) {
        onDeleteRspndrActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          tenant_id
          request_id
          incidentId
          rspndrIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          type
          notes
          notesTranslated
          notesSanitized
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRspndrActivity">
      >
    >;
  }

  OnCreateTrackTikIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTrackTikIncident">
    >
  > {
    const statement = `subscription OnCreateTrackTikIncident($monitoringCenterId: String) {
        onCreateTrackTikIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTrackTikIncident">
      >
    >;
  }

  OnUpdateTrackTikIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTrackTikIncident">
    >
  > {
    const statement = `subscription OnUpdateTrackTikIncident($monitoringCenterId: String) {
        onUpdateTrackTikIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTrackTikIncident">
      >
    >;
  }

  OnDeleteTrackTikIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTrackTikIncident">
    >
  > {
    const statement = `subscription OnDeleteTrackTikIncident($monitoringCenterId: String) {
        onDeleteTrackTikIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          status
          reportTemplateId
          taskTypeId
          taskTypeName
          priority
          trackTikId
          primaryCameraEventId
          modifiedBy
          createdAt
          updatedAt
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              trackTikIncidentId
              monitoringCenterId
              notes
              payload
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTrackTikIncident">
      >
    >;
  }

  OnCreateTrackTikActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTrackTikActivity">
    >
  > {
    const statement = `subscription OnCreateTrackTikActivity($monitoringCenterId: String) {
        onCreateTrackTikActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTrackTikActivity">
      >
    >;
  }

  OnUpdateTrackTikActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTrackTikActivity">
    >
  > {
    const statement = `subscription OnUpdateTrackTikActivity($monitoringCenterId: String) {
        onUpdateTrackTikActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTrackTikActivity">
      >
    >;
  }

  OnDeleteTrackTikActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTrackTikActivity">
    >
  > {
    const statement = `subscription OnDeleteTrackTikActivity($monitoringCenterId: String) {
        onDeleteTrackTikActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          trackTikIncidentId
          monitoringCenterId
          notes
          payload
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTrackTikActivity">
      >
    >;
  }

  OnCreateRapidSOSIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSIncident">
    >
  > {
    const statement = `subscription OnCreateRapidSOSIncident($monitoringCenterId: String) {
        onCreateRapidSOSIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSIncident">
      >
    >;
  }

  OnUpdateRapidSOSIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSIncident">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSIncident($monitoringCenterId: String) {
        onUpdateRapidSOSIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSIncident">
      >
    >;
  }

  OnDeleteRapidSOSIncidentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSIncident">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSIncident($monitoringCenterId: String) {
        onDeleteRapidSOSIncident(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          coverageType
          alert_id
          covering_psap
          callflow
          zone_description
          alarm_permit_number
          visually_verified
          site_phone
          status
          instructions
          createdAt
          modifiedBy
          primaryCameraEventId
          siteIntegrationId
          incidentId
          siteId
          customerId
          monitoringCenterId
          updatedAt
          event {
            __typename
            id
            rapidSOSIncidentId
            description
            descriptionTranslated
            descriptionSanitized
            emergency_type
            incident_time
            service_provider_name
            site_type
            source_id
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            location {
              __typename
              id
              rapidSOSIncidentId
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
              civic {
                __typename
                id
                rapidSOSIncidentId
                name
                street_1
                street_2
                city
                state
                country
                zip_code
                modifiedBy
                monitoringCenterId
                createdAt
                updatedAt
              }
            }
          }
          emergency_contacts {
            __typename
            items {
              __typename
              id
              rapidSOSIncidentId
              name
              phone
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
            nextToken
          }
          activities {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              error
              payload
              modifiedBy
              createdAt
              notes
              notesTranslated
              notesSanitized
              updatedAt
              logMessage {
                __typename
                id
                incidentId
                siteIntegrationId
                rapidSOSIncidentId
                monitoringCenterId
                type
                modifiedBy
                writtenBy
                message
                messageTranslated
                messageSanitized
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          attachments {
            __typename
            items {
              __typename
              id
              incidentId
              siteIntegrationId
              rapidSOSIncidentId
              monitoringCenterId
              modifiedBy
              fileName
              createdAt
              updatedAt
            }
            nextToken
          }
          additionalDataToSend {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSIncident">
      >
    >;
  }

  OnCreateRapidSOSEventDetailsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSEventDetails">
    >
  > {
    const statement = `subscription OnCreateRapidSOSEventDetails($monitoringCenterId: String) {
        onCreateRapidSOSEventDetails(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSEventDetails">
      >
    >;
  }

  OnUpdateRapidSOSEventDetailsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSEventDetails">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSEventDetails($monitoringCenterId: String) {
        onUpdateRapidSOSEventDetails(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSEventDetails">
      >
    >;
  }

  OnDeleteRapidSOSEventDetailsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSEventDetails">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSEventDetails($monitoringCenterId: String) {
        onDeleteRapidSOSEventDetails(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          description
          descriptionTranslated
          descriptionSanitized
          emergency_type
          incident_time
          service_provider_name
          site_type
          source_id
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          location {
            __typename
            id
            rapidSOSIncidentId
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
            civic {
              __typename
              id
              rapidSOSIncidentId
              name
              street_1
              street_2
              city
              state
              country
              zip_code
              modifiedBy
              monitoringCenterId
              createdAt
              updatedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSEventDetails">
      >
    >;
  }

  OnCreateRapidSOSLocationListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSLocation">
    >
  > {
    const statement = `subscription OnCreateRapidSOSLocation($monitoringCenterId: String) {
        onCreateRapidSOSLocation(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSLocation">
      >
    >;
  }

  OnUpdateRapidSOSLocationListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSLocation">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSLocation($monitoringCenterId: String) {
        onUpdateRapidSOSLocation(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSLocation">
      >
    >;
  }

  OnDeleteRapidSOSLocationListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSLocation">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSLocation($monitoringCenterId: String) {
        onDeleteRapidSOSLocation(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
          civic {
            __typename
            id
            rapidSOSIncidentId
            name
            street_1
            street_2
            city
            state
            country
            zip_code
            modifiedBy
            monitoringCenterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSLocation">
      >
    >;
  }

  OnCreateRapidSOSCivicAddressListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSCivicAddress">
    >
  > {
    const statement = `subscription OnCreateRapidSOSCivicAddress($monitoringCenterId: String) {
        onCreateRapidSOSCivicAddress(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSCivicAddress">
      >
    >;
  }

  OnUpdateRapidSOSCivicAddressListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSCivicAddress">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSCivicAddress($monitoringCenterId: String) {
        onUpdateRapidSOSCivicAddress(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSCivicAddress">
      >
    >;
  }

  OnDeleteRapidSOSCivicAddressListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSCivicAddress">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSCivicAddress($monitoringCenterId: String) {
        onDeleteRapidSOSCivicAddress(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          name
          street_1
          street_2
          city
          state
          country
          zip_code
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSCivicAddress">
      >
    >;
  }

  OnCreateRapidSOSEmergencyContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSEmergencyContact">
    >
  > {
    const statement = `subscription OnCreateRapidSOSEmergencyContact($monitoringCenterId: String) {
        onCreateRapidSOSEmergencyContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSEmergencyContact">
      >
    >;
  }

  OnUpdateRapidSOSEmergencyContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSEmergencyContact">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSEmergencyContact($monitoringCenterId: String) {
        onUpdateRapidSOSEmergencyContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSEmergencyContact">
      >
    >;
  }

  OnDeleteRapidSOSEmergencyContactListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSEmergencyContact">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSEmergencyContact($monitoringCenterId: String) {
        onDeleteRapidSOSEmergencyContact(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          rapidSOSIncidentId
          name
          phone
          modifiedBy
          monitoringCenterId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSEmergencyContact">
      >
    >;
  }

  OnCreateRapidSOSActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSActivity">
    >
  > {
    const statement = `subscription OnCreateRapidSOSActivity($monitoringCenterId: String) {
        onCreateRapidSOSActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSActivity">
      >
    >;
  }

  OnUpdateRapidSOSActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSActivity">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSActivity($monitoringCenterId: String) {
        onUpdateRapidSOSActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSActivity">
      >
    >;
  }

  OnDeleteRapidSOSActivityListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSActivity">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSActivity($monitoringCenterId: String) {
        onDeleteRapidSOSActivity(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          error
          payload
          modifiedBy
          createdAt
          notes
          notesTranslated
          notesSanitized
          updatedAt
          logMessage {
            __typename
            id
            incidentId
            siteIntegrationId
            rapidSOSIncidentId
            monitoringCenterId
            type
            modifiedBy
            writtenBy
            message
            messageTranslated
            messageSanitized
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSActivity">
      >
    >;
  }

  OnCreateRapidSOSLogMessageListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSLogMessage">
    >
  > {
    const statement = `subscription OnCreateRapidSOSLogMessage($monitoringCenterId: String) {
        onCreateRapidSOSLogMessage(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSLogMessage">
      >
    >;
  }

  OnUpdateRapidSOSLogMessageListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSLogMessage">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSLogMessage($monitoringCenterId: String) {
        onUpdateRapidSOSLogMessage(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSLogMessage">
      >
    >;
  }

  OnDeleteRapidSOSLogMessageListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSLogMessage">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSLogMessage($monitoringCenterId: String) {
        onDeleteRapidSOSLogMessage(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          type
          modifiedBy
          writtenBy
          message
          messageTranslated
          messageSanitized
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSLogMessage">
      >
    >;
  }

  OnCreateRapidSOSAttachmentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRapidSOSAttachment">
    >
  > {
    const statement = `subscription OnCreateRapidSOSAttachment($monitoringCenterId: String) {
        onCreateRapidSOSAttachment(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRapidSOSAttachment">
      >
    >;
  }

  OnUpdateRapidSOSAttachmentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRapidSOSAttachment">
    >
  > {
    const statement = `subscription OnUpdateRapidSOSAttachment($monitoringCenterId: String) {
        onUpdateRapidSOSAttachment(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRapidSOSAttachment">
      >
    >;
  }

  OnDeleteRapidSOSAttachmentListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRapidSOSAttachment">
    >
  > {
    const statement = `subscription OnDeleteRapidSOSAttachment($monitoringCenterId: String) {
        onDeleteRapidSOSAttachment(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          siteIntegrationId
          rapidSOSIncidentId
          monitoringCenterId
          modifiedBy
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRapidSOSAttachment">
      >
    >;
  }

  OnCreateIncidentCodeDetailListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentCodeDetail">
    >
  > {
    const statement = `subscription OnCreateIncidentCodeDetail($monitoringCenterId: String) {
        onCreateIncidentCodeDetail(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIncidentCodeDetail">
      >
    >;
  }

  OnUpdateIncidentCodeDetailListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentCodeDetail">
    >
  > {
    const statement = `subscription OnUpdateIncidentCodeDetail($monitoringCenterId: String) {
        onUpdateIncidentCodeDetail(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIncidentCodeDetail">
      >
    >;
  }

  OnDeleteIncidentCodeDetailListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentCodeDetail">
    >
  > {
    const statement = `subscription OnDeleteIncidentCodeDetail($monitoringCenterId: String) {
        onDeleteIncidentCodeDetail(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          publicCode
          shareUrl
          locationSid
          deviceSid
          eventDate
          modifiedBy
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIncidentCodeDetail">
      >
    >;
  }

  OnCreateIncidentShareSettingsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareSettings">
    >
  > {
    const statement = `subscription OnCreateIncidentShareSettings($monitoringCenterId: String) {
        onCreateIncidentShareSettings(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIncidentShareSettings">
      >
    >;
  }

  OnUpdateIncidentShareSettingsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareSettings">
    >
  > {
    const statement = `subscription OnUpdateIncidentShareSettings($monitoringCenterId: String) {
        onUpdateIncidentShareSettings(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIncidentShareSettings">
      >
    >;
  }

  OnDeleteIncidentShareSettingsListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareSettings">
    >
  > {
    const statement = `subscription OnDeleteIncidentShareSettings($monitoringCenterId: String) {
        onDeleteIncidentShareSettings(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          code
          modifiedBy
          expiration
          createdAt
          updatedAt
          incidentDataToShare {
            __typename
            id
            incidentId
            monitoringCenterId
            shareType
            externalIncidentNotes
            externalSiteCriticalNotes
            externalClientCriticalNotes
            completedActionItems
            objectsFound
            alarmStationInfo
            customerContactInfo
            videoFeedUrls
            modifiedBy
            attachmentsList
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIncidentShareSettings">
      >
    >;
  }

  OnCreateIncidentDataToShareListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentDataToShare">
    >
  > {
    const statement = `subscription OnCreateIncidentDataToShare($monitoringCenterId: String) {
        onCreateIncidentDataToShare(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIncidentDataToShare">
      >
    >;
  }

  OnUpdateIncidentDataToShareListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentDataToShare">
    >
  > {
    const statement = `subscription OnUpdateIncidentDataToShare($monitoringCenterId: String) {
        onUpdateIncidentDataToShare(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIncidentDataToShare">
      >
    >;
  }

  OnDeleteIncidentDataToShareListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentDataToShare">
    >
  > {
    const statement = `subscription OnDeleteIncidentDataToShare($monitoringCenterId: String) {
        onDeleteIncidentDataToShare(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          incidentId
          monitoringCenterId
          shareType
          externalIncidentNotes
          externalSiteCriticalNotes
          externalClientCriticalNotes
          completedActionItems
          objectsFound
          alarmStationInfo
          customerContactInfo
          videoFeedUrls
          modifiedBy
          attachmentsList
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIncidentDataToShare">
      >
    >;
  }

  OnCreateIncidentShareEntryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEntry">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareEntry {
        onCreateIncidentShareEntry {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEntry">
    >
  >;

  OnUpdateIncidentShareEntryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEntry">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareEntry {
        onUpdateIncidentShareEntry {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEntry">
    >
  >;

  OnDeleteIncidentShareEntryListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEntry">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareEntry {
        onDeleteIncidentShareEntry {
          __typename
          id
          incidentDataToShareId
          incidentId
          code
          shareType
          expiration
          alarmsCentralStation
          alarmsPermitNum
          alarmsCSID
          attachmentsList
          incidentOpenTime
          incidentClosedTime
          externalSiteNotes
          externalClientNotes
          createdAt
          updatedAt
          site {
            __typename
            id
            name
            addressOne
            addressTwo
            city
            state
            country
            zipCode
            createdAt
            updatedAt
          }
          integrator {
            __typename
            id
            name
            logoPath
            createdAt
            updatedAt
          }
          notes {
            __typename
            items {
              __typename
              id
              incidentId
              incidentNoteId
              incidentShareEntryId
              note
              noteCreatedAt
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            __typename
            items {
              __typename
              id
              cameraEventId
              startTime
              vmsCameraName
              videoFeedUrl
              eventType
              disposition
              triggerType
              objectsFound
              incidentShareEntryId
              cameraLatitude
              cameraLongitude
              createdAt
              updatedAt
              actionItems {
                __typename
                items {
                  __typename
                  id
                  incidentProcedureTaskId
                  incidentShareEventId
                  order
                  completedAt
                  description
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          contactInformation {
            __typename
            items {
              __typename
              id
              incidentShareEntryId
              name
              phone
              ext
              email
              type
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEntry">
    >
  >;

  OnCreateIncidentShareSiteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareSite">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareSite {
        onCreateIncidentShareSite {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareSite">
    >
  >;

  OnUpdateIncidentShareSiteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareSite">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareSite {
        onUpdateIncidentShareSite {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareSite">
    >
  >;

  OnDeleteIncidentShareSiteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareSite">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareSite {
        onDeleteIncidentShareSite {
          __typename
          id
          name
          addressOne
          addressTwo
          city
          state
          country
          zipCode
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareSite">
    >
  >;

  OnCreateIncidentShareIntegratorListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareIntegrator">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareIntegrator {
        onCreateIncidentShareIntegrator {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareIntegrator">
    >
  >;

  OnUpdateIncidentShareIntegratorListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareIntegrator">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareIntegrator {
        onUpdateIncidentShareIntegrator {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareIntegrator">
    >
  >;

  OnDeleteIncidentShareIntegratorListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareIntegrator">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareIntegrator {
        onDeleteIncidentShareIntegrator {
          __typename
          id
          name
          logoPath
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareIntegrator">
    >
  >;

  OnCreateIncidentShareEntryAccessListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEntryAccess">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareEntryAccess {
        onCreateIncidentShareEntryAccess {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEntryAccess">
    >
  >;

  OnUpdateIncidentShareEntryAccessListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEntryAccess">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareEntryAccess {
        onUpdateIncidentShareEntryAccess {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEntryAccess">
    >
  >;

  OnDeleteIncidentShareEntryAccessListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEntryAccess">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareEntryAccess {
        onDeleteIncidentShareEntryAccess {
          __typename
          id
          incidentShareEntryId
          incidentId
          code
          success
          accessDate
          ipAddress
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEntryAccess">
    >
  >;

  OnCreateIncidentShareEntyNoteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEntyNote">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareEntyNote {
        onCreateIncidentShareEntyNote {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEntyNote">
    >
  >;

  OnUpdateIncidentShareEntyNoteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEntyNote">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareEntyNote {
        onUpdateIncidentShareEntyNote {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEntyNote">
    >
  >;

  OnDeleteIncidentShareEntyNoteListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEntyNote">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareEntyNote {
        onDeleteIncidentShareEntyNote {
          __typename
          id
          incidentId
          incidentNoteId
          incidentShareEntryId
          note
          noteCreatedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEntyNote">
    >
  >;

  OnCreateIncidentShareEventListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEvent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareEvent {
        onCreateIncidentShareEvent {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareEvent">
    >
  >;

  OnUpdateIncidentShareEventListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEvent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareEvent {
        onUpdateIncidentShareEvent {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareEvent">
    >
  >;

  OnDeleteIncidentShareEventListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEvent">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareEvent {
        onDeleteIncidentShareEvent {
          __typename
          id
          cameraEventId
          startTime
          vmsCameraName
          videoFeedUrl
          eventType
          disposition
          triggerType
          objectsFound
          incidentShareEntryId
          cameraLatitude
          cameraLongitude
          createdAt
          updatedAt
          actionItems {
            __typename
            items {
              __typename
              id
              incidentProcedureTaskId
              incidentShareEventId
              order
              completedAt
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareEvent">
    >
  >;

  OnCreateIncidentShareActionItemsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareActionItems">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareActionItems {
        onCreateIncidentShareActionItems {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareActionItems">
    >
  >;

  OnUpdateIncidentShareActionItemsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareActionItems">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareActionItems {
        onUpdateIncidentShareActionItems {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareActionItems">
    >
  >;

  OnDeleteIncidentShareActionItemsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareActionItems">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareActionItems {
        onDeleteIncidentShareActionItems {
          __typename
          id
          incidentProcedureTaskId
          incidentShareEventId
          order
          completedAt
          description
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareActionItems">
    >
  >;

  OnCreateIncidentShareContactInformationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareContactInformation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIncidentShareContactInformation {
        onCreateIncidentShareContactInformation {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIncidentShareContactInformation">
    >
  >;

  OnUpdateIncidentShareContactInformationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareContactInformation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIncidentShareContactInformation {
        onUpdateIncidentShareContactInformation {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIncidentShareContactInformation">
    >
  >;

  OnDeleteIncidentShareContactInformationListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareContactInformation">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIncidentShareContactInformation {
        onDeleteIncidentShareContactInformation {
          __typename
          id
          incidentShareEntryId
          name
          phone
          ext
          email
          type
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIncidentShareContactInformation">
    >
  >;

  OnCreateCallNoticeListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallNotice">>
  > {
    const statement = `subscription OnCreateCallNotice($monitoringCenterId: String) {
        onCreateCallNotice(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallNotice">>
    >;
  }

  OnUpdateCallNoticeListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallNotice">>
  > {
    const statement = `subscription OnUpdateCallNotice($monitoringCenterId: String) {
        onUpdateCallNotice(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallNotice">>
    >;
  }

  OnDeleteCallNoticeListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallNotice">>
  > {
    const statement = `subscription OnDeleteCallNotice($monitoringCenterId: String) {
        onDeleteCallNotice(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          fromNumber
          toNumber
          monitoringCenterId
          createdAt
          answeredTime
          answeredBy
          twilioCallSid
          modifiedBy
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallNotice">>
    >;
  }

  OnCreateCallLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCallLog {
        onCreateCallLog {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCallLog">>
  >;

  OnUpdateCallLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCallLog {
        onUpdateCallLog {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCallLog">>
  >;

  OnDeleteCallLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCallLog {
        onDeleteCallLog {
          __typename
          id
          phoneNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          callEnd
          storageAudioUrl
          storageTranscriptUrl
          createdAt
          callingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCallLog">>
  >;

  OnCreateSMSLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSMSLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSMSLog {
        onCreateSMSLog {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSMSLog">>
  >;

  OnUpdateSMSLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSMSLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSMSLog {
        onUpdateSMSLog {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSMSLog">>
  >;

  OnDeleteSMSLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSMSLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSMSLog {
        onDeleteSMSLog {
          __typename
          id
          twilioSid
          toNumber
          fromNumber
          monitoringCenterId
          customerId
          siteId
          incidentId
          message
          createdAt
          textingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSMSLog">>
  >;

  OnCreateEmailLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmailLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEmailLog {
        onCreateEmailLog {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmailLog">>
  >;

  OnUpdateEmailLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmailLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEmailLog {
        onUpdateEmailLog {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmailLog">>
  >;

  OnDeleteEmailLogListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmailLog">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEmailLog {
        onDeleteEmailLog {
          __typename
          id
          awsMessageId
          toEmail
          fromEmail
          monitoringCenterId
          customerId
          siteId
          incidentId
          subject
          createdAt
          sendingUser
          modifiedBy
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmailLog">>
  >;

  OnCreateNotificationSettingListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateNotificationSetting">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNotificationSetting {
        onCreateNotificationSetting {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateNotificationSetting">
    >
  >;

  OnUpdateNotificationSettingListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateNotificationSetting">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNotificationSetting {
        onUpdateNotificationSetting {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateNotificationSetting">
    >
  >;

  OnDeleteNotificationSettingListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteNotificationSetting">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNotificationSetting {
        onDeleteNotificationSetting {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          userGroup
          clientList
          modifiedBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteNotificationSetting">
    >
  >;

  OnCreateNotificationListener(
    username?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateNotification">>
  > {
    const statement = `subscription OnCreateNotification($username: String) {
        onCreateNotification(username: $username) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateNotification">
      >
    >;
  }

  OnUpdateNotificationListener(
    username?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateNotification">>
  > {
    const statement = `subscription OnUpdateNotification($username: String) {
        onUpdateNotification(username: $username) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateNotification">
      >
    >;
  }

  OnDeleteNotificationListener(
    username?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteNotification">>
  > {
    const statement = `subscription OnDeleteNotification($username: String) {
        onDeleteNotification(username: $username) {
          __typename
          id
          username
          monitoringCenterId
          notificationType
          status
          createdAt
          modifiedBy
          url
          note
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteNotification">
      >
    >;
  }

  OnCreateUserListener(
    username?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > {
    const statement = `subscription OnCreateUser($username: String) {
        onCreateUser(username: $username) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
    >;
  }

  OnUpdateUserListener(
    username?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > {
    const statement = `subscription OnUpdateUser($username: String) {
        onUpdateUser(username: $username) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
    >;
  }

  OnDeleteUserListener(
    username?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > {
    const statement = `subscription OnDeleteUser($username: String) {
        onDeleteUser(username: $username) {
          __typename
          username
          monitoringCenterId
          vmsOperatorUserIds
          vmsOperatorUserIdAutomatedRole
          vmsAdminUserIds
          modifiedBy
          group
          clientIdList
          enabled
          phone
          email
          name
          integratorId
          autoAssign
          preferredLanguage
          gridEnabled
          gridBehavior
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
    >;
  }

  OnCreateUserVMSDetailsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateUserVMSDetails">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserVMSDetails {
        onCreateUserVMSDetails {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateUserVMSDetails">
    >
  >;

  OnUpdateUserVMSDetailsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateUserVMSDetails">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserVMSDetails {
        onUpdateUserVMSDetails {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateUserVMSDetails">
    >
  >;

  OnDeleteUserVMSDetailsListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteUserVMSDetails">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserVMSDetails {
        onDeleteUserVMSDetails {
          __typename
          id
          vmsUserId
          profileName
          cognitoUsernames
          authEmail
          vmsUsername
          vmsPassword
          vmsUserType
          apiKey
          accessToken
          refreshToken
          accessTokenExpires
          refreshTokenExpires
          monitoringCenterId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteUserVMSDetails">
    >
  >;

  OnCreateCustomerUserPermissionSetListener(
    username?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCustomerUserPermissionSet">
    >
  > {
    const statement = `subscription OnCreateCustomerUserPermissionSet($username: String) {
        onCreateCustomerUserPermissionSet(username: $username) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCustomerUserPermissionSet">
      >
    >;
  }

  OnUpdateCustomerUserPermissionSetListener(
    username?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCustomerUserPermissionSet">
    >
  > {
    const statement = `subscription OnUpdateCustomerUserPermissionSet($username: String) {
        onUpdateCustomerUserPermissionSet(username: $username) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCustomerUserPermissionSet">
      >
    >;
  }

  OnDeleteCustomerUserPermissionSetListener(
    username?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCustomerUserPermissionSet">
    >
  > {
    const statement = `subscription OnDeleteCustomerUserPermissionSet($username: String) {
        onDeleteCustomerUserPermissionSet(username: $username) {
          __typename
          username
          email
          enabled
          modifiedBy
          ownerCustomerId
          createdAt
          updatedAt
          permissions {
            __typename
            items {
              __typename
              username
              email
              monitoringCenterId
              customerId
              sitePermissions
              archived
              modifiedBy
              createdAt
              updatedAt
              permissionSet {
                __typename
                username
                email
                enabled
                modifiedBy
                ownerCustomerId
                createdAt
                updatedAt
                permissions {
                  __typename
                  items {
                    __typename
                    username
                    email
                    monitoringCenterId
                    customerId
                    sitePermissions
                    archived
                    modifiedBy
                    createdAt
                    updatedAt
                    permissionSet {
                      __typename
                      username
                      email
                      enabled
                      modifiedBy
                      ownerCustomerId
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCustomerUserPermissionSet">
      >
    >;
  }

  OnCreateCustomerUserPermissionListener(
    username?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCustomerUserPermission">
    >
  > {
    const statement = `subscription OnCreateCustomerUserPermission($username: String) {
        onCreateCustomerUserPermission(username: $username) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCustomerUserPermission">
      >
    >;
  }

  OnUpdateCustomerUserPermissionListener(
    username?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCustomerUserPermission">
    >
  > {
    const statement = `subscription OnUpdateCustomerUserPermission($username: String) {
        onUpdateCustomerUserPermission(username: $username) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCustomerUserPermission">
      >
    >;
  }

  OnDeleteCustomerUserPermissionListener(
    username?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCustomerUserPermission">
    >
  > {
    const statement = `subscription OnDeleteCustomerUserPermission($username: String) {
        onDeleteCustomerUserPermission(username: $username) {
          __typename
          username
          email
          monitoringCenterId
          customerId
          sitePermissions
          archived
          modifiedBy
          createdAt
          updatedAt
          permissionSet {
            __typename
            username
            email
            enabled
            modifiedBy
            ownerCustomerId
            createdAt
            updatedAt
            permissions {
              __typename
              items {
                __typename
                username
                email
                monitoringCenterId
                customerId
                sitePermissions
                archived
                modifiedBy
                createdAt
                updatedAt
                permissionSet {
                  __typename
                  username
                  email
                  enabled
                  modifiedBy
                  ownerCustomerId
                  createdAt
                  updatedAt
                  permissions {
                    __typename
                    items {
                      __typename
                      username
                      email
                      monitoringCenterId
                      customerId
                      sitePermissions
                      archived
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCustomerUserPermission">
      >
    >;
  }

  OnCreateTaxListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTax">>
  > {
    const statement = `subscription OnCreateTax($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateTax(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTax">>
    >;
  }

  OnUpdateTaxListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTax">>
  > {
    const statement = `subscription OnUpdateTax($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateTax(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTax">>
    >;
  }

  OnDeleteTaxListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTax">>
  > {
    const statement = `subscription OnDeleteTax($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteTax(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          region
          subregion
          archived
          amount
          name
          taxGovId
          modifiedBy
          createdAt
          updatedAt
          taxTypes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTax">>
    >;
  }

  OnCreateTaxesTaxTypeListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaxesTaxType">>
  > {
    const statement = `subscription OnCreateTaxesTaxType($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateTaxesTaxType(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          taxId
          taxTypeId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          tax {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          integratorId
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTaxesTaxType">
      >
    >;
  }

  OnUpdateTaxesTaxTypeListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaxesTaxType">>
  > {
    const statement = `subscription OnUpdateTaxesTaxType($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateTaxesTaxType(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          taxId
          taxTypeId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          tax {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          integratorId
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTaxesTaxType">
      >
    >;
  }

  OnDeleteTaxesTaxTypeListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaxesTaxType">>
  > {
    const statement = `subscription OnDeleteTaxesTaxType($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteTaxesTaxType(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          taxId
          taxTypeId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          tax {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            region
            subregion
            archived
            amount
            name
            taxGovId
            modifiedBy
            createdAt
            updatedAt
            taxTypes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          integratorId
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTaxesTaxType">
      >
    >;
  }

  OnCreateTaxTypeListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaxType">>
  > {
    const statement = `subscription OnCreateTaxType($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateTaxType(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaxType">>
    >;
  }

  OnUpdateTaxTypeListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaxType">>
  > {
    const statement = `subscription OnUpdateTaxType($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateTaxType(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaxType">>
    >;
  }

  OnDeleteTaxTypeListener(
    integratorId?: string,
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaxType">>
  > {
    const statement = `subscription OnDeleteTaxType($integratorId: String, $mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteTaxType(
          integratorId: $integratorId
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcIntAdminAccess
          mcOwnerAccess
          mcControllerAccess
          integratorId
          archived
          name
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              taxId
              taxTypeId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              tax {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                region
                subregion
                archived
                amount
                name
                taxGovId
                modifiedBy
                createdAt
                updatedAt
                taxTypes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
              integratorId
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaxType">>
    >;
  }

  OnCreateSetupFeeListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSetupFee">>
  > {
    const statement = `subscription OnCreateSetupFee($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateSetupFee(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSetupFee">>
    >;
  }

  OnUpdateSetupFeeListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSetupFee">>
  > {
    const statement = `subscription OnUpdateSetupFee($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateSetupFee(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSetupFee">>
    >;
  }

  OnDeleteSetupFeeListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSetupFee">>
  > {
    const statement = `subscription OnDeleteSetupFee($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteSetupFee(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          name
          taxTypeId
          amount
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSetupFee">>
    >;
  }

  OnCreateProductListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProduct">>
  > {
    const statement = `subscription OnCreateProduct($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateProduct(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          name
          nameForInvoice
          description
          productTaxTypeId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          status
          type
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          publishedFor
          publishedForArchived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProduct">>
    >;
  }

  OnUpdateProductListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProduct">>
  > {
    const statement = `subscription OnUpdateProduct($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateProduct(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          name
          nameForInvoice
          description
          productTaxTypeId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          status
          type
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          publishedFor
          publishedForArchived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProduct">>
    >;
  }

  OnDeleteProductListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProduct">>
  > {
    const statement = `subscription OnDeleteProduct($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteProduct(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          name
          nameForInvoice
          description
          productTaxTypeId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          status
          type
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          publishedFor
          publishedForArchived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProduct">>
    >;
  }

  OnCreateCameraPlanListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCameraPlan">>
  > {
    const statement = `subscription OnCreateCameraPlan($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateCameraPlan(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCameraPlan">>
    >;
  }

  OnUpdateCameraPlanListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCameraPlan">>
  > {
    const statement = `subscription OnUpdateCameraPlan($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateCameraPlan(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCameraPlan">>
    >;
  }

  OnDeleteCameraPlanListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCameraPlan">>
  > {
    const statement = `subscription OnDeleteCameraPlan($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteCameraPlan(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          parentId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          cameraPlanProductId
          cameraPlanLivePatrolId
          cameraPlanOperatorMinutesId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            cameraPlanId
            name
            nameForInvoice
            description
            productTaxTypeId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            status
            type
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            publishedFor
            publishedForArchived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            cameraPlanId
            enabled
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            cameraPlanId
            minPrice
            mrp
            cost
            price
            fxPrices {
              __typename
              currency
              price
            }
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCameraPlan">>
    >;
  }

  OnCreateLivePatrolListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLivePatrol">>
  > {
    const statement = `subscription OnCreateLivePatrol($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateLivePatrol(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          enabled
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLivePatrol">>
    >;
  }

  OnUpdateLivePatrolListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLivePatrol">>
  > {
    const statement = `subscription OnUpdateLivePatrol($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateLivePatrol(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          enabled
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLivePatrol">>
    >;
  }

  OnDeleteLivePatrolListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLivePatrol">>
  > {
    const statement = `subscription OnDeleteLivePatrol($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteLivePatrol(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          enabled
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLivePatrol">>
    >;
  }

  OnCreateOperatorMinutesListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateOperatorMinutes">
    >
  > {
    const statement = `subscription OnCreateOperatorMinutes($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateOperatorMinutes(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateOperatorMinutes">
      >
    >;
  }

  OnUpdateOperatorMinutesListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateOperatorMinutes">
    >
  > {
    const statement = `subscription OnUpdateOperatorMinutes($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateOperatorMinutes(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateOperatorMinutes">
      >
    >;
  }

  OnDeleteOperatorMinutesListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteOperatorMinutes">
    >
  > {
    const statement = `subscription OnDeleteOperatorMinutes($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteOperatorMinutes(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          id
          cameraPlanId
          minPrice
          mrp
          cost
          price
          fxPrices {
            __typename
            currency
            price
          }
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteOperatorMinutes">
      >
    >;
  }

  OnCreateSiteSubscriptionListener(
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSiteSubscription">
    >
  > {
    const statement = `subscription OnCreateSiteSubscription($mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateSiteSubscription(
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSiteSubscription">
      >
    >;
  }

  OnUpdateSiteSubscriptionListener(
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSiteSubscription">
    >
  > {
    const statement = `subscription OnUpdateSiteSubscription($mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateSiteSubscription(
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSiteSubscription">
      >
    >;
  }

  OnDeleteSiteSubscriptionListener(
    mcIntAdminAccess?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSiteSubscription">
    >
  > {
    const statement = `subscription OnDeleteSiteSubscription($mcIntAdminAccess: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteSiteSubscription(
          mcIntAdminAccess: $mcIntAdminAccess
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          siteId
          customerId
          status
          onHold
          startDate
          term
          renewalDate
          renewalTerm
          merchant
          billingDay
          billingFrequency
          nextBillingDay
          autorefillOn
          autorefillAmount
          minimumBalance
          currentBalance
          currentBalanceUsd
          maxNumberRecharges
          currentDayRechargeCount
          lastRefilled
          currency
          usedOperatorMinutes
          totalOperatorMinutes
          transactionsLastCalculated
          secondsPerInteraction
          incidentBillingDisabled
          interactionBillingDisabled
          livePatrolBillingDisabled
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          mcIntAdminAccess
          integratorId
          modifiedBy
          archived
          billingEmails
          billingEntityName
          commissionBilling
          createdAt
          updatedAt
          stripeSubscription {
            __typename
            id
            archived
            modifiedBy
            siteSubscriptionId
            siteId
            customerId
            integratorId
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            createdAt
            updatedAt
            paymentMethods {
              __typename
              items {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          cameraPlans {
            __typename
            items {
              __typename
              id
              parentId
              cameraId
              subscriptionId
              analyticsType
              resolution
              bitRate
              operatorDuration
              supervision
              storageDays
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
              product {
                __typename
                id
                appliedCameraPlanId
                name
                nameForInvoice
                description
                appliedProductTaxTypeId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              livePatrol {
                __typename
                id
                appliedCameraPlanId
                enabled
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
              operatorMinutes {
                __typename
                id
                appliedCameraPlanId
                price
                currency
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          setupFees {
            __typename
            items {
              __typename
              id
              parentId
              subscriptionId
              amount
              name
              currency
              integratorId
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              modifiedBy
              archived
              createdAt
              updatedAt
              taxType {
                __typename
                id
                monitoringCenterId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                integratorId
                archived
                name
                modifiedBy
                createdAt
                updatedAt
                taxes {
                  __typename
                  items {
                    __typename
                    id
                    taxId
                    taxTypeId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    monitoringCenterId
                    modifiedBy
                    createdAt
                    updatedAt
                    tax {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      region
                      subregion
                      archived
                      amount
                      name
                      taxGovId
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                    integratorId
                    taxType {
                      __typename
                      id
                      monitoringCenterId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      integratorId
                      archived
                      name
                      modifiedBy
                      createdAt
                      updatedAt
                    }
                  }
                  nextToken
                }
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSiteSubscription">
      >
    >;
  }

  OnCreateSubscriptionStripeDetailListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSubscriptionStripeDetail">
    >
  > {
    const statement = `subscription OnCreateSubscriptionStripeDetail($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateSubscriptionStripeDetail(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSubscriptionStripeDetail">
      >
    >;
  }

  OnUpdateSubscriptionStripeDetailListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSubscriptionStripeDetail">
    >
  > {
    const statement = `subscription OnUpdateSubscriptionStripeDetail($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateSubscriptionStripeDetail(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSubscriptionStripeDetail">
      >
    >;
  }

  OnDeleteSubscriptionStripeDetailListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSubscriptionStripeDetail">
    >
  > {
    const statement = `subscription OnDeleteSubscriptionStripeDetail($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteSubscriptionStripeDetail(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          archived
          modifiedBy
          siteSubscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          createdAt
          updatedAt
          paymentMethods {
            __typename
            items {
              __typename
              id
              subscriptionStripeDetailId
              stripePaymentId
              stripePaymentStatus
              type
              name
              identifier
              preferredPayment
              monitoringCenterId
              mcOwnerAccess
              mcControllerAccess
              mcBillingAdminAccess
              integratorId
              modifiedBy
              archived
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSubscriptionStripeDetail">
      >
    >;
  }

  OnCreateStripePaymentMethodListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateStripePaymentMethod">
    >
  > {
    const statement = `subscription OnCreateStripePaymentMethod($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateStripePaymentMethod(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateStripePaymentMethod">
      >
    >;
  }

  OnUpdateStripePaymentMethodListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateStripePaymentMethod">
    >
  > {
    const statement = `subscription OnUpdateStripePaymentMethod($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateStripePaymentMethod(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateStripePaymentMethod">
      >
    >;
  }

  OnDeleteStripePaymentMethodListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteStripePaymentMethod">
    >
  > {
    const statement = `subscription OnDeleteStripePaymentMethod($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteStripePaymentMethod(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          subscriptionStripeDetailId
          stripePaymentId
          stripePaymentStatus
          type
          name
          identifier
          preferredPayment
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteStripePaymentMethod">
      >
    >;
  }

  OnCreateAppliedProductListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAppliedProduct">
    >
  > {
    const statement = `subscription OnCreateAppliedProduct($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateAppliedProduct(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          name
          nameForInvoice
          description
          appliedProductTaxTypeId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAppliedProduct">
      >
    >;
  }

  OnUpdateAppliedProductListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAppliedProduct">
    >
  > {
    const statement = `subscription OnUpdateAppliedProduct($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateAppliedProduct(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          name
          nameForInvoice
          description
          appliedProductTaxTypeId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAppliedProduct">
      >
    >;
  }

  OnDeleteAppliedProductListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAppliedProduct">
    >
  > {
    const statement = `subscription OnDeleteAppliedProduct($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteAppliedProduct(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          name
          nameForInvoice
          description
          appliedProductTaxTypeId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAppliedProduct">
      >
    >;
  }

  OnCreateAppliedCameraPlanListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAppliedCameraPlan">
    >
  > {
    const statement = `subscription OnCreateAppliedCameraPlan($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateAppliedCameraPlan(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAppliedCameraPlan">
      >
    >;
  }

  OnUpdateAppliedCameraPlanListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAppliedCameraPlan">
    >
  > {
    const statement = `subscription OnUpdateAppliedCameraPlan($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateAppliedCameraPlan(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAppliedCameraPlan">
      >
    >;
  }

  OnDeleteAppliedCameraPlanListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAppliedCameraPlan">
    >
  > {
    const statement = `subscription OnDeleteAppliedCameraPlan($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteAppliedCameraPlan(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          parentId
          cameraId
          subscriptionId
          analyticsType
          resolution
          bitRate
          operatorDuration
          supervision
          storageDays
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
          product {
            __typename
            id
            appliedCameraPlanId
            name
            nameForInvoice
            description
            appliedProductTaxTypeId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
            taxType {
              __typename
              id
              monitoringCenterId
              mcIntAdminAccess
              mcOwnerAccess
              mcControllerAccess
              integratorId
              archived
              name
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  taxId
                  taxTypeId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                  tax {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    region
                    subregion
                    archived
                    amount
                    name
                    taxGovId
                    modifiedBy
                    createdAt
                    updatedAt
                    taxTypes {
                      __typename
                      nextToken
                    }
                  }
                  integratorId
                  taxType {
                    __typename
                    id
                    monitoringCenterId
                    mcIntAdminAccess
                    mcOwnerAccess
                    mcControllerAccess
                    integratorId
                    archived
                    name
                    modifiedBy
                    createdAt
                    updatedAt
                    taxes {
                      __typename
                      nextToken
                    }
                  }
                }
                nextToken
              }
            }
          }
          livePatrol {
            __typename
            id
            appliedCameraPlanId
            enabled
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
          operatorMinutes {
            __typename
            id
            appliedCameraPlanId
            price
            currency
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAppliedCameraPlan">
      >
    >;
  }

  OnCreateAppliedLivePatrolListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAppliedLivePatrol">
    >
  > {
    const statement = `subscription OnCreateAppliedLivePatrol($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateAppliedLivePatrol(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          enabled
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAppliedLivePatrol">
      >
    >;
  }

  OnUpdateAppliedLivePatrolListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAppliedLivePatrol">
    >
  > {
    const statement = `subscription OnUpdateAppliedLivePatrol($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateAppliedLivePatrol(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          enabled
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAppliedLivePatrol">
      >
    >;
  }

  OnDeleteAppliedLivePatrolListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAppliedLivePatrol">
    >
  > {
    const statement = `subscription OnDeleteAppliedLivePatrol($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteAppliedLivePatrol(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          enabled
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAppliedLivePatrol">
      >
    >;
  }

  OnCreateAppliedOperatorMinutesListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAppliedOperatorMinutes">
    >
  > {
    const statement = `subscription OnCreateAppliedOperatorMinutes($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateAppliedOperatorMinutes(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAppliedOperatorMinutes">
      >
    >;
  }

  OnUpdateAppliedOperatorMinutesListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAppliedOperatorMinutes">
    >
  > {
    const statement = `subscription OnUpdateAppliedOperatorMinutes($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateAppliedOperatorMinutes(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAppliedOperatorMinutes">
      >
    >;
  }

  OnDeleteAppliedOperatorMinutesListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAppliedOperatorMinutes">
    >
  > {
    const statement = `subscription OnDeleteAppliedOperatorMinutes($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteAppliedOperatorMinutes(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          appliedCameraPlanId
          price
          currency
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          integratorId
          modifiedBy
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAppliedOperatorMinutes">
      >
    >;
  }

  OnCreateAppliedSetupFeeListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAppliedSetupFee">
    >
  > {
    const statement = `subscription OnCreateAppliedSetupFee($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onCreateAppliedSetupFee(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          parentId
          subscriptionId
          amount
          name
          currency
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAppliedSetupFee">
      >
    >;
  }

  OnUpdateAppliedSetupFeeListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAppliedSetupFee">
    >
  > {
    const statement = `subscription OnUpdateAppliedSetupFee($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onUpdateAppliedSetupFee(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          parentId
          subscriptionId
          amount
          name
          currency
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAppliedSetupFee">
      >
    >;
  }

  OnDeleteAppliedSetupFeeListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcBillingAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAppliedSetupFee">
    >
  > {
    const statement = `subscription OnDeleteAppliedSetupFee($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String, $mcBillingAdminAccess: String) {
        onDeleteAppliedSetupFee(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcBillingAdminAccess: $mcBillingAdminAccess
        ) {
          __typename
          id
          parentId
          subscriptionId
          amount
          name
          currency
          integratorId
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcBillingAdminAccess
          modifiedBy
          archived
          createdAt
          updatedAt
          taxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcBillingAdminAccess) {
      gqlAPIServiceArguments.mcBillingAdminAccess = mcBillingAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAppliedSetupFee">
      >
    >;
  }

  OnCreateTransactionLineItemListener(
    customerId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTransactionLineItem">
    >
  > {
    const statement = `subscription OnCreateTransactionLineItem($customerId: String, $integratorId: String) {
        onCreateTransactionLineItem(
          customerId: $customerId
          integratorId: $integratorId
        ) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTransactionLineItem">
      >
    >;
  }

  OnUpdateTransactionLineItemListener(
    customerId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTransactionLineItem">
    >
  > {
    const statement = `subscription OnUpdateTransactionLineItem($customerId: String, $integratorId: String) {
        onUpdateTransactionLineItem(
          customerId: $customerId
          integratorId: $integratorId
        ) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTransactionLineItem">
      >
    >;
  }

  OnDeleteTransactionLineItemListener(
    customerId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTransactionLineItem">
    >
  > {
    const statement = `subscription OnDeleteTransactionLineItem($customerId: String, $integratorId: String) {
        onDeleteTransactionLineItem(
          customerId: $customerId
          integratorId: $integratorId
        ) {
          __typename
          id
          subscriptionId
          invoiceId
          connectedId
          type
          description
          currency
          pricePer
          totalPrice
          quantity
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
          taxes {
            __typename
            items {
              __typename
              id
              transactionLineItemId
              invoiceId
              taxId
              taxGovId
              taxPercent
              taxName
              currency
              amount
              subscriptionId
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTransactionLineItem">
      >
    >;
  }

  OnCreateTransactionLineItemTaxListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateTransactionLineItemTax">
    >
  > {
    const statement = `subscription OnCreateTransactionLineItemTax($integratorId: String) {
        onCreateTransactionLineItemTax(integratorId: $integratorId) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateTransactionLineItemTax">
      >
    >;
  }

  OnUpdateTransactionLineItemTaxListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateTransactionLineItemTax">
    >
  > {
    const statement = `subscription OnUpdateTransactionLineItemTax($integratorId: String) {
        onUpdateTransactionLineItemTax(integratorId: $integratorId) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateTransactionLineItemTax">
      >
    >;
  }

  OnDeleteTransactionLineItemTaxListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteTransactionLineItemTax">
    >
  > {
    const statement = `subscription OnDeleteTransactionLineItemTax($integratorId: String) {
        onDeleteTransactionLineItemTax(integratorId: $integratorId) {
          __typename
          id
          transactionLineItemId
          invoiceId
          taxId
          taxGovId
          taxPercent
          taxName
          currency
          amount
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteTransactionLineItemTax">
      >
    >;
  }

  OnCreateInvoiceListener(
    integratorId?: string,
    customerId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateInvoice">>
  > {
    const statement = `subscription OnCreateInvoice($integratorId: String, $customerId: String) {
        onCreateInvoice(integratorId: $integratorId, customerId: $customerId) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateInvoice">>
    >;
  }

  OnUpdateInvoiceListener(
    integratorId?: string,
    customerId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateInvoice">>
  > {
    const statement = `subscription OnUpdateInvoice($integratorId: String, $customerId: String) {
        onUpdateInvoice(integratorId: $integratorId, customerId: $customerId) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateInvoice">>
    >;
  }

  OnDeleteInvoiceListener(
    integratorId?: string,
    customerId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteInvoice">>
  > {
    const statement = `subscription OnDeleteInvoice($integratorId: String, $customerId: String) {
        onDeleteInvoice(integratorId: $integratorId, customerId: $customerId) {
          __typename
          id
          subtotal
          invoiceNumber
          total
          transactionCurrTotal
          notes
          type
          currency
          transactionCurr
          status
          merchant
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          modifiedBy
          createdAt
          transferGroup
          mcOwnerAccess
          mcControllerAccess
          updatedAt
          lineItems {
            __typename
            items {
              __typename
              id
              subscriptionId
              invoiceId
              connectedId
              type
              description
              currency
              pricePer
              totalPrice
              quantity
              siteId
              customerId
              integratorId
              monitoringCenterId
              modifiedBy
              createdAt
              updatedAt
              taxes {
                __typename
                items {
                  __typename
                  id
                  transactionLineItemId
                  invoiceId
                  taxId
                  taxGovId
                  taxPercent
                  taxName
                  currency
                  amount
                  subscriptionId
                  siteId
                  customerId
                  integratorId
                  monitoringCenterId
                  modifiedBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          payments {
            __typename
            items {
              __typename
              id
              stripePaymentIntentId
              paymentStatus
              paymentType
              notes
              invoiceId
              siteId
              customerId
              integratorId
              monitoringCenterId
              amountReceived
              amountRequested
              modifiedBy
              createdAt
              updatedAt
              paymentMethod {
                __typename
                id
                subscriptionStripeDetailId
                stripePaymentId
                stripePaymentStatus
                type
                name
                identifier
                preferredPayment
                monitoringCenterId
                mcOwnerAccess
                mcControllerAccess
                mcBillingAdminAccess
                integratorId
                modifiedBy
                archived
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          transferPayment {
            __typename
            id
            destination
            transferGroup
            stripePaymentIntentId
            currency
            stripeTransferRefId
            amount
            customerId
            monitoringCenterId
            integratorId
            createdAt
            modifiedBy
            status
            invoiceIds
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteInvoice">>
    >;
  }

  OnCreateInvoicePaymentListener(
    customerId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInvoicePayment">
    >
  > {
    const statement = `subscription OnCreateInvoicePayment($customerId: String, $integratorId: String) {
        onCreateInvoicePayment(customerId: $customerId, integratorId: $integratorId) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateInvoicePayment">
      >
    >;
  }

  OnUpdateInvoicePaymentListener(
    customerId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInvoicePayment">
    >
  > {
    const statement = `subscription OnUpdateInvoicePayment($customerId: String, $integratorId: String) {
        onUpdateInvoicePayment(customerId: $customerId, integratorId: $integratorId) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateInvoicePayment">
      >
    >;
  }

  OnDeleteInvoicePaymentListener(
    customerId?: string,
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInvoicePayment">
    >
  > {
    const statement = `subscription OnDeleteInvoicePayment($customerId: String, $integratorId: String) {
        onDeleteInvoicePayment(customerId: $customerId, integratorId: $integratorId) {
          __typename
          id
          stripePaymentIntentId
          paymentStatus
          paymentType
          notes
          invoiceId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amountReceived
          amountRequested
          modifiedBy
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteInvoicePayment">
      >
    >;
  }

  OnCreateInvoiceTransferPaymentListener(
    integratorId?: string,
    customerId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateInvoiceTransferPayment">
    >
  > {
    const statement = `subscription OnCreateInvoiceTransferPayment($integratorId: String, $customerId: String) {
        onCreateInvoiceTransferPayment(
          integratorId: $integratorId
          customerId: $customerId
        ) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateInvoiceTransferPayment">
      >
    >;
  }

  OnUpdateInvoiceTransferPaymentListener(
    integratorId?: string,
    customerId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateInvoiceTransferPayment">
    >
  > {
    const statement = `subscription OnUpdateInvoiceTransferPayment($integratorId: String, $customerId: String) {
        onUpdateInvoiceTransferPayment(
          integratorId: $integratorId
          customerId: $customerId
        ) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateInvoiceTransferPayment">
      >
    >;
  }

  OnDeleteInvoiceTransferPaymentListener(
    integratorId?: string,
    customerId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteInvoiceTransferPayment">
    >
  > {
    const statement = `subscription OnDeleteInvoiceTransferPayment($integratorId: String, $customerId: String) {
        onDeleteInvoiceTransferPayment(
          integratorId: $integratorId
          customerId: $customerId
        ) {
          __typename
          id
          destination
          transferGroup
          stripePaymentIntentId
          currency
          stripeTransferRefId
          amount
          customerId
          monitoringCenterId
          integratorId
          createdAt
          modifiedBy
          status
          invoiceIds
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteInvoiceTransferPayment">
      >
    >;
  }

  OnCreateWalletTransferListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateWalletTransfer">
    >
  > {
    const statement = `subscription OnCreateWalletTransfer($integratorId: String) {
        onCreateWalletTransfer(integratorId: $integratorId) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateWalletTransfer">
      >
    >;
  }

  OnUpdateWalletTransferListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateWalletTransfer">
    >
  > {
    const statement = `subscription OnUpdateWalletTransfer($integratorId: String) {
        onUpdateWalletTransfer(integratorId: $integratorId) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateWalletTransfer">
      >
    >;
  }

  OnDeleteWalletTransferListener(
    integratorId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteWalletTransfer">
    >
  > {
    const statement = `subscription OnDeleteWalletTransfer($integratorId: String) {
        onDeleteWalletTransfer(integratorId: $integratorId) {
          __typename
          id
          subscriptionId
          siteId
          customerId
          integratorId
          monitoringCenterId
          amount
          currency
          conversionRate
          stripePaymentId
          modifiedBy
          type
          createdAt
          updatedAt
          paymentMethod {
            __typename
            id
            subscriptionStripeDetailId
            stripePaymentId
            stripePaymentStatus
            type
            name
            identifier
            preferredPayment
            monitoringCenterId
            mcOwnerAccess
            mcControllerAccess
            mcBillingAdminAccess
            integratorId
            modifiedBy
            archived
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteWalletTransfer">
      >
    >;
  }

  OnCreateStripePayoutDetailsListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateStripePayoutDetails">
    >
  > {
    const statement = `subscription OnCreateStripePayoutDetails($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onCreateStripePayoutDetails(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateStripePayoutDetails">
      >
    >;
  }

  OnUpdateStripePayoutDetailsListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateStripePayoutDetails">
    >
  > {
    const statement = `subscription OnUpdateStripePayoutDetails($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onUpdateStripePayoutDetails(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateStripePayoutDetails">
      >
    >;
  }

  OnDeleteStripePayoutDetailsListener(
    integratorId?: string,
    mcOwnerAccess?: string,
    mcControllerAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteStripePayoutDetails">
    >
  > {
    const statement = `subscription OnDeleteStripePayoutDetails($integratorId: String, $mcOwnerAccess: String, $mcControllerAccess: String) {
        onDeleteStripePayoutDetails(
          integratorId: $integratorId
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
        ) {
          __typename
          stripeAccId
          integratorId
          monitoringCenterId
          chargesEnabled
          modifiedBy
          mcOwnerAccess
          mcControllerAccess
          archived
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (integratorId) {
      gqlAPIServiceArguments.integratorId = integratorId;
    }
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteStripePayoutDetails">
      >
    >;
  }

  OnCreateExchangeRateSettingListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExchangeRateSetting">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateExchangeRateSetting {
        onCreateExchangeRateSetting {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExchangeRateSetting">
    >
  >;

  OnUpdateExchangeRateSettingListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExchangeRateSetting">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExchangeRateSetting {
        onUpdateExchangeRateSetting {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExchangeRateSetting">
    >
  >;

  OnDeleteExchangeRateSettingListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExchangeRateSetting">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExchangeRateSetting {
        onDeleteExchangeRateSetting {
          __typename
          country
          modifiedBy
          rate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExchangeRateSetting">
    >
  >;

  OnCreatePaymentTransferSettingsListener(
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcIntAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreatePaymentTransferSettings">
    >
  > {
    const statement = `subscription OnCreatePaymentTransferSettings($mcOwnerAccess: String, $mcControllerAccess: String, $mcIntAdminAccess: String) {
        onCreatePaymentTransferSettings(
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcIntAdminAccess: $mcIntAdminAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreatePaymentTransferSettings">
      >
    >;
  }

  OnUpdatePaymentTransferSettingsListener(
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcIntAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdatePaymentTransferSettings">
    >
  > {
    const statement = `subscription OnUpdatePaymentTransferSettings($mcOwnerAccess: String, $mcControllerAccess: String, $mcIntAdminAccess: String) {
        onUpdatePaymentTransferSettings(
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcIntAdminAccess: $mcIntAdminAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdatePaymentTransferSettings">
      >
    >;
  }

  OnDeletePaymentTransferSettingsListener(
    mcOwnerAccess?: string,
    mcControllerAccess?: string,
    mcIntAdminAccess?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeletePaymentTransferSettings">
    >
  > {
    const statement = `subscription OnDeletePaymentTransferSettings($mcOwnerAccess: String, $mcControllerAccess: String, $mcIntAdminAccess: String) {
        onDeletePaymentTransferSettings(
          mcOwnerAccess: $mcOwnerAccess
          mcControllerAccess: $mcControllerAccess
          mcIntAdminAccess: $mcIntAdminAccess
        ) {
          __typename
          id
          monitoringCenterId
          mcOwnerAccess
          mcControllerAccess
          mcIntAdminAccess
          type
          variable
          variableMaxDollars
          flat
          modifiedBy
          archived
          createdAt
          updatedAt
          variableTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
          flatTaxType {
            __typename
            id
            monitoringCenterId
            mcIntAdminAccess
            mcOwnerAccess
            mcControllerAccess
            integratorId
            archived
            name
            modifiedBy
            createdAt
            updatedAt
            taxes {
              __typename
              items {
                __typename
                id
                taxId
                taxTypeId
                mcIntAdminAccess
                mcOwnerAccess
                mcControllerAccess
                monitoringCenterId
                modifiedBy
                createdAt
                updatedAt
                tax {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  region
                  subregion
                  archived
                  amount
                  name
                  taxGovId
                  modifiedBy
                  createdAt
                  updatedAt
                  taxTypes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
                integratorId
                taxType {
                  __typename
                  id
                  monitoringCenterId
                  mcIntAdminAccess
                  mcOwnerAccess
                  mcControllerAccess
                  integratorId
                  archived
                  name
                  modifiedBy
                  createdAt
                  updatedAt
                  taxes {
                    __typename
                    items {
                      __typename
                      id
                      taxId
                      taxTypeId
                      mcIntAdminAccess
                      mcOwnerAccess
                      mcControllerAccess
                      monitoringCenterId
                      modifiedBy
                      createdAt
                      updatedAt
                      integratorId
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (mcOwnerAccess) {
      gqlAPIServiceArguments.mcOwnerAccess = mcOwnerAccess;
    }
    if (mcControllerAccess) {
      gqlAPIServiceArguments.mcControllerAccess = mcControllerAccess;
    }
    if (mcIntAdminAccess) {
      gqlAPIServiceArguments.mcIntAdminAccess = mcIntAdminAccess;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeletePaymentTransferSettings">
      >
    >;
  }

  OnCreateWebhookHealthListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateWebhookHealth">>
  > {
    const statement = `subscription OnCreateWebhookHealth($monitoringCenterId: String) {
        onCreateWebhookHealth(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateWebhookHealth">
      >
    >;
  }

  OnUpdateWebhookHealthListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateWebhookHealth">>
  > {
    const statement = `subscription OnUpdateWebhookHealth($monitoringCenterId: String) {
        onUpdateWebhookHealth(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateWebhookHealth">
      >
    >;
  }

  OnDeleteWebhookHealthListener(
    monitoringCenterId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteWebhookHealth">>
  > {
    const statement = `subscription OnDeleteWebhookHealth($monitoringCenterId: String) {
        onDeleteWebhookHealth(monitoringCenterId: $monitoringCenterId) {
          __typename
          id
          monitoringCenterId
          vmsWebhookId
          date
          ackAvgInterval
          acklastReceived
          ackCount
          analyticAvgInterval
          analyticlastReceived
          analyticCount
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (monitoringCenterId) {
      gqlAPIServiceArguments.monitoringCenterId = monitoringCenterId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteWebhookHealth">
      >
    >;
  }

  OnCreateMasterListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMaster">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMaster {
        onCreateMaster {
          __typename
          id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMaster">>
  >;

  OnUpdateMasterListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMaster">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMaster {
        onUpdateMaster {
          __typename
          id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMaster">>
  >;

  OnDeleteMasterListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMaster">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMaster {
        onDeleteMaster {
          __typename
          id
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMaster">>
  >;
}
