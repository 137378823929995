/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://xmi4hjm6w3.execute-api.us-east-1.amazonaws.com/emily",
      region: "us-east-1",
    },
    {
      name: "localSecurityPublicAPI",
      endpoint: "https://83jje3f3fj.execute-api.us-east-1.amazonaws.com/emily",
      region: "us-east-1",
    },
    {
      name: "localguard",
      endpoint: "https://zbfasp7ho8.execute-api.us-east-1.amazonaws.com/emily",
      region: "us-east-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://i7egfdpsafcybkafn2mmvabrsi.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "us-east-1:659b50cd-b0d5-4233-9f9b-c2debf3ec552",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_EuRR3N60B",
  aws_user_pools_web_client_id: "5sufs051gunvkemnb20jrb5o6r",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: "8",
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "ls-storage95524-emily",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_cognito_login_mechanisms: ["PREFERRED_USERNAME"],
  aws_cognito_login_mechanism: [],
};

export default awsmobile;
